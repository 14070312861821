import { Props } from '../../../types/GlobalType';
import HeaderLayout from '../../components/home/HeaderLayout';
import ContentLayout from '../../components/home/ContentLayout';
import FooterLayout from '../../components/home/FooterLayout';
import SidebarLayout from '../../components/home/SidebarLayout';

const HomeLayout = (props: Props) => {

  return (
    <>
      <SidebarLayout />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <HeaderLayout />
        <ContentLayout>
          {props.children}
        </ContentLayout>
        <FooterLayout />
      </div>
    </>
  )
}

export default HomeLayout
import { Chart } from 'primereact/chart';
import { useEffect, useState } from 'react'
import { fetchSinToken } from '../../../helpers/fetch';

const CitasEstado = ({ ...prop }) => {
    const [vl_data_sesiones, setDetalleSesiones] = useState<any>([]);
    useEffect(() => {
        pa_agenda_resumen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.vp_periodo, prop.vp_mes]);

    const pa_agenda_resumen = async () => {
        const param = {
            "vp_opcion": '1',
            "vp_periodo": prop.vp_periodo,
            "vp_mes": prop.vp_mes,
            "vp_IdSucursal": prop.vp_IdSucursal
        }
        const response = await fetchSinToken('dashboard/pa_agenda_resumen', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        setDetalleSesiones(record);

    }
   
    let vl_TotalSesion: any = 0;
    let vl_QtyProgramado: any = 0;
    let vl_QtyCancelado: any = 0;
    let vl_QtyReProgramado: any = 0;
    let vl_QtyEjecutado: any = 0;
    let vl_QtyNoEjecutado: any = 0;

    vl_data_sesiones.forEach(
        function (row: any) {
            vl_TotalSesion = vl_TotalSesion + parseFloat(row.TtlSesion);
            vl_QtyProgramado = vl_QtyProgramado + parseFloat(row.TtlSProgramada);
            vl_QtyCancelado = vl_QtyCancelado + parseFloat(row.TtlCCancelada);
            vl_QtyReProgramado = vl_QtyReProgramado + parseFloat(row.TtlCReprogramada);
            vl_QtyEjecutado = vl_QtyEjecutado + parseFloat(row.TtlCRealizada);
            vl_QtyNoEjecutado = vl_QtyNoEjecutado + parseFloat(row.TtlCNoRealizada);
        }
    );
    let vl_det_sesiones: any = [vl_QtyProgramado, vl_QtyCancelado, vl_QtyReProgramado, vl_QtyEjecutado, vl_QtyNoEjecutado];
    // console.log(vl_det_sesiones);
    const chartData_p: any = {
        datasets: [{
            data: vl_det_sesiones,
            backgroundColor: [
                "#3f51b5",
                "#c61a54",
                "#f57c00",
                "#419544",
                "#00bcd4"
            ],
            hoverBackgroundColor: [
                "#3f51b5",
                "#c61a54",
                "#f57c00",
                "#419544",
                "#00bcd4"
            ]
            // label: 'Tipo de cita'
        }],
        labels: ['Programada', 'Cancelada', 'Reprogramada', 'Realizada', 'No Realizada']
    };

    const [lightOptions_p] = useState({
        plugins: {
            title: {
                display: true,
                text: 'Estado cita',
                font: {
                    size: 16
                }
            },
            legend: {
                position: 'left',
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    return (
        <>
            <div className='card flex flex-wrap justify-content-center card-container' >
                <div className="flex align-items-center justify-content-center">                    
                    <Chart type="doughnut" data={chartData_p} options={lightOptions_p} style={{ position: 'relative', width: '40%' }} />
                </div>
            </div>

        </>
    )
}

export default CitasEstado
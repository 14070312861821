import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload'

const UploadFiles = ({ ...prop }) => {
    // UPLOAD INCONS
    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const renderFooter = () => {
        return (
            <div>
                <Button label="Cerrar" icon="pi pi-time" className='p-button-danger'
                    onClick={() => {
                        prop.setIsMounted(false);
                        prop.isHide();
                    }} autoFocus />
            </div>
        );
    }

    return (
        <>
            <Dialog position={'center'} modal draggable resizable closable showHeader maximizable
                style={{ width: '500px', height: '500px' }}
                header={'Cargar archivo'}
                footer={renderFooter}
                visible={prop.isOpen}
                onHide={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }}
            >                
                <div className="flex flex-wrap justify-content-start text-base pt-2 w-full " >
                    <FileUpload name="hc_files_upload"                        
                        url={prop.url}
                        onUpload={prop.onUpload}
                        multiple={false}                        
                        accept="*"
                        maxFileSize={5000000}
                        chooseOptions={chooseOptions}
                        uploadOptions={uploadOptions}
                        cancelOptions={cancelOptions}
                        emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                </div>
            </Dialog>
        </>
    )
}

export default UploadFiles
import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import CitasProfesional from "../../components/dashboard/CitasProfesional";
// import Agenda from "../../components/dashboard/Agenda";
import { Dropdown } from 'primereact/dropdown';
import CitasEstado from "../../components/dashboard/CitasEstado";
// import CitasTipoAtenDoughnutChrt from "../../components/dashboard/CitasTipoAtenDoughnutChrt";
import { fetchSinToken } from "../../../helpers/fetch";
import { AppContext01 } from '../../../context/AppContext';
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ScrollPanel } from "primereact/scrollpanel";
import ResumenPagos from "../../components/dashboard/ResumenPagos";
import ResumenPagosTipos from "../../components/dashboard/ResumenPagoTipos";


const DashboardView = () => {


  // const vl_IdSucursal_0: any = localStorage.getItem('authIdSucursal');
  const [vl_IdSucursal, setIdSucursal] = useState<any>(0); //vl_IdSucursal_0
  const [dataPeriodo, setDataPeriodo] = useState<any>([]);
  const [vl_periodo, setPeriodo] = useState<any>(new Date().getFullYear());
  const [dataPeriodoMeses, setDataPeriodoMeses] = useState<any>([]);
  const [vl_periodoMes, setPeriodoMes] = useState<any>(0);
  //
  const [dataDashboard, setDataDashboard] = useState<any>([]);
  const [dataSede, setDataSede] = useState<any>([]);

  const [vl_TotalVentas, setTotalVentas] = useState<any>(0);
  const [vl_TotalPagos, setTotalPagos] = useState<any>(0);
  const [vl_TtlIngreso, setTtlIngreso] = useState<any>(0);
  const [vl_TtlGasto, setTtlGasto] = useState<any>(0);
  const [vl_TtlComision, setTtlComision] = useState<any>(0);
  const [vl_TtlComisionPagada, setTtlComisionPagada] = useState<any>(0);
  const [vl_TtlUtilidad, setTtlUtilidad] = useState<any>(0);

  useEffect(() => {
    handleSetTitle();
    //call init
    const periodo: any = new Date().getFullYear();
    // const mes: any = new Date().getMonth() + 1;
    const mes: any = 0; //all
    let dsPeriodo: any = [];
    let anio = 0;
    for (let i = (periodo - 3); i <= (periodo + 1); i++) {
      anio = i;
      dsPeriodo.push(
        { "lstPeriodo": anio }
      );
    }
    //console.log(dsPeriodo);
    setDataPeriodo(dsPeriodo);
    setPeriodo(periodo);
    //mes
    let vl_mes = ['All', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let dsPeriodoMes: any = [];
    for (let i = 0; i <= 12; i++) {
      dsPeriodoMes.push(
        { "lstMes": vl_mes[i], "IdMes": i }
      );
    }
    pa_get_sys_sucursal();
    setDataPeriodoMeses(dsPeriodoMes);
    setPeriodoMes(mes);
    pa_get_dashboard(vl_periodo, mes, vl_IdSucursal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/DASHBOARD');
  }
  const pa_get_sys_sucursal = async () => {
    const param = {
      "VP_IdSucursal": 0
    }
    const response = await fetchSinToken('selectors/pa_get_sys_sucursal', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataSede(record);
  }
  const pa_get_dashboard = async (vp_periodo: any, vp_mes: any, vp_IdSucursal: any) => {
    const param = {
      "vp_opcion": '1',
      "vp_periodo": vp_periodo,
      "vp_mes": vp_mes,
      "vp_IdSucursal": vp_IdSucursal
    }
    const response = await fetchSinToken('dashboard/pa_get_dashboard', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    // console.log(record);
    let vl_TotalVentas_1 = 0;
    let vl_TotalPagos_1 = 0;
    let vl_TtlIngreso_1 = 0;
    let vl_TtlGasto_1 = 0;
    let vl_TtlComision_1 = 0;
    let vl_TtlComisionPagada_1 = 0;
    let vl_TtlNeto_1 = 0;
    record.forEach(
      function (row: any) {
        vl_TotalVentas_1 = vl_TotalVentas_1 + parseFloat(row.TotalVentas);
        vl_TotalPagos_1 = vl_TotalPagos_1 + parseFloat(row.TotalPagos);
        vl_TtlIngreso_1 = vl_TtlIngreso_1 + parseFloat(row.TotalIngresos);
        vl_TtlGasto_1 = vl_TtlGasto_1 + parseFloat(row.TotalEgresos);
        vl_TtlComision_1 = vl_TtlComision_1 + parseFloat(row.TotalComision);
        vl_TtlComisionPagada_1 = vl_TtlComisionPagada_1 + parseFloat(row.TotalComisionPagada);
        vl_TtlNeto_1 = vl_TtlNeto_1 + parseFloat(row.TotalNeto);
      }
    );
    setTotalVentas(vl_TotalVentas_1);
    setTotalPagos(vl_TotalPagos_1);
    setTtlIngreso(vl_TtlIngreso_1);
    setTtlGasto(vl_TtlGasto_1);
    setTtlComision(vl_TtlComision_1);
    setTtlComisionPagada(vl_TtlComisionPagada_1)
    // setTtlUtilidad(vl_TtlIngreso_1 - (vl_TtlGasto_1 + vl_TtlComision_1));
    setTtlUtilidad(vl_TtlNeto_1);
    //para otros usos post
    setDataDashboard(record);
  }

  let vl_mes = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // console.log(vl_mes[0]);
  // console.log(dataDashboard);
  // const vl_labels:any = [];
  let vl_TtlJan = 0;
  let vl_TtlFeb = 0;
  let vl_TtlMar = 0;
  let vl_TtlApr = 0;
  let vl_TtlMay = 0;
  let vl_TtlJun = 0;
  let vl_TtlJul = 0;
  let vl_TtlAug = 0;
  let vl_TtlSep = 0;
  let vl_TtlOct = 0;
  let vl_TtlNov = 0;
  let vl_TtlDec = 0;
  //gastos
  let vl_TtlJan_ = 0;
  let vl_TtlFeb_ = 0;
  let vl_TtlMar_ = 0;
  let vl_TtlApr_ = 0;
  let vl_TtlMay_ = 0;
  let vl_TtlJun_ = 0;
  let vl_TtlJul_ = 0;
  let vl_TtlAug_ = 0;
  let vl_TtlSep_ = 0;
  let vl_TtlOct_ = 0;
  let vl_TtlNov_ = 0;
  let vl_TtlDec_ = 0;

  //utilidad
  let vl_TtlJan__ = 0;
  let vl_TtlFeb__ = 0;
  let vl_TtlMar__ = 0;
  let vl_TtlApr__ = 0;
  let vl_TtlMay__ = 0;
  let vl_TtlJun__ = 0;
  let vl_TtlJul__ = 0;
  let vl_TtlAug__ = 0;
  let vl_TtlSep__ = 0;
  let vl_TtlOct__ = 0;
  let vl_TtlNov__ = 0;
  let vl_TtlDec__ = 0;

  // console.log(dataDashboard);
  dataDashboard.forEach(
    function (row: any) {
      if (Number(row.Mes) === 1) {
        vl_TtlJan = vl_TtlJan + parseFloat(row.TotalVentas);
        vl_TtlJan_ = vl_TtlJan_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlJan__ = vl_TtlJan__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 2) {
        vl_TtlFeb = vl_TtlFeb + parseFloat(row.TotalVentas);
        vl_TtlFeb_ = vl_TtlFeb_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlFeb__ = vl_TtlFeb__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 3) {
        vl_TtlMar = vl_TtlMar + parseFloat(row.TotalVentas);
        vl_TtlMar_ = vl_TtlMar_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlMar__ = vl_TtlMar__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 4) {
        vl_TtlApr = vl_TtlApr + parseFloat(row.TotalVentas);
        vl_TtlApr_ = vl_TtlApr_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlApr__ = vl_TtlApr__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 5) {
        vl_TtlMay = vl_TtlMay + parseFloat(row.TotalVentas);
        vl_TtlMay_ = vl_TtlMay_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlMay__ = vl_TtlMay__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 6) {
        vl_TtlJun = vl_TtlJun + parseFloat(row.TotalVentas);
        vl_TtlJun_ = vl_TtlJun_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlJun__ = vl_TtlJun__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 7) {
        vl_TtlJul = vl_TtlJul + parseFloat(row.TotalVentas);
        vl_TtlJul_ = vl_TtlJul_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlJul__ = vl_TtlJul__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 8) {
        vl_TtlAug = vl_TtlAug + parseFloat(row.TotalVentas);
        vl_TtlAug_ = vl_TtlAug_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlAug__ = vl_TtlAug__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 9) {
        vl_TtlSep = vl_TtlSep + parseFloat(row.TotalVentas);
        vl_TtlSep_ = vl_TtlSep_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlSep__ = vl_TtlSep__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 10) {
        vl_TtlOct = vl_TtlOct + parseFloat(row.TotalVentas);
        vl_TtlOct_ = vl_TtlOct_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlOct__ = vl_TtlOct__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 11) {
        vl_TtlNov = vl_TtlNov + parseFloat(row.TotalVentas);
        vl_TtlNov_ = vl_TtlNov_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlNov__ = vl_TtlNov__ + parseFloat(row.TotalNeto);
      }
      if (Number(row.Mes) === 12) {
        vl_TtlDec = vl_TtlDec + parseFloat(row.TotalVentas);
        vl_TtlDec_ = vl_TtlDec_ + parseFloat(row.TotalEgresos) + parseFloat(row.TotalComision);
        vl_TtlDec__ = vl_TtlDec__ + parseFloat(row.TotalNeto);
      }

    }
  );

  const stackedData = {
    labels: vl_mes,
    datasets: [{
      type: 'bar',
      label: '+ VENTAS',
      backgroundColor: '#FFA726',
      data: [
        vl_TtlJan, vl_TtlFeb, vl_TtlMar, vl_TtlApr, vl_TtlMay, vl_TtlJun, vl_TtlJul, vl_TtlAug, vl_TtlSep, vl_TtlOct, vl_TtlNov, vl_TtlDec]
    }, {
      type: 'bar',
      label: '- COMISIÓN(Asignada)',
      backgroundColor: '#FF3C63',
      data: [
        vl_TtlJan_, vl_TtlFeb_, vl_TtlMar_, vl_TtlApr_, vl_TtlMay_, vl_TtlJun_, vl_TtlJul_, vl_TtlAug_, vl_TtlSep_, vl_TtlOct_, vl_TtlNov_, vl_TtlDec_
      ]
    }, {
      type: 'bar',
      label: 'UTILIDAD',
      backgroundColor: '#66BB6A',
      data: [
        vl_TtlJan__, vl_TtlFeb__, vl_TtlMar__, vl_TtlApr__, vl_TtlMay__, vl_TtlJun__, vl_TtlJul__, vl_TtlAug__, vl_TtlSep__, vl_TtlOct__, vl_TtlNov__, vl_TtlDec__
      ]
    }]
  };

  let stackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: .8,
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  //line CHART  
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue('--text-color');
  const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
  const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

  const stackedData_line = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'VENTA',
        data: [vl_TtlJan, vl_TtlFeb, vl_TtlMar, vl_TtlApr, vl_TtlMay, vl_TtlJun, vl_TtlJul, vl_TtlAug, vl_TtlSep, vl_TtlOct, vl_TtlNov, vl_TtlDec],
        fill: false,
        tension: 0.4,
        borderColor: documentStyle.getPropertyValue('--blue-500')
      },
      {
        label: 'GASTO',
        data: [vl_TtlJan_, vl_TtlFeb_, vl_TtlMar_, vl_TtlApr_, vl_TtlMay_, vl_TtlJun_, vl_TtlJul_, vl_TtlAug_, vl_TtlSep_, vl_TtlOct_, vl_TtlNov_, vl_TtlDec_],
        fill: false,
        borderDash: [5, 5],
        tension: 0.4,
        borderColor: documentStyle.getPropertyValue('--teal-500')
      },
      {
        label: 'UTILIDAD',
        data: [vl_TtlJan__, vl_TtlFeb__, vl_TtlMar__, vl_TtlApr__, vl_TtlMay__, vl_TtlJun__, vl_TtlJul__, vl_TtlAug__, vl_TtlSep__, vl_TtlOct__, vl_TtlNov__, vl_TtlDec__],
        fill: true,
        borderColor: documentStyle.getPropertyValue('--orange-500'),
        tension: 0.4,
        backgroundColor: 'rgba(255,167,38,0.2)'
      }
    ]
  };
  const stackedOptions_line = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: textColor
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      },
      y: {
        ticks: {
          color: textColorSecondary
        },
        grid: {
          color: surfaceBorder
        }
      }
    }
  };
  //line CHART ..FIN

  return (
    <>

      <div className="card p-2 m-1">
        <div className="flex flex-wrap card-container w-full">
          <div className="flex align-items-center justify-content-center m-0">
            <label className='text-lg font-semibold pr-2'> Periodo: </label>
            <Dropdown style={{ width: '120px' }}
              value={vl_periodo}
              options={dataPeriodo}
              onChange={(e: any) => {
                setPeriodo(e.value);
                pa_get_dashboard(e.value, 0, vl_IdSucursal);
              }}
              optionValue="lstPeriodo"
              optionLabel="lstPeriodo"
              placeholder="" />
          </div>
          <div className="flex align-items-center justify-content-center m-0">
            <label className='text-xl ml-1 '>  </label>
            <Dropdown style={{ width: '120px' }}
              value={vl_periodoMes}
              options={dataPeriodoMeses}
              onChange={(e: any) => {
                setPeriodoMes(e.value);
                pa_get_dashboard(vl_periodo, e.value, vl_IdSucursal);
              }}
              optionValue="IdMes"
              optionLabel="lstMes"
              placeholder="" />
          </div>
          <div className="flex align-items-center justify-content-center m-0">
            <label className='text-lg pl-2 font-semibold pr-1'>Sede: </label>
            <Dropdown style={{ width: '12rem' }} showClear
              value={vl_IdSucursal}
              options={dataSede}
              onChange={(e: any) => {
                setIdSucursal(e.value ? e.value : 0);
                pa_get_dashboard(vl_periodo, vl_periodoMes, e.value ? e.value : 0);
              }}
              optionValue="IdSucursal"
              optionLabel="Descripcion"
              placeholder="All" />

            <Button type="button" icon="pi pi-refresh"
              onClick={() => pa_get_dashboard(vl_periodo, vl_periodoMes, vl_IdSucursal)}
              className="p-button-rounded p-button-primary p-button-outlined ml-2"
            />
          </div>
        </div>
      </div>

      <div className="p-2 m-1 bg-gray-200 ">
        <ScrollPanel className='pt-3' style={{ width: '100%', height: '70vh' }}>
          <div className="bg-gray-200">
            <div className="flex flex-wrap justify-content-center card-container">
              <div className="col">
                <div className="border-round  h-6rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                  <div className="grid-col">
                    <div className="p-col mb-1 pl-2  ">
                      <label className="text-sm font-light" style={{ textAlign: 'start' }}>Ventas</label>
                    </div>
                    <div className="p-col pl-3 ">
                      {/* <label className='text-base' >Total S/ { vl_TotalVentas } </label> */}
                      <InputNumber type="text" inputClassName='border-0 text-white font-bold bg-gray-700 ' inputStyle={{ height: '30px', width: '8rem', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={vl_TotalVentas}
                      />
                      <img src='../icon/value.svg' width='100px' height='40px' alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border-round  h-6rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                  <div className="grid-col">
                    <div className="p-col mb-1 pl-3">
                      <label className="text-sm font-light" style={{ textAlign: 'start' }}>Pagos</label>
                    </div>
                    <div className="p-col pl-2 ">
                      {/* <label className='text-base' >Total S/ {vl_TotalPagos} </label> */}
                      <InputNumber type="text" inputClassName='border-0 text-white font-bold bg-gray-700' inputStyle={{ height: '30px', width: '8rem', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={vl_TotalPagos}
                      />
                      <img src='../icon/value.svg' width='100px' height='40px' alt="" />

                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border-round  h-6rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                  <div className="grid-col">
                    <div className="p-col mb-1 pl-3">
                      <label className="text-sm font-light" style={{ textAlign: 'start' }}>Ingresos *A</label>
                    </div>
                    <div className="p-col pl-2">
                      {/* <label className='text-base pl-2' >Total S/ {vl_TtlIngreso} </label> */}
                      <InputNumber type="text" inputClassName='border-0 text-white font-bold bg-gray-700' inputStyle={{ height: '30px', width: '8rem', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={vl_TtlIngreso}
                      />
                      <img src='../icon/value.svg' width='100px' height='40px' alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border-round  h-6rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                  <div className="grid-col ">
                    <div className="p-col mb-1 pl-3">
                      <label className="text-sm font-light" style={{ textAlign: 'start' }}>Gastos *B</label>
                    </div>
                    <div className="p-col pl-2">
                      {/* <label className='text-base pl-2' >Total S/ {vl_TtlGasto} </label> */}
                      <InputNumber type="text" inputClassName='border-0 text-white font-bold bg-gray-700' inputStyle={{ height: '30px', width: '8rem', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={vl_TtlGasto}
                      />
                      <img src='../icon/rate.svg' width='100px' height='40px' alt="" />
                      {/* <Button icon="pi pi-dollar" className="absolute md:top-0 p-button-rounded p-button-secondary" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border-round  h-6rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                  <div className="grid-col">
                    <div className="p-col mb-1 pl-3">
                      <label className="text-sm font-light" style={{ textAlign: 'start' }}>Comisión Asignada</label>
                    </div>
                    <div className="p-col pl-2">
                      {/* <label className='text-base' >Total S/ {vl_TtlComision} </label> */}
                      <InputNumber type="text" inputClassName='border-0 text-white font-bold bg-gray-700' inputStyle={{ height: '30px', width: '8rem', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={vl_TtlComision}
                      />
                      <img src='../icon/rate.svg' width='100px' height='40px' alt="" />
                      {/* <Button icon="pi pi-dollar" className="absolute md:top-0 p-button-rounded p-button-secondary" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border-round  h-6rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                  <div className="grid-col">
                    <div className="p-col mb-1 pl-3">
                      <label className="text-sm font-light" style={{ textAlign: 'start' }}>Comisión Pagada *C</label>
                    </div>
                    <div className="p-col pl-2">
                      {/* <label className='text-base' >Total S/ {vl_TtlComision} </label> */}
                      <InputNumber type="text" inputClassName='border-0 text-white font-bold bg-gray-700' inputStyle={{ height: '30px', width: '8rem', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={vl_TtlComisionPagada}
                      />
                      <img src='../icon/rate.svg' width='100px' height='40px' alt="" />
                      {/* <Button icon="pi pi-dollar" className="absolute md:top-0 p-button-rounded p-button-secondary" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border-round  h-6rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                  <div className="grid-col">
                    <div className="p-col mb-0 pl-2">
                      <label className="text-sm font-light" style={{ textAlign: 'start' }}>Utilidad(A-(B+C))</label>
                    </div>
                    <div className="p-col pl-3">
                      {/* <label className='text-base' >Total S/ {vl_TtlUtilidad} </label> */}
                      <InputNumber type="text" inputClassName='border-0 text-white font-bold bg-gray-700' inputStyle={{ height: '30px', width: '8rem', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={vl_TtlUtilidad}
                      />
                      <img src='../icon/quantity.svg' width='100px' height='40px' alt="" />
                      {/* <Button icon="pi pi-dollar" className="absolute md:top-0 p-button-rounded p-button-secondary" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-200 p-2">
            <div className='flex flex-column bg-gray-200 border-round w-full m-2'>
              <div className="border-round w-full h-full font-bold align-items-center justify-content-center">
                <label className='text-lg font-semibold mb-2 pl-2'>VENTAS - GASTOS (Importes en S/.)</label>
                <div className="col mt-0 ml-1">
                  <Chart type="bar" data={stackedData} options={stackedOptions} style={{ width: '100%' }} />
                </div>
                <div className="col">
                  {/* <label className='text-lg font-semibold mb-2'>Ventas</label> */}
                  <div className="col mt-0 ml-1">
                    <Chart type="line" data={stackedData_line} options={stackedOptions_line} style={{ width: '100%' }} />
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-column bg-gray-200 border-round w-full m-2'>
              <div className="border-round w-full h-full font-light align-items-center justify-content-center">
                <label className='text-lg font-semibold mb-2 pl-2'>Pagos por servicio</label>
                <ResumenPagos
                  vp_periodo={vl_periodo}
                  vp_mes={vl_periodoMes}
                  vp_IdSucursal={vl_IdSucursal}
                />
              </div>
            </div>
            <div className='flex flex-column bg-gray-200 border-round w-full m-2'>
              <div className="border-round w-full h-full font-light align-items-center justify-content-center">
                <label className='text-lg font-semibold mb-2 pl-2'>Por formas de pago</label>
                <ResumenPagosTipos
                  vp_periodo={vl_periodo}
                  vp_mes={vl_periodoMes}
                  vp_IdSucursal={vl_IdSucursal}
                />
              </div>
            </div>
            <div className="flex flex-column justify-content-center">
              <div className="col">
                <label className='text-lg font-semibold mb-2 pl-2'>Citas realizadas</label>
                <CitasProfesional
                  vp_periodo={vl_periodo}
                  vp_mes={vl_periodoMes}
                  vp_IdSucursal={vl_IdSucursal}
                />
              </div>
            </div>
            <div className="flex flex-column justify-content-center">
              <div className="col">
                <label className='text-lg font-semibold mb-2 pl-2'>Estadistica por estado</label>
                <CitasEstado
                  vp_periodo={vl_periodo}
                  vp_mes={vl_periodoMes}
                  vp_IdSucursal={vl_IdSucursal}
                />
              </div>
            </div>
          </div>
        </ScrollPanel>
      </div>



    </>
  )
}

export default DashboardView;



import React from "react";
import { useNavigate } from 'react-router-dom';
import { MenuResponse } from '../../../interfaces/GlobalInterface';
import { useFetch } from 'usehooks-ts';

const Menu = () => {

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_URL;
  // console.log('baseUrl>>' + baseUrl);
  
    const VP_IdUsuario = localStorage.getItem('authIdUsuario');
    const VP_IdSucursal = localStorage.getItem('authIdSucursal');
  
  const { data, error } = useFetch<MenuResponse[]>(`${baseUrl}/core/getMenus?VP_IdUsuario=`+VP_IdUsuario+`&VP_IdSucursal=`+VP_IdSucursal, 
  { 
    method: 'POST', 
    // body: JSON.stringify({ username: 'vhidalgo' }) 
    // body: JSON.stringify(param)
  });
  // console.log(data);

  const handleClickMenuEvents = (e: React.FormEvent) => {
    e.preventDefault();
    
    // console.log(e.currentTarget.parentNode);

    let elemClass;
    let elemTarget = null;
    if (e.currentTarget.getAttribute("href") === '/#') {
      elemTarget = e.currentTarget.parentNode as HTMLElement;
      elemClass = String(elemTarget?.getAttribute('class'));
      if (elemClass.indexOf('show') >= 0) {
        // console.log('remove("show")');
        elemTarget.classList.remove("show");
      } else {
        elemTarget.classList.add("show");
      }
    } else {
      // history.push(String(e.currentTarget.getAttribute("href")));
      navigate(String(e.currentTarget.getAttribute("href")));
    }
  }
//  console.log(error)
  if (error) return <p>There is an error</p>
  if (data?.length===0) return <p>does not have permissions</p>

  return (
    <ul className="sidebar-nav" data-coreui="navigation" data-simplebar>
      {
        data?.map(
          // eslint-disable-next-line array-callback-return
          (item, index) => {
            if (item.items.length > 0) {
              return (
                <React.Fragment key={'Module-' + item.id_menu}>
                  <li className="nav-group">
                    <a className="nav-link nav-group-toggle" href="/#" onClick={handleClickMenuEvents}>
                      {/* <FontAwesomeIcon icon={['fas', 'book']} style={{ marginRight: '10px' }} />{item.name}  */}
                      {/* <FontAwesomeIcon icon={faHome} style={{ marginRight: '10px' }} />{item.name}                       */}
                      <img src={ "../icon/menu/"+item.icon } width="28" height="28" alt="" style={{ marginRight: '10px' }} /> {item.name}
                      
                    </a>
                    <ul className="nav-group-items">
                      {
                        item.items.map(
                          (item01, index01) => {
                            return (
                              <li key={item01.id_menu} className="nav-item">
                                <a className="nav-link" href={"/" + String(item01.path).trim()} onClick={handleClickMenuEvents}>
                                  {/* <i className="mr-2 pi pi-check" style={{'fontSize': '1em'}}></i> {item01.name} */}
                                  {/* <span className="pi pi-trash"> {item01.name} </span>  */}
                                  <img src={ "../icon/menu/"+item01.icon } width="24" height="24" alt="" style={{ marginRight: '10px' }} /> {item01.name}
                                </a>
                              </li>
                            )
                          }
                        )
                      }
                    </ul>
                  </li>
                </React.Fragment>
              )
            } else {
              return (
                <React.Fragment key={'Module-' + item.id_menu}>
                  <li className="nav-item">
                    <a className="nav-link" href={"/" + String(item.path).trim()} onClick={handleClickMenuEvents}>
                      {/* <FontAwesomeIcon icon={['fas', 'book']} style={{ marginRight: '10px' }} />xxxxx{item.name} */}
                      <img src={ "../icon/menu/"+item.icon } width="28" height="28" alt="" style={{ marginRight: '10px' }} /> {item.name}
                    </a>
                  </li>
                </React.Fragment>
              )
            }
          }
        )
      }
    </ul>
  )
}

export default Menu;
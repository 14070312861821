import { createContext } from "react";
import { AppState, AppMenuTitle } from '../interfaces/GlobalInterface';


export type AppContextProps = {
  appState: AppState,
  showSidebar: (state: boolean) => void
}
export const AppContext = createContext<AppContextProps>({} as AppContextProps);

// new
export type AppContextProps01 = {  
  appmenuTitle: AppMenuTitle,
  showmenuTitle: (title: string) => void,
}
export const AppContext01 = createContext<AppContextProps01>({} as AppContextProps01);







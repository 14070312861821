import { Dialog } from 'primereact/dialog'
import { ProgressBar } from 'primereact/progressbar';

const ProgressBarUtil = ({...prop}) => {

    // @ProgressBarVisible    
    // @ProgressBarMessage (optional)
    // @setProgressBar

    return (
        <>
            <Dialog
                visible={prop.ProgressBarVisible}
                style={{ width: '250px', height: '100px' }}
                className='border-round'
                onHide={() => prop.setProgressBar(false)}
                modal position='center' draggable={true} closeOnEscape
                showHeader={false}
            >
                <ProgressBar
                    className='bg-indigo-100 font-bold text-white border-round mt-4 mb-1 ml-0 mr-0'                                                            
                    mode={ prop.ProgressBarVisible?'indeterminate':'determinate'}> 
                </ProgressBar>                
                <label className='text-base' >{prop.ProgressBarMessage?prop.ProgressBarMessage:'Please wait, loading...'}</label>
            </Dialog>
        </>
    )
}

export default ProgressBarUtil
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


export const DetallePagos = ({ ...props }) => {    
    // console.log(props.dataDetallePagos);    
    // const vl_TotalInversion = props.dataDetallePagos[0]?.tpp_Importe;    

    // const templateStatusPago = (item: any) => {
    //     // console.log(item);
    //     const BtnStatusPending = () => {
    //         return (
    //             <>
    //                 <Button
    //                     type="button"
    //                     className="p-button-rounded p-button-warning "
    //                     icon="pi pi-minus-circle" style={{ height: '24px' }}
    //                     label="Pendiente"
    //                 />
    //             </>
    //         );
    //     }
    //     const BtnStatusPagado = () => {
    //         return (
    //             <>
    //                 <Button
    //                     type="button"
    //                     className="p-button-rounded p-button-success   "
    //                     icon="pi pi-check-circle" style={{ height: '24px' }}
    //                     label="Pagado"
    //                 />
    //             </>
    //         );
    //     }

    //     return (
    //         <>
    //             {item.Estado === 'P' ? <BtnStatusPending /> : <BtnStatusPagado />
    //                 // <div className="border-cyan-500 hover:border-cyan-200 border-round font-bold border-1 m-0 flex justify-content-center">Pendiente</div> :
    //                 // <div className="border-green-500 hover:border-green-200 border-round font-bold border-1 m-0 flex justify-content-center">Pagado</div>
    //             }
    //         </>
    //     );
    // }
    // const templateNumberCurrency = (item: any) => {
    //     return (
    //         <>
    //             ${item.Saldo}
    //         </>
    //     );
    // }

    return (
        <>
            <OverlayPanel
                ref={props.o_detallePago}
                showCloseIcon={false}
                style={{ width: "850px" }}
            >                
                <DataTable header="Detalle de pagos realizados"
                    size="small"
                    value={props.dataDetallePagos ?? []}
                    dataKey="IdPagoDetalle"
                    resizableColumns
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    selectionMode="single"  
                >                    
                    <Column field="IdPago" header="Id" style={{ maxWidth: '70px' }} bodyClassName="flex justify-content-center" headerClassName="flex justify-content-center"  ></Column>
                    <Column field="FechaPago" header="F. Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-center" ></Column>                    
                    <Column field="Detalle" header="Detalle Pago" style={{  align: 'end' }} headerClassName="flex justify-content-left" bodyClassName="flex justify-content-left"></Column>                    
                    <Column field="tpd_Total" header="Importe" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column>                   
                    <Column field="IdFormaPago" header="T. Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column>                    
                    <Column field="ReferenciaPago" header="Ref. Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column>                    

                </DataTable>

                <Button
                    type="button"
                    className="p-button-rounded p-button-warning ml-1 mt-1 "
                    icon="pi pi-times"
                    label="Cerrar"
                    onClick={
                        () => {
                            props.o_detallePago.current?.hide()
                        }
                    }
                />
            </OverlayPanel>
        </>
    )
}
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { fetchSinToken } from '../../../helpers/fetch';
import TitleForm from '../../components/util/TitleForm';

const CajaPagoDetalle = ({ ...props }) => {
    const { isData } = props;
    const VL_IdLiquidacionPago: any = isData.IdLiquidacionPago;
    const VL_IdLiquidacionPagosStaff: any = isData.IdLiquidacionPagosStaff;
    const VL_IdStaff: any = isData.IdStaff;
    const VL_Staff: any = isData.Staff;

    const [dataPagoConcepto, setDataPagoConcepto] = useState<any>([]);
    const [dataPagoConceptoDet, setDataPagoConceptoDet] = useState<any>([]);
    // editor grid
    const [dataPagoConceptoOtros, setDataPagoConceptoOtros] = useState<any>([]);
    const [selectedIemsGrid, setSelectedIemsGrid] = useState<any>([]);
    const [vl_SwitchDetalle, setSwitchDetalle] = useState<boolean>(false);


    useEffect(() => {
        // console.log(isData);
        pa_get_liquidacion_pagos_conceptos();
        pa_get_liquidacion_pagos_conceptos_otros();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_liquidacion_pagos_conceptos = async () => {
        const param = {
            "VP_IdLiquidacionPago": VL_IdLiquidacionPago,
            "VP_IdStaff": VL_IdStaff,
            "VP_Tipo": 'A'
        }
        const response = await fetchSinToken('caja/pa_get_liquidacion_pagos_conceptos', param, 'POST');
        const resp = await response.json();
        setDataPagoConcepto(resp.data);
    }
    const pa_get_liquidacion_pagos_conceptos_otros = async () => {
        const param = {
            "VP_IdLiquidacionPago": VL_IdLiquidacionPago,
            "VP_IdStaff": VL_IdStaff,
            "VP_Tipo": 'O'
        }
        const response = await fetchSinToken('caja/pa_get_liquidacion_pagos_conceptos', param, 'POST');
        const resp = await response.json();
        // console.log(resp.data);
        setDataPagoConceptoOtros(resp.data);
    }

    const pa_get_liquidacion_pagos_conceptos_det = async () => {
        const param = {
            "VP_IdLiquidacionPago": VL_IdLiquidacionPago,
            "VP_IdStaff": VL_IdStaff
        }
        const response = await fetchSinToken('caja/pa_get_liquidacion_pagos_conceptos_det', param, 'POST');
        const resp = await response.json();
        // console.log(resp.data.errno);
        // if (!resp.data.errno) {
        setDataPagoConceptoDet(resp.data);
        // } else {
        //     const message =
        //         'Code: ' + resp.data.code +
        //         'Errno: ' + resp.data.errno +
        //         'Sql: ' + resp.data.sql +
        //         'SqlState: ' + resp.data.sqlState +
        //         'SqlMessage: ' + resp.data.sqlMessage;
        //     toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
        // }
    }

    const showDialogMessage = useRef<any>(null);
    const [saveConfirmDialog, setSaveConfirmDialog] = useState<boolean>(false);
    const setVisibleConfirmDialog = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            showDialogMessage.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 8000 });
            return;
        }
        setSaveConfirmDialog(true);
    }
    const handleCancel = () => {
        setSaveConfirmDialog(false);
    }
    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';
        if (dataPagoConceptoOtros.length > 0) {
            dataPagoConceptoOtros.forEach(
                function (row: any) {
                    if (row.Crud !== 'D') {
                        if (String(row.Concepto).trim() === '') {
                            vl_valida = false;
                            vl_message = 'Ingresar detalle';
                        } else if (Number(row.SubTotal) === 0) {
                            vl_valida = false;
                            vl_message = 'Ingresar Importe';
                        } else if (Number(row.Cantidad) === 0) {
                            vl_valida = false;
                            vl_message = 'Ingresar Cantidad';
                        }
                    }
                }
            );
        }
        if (dataPagoConceptoOtros.length === 0) {
            vl_valida = false;
            vl_message = 'No hay registros para guardar';
        }
        return [vl_valida, vl_message];
    }

    // C R U D - CONCEPTOS
    const setGrabarDetalleConceptos = async () => {
        // console.log('setGrabarDetalleConceptos');
        setSaveConfirmDialog(false);
        let vl_ParametrosPago: any = {}; // no usado 

        const param = {
            VP_Action: 'C',
            VP_IdLiquidacionPago: VL_IdLiquidacionPago,
            VP_FechaPago: isData.FechaPago,// no usado
            VP_Tipo: '', // no usado
            VP_TotalPago: 0,// no usado
            VP_TipoFecha: '',// no usado
            VP_PeriodoDesde: (isData.PeriodoDesde),// no usado
            VP_PeriodoHasta: (isData.PeriodoHasta), // no usado
            VP_Estado: '', // no usado
            VP_ParametrosPago: JSON.stringify(vl_ParametrosPago),  // no usado
            VP_Observaciones: '',  // no usado
            VP_IdLiquidacionPagosStaff: VL_IdLiquidacionPagosStaff,
            VP_Conceptos: JSON.stringify(dataPagoConceptoOtros),
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_ChkPsicologos: 0, // no usado
            VP_ChkAdministrativo: 0 // no usado
        };
        // console.log(param);
        // return;
        const response = await fetchSinToken('Caja/pa_set_liquidacion_pagos', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log(record);
        if (record) {
            const message = record.sql_message;
            showDialogMessage.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
            if (record.sql_error === 0) {
                props.isCallfn();
                props.setIsMounted(false);
                props.isHide();
            }
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Err: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            // console.log(message);
            showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
        }
    }

    const cantidadEditor = (options: any) => {
        return <InputNumber type="text" inputStyle={{ width: '4rem', textAlign: 'center' }}
            value={options.value} min={1} minFractionDigits={0} maxFractionDigits={0} locale="en-US"
            onValueChange={(e) => options.editorCallback(e.value)}
        />
    }
    const importeEditor = (options: any) => {
        return <InputNumber type="text" inputStyle={{ width: '6rem', textAlign: 'right' }}
            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
            locale="en-US"
            value={options.value}
            onValueChange={(e: any) => {
                // console.log(typeof e.value, e.value);
                options.editorCallback(e.value);
            }}
        />
    }
    const conceptoTextEditor = (options: any) => {
        return <InputText type="text"
            maxLength={200}
            autoFocus
            style={{ width: '100%' }}
            value={options.value}
            onChange={(e) => options.editorCallback(e.target.value.toUpperCase())} />;
    }
    const dataCategoria = [
        { label: 'INGRESO', value: 'I' },
        { label: 'DESCUENTO', value: 'D' },
        { label: 'APORTE', value: 'A' }
    ];
    const categoriaComboEditor = (options: any) => {
        return (
            <Dropdown value={options.value} options={dataCategoria} optionLabel="label" optionValue="value"
                onChange={(e) => options.editorCallback(e.value)} placeholder="Select.."
                itemTemplate={(option: any) => {
                    return <span className={'white-space-normal'}>{option.label}</span>
                }}
            />
        );
    }
    const categoriaBodyTemplate = (row: any) => {
        let VL_CategoriaLabel: any = '';
        switch (row.Categoria) {
            case 'I': VL_CategoriaLabel = 'INGRESO'; break;
            case 'D': VL_CategoriaLabel = 'DESCUENTO'; break;
            case 'A': VL_CategoriaLabel = 'APORTE'; break;
            default: VL_CategoriaLabel = 'NA'; break;
        }
        return VL_CategoriaLabel;
    }

    const onRowEditComplete = (e: any) => {
        // console.log(e);
        let editTarget = [...dataPagoConceptoOtros];
        let { newData, index } = e;
        newData.Total = parseFloat(newData.Cantidad) * parseFloat(newData.SubTotal);
        editTarget[index] = newData;
        setDataPagoConceptoOtros([...editTarget]);
    }

    const createId = () => {
        let index = 0;
        dataPagoConceptoOtros.forEach(function (row: any) {
            index = parseFloat(row.IdLiquidacionPagoConceptos);
        });
        // Los index NEGTAIVOS son registros nuevos
        if (index > 0) index = index + 1;
        else index = index - 1;
        if (index > 0) index = index * -1;
        return index;
    }

    const setAgregarConcepto = () => {
        // console.log(e);  

        let targetModelEmpty = {
            IdLiquidacionPagoConceptos: createId(),
            IdLiquidacionPago: VL_IdLiquidacionPago,
            IdLiquidacionPagosStaff: VL_IdLiquidacionPagosStaff,
            IdStaff: VL_IdStaff,
            Staff: VL_Staff,
            Codigo: '',
            Concepto: '',
            Cantidad: 1,
            SubTotal: 0,
            Descuento: 0,
            Adicional: 0,
            Total: 0,
            Tipo: 'O',
            Categoria: 'I',
            Crud: 'N' // NEW RECORD (NEW, MODIFY, DELETE)
        };
        dataPagoConceptoOtros.push(targetModelEmpty);
        setDataPagoConceptoOtros([...dataPagoConceptoOtros]);
    }
    const setRemoverConcepto = () => {
        // console.log(selectedIemsGrid);
        if (selectedIemsGrid.IdLiquidacionPagoConceptos) {
            //Item Delete
            const vl_IdLiquidacionPagoConceptos: any = selectedIemsGrid.IdLiquidacionPagoConceptos;
            const itemsNew: any = [];
            dataPagoConceptoOtros.forEach(
                function (row: any, index: any) {
                    // console.log(row);     
                    itemsNew.push(row);
                    if (row.IdLiquidacionPagoConceptos === vl_IdLiquidacionPagoConceptos) {
                        row.Crud = 'D';
                        itemsNew[index] = row;
                    }
                }
            );
            // console.log(itemsNew);
            setDataPagoConceptoOtros(itemsNew);
        } else {
            showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar un registro', life: 8000 });
        }

    }

    const renderFooter = () => {
        return (
            <>
                <Button label="Guardar" icon="pi pi-save" onClick={() => setVisibleConfirmDialog()} />
                <Button label="Cerrar" icon="pi pi-times" className="p-button-outlined p-button-danger ml-2" onClick={() => {
                    props.setIsMounted(false);
                    props.isHide();
                }} />
            </>
        );
    }
    const renderHeader = () => {
        return (
            <>
                <TitleForm title={'Pago #ID: ' + props.isData.IdLiquidacionPago + ' - Detalles de pago (Conceptos)'} />
            </>
        );
    }
    const buildPDFLiquidacionHonorarios = async () => {
        if (VL_IdLiquidacionPago === 0) {
            showDialogMessage.current.show({ severity: 'info', detail: 'Debe generar el pago', life: 6000 });
            return;
        }
        const endpoint = 'reports/LiquidacionHonorarios/?IdLiquidacionPago=' + VL_IdLiquidacionPago + '&Id=' + VL_IdLiquidacionPagosStaff;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        // console.log(url);
        window.open(url);
    }
    const buildExcelLiqPagoDetalleComm = async () => {
        if (VL_IdLiquidacionPago === 0) {
            showDialogMessage.current.show({ severity: 'info', detail: 'Debe generar el pago', life: 6000 });
            return;
        }
        const endpoint = 'caja/pa_get_liquidacion_pagos_conceptos_det_excel/?IdLiquidacionPago=' + VL_IdLiquidacionPago + '&Id=' + VL_IdStaff;
        // const endpoint = 'caja/pa_get_reporte_movimientos/?vp_FechaCierre=' + setIntlDateTimeFormat(vl_FechaCierre) + '&vp_opcion=M&vp_IdSucursal=' + vl_IdSucursal;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        // console.log(url);
        window.open(url);
    }

    return (
        <>
            <Toast ref={showDialogMessage} position="center"></Toast>
            <ConfirmDialog visible={saveConfirmDialog} onHide={() => setSaveConfirmDialog(false)}
                message="¿Guardar registro de conceptos?"
                header="Confirmar."
                icon="pi pi-exclamation-triangle"
                accept={setGrabarDetalleConceptos}
                reject={handleCancel}
            />
            <Dialog
                header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
                visible={props.isOpen}
                footer={renderFooter}
                onHide={() => {
                    props.setIsMounted(false);
                    props.isHide();
                }}
            >
                <div className='bg-yellow-200 mb-1' >
                    <div className="col mt-0 ml-0 text-base font-semibold font-italic" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='pb-1'> Profesional: {VL_IdStaff + '-' + VL_Staff + ' ('+ isData.Cargo + ')' } </label><br />
                        <label className='pb-1'> Periodo: {isData.PeriodoDesde1 + ' al ' + isData.PeriodoHasta1} </label><br />
                        <label className='pb-1'> Cant. Atenciones: {isData.CantAtencion} </label> - Total Pago $: {isData.TotalPago}
                    </div>
                </div>
                <div className="flex justify-content-between bg-gray-100">
                    <div className='mb-1' >
                        <label className="p-col-fixed ml-2 mr-2 font-semibold text-xs" style={{ textAlign: 'end' }}>Mostrar a detalle</label>
                        <InputSwitch checked={vl_SwitchDetalle}
                            onChange={(e: any) => {
                                setSwitchDetalle(e.value);
                                if (e.value)
                                    pa_get_liquidacion_pagos_conceptos_det();
                            }}
                        />
                    </div>
                    <div className='mb-1' >
                        <Button
                            label="Hoja Liq."
                            icon="pi pi-file-pdf"
                            onClick={() => buildPDFLiquidacionHonorarios()}
                            className="p-button-rounded p-button-link"
                        />
                        <Button
                            label="Descargar det."
                            icon="pi pi-file-excel"
                            onClick={() => buildExcelLiqPagoDetalleComm()}
                            className="p-button-rounded p-button-link"
                        />
                    </div>

                </div>
                <div className={vl_SwitchDetalle ? 'hidden' : 'mb-1'}>
                    <DataTable size="small" resizableColumns
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        value={dataPagoConcepto}
                        selectionMode="single"
                        scrollHeight="300px"
                    // paginator
                    // rows={30}
                    >

                        <Column field="Concepto" header="Servicio" style={{ minWidth: '300px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="white-space-normal"
                        // body={template_detalleAgenda}                    
                        >
                        </Column>
                        <Column field="Cantidad" header="Cant." style={{ maxWidth: '100px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-center text-cyan-800 font-bold"
                        // body={template_NumberCurrency}
                        >
                        </Column>
                        <Column field="Descuento" header="Descto."
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-center text-cyan-800 font-bold"
                        // body={template_NumberCurrency}
                        >
                        </Column>
                        <Column field="Adicional" header="Otros"
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        // body={template_NumberCurrency}
                        >
                        </Column>
                        <Column field="Total" header="Importe Total"
                            headerClassName="flex justify-content-center  white-space-normal"
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                    </DataTable>
                </div>
                <div className={!vl_SwitchDetalle ? 'hidden' : 'mb-1'}  >
                    <DataTable size="small" resizableColumns
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        value={dataPagoConceptoDet}
                        selectionMode="single"
                        scrollHeight="300px"
                        // style={{ maxWidth: '900px' }}
                        paginator
                        rows={30}
                    >
                        <Column field="FechaEfectiva" header="Fecha" style={{ maxWidth: '100px' }}  ></Column>
                        <Column field="ServicioDet" header="Servicio" style={{ minWidth: '300px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="white-space-normal"
                        >
                        </Column>
                        <Column field="Paciente" header="Paciente" style={{ minWidth: '200px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="white-space-normal"
                        >
                        </Column>
                        <Column field="NumeroHistoriaClinica" header="HC." style={{ minWidth: '85px' }}
                            headerClassName="flex justify-content-center"                            
                        >
                        </Column>                        
                        <Column field="Base" header="Costo servicio" style={{ maxWidth: '100px' }}
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="Porcentage" header="% Apl."
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-center text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="ImporteFijo" header="Comisión fija"
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="Importe" header="Comisión aplicada"
                            headerClassName="flex justify-content-center  white-space-normal "
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="ImporteAdicional" header="Valor Adicional"
                            headerClassName="flex justify-content-center white-space-normal"
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="Descuentos" header="Descto."
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-end text-red-500 font-bold"
                        >
                        </Column>
                        <Column field="Total" header="Total"
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-end text-green-600 font-bold"
                        >
                        </Column>
                    </DataTable>
                </div>
                <div className="flex justify-content-between pt-1 ">
                    <div className='flex flex-column p-1' >
                        <Button icon="pi pi-plus" className="p-button-rounded p-button-success p-button-outlined mr-0" title='Agregar conceptos'
                            onClick={() => { setAgregarConcepto() }}
                        />
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined mt-1" title='Quitar conceptos'
                            onClick={() => { setRemoverConcepto() }}
                        />
                    </div>
                    <div className='w-100' >
                        <DataTable size="small" resizableColumns
                            header="Otros conceptos"
                            // style={{ height: '200px' }}
                            responsiveLayout="scroll" scrollHeight="250px"
                            columnResizeMode="fit"
                            value={dataPagoConceptoOtros ?? []}
                            dataKey="IdLiquidacionPagoConceptos"
                            showGridlines
                            stripedRows
                            scrollable
                            selectionMode="single"
                            //editor
                            editMode="row"
                            onRowEditComplete={onRowEditComplete}
                            onRowEditInit={(e: any) => {
                                // console.log('onRowEditInit')                                
                            }}
                            selection={selectedIemsGrid}
                            onSelectionChange={(e: any) => {
                                setSelectedIemsGrid(e.value);
                            }}

                        >
                            <Column rowEditor onCellEditInit={(e) => { console.log(e) }} bodyStyle={{ textAlign: 'center' }}></Column>
                            <Column field="Concepto" header="Detalle de pago" style={{ minWidth: '300px' }}
                                headerClassName="flex justify-content-center"
                                bodyClassName="white-space-normal"
                                editor={(options) => conceptoTextEditor(options)}
                                body={(item: any) => {
                                    return (<label className={`${item.Crud === 'D' ? 'line-through text-red-500' : ''}`}> {item.Concepto}</label>);
                                }}
                            >
                            </Column>
                            <Column field="Categoria" header="Categoria" style={{ minWidth: '150px' }}
                                headerClassName="flex justify-content-center"
                                bodyClassName="white-space-normal"
                                editor={(options) => categoriaComboEditor(options)}
                                body={categoriaBodyTemplate}
                            >
                            </Column>

                            <Column field="SubTotal" header="Importe" style={{ minWidth: '8rem' }}
                                headerClassName="flex justify-content-start white-space-normal "
                                bodyClassName="flex justify-content-end text-cyan-800 font-semibold"
                                editor={(options) => {
                                    // console.log(options);
                                    return importeEditor(options)
                                }}
                            >
                            </Column>
                            <Column field="Cantidad" header="Cant." style={{ minWidth: '5rem' }}
                                headerClassName="flex justify-content-center"
                                bodyClassName="flex justify-content-center text-cyan-800 font-semibold"
                                editor={(options) => cantidadEditor(options)}
                            >
                            </Column>
                            <Column field="Total" header="Total" style={{ minWidth: '6rem' }}
                                headerClassName="flex justify-content-center"
                                bodyClassName="flex justify-content-end text-cyan-800 font-semibold"
                            >
                            </Column>
                        </DataTable>
                    </div>

                </div>

            </Dialog>
        </>
    )
}

export default CajaPagoDetalle
import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from 'react';

// import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { OrdenAtencionForm } from '../../components/admision/ordenAtencion/OrdenAtencionForm';
import useModal from '../../../context/WindowsOpen';
import { fetchSinToken } from '../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../helpers/functions';
import { Toast } from 'primereact/toast';
// import { InputNumber } from 'primereact/inputnumber';
import { AppContext01 } from '../../../context/AppContext';
import { FooterGridQuantity } from '../../components/util/FooterGrid';
import BuscadorClientes from '../../components/util/BuscadorClientes';


const OrdenAtencionView = () => {
  // console.log('OrdenAtencionView');
  //controla el estado de renderizacion: AdmisionForm 
  const isMounted = useRef(false);
  // const [isOpenOrdenAtencionForm, setIsOpenOrdenAtencionForm] = useState(false);
  const [valFechaDesde, setvalFechaDesde] = useState<any>(new Date());
  const [valFechaHasta, setvalFechaHasta] = useState<any>(new Date());
  const [valNumeroHistoria, setvalNumeroHistoria] = useState('');
  const [ordenAtencion, setOrdenAtencion] = useState([]);

  //PARA FORMULARIO REGISTRO
  const [isMode, setMode] = useState('I');
  const [selectedGrid1, setSelectedGrid1] = useState<any>([]);

  // Para open/close formularios
  const [isOpenModal, openAdmisionModal, closeAdmisionModal] = useModal();
  // mensaje
  const toast = useRef<any>(null);

  const handleClickNew = (e: any) => {
    setMode('I');
    // setIsOpenOrdenAtencionForm(true);      
    isMounted.current = true;
    openAdmisionModal();
  }
  const handleClickEdit = (e: any) => {
    // console.log(selectedGrid1);
    setMode('U');
    // console.log(isMode);
    if (Object.keys(selectedGrid1).length <= 0) {
      // console.log('seleccionar un registro');
      const message = 'Seleccionar un registro';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // setIsOpenOrdenAtencionForm(true);
    isMounted.current = true;
    openAdmisionModal();
  }

  const handleClickAnular = (e: any) => {
    setMode('A');
    if (Object.keys(selectedGrid1).length <= 0) {
      const message = 'Seleccionar un registro';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // console.log(selectedGrid1.EstadoRegistro);
    if (selectedGrid1.EstadoRegistro === 'A') {
      const message = '¡Registro ya fue anulado!';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // setIsOpenOrdenAtencionForm(true);
    isMounted.current = true;
    openAdmisionModal();
  }

  const setIsMounted = (val: boolean) => {
    isMounted.current = val;
  }

  const handleClickImprimir = (e: any) => {
    alert('vaya! ..no implementado');
    // const url:any =  '/Testpdf'; 
    // window.open(url);
    //return < ReportOrdenAtencion />;
    // const doc = new jsPDF();
    // doc.text("ORDEN  DE  ATENCIÓN", 10, 10);
    // doc.save("ot.pdf");  
  }




  // Agrega titulo a la barra de navegacion superior  
  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/Proceso/Orden de atención');
  }

  const OrdenAtencionFormNew = () => {
    return (
      <OrdenAtencionForm
        isOpen={isOpenModal}
        isHide={closeAdmisionModal}
        setSelectedGrid1={setSelectedGrid1}
        isData={selectedGrid1}
        isMode={isMode}
        setBuscar={setBuscar}
        setIsMounted={setIsMounted}
      />
    );
  }

  useEffect(() => {
    handleSetTitle();
    getOrdenAtencion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const getOrdenAtencion = async () => {
    const param = {
      "vp_NumeroDocumento": '',
      "vp_NumeroHistoria": valNumeroHistoria,
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      VP_IdSucursal: localStorage.getItem('authIdSucursal')
    }
    const response = await fetchSinToken('admision/pa_get_orden_atencion', param, 'POST');
    const resp = await response.json();
    
    console.log(resp.data.length);

    if (resp.data.errno) {
      const message =
        'Code: ' + resp.data.code +
        'Errno: ' + resp.data.errno  +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage ;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 3000 });
    } else {
      if (resp.data.length === 0)        
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No results found', life: 3000 });
      setOrdenAtencion(resp.data);
      
    }

  }

  const getOrdenAtencion02 = async (IN_NumeroHistoria:any) => {
    const param = {
      "vp_NumeroDocumento": '',
      "vp_NumeroHistoria": IN_NumeroHistoria,
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      VP_IdSucursal: localStorage.getItem('authIdSucursal')
    }

    const response = await fetchSinToken('admision/pa_get_orden_atencion', param, 'POST');
    const resp = await response.json();
    // console.log(resp.data.length);
    if (resp.data.errno) {
      const message =
        'Code: ' + resp.data.code + '<br>' +
        'Errno: ' + resp.data.errno + '<br>' +
        'Sql: ' + resp.data.sql + '<br>' +
        'SqlState: ' + resp.data.sqlState + '<br>' +
        'SqlMessage: ' + resp.data.sqlMessage + '<br>';
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 3000 });
    } else {
      if (resp.data.length === 0)              
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No results found', life: 3000 });
      setOrdenAtencion(resp.data);
      
    }

  }

  const setBuscar = () => {
    getOrdenAtencion();
  }


  const footer = () => {
    let vl_Total: any = ordenAtencion.length;
    // ordenAtencion.forEach(
    //   function (currentValue: any) {
    //     Total = Total + parseFloat(currentValue.CostoTotal);
    //   }
    // );
    return (
      <>
        <FooterGridQuantity Total={vl_Total} />
      </>
    );
  }

  // new buscador clientes
  const [isOpenModal1, openModal1, closeModal1] = useModal(); //buscador
  const handleClickBuscar = (e: any) => {
    isMounted.current = true;
    openModal1();
  }
  const setIsMounted2 = (val: boolean) => {
    isMounted.current = val;
  }
  const BuscadorClientesForm = () => {
    return (
      <BuscadorClientes
        isOpen={isOpenModal1}
        isHide={closeModal1}
        getDataCliente={getDataCliente}
        setIsMounted={setIsMounted2}
      />
    );
  }
  const getDataCliente = (data: any) => {
    // console.log(data.NumeroDocumento);    
    getOrdenAtencion02(data.NumeroHistoriaClinica);
  }


  return (
    <>
      <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
      <div className="card p-1 mt-0" style={{ marginTop: '.1em' }}  >
        <div className="card-container gray-container">
          <div className="block bg-gray-10 font-bold text-end p-0 mb-2 flex justify-content-end">
            {/* <Menubar model={MenubarItems} style={{ height: '60px' }} /> */}
            <span className="p-buttonset">
              <Button
                label="Nuevo"
                icon="pi pi-file"
                onClick={(e) => handleClickNew(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Editar"
                icon="pi pi-pencil"
                onClick={(e) => handleClickEdit(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Anular"
                icon="pi pi-trash"
                onClick={(e) => handleClickAnular(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              {/* <Button
                label="Imprimir"
                icon="pi pi-print"
                onClick={(e) => handleClickImprimir(e)}
                className="p-button-sm p-button-primary p-button-raised"
              /> */}
            </span>
          </div>
          <div className="block bg-gray-100 filter-view font-bold text-left p-1  mb-1">
            <div className="p-field p-grid mb-1">
              <div className="p-col">
                <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Del:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                  maxDate={new Date()}
                  value={valFechaDesde}
                  onChange={(e) => setvalFechaDesde(e.value)}
                />
                <label className="p-col-fixed ml-3 mr-1 font-bold " style={{ textAlign: 'end' }}>Al:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                  maxDate={new Date()}
                  value={valFechaHasta}
                  onChange={(e) => setvalFechaHasta(e.value)}
                />
                <label className="p-col-fixed ml-3 mr-1 font-bold " style={{ textAlign: 'end' }}>N° HC:</label>
                <InputText type="text" style={{ width: '120px' }} className="p-inputtext-sm" autoFocus
                  value={valNumeroHistoria}
                  onChange={(e) => setvalNumeroHistoria(String(e.currentTarget.value).toUpperCase())}
                />
                <Button label="Buscar"
                  onClick={(e) => setBuscar()}
                  icon="pi pi-search" className="p-button ml-2" 
                />
                <Button label="" tooltip='Busqueda de Pacientes'
                  onClick={(e: any) => handleClickBuscar(e)}
                  icon="pi pi-user-plus" className="p-button-rounded mt-0 p-button-warning ml-2 "
                />

              </div>
            </div>

          </div>
          <div className="block bg-gray-100 font-bold text-center p-1  mb-1">
            <DataTable size="small" resizableColumns
              footer={footer}
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              stripedRows
              scrollable
              value={ordenAtencion}
              dataKey="IdRegistro"
              selectionMode="single"
              selection={selectedGrid1}
              onSelectionChange={e => setSelectedGrid1(e.value)}
              scrollHeight="50vh"
              paginator
              rows={20}
            >
              <Column field="NumeroOrden" header="O/Atención" style={{ maxWidth: '125px' }}   ></Column>
              <Column field="FechaAtencion_1" header="Fecha" style={{ maxWidth: '125px' }}  ></Column>
              <Column field="HoraAtencion" header="Hora" style={{ maxWidth: '85px' }}  ></Column>
              <Column field="NumHistoriaClinica" header="Nº HC" style={{ maxWidth: '120px' }}  ></Column>
              <Column field="Paciente" header="Paciente/Usuario" ></Column>
              <Column field="NumeroDocumento" header="Nº Doc." style={{ maxWidth: '100px' }} ></Column>
              <Column field="FechaNacimiento_1" header="Fecha Nac." style={{ maxWidth: '125px' }} ></Column>
              <Column field="Edad" header="Edad" style={{ maxWidth: '70px' }} ></Column>
              <Column field="Estado_1" header="Estado">
              </Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Toast ref={toast} position="top-center"></Toast>
      <React.Fragment>{
        isMounted.current && <OrdenAtencionFormNew />
      }
      </React.Fragment>

    </>
  )
}

export default OrdenAtencionView


import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AppContext01 } from '../../../context/AppContext';
import { fetchSinToken } from '../../../helpers/fetch';
import useModal from '../../../context/WindowsOpen';
import ServicioForm from '../../components/catalogo/ServicioForm';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FooterGridNumber } from '../../components/util/FooterGrid';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import '../../../assets/scss/_dataView.scss';
import { setIntlDateTimeFormat } from '../../../helpers/functions';

const CajaComisionesView = () => {
    // filter sp
    const [vp_tipo, setTipo] = useState<any>('E');
    const [vp_Periodo, setPeriodo] = useState<any>('0');
    const [vp_FechaDesde, setFechaDesde] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [vp_FechaHasta, setFechaHasta] = useState<any>(new Date());
    const [vp_Staff, setStaff] = useState<any>('');
    const [dataComision, setdataComision] = useState<any>([]);
    const [selectedGrid, setSelectedGrid] = useState<any>([]);

    const [isMode, setMode] = useState('I');
    const isMounted = useRef(false);
    const [isOpenModal, openModal, closeModal] = useModal();
    const toast = useRef<any>(null);

    const [layout, setLayout] = useState('grid');
    const [selectedCategory, setSelectedCategory] = useState<any>('');
    const [dataIdStaff, setDataIdStaff] = useState<any>([]);
    const [vl_IdStaff, setIdStaff] = useState<any>(0);



    const navigate = useNavigate();
    useEffect(() => {
        handleSetTitle();
        // pa_get_staff();
        pa_get_liquidacion_pagos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/Caja/Comisiones');
    }

    const pa_get_liquidacion_pagos = async () => {
        const param = {
            "vp_tipo": vp_tipo,
            "vp_Periodo": vp_Periodo,
            "vp_FechaDesde": setIntlDateTimeFormat(vp_FechaDesde),
            "vp_FechaHasta": setIntlDateTimeFormat(vp_FechaHasta),
            "vp_Staff": vl_IdStaff,
            "vp_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('caja/pa_get_liquidacion_pagos', param, 'POST');
        const resp = await response.json();

        // console.log(resp.data.errno);
        if (!resp.data.errno) {
            setdataComision(resp.data);
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
        }

    }


    // const handleClickNew = () => {
    //     setMode('I');
    //     isMounted.current = true;
    //     setSelectedGrid([]);
    //     openModal();
    // }
    // const handleClickEdit = () => {
    //     if (Object.keys(selectedGrid).length <= 0) {
    //         const message = 'seleccionar un item';
    //         toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
    //         return;
    //     }
    //     setMode('U');
    //     isMounted.current = true;
    //     openModal();
    // }

    const handleSetIsMounted = (val: boolean) => {
        isMounted.current = val;
    }

    const template_NumberCurrency = (item: any) => {
        return (
            <>
                <label className='text-color-green-600'>$ {item.TotalPago}</label>
            </>
        );
    }
    
    const templateDetallePagos = (item: any) => {        
        return (
          <>
            <Button icon="pi pi-desktop" className="p-button-sm p-button-raised p-button-text p-button-plain"
              onClick={(e) => formCajaPagoComisionEdit(item)}
            />
          </>
        );
      }

    // const pa_get_staff = async () => {
    //     const param = {
    //         "vp_opcion": "A",
    //         "vp_IdStaff": "0",
    //         "VP_Codigo": "",
    //         "VP_Nombres": "",
    //         "VP_Apellidos": ""
    //     }
    //     const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
    //     const resp = await response.json();
    //     const record = resp.data;
    //     setDataIdStaff(record);
    // }

    const footer = () => {
        // let vl_Base: any = 0;
        let vl_Total: any = 0;
        // let vl_TotalSaldo: any = 0;
        dataComision.forEach(
            function (currentValue: any) {
                // vl_Base = vl_Base + parseFloat(currentValue.Base);
                vl_Total = vl_Total + parseFloat(currentValue.TotalPago);
                // vl_TotalSaldo = vl_TotalSaldo + parseFloat(currentValue.Saldo);
            }
        );
        return (
            <>
                <div className='flex flex-wrap justify-content-end' >
                    {/* <FooterGridNumber Total={vl_Base} Title={'Total Base'} /> */}
                    <FooterGridNumber Total={vl_Total} Title={'Total: '} />
                    {/* <FooterGridNumber Total={vl_TotalSaldo} Title={'Comision'} /> */}
                </div>
            </>
        );
    }

    //LINKS
    const formCajaPagoComisionView = () => {
        navigate('/CajaPagoComisionView/0/New');
    }
    const formCajaPagoComisionEdit = (item:any) => {
        navigate('/CajaPagoComisionView/'+item.IdLiquidacionPago+'/Edit');
    }
    const link_form_close = () => {
        navigate('/WelcomePage');
    }

    return (
        <>
            <>
                {isMounted.current && <ServicioForm
                    isMode={isMode}
                    isOpen={isOpenModal}
                    isHide={closeModal}
                    isMounted={handleSetIsMounted}
                    isData={selectedGrid}
                    isSetSelectedGrid={setSelectedGrid}
                    setSelectedCategory={setSelectedCategory}
                    isCallfn={pa_get_liquidacion_pagos} />}
            </>
            <Toast ref={toast} position="top-center"></Toast>

            <div className="flex justify-content-between pt-1 pl-3 border-bottom-2 p-3  ">
                {/* <div className='md:border-solid border-yellow-500 shadow-1 m-2 flex align-items-center justify-content-center p-1' >
                    <label className='text-base font-italic'> * Pago de Honorarios</label>
                </div> */}
                <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
                    <div className="col mt-0 ml-0 text-base font-bold font-italic h-3rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='pb-1'> Pago de Honorarios </label>
                    </div>
                </div>
                <div>
                    <span className="p-buttonset">
                        <Button
                            label="Nuevo Pago"
                            icon="pi pi-file"
                            onClick={() => formCajaPagoComisionView()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />                        
                        {/* <Button
                            label="Exportar"
                            icon="pi pi-file-excel"
                            // onClick={() => handleClickEdit()}
                            className="p-button-sm p-button-primary p-button-raised"
                        /> */}
                        <Button
                        icon="pi pi-times"
                        onClick={() => link_form_close()}
                        title="Cerrar"
                        className="p-button-sm p-button-outlined p-button-danger ml-1 "
                        // className="p-button-rounded p-button-danger p-button ml-1" aria-label="Cancel"
                    />
                    </span>                    
                </div>
            </div>

            <div className="card-container gray-container p-3 ">
                <label className="p-col-fixed ml-1 mr-1 font-semibold " style={{ textAlign: 'end' }}>Filtrar:</label>
                <Dropdown style={{ width: '10rem' }} value={vp_tipo}
                    options={[{
                        "IdCode": "E",
                        "Descripcion": "F. Emisión"
                    }, {
                        "IdCode": "P",
                        "Descripcion": "F. Pago"
                    }
                    ]}
                    onChange={(e: any) => {
                        setTipo(e.value);
                    }}
                    optionValue="IdCode"
                    optionLabel="Descripcion"
                    placeholder="Seleccionar" />
                <label className="p-col-fixed mr-1 ml-2 font-semibold " style={{ textAlign: 'end' }}>De:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                    dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                    maxDate={new Date()}
                    value={vp_FechaDesde}
                    onChange={(e) => setFechaDesde(e.value)}
                />
                <label className="p-col-fixed ml-1 mr-1 font-semibold " style={{ textAlign: 'end' }}>Al:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                    dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                    maxDate={new Date()}
                    value={vp_FechaHasta}
                    onChange={(e) => setFechaHasta(e.value)}
                />
                {/* <label className="p-col-fixed ml-1 mr-1 font-semibold " style={{ textAlign: 'end' }}>Profesional:</label>
                <Dropdown style={{width: '12rem' }} filter filterBy="staff" showClear
                    value={vl_IdStaff}
                    options={dataIdStaff}
                    onChange={ (e:any)=>{
                        setIdStaff(e.value);
                    }}
                    optionValue="IdStaff"
                    optionLabel="staff"
                    placeholder="Seleccionar" /> */}

                <Button label=""
                    onClick={(e: any) => pa_get_liquidacion_pagos()}
                    icon="pi pi-search"
                    title='Buscar '
                    className="p-button-rounded ml-2"
                />
            </div>

            <div className="card-container gray-container">
                <DataTable size="small" resizableColumns
                    footer={footer}
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    value={dataComision}
                    // dataKey="CodigoServicio"
                    selectionMode="single"
                    // selection={selectedGrid}
                    // onSelectionChange={e => setSelectedGrid(e.value)}
                    scrollHeight="400px"
                    paginator
                    rows={30}
                >
                    <Column header="Ver" body={templateDetallePagos} style={{ maxWidth: '70px' }}   ></Column>
                    <Column field="IdLiquidacionPago" header="#ID" style={{ maxWidth: '70px' }}   ></Column>
                    <Column field="FechaEmision" header="Fecha Emisión"  ></Column>
                    <Column field="FechaPago" header="Fecha Pago"  ></Column>
                    <Column field="PeriodoDesde" header="Periodo desde" headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center"></Column>
                    <Column field="PeriodoHasta" header="Periodo hasta" headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center" ></Column>
                    <Column field="Estado" header="Estado" style={{ maxWidth: '120px' }}   ></Column>
                    <Column field="TotalPago" header="Total" style={{ maxWidth: '100px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-green-600 font-semibold"
                        body={template_NumberCurrency}
                    >
                    </Column>                    
                </DataTable>
            </div>

        </>
    )
}
export default CajaComisionesView;

// funciones generales
// import { toMoment } from "@fullcalendar/moment";
import moment from 'moment-timezone';

// Formato fecha :: YYYY-mm-dd
export const setIntlDateTimeFormat = (valDate: any) => {
    // NO ES COMPTATIBLE CON FIREFOX (F:20230121)
    // return new Intl.DateTimeFormat("az", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    // }).format(valDate);

    var format = 'YYYY-MM-DD';
    return moment(valDate, format).tz('America/Lima').format(format);
}
export const setIntlTimeFormat = (valDate: any) => {
    // var format = 'HH:mm:ss';
    // return moment(valDate, format).tz('America/Lima').format(format);
    return new Intl.DateTimeFormat("az", {
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
    }).format(valDate);
}

// test
export const getCalculaIntervalTime = (date1: any, date2: any) => {

    // asignar el valor de las unidades en milisegundos
    var msecPerMinute = 1000 * 60;
    var msecPerHour = msecPerMinute * 60;
    var msecPerDay = msecPerHour * 24;

    // asignar la fecha en milisegundos
    var date = new Date('12/04/2022');
    var dateMsec = date.getTime();

    // asignar la fecha el 1 de enero del a la media noche
    date.setMonth(3);
    date.setDate(12);
    date.setHours(0, 0, 0, 0);

    // Obtener la diferencia en milisegundos
    var interval = dateMsec - date.getTime();

    // Calcular cuentos días contiene el intervalo. Substraer cuantos días
    //tiene el intervalo para determinar el sobrante
    var days = Math.floor(interval / msecPerDay);
    interval = interval - (days * msecPerDay);

    // Calcular las horas , minutos y segundos
    var hours = Math.floor(interval / msecPerHour);
    interval = interval - (hours * msecPerHour);

    var minutes = Math.floor(interval / msecPerMinute);
    interval = interval - (minutes * msecPerMinute);

    var seconds = Math.floor(interval / 1000);

    // Mostrar el resultado.
    // document.write(days + " days, " + hours + " hours, " + minutes + " minutes, " + seconds + " seconds.");

    //Output: 164 días, 23 horas, 0 minutos, 0 segundos.
    return days + " days, " + hours + " hours, " + minutes + " minutes, " + seconds + " seconds.";
}

export const toTimeZone = (time: any, zone: any) => {
    // moment().tz("America/Los_Angeles").format();
    var format = 'YYYY/MM/DD HH:mm:ss ZZ';
    return moment(time, format).tz(zone).format(format);
}

export const toTimeZoneF2 = (time: any, zone: any) => {
    // moment().tz("America/Los_Angeles").format();
    var format = 'YYYY/MM/DD';
    return moment(time, format).tz(zone).format(format);
}

export const toTimeZoneF3 = (time: any, zone: any) => {
    // moment().tz("America/Los_Angeles").format();
    var format = 'YYYY/MM/DD HH:mm';
    return moment(time, format).tz(zone).format(format);
}

export const toTimeZoneF4 = (time: any, zone: any) => {
    // moment().tz("America/Los_Angeles").format();
    var format = 'HH:mm';
    return moment(time, format).tz(zone).format(format);
}


export const toAddTime = (time: any, addMinutes: any) => {    
    // '2:00:00 PM', 'h:mm:ss A'
    var date = moment(time, 'h:mm:ss A')
    // .add(30, 'seconds')
    .add(addMinutes, 'minutes')
    .format('LTS');
    return date;
}



export const getClaseName = (VP_CategoriaAlerta: any) => {
    let vl_ClaseName: any = '';
    switch (VP_CategoriaAlerta) {
        case "B": vl_ClaseName = ' bg-white font-semibold p-1 w-full'; break;
        case "A": vl_ClaseName = ' bg-yellow-500 font-semibold p-1 w-full '; break;
        case "N": vl_ClaseName = ' bg-orange-600 text-white font-semibold p-1 w-full '; break;
        case "R": vl_ClaseName = ' bg-red-500 text-white font-semibold p-1 w-full '; break;
        default: vl_ClaseName = ''; break;
    }
    return vl_ClaseName;
}

export const setDateAdd = (vp_fecha: any, vp_dias: any) => {
    vp_fecha.setDate(vp_fecha.getDate() + vp_dias);
    return vp_fecha;
}
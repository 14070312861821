import FullCalendar from '@fullcalendar/react';
import { Dialog } from 'primereact/dialog';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import { useEffect, useState } from 'react';
import { fetchSinToken } from '../../../helpers/fetch';
import { Button } from 'primereact/button';
import { toAddTime, toTimeZoneF3, toTimeZoneF4 } from '../../../helpers/functions';

const AgendaFormPro = ({ ...prop }) => {
    const [dataAgenda, setDataAgenda] = useState<any>([]);
    const [vl_DateClickData, setDateClickData] = useState<any>([]);

    const [vl_PsiFullName, setPsiFullName] = useState<any>('');
    const [vl_DateSelected, setDateSelected] = useState<any>('');
    const [vl_DateSelectedEnd, setDateSelectedEnd] = useState<any>('');
    const [vl_DateSelectedSlot, setDateSelectedSlot] = useState<any>('');

    const vl_slotDuration:any = '00:40:00';
    const vl_slotMinTime:any = '07:00:00';
    
    useEffect(() => {
        setPsiFullName(prop?.vp_Staff);
        pa_get_agenda();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_agenda = async () => {
        // localStorage.getItem('authIdPerfil')
        // -- 1 ROOT
        // let vl_IdSucursal: any = localStorage.getItem('authIdSucursal');
        // if (localStorage.getItem('authIdPerfil') === '1' && tipo === '*') {
        //     vp_IdStaff = 0;
        //     vl_IdSucursal = 0;
        // }
        const param = {
            "vp_IdAgenda": 0,
            "vp_FechaYear": prop.vp_periodo,
            "vp_FechaMonth": prop.vp_mes,
            "vp_IdStaff": prop.vp_IdStaff,
            "VP_IdSucursal": prop.vp_IdSucursal
        }
        // setProgressBar(true);
        const response = await fetchSinToken('selectors/pa_get_agenda', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // setPsiFullName(record[0].PsiFullName);
        setDataAgenda(record);

    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-check" className='p-button-outlined' onClick={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                    prop.getDataCalendar(vl_DateClickData)
                }} autoFocus />
            </div>
        );
    }
    const renderHeader = () => {
        return (
            <>
                <div className="pb-1">
                    <div className="col mt-0 ml-0 font-italic h-3rem" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='text-base font-normal'> <h4>Seleccionar horario... [ {vl_DateSelected} {vl_DateSelected ? ' a ' : ''} {vl_DateSelectedEnd} {vl_DateSelectedSlot}  ]  </h4> </label><br />
                        <label className='text-base font-bold'>Agenda: Psi. {vl_PsiFullName} </label><br />
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Dialog
                header={renderHeader} style={{ minWidth: '90vw' }}
                modal={false} draggable resizable closable showHeader maximizable
                visible={prop.isOpen}
                footer={renderFooter}
                onHide={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }}
            >
                <div className="p-1">                    
                    <div className='flex justify-content-between border-yellow-500 border-top-2 pt-2 pb-2 ' >
                        <div className='flex align-items-center'>
                            
                        </div>
                        <div className='flex flex-wrap justify-content-end align-items-center font-bold '  >
                            <label className="col text-xs bg-indigo-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Programada</label>
                            <label className="col text-xs bg-purple-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Confirmada</label>
                            <label className="col text-xs bg-orange-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Reprogramada</label>
                            <label className="col text-xs bg-green-600 text-white" style={{ width: '6rem', textAlign: 'center' }}>Realizada</label>
                            <label className="col text-xs bg-cyan-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>No Realizada</label>
                        </div>
                    </div>

                    <FullCalendar
                        events={dataAgenda}
                        initialView="timeGridWeek" //dayGridWeek , timeGridWeek, listWeek, listMonth
                        locale={esLocale}
                        nowIndicator={true}
                        droppable={false}
                        slotMinTime={vl_slotMinTime}
                        slotDuration= {vl_slotDuration} // The frequency for displaying time slots. (40 minutes)
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{ left: 'prev,next,today,listMonth', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay' }}
                        defaultRangeSeparator=' - '
                        titleRangeSeparator=' al '
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short'
                        }}
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                        }} //se visualizara de esta manera 01:00 AM en la columna de horas
                        buttonText={
                            {
                                today: 'Hoy',
                                month: 'Mes',
                                week: 'Semana',
                                day: 'Dia',
                                list: 'Lista'
                            }
                        }
                        dateClick={
                            (e: any) => {
                                setDateClickData(e.date);
                                //revisar ....                                
                                // console.log(e);
                                let minutes: any = 40;
                                let FechaSel: any = e.date;
                                setDateSelected(toTimeZoneF3(FechaSel, 'America/Lima'));
                                // console.log(toAddTime('22:30', 10 ));
                                setDateSelectedEnd(toAddTime(toTimeZoneF4(FechaSel, 'America/Lima'), minutes));
                                // let diff = Math.abs(e.date - FechaSel);                                
                                // let minutes = Math.floor((diff / 1000) / 60);                                                                
                                setDateSelectedSlot('- ' + minutes + 'min.');
                            }
                        }
                        editable selectable selectMirror dayMaxEvents />
                </div>

            </Dialog>
        </>
    )
}

export default AgendaFormPro
import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { fetchSinToken } from '../../../../helpers/fetch';

import { Filter } from './Filter';
import { DatoPaciente } from './DatoPaciente';
import { OrdenPagos } from './OrdenPagos';
import { FooterGridNumber } from '../../util/FooterGrid';
import TitleForm from '../../util/TitleForm';


export const OrdenTerapiaForm = ({ ...props }) => {

    //valores INPUT Cliente/Paciente
    const [vl_IdCliente, setInputValIdCliente] = useState<any>('0');
    const [vl_Nombre, setInputValNombre] = useState('');
    const [vl_ApPaterno, setInputValApPaterno] = useState('');
    const [vl_ApMaterno, setInputValApMaterno] = useState('');
    const [vl_IdTipoDocumento, setInputValIdTipoDocumento] = useState<any>('');
    const [vl_NumeroDocumento, setInputValNumeroDocumento] = useState('');
    const [vl_Edad, setInputValEdad] = useState<number>(0);
    const [vl_Sexo, setInputValSexo] = useState<any>('');

    // Inputs Crud 
    const [vl_ACTION, setInputValACTION] = useState('I');
    const [vl_IdRegistro, setInputValIdRegistro] = useState('0');
    const [vl_IdOrdenTerapia, setInputValIdOrdenAtencion] = useState('0');
    const [vl_FechaTerapia, setInputValFechaTerapia] = useState<any>(new Date());
    const [vl_HoraTerapia, setInputValHoraTerapia] = useState<any>(new Date());
    const [vl_NumeroOrden, setInputValNumeroOrden] = useState('');
    const [vl_IdHistoriaClinica, setInputValIdHistoriaClinica] = useState('0');
    const [vl_NumeroHistoriaClinica, setInputValNumeroHistoriaClinica] = useState('');
    // const [vl_Pagado, setInputValPagado] = useState<boolean>(false);
    // const [vl_MontoPagado, setInputValMontoPagado] = useState<any>('0.00');
    // const [vl_IdFormaPago, setInputValIdFormaPago] = useState<any>('');
    // const [vl_ReferenciaPago, setInputValReferenciaPago] = useState<any>('');
    const [vl_Observaciones, setInputValObservaciones] = useState<any>('');
    const [vl_IdEspecialidad, setInputValIdEspecialidad] = useState(1);
    const [vl_IdStaff, setInputValIdStaff] = useState(1);
    const [vl_Validez, setInputValValidez] = useState<boolean>(false);
    const [vl_Dias, setInputValDias] = useState('0');
    // const [vl_Importe, setImporte] = useState<number>(0.0);
    const [vl_Importe, setImporte] = useState<any>(0.0);
    const [vl_Descuentopje, setDescuentopje] = useState<number>(0.0);
    const [vl_Descuento, setDescuento] = useState<number>(0.0);

    const [vl_TotalInversion, setInputValTotalInversion] = useState<any>(0);
    // const [vl_Cuota, setInputValCuota] = useState<boolean>(false);
    const [vl_NumeroCuotas, setInputValNumeroCuotas] = useState<any>(0);
    const [vl_ImporteCuotas, setInputValImporteCuotas] = useState<any>(0);
    // const [vl_ImporteSaldo, setInputValImporteSaldo] = useState<number>(0);
    const [vl_MontoTotalSel, setInputValMontoTotalSel] = useState<any>(0);

    const [vl_FrecuenciaAtencion, setFrecuenciaAtencion] = useState('');


    const [dataEspecialidad, setDataEspecialidad] = useState([]);
    const [dataIdStaff, setDataIdStaff] = useState([]);
    const [dataCodigoServicios, setDataCodigoServicio] = useState<any>([]);
    const [dataIdTipoDocumento_1, setDataIdTipoDocumento_1] = useState([]);
    const [dataOrdenPago, setDataOrdenPago] = useState<any>([]);

    const [selectedIemsGrid, setSelectedIemsGrid] = useState<any>([]);
    const [editingRows, setEditingRows] = useState<any>([]);

    const [activeIndex, setActiveIndex] = useState(0);
    // const [chkValEvaluacion, setChkValEvaluacion] = useState<boolean>(false);
    const [chkValInterConsulta, setChkValInterConsulta] = useState<boolean>(false);
    const [chkValPsicoterapia, setchkValPsicoterapia] = useState<boolean>(false);

    //Visible
    const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const [b_visible1, setVisibleConfirmDialog1] = useState<boolean>(false);

    const [b_hiddenGrabar, setHiddenbtnGrabar] = useState<boolean>(false);
    // const [b_hiddenEdit, setHiddenbtnEdit] = useState<boolean>(false);
    const [b_hiddenAnular, setHiddenbtnAnular] = useState<boolean>(true);

    const [deleteItemDialog, setDeleteItemDialog] = useState<boolean>(false);
    // const [reportView, setReportView] = useState<boolean>(false);


    //OverlayPanel
    // const [source, setSource] = useState<any>([]);
    const [target, setTarget] = useState<any>([]);

    //Messages
    const toast = useRef<any>(null);
    const toastConfirm = useRef<any>(null);

    const opDetalleCuota = useRef<OverlayPanel>(null);
    const oplanPago = useRef<OverlayPanel>(null);

    // Input ref        
    // const inputMontoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputNumeroCuotasRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    // const inputImporteCuotasRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    // const inputReferenciaPagoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputObservacionesRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    //
    const inputNumeroHistoriaClinicaRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputDiasRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputDescuentopjeRef = useRef<HTMLElement | HTMLInputElement | any>(null);

    //disabled INPUTs
    const [disabledNumeroHistoriaClinica, setDisabledNumeroHistoriaClinica] = useState(false);

    // const [disabledNumeroDocumento, setDisabledNumeroDocumento] = useState(false);
    // const [disabledMonto, setDisabledMonto] = useState(false);
    // const [disabledIdFormaPago, setDisabledIdFormaPago] = useState(false);
    // const [disabledReferenciaPago, setDisabledReferenciaPago] = useState(false);
    // //
    // const [disabledNumeroCuotas, setDisabledNumeroCuotas] = useState(false);
    // const [disabledImporteCuotas, setDisabledImporteCuotas] = useState(false);
    const [disabledDias, setDisabledDias] = useState(false);
    const [disabledDescuentopje, setDisabledDescuentopje] = useState(true);
    const [disabledDescuento, setDisabledDescuento] = useState(true);
    //
    const [vl_aplDescuento, setAplDescuento] = useState<boolean>(false);
    const [vl_IdTipoDocumento_1, setIdTipoDocumento_1] = useState<any>('BVT');

    // Para cuotas de pago
    const [dataSelectedItemTerapia, setDataSelectedItemTerapia] = useState<any>([]);
    const [dataCuotasAll, setDataCuotasAll] = useState<any>([]);
    const [dataCuotas, setDataCuotas] = useState<any>([]);
    const [dataCuotasDetalle, setDataCuotasDetalle] = useState<any>([]);


    // set Values
    const onIdEspecialidadChange = (e: { value: any }) => {
        setInputValIdEspecialidad(e.value);
    }
    // const onIdFormaPagoChange = (e: { value: any }) => {
    //     setInputValIdFormaPago(e.value);
    // }
    const onIdStaffChange = (e: { value: any }) => {
        setInputValIdStaff(e.value);
    }
    const onChangeSwitchValidez = (e: any) => {
        const isValidez: boolean = e.value;
        setInputValValidez(isValidez);
        setInputValDias('0');
        setDisabledDias(true);
        if (isValidez) {
            setDisabledDias(false);
            setInputValDias('7');
            setTimeout(() => {
                inputDiasRef.current?.select();
            }, 100);
        }
    }
    const onChangeSwitchAplDescuento = (e: any) => {
        const isFlag: boolean = e.value;
        setAplDescuento(isFlag);
        setDisabledDescuentopje(true);
        setDescuentopje(0.00);
        setDescuento(0.00);
        setAplicarDescuentoPje(0.00);
        setAplicarDescuentoMontoFijo(0.00);
        if (isFlag) {
            setDisabledDescuentopje(false);
            setDisabledDescuento(false);
            setTimeout(() => {
                // console.log(inputDescuentopjeRef.current);
                inputDescuentopjeRef.current.focus();
            }, 100);
        }

    }
    const setAplicarDescuentoPje = (Descuentopje: any) => {
        let v_descuento: any = 0;
        v_descuento = (Descuentopje !== 0 ? vl_Importe * Descuentopje / 100 : 0);
        // console.log('Descuento>>' + v_descuento);
        let v_Total: any = vl_Importe - v_descuento;
        // console.log('v_Total>>' + v_Total);
        setDescuento(v_descuento);
        setInputValTotalInversion(v_Total.toFixed(2)); //new se agrego fixed 

    }
    const setAplicarDescuentoMontoFijo = (Descuento: any) => {
        // console.log('vl_Importe>>' + vl_Importe);
        // console.log('Descuento>>' + Descuento);
        let v_Total: any = vl_Importe - Descuento;
        // console.log('v_Total>>' + v_Total);
        let v_descuento: any = (Descuento / vl_Importe * 100);
        setDescuentopje(v_descuento);
        setInputValTotalInversion(v_Total.toFixed(2)); //new se agrego fixed

    }
    const onChangeSwitchPagar = (e: any) => {
        const swPagar: boolean = e.value;
        // setInputValPagado(swPagar);
        // //valor: vl_Pagado recien cambia en la renderizacion 
        // // console.log(vl_Pagado);
        // setInputValMontoPagado(0);
        // setInputValIdFormaPago('');
        // //Disable for inputs pago
        // setDisabledMonto(true);
        // setDisabledIdFormaPago(true);
        // setDisabledReferenciaPago(true);

        // setCalculaImporteSaldo(vl_TotalInversion, 0, vl_Cuota);

        if (swPagar) {
            // setDisabledMonto(false);
            // setDisabledIdFormaPago(false);
            // setDisabledReferenciaPago(false);
            // setInputValMontoPagado(vl_TotalInversion);
            // setInputValIdFormaPago('CA');
            setTimeout(() => {
                // inputMontoRef.current?.focus();
                // setCalculaImporteSaldo(vl_TotalInversion, vl_TotalInversion, vl_Cuota);
            }, 100);
        }
    }

    const onChangeSwitchCuota = (e: any) => {

        const isCuota: boolean = e.value;
        // setInputValCuota(isCuota);
        // setInputValNumeroCuotas(0);
        // setInputValImporteCuotas(0);
        // setDisabledNumeroCuotas(true);
        // setInputValImporteSaldo(vl_TotalInversion);        
        // setCalculaImporteSaldo(vl_TotalInversion, vl_MontoPagado, isCuota);

        if (isCuota) {
            // setDisabledNumeroCuotas(false);
            // setInputValNumeroCuotas(1);
            // const vl_ImporteCuotas: number = setCalcularCuota(vl_TotalInversion);
            // setInputValImporteCuotas(vl_ImporteCuotas);
            inputNumeroCuotasRef.current?.select();
        }
        // else{
        //     //calcular saldo
        //     if ((vl_TotalInversion - vl_MontoPagado) == 0){
        //         console.log('no hay saldo');
        //         setInputValNumeroCuotas(0);
        //         setInputValImporteCuotas(0);
        //         setDisabledNumeroCuotas(true);
        //     }
        // }
    }

    const pa_get_tipo_servicio = async () => {
        const param = {
            "vp_Tipo": "T",
            "vp_estado": ""
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_servicio', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataCodigoServicio(record);
        // setSource(record);
    }

    const pa_get_forma_pago = async () => {
        // const param = {
        //     "vp_IdFormaPago": "",
        //     "vp_estado": ""
        // }
        // const response = await fetchSinToken('selectors/pa_get_forma_pago', param, 'POST');
        // const resp = await response.json();
        // const record = resp.data;
        // setIdDataFormaPago(record);
    }
    const pa_get_tipo_comprobante = async () => {
        const param = {
            "VP_Grupo": "V"
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_comprobante', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataIdTipoDocumento_1(record);
    }

    const pa_get_especialidad = async () => {
        //e.preventDefault();        
        const param = {
            "vp_opcion": "S", //LISTA
            "vp_IdEspecialidad": "0",
            "vp_Descripcion": "",
            "vp_Estado": "0"
        }
        const response = await fetchSinToken('selectors/pa_get_especialidad', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataEspecialidad(record);
    }

    const pa_get_staff = async () => {
        //e.preventDefault();
        const param = {
            "vp_opcion": "",
            "vp_IdStaff": "0",
            "VP_Codigo": "",
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataIdStaff(record);
    }

    // const pa_get_plan_pagos = async (IdPlanPago: any) => {
    //     // console.log('IdPlanPago:'+ IdPlanPago );        
    //     if (Number(IdPlanPago) > 0) {
    //         const param = {
    //             "vp_IdPlanPago": IdPlanPago
    //         }
    //         const response = await fetchSinToken('admision/pa_get_plan_pagos', param, 'POST');
    //         const resp = await response.json();
    //         const record = resp.data;
    //         setDataPlanPagos(record);
    //     }
    // }
    const pa_get_orden_pago = async (IdRegistro: any) => {
        // console.log('IdPlanPago:'+ IdPlanPago );        
        if (Number(IdRegistro) > 0) {
            const param = {
                "VP_IdRegistro": IdRegistro,
                "VP_IdCliente": '0',
                "VP_NumHistoriaClinica": '',
                "vp_FechaDesde": '',
                "vp_FechaHasta": '',
                "vp_EstadoPago": '',
                "vp_IdSucursal": localStorage.getItem('authIdSucursal')
            }
            const response = await fetchSinToken('admision/pa_get_orden_pago', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataOrdenPago(record);
        }
    }

    //
    // useEffect: Se ejecuta después de cada cambio y cuando este renderizado por completado
    //
    useEffect(() => {
        // console.log(props.isMode);
        setInputValACTION(props.isMode);
        // pa_get_tipo_servicio(); //no usado
        pa_get_especialidad();
        pa_get_staff();
        pa_get_forma_pago(); //no usado
        pa_get_tipo_comprobante();
        setReiniciarCalculoCuota(); //new
        //control form segun mode(crud)
        setHiddenbtnGrabar(false);
        setHiddenbtnAnular(true);
        // setHiddenbtnEdit(true);
        setDisabledNumeroHistoriaClinica(false);
        switch (props.isMode) {
            case 'I': //INSERT MODE
                setTimeout(() => {
                    inputNumeroHistoriaClinicaRef.current?.focus();
                    setHiddenbtnGrabar(false);
                }, 100);
                break;
            case 'G': //GENERAR OP 
                getRecorOrdenTerapia();
                setDisabledNumeroHistoriaClinica(true);
                // setHiddenbtnEdit(false);
                break;
            case 'A': //ANULAR MODE
                getRecorOrdenTerapia();
                setHiddenbtnGrabar(true);
                setHiddenbtnAnular(false);
                setDisabledNumeroHistoriaClinica(true);
                setTimeout(() => {
                    inputObservacionesRef.current?.select();
                }, 100);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRecorOrdenTerapia = () => {
        // console.log('getRecorOrdenTerapia');
        // console.log(props.isData);
        setInputValIdRegistro(props.isData.IdRegistro);
        setInputValIdOrdenAtencion(props.isData.IdOrdenAtencion);
        setInputValNumeroHistoriaClinica(props.isData.NumHistoriaClinica);
        setInputValIdHistoriaClinica(props.isData.IdHistoriaClinica);
        // console.log( new Date(Date.parse( '2022-01-10 17:48:54' )) );    
        // console.log(new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format( props.isData.HoraTerapia ));
        // console.log(new Date(Date.parse(props.isData.FechaTerapia )));
        setInputValFechaTerapia(new Date(Date.parse(props.isData.FechaAtencion)));
        setInputValHoraTerapia(new Date(Date.parse(props.isData.FechaAtencion_2)));
        setInputValNumeroOrden(props.isData.NumeroOrden);
        setInputValIdCliente(props.isData.IdCliente);
        //Cliente 
        setInputValNombre(props.isData.Nombres);
        setInputValApPaterno(props.isData.ApPaterno);
        setInputValApMaterno(props.isData.ApMaterno);
        setInputValIdTipoDocumento(props.isData.tipo_doc);
        setInputValNumeroDocumento(props.isData.NumeroDocumento);
        setInputValEdad(props.isData.Edad);
        setInputValSexo(props.isData.Sexo);

        setInputValIdEspecialidad(props.isData.IdEspecialidad);
        setInputValIdStaff(props.isData.IdStaff);

        setInputValValidez(true);
        // setDisabledValidez(true);
        setInputValDias(props.isData.Validez);
        if (Number(props.isData.Validez) > 0) {
            setInputValValidez(true);
            // setDisabledValidez(false);            
        }
        if (Object.keys(props.isData.Tipo).length > 0) {
            // setChkValEvaluacion(props.isData.Tipo[0].Evaluacion == '1' ? true : false);
            setChkValInterConsulta(props.isData.Tipo[0].InterConsulta === 1 ? true : false);
            setchkValPsicoterapia(props.isData.Tipo[0].Psicoterapia === 1 ? true : false);
        }
        // console.log(props.isData);
        setInputValTotalInversion(props.isData.Total);
        //Plan de cuotas ref: tb_orden_pago
        // console.log('props.isData.PagoCuotas');
        // console.log(props.isData.PagoCuotas);
        // setInputValCuota(props.isData.PagoCuotas == 1 ? true : false);
        // setInputValNumeroCuotas(props.isData.NumeroCuotas);
        // setInputValImporteCuotas(props.isData.ImporteCuotas);
        // setInputValImporteSaldo(props.isData.SaldoInicial);
        // setInputValMontoPagado(props.isData.PagoInicial);

        // setInputValPagado(false);
        // setDisabledMonto(true);
        // setDisabledIdFormaPago(true);
        // setDisabledReferenciaPago(true);
        if (props.isData.PagoInicial > 0) {
            // setInputValPagado(true);
            // setDisabledMonto(false);
            // setDisabledIdFormaPago(false);
            // setDisabledReferenciaPago(false);
        }
        //pagos  
        // setInputValMontoPagado(props.isData.PagoInicial);
        // setInputValIdFormaPago(props.isData.IdFormaPago);
        // setInputValReferenciaPago(props.isData.ReferenciaPago);

        setInputValObservaciones(props.isData.Observaciones);
        setTarget(props.isData.Procedimientos);
        setCalculaTotalInversion_view_mode(props.isData.Procedimientos);

        pa_get_orden_pago(props.isData.IdRegistro);
        setFrecuenciaAtencion(props.isData.FrecuenciaAtencion);
        // pa_get_plan_pagos(props.isData.IdPlanPago);
        // console.log('props.isData.PagoCuotas: ' + props.isData.PagoCuotas);
        // setDisabledbtnPlanPago(props.isData.PagoCuotas == 1 ? false : true);

    }

    const getTotalCuotas = () => {
        let vl_MontoTotalCuotas: any = 0;
        if (parseFloat(dataCuotasAll.length) > 0) {
            dataCuotasAll.forEach(
                function (row: any) {
                    let vl_Cuota: any = Number(row.Cuota);
                    let vl_ImporteTotal: any = Number(row.Total);
                    let vl_ImporteCuota: any = Number(row.Importe);
                    let vl_ajuste: any = 0;
                    // console.log('vl_ImporteCuota', vl_ImporteCuota);
                    // console.log('******************');
                    let vl_TltImporteCuota: any = 0;
                    for (let index = 1; index <= vl_Cuota; index++) {                        
                        vl_TltImporteCuota = parseFloat(vl_TltImporteCuota) + parseFloat(vl_ImporteCuota);
                        if (index === vl_Cuota) {
                            vl_ajuste = parseFloat(vl_ImporteTotal) - parseFloat(vl_TltImporteCuota);
                            // vl_ImporteCuota = vl_ImporteCuota + parseFloat(vl_ajuste.toFixed(2)); //no usa la cuota, se halla el total
                            // console.log('vl_ImporteCuota ajus>> ', vl_ImporteCuota);
                        }                        
                        console.log(index,'**', vl_ImporteCuota,'**', vl_ajuste.toFixed(2) )
                    }
                    vl_MontoTotalCuotas = vl_MontoTotalCuotas + vl_TltImporteCuota + parseFloat(vl_ajuste.toFixed(2)) ;
                }
            );
        }
        // console.log('vl_MontoTotalCuotas>> ', vl_MontoTotalCuotas );
        return vl_MontoTotalCuotas.toFixed(2);
    }

    // const onCellEditComplete = (e: any) => {
    //     let { rowData, newValue, field, originalEvent: event } = e;
    //     switch (field) {
    //         default:
    //             if (newValue.trim().length > 0)
    //                 rowData[field] = newValue;
    //             else
    //                 event.preventDefault();
    //             break;
    //     }
    // }

    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';
        let vl_MontoTotalCuotas: any = getTotalCuotas();
        if (vl_IdRegistro === "") {
            vl_valida = false;
            vl_message = 'Campo IdRegistro no puede ser vacio';
        } else if (vl_IdCliente === "0" || String(vl_IdCliente).trim() === "") {
            vl_valida = false;
            vl_message = 'Seleccionar un Cliente';
        } else if (Number(vl_TotalInversion) === 0 || vl_TotalInversion === undefined) {
            vl_valida = false;
            vl_message = 'Total Pago debe ser mayor a 0';
        } else if (String(vl_IdTipoDocumento_1).trim() === "") {
            vl_valida = false;
            vl_message = 'Seleccionar tipo comprobante';
        } else if (parseFloat(dataCuotasAll.length) === 0 || (!Number(dataCuotasAll.length))) {
            vl_valida = false;
            vl_message = 'Debe generar detalle de cuotas';
        } else if (Number(vl_TotalInversion) !== Number(vl_MontoTotalCuotas)) {
            console.log('vl_TotalInversion>> ' + vl_TotalInversion);
            console.log('vl_MontoTotalCuotas>> ' + vl_MontoTotalCuotas);
            vl_valida = false;
            vl_message = 'Total pago diferente a sumatoria de cuotas';
        }
        return [vl_valida, vl_message];
    }

    const handleCloseShowConfirmSave = () => {
        setVisibleConfirmDialog(false);
    }

    const handleCloseShowConfirmAnular = () => {
        setVisibleConfirmDialog1(false);
    }

    // NEW
    const setVisibleConfirmDialogGenera = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 8000 });
            return;
        }
        setVisibleConfirmDialog(true)
    }


    const onProcesarOrdenPago = async () => {
        switch (vl_ACTION) {
            case 'I': case 'U': case 'G':
                setVisibleConfirmDialog(false);
                break;
            case 'A':
                setVisibleConfirmDialog1(false);
                break;
        }

        const param = {
            VP_ACTION: vl_ACTION,
            VP_IdRegistro: vl_IdRegistro,
            VP_IdCliente: vl_IdCliente,
            VP_Importe: vl_Importe,
            VP_Descuentopje: vl_Descuentopje,
            VP_Descuento: vl_Descuento,
            VP_Total: vl_TotalInversion,
            VP_PagoCuotas: '1',//NO USADO (ES PARA OA)
            VP_NumeroCuotas: '0', //NO USADO (ES PARA OA)            
            VP_Observaciones: vl_Observaciones,
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_Procedimientos: JSON.stringify(target),
            VP_IdTipoDocumento: vl_IdTipoDocumento_1,
            VP_NumeroCuotaPago: 0, // N/A 
            VP_DetalleCuotas: JSON.stringify(dataCuotasAll) // new            
        };

        console.log(param);
        // return;
        const response = await fetchSinToken('ordenPago/pa_registra_orden_pago', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        console.log(record);
        if (record) {
            const message = record.sql_message;
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, sticky: false });
            //eventos de la pantalla anterior
            if (record.sql_error === 0) {
                props.setBuscar();
                props.setIsMounted(false);
                props.isHide();
                props.setSelectedGrid1([]); //limpiar seleccionados, para forzar al usuario volver a seleccionar nueva data
            }
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, sticky: false });
        }
        // console.log(resp)
    }

    const renderFooter = () => {
        return (
            <>
                <Button label="Grabar" hidden={b_hiddenGrabar} icon="pi pi-save" onClick={() => setVisibleConfirmDialogGenera()} />
                <Button label="Anular" hidden={b_hiddenAnular} icon="pi pi-ban" onClick={() => setVisibleConfirmDialog1(true)} />
                <Button label="Imprimir" icon="pi pi-print" className="p-button-text" onClick={() => setReportsOrdenTerapiaOP()} />
                <Button label="Cerrar" icon="pi pi-times" className="p-button-outlined p-button-danger ml-2" onClick={() => {
                    props.setIsMounted(false);
                    props.isHide()
                }} />
            </>
        );
    }

    const setCalculaTotalInversion = (p_target: any) => {
        let TotalCosto = 0;
        p_target.forEach(
            function (currentValue: any) {
                // TotalCosto = TotalCosto + (parseInt(currentValue.Cantidad) * (parseFloat(currentValue.Importe) + parseFloat(currentValue.Descuento)));
                TotalCosto = TotalCosto + ( (parseInt(currentValue.Cantidad) * parseFloat(currentValue.Importe)) + parseFloat(currentValue.Descuento) );                
            }
        );
        // console.log(TotalCosto);
        setInputValTotalInversion(TotalCosto.toFixed(2));
        setImporte(TotalCosto.toFixed(2)); //new
        // reiniciar tab: PAGO
        onChangeSwitchCuota(false);
        // setInputValNumeroCuotas(0);
        onChangeSwitchPagar(false);
        // setCalculaImporteSaldo(TotalCosto, TotalCosto, false);
        // setInputValImporteCuotas(0);
    }
    const setCalculaTotalInversion_view_mode = (p_target: any) => {
        let TotalCosto = 0;
        p_target.forEach(
            function (currentValue: any) {
                // TotalCosto = TotalCosto + (parseInt(currentValue.Cantidad) * parseFloat(currentValue.Importe));
                TotalCosto = TotalCosto + parseFloat(currentValue.Total)
            }
        );
        // setInputValMontoTotalSel(TotalCosto); **********************
        setImporte(TotalCosto); //new
    }

    // PARA EDITAR CELDAS
    const priceEditor = (options: any) => {
        return <InputNumber type="text" inputStyle={{ width: '6rem', textAlign: 'end' }}
            value={options.value} mode="decimal" minFractionDigits={2} maxFractionDigits={2}
            onValueChange={(e) => options.editorCallback(e.value)}
        />
        // return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} mode="currency" currency="USD" locale="en-US" />
    }
    const cantidadEditor = (options: any) => {
        return <InputNumber type="text" inputStyle={{ width: '4rem', textAlign: 'center' }}
            value={options.value} mode="decimal" minFractionDigits={0} maxFractionDigits={0}
            onValueChange={(e) => options.editorCallback(e.value)}
        />
        // return <InputNumber value={options.value} onValueChange={(e) => options.editorCallback(e.value)} />
    }

    const textEditor = (options: any) => {
        return <InputText type="text" style={{ width: '99%', textAlign: 'left' }} value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    // const textEditor2 = (options: any) => {
    //     return <InputText type="text" className='w-full p-inputtext-sm ' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    // }
    const priceBodyTemplate = (rowData: any) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.Importe);
    }
    const subTotalBodyTemplate = (rowData: any) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.Importe * rowData.Cantidad);
    }
    const descuentoBodyTemplate = (rowData: any) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.Descuento);
    }
    const totalBodyTemplate = (rowData: any) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.Total);
    }



    const onRowEditComplete = (e: any) => {
        //  console.log('onRowEditComplete');
        //  console.log(e);
        let editTarget = [...target];
        let { newData, index } = e;
        // calcular total
        // console.log( newData.Cantidad * newData.Importe + newData.Descuento);
        newData.Total = ( (newData.Cantidad * newData.Importe) + newData.Descuento );
        editTarget[index] = newData;
        // console.log(editTarget);        
        setTarget([...editTarget]);
        setCalculaTotalInversion(editTarget);

    }


    const gridDeleteItem = (item: any) => {
        let itemsfiltered = target.filter(function (element: any) {
            // console.log(element);
            return element.CodigoServicio ? element.CodigoServicio !== item.CodigoServicio : [];
        });
        setTarget(itemsfiltered);
        //close DIALOG CONFIRM
        setDeleteItemDialog(false);
    }



    const deleteItemDialogFooter = () => {
        // console.log( editingRows );
        return (
            <React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteItemDialog(false)} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={() => gridDeleteItem(editingRows)} />
            </React.Fragment>
        );
    }

    const setReportsOrdenTerapiaOP = async () => {

        const endpoint = 'reports/ordenTerapiaOP/?IdOrdenTerapia=' + vl_IdOrdenTerapia;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    // c u o t a s ****
    const isSelectable = (value: any, field: any) => {
        let isSelectable = true;
        // console.log(value)
        // console.log(field);      
        switch (field) {
            case 'RecordSel':
                isSelectable = (value ? value : 0) === 0;
                break;
            default:
                break;
        }
        return isSelectable;
    }

    const isRowSelectable = (event: any) => {
        const data = event.data;
        return isSelectable(data.RecordSel, 'RecordSel');
    }
    const rowClassName = (data: any) => {
        return isSelectable(data.RecordSel, 'RecordSel') ? '' : 'p-disabled bg-gray-100';
    }

    const setMontoTotalSeleccionado = (data: any) => {
        let vl_Total: any = 0;
        data.forEach(
            function (row: any) {
                if (!row.RecordSel)
                    vl_Total = vl_Total + parseFloat(row.Total);
            }
        );
        // console.log(vl_Descuento);
        vl_Total = vl_Total - vl_Descuento; // NEW
        setInputValMontoTotalSel(vl_Total);
    }

    const createId = () => {
        let index = 0;
        dataCuotasAll.forEach(function (row: any) {
            index = parseFloat(row.Id);
        });
        return index + 1;
    }

    const setCrearCuotas = (NumCuota: any) => {
        if (parseFloat(vl_MontoTotalSel) === 0) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar detalle de terapia', life: 10000 });
            return;
        }
        if (parseInt(NumCuota) === 0) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingrese número de cuotas', life: 10000 });
            return;
        }
        let vl_Importe: any = 0;
        vl_Importe = vl_MontoTotalSel / NumCuota;
        let vl_Id: any = createId();
        let array: any = {
            "Id": vl_Id,
            "Total": vl_MontoTotalSel.toFixed(2),
            "Importe": vl_Importe.toFixed(2),
            "Cuota": NumCuota
        };
        setDataCuotas(array);
        // setCrearCuotasDetalle(NumCuota, vl_Id);
        //agregar 
        dataCuotasAll.push(array);
        setDataCuotasAll([...dataCuotasAll]);
        // marcar ITEM seleccionado        
        dataSelectedItemTerapia.forEach(
            function (item: any) {
                const isItemIndex = (element: any) => element.CodigoServicio === item.CodigoServicio;
                const index: any = target.findIndex(isItemIndex);
                // console.log(target.findIndex(isItemIndex));
                target[index].RecordSel = 1;
                setTarget(target);
            }
        );
        //limpiar seleccion
        setDataSelectedItemTerapia([]);
        setInputValMontoTotalSel(0.0);
        setInputValNumeroCuotas(0);
    }

    const setReiniciarCalculoCuota = () => {
        setDataSelectedItemTerapia([]);
        setInputValMontoTotalSel(0.0);
        setInputValNumeroCuotas(0);
        setDataCuotasAll([]);
        setDataCuotas([]);
        setDataCuotasDetalle([]); //***
        target.forEach(
            function (item: any, index: any) {
                target[index].RecordSel = 0;
                setTarget(target);
            }
        );
    }

    const setCrearCuotasDetalle = (in_NumCuota: any, in_Total: any) => {
        let vl_Importe: any = 0;
        let vl_Total: any = 0;
        let vl_ajuste: any = 0;
        if (parseFloat(in_Total) === 0 || in_NumCuota === 0)
            return;

        vl_Importe = in_Total / in_NumCuota;
        vl_ajuste = 0;
        let array: any = [];
        for (let index = 1; index <= in_NumCuota; index++) {
            vl_Total = parseFloat(vl_Total) + parseFloat(vl_Importe.toFixed(2));
            if (index === in_NumCuota) {
                vl_ajuste = parseFloat(in_Total) - parseFloat(vl_Total);
                vl_Importe = vl_Importe + vl_ajuste;
            }
            array.push(
                {
                    "Total": in_Total,
                    "Importe": vl_Importe.toFixed(2),
                    "Cuota": index
                }
            );
        }
        setDataCuotasDetalle(array);
    }

    const setFormDetalleCuota1 = (e: any, data: any) => {
        // console.log(data);
        setCrearCuotasDetalle(data.Cuota, data.Total);
        opDetalleCuota.current?.toggle(e);
    }

    const renderHeader = () => {
        let vl_paciente: any = vl_ApPaterno + ' ' + vl_ApMaterno + ' ' + vl_Nombre;
        return (
            <>
                <TitleForm title={vl_NumeroOrden ? 'Orden de Terapia Nº: ' + vl_NumeroOrden : 'Orden de Terapia'} />
                {/* <div className="bg-indigo-600 p2 border-bottom-2 text-white">
                    <div className="col mt-0 ml-0 font-italic" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='mb-2 font-bold '> {vl_NumeroOrden ? 'Orden de Terapia Nº: ' + vl_NumeroOrden : 'Orden de Terapia'} </label><br />
                        <label className='pb-1 text-base font-semibold '> Fecha y hora: {props.isData.FechaAtencion_2}  </label><br />
                        <label className='pb-1 text-base font-semibold '> Paciente: {vl_paciente}, Nº HC. {vl_NumeroHistoriaClinica} </label><br />
                        <label className='pb-1 text-base font-semibold'> Nº Doc.: {vl_IdTipoDocumento}-{vl_NumeroDocumento}, Edad: {vl_Edad} años, Sexo: {vl_Sexo}  </label><br />
                    </div>
                </div> */}
            </>
        );
    }

    return (
        <Dialog
            header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
            visible={props.isOpen}
            footer={renderFooter}
            onHide={() => {
                props.setIsMounted(false);
                props.isHide();
            }}
        >
            {/* <Filter></Filter>*/}
            {/* <DatoPaciente/> */}
            <div className="flex flex-wrap card-container w-full bg-bluegray-100 ">
                <div className="flex align-items-center justify-content-center m-1">                    
                    
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '100px', textAlign: 'end' }}>Nº Orden:</label>
                    <label className='p-col-fixed mr-1 text-base font-bold '> {vl_NumeroOrden}  </label> 
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '140px', textAlign: 'end' }}>Fecha y hora:</label>
                    <label className='p-col-fixed mr-1 text-base font-bold '> {props.isData.FechaAtencion_2}  </label> 

                </div>
            </div>
            <div className="flex flex-wrap card-container w-full bg-bluegray-100 ">
                <div className="flex align-items-center justify-content-center m-1">
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '100px', textAlign: 'end' }}>Paciente:</label>
                    <label className="p-col-fixed mr-1 font-bold text-base " style={{ textAlign: 'end' }}>{vl_ApPaterno + ' ' + vl_ApMaterno + ' ' + vl_Nombre}</label>
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '80px', textAlign: 'end' }}>H.C:</label>
                    <label className="p-col-fixed mr-1 font-bold text-xl text-blue-500 " style={{ textAlign: 'end' }}>{vl_NumeroHistoriaClinica}</label>
                </div>
            </div>
            <div className="flex flex-wrap card-container w-full bg-bluegray-100 pb-2 ">
                <div className="flex align-items-center justify-content-center m-1">                    
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '100px', textAlign: 'end' }}>Nº Doc.:</label>
                    <label className="p-col-fixed mr-1 font-bold text-base " style={{ textAlign: 'end' }}>{vl_IdTipoDocumento}-{vl_NumeroDocumento}</label>
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '80px', textAlign: 'end' }}>Edad:</label>
                    <label className="p-col-fixed mr-1 font-bold text-base " style={{ textAlign: 'end' }}>{vl_Edad}</label>
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '80px', textAlign: 'end' }}>Sexo:</label>
                    <label className="p-col-fixed mr-1 font-bold text-base " style={{ textAlign: 'end' }}>{vl_Sexo}</label>
                </div>
            </div>

            <TabView className="tabview-custom"
                activeIndex={activeIndex}
                onTabChange={(e) => {
                    setActiveIndex(e.index);
                    switch (e.index) {
                        case 0: break;
                        case 1:
                            // if (vl_ACTION !== 'V')
                            // setCalculaImporteSaldo(vl_TotalInversion, vl_MontoPagado, vl_Cuota);
                            break;
                        default: break;
                    }
                }}
            >
                <TabPanel header="Crear orden pago" leftIcon="pi pi-wallet mr-2" >
                    <div className="p-field p-grid mb-1">
                        <div className="p-col">
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '140px', textAlign: 'end' }}>Especialidad:</label>
                            <Dropdown style={{ height: '35px', width: '18rem' }} filter filterBy="Descripcion"
                                value={vl_IdEspecialidad} disabled
                                options={dataEspecialidad}
                                onChange={onIdEspecialidadChange}
                                optionValue="IdEspecialidad"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />

                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Profesional:</label>
                            <Dropdown style={{ height: '35px', width: '18rem' }} filter filterBy="Descripcion"
                                value={vl_IdStaff} disabled
                                options={dataIdStaff}
                                onChange={onIdStaffChange}
                                optionValue="IdStaff"
                                optionLabel="staff"
                                placeholder="Seleccionar" />
                        </div>
                        <div className="p-col mt-1">
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold" style={{ width: '140px', textAlign: 'end' }}>Frecuencia Atenc.</label>
                            <InputText type="text" style={{ height: '30px', width: '12rem', textAlign: 'left' }} disabled
                                value={vl_FrecuenciaAtencion}
                            />

                            <label htmlFor="" className="p-col-fixed ml-2 mr-1 font-bold " style={{ textAlign: 'end' }}>Validez:</label>
                            {/* <InputSwitch checked={vl_Validez} style={{ height: '28px' }} disabled
                                //tooltip='Especifique la validez de la orden en dias'
                                onChange={(e: any) => onChangeSwitchValidez(e)}
                            /> */}
                            <InputText type="text" style={{ height: '30px', width: '5rem', textAlign: 'center' }}
                                value={vl_Dias + ' dias'} disabled
                                ref={inputDiasRef}
                                onChange={(e) => setInputValDias(String(e.currentTarget.value))}
                            />
                            {/* <label className="p-col-fixed mr-1 ml-2 font-bold" style={{ textAlign: 'end' }}>dias</label> */}



                            <Checkbox className="ml-3" inputId="cb1" disabled
                                onChange={e => setChkValInterConsulta(e.checked)}
                                checked={chkValInterConsulta}> </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label pl-1">Interconsulta</label>
                            <Checkbox className="ml-3" inputId="cb1" disabled
                                onChange={e => setchkValPsicoterapia(e.checked)}
                                checked={chkValPsicoterapia}> </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label pl-1">Psicoterapia</label>
                        </div>
                        <div className="p-col pt-1" >
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '140px', textAlign: 'end' }}>Descuento:</label>
                            <InputSwitch checked={vl_aplDescuento} style={{ height: '28px' }}
                                onChange={(e) => onChangeSwitchAplDescuento(e)}
                            />
                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>%</label>
                            <InputText type="number" style={{ height: '35px', width: '70px', textAlign: 'center' }}
                                value={vl_Descuentopje}
                                disabled={disabledDescuentopje}
                                ref={inputDescuentopjeRef}
                                onChange={(e) => setDescuentopje(Number(e.currentTarget.value))}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        setAplicarDescuentoPje(Number(e.target.value));
                                    }
                                }}
                                onBlur={(e: any) => {
                                    setAplicarDescuentoPje(Number(e.target.value));
                                }}
                            />
                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>S/.  </label>
                            <InputNumber type="text" inputStyle={{ height: '35px', width: '90px', textAlign: 'center' }}
                                value={vl_Descuento} locale="en-US"
                                disabled={disabledDescuento}
                                //ref={inputDescuentoRef} //no funciona con: InputNumber
                                onChange={(e) => setDescuento(Number(e.value))}
                                mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        // console.log(e.target.value);
                                        setAplicarDescuentoMontoFijo(Number(e.target.value))
                                    }
                                }}
                                onBlur={(e: any) => {
                                    // console.log( e.target.value);
                                    setAplicarDescuentoMontoFijo(Number(e.target.value));
                                }}
                            />
                            <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}> Total Pago: </label>
                            <InputNumber type="text" inputStyle={{ height: '35px', width: '7rem', textAlign: 'center' }}
                                inputClassName='bg-green-600 font-semibold text-white border-round' locale="en-US" prefix='S/ '
                                value={vl_TotalInversion} readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                            />
                            <label htmlFor="" className="p-col-fixed ml-2 mr-1 font-bold " style={{ textAlign: 'end' }}>Comprobante:</label>
                            <Dropdown style={{ height: '36px', width: '10rem' }}
                                // filter filterBy="Descripcion"
                                value={vl_IdTipoDocumento_1}
                                options={dataIdTipoDocumento_1}
                                onChange={(e: any) => {
                                    setIdTipoDocumento_1(e.value);
                                }}
                                optionValue="Codigo"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />
                        </div>

                        <div className="p-col pt-1 ">
                            <DataTable size="small"
                                resizableColumns
                                // footer={footer}
                                value={target ?? []}  //props.isData.Procedimientos
                                dataKey="CodigoServicio"
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                scrollHeight="230px"
                                selectionMode="single"
                                editMode="row"
                                onRowEditComplete={onRowEditComplete}
                            // selection={editingRows}
                            // onSelectionChange={e => setEditingRows(e.value)}
                            >
                                <Column rowEditor style={{ maxWidth: '70px' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                {/* <Column field="CodigoServicio" header="" body={actionBodyTemplate} style={{ maxWidth: '64px' }}  ></Column> */}
                                <Column field="Descripcion" style={{ minWidth: '8rem', maxWidth: '150px' }}
                                    bodyClassName="white-space-normal"
                                    header="Detalle Terapia"></Column>
                                <Column field="Cantidad" header="T. Sesión" style={{ maxWidth: '90px' }}
                                    editor={(options) => cantidadEditor(options)}
                                    // onCellEditComplete={onCellEditComplete}                                
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-center text-green-800 font-bold"
                                ></Column>
                                <Column field="Importe" header="Costo Ses." style={{ minWidth: '6rem', maxWidth: '110px' }} body={priceBodyTemplate}
                                    editor={(options) => priceEditor(options)}
                                    // onCellEditComplete={onCellEditComplete}
                                    headerClassName="flex justify-content-end"
                                    bodyClassName="flex justify-content-end text-green-800 font-bold"
                                ></Column>
                                <Column field="Importe" header="Importe" style={{ maxWidth: '100px' }} body={subTotalBodyTemplate}
                                    //editor={(options) => priceEditor(options)}
                                    // onCellEditComplete={onCellEditComplete}
                                    headerClassName="flex justify-content-end"
                                    bodyClassName="flex justify-content-end text-green-800 font-bold"
                                ></Column>
                                <Column field="Descuento" header="Dscto." style={{ maxWidth: '100px' }} body={descuentoBodyTemplate}
                                    editor={(options) => priceEditor(options)}
                                    // onCellEditComplete={onCellEditComplete}
                                    headerClassName="flex justify-content-end"
                                    bodyClassName="flex justify-content-end text-green-800 font-bold"
                                ></Column>

                                <Column field="Total" header="Total" style={{ maxWidth: '100px' }}
                                    body={totalBodyTemplate}
                                    headerClassName="flex justify-content-end"
                                    bodyClassName="flex justify-content-end text-green-800 font-bold"
                                ></Column>
                                <Column field="Nota" header="Comentarios"
                                    bodyClassName="white-space-normal text-sm"
                                    editor={(options) => textEditor(options)}
                                // onCellEditComplete={onCellEditComplete}
                                ></Column>
                            </DataTable>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Generar cuotas" leftIcon="pi pi-calculator mr-2">
                    <div className='card p-1' >
                        <div className="flex justify-content-between" >
                            <div className='card p-1 mr-1 bg-gray-100 w-full' >
                                <div className="p-field p-grid mb-1">
                                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}> Total: </label>
                                    <InputNumber type="text" inputStyle={{ width: '7rem', textAlign: 'right' }}
                                        inputClassName='bg-green-600 font-semibold text-white border-round' locale="en-US" prefix='S/ '
                                        value={vl_MontoTotalSel} readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                    />
                                    <label className="p-col-fixed mr-2 ml-2 " style={{ textAlign: 'end' }}>Nº Cuotas</label>
                                    <InputText type="number" style={{ width: '5rem', textAlign: 'right' }}
                                        value={vl_NumeroCuotas} min={1}
                                        onChange={(e) => setInputValNumeroCuotas(Number(e.currentTarget.value))}
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter') {
                                                setCrearCuotas(Number(e.target.value));
                                            }
                                        }}
                                        onBlur={(e: any) => {
                                            // setImporteCuotas2(Number(e.target.value));
                                        }}
                                    />

                                    <Button icon="pi pi-arrow-right" className="p-button-rounded p-button-success ml-2"
                                        title='Generar cuotas'
                                        onClick={() => {
                                            setCrearCuotas(Number(vl_NumeroCuotas));
                                        }}
                                    />
                                    <Button icon="pi pi-history" className="p-button-rounded  p-button-danger p-button-outlined ml-1"
                                        title='Reiniciar'
                                        onClick={() => {
                                            setReiniciarCalculoCuota();
                                        }}
                                    />
                                </div>
                                <div className="p-field p-grid mb-1">
                                    <DataTable size="small" resizableColumns
                                        value={target ?? []}  //props.isData.Procedimientos
                                        dataKey="CodigoServicio"
                                        responsiveLayout="scroll"
                                        columnResizeMode="fit"
                                        showGridlines
                                        stripedRows
                                        scrollable
                                        scrollHeight="230px"
                                        selectionMode="checkbox"
                                        // selectAll={true}
                                        // showSelectAll={false}
                                        // selectionAutoFocus={true}
                                        // cellSelection={true}
                                        // loading={true}
                                        rowClassName={rowClassName}
                                        isDataSelectable={isRowSelectable}
                                        selection={dataSelectedItemTerapia}
                                        onSelectionChange={(e) => {
                                            setDataSelectedItemTerapia(e.value);
                                            setMontoTotalSeleccionado(e.value);
                                        }}
                                    // selectionMode="single"                                
                                    // editMode="row"                                
                                    // onRowEditComplete={onRowEditComplete}                            
                                    >
                                        {/* <Column rowEditor style={{ maxWidth: '70px' }} bodyStyle={{ textAlign: 'center' }}></Column>                                 */}
                                        <Column selectionMode="multiple" style={{ maxWidth: '3rem' }} ></Column>
                                        <Column field='RecordSel' style={{ maxWidth: '3rem' }}
                                            body={(item: any) => {
                                                return (<i style={{ 'fontSize': '1.5em' }} className={`${item.RecordSel === 1 ? 'pi pi-check' : ''}`}></i>);
                                            }}
                                        ></Column>
                                        <Column field="Descripcion" style={{ minWidth: '12rem' }} bodyClassName="white-space-normal" header="Detalle Terapia"></Column>
                                        <Column field="Cantidad" header="Cant. Sesiones" style={{ minWidth: '5rem', maxWidth: '5rem' }}
                                            // editor={(options) => cantidadEditor(options)}
                                            // onCellEditComplete={onCellEditComplete}                                
                                            headerClassName="white-space-normal "
                                            bodyClassName="flex justify-content-center text-green-800 font-bold"
                                        ></Column>
                                        <Column field="Total" header="Total" style={{ maxWidth: '100px' }}
                                            body={totalBodyTemplate}
                                            headerClassName="flex justify-content-end"
                                            bodyClassName="flex justify-content-end text-green-800 font-bold"
                                        ></Column>
                                    </DataTable>
                                </div>
                            </div>
                            <div className="p-field p-grid">
                                <DataTable
                                    header="Detalle cuotas"
                                    size="small"
                                    value={dataCuotasAll ?? []}
                                    dataKey="Cuota"
                                    resizableColumns
                                    responsiveLayout="scroll"
                                    columnResizeMode="fit"
                                    showGridlines
                                    stripedRows
                                    scrollable
                                    selectionMode="single"
                                    scrollHeight='180px' // style={{ height: '210px' }}
                                >
                                    <Column field="Id" header="ID" style={{ minWidth: '4rem' }} bodyClassName="flex justify-content-center" headerClassName='flex justify-content-center'></Column>
                                    <Column field="Total" header="Total" style={{ minWidth: '8rem' }}
                                        headerClassName="flex justify-content-end white-space-normal"
                                        bodyClassName="flex justify-content-end font-semibold "
                                    ></Column>
                                    <Column field="Cuota" header="Nº Cuotas" style={{ minWidth: '4rem', maxWidth: '5rem' }}
                                        bodyClassName="flex justify-content-center" headerClassName='white-space-normal'></Column>
                                    <Column field="Importe" header="Importe Cuota" style={{ minWidth: '5rem', maxWidth: '8rem' }}
                                        headerClassName="flex justify-content-end white-space-normal"
                                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                    >
                                    </Column>
                                    <Column style={{ minWidth: '4rem', maxWidth: '4rem' }}
                                        headerClassName="flex justify-content-end white-space-normal"
                                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                        body={(item: any) => {
                                            // return (<label className={`${item.Crud === 'D' ? 'line-through text-red-500' : ''}`}> {item.Concepto}</label>);
                                            return <>
                                                <Button icon="pi pi-calculator" tooltip="Detalle de cuotas" className="p-button-small p-button-info"
                                                    onClick={(e: any) => {
                                                        setFormDetalleCuota1(e, item);
                                                    }}
                                                />
                                            </>
                                        }}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Orden de pago"
                    disabled={dataOrdenPago.length > 0 ? false : true}
                    leftIcon="pi pi-book mr-2">
                    <OrdenPagos
                        oplanPago={oplanPago}
                        dataOrdenPago={dataOrdenPago}
                        IdOrdenTerapia={vl_IdOrdenTerapia}
                    />
                </TabPanel>
            </TabView>

            <OverlayPanel ref={opDetalleCuota} >
                <DataTable size="small" resizableColumns
                    value={dataCuotasDetalle ?? []}
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    scrollHeight="200px"
                >
                    <Column field="Cuota" header="Nº Cuota" bodyClassName="flex justify-content-center"  ></Column>
                    <Column field="Importe" header="Importe" style={{ minWidth: '6rem' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-green-800 font-bold"
                    ></Column>
                    {/* <Column field="Nota" header="Notas" headerClassName='pi pi-file-edit' style={{ minWidth: '12rem', maxWidth: '15rem' }} bodyClassName='white-space-normal text-sm'
                        editor={(options) => textEditor2(options)} onCellEditComplete={onCellEditComplete}></Column> */}
                </DataTable>
                <Button
                    type="button"
                    icon="pi pi-times"
                    title="Cerrar"
                    onClick={() => {
                        opDetalleCuota.current?.hide()
                    }}
                    className="p-button-outlined p-button-danger p-button-rounded ml-2 mt-2"
                />
            </OverlayPanel>

            <Toast ref={toast} position="center"></Toast>
            <Toast ref={toastConfirm} position="center"></Toast>

            <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
                message={dataOrdenPago.length > 0 ? "Ya existe una orden de pago. ¿Estas seguro de reemplazar?" : "¿Generar orden de pago?"}
                header="Confirmar para continuar"
                icon="pi pi-exclamation-triangle"
                accept={onProcesarOrdenPago}
                reject={handleCloseShowConfirmSave}
            />

            <ConfirmDialog visible={b_visible1} onHide={() => setVisibleConfirmDialog1(false)}
                message="¿Anular Orden de pago?"
                header="Confirmar para continuar"
                icon="pi pi-exclamation-triangle"
                accept={onProcesarOrdenPago}
                reject={handleCloseShowConfirmAnular}
            />

            <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirmar" modal
                footer={deleteItemDialogFooter}
                onHide={() => setDeleteItemDialog(false)} >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span> ¿Estás seguro(a) de eliminar?</span>
                </div>
            </Dialog>
        </Dialog>
    )
};
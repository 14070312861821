
import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { AppContext01 } from '../../../context/AppContext';
import { fetchSinToken } from '../../../helpers/fetch';
import useModal from '../../../context/WindowsOpen';
import { Toast } from 'primereact/toast';
// import AuthUsuarioForm from '../../components/auth/AuthUsuarioForm';
import { useNavigate } from 'react-router-dom';


const AuthView = () => {
    const [vl_filter, setFilter] = useState<any>('');
    const [dataServicios, setDataServicios] = useState<any>([]);
    const [selectedGrid, setSelectedGrid] = useState<any>([]);

    const [isMode, setMode] = useState('I');
    const isMounted = useRef(false);
    const [isOpenModal, openModal, closeModal] = useModal();
    const toast = useRef<any>(null);

    // const [layout, setLayout] = useState('grid');
    // const [selectedCategory, setSelectedCategory] = useState<any>('');

    const navigate = useNavigate();

    useEffect(() => {
        handleSetTitle();
        pa_set_sys_usuario();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/Configuración/Usuarios');
    }
    const pa_set_sys_usuario = async () => {
        const param = {
            "VP_action": 'L',
            "VP_filter": vl_filter,
            "VP_IdUsuario": '0',
            "VP_UserName": '',
            "VP_Email": '',
            "VP_PassWord": '',
            "VP_Flag": '0',
            "VP_IdPerfil": '0',
            "VP_IdStaff": '0',
            "VP_IdUsuario_1": '0',
            "VP_IdSucursal": '0',
            "VP_sys_usuario_menu": '',
            "VP_sys_usuario_permiso": '',
            "VP_sys_usuario_sucursal": '',
            "VP_IdSysUsuarioPermiso": 0,
            "VP_IdMenu": 0
        }
        const response = await fetchSinToken('auth/pa_set_sys_usuario', param, 'POST');
        const resp = await response.json();

        // console.log(resp.data.errno);
        if (!resp.data.errno) {
            setDataServicios(resp.data);
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
        }

    }


    const handleClickNew = () => {
        setMode('I');
        isMounted.current = true;
        setSelectedGrid([]);
        openModal();
    }
    const handleClickEdit = () => {
        if (Object.keys(selectedGrid).length <= 0) {
            const message = 'seleccionar un item';
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
            return;
        }
        setMode('U');
        isMounted.current = true;
        openModal();
    }

    const handleSetIsMounted = (val: boolean) => {
        isMounted.current = val;
    }

    const link_form_detalle_usuario = (vl_IdUsuario: any, e: any) => {
        navigate('/Auth/' + vl_IdUsuario + '/Edit');
    }

    const link_form_close = () => {
        navigate('/WelcomePage');
    }


    const link_detalle_usuario = (item: any) => {
        // console.log(item);
        const vl_IdUsuario = item.IdUsuario;
        return (
            <>
                <Button
                    type="button"
                    className="p-button-sm p-button-link "
                    icon="pi pi-user-edit"
                    label='Editar'
                    onClick={(e) => link_form_detalle_usuario(vl_IdUsuario, e)}
                />
            </>
        );
    }

    const rowClassName = (data: any) => {
        // return isSelectable(data.Situacion, 'Situacion') ? '' : 'p-disabled bg-gray-100 line-through text-red-500';
        return data.Flag===1 ? '' : 'bg-gray-100 line-through text-red-500';
      }


    return (
        <>
            
            <Toast ref={toast} position="top-center"></Toast>

            <div className="flex justify-content-between pt-1 pl-3 ">
                <div className='md:border-solid border-red-500 shadow-1 m-2 flex align-items-center justify-content-center p-1' >
                    <label className='text-normal font-italic'> Configurar perfiles/accesos de usuarios</label>
                </div>
                <div>
                    <span className="p-buttonset">
                        <Button
                            label="Nuevo"
                            disabled
                            icon="pi pi-file"
                            onClick={() => handleClickNew()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                        <Button
                            // label="Cerrar"
                            icon="pi pi-times"
                            onClick={() => link_form_close()}
                            tooltip="Cerrar"
                            // className="p-button-rounded p-button-warning   p-button-outlined ml-2 "
                            className="p-button-sm p-button-danger p-button ml-1"
                        />
                    </span>

                </div>
            </div>

            <div className="card-container gray-container p-1 ">
                <InputText type="search" style={{ width: '50%', textAlign: 'left' }}
                    autoFocus
                    placeholder='Filtrar...'
                    value={vl_filter}
                    onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            pa_set_sys_usuario();
                        }
                    }}
                />
                <Button label=""
                    onClick={(e: any) => pa_set_sys_usuario()}
                    icon="pi pi-search"
                    tooltip='Buscar '
                    className="p-button-rounded ml-2"
                />
            </div>

            <div className="card-container gray-container">
                <DataTable size="small" resizableColumns
                    // footer={footer}
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    value={dataServicios}
                    dataKey="IdUsuario"
                    rowClassName={rowClassName}
                    selectionMode="single"
                    selection={selectedGrid}
                    onSelectionChange={(e: any) => {
                        setSelectedGrid(e.value);
                    }}
                    scrollHeight="60vh"
                    paginator
                    rows={15}
                >
                    <Column style={{ maxWidth: '6rem' }}
                        body={link_detalle_usuario} >
                    </Column>
                    <Column field="IdUsuario" header="#ID" style={{ maxWidth: '6rem' }}   ></Column>
                    <Column field="Staff" header="Staff"  ></Column>
                    <Column field="UserName" header="User" style={{ maxWidth: '12rem' }} ></Column>
                    <Column field="Perfil" header="Perfil" style={{ maxWidth: '15rem' }}  ></Column>
                    <Column field="Flag" header="Estado" style={{ maxWidth: '6rem' }} bodyClassName='flex justify-content-center'
                        body={(item: any) => {
                            return (<label title={'' + item.Flag_1} className={`${item.Flag === 1 ? ' pi pi-thumbs-up-fill ' : ' pi pi-thumbs-down-fill '}`}></label>);
                        }}
                    ></Column>
                </DataTable>
            </div>

        </>
    )
}

export default AuthView
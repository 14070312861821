import avatar from "../../../assets/img/avatars/avatar-3.jpg";

const InformacionFiliacion = ({ ...props }) => {
  // console.log(props);
  return (
    <>
      <div className="p-field p-grid pl-1 pr-1 pt-1 ">
        <div className="gray-container mb-0 ">
          <div className="flex justify-content-center hc-sidebar-user">
            <img
              src={avatar}
              className="img-fluid rounded-circle mb-3"
              alt=""
            />
          </div>
          <div className="col mt-0 ml-0 bg-indigo-800 text-white font-italic border-round h-4rem" style={{ textAlign: 'center', minWidth:'10rem', maxWidth: '18rem' }} >
            <label className='text-base font-bold' >{props.PacienteInfo?.Paciente}</label><br />
            <label className='text-base font-bold'> {props.PacienteInfo?.NumeroHistoriaClinica ? 'Nº HC. ' + props.PacienteInfo?.NumeroHistoriaClinica : ''} </label>
          </div>
        </div>

        <div className="p-grid mt-1 ml-2 mr-2 font-italic ">          
          <div className="border-round-bottom h-4rem bg-indigo-100 text-black font-bold mb-1 p-2 flex align-items-center justify-content-start">
              
            <div className="grid-col">
              <label className='text-sm font-normal pb-1' >Documento:</label><br />
              <label className='text-base' > {props.PacienteInfo?.tipo_doc ? props.PacienteInfo?.tipo_doc + '-' + props.PacienteInfo?.NumeroDocumento : ''}  </label>
            </div>
          </div>
          <div className="border-round-bottom h-4rem bg-indigo-100 text-black font-bold mb-1 p-2 flex align-items-center justify-content-start">
            <div className="grid-col">
              <label className='text-sm font-normal pb-1' >Edad:</label><br />
              <label className='text-base'> {props.PacienteInfo?.Edad ? props.PacienteInfo?.Edad + ' Años' : ''}</label>
            </div>
          </div>
          <div className="border-round-bottom h-4rem bg-indigo-100 text-black font-bold mb-1 p-2 flex align-items-center justify-content-start">
            <div className="grid-col white-space-nowrap overflow-hidden text-overflow-ellipsis ">
              <label className='text-sm font-normal pb-1' >Correo:</label><br />
              <label title={props.PacienteInfo?.InformacionContacto[0].DireccionEmail} className='text-base' > {props.PacienteInfo?.InformacionContacto[0].DireccionEmail} </label>              
            </div>
          </div>
          <div className="border-round-bottom h-4rem bg-indigo-100 text-black font-bold mb-1 p-2 flex align-items-center justify-content-start">
            <div className="grid-col">
              <label className='text-sm font-normal pb-1'> Teléfono:</label><br />
              <label className='text-base' >  {props.PacienteInfo?.InformacionContacto[0].NumeroTelefono} </label>
            </div>
          </div>
          <div className="border-round-bottom h-4rem bg-indigo-100 text-black font-bold mb-1 p-2 flex align-items-center justify-content-start">
            <div className="grid-col">
              <label className='text-sm font-normal pb-1'> Situación:</label><br />
              <label className='text-base' > {props.PacienteInfo?.Estado_00} </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InformacionFiliacion;


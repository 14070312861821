
import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AppContext01 } from '../../../context/AppContext';
import { fetchSinToken } from '../../../helpers/fetch';
import useModal from '../../../context/WindowsOpen';
import ServicioForm from '../../components/catalogo/ServicioForm';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FooterGridNumber } from '../../components/util/FooterGrid';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import '../../../assets/scss/_dataView.scss';
import { setIntlDateTimeFormat } from '../../../helpers/functions';

const CajaEgresoView = () => {
  const [VP_ExcluirFecha, setExcluirFecha] = useState<boolean>(false);
  const [VP_Fecha1, setFecha1] = useState<any>(new Date());
  const [VP_Fecha2, setFecha2] = useState<any>(new Date());
  const [VP_IdTipoDocumento, setIdTipoDocumento] = useState<any>(0);
  const [VP_NumeroDocumento, setNumeroDocumento] = useState<any>('');
  const [VP_IdSucursal, setIdSucursal] = useState<any>(0);
  const [VP_IdCliente, setIdCliente] = useState<any>(0);
  const [VP_IdCentroCosto, setIdCentroCosto] = useState<any>(0);

  const [dataEgresos, setEgresos] = useState<any>([]);
  const [selectedGrid, setSelectedGrid] = useState<any>([]);

  const [isMode, setMode] = useState('I');
  const isMounted = useRef(false);
  const [isOpenModal, openModal, closeModal] = useModal();
  const toast = useRef<any>(null);

  const navigate = useNavigate();
  useEffect(() => {
    handleSetTitle();
    pa_get_registro_egreso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/Registro de egresos');
  }

  const pa_get_registro_egreso = async () => {
    const param = {
      "VP_ExcluirFecha": 0,
      "VP_Fecha1": setIntlDateTimeFormat(VP_Fecha1),
      "VP_Fecha2": setIntlDateTimeFormat(VP_Fecha2),
      "VP_IdTipoDocumento": VP_IdTipoDocumento,
      "VP_NumeroDocumento": VP_NumeroDocumento,
      "VP_IdSucursal": VP_IdSucursal,
      "VP_IdCliente": VP_IdCliente,
      "VP_IdCentroCosto": VP_IdCentroCosto,
      "VP_IdRegistroEgreso":0
    }
    const response = await fetchSinToken('caja/pa_get_registro_egreso', param, 'POST');
    const resp = await response.json();

    // console.log(resp.data.errno);
    if (!resp.data.errno) {
      setEgresos(resp.data);
    } else {
      const message =
        'Code: ' + resp.data.code +
        'Errno: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
    }

  }


  const handleClickNew = () => {
    setMode('I');
    isMounted.current = true;
    setSelectedGrid([]);
    openModal();
  }
  const handleClickEdit = () => {
    if (Object.keys(selectedGrid).length <= 0) {
      const message = 'seleccionar un item';
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
      return;
    }
    setMode('U');
    isMounted.current = true;
    openModal();
  }

  const handleSetIsMounted = (val: boolean) => {
    isMounted.current = val;
  }

  const template_NumberCurrency = (item: any) => {
    return (
      <>
        <label className='text-color-green-400'>${item.Total}</label>
      </>
    );
  }

  const footer = () => {
    let vl_Base: any = 0;
    let vl_Total: any = 0;
    // let vl_TotalSaldo: any = 0;
    dataEgresos.forEach(
      function (currentValue: any) {
        // vl_Base = vl_Base + parseFloat(currentValue.Base);
        vl_Total = vl_Total + parseFloat(currentValue.Total);
        // vl_TotalSaldo = vl_TotalSaldo + parseFloat(currentValue.Saldo);
      }
    );
    return (
      <>
        <div className='flex flex-wrap justify-content-end' >
          {/* <FooterGridNumber Total={vl_Base} Title={'Total Base'} /> */}
          <FooterGridNumber Total={vl_Total} Title={'Total'} />
          {/* <FooterGridNumber Total={vl_TotalSaldo} Title={'Comision'} /> */}
        </div>
      </>
    );
  }

  //LINKS
  const egresoForm = () => {
    navigate('/CajaEgresosForm/0/New');
  }
  const egresoFormEdit= () => {
    if (Object.keys(selectedGrid).length <= 0) {
      const message = 'seleccionar un item';
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
      return;
    }
    // console.log(selectedGrid);
    navigate('/CajaEgresosForm/'+ selectedGrid.IdRegistroEgreso +'/Edit');    
  }


  const link_form_close = () => {
    navigate('/WelcomePage');
  }

  return (
    <>
      
      <Toast ref={toast} position="top-center"></Toast>

      <div className="flex justify-content-between p-2 border-gray-500 pt-1 border-bottom-3 border-round-bottom ">
        <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
          <div className="col mt-0 ml-0 text-base font-bold font-italic h-2rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
            <label className='pb-1'> Registro y control de gastos</label>
          </div>
        </div>
        <div>
          <span className="p-buttonset">
            <Button
              label="Nuevo"
              icon="pi pi-file"
              onClick={() => egresoForm()}
              className="p-button-sm p-button-primary p-button-raised"
            />
            <Button
              label="Editar"
              icon="pi pi-pencil"
              onClick={() => egresoFormEdit()}
              className="p-button-sm p-button-text p-button-raised"
            />
            {/* <Button
              label="Reporte"
              icon="pi pi-print"
              // onClick={() => handleClickEdit()}
              className="p-button-sm p-button-primary p-button-raised"
            /> */}
            <Button
              icon="pi pi-times"
              onClick={() => link_form_close()}
              title="Cerrar"              
              className="p-button-sm p-button-danger p-button ml-1"  
            />
          </span>

        </div>
      </div>

      <div className="card-container gray-container p-1 pt-4 ">
        {/* <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>Filtrar:</label>
        <Dropdown style={{ width: '13rem' }} value={vp_tipo}
          options={[{
            "IdCode": "E",
            "Descripcion": "Fecha gasto"
          }, {
            "IdCode": "P",
            "Descripcion": "Fecha registro"
          }
          ]}
          onChange={(e: any) => {
            setTipo(e.value);
          }}
          optionValue="IdCode"
          optionLabel="Descripcion"
          placeholder="Seleccionar" /> */}

        <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>De:</label>
        <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
          dateFormat="yy/mm/dd" showIcon={true} maxDate={new Date()}
          value={VP_Fecha1}
          onChange={(e) => setFecha1(e.value)}
        />
        <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>Al:</label>
        <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
          dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
          maxDate={new Date()}
          value={VP_Fecha2}
          onChange={(e) => setFecha2(e.value)}
        />

        {/* <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}></label>
        <InputText type="search" style={{ width: '15rem', textAlign: 'left' }}
          autoFocus
          placeholder='Buscar un gasto...'
          value={vl_filter}
          onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') {
              pa_get_registro_egreso();
            }
          }}
        /> */}
        <Button label=""
          onClick={(e: any) => pa_get_registro_egreso()}
          icon="pi pi-search"
          tooltip='Buscar '
          className="p-button-rounded ml-2"
        />
      </div>

      <div className="card-container gray-container">
        <DataTable size="small" resizableColumns
          footer={footer}
          responsiveLayout="scroll"
          columnResizeMode="fit"
          showGridlines
          stripedRows
          scrollable
          value={dataEgresos}
          dataKey="IdRegistroEgreso"
          selectionMode="single"
          selection={selectedGrid}
          onSelectionChange={e => setSelectedGrid(e.value)}
          scrollHeight="70vh"
          paginator
          rows={20}
        >
          <Column field="IdRegistroEgreso" header="#ID" style={{ maxWidth: '70px' }}   ></Column>
          {/* <Column field="Periodo" header="Periodo" style={{ maxWidth: '75px' }}  ></Column> */}
          <Column field="FechaEmision" header="Fecha Registro"  headerClassName="white-space-normal" style={{ minWidth: '8rem' }}  ></Column>
          <Column field="IdTipoDocumento_1" header="Tipo Documento"  headerClassName="white-space-normal"  ></Column>
          <Column field="NumeroDocumento" header="Nº Documento"  headerClassName="white-space-normal" ></Column>
          <Column field="Fecha" header="Fecha Documento"  headerClassName="white-space-normal" ></Column>
          <Column field="IdCentroCosto_1" header="Centro costo"  ></Column>
          <Column field="Cliente" header="Cliente/Proveedor"  ></Column>
          <Column field="Importe" header="Importe" style={{ maxWidth: '120px' }}
            headerClassName="flex justify-content-end"
            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
          // body={template_NumberCurrency}
          >
          </Column>
          <Column field="Impuesto" header="IGV" style={{ maxWidth: '120px' }}
            headerClassName="flex justify-content-end"
            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
          // body={template_NumberCurrency}
          >
          </Column>
          <Column field="Total" header="Total" style={{ maxWidth: '100px' }}
            headerClassName="flex justify-content-end"
            bodyClassName="flex justify-content-end text-green-600 font-bold"
            body={template_NumberCurrency}
          >
          </Column>

        </DataTable>
      </div>

    </>
  )
}

export default CajaEgresoView;
import React, { useEffect, useState, useRef, useContext } from 'react';

import { fetchSinToken } from '../../../helpers/fetch';
import useModal from '../../../context/WindowsOpen';

import ProcesarCierre from '../../components/caja/cierre/ProcesarCierre';
import { Toast } from 'primereact/toast';
// import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { InputNumber } from 'primereact/inputnumber';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DetalleCierreCaja } from '../../components/caja/cierre/DetalleCierreCaja';
import { setIntlDateTimeFormat } from '../../../helpers/functions';
import { AppContext01 } from '../../../context/AppContext';
import { FooterGridNumber } from '../../components/util/FooterGrid';



const CajaCierreView = () => {

  // const [dataCliente, setDataCliente] = useState<any>([]);
  const [dataCierreCaja, setDataCierreCaja] = useState<any>([]);
  const [dataCierreCajaDetalle, setDataCierreCajaDetalle] = useState<any>([]);
  const [dataSelectedOPItems, setSelectedOPItems] = useState<any>([]);

  //filter
  // const [valCheckedPend, setChecked] = useState<boolean>(true);  
  const [valFechaDesde, setvalFechaDesde] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 1 ) );
  const [valFechaHasta, setvalFechaHasta] = useState<any>(new Date());
  // const [vl_GranTotal, setGranTotal] = useState<any>(0.00);

  //flag
  const isMounted = useRef(false);
  const [isMode, setMode] = useState('I');

  //window
  const [isOpenModal, openAdmisionModal, closeAdmisionModal] = useModal();
  const o_CierreCajaDetalle = useRef<OverlayPanel>(null);

  // mensaje
  const toast = useRef<any>(null);

  useEffect(() => {
    handleSetTitle();
    pa_get_cierre_caja();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Agrega titulo a la barra de navegacion superior
  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/Caja/Cierre diario');
  }


  const pa_get_cierre_caja = async () => {
    const param = {
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      "vp_IdSucursal": localStorage.getItem('authIdSucursal')
    }
    const response = await fetchSinToken('caja/pa_get_cierre_caja', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataCierreCaja(record);
    // getGranTotalPagos(record);
  }

  const pa_get_cierre_caja_detalle = async (in_IdCaja: any, e: any) => {
    const param = {
      "vp_IdCaja": in_IdCaja
    }
    const response = await fetchSinToken('caja/pa_get_cierre_caja_detalle', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataCierreCajaDetalle(record);
    o_CierreCajaDetalle.current?.toggle(e);
  }

  const pa_get_cierre_caja_export = async () => {
    const vl_IdSucursal = localStorage.getItem('authIdSucursal');
    const endpoint = 'reports/reportecierreCajaExport/?vp_FechaDesde=' + setIntlDateTimeFormat(valFechaDesde) + '&vp_FechaHasta=' + setIntlDateTimeFormat(valFechaHasta) + '&vp_IdSucursal=' + vl_IdSucursal;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }

  const pa_get_cierre_caja_export_detalle = async () => {
    // alert('Not implemented..');
    const vl_IdSucursal = localStorage.getItem('authIdSucursal');
    const endpoint = 'caja/pa_get_excel/?vp_FechaDesde=' + setIntlDateTimeFormat(valFechaDesde) + '&vp_FechaHasta=' + setIntlDateTimeFormat(valFechaHasta) + '&vp_IdSucursal=' + vl_IdSucursal;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }
  

  // const getGranTotalPagos = (data: any) => {
  //   let vl_Total: any = 0;
  //   data.forEach(
  //     function (currentValue: any) {
  //       vl_Total = vl_Total + parseFloat(currentValue.Total);
  //     }
  //   );
  //   setGranTotal(vl_Total);
  // }

  const setFormProcesaCierre = () => {
    isMounted.current = true;
    setMode('P');
    // console.log('setFormProcesaPagos')
    openAdmisionModal();
  }
  const setIsMounted = (val: boolean) => {
    isMounted.current = val;
  }

  const ProcesarCierreNew = () => {
    return (
      <ProcesarCierre
        isOpen={isOpenModal}
        isHide={closeAdmisionModal}
        isData={dataSelectedOPItems}
        isMode={isMode}
        pa_get_cierre_caja={pa_get_cierre_caja}
        setIsMounted={setIsMounted}
      />
    );
  }

  const template_btnDetalleCierre = (item: any) => {
    const vl_IdCaja: any = item.IdCaja;
    return (
      <>
        <Button
          type="button"
          className="p-button-sm p-button-warning "
          icon="pi pi-bars"
          tooltip="Ver Detalle "
          onClick={(e) => pa_get_cierre_caja_detalle(vl_IdCaja, e)}
        />
      </>
    );
  }

  const footer = () => {
    let vl_TotalCaja: any = 0;
    dataCierreCaja.forEach(
      function (currentValue: any) {
        vl_TotalCaja = vl_TotalCaja + parseFloat(currentValue.Total);        
      }
    );

    return (
      <>
        <FooterGridNumber Total={vl_TotalCaja} Title={'Gran Total'} />
      </>

    )
  }
  return (
    <>
      <div className="card p-1 mt-0" style={{ marginTop: '.1em' }}  >
        <div className="card-container gray-container">
          <div className="block bg-gray-10 font-bold text-end p-0 mb-2 flex justify-content-end">
            {/* <Menubar model={MenubarItems} style={{ height: '60px' }} /> */}
            <span className="p-buttonset">
              <Button
                label="Cierre caja"
                icon="pi pi-credit-card"
                onClick={() => setFormProcesaCierre()}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Reporte"
                icon="pi pi-file-pdf"
                onClick={() => pa_get_cierre_caja_export()}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="R. Detallado"
                icon="pi pi-file-excel"
                onClick={() => pa_get_cierre_caja_export_detalle()}
                className="p-button-sm p-button-primary p-button-raised"
              />
            </span>
          </div>
          <div className="block bg-gray-100 filter-view font-bold text-left p-1  mb-1">
            <div className="p-field p-grid pl-1 pr-1 pt-1 pb-1 ">
              <div className="p-col">
                <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Desde:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                  maxDate={new Date()}
                  value={valFechaDesde}
                  onChange={(e) => setvalFechaDesde(e.value)}
                />
                <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>Hasta:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                  maxDate={new Date()}
                  value={valFechaHasta}
                  onChange={(e) => setvalFechaHasta(e.value)}
                />

                <Button label="Buscar"
                  onClick={(e: any) => pa_get_cierre_caja()}
                  icon="pi pi-search"
                  className="p-button ml-1 mr-4"
                />

              </div>
            </div>
          </div>
          <div className="block bg-gray-100 font-bold text-start p-1  mb-1">
            <div className="p-field p-grid mt-1 mb-1">
              <div className="p-col">
                <DataTable
                  size="small" footer={footer}
                  value={dataCierreCaja ?? []}
                  dataKey="IdCaja"
                  resizableColumns
                  responsiveLayout="scroll"
                  columnResizeMode="fit"
                  showGridlines
                  stripedRows
                  scrollable
                  scrollHeight="400px"
                  paginator
                  rows={20}
                  // selectionMode="multiple"
                  selection={dataSelectedOPItems}
                  onSelectionChange={
                    (e) => {
                      setSelectedOPItems(e.value);
                    }
                  }
                >
                  {/* <Column selectionMode="multiple" style={{ maxWidth: '40px' }} ></Column> */}
                  <Column field="" header="Detalle"
                    body={template_btnDetalleCierre}
                    style={{ maxWidth: '80px' }}
                    bodyClassName="flex justify-content-center"
                  ></Column>

                  <Column field="IdCaja" header="# Cierre" style={{ maxWidth: '80px' }} bodyClassName="flex justify-content-left" ></Column>
                  <Column field="Sucursal" header="Sucursal" style={{ maxWidth: '150px' }} bodyClassName="flex justify-content-left" ></Column>
                  <Column field="Fecha" header="Fecha" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-center" ></Column>
                  <Column field="FechaRegistro" header="Procesado" style={{ minWidth: '180px', maxWidth: '180px' }} ></Column>
                  <Column field="Cajero" header="Usuario"
                    style={{ maxWidth: '120px' }}
                    bodyClassName="flex justify-content-center"
                  ></Column>
                  <Column field="Estado" header="Estado"
                    style={{ maxWidth: '170px', minWidth: '170px' }}  ></Column>
                  <Column field="Total" header="Total"
                    // style={{ maxWidth: '90px' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end text-cyan-800 font-bold "></Column>

                </DataTable>
              </div>              
            </div>
          </div>
        </div>
      </div>

      <DetalleCierreCaja
        oCierreCajaDetalle={o_CierreCajaDetalle}
        dataCierreCajaDetalle={dataCierreCajaDetalle}
      />

      <React.Fragment>{
        isMounted.current && <ProcesarCierreNew />
      }
      </React.Fragment>
      <Toast ref={toast} position="top-center"></Toast>
    </>
  );
};

export default CajaCierreView;





import { useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputNumber } from 'primereact/inputnumber';
import { ProgressBar } from 'react-bootstrap';
import { fetchSinToken } from '../../../../helpers/fetch';
import { PlanPagos } from './PlanPagos';
import { DetallePagos } from './DetallePagos';
import { DetalleCuotas } from './DetalleCuotas';
import useModal from '../../../../context/WindowsOpen';

export const OrdenPagos = ({ ...props }) => {
    // console.log('OrdenPagos');    
    // console.log(props);
    let t_TotalInversion = 0;
    let t_TotalPagos = 0;
    let t_TotaltCuota = 0;
    let t_CuotaPagada = 0;
    let t_Saldo = 0;
    props.dataOrdenPago.forEach(
        function (row: any) {
            t_TotalInversion = t_TotalInversion + parseFloat(row.Total);
            t_TotalPagos = t_TotalPagos + parseFloat(row.Pagos);
            t_TotaltCuota = t_TotaltCuota + parseFloat(row.NumeroCuotas);
            t_Saldo = t_Saldo + parseFloat(row.Saldo);
            // if( row.EstadoPago == 'T') 
            t_CuotaPagada = t_CuotaPagada + parseFloat(row.NumeroCuotasPagado);
        }
    );
    const vl_TotalInversion = t_TotalInversion; //props.dataOrdenPago[0]?.Importe;
    const vl_TotalPagos = t_TotalPagos; //props.dataOrdenPago[0]?.Pagos;
    const vl_TotaltCuota = t_TotaltCuota; //props.dataOrdenPago[0]?.NumeroCuotas;
    const vl_CuotaPagada = t_CuotaPagada; // props.dataOrdenPago[0]?.CuotaPagada;
    const vl_Saldo = t_Saldo; //props.dataOrdenPago[0]?.Saldo;

    const [dataPlanPagos, setDataPlanPagos] = useState<any>([]);
    const [dataDetallePagos, setDataDetallePagos] = useState<any>([]);

    const o_planPago = useRef<OverlayPanel>(null);
    const o_detallePago = useRef<OverlayPanel>(null);


    const [isOpenModal, openModal, closeModal] = useModal(); //buscador
    const isMounted = useRef(false);
    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }

    const template_statusPago = (item: any) => {
        // console.log(item);
        const BtnStatusPending = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button-sm p-button-warning pl-0"
                        // icon="pi pi-minus-circle"
                        // style={{ height: '24px' }}
                        label="Pendiente"
                    />
                </>
            );
        }
        const BtnStatusPagado = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button-sm p-button-success pl-0 "
                        //icon="pi pi-check-circle"
                        //style={{ height: '24px' }}
                        label="Pagado"
                    />
                </>
            );
        }
        const BtnStatusParcial = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button-sm p-button-warning pl-0"
                        // icon="pi pi-spinner"
                        //style={{ height: '24px' }}
                        label="Parcial"
                    />
                </>
            );
        }


        return (
            <>
                {item.EstadoPago === 'P' ? <BtnStatusPending /> : item.EstadoPago === 'T' ? <BtnStatusPagado /> : <BtnStatusParcial />}
            </>
        );
    }

    const template_btnPlanPago = (item: any) => {
        const vl_IdPlanPago = item.IdPlanPago;
        return (
            <>
                <Button
                    type="button"
                    className="p-button-rounded p-button-warning "
                    icon="pi pi-calendar-plus"
                    title="Cronograma de Pagos"
                    onClick={(e) => pa_get_plan_pagos(vl_IdPlanPago, e)}
                />
            </>
        );
    }

    const template_NumberCurrency = (item: any) => {
        return (
            <>
                S/ {item.Saldo}
            </>
        );
    }
    const pa_get_plan_pagos = async (IdPlanPago: any, e: any) => {
        // console.log('IdPlanPago:'+ IdPlanPago );        
        if (Number(IdPlanPago) > 0) {
            const param = {
                "vp_IdPlanPago": IdPlanPago
            }
            const response = await fetchSinToken('admision/pa_get_plan_pagos', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataPlanPagos(record);
            // Mostrar el Overlay Popup
            o_planPago.current?.toggle(e);
        }
    }

    const pa_get_pago_detalle = async (IdOrdenPago: any, e: any) => {
        // console.log('IdOrdenPago:'+ IdOrdenPago );        
        if (Number(IdOrdenPago) > 0) {
            const param = {
                "vp_IdOrdenPago": IdOrdenPago
            }
            const response = await fetchSinToken('admision/pa_get_pago_detalle', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataDetallePagos(record);
            // Mostrar el Overlay Popup
            console.log('o_detallePago show');
            o_detallePago.current?.toggle(e);
        }
    }

    const handleClickDetalleCuota = () => {
        isMounted.current = true;
        openModal();
    }

    return (
        <>
            <div className="p-field p-grid mb-1">
                <div className="p-col mb-1 " >
                    <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Total:</label>
                    <InputNumber type="text" inputClassName='border-cyan-500 font-semibold text-gray-900 border-round '
                        inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }} value={vl_TotalInversion} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>S. Pagada:</label>
                    <InputNumber type="text" inputClassName='border-cyan-500 font-semibold text-gray-900 border-round '
                        inputStyle={{ height: '35px', width: '50px', textAlign: 'center' }} value={vl_CuotaPagada} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>de:</label>
                    <InputNumber type="text" inputClassName='border-cyan-500 font-semibold text-gray-900 border-round '
                        inputStyle={{ height: '35px', width: '50px', textAlign: 'right' }} value={vl_TotaltCuota} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>T.Pago:</label>
                    <InputNumber type="text" inputClassName='border-cyan-500 font-semibold text-gray-900 border-round '
                        inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }} value={vl_TotalPagos} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Saldo:</label>
                    <InputNumber type="text" inputClassName='border-cyan-500 font-semibold text-gray-900 border-round '
                        inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }} value={vl_Saldo} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                    />
                    <Button label="Cuotas" icon="pi pi-file-edit" className="p-button p-button-warning p-button-rounded ml-2"
                        onClick={() => {
                            handleClickDetalleCuota();
                        }} />
                </div>

                <ProgressBar min={0} now={vl_CuotaPagada} max={vl_TotaltCuota}
                    label={'Pagados ' + vl_CuotaPagada + ' de ' + vl_TotaltCuota}
                    variant={vl_CuotaPagada === vl_TotaltCuota ? 'success' : 'info'} >
                </ProgressBar>

            </div>

            <DataTable
                size="small"
                value={props.dataOrdenPago ?? []}
                dataKey="IdOrdenPago"
                resizableColumns
                responsiveLayout="scroll"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                selectionMode="single"
            >
                <Column field="IdOrdenPago" header="O/P"
                    //body={template_OrdenPagoDetalle} //no implementado x ahora
                    style={{ maxWidth: '60px' }}
                    bodyClassName="flex justify-content-center"  ></Column>
                <Column field="Fecha" header="Fecha" style={{ minWidth: '6rem' }}
                    bodyClassName="flex justify-content-center" ></Column>
                <Column field="Importe" header="Importe" style={{ minWidth: '4rem' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end "></Column>
                <Column field="Descuento" header="Dscto" style={{ minWidth: '4rem' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end "></Column>
                <Column field="Total" header="Total" style={{ minWidth: '4rem' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end "></Column>
                <Column field="NumeroCuotas" header="Cant. Sesión" style={{ maxWidth: '4rem' }}
                    headerClassName="white-space-normal "
                    bodyClassName="flex justify-content-center font-bold"
                ></Column>
                <Column field="" header="Cron. Pagos" body={template_btnPlanPago} style={{ maxWidth: '7rem' }}
                    headerClassName='white-space-normal'
                ></Column>
                <Column field="Pagos" header="Total Pagado" style={{ minWidth: '4rem' }}
                    headerClassName="white-space-normal"
                    bodyClassName="flex justify-content-center"
                    body={(item: any) => {
                        return (
                            <Button label={`$ ${item.Pagos}`} className="p-button-link pl-1" title='Ver detalle de pagos'
                                onClick={(e) => pa_get_pago_detalle(item.IdOrdenPago, e)} ></Button>
                        )
                    }}
                > </Column>
                <Column field="Saldo" header="Saldo" style={{ maxWidth: '90px' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    body={template_NumberCurrency} > </Column>
                <Column field="EstadoPago" header="Estado Pago" headerClassName='white-space-normal'
                    body={template_statusPago} style={{ minWidth: '4rem' }}  ></Column>

            </DataTable>

            <PlanPagos
                oplanPago={o_planPago}
                dataPlanPagos={dataPlanPagos}
            />
            <DetallePagos
                o_detallePago={o_detallePago}
                dataDetallePagos={dataDetallePagos}
            />

            <>
                {
                    isMounted.current && <DetalleCuotas
                        isOpen={isOpenModal}
                        isHide={closeModal}
                        setIsMounted={setIsMounted}
                        IdOrdenTerapia={props.IdOrdenTerapia}                        
                        TipoRegistro={'T'}
                        IdOrdenPago={0}
                        IdRegistro={0} 
                    />
                }
            </>

        </>
    )
}
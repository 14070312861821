import { AppRouter } from "./router/AppRouter";
import { AppProvider, AppTitleMenu, } from './context/AppProvider';
import { AppAuthProvider } from "./context/AppAuthProvider";
// import MessageForm from "./views/components/util/MessageForm";

const App = () => {
  return (
    <AppProvider>
      <AppAuthProvider>
        <AppTitleMenu>
          {/* <MessageForm/> */}
          <AppRouter />
        </AppTitleMenu>
      </AppAuthProvider>
    </AppProvider>
  )
}

export default App

import { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { AppContext01 } from '../../../../context/AppContext';
import ReporteAdmisionDetallado from './ReporteAdmisionDetallado';


const ReporteAdmisionView = () => {

    const [vl_hidden1, setHidden1] = useState<any>('');
    const [vl_hidden2, setHidden2] = useState<any>('hidden');

    const [vl_btn1, setBtn1] = useState<any>('');
    const [vl_btn2, setBtn2] = useState<any>('p-button-link');

    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/Reportes/Admisión');
    }

    useEffect(() => {
        handleSetTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setHidden = (val: any) => {

        setBtn1('p-button-link');
        setBtn2('p-button-link');

        setHidden1('hidden');
        setHidden2('hidden');

        switch (val) {
            case 1:
                setHidden1('');
                setBtn1('');
                break;
            case 2:
                setHidden2('');
                setBtn2('');
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className="flex align-items-stretch flex-nowrap p-2">
                <div className='flex flex-column bg-gray-200 border-round w-8rem  '>
                    <div className='border-bottom-1 h-4rem p-2 m-1 ' >
                        <img src="../icon/quantity.svg" width="100%"></img>
                        <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Reportes</label>
                    </div>
                    <div style={{ height: '70vh' }} className='flex flex-column p-3 '>
                        <Button icon="pi pi-list" title='Detallado' onClick={() => setHidden(1)} className={'p-button-rounded p-button-secondary ' + vl_btn1} />
                        {/* <Button icon="pi pi-chart-line" title='Resumen' onClick={() => setHidden(2)} className={'p-button-rounded p-button-secondary ' + vl_btn2} />                        */}
                    </div>
                </div>
                <ReporteAdmisionDetallado bhidden={vl_hidden1} />                
            </div>
        </>
    );
}
export default ReporteAdmisionView;
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from 'react'
import { fetchSinToken } from '../../../helpers/fetch';
import { FooterGridNumber } from '../util/FooterGrid';

const ResumenPagosTipos = ({ ...prop }) => {

    const [vl_dataPagos, setDataPagos] = useState<any>([]);
    const [vl_Total, setTotal] = useState<any>(0);    
    
    useEffect(() => {
        pa_get_pagos_dasboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.vp_periodo, prop.vp_mes, prop.vp_IdSucursal]);

    const pa_get_pagos_dasboard = async () => {
        const param = {
            "vp_opcion": 'P',
            "vp_periodo": prop.vp_periodo,
            "vp_mes": prop.vp_mes,
            "vp_IdSucursal": prop.vp_IdSucursal
        }
        const response = await fetchSinToken('dashboard/pa_get_pagos_dasboard', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataPagos(record);
    }
    // console.log(vl_dataPagos);

    let vl_TotalAG: any = 0;
    let vl_TotalCA: any = 0;
    let vl_TotalCC: any = 0;
    let vl_TotalPA: any = 0;
    let vl_TotalPL: any = 0;
    let vl_TotalTD: any = 0;
    let vl_TotalTR: any = 0;
    let vl_TotalYP: any = 0;
    let VL_DatasetLabels: any = [];
    //VERIFICAR EL LEGEND ???
    vl_dataPagos.forEach(
        function (row: any) {
            VL_DatasetLabels.push(row.Descripcion);
            if (row.IdFormaPago === 'AG') {
                vl_TotalAG = vl_TotalAG + parseFloat(row.TotalPago);                
            }
            if (row.IdFormaPago === 'CA') {
                vl_TotalCA = vl_TotalCA + parseFloat(row.TotalPago);                
            }
            if (row.IdFormaPago === 'CC') {
                vl_TotalCC = vl_TotalCC + parseFloat(row.TotalPago);                
            }
            if (row.IdFormaPago === 'PA') {
                vl_TotalPA = vl_TotalPA + parseFloat(row.TotalPago);                
            }
            if (row.IdFormaPago === 'PL') {
                vl_TotalPL = vl_TotalPL + parseFloat(row.TotalPago);                
            }
            if (row.IdFormaPago === 'TD') {
                vl_TotalTD = vl_TotalTD + parseFloat(row.TotalPago);                
            }
            if (row.IdFormaPago === 'TR') {
                vl_TotalTR = vl_TotalTR + parseFloat(row.TotalPago);                
            }
            if (row.IdFormaPago === 'YP') {
                vl_TotalYP = vl_TotalYP + parseFloat(row.TotalPago);                
            }
        }
    );
    let arraydatasets: any = [vl_TotalAG, vl_TotalCA, vl_TotalCC, vl_TotalPA, vl_TotalPL, vl_TotalTD, vl_TotalTR, vl_TotalYP];
    // console.log(VL_DatasetLabels);
    const chartData_t: any = {
        datasets: [{
            data: arraydatasets,
            backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FFA7FF",
                "#408080",
                "#8080C0",
                "#99FFFF",
                "#FF3C63"
            ],
            hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FFA7FF",
                "#408080",
                "#8080C0",
                "#99FFFF",
                "#FF3C63"
            ]
            // label: 'Tipo de cita'
        }],
        labels: ['AGENTE','EFECTIVO','TARJ.CREDITO','PAYPAL','PLIN','TARJ.DEBITO','TRANSF.','YAPE']
    };
    const [lightOptions] = useState({
        plugins: {
            title: {
                display: true,
                text: 'Tipo de Pago',
                font: {
                    size: 16
                }
            },
            legend: {
                position: 'right',
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    const footer = () => {
        let VL_TotalPago: any = 0;
        vl_dataPagos.forEach(
            function (currentValue: any) {
                VL_TotalPago = VL_TotalPago + parseFloat(currentValue.TotalPago);
            }
        );
        setTotal(VL_TotalPago);
        return (
            <>
                <div className='flex align-items-stretch justify-content-end'>
                    <FooterGridNumber Total={VL_TotalPago} Title={'Total: '} />
                </div>
            </>
        );
    }

    return (
        <>
            <div className="card p-2 m-1">
                <div className="flex flex-wrap card-container w-full">
                    <div className="flex align-items-center justify-content-center p-1 w-30 border-right-1">
                        <DataTable size="small" resizableColumns
                            footer={footer}
                            responsiveLayout="scroll"
                            columnResizeMode="fit"
                            showGridlines
                            stripedRows
                            scrollable
                            value={vl_dataPagos}
                            selectionMode="single"
                            scrollHeight="50vh"
                        >
                            <Column field="Descripcion" header="Forma Pago" style={{ minWidth: '6rem', maxWidth: '8rem' }}
                                headerClassName="flex justify-content-center"
                                bodyClassName="white-space-normal"
                            >
                            </Column>
                            <Column field="TotalPago" header="Total" style={{ maxWidth: '11rem' }}
                                headerClassName="flex justify-content-center white-space-normal"
                                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                body={(item: any) => {
                                    return <InputNumber type="text" inputClassName='border-0 text-green-600 font-semibold w-50 p-inputtext-sm' inputStyle={{ height: '30px', textAlign: 'right' }}
                                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                        locale="en-US" prefix='S/ ' readOnly value={item.TotalPago}
                                    />
                                }}
                            >
                            </Column>
                            <Column field="" header="%" style={{ minWidth: '4rem', maxWidth: '4rem' }}
                                headerClassName="flex justify-content-center"
                                bodyClassName="flex justify-content-center"
                                body={(item: any) => {
                                    let val: any = vl_Total > 0 ? ((item.TotalPago / vl_Total) * 100).toFixed(2) : 0;
                                    return (<label className='text-sm font-semibold text-red-600'>{val}</label>);
                                }}
                            >
                            </Column>
                        </DataTable>
                    </div>
                    <div className="flex align-items-center justify-content-center m-0 w-50  border-0 ">
                        <Chart type="doughnut" data={chartData_t} options={lightOptions} style={{ position: 'relative', width: '70%' }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumenPagosTipos;
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { AppContext01 } from '../../../context/AppContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import ClienteAnularForm from './ClienteAnularForm';
import useModal from '../../../context/WindowsOpen';
import { useNavigate } from 'react-router-dom';
import ClienteAlertaForm from './ClienteAlertaForm';
import { getClaseName } from '../../../helpers/functions';



const ClienteView = () => {

    const [dataClientes, setDataClientes] = useState<any>([]);
    const [dataGridSelected, setDataGridSelected] = useState<any>([]);
    const [vl_IdCliente, setIdCliente] = useState<any>(0);
    const [vl_NumeroDocumento, setNumeroDocumento] = useState<any>('');
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
    const [vl_Filter, setFilter] = useState<any>('');
    const [isOpenModal, openModal, closeModal] = useModal();
    const isMounted = useRef(false);
    const isMounted1 = useRef(false);
    const showDialogMessage = useRef<any>(null);

    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/Clientes');
    }

    useEffect(() => {
        setIdCliente(0);
        setNumeroDocumento('');
        setNumeroHistoriaClinica('');
        handleSetTitle();
        pa_get_cliente_catalogo(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_cliente_catalogo = async (P_INIT: any) => {
        const param = {
            "VP_IdCliente": vl_IdCliente,
            "VP_NumeroDocumento": vl_NumeroDocumento,
            "VP_NumeroHistoriaClinica": vl_NumeroHistoriaClinica,
            "VP_filter": vl_Filter
        }

        if (String(vl_Filter).trim().length === 8 && Number(vl_Filter)) {
            param.VP_NumeroHistoriaClinica = vl_Filter;
            param.VP_filter = '';
        }
        
        if (!P_INIT) {
            
            if (String(vl_Filter).trim().length <= 3) {
                showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar mínimo 3 caracteres para la búsqueda', life: 8000 });
                return;
            }
        }
        setDataGridSelected([]);
        const response = await fetchSinToken('catalogo/pa_get_cliente_catalogo', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataClientes(record);
    }

    const isSelectable = (value: any, field: any) => {
        let isSelectable = true;
        switch (field) {
            case 'Estado':
                isSelectable = (value ? value : 0) === 1;
                break;
            default:
                break;
        }
        return isSelectable;
    }
    const isRowSelectable = (event: any) => {
        const data = event.data;
        return isSelectable(data.Estado, 'Estado');
    }

    const rowClassName = (data: any) => {
        return isSelectable(data.Estado, 'Estado') ? '' : 'p-disabled bg-gray-100 line-through text-red-500';
    }

    const ClienteAnularFormNew = () => {
        return (
            <ClienteAnularForm
                isOpen={isOpenModal}
                isHide={closeModal}
                isData={dataGridSelected}
                isCallfn={pa_get_cliente_catalogo}
                setIsMounted={setIsMounted}
            />
        );
    }
    const handleEventClickAnular = () => {
        if (dataGridSelected.length === 0) {
            showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar un cliente', life: 8000 });
            return;
        }
        isMounted.current = true;
        openModal();
    }
    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }

    // CRUD alertas
    const ClienteAlertaFormNew = () => {
        return (
            <ClienteAlertaForm
                isOpen={isOpenModal}
                isHide={closeModal}
                isData={dataGridSelected}
                isCallfn={pa_get_cliente_catalogo}
                setIsMounted={setIsMounted1}
            />
        );
    }
    const handleEventClickAlertas = () => {
        if (dataGridSelected.length === 0) {
            showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar un cliente', life: 8000 });
            return;
        }
        isMounted1.current = true;
        openModal();
    }
    const setIsMounted1 = (val: boolean) => {
        isMounted1.current = val;
    }

    //LINKS
    const navigate = useNavigate();
    const formClientesAnulados = () => {
        navigate('/ClientesAnulados');
    }
    const link_form_close = () => {
        navigate('/WelcomePage');
    }

    return (
        <>
            <Toast ref={showDialogMessage} position="top-center"></Toast>
            <React.Fragment>{isMounted.current && <ClienteAnularFormNew />}</React.Fragment>
            <React.Fragment>{isMounted1.current && <ClienteAlertaFormNew />}</React.Fragment>

            <div className="flex justify-content-between p-2 border-gray-500 pt-1 border-bottom-2 ">
                <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
                    <div className="col mt-0 ml-0 text-base font-bold font-italic h-2rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='pb-1'>Clientes - Pacientes </label>
                    </div>
                </div>
                <div className='p-1'>
                    <span className="p-buttonset">
                        <Button
                            label="Alerta"
                            onClick={() => handleEventClickAlertas()}
                            icon="pi pi-megaphone"
                            className="p-button-raised"
                        />
                        <Button
                            label="Anular"
                            onClick={() => handleEventClickAnular()}
                            icon="pi pi-trash"
                            className="p-button-raised p-button-danger"
                        />
                        <Button
                            icon="pi pi-times"
                            onClick={() => link_form_close()}
                            title="Cerrar formulario"
                            className="p-button-sm p-button-outlined p-button-danger ml-1 "
                        />
                    </span>
                </div>
            </div>

            <div className='card w-full p-2 mt-1 ml-2' >
                <div className="flex justify-content-between w-full ">
                    <div className="flex align-items-center justify-content-start m-1">
                        <InputText type="search" style={{ width: '22rem', textAlign: 'left' }}
                            autoFocus
                            placeholder='Filtrar por nombres, #HC ..'
                            value={vl_Filter}
                            onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    pa_get_cliente_catalogo(false);
                                }
                            }}
                        />
                        <Button title="Buscar"
                            onClick={(e: any) => pa_get_cliente_catalogo(false)}
                            icon="pi pi-search"
                            tooltip='Buscar '
                            className="p-button-rounded p-button-outlined ml-2"
                        />
                    </div>
                    <div className="flex align-items-center justify-content-end m-1">
                        <Button label="Anulados"
                            onClick={(e: any) => formClientesAnulados()}
                            icon="pi pi-search"
                            title='Ver clientes anulados'
                            className="p-button-rounded p-button-outlined ml-2"
                        />
                    </div>
                </div>
                <div className="flex flex-wrap card-container m-2 w-full ">
                    <DataTable size="small" resizableColumns style={{ width: '100%' }}
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        rowClassName={rowClassName} isDataSelectable={isRowSelectable}
                        value={dataClientes}
                        scrollHeight="60vh"
                        paginator
                        rows={50}
                        dataKey="IdCliente"
                        selectionMode="single"
                        // selectionMode="checkbox"
                        selection={dataGridSelected}
                        onSelectionChange={(e) => {
                            setDataGridSelected(e.value);
                        }}
                    >
                        {/* <Column selectionMode="multiple" style={{ maxWidth: '50px' }} ></Column> */}
                        <Column field="IdCliente" header="#ID" style={{ maxWidth: '4rem' }}
                            headerClassName="flex justify-content-center"
                        ></Column>
                        <Column field="Paciente" header="Cliente" style={{ minWidth: '300px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="white-space-normal"
                            body={(item: any) => {                                
                                return (
                                    <div className='w-full' >
                                         <label className={`${item.Alerta? getClaseName(item.CategoriaAlerta) : ''}`}>{' '+item.Paciente}</label>
                                    </div>
                                );                                
                            }}
                        ></Column>
                        <Column field="NumeroHistoriaClinica" header="Nº HC."
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-center"
                        ></Column>
                        <Column field="NumeroDocumento" header="Nº Documento" headerClassName="white-space-normal" style={{ minWidth: '100px' }}></Column>
                        <Column field="FechaNacimiento" header="Fecha Nacimiento" style={{ minWidth: '8rem' }}
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-center"
                        ></Column>
                        <Column field="Edad" header="Edad" style={{ maxWidth: '5rem' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-center"
                        ></Column>
                        <Column field="Sexo" header="Genero" style={{ maxWidth: '5rem' }}
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-center"
                        ></Column>
                        <Column field="FechaRegistro1" header="Fecha Alta"
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="white-space-normal"
                        ></Column>
                        <Column field="Estado" header="Estado"
                            headerClassName="flex justify-content-center  white-space-normal "
                            bodyClassName="flex justify-content-center"
                            body={(item: any) => {
                                return (<label title={'' + item.Estado_00} className={`${item.Estado === 1 ? ' pi pi-thumbs-up-fill ' : ' pi pi-thumbs-down-fill '}`}></label>);
                            }}
                        >
                        </Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
}
export default ClienteView;
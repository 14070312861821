import { useReducer } from 'react';
import { Props } from '../types/GlobalType';
import { AuthData } from '../interfaces/AuthInterface';
import { AppAuthReducer } from './AppAuthReducer';
import { AppAuthContext } from './AppAuthContext';


const INITIAL_STATE: AuthData = {
    logged: false
    // data: []
}

export const AppAuthProvider = ({ children }: Props) => {

    const [state, dispatch] = useReducer(AppAuthReducer, INITIAL_STATE);

    const login = (state: boolean) => {
        dispatch({
            type: 'login', payload: { state }
        }
        );
    }
    const logout = (state: boolean) => {
        dispatch({
            type: 'logout', payload: { state }
        }
        );
    }
    // const setAuthData = (data:[]) => {
    //     dispatch({
    //         type: 'login', payload: { data }
    //     }
    //     );
    // }

    return (
        <AppAuthContext.Provider value={{
            authData: state,
            login,
            logout
            // setAuthData
        }}>
            {children}
        </AppAuthContext.Provider>
    )
}
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from 'react'
import { fetchSinToken } from '../../../helpers/fetch';
import { FooterGridNumber } from '../util/FooterGrid';

const ResumenPagos = ({ ...prop }) => {

    const [vl_dataPagos, setDataPagos] = useState<any>([]);
    const [vl_Total, setTotal] = useState<any>(0);

    useEffect(() => {
        pa_get_pagos_dasboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.vp_periodo, prop.vp_mes, prop.vp_IdSucursal]);

    const pa_get_pagos_dasboard = async () => {
        const param = {
            "vp_opcion": 'T',
            "vp_periodo": prop.vp_periodo,
            "vp_mes": prop.vp_mes,
            "vp_IdSucursal": prop.vp_IdSucursal
        }
        const response = await fetchSinToken('dashboard/pa_get_pagos_dasboard', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataPagos(record);
    }
    // console.log(vl_dataPagos);

    let vl_TotalC: any = 0;
    let vl_TotalA: any = 0;
    let vl_TotalT: any = 0;
    let vl_TotalP: any = 0;

    vl_dataPagos.forEach(
        function (row: any) {
            if (row.TipoRegistro === 'C') {
                vl_TotalC = vl_TotalC + parseFloat(row.TotalPago);
            }
            if (row.TipoRegistro === 'A') {
                vl_TotalA = vl_TotalA + parseFloat(row.TotalPago);
            }
            if (row.TipoRegistro === 'T') {
                vl_TotalT = vl_TotalT + parseFloat(row.TotalPago);
            }
            if (row.TipoRegistro === 'P') {
                vl_TotalP = vl_TotalP + parseFloat(row.TotalPago);
            }
        }
    );
    let arraydatasets: any = [vl_TotalC, vl_TotalA, vl_TotalT, vl_TotalP];


    const chartData_t: any = {
        datasets: [{
            data: arraydatasets, // data: [ 40, 10, 25, 3, 7],
            backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FFA7FF"
            ],
            hoverBackgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#FFCEFF"
            ]
            // label: 'Tipo de cita'
        }],
        labels: ['CONSULTA', 'ATENCION', 'TERAPIA', 'OTROS']
    };
    // console.log(chartData_t)
    const [lightOptions] = useState({
        plugins: {
            title: {
                display: true,
                text: 'Tipo Servicio',
                font: {
                    size: 16
                }
            },
            legend: {
                position: 'right',
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    const footer = () => {
        let VL_TotalPago: any = 0;
        vl_dataPagos.forEach(
            function (currentValue: any) {
                VL_TotalPago = VL_TotalPago + parseFloat(currentValue.TotalPago);
            }
        );
        setTotal(VL_TotalPago);
        return (
            <>
                <div className='flex align-items-stretch justify-content-end'>
                    <FooterGridNumber Total={VL_TotalPago} Title={'Total: '} />
                </div>
            </>
        );
    }

    return (
        <>
            <div className="card p-2 m-1">
                <div className="flex flex-wrap card-container w-full">
                    <div className="flex align-items-center justify-content-center p-1 w-30 border-right-1">
                        <DataTable size="small" resizableColumns
                            footer={footer}
                            responsiveLayout="scroll"
                            columnResizeMode="fit"
                            showGridlines
                            stripedRows
                            scrollable
                            value={vl_dataPagos}
                            selectionMode="single"
                            scrollHeight="50vh"
                        >
                            <Column field="TipoServico" header="Servicio" style={{ minWidth: '6rem', maxWidth: '6rem' }}
                                headerClassName="flex justify-content-center"
                                bodyClassName="white-space-normal"
                            >
                            </Column>
                            <Column field="TotalPago" header="Total" style={{ maxWidth: '11rem' }}
                                headerClassName="flex justify-content-center white-space-normal"
                                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                body={(item: any) => {
                                    return <InputNumber type="text" inputClassName='border-0 text-green-600 font-semibold w-50 p-inputtext-sm' inputStyle={{ height: '30px', textAlign: 'right' }}
                                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                        locale="en-US" prefix='S/ ' readOnly value={item.TotalPago}
                                    />
                                }}
                            >
                            </Column>
                            <Column field="" header="%" style={{ minWidth: '4rem', maxWidth: '4rem' }}
                                headerClassName="flex justify-content-center"
                                bodyClassName="flex justify-content-center"
                                body={(item: any) => {
                                    let val: any = vl_Total > 0 ? ((item.TotalPago / vl_Total) * 100).toFixed(2) : 0;
                                    return (<label className='text-sm font-semibold text-red-600'>{val}</label>);
                                }}
                            >
                            </Column>
                        </DataTable>
                    </div>
                    <div className="flex align-items-center justify-content-center m-0 w-50  border-0 ">
                        <Chart type="doughnut" data={chartData_t} options={lightOptions} style={{ position: 'relative', width: '70%' }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumenPagos;
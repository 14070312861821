import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/style.scss';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
// import 'primereact/resources/themes/saga-blue/theme.css'; //default 
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './assets/scss/app.scss';

library.add(fas);
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// StrictMode es una herramienta para destacar problemas potenciales en la aplicación. 
// Al igual que Fragment, StrictMode no renderiza nada en la interfaz de usuario. 
// Este modo también activa advertencias y comprobaciones adicionales para sus descendientes.
// importante! Las comprobaciones hechas por el modo estricto solamente son ejecutadas en el modo de desarrollo; no van a impactar producción.

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
); 
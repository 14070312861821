

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { AppContext01 } from '../../../context/AppContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import ClienteAnularForm from './ClienteAnularForm';
import useModal from '../../../context/WindowsOpen';
import { useNavigate } from 'react-router-dom';



const ClientesAnulados = () => {

    const [dataClientes, setDataClientes] = useState<any>([]);
    const [dataGridSelected, setDataGridSelected] = useState<any>([]);
    const [vl_IdCliente, setIdCliente] = useState<any>(0);
    const [vl_NumeroDocumento, setNumeroDocumento] = useState<any>('');
    const [vl_NumeroHistoriaClinica, setVNumeroHistoriaClinica] = useState<any>('');
    const [vl_Filter, setFilter] = useState<any>('');
    const [isOpenModal, openModal, closeModal] = useModal();
    const isMounted = useRef(false);
    const showDialogMessage = useRef<any>(null);

    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/Clientes anulados');
    }

    useEffect(() => {
        setIdCliente(0);
        handleSetTitle();
        pa_get_anulaciones(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_anulaciones = async (P_INIT: any) => {
        const param = {
            "VP_IdCliente": vl_IdCliente,
            "VP_NumeroDocumento": vl_NumeroDocumento,
            "VP_NumeroHistoriaClinica": vl_NumeroHistoriaClinica,
            "VP_filter": vl_Filter
        }
        if (!P_INIT) {
            if (String(vl_Filter).trim().length === 8 && Number(vl_Filter)) {
                param.VP_NumeroHistoriaClinica = vl_Filter;
                param.VP_filter = '';
            }
            if (String(vl_Filter).trim().length <= 3) {
                showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar mínimo 3 caracteres para la búsqueda', life: 8000 });
                return;
            }
        }
        const response = await fetchSinToken('catalogo/pa_get_anulaciones', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataClientes(record);
    }

    // const isSelectable = (value: any, field: any) => {
    //     let isSelectable = true;
    //     switch (field) {
    //         case 'Estado':
    //             isSelectable = (value ? value : 0) === 1;
    //             break;
    //         default:
    //             break;
    //     }
    //     return isSelectable;
    // }
    // const isRowSelectable = (event: any) => {
    //     const data = event.data;
    //     return isSelectable(data.Estado, 'Estado');
    // }

    // const rowClassName = (data: any) => {
    //     return isSelectable(data.Estado, 'Estado') ? '' : 'line-through text-red-500';
    // }
    
   
    //LINKS
    const navigate = useNavigate();
    const linkGoBack = () => {
        navigate('/ClientesView');
    }

    return (
        <>
            <Toast ref={showDialogMessage} position="top-center"></Toast>            
            <div className="flex justify-content-between p-2 border-gray-500 pt-1 border-bottom-2 ">
                <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
                    <div className="col mt-0 ml-0 text-base font-bold font-italic h-2rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='pb-1'>Clientes anulados </label>
                    </div>
                </div>
                <span className="p-buttonset">
                    <Button icon="pi pi-arrow-left" label='Volver'
                        className="p-button-rounded p-button-warning p-button-raised"
                        onClick={() => linkGoBack()}
                    />
                </span>
            </div>

            <div className='card w-full p-2 mt-1 ml-2' >
                <div className="flex justify-content-between w-full ">
                    <div className="flex align-items-center justify-content-start m-1">
                        <InputText type="search" style={{ width: '22rem', textAlign: 'left' }}
                            autoFocus
                            placeholder='Filtrar por nombres, #HC ..'
                            value={vl_Filter}
                            onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    pa_get_anulaciones(false);
                                }
                            }}
                        />
                        <Button title="Buscar"
                            onClick={(e: any) => pa_get_anulaciones(false)}
                            icon="pi pi-search"
                            tooltip='Buscar '
                            className="p-button-rounded p-button-outlined ml-2"
                        />
                    </div>
                    <div className="flex align-items-center justify-content-end m-1">

                    </div>
                </div>
                <div className="flex flex-wrap card-container m-2 w-full ">
                    <DataTable size="small" resizableColumns style={{ width: '100%' }}
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        // rowClassName={rowClassName} isDataSelectable={isRowSelectable}
                        value={dataClientes}
                        scrollHeight="60vh"
                        paginator
                        rows={50}
                        dataKey="IdCliente"
                        selectionMode="single"                        
                        selection={dataGridSelected}
                        onSelectionChange={(e) => {
                            setDataGridSelected(e.value);
                        }}
                    >
                        {/* <Column selectionMode="multiple" style={{ maxWidth: '50px' }} ></Column> */}
                        <Column field="ReferenciaID" header="#ID" style={{ maxWidth: '4rem' }}
                            headerClassName="flex justify-content-center"
                        ></Column>
                        <Column field="Paciente" header="Cliente" style={{ minWidth: '300px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="white-space-normal"
                        ></Column>
                        <Column field="NumeroHistoriaClinica" header="Nº HC."
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-center"
                        ></Column>

                        <Column field="IdAnulacion" header="#ID Anulación" style={{ minWidth: '8rem' }}
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-center bg-yellow-100 "
                        ></Column>
                        <Column field="Fecha" header="Fecha" style={{ minWidth: '8rem' }}
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-center bg-yellow-100"
                        ></Column>
                        <Column field="Hora" header="Hora" style={{ maxWidth: '5rem' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-center bg-yellow-100"
                        ></Column>
                        <Column field="IdMotivo" header="Motivo" style={{ maxWidth: '5rem' }}
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-center bg-yellow-100"
                        ></Column>
                        <Column field="DetalleAnulacion" header="Detalle Anulacion"
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="white-space-normal bg-yellow-100"
                        ></Column>
                        <Column field="UsuarioRegistro" header="Usuario"
                            headerClassName="flex justify-content-center  white-space-normal "
                            bodyClassName="flex justify-content-center bg-yellow-100"                            
                        >
                        </Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
}
export default ClientesAnulados;
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { setIntlDateTimeFormat, setIntlTimeFormat } from '../../../helpers/functions';
import { BlockUI } from 'primereact/blockui';
import { InputSwitch } from 'primereact/inputswitch';
import { DropdownItem } from 'reactstrap';
import { InputNumber } from 'primereact/inputnumber';
import { AppContext01 } from '../../../context/AppContext';

const StaffView = () => {

  const [VP_IdStaff, setIdStaff] = useState<any>('0');
  // const [VP_IdSucursal, setIdSucursal] = useState('0');
  const [VP_Codigo, setCodigo] = useState('');
  const [VP_Nombres, setNombres] = useState<any>('');
  const [VP_Apellidos, setApellidos] = useState<any>('');
  const [VP_ApellidoPaterno, setApellidoPaterno] = useState<any>(''); //new
  const [VP_ApellidoMaterno, setApellidoMaterno] = useState<any>(''); //new
  const [VP_FechaNacimento, setFechaNacimento] = useState<any>('');
  const [VP_EstadoCivil, setEstadoCivil] = useState<any>('1');
  const [VP_Sexo, setSexo,] = useState('M');
  const [VP_FechaIngreso, setFechaIngreso] = useState<any>(''); //new
  const [VP_IdNivelAcademico, setIdNivelAcademico] = useState<any>('0');
  const [VP_IdTituloAcademico, setIdTituloAcademico] = useState<any>('1');
  const [VP_IdDocumento, setIdDocumento] = useState<any>(1);
  const [VP_NumeroDocumento, setNumeroDocumento] = useState<any>('');
  const [VP_Remuneracion, setRemuneracion] = useState<any>('0.00');
  const [VP_TipoRemuneracion, setTipoRemuneracion] = useState<any>('M');
  //-- IN VP_Foto longblob
  const [VL_Direccion, setDireccion] = useState('');
  const [VL_InformacionContactoEmail, setInformacionContactoEmail] = useState<any>('');
  const [VL_InformacionContactoTelefono, setInformacionContactoTelefono] = useState<any>('');
  const [VP_Situacion, setSituacion] = useState<boolean>(true);
  const [VP_CreaUsuario, setCreaUsuario] = useState<boolean>(true);


  const [VP_ConfigSchedule, setConfigSchedule] = useState<any>([]);
  const [VL_IdHorario, setIdHorario] = useState<any>('0');
  const [VL_EstadoHr, setEstadoHr] = useState<boolean>(true);
  const [VL_ConfigScheduleHrinicio, setConfigScheduleHrinicio] = useState<any>(null);
  const [VL_ConfigScheduleHrfin, setConfigScheduleHrfin] = useState<any>(null);
  const [VL_ChkLun, setChkLun] = useState<boolean>(false);
  const [VL_ChkMar, setChkMar] = useState<boolean>(false);
  const [VL_ChkMie, setChkMie] = useState<boolean>(false);
  const [VL_ChkJue, setChkJue] = useState<boolean>(false);
  const [VL_ChkVie, setChkVie] = useState<boolean>(false);
  const [VL_ChkSab, setChkSab] = useState<boolean>(false);
  const [VL_ChkDom, setChkDom] = useState<boolean>(false);

  // const [VP_IdUsuario, setIdUsuario] = useState('0');
  const [VP_ConfigPagoComision, setConfigPagoComision] = useState<any>([]);
  //buscador
  const [vl_filter, setFilter] = useState<any>('');
  //ref
  const inputNombresRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputApellidosRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputApellidoPaternoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputApellidoMaternoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputFechaIngresoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputIdDocumentoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputNumeroDocumentoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputFechaNacimentoRef = useRef<HTMLElement | HTMLInputElement | any>(null);

  const inputPercentRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputHrinicioRef = useRef<HTMLElement | HTMLInputElement | any>(null);


  const [dataStaff, setDataStaff] = useState<any>([]);
  const [dataIdTipoDocumento, setDataIdTipoDocumento] = useState([]);
  const [cmbIdEstadoCivil, setCmbIdEstadoCivil] = useState([]);
  const [cmbIdGradoInstruccion, setCmbIdGradoInstruccion] = useState([]);
  const [cmbIdTituloAcademico, setCmbIdTituloAcademicon] = useState([]);
  const [cbmIdTipoPago, setCbmIdTipoPago] = useState([]);

  const toast = useRef<any>(null);

  //Visible
  const [vl_mode, setModeCrud] = useState('I');
  const [blockedPanel, setBlockedPanel] = useState<boolean>(true);
  const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
  const [b_buttonNuevodisabled, setbuttonNuevodisabled] = useState<boolean>(false);
  const [b_buttonEditardisabled, setbuttonEditardisabled] = useState<boolean>(false);
  const [b_buttonGrabardisabled, setbuttonGrabardisabled] = useState<boolean>(true);
  const [b_buttonCancelardisabled, setbuttonCancelardisabled] = useState<boolean>(true);

  //COMISIONES
  const [vl_IdPercentComision, setIdPercentComision] = useState<any>('0');
  const [vl_Percent, setPercent] = useState<any>('0.00');
  const [vl_Fijo, setFijo] = useState<any>('0.00');
  const [vl_PercentDiscount, setPercentDiscount] = useState<any>('0.00');
  const [vl_FijoDiscount, setFijoDiscount] = useState<any>('0.00');
  const [vl_Estado, setEstado] = useState<boolean>(true);
  const [vl_FechaVigenciaDesde, setFechaVigenciaDesde] = useState<any>('');
  const [vl_FechaVigenciaHasta, setFechaVigenciaHasta] = useState<any>('');
  //para importe adicional segun tipo o forma de servicio
  const [VL_ChkIndividual, setChkIndividual] = useState<boolean>(false);
  const [VL_ChkFamiliar, setChkFamiliar] = useState<boolean>(false);
  const [VL_ChkGrupal, setChkGrupal] = useState<boolean>(false);
  //montos
  const [vl_ImporteAddIndividual, setImporteAddIndividual] = useState<any>('0.00');
  const [vl_ImporteAddFamiliar, setImporteAddFamiliar] = useState<any>('0.00');
  const [vl_ImporteAddGrupal, setImporteAddGrupal] = useState<any>('0.00');

  const [vl_ImporteAdicional, setImporteAdicional] = useState<any>([]);


  //INPUTS disabled
  const [vl_Percent_disabled, setPercent_disabled] = useState<boolean>(true);
  const [vl_Fijo_disabled, setFijo_disabled] = useState<boolean>(true);
  const [vl_PercentDiscount_disabled, setPercentDiscount_disabled] = useState<boolean>(true);
  const [vl_FijoDiscount_disabled, setFijoDiscount_disabled] = useState<boolean>(true);
  const [vl_FechaVigenciaDesde_disabled, setFechaVigenciaDesde_disabled] = useState<boolean>(true);
  const [vl_FechaVigenciaHasta_disabled, setFechaVigenciaHasta_disabled] = useState<boolean>(true);
  const [b_Hrinicio_disabled, setHrinicio_disabled] = useState<boolean>(true);
  const [b_Hrfin_disabled, setHrfin_disabled] = useState<boolean>(true);
  const [vl_ImporteAddIndiviual_disabled, setImporteAddIndiviual_disabled] = useState<boolean>(false);
  const [vl_ImporteAddFamiliar_disabled, setImporteAddFamiliar_disabled] = useState<boolean>(false);
  const [vl_ImporteAddGrupal_disabled, setImporteAddGrupal_disabled] = useState<boolean>(false);

  // Agrega titulo a la barra de navegacion superior 
  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/Catálogo/Profesionales');
  }

  useEffect(() => {
    handleSetTitle();
    getDataStaff();
    pa_get_tipo_documento();
    pa_get_sys_config('EstadoCivil');
    pa_get_sys_config('GradoInstruccion');
    pa_get_sys_config('TipoPago');
    pa_get_titulo_academico();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataStaff = async () => {
    const param = {
      "VP_opcion": '',
      "VP_IdStaff": '0',
      "VP_Codigo": '',
      "VP_Nombres": vl_filter,
      "VP_Apellidos": ''

    }
    const response = await fetchSinToken('catalogo/pa_get_staff', param, 'POST');
    const resp = await response.json();

    // console.log(resp.data.errno);
    if (!resp.data.errno) {
      setDataStaff(resp.data);
    } else {
      const message =
        'Code: ' + resp.data.code +
        'Errno: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 8000 });
    }

  }
  const pa_get_tipo_documento = async () => {
    //e.preventDefault();
    const param = {
      "vp_IdTipoDocumento": "0",
      "vp_Codigo": ""
    }
    const response = await fetchSinToken('selectors/pa_get_tipo_documento', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataIdTipoDocumento(record);
  }
  const pa_get_sys_config = async (p_Identificador: any) => {
    const param = {
      "VP_Identificador": p_Identificador,
      "VP_Estado": "A"
    }
    const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    // console.log(record[0].RecordSet);
    switch (p_Identificador) {
      case 'EstadoCivil':
        setCmbIdEstadoCivil(record[0]?.RecordSet);
        break;
      case 'GradoInstruccion':
        setCmbIdGradoInstruccion(record[0]?.RecordSet);
        break;
      case 'TipoPago':
        setCbmIdTipoPago(record[0]?.RecordSet);
        break;
      default:
        break;
    }
  }
  const pa_get_titulo_academico = async () => {
    const param = {
      "vp_opcion": 0
    }
    const response = await fetchSinToken('selectors/pa_get_titulo_academico', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setCmbIdTituloAcademicon(record);
  }
  const setOnSelectionChange = (data: any) => {
    setIdStaff(data.IdStaff);
    setSituacion(data.Situacion === 'A' ? true : false);
    setCreaUsuario(data.CreaUsuario);
    setCodigo(data.Codigo);
    setNombres(data.Nombres);
    setApellidos(data.Apellidos);
    setApellidoPaterno(data.ApellidoPaterno); //new
    setApellidoMaterno(data.ApellidoMaterno);//new
    setFechaIngreso(new Date(Date.parse(data.FechaIngreso)));
    setFechaNacimento(new Date(Date.parse(data.FechaNacimento)));
    setEstadoCivil(String(data.EstadoCivil));
    setSexo(data.Sexo);
    setIdNivelAcademico(String(data.IdNivelAcademico));
    setIdTituloAcademico(data.IdTituloAcademico);
    setIdDocumento(data.IdDocumento);
    setNumeroDocumento(data.NumeroDocumento);
    setRemuneracion(data.Remuneracion);
    setTipoRemuneracion(data.TipoRemuneracion);
    // console.log(data.ConfigSchedule);
    setConfigSchedule(data.ConfigSchedule);
    setConfigPagoComision(data.ConfigCalculoComision);

    if (Object.keys(data.InformacionContacto).length > 0) {
      setInformacionContactoEmail(data.InformacionContacto[0]?.DireccionEmail);
      setInformacionContactoTelefono(data.InformacionContacto[0]?.NumeroTelefono);
    }
    if (Object.keys(data.Direccion).length > 0) {
      setDireccion(data.Direccion[0].NombreVia);
    }
    setModeCancel();
    //reinicia campos 
    setConfigPagoComisionClear();
    setConfigHorarioClear();
    //disabled campos
    setConfigPagoComisionDisabled(true);
    setConfigHorarioDisabled(true);

  }
  const onValidarCampos = () => {
    let vl_valida = true;
    let vl_message = '';

    if (String(VP_Nombres).trim() === '') {
      vl_message = 'Ingresar nombres';
      vl_valida = false;
    } else if (String(VP_ApellidoPaterno).trim() === '') {
      vl_message = 'Ingresar apellido paterno';
      vl_valida = false;
    } else if (String(VP_ApellidoMaterno).trim() === '') {
      vl_message = 'Ingresar apellido materno';
      vl_valida = false;
    } else if (String(VP_IdDocumento).trim() === '') {
      vl_message = 'Seleccionar tipo de documento';
      vl_valida = false;
    } else if (String(VP_NumeroDocumento).trim() === '') {
      vl_message = 'Ingresar numero de documento';
      vl_valida = false;
    } else if (String(VP_Sexo).trim() === '') {
      vl_message = 'Ingresar el sexo';
      vl_valida = false;
    } else if (String(VP_IdTituloAcademico).trim() === '') {
      vl_message = 'Seleccionar un titúlo acádemico';
      vl_valida = false;
    } else if (String(VP_IdNivelAcademico).trim() === '') {
      vl_message = 'Seleccionar un nivel academico';
      vl_valida = false;
    }

    return [vl_valida, vl_message];
  }

  const handleOnSubmitform = async () => {

    switch (vl_mode) {
      case 'I': case 'U':
        setVisibleConfirmDialog(false);
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
          toast.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 8000 });
          return;
        }
        break;
      default:
        break;
    }

    const jsonDir: any = [];
    jsonDir.push({
      // 'Ubigeo': vl_DireccionDistrito,
      // 'TipoVia': String(vl_DireccionTipovia).trim(),
      'NombreVia': String(VL_Direccion).trim(),
      // 'Numero': String(vl_DireccionNumero).trim(),
      // 'Manzana': String(vl_DireccionManzana).trim(),
      // 'Lote': String(vl_DireccionLote).trim(),
      // 'Dpto': String(vl_DireccionDpto).trim(),
      // 'Urbanizacion': String(vl_DireccionUrbanizacion).trim()
    });

    const VP_InformacionContacto: any = [];
    VP_InformacionContacto.push({
      'DireccionEmail': VL_InformacionContactoEmail,
      'NumeroTelefono': VL_InformacionContactoTelefono
    });

    const param = {
      VP_ACTION: vl_mode,
      VP_IdStaff: VP_IdStaff,
      VP_IdSucursal: localStorage.getItem('authIdSucursal'),
      VP_Codigo: VP_Codigo,
      VP_Nombres: VP_Nombres,
      VP_Apellidos: VP_Apellidos, //no usado
      VP_ApellidoPaterno: VP_ApellidoPaterno,
      VP_ApellidoMaterno: VP_ApellidoMaterno,
      VP_FechaIngreso: setIntlDateTimeFormat(VP_FechaIngreso),
      VP_FechaNacimento: setIntlDateTimeFormat(VP_FechaNacimento),
      VP_EstadoCivil: VP_EstadoCivil,
      VP_Sexo: VP_Sexo,
      VP_IdNivelAcademico: VP_IdNivelAcademico,
      VP_IdTituloAcademico: VP_IdTituloAcademico,
      VP_IdDocumento: VP_IdDocumento,
      VP_NumeroDocumento: VP_NumeroDocumento,
      VP_Remuneracion: VP_Remuneracion,
      VP_TipoRemuneracion: VP_TipoRemuneracion,
      VP_FotoUrl: '',
      VP_Direccion: JSON.stringify(jsonDir),
      VP_InformacionContacto: JSON.stringify(VP_InformacionContacto),
      VP_Situacion: VP_Situacion ? 'A' : 'C',
      VP_ConfigSchedule: JSON.stringify(VP_ConfigSchedule),
      VP_IdUsuario: localStorage.getItem('authIdUsuario'),
      VP_ConfigPagoComision: JSON.stringify(VP_ConfigPagoComision),
      VP_CreaUsuario: VP_CreaUsuario ? 1 : 0
    };

    console.log(param);
    // return;

    const response = await fetchSinToken('catalogo/pa_set_staff', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    if (record) {
      //console.log(record);
      const message = record.sql_message;
      toast.current.show({ severity: 'info', summary: 'Message', detail: message, life: 3000 });
      //eventos de la pantalla anterior
      setModeCancel();
      getDataStaff();

    } else {
      const message =
        'Code: ' + resp.data.code + '<br>' +
        'Errno: ' + resp.data.errno + '<br>' +
        'Sql: ' + resp.data.sql + '<br>' +
        'SqlState: ' + resp.data.sqlState + '<br>' +
        'SqlMessage: ' + resp.data.sqlMessage + '<br>';
      toast.current.show({ severity: 'error', summary: 'Error Message', detail: message, life: 3000 });
    }
    // console.log(resp)
  }
  const handleOnSubmitformReject = () => {
    setVisibleConfirmDialog(false);
  }
  const setModeEdit = () => {
    // console.log(VP_IdStaff);
    if (parseFloat(VP_IdStaff) === 0) {
      toast.current.show({ severity: 'info', detail: 'Seleccionar un registro', life: 6000 });
      return;
    }
    setBlockedPanel(false);
    setModeCrud('U');
    setbuttonNuevodisabled(false);
    setbuttonGrabardisabled(false);
    setbuttonEditardisabled(true);
    setbuttonCancelardisabled(false);
    setTimeout(() => {
      inputNombresRef.current?.focus();
    }, 200);
  }
  const setModeNew = () => {
    setModeCrud('I');
    setBlockedPanel(false);
    setbuttonNuevodisabled(true);
    setbuttonGrabardisabled(false);
    setbuttonEditardisabled(true);
    setbuttonCancelardisabled(false);
    setFormClear();
    setTimeout(() => {
      inputNombresRef.current?.focus();
    }, 200);
  }

  const setModeCancel = () => {
    setModeCrud('V');
    setBlockedPanel(true);
    setbuttonNuevodisabled(false);
    setbuttonGrabardisabled(true);
    setbuttonEditardisabled(false);
    setbuttonCancelardisabled(true);
  }

  const setFormClear = () => {
    setIdStaff(0);
    setSituacion(true);
    setCreaUsuario(true);
    setCodigo('');
    setNombres('');
    setApellidos('');
    setApellidoPaterno('');
    setApellidoMaterno('');
    setFechaIngreso(new Date(Date.parse('1990-01-01')));
    setFechaNacimento(new Date(Date.parse('1990-01-01')));
    setEstadoCivil('01');
    setSexo('M');
    setIdNivelAcademico('00');
    setIdTituloAcademico(0);
    setIdDocumento(1);
    setNumeroDocumento('');
    setRemuneracion('0.00');
    setTipoRemuneracion('');
    // console.log(data);
    setConfigSchedule([]);
    setConfigPagoComision([]);
    setInformacionContactoEmail('');
    setInformacionContactoTelefono('');
    setDireccion('');

    //Campos CRUD grid Comisiones
    setConfigPagoComisionClear();
    setConfigPagoComisionDisabled(false);
    //Campos CRUD grid Horario ..falta
    setConfigHorarioClear();
    setConfigHorarioDisabled(false);
  }

  const gridStaffTemplateStatus = (data: any) => {
    const vl_status_template: any = data.Situacion === 'A' ? 'p-button-success' : 'p-button-danger';
    const vl_icon_template: any = data.Situacion === 'A' ? 'pi pi-check' : 'pi pi-times';
    return (
      <Button icon={`${vl_icon_template}`} className={`p-button-sm p-button-outlined ${vl_status_template}`} />
    );
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++
  // CRUD grid config comisiones
  // ++++++++++++++++++++++++++++++++++++++++++++++
  const setConfigPagoComisionNew = () => {
    //console.log('new');
    setConfigPagoComisionClear();
    setConfigPagoComisionDisabled(false);
    setTimeout(() => {
      inputPercentRef.current?.select();
    }, 200);
  }

  const setConfigPagoComisionClear = () => {
    setIdPercentComision(0);
    setPercent('0.00');
    setFijo('0.00');
    setPercentDiscount('0.00');
    setFijoDiscount('0.00');
    setEstado(true);
    setFechaVigenciaDesde(new Date());
    setFechaVigenciaHasta(new Date());
    setImporteAdicional([]); //json

  }

  const setConfigPagoComisionEdit = () => {
    if (vl_IdPercentComision === 0) {
      toast.current.show({ severity: 'info', detail: 'Seleccionar un registro', life: 6000 });
      return;
    }
    setConfigPagoComisionDisabled(false);
  }

  const setConfigPagoComisionDisabled = (val: boolean) => {
    setPercent_disabled(val);
    setFijo_disabled(val);
    setPercentDiscount_disabled(val);
    setFijoDiscount_disabled(val);
    setFechaVigenciaDesde_disabled(val);
    setFechaVigenciaHasta_disabled(val);
    // -- **
    setImporteAddIndiviual_disabled(val);
    setImporteAddFamiliar_disabled(val);
    setImporteAddGrupal_disabled(val);

  }

  const setConfigPagoComisionSeleccionado = (data: any) => {
    // console.log(data);
    setIdPercentComision(data.IdPercentComision);
    setPercent(data.Percent);
    setFijo(data.Fijo);
    setPercentDiscount(data.PercentDiscount);
    setFijoDiscount(data.FijoDiscount);
    setEstado(data.Estado);
    setFechaVigenciaDesde(new Date(Date.parse(data.FechaVigenciaDesde)));
    setFechaVigenciaHasta(new Date(Date.parse(data.FechaVigenciaHasta)));

    setChkIndividual(data.ImporteAdicional[0]?.ChkIndividual);
    setChkFamiliar(data.ImporteAdicional[0]?.ChkFamiliar);
    setChkGrupal(data.ImporteAdicional[0]?.ChkGrupal);
    //valores
    setImporteAddIndividual(data.ImporteAdicional[0]?.ImporteAddIndividual);
    setImporteAddFamiliar(data.ImporteAdicional[0]?.ImporteAddFamiliar);
    setImporteAddGrupal(data.ImporteAdicional[0]?.ImporteAddGrupal);

  }

  const setConfigPagoComisionAdd = () => {
    let emptyProduct1 = {
      IdPercentComision: 0,
      IdStaff: VP_IdStaff,
      Percent: '0.0',
      Fijo: '0.0',
      PercentDiscount: '0.00',
      FijoDiscount: '0.00',
      Estado: true,
      FechaVigenciaDesde: '',
      FechaVigenciaHasta: '',
      ImporteAdicional: []
    };
    let vl_ImporteAdicional_1: any = [];
    vl_ImporteAdicional_1.push({
      "ChkIndividual": VL_ChkIndividual,
      "ImporteAddIndividual": vl_ImporteAddIndividual,
      "ChkFamiliar": VL_ChkFamiliar,
      "ImporteAddFamiliar": vl_ImporteAddFamiliar,
      "ChkGrupal": VL_ChkGrupal,
      "ImporteAddGrupal": vl_ImporteAddGrupal
    });

    emptyProduct1 = {
      IdPercentComision: vl_IdPercentComision,
      IdStaff: VP_IdStaff,
      Percent: vl_Percent,
      Fijo: vl_Fijo,
      PercentDiscount: vl_PercentDiscount,
      FijoDiscount: vl_FijoDiscount,
      Estado: vl_Estado,
      FechaVigenciaDesde: setIntlDateTimeFormat(vl_FechaVigenciaDesde),
      FechaVigenciaHasta: setIntlDateTimeFormat(vl_FechaVigenciaHasta),
      ImporteAdicional: vl_ImporteAdicional_1
    }

    if (parseFloat(vl_Percent) === 0 && parseFloat(vl_Fijo) === 0) {
      toast.current.show({ severity: 'info', detail: 'Ingresar % o un importe fijo de comisión ', life: 6000 });
      return;
    }

    if (parseFloat(vl_Percent) !== 0 && parseFloat(vl_Fijo) !== 0) {
      toast.current.show({ severity: 'info', detail: 'No puede ingresar(ambos) un % y un importe fijo de comisión ', life: 6000 });
      return;
    }

    if (emptyProduct1.IdStaff !== '0') {
      let _products = [...VP_ConfigPagoComision];
      let _product = { ...emptyProduct1 };
      if (vl_IdPercentComision > 0) {
        const index = findIndexById(emptyProduct1.IdPercentComision);
        _products[index] = _product;
      }
      else {
        _product.IdPercentComision = createId();
        _product.IdStaff = emptyProduct1.IdStaff;
        _product.Percent = emptyProduct1.Percent;
        _product.Fijo = emptyProduct1.Fijo;
        _product.PercentDiscount = emptyProduct1.PercentDiscount;
        _product.Estado = emptyProduct1.Estado;
        _product.FechaVigenciaDesde = emptyProduct1.FechaVigenciaDesde;
        _product.FechaVigenciaHasta = emptyProduct1.FechaVigenciaHasta;
        _product.ImporteAdicional = emptyProduct1.ImporteAdicional;
        _products.push(_product);
      }
      setConfigPagoComision(_products);
    }
  }

  const findIndexById = (id: any) => {
    let index = -1;
    for (let i = 0; i < VP_ConfigPagoComision.length; i++) {
      if (VP_ConfigPagoComision[i].IdPercentComision === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  const createId = () => {
    let index = 0;
    VP_ConfigPagoComision.forEach(function (row: any) {
      index = parseFloat(row.IdPercentComision);
    });
    index = index + 1;
    return index;
  }

  const setConfigPagoComisionTemplateDelete = (rowData: any) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-trash"
          className="p-button-sm p-button-danger"
          tooltip='Remover registro de forma temporal, para confirmar debe Grabar!'
          onClick={() => setConfigPagoComisionGridItem(rowData)} />
      </React.Fragment>
    );
  }

  const setConfigPagoComisionGridItem = (item: any) => {
    let itemsfiltered = VP_ConfigPagoComision.filter(function (element: any) {
      return element.IdPercentComision ? element.IdPercentComision !== item.IdPercentComision : [];
    });
    setConfigPagoComision(itemsfiltered);
  }

  // ++++++++++++++++++++++++++++++++++++++++++++++
  // CRUD grid horario laboral
  // ++++++++++++++++++++++++++++++++++++++++++++++

  const setConfigHorarioNew = () => {
    //console.log('new');
    setConfigHorarioClear();
    setConfigHorarioDisabled(false);
    setTimeout(() => {
      inputHrinicioRef.current?.focus();
    }, 200);
  }

  const setConfigHorarioClear = () => {
    setIdHorario('0');
    setConfigScheduleHrinicio(null);
    setConfigScheduleHrfin(null);
    setEstadoHr(false);
    setChkLun(false);
    setChkMar(false);
    setChkMie(false);
    setChkJue(false);
    setChkVie(false);
    setChkSab(false);
    setChkDom(false);
  }

  const setConfigHorarioEdit = () => {
    if (parseFloat(VL_IdHorario) === 0) {
      toast.current.show({ severity: 'info', detail: 'Seleccionar un horario', life: 6000 });
      return;
    }
    setConfigHorarioDisabled(false);
  }

  const setConfigHorarioDisabled = (val: boolean) => {
    setHrinicio_disabled(val);
    setHrfin_disabled(val);
  }

  const setConfigHorarioSeleccionado = (data: any) => {
    setConfigHorarioClear();
    setIdHorario(data.IdHorario);
    setEstadoHr(data.Estado);
    // console.log(data);
    setConfigScheduleHrinicio(new Date(Date.parse('1999-01-01 ' + data.startTime)));
    setConfigScheduleHrfin(new Date(Date.parse('1999-01-01 ' + data.endTime)));
    // console.log(data.daysOfWeek);
    let days = data.daysOfWeek;
    days.forEach(
      function (rec: any) {
        // console.log(rec);
        if (rec === 1) setChkLun(true);
        if (rec === 2) setChkMar(true);
        if (rec === 3) setChkMie(true);
        if (rec === 4) setChkJue(true);
        if (rec === 5) setChkVie(true);
        if (rec === 6) setChkSab(true);
        if (rec === 7) setChkDom(true);
      }
    );
  }

  const setConfigHorarioAdd = () => {
    let emptyProduct2 = {
      IdHorario: 0,
      startTime: '',
      endTime: '',
      daysOfWeek: '',
      daysOfWeekDescr: '',
      Estado: true
    };

    if (VL_ConfigScheduleHrinicio === '') {
      toast.current.show({ severity: 'info', detail: 'Ingresar Hora de inicio ', life: 6000 });
      return;
    }
    if (VL_ConfigScheduleHrfin === '') {
      toast.current.show({ severity: 'info', detail: 'Ingresar Hora fin ', life: 6000 });
      return;
    }

    let vl_daysOfWeek: any = [];
    if (VL_ChkLun) vl_daysOfWeek.push(1);
    if (VL_ChkMar) vl_daysOfWeek.push(2);
    if (VL_ChkMie) vl_daysOfWeek.push(3);
    if (VL_ChkJue) vl_daysOfWeek.push(4);
    if (VL_ChkVie) vl_daysOfWeek.push(5);
    if (VL_ChkSab) vl_daysOfWeek.push(6);
    if (VL_ChkDom) vl_daysOfWeek.push(7);

    let vl_daysOfWeekDescr: any = [];
    if (VL_ChkLun) vl_daysOfWeekDescr.push('Lun');
    if (VL_ChkMar) vl_daysOfWeekDescr.push('Mar');
    if (VL_ChkMie) vl_daysOfWeekDescr.push('Mie');
    if (VL_ChkJue) vl_daysOfWeekDescr.push('Jue');
    if (VL_ChkVie) vl_daysOfWeekDescr.push('Vie');
    if (VL_ChkSab) vl_daysOfWeekDescr.push('Sab');
    if (VL_ChkDom) vl_daysOfWeekDescr.push('Dom');
    // console.log(vl_daysOfWeekDescr);

    emptyProduct2 = {
      IdHorario: VL_IdHorario,
      startTime: setIntlTimeFormat(VL_ConfigScheduleHrinicio),
      endTime: setIntlTimeFormat(VL_ConfigScheduleHrfin),
      daysOfWeek: vl_daysOfWeek,
      daysOfWeekDescr: JSON.stringify(vl_daysOfWeekDescr),
      Estado: VL_EstadoHr
    }


    //if (emptyProduct2.IdStaff !== '0') {
    let _products = [...VP_ConfigSchedule];
    let _product = { ...emptyProduct2 };
    if (VL_IdHorario > 0) {
      const index = findIndexById1(emptyProduct2.IdHorario);
      _products[index] = _product;
    }
    else {
      _product.IdHorario = createId1();
      // _product.IdStaff = emptyProduct1.IdStaff;
      _product.startTime = emptyProduct2.startTime;
      _product.endTime = emptyProduct2.endTime;
      _product.daysOfWeek = emptyProduct2.daysOfWeek;
      _product.daysOfWeekDescr = emptyProduct2.daysOfWeekDescr;
      _product.Estado = emptyProduct2.Estado;
      _products.push(_product);
    }
    setConfigSchedule(_products);
    //}
  }

  const findIndexById1 = (id: any) => {
    let index = -1;
    for (let i = 0; i < VP_ConfigSchedule.length; i++) {
      if (VP_ConfigSchedule[i].IdHorario === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  const createId1 = () => {
    let index = 0;
    VP_ConfigSchedule.forEach(function (row: any) {
      index = parseFloat(row.IdHorario);
    });
    index = index + 1;
    return index;
  }

  const setConfigHorarioTemplateDelete = (rowData: any) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-trash"
          className="p-button-sm p-button-danger"
          tooltip='Remover registro de forma temporal, para confirmar debe Grabar!'
          onClick={() => setConfigHorarioGridItem(rowData)} />
      </React.Fragment>
    );
  }

  const setConfigHorarioGridItem = (item: any) => {
    let itemsfiltered = VP_ConfigSchedule.filter(function (element: any) {
      return element.IdHorario ? element.IdHorario !== item.IdHorario : [];
    });
    setConfigSchedule(itemsfiltered);
  }
  const gridHorarioTemplateStatus = (data: any) => {
    // const vl_status_template: any = data.Situacion === 'A' ? 'p-button-success' : 'p-button-danger';
    // const vl_icon_template: any = data.Situacion === 'A' ? 'pi pi-check' : 'pi pi-times';
    // return (
    //   <Button icon={`${vl_icon_template}`} className={`p-button-sm p-button-outlined ${vl_status_template}`} />
    // );
    return (
      <React.Fragment> <InputSwitch checked={data.Estado} /> </React.Fragment>
    );

  }

  const isSelectable = (value: any, field: any) => {
    let isSelectable = true;
    switch (field) {
      case 'Situacion':
        isSelectable = (value ? value : '') === 'A';
        break;
      default:
        break;
    }
    return isSelectable;
  }
  const isRowSelectable = (event: any) => {
    const data = event.data;
    return isSelectable(data.Situacion, 'Situacion');
  }

  const rowClassName = (data: any) => {
    // return isSelectable(data.Situacion, 'Situacion') ? '' : 'p-disabled bg-gray-100 line-through text-red-500';
    return isSelectable(data.Situacion, 'Situacion') ? '' : 'bg-gray-100 line-through text-red-500';
  }

  return (
    <>
      <Toast ref={toast} position="center"></Toast>
      <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
        message="¿Guardar Registro?"
        header="Confirmar para continuar"
        icon="pi pi-exclamation-triangle"
        accept={handleOnSubmitform}
        reject={handleOnSubmitformReject}
      />

      <div className="flex justify-content-between p-2 border-gray-500 pt-1 border-bottom-2 ">
        <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
          <div className="col mt-0 ml-0 text-base font-bold font-italic h-2rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
            <label className='pb-1'> Mantenimiento de trabajadores </label>
          </div>
        </div>
        <div>
          <span className="p-buttonset">
            <Button
              title="Nuevo registro"
              onClick={() => setModeNew()}
              icon="pi pi-file"
              className="p-button-raised"
              disabled={b_buttonNuevodisabled}
            />
            <Button
              title="Editar registro"
              onClick={() => setModeEdit()}
              icon="pi pi-pencil"
              className="p-button-raised"
              disabled={b_buttonEditardisabled}
            />
            <Button
              title="Grabar registro"
              onClick={() => setVisibleConfirmDialog(true)}
              icon="pi pi-save"
              className="p-button-raised"
              disabled={b_buttonGrabardisabled}
            />
            <Button
              title="Cancelar"
              onClick={() => setModeCancel()}
              icon="pi pi-undo"
              className="p-button-raised"
              disabled={b_buttonCancelardisabled}
            />
          </span>          
        </div>
      </div>

      <div className="flex align-items-stretch flex-nowrap justify-content-center p-2 ">
        <div className='card p-1 '>
          <div className="p-field p-grid pl-1 pr-1 pt-1 pb-2 ">
            <div className="p-col pl-1 pb-2">
              <InputText type="search" style={{ width: '22rem', textAlign: 'left' }}
                autoFocus
                placeholder='Filtrar por nombres o apellidos..'
                value={vl_filter}
                onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    getDataStaff();
                  }
                }}
              />
              <Button label=""
                onClick={(e: any) => getDataStaff()}
                icon="pi pi-search"
                tooltip='Buscar '
                className="p-button-rounded p-button-outlined ml-2"
              />
            </div>
            <div className="pl-1 pr-1 pt-1 pb-2 ">
              <DataTable
                size="small"
                value={dataStaff ?? []}
                dataKey="IdStaff"
                resizableColumns
                responsiveLayout="scroll"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                selectionMode="single"
                scrollDirection="horizontal"
                rowClassName={rowClassName} isDataSelectable={isRowSelectable}
                // style={{ maxWidth: '400px', height: '450px' }}
                scrollHeight="60vh"
                paginator
                rows={50}
                onSelectionChange={(e: any) => {                    
                    setOnSelectionChange(e.value);
                }}
                onRowDoubleClick={(e: any) => { 
                    // console.log(e.data);
                    dataStaff.forEach(
                      function (item: any, index: any) {
                        if(dataStaff[index].IdStaff === e.data.IdStaff){
                          dataStaff[index].Situacion = 'A';
                          setDataStaff(dataStaff);
                        }                        
                      }
                  );
                }}
              >
                <Column field="IdStaff" header="#ID" style={{ maxWidth: '60px' }} bodyClassName="flex justify-content-left"  ></Column>
                <Column field="staff" header="Trabajador(a)" headerClassName='white-space-normal' bodyClassName="flex justify-content-start white-space-normal " ></Column>
                {/* <Column field="Situacion" header="Activo" body={gridStaffTemplateStatus} style={{ maxWidth: '70px' }}></Column> */}
                <Column field="Situacion" header="Activo" style={{ maxWidth: '4rem' }} className='flex justify-content-center'
                  body={(item: any) => {
                    return (<label title={'' + item.Situacion} className={`${item.Situacion === 'A' ? ' pi pi-thumbs-up-fill ' : ' pi pi-thumbs-down-fill '}`}></label>);
                  }}
                ></Column>

              </DataTable>
            </div>
          </div>
        </div>
        <div className='card p-2 pl-2 ml-1 pr-2 '>
          <BlockUI blocked={blockedPanel} className='opacity-50' template={<i className="pi pi-lock" style={{ 'fontSize': '3rem' }} />}>
            <ScrollPanel style={{ height: '75vh' }}>
              <div className="p-field p-grid mb-1 ml-2 mt-1">
                <div className="col bg-bluegray-500 font-bold text-white  ">Datos generales</div>
                <div className="p-col pt-2 ">
                  <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Id:</label>
                  <InputText type="text" style={{ width: '80px', textAlign: 'center' }} className='font-bold'
                    value={VP_IdStaff} readOnly
                  />

                  <label className="p-col-fixed ml-8 font-bold text-sm ">Activo</label>
                  <InputSwitch checked={VP_Situacion}
                    onChange={(e: any) => setSituacion(e.value)}
                  />
                  <Button icon="pi pi-info-circle"
                    className="p-button-rounded p-button-text p-button-plain ml-5 "
                    tooltip='Crear usuario de sistema: Usuario=1º caracter de Nombres + 9 caracteres del Apellido. Clave: 123456' />
                  <label className="p-col-fixed ml-1 font-bold text-sm ">Crear usuario</label>
                  <InputSwitch checked={VP_CreaUsuario} disabled={vl_mode === 'I' ? false : true}
                    onChange={(e: any) => setCreaUsuario(e.value)}
                  />

                </div>
                <div className="p-col pt-1 ">
                  <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Nombres:</label>
                  <InputText type="text" style={{ width: '20rem' }}
                    value={VP_Nombres}
                    onChange={(e) => {
                      setNombres(String(e.currentTarget.value).toUpperCase());
                    }}
                    ref={inputNombresRef}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        inputApellidoPaternoRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div className="p-col pt-1 ">
                  <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Apellidos:</label>
                  <InputText type="text" style={{ width: '12rem' }}
                    value={VP_ApellidoPaterno}
                    onChange={(e) => {
                      setApellidoPaterno(String(e.currentTarget.value).toUpperCase());
                    }}
                    ref={inputApellidoPaternoRef}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        inputApellidoMaternoRef.current.focus();
                      }
                    }}
                  />
                  <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ width: '3px', textAlign: 'end' }}></label>
                  <InputText type="text" style={{ width: '12rem' }}
                    value={VP_ApellidoMaterno}
                    onChange={(e) => {
                      setApellidoMaterno(String(e.currentTarget.value).toUpperCase());
                    }}
                    ref={inputApellidoMaternoRef}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        // inputIdDocumentoRef.current.focus();
                        inputNumeroDocumentoRef.current.focus();
                      }
                    }}
                  />
                </div>

                <div className="p-col pt-1 ">
                  <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Documento:</label>
                  <Dropdown
                    style={{ width: '8rem' }}
                    value={VP_IdDocumento}
                    // inputRef={inputIdDocumentoRef}
                    options={dataIdTipoDocumento}
                    onChange={(e: any) => {
                      setIdDocumento(e.value);
                      inputNumeroDocumentoRef.current.focus();
                    }}
                    optionValue="IdTipoDocumento"
                    optionLabel="Codigo"
                    placeholder="Seleccionar" />

                  <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>Nº:</label>
                  <InputText type="text"
                    style={{ width: '8rem' }}
                    className='font-normal'
                    value={VP_NumeroDocumento}
                    onChange={(e) => {
                      setNumeroDocumento(String(e.currentTarget.value).toUpperCase());
                    }}
                    ref={inputNumeroDocumentoRef}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        inputFechaNacimentoRef.current.focus();
                      }
                    }}
                  />
                </div>
                <div className="p-col pt-1 ">
                  <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Fecha Nac.:</label>
                  <Calendar dateFormat="dd/mm/yy"
                    style={{ width: '8rem' }}
                    value={VP_FechaNacimento}
                    inputRef={inputFechaNacimentoRef}
                    onChange={(e: any) => setFechaNacimento(e.value)}
                  />
                  <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Sexo:</label>
                  <InputText type="text" style={{ width: '60px' }}
                    maxLength={1}

                    value={VP_Sexo}
                    onChange={(e) => {
                      setSexo(String(e.currentTarget.value).toUpperCase());
                    }}
                  />

                </div>
                <div className="p-col pt-1 ">
                  <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Estado Civil:</label>
                  <Dropdown style={{ width: '10rem' }}
                    value={VP_EstadoCivil}
                    options={cmbIdEstadoCivil}
                    onChange={(e: any) => { setEstadoCivil(e.value) }}
                    optionValue="Codigo"
                    optionLabel="Descripcion"
                    placeholder="Seleccionar" />
                  <label className="p-col-fixed ml-2 mr-2 font-bold text-sm " style={{ textAlign: 'end' }}>F. Ingreso:</label>
                  <Calendar dateFormat="dd/mm/yy"
                    style={{ width: '8rem' }}
                    value={VP_FechaIngreso}
                    // ref={inputFechaNacimentoRef}
                    onChange={(e: any) => setFechaIngreso(e.value)}
                  />
                </div>
                <div className="p-col pt-1 ">
                  <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Cargo:</label>
                  <Dropdown style={{ width: '40%' }}
                    value={VP_IdTituloAcademico}
                    options={cmbIdTituloAcademico}
                    onChange={(e: any) => { setIdTituloAcademico(e.value) }}
                    optionValue="IdTituloAcademico"
                    optionLabel="Descripcion"
                    placeholder="Seleccionar" />

                </div>
                <div className="p-col pt-1 ">
                  <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Nivel Académico:</label>
                  <Dropdown style={{ width: '40%' }}
                    value={VP_IdNivelAcademico}
                    options={cmbIdGradoInstruccion}
                    onChange={(e: any) => { setIdNivelAcademico(e.value) }}
                    optionValue="Codigo"
                    optionLabel="Descripcion"
                    placeholder="Seleccionar" />
                </div>
              </div>
              <div className="p-field p-grid mb-1 ml-2 mt-3">
                <div className="col bg-bluegray-500 font-bold text-white  ">Remuneración</div>
                <div className="p-col pt-1 ">
                  <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Periodicidad Pago:</label>
                  <Dropdown style={{ width: '30%' }}
                    value={VP_TipoRemuneracion}
                    options={cbmIdTipoPago}
                    onChange={(e: any) => { setTipoRemuneracion(e.value) }}
                    optionValue="Codigo"
                    optionLabel="Descripcion"
                    placeholder="Seleccionar" />

                  <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Importe:</label>
                  <InputNumber type="text" inputStyle={{ width: '90px', textAlign: 'right' }}
                    mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                    value={VP_Remuneracion}
                    // disabled={vl_Percent_disabled}
                    onChange={
                      (e) => {
                        setRemuneracion(Number(e.value));
                      }
                    }
                  />
                  <div className="p-field p-grid mt-0 mb-1">
                    <DropdownItem divider />
                    <div className="col bg-bluegray-500 font-bold text-white  ">Parametros para calcular comisiones</div>
                    <div className="p-col pt-1">
                      <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ width: '80px', textAlign: 'end' }}>Id:</label>
                      <InputText type="text" style={{ width: '70px', height: '30px', textAlign: 'center' }}
                        value={vl_IdPercentComision} readOnly
                      />
                    </div>
                    <div className="p-col pt-1">
                      <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ width: '80px', textAlign: 'end' }}>% Comm:</label>
                      <InputNumber type="text" inputStyle={{ height: '30px', width: '90px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        value={vl_Percent} disabled={vl_Percent_disabled}
                        inputRef={inputPercentRef}
                        onChange={
                          (e) => {
                            setPercent(Number(e.value));
                          }
                        }
                      />

                      <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>Importe Fijo:</label>
                      <InputNumber type="text" inputStyle={{ height: '30px', width: '90px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        value={vl_Fijo} disabled={vl_Fijo_disabled}
                        onChange={
                          (e) => {
                            setFijo(Number(e.value));
                          }
                        }
                      />

                      <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>% Descto:</label>
                      <InputNumber type="text" inputStyle={{ height: '30px', width: '70px', textAlign: 'right' }}
                        //inputClassName='bg-yellow-500 font-bold text-gray-900 border-round '
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        value={vl_PercentDiscount} disabled={vl_PercentDiscount_disabled}
                        onChange={
                          (e) => {
                            setPercentDiscount(Number(e.value));
                          }
                        }
                      />
                      <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>Importe:</label>
                      <InputNumber type="text" inputStyle={{ height: '30px', width: '90px', textAlign: 'right' }}
                        //inputClassName='bg-yellow-500 font-bold text-gray-900 border-round '
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        value={vl_FijoDiscount} disabled={vl_FijoDiscount_disabled}
                        onChange={
                          (e) => {
                            setFijoDiscount(Number(e.value));
                          }
                        }
                      />
                    </div>
                    <div className="p-col pt-1">
                      <label className="p-col-fixed mr-1 ml-2 text-sm font-bold " style={{ textAlign: 'end' }}>Importe adicional por tipo de atención:</label>
                    </div>
                    <div className="p-col pt-1">
                      <Checkbox className="ml-2"
                        onChange={(e: any) => {
                          setChkIndividual(e.checked);
                          setImporteAddIndiviual_disabled(e.checked ? false : true);
                        }}
                        checked={VL_ChkIndividual}>
                      </Checkbox>
                      <label className="p-checkbox-label pr-1">Individual</label>

                      <InputNumber type="text" inputStyle={{ height: '30px', width: '80px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        value={vl_ImporteAddIndividual} disabled={vl_ImporteAddIndiviual_disabled}
                        onChange={
                          (e) => {
                            setImporteAddIndividual(Number(e.value));
                          }
                        }
                      />
                      <Checkbox className="ml-2"
                        onChange={(e: any) => {
                          setChkFamiliar(e.checked);
                          setImporteAddFamiliar_disabled(e.checked ? false : true);
                        }}
                        checked={VL_ChkFamiliar}>
                      </Checkbox>
                      <label className="p-checkbox-label pr-1">Pareja</label>
                      <InputNumber type="text" inputStyle={{ height: '30px', width: '80px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        value={vl_ImporteAddFamiliar} disabled={vl_ImporteAddFamiliar_disabled}
                        onChange={
                          (e) => {
                            setImporteAddFamiliar(Number(e.value));
                          }
                        }
                      />
                      <Checkbox className="ml-2"
                        onChange={(e: any) => {
                          setChkGrupal(e.checked);
                          setImporteAddGrupal_disabled(e.checked ? false : true);
                        }}
                        checked={VL_ChkGrupal}>
                      </Checkbox>
                      <label className="p-checkbox-label pr-1">Grupal</label>
                      <InputNumber type="text" inputStyle={{ height: '30px', width: '80px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        value={vl_ImporteAddGrupal} disabled={vl_ImporteAddGrupal_disabled}
                        onChange={
                          (e) => {
                            setImporteAddGrupal(Number(e.value));
                          }
                        }
                      />

                    </div>
                    <div className="p-col pt-1">
                      <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ width: '80px', textAlign: 'end' }}>Vigencia:</label>
                      <Calendar style={{ width: '120px', height: '30px' }}
                        value={vl_FechaVigenciaDesde} disabled={vl_FechaVigenciaDesde_disabled}
                        onChange={(e: any) => setFechaVigenciaDesde(e.value)} minDate={new Date()}
                        dateFormat="yy/mm/dd"
                      />
                      <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>Al:</label>
                      <Calendar style={{ width: '120px', height: '30px' }}
                        value={vl_FechaVigenciaHasta} disabled={vl_FechaVigenciaHasta_disabled}
                        onChange={(e: any) => setFechaVigenciaHasta(e.value)} minDate={new Date()}
                        dateFormat="yy/mm/dd"
                      />
                      <label className="p-col-fixed ml-2 font-bold text-sm ">Vigente</label>
                      <InputSwitch checked={vl_Estado}
                        onChange={(e: any) => setEstado(e.value)}
                      />
                      <label className="mr-5"></label>
                      <span className="p-buttonset">
                        <Button
                          // type="button"
                          icon="pi pi-pencil"
                          tooltip='Editar registro seleccionado'
                          onClick={(e) => setConfigPagoComisionEdit()}
                          className="p-button-sm p-button-success p-button-raised"
                        />
                        <Button
                          // type="button"
                          icon="pi pi-file"
                          tooltip='Habilitar campos para agregar nuevo registro'
                          onClick={(e) => setConfigPagoComisionNew()}
                          className="p-button-sm p-button-success p-button-raised"
                        />
                        <Button
                          type="button"
                          icon="pi pi-plus"
                          tooltip='Agregar el registro'
                          onClick={(e) => setConfigPagoComisionAdd()}
                          className="p-button-sm p-button-success p-button-raised"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-col pt-1 ">
                    <DataTable
                      size="small" header=""
                      value={VP_ConfigPagoComision ?? []}
                      dataKey="IdPercentComision"
                      resizableColumns
                      responsiveLayout="scroll"
                      columnResizeMode="fit"
                      showGridlines
                      stripedRows
                      scrollable
                      selectionMode="single"
                      scrollDirection="horizontal"
                      style={{ maxWidth: '750px', height: '270px' }}
                      scrollHeight="190px"
                      paginator
                      rows={20}
                      onSelectionChange={
                        (e: any) => {
                          // console.log(e.value);
                          setConfigPagoComisionSeleccionado(e.value);
                          setConfigPagoComisionDisabled(true);
                        }
                      }
                    >
                      <Column field="" header="" body={setConfigPagoComisionTemplateDelete} style={{ maxWidth: '64px' }}  ></Column>
                      <Column field="IdPercentComision" header="Id" style={{ maxWidth: '60px' }} bodyClassName="flex justify-content-start" ></Column>
                      <Column field="Percent" header="% Comisión" bodyClassName="flex justify-content-end" ></Column>
                      <Column field="Fijo" header="Importe" bodyClassName="flex justify-content-start" ></Column>
                      <Column field="PercentDiscount" header="% Descto" bodyClassName="flex justify-content-start" ></Column>
                      <Column field="FijoDiscount" header="Importe" bodyClassName="flex justify-content-start" ></Column>
                      {/* <Column field="Estado" header="Estado" bodyClassName="flex justify-content-start" ></Column> */}
                      <Column field="FechaVigenciaDesde" header="Vigencia" bodyClassName="flex justify-content-start" ></Column>
                      <Column field="FechaVigenciaHasta" header="Fin vigencia" bodyClassName="flex justify-content-start" ></Column>

                    </DataTable>

                  </div>

                </div>
              </div>
              <div className="p-field p-grid mb-1">
                <div className="col bg-bluegray-500 font-bold text-white  ">Horario Laboral</div>
                <div className="p-col pt-1">
                  <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Id:</label>
                  <InputText type="text" style={{ width: '70px', height: '30px', textAlign: 'center' }}
                    value={VL_IdHorario} readOnly
                  />
                </div>
                <div className="p-col pb-1">
                  <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Hora inicio:</label>
                  <Calendar
                    value={VL_ConfigScheduleHrinicio}
                    style={{ width: '90px', height: '30px' }}
                    disabled={b_Hrinicio_disabled}
                    showTime dateFormat="hh:mm" timeOnly
                    inputRef={inputHrinicioRef}
                    onChange={(e: any) => {
                      // console.log(e.value);
                      setConfigScheduleHrinicio(e.value);
                    }}
                    onBlur={(e: any) => {
                      // setEventEndTimeChange(eventStartTime);
                    }}
                  />
                  <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Fin:</label>
                  <Calendar value={VL_ConfigScheduleHrfin} style={{ width: '90px', height: '30px' }}
                    showTime dateFormat="hh:mm" timeOnly
                    disabled={b_Hrfin_disabled}
                    onChange={(e: any) => {
                      setConfigScheduleHrfin(e.value);
                    }}
                    onBlur={(e: any) => {
                      // setEventEndTimeChange(eventStartTime);
                    }}
                  />
                  <label htmlFor="" className="p-col-fixed ml-8 font-bold text-sm " style={{ textAlign: 'end' }}>Horario activo</label>
                  <InputSwitch checked={VL_EstadoHr}
                    onChange={(e: any) => setEstadoHr(e.value)}
                  />
                </div>
                <div className='p-col pt-1 pb-1' >
                  <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Los dias:</label>
                  <label style={{ width: '65px' }} className="p-checkbox-label pl-1">
                    <Checkbox className="ml-2" onChange={e => setChkLun(e.checked)} checked={VL_ChkLun}>
                    </Checkbox>Lun</label>

                  <label style={{ width: '65px' }} className="p-checkbox-label pl-1">
                    <Checkbox className="ml-2" onChange={e => setChkMar(e.checked)} checked={VL_ChkMar}>
                    </Checkbox>Mar</label>

                  <label style={{ width: '65px' }} className="p-checkbox-label pl-1">
                    <Checkbox className="ml-2" onChange={e => setChkMie(e.checked)} checked={VL_ChkMie}>
                    </Checkbox>Mie</label>

                  <label style={{ width: '65px' }} className="p-checkbox-label pl-1">
                    <Checkbox className="ml-2" onChange={e => setChkJue(e.checked)} checked={VL_ChkJue}>
                    </Checkbox>Jue</label>

                  <label style={{ width: '65px' }} className="p-checkbox-label pl-1">
                    <Checkbox className="ml-2" onChange={e => setChkVie(e.checked)} checked={VL_ChkVie}>
                    </Checkbox>Vie</label>

                  <label style={{ width: '65px' }} className="p-checkbox-label pl-1">
                    <Checkbox className="ml-2" onChange={e => setChkSab(e.checked)} checked={VL_ChkSab}>
                    </Checkbox>Sab</label>

                  <label style={{ width: '70px' }} className="p-checkbox-label pl-1">
                    <Checkbox className="ml-2" onChange={e => setChkDom(e.checked)} checked={VL_ChkDom}>
                    </Checkbox>Dom</label>
                </div>
                <div className="p-col pt-1" >
                  <label className="mr-3"></label>
                  <span className="p-buttonset">
                    <Button
                      // type="button"
                      icon="pi pi-pencil"
                      tooltip='Editar registro seleccionado'
                      onClick={(e) => setConfigHorarioEdit()}
                      className="p-button-sm p-button-success p-button-raised"
                    />
                    <Button
                      // type="button"
                      icon="pi pi-file"
                      tooltip='Habilitar campos para agregar nuevo registro'
                      onClick={(e) => setConfigHorarioNew()}
                      className="p-button-sm p-button-success p-button-raised"
                    />
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      tooltip='Agregar el registro'
                      onClick={(e) => setConfigHorarioAdd()}
                      className="p-button-sm p-button-success p-button-raised"
                    />
                  </span>
                </div>
              </div>
              <DataTable
                size="small" header=''
                value={VP_ConfigSchedule ?? []}
                dataKey="IdHorario"
                resizableColumns
                responsiveLayout="scroll"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                selectionMode="single"
                scrollDirection="horizontal"
                style={{ maxWidth: '700px', height: '270px' }}
                scrollHeight="190px"
                paginator
                rows={20}
                onSelectionChange={
                  (e: any) => {
                    // console.log(e.value);
                    setConfigHorarioSeleccionado(e.value);
                    setConfigHorarioDisabled(true);
                  }
                }
              >
                <Column field="" header="" body={setConfigHorarioTemplateDelete} style={{ maxWidth: '64px' }}  ></Column>
                <Column field="IdHorario" header="Id" style={{ maxWidth: '60px' }} bodyClassName="flex justify-content-start" ></Column>
                <Column field="daysOfWeekDescr" header="Dias" style={{ minWidth: '200px' }} bodyClassName="flex justify-content-start" ></Column>
                <Column field="startTime" header="Hora inicio" bodyClassName="flex justify-content-center" ></Column>
                <Column field="endTime" header="Hora fin" bodyClassName="flex justify-content-center" ></Column>
                <Column field="Estado" header="Hr. Activo" bodyClassName="flex justify-content-center" body={gridHorarioTemplateStatus} ></Column>
              </DataTable>
              <div className="p-field p-grid mb-1">
                <div className="col bg-bluegray-500 font-bold text-white  ">Domicilio/Contacto</div>
                <div className="p-col pt-1 pb-1">
                  <label className="p-col-fixed mr-1 font-bold " style={{ width: '100px', textAlign: 'end' }}>Dirección:</label>
                  <InputText type="text" style={{ height: '30px', width: '70%' }}
                    value={VL_Direccion}
                    onChange={(e) => {
                      setDireccion(String(e.currentTarget.value).toUpperCase())
                    }}
                  />
                </div>
                <div className="p-col pt-1 pb-1">
                  <label className="p-col-fixed mr-1 font-bold " style={{ width: '100px', textAlign: 'end' }}>Correo:</label>
                  <InputText type="email" style={{ height: '30px', width: '300px' }}
                    value={VL_InformacionContactoEmail}
                    onChange={(e) => setInformacionContactoEmail(String(e.currentTarget.value).toUpperCase())}
                    // ref={inputDireccionEmailRef}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        // inputFechaNacimientoRef.current.focus();
                      }
                    }}

                  />
                  <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Nº Celular:</label>
                  <InputText type="text" style={{ height: '30px', width: '200px' }}
                    value={VL_InformacionContactoTelefono}
                    onChange={(e) => setInformacionContactoTelefono(String(e.currentTarget.value).toUpperCase())}
                    // ref={inputNumeroTelefonoRef}
                    onKeyPress={(e: any) => {
                      if (e.key === 'Enter') {
                        // inputFechaNacimientoRef.current.focus();
                      }
                    }}
                  />
                </div>
              </div>
            </ScrollPanel>
          </BlockUI>
        </div>
      </div>
    </>
  )
}

export default StaffView;
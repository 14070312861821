
import { Player } from '@lottiefiles/react-lottie-player';
const AuthResetPasswordExpire = () => {

    return (
        <>

            <div className="wrapper d-flex flex-column min-vh-100 bg-gradient-indigo text-white">
                <div className='flex flex-wrap justify-content-center pb-3 m-2 ' >
                    <div className="flex justify-content-between ">
                        <div>
                            <nav className="flex justify-content-center p-4">
                                {/* <img src="../../psisabe.ico" alt="" height={'184px'} /> */}
                            </nav>
                        </div>
                    </div>
                </div>
                <main className="form-passwor">
                    <div>
                        <div className='flex flex-column justify-content-center p-1 m-2'>
                            <div className="field">
                                <label className='font-base text-xl text-red-600 flex justify-content-center' >Expired link.</label>
                            </div>
                            <div className='font-base text-normal p-2 white-space-normal' >
                                {'El token asociado para restablecer la contraseña no es valido o ha expirado.'}
                            </div>
                            <div>
                                <Player
                                    // ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="../lottie-player/51972-error-404.json"
                                    style={{ height: '400px', width: '400px' }}
                                    speed={1}
                                ></Player>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </>
    )
}

export default AuthResetPasswordExpire;
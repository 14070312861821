import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressBar } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useRef, useState } from 'react';
import { fetchSinToken } from '../../../helpers/fetch';
import { Badge } from 'primereact/badge';
import AgendaForm from '../../components/agenda/AgendaForm';
import useModal from '../../../context/WindowsOpen';
import { Checkbox } from 'primereact/checkbox';
import { DetalleOrdenPago } from '../../components/caja/ordenPago/DetalleOrdenPago';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DetalleCuotas } from '../../components/ordenPago/ordenTerapia/DetalleCuotas';
import AnularOrdenPago from './AnularOrdenPago';


export const PlanSesionesView = ({ ...prop }) => {


    const [vl_QtySesion, setQtySesion] = useState<any>(0);
    const [vl_QtySesionExecuted, setQtySesionExecuted] = useState<any>(0);
    const [vl_QtySesionPending, setQtySesionPending] = useState<any>(0);
    // -- estadistic
    const [vl_QtySesionScheduled, setQtySesionScheduled] = useState<any>(0);
    const [vl_QtySessionExecuted, setQtySessionExecuted] = useState<any>(0);

    const [vl_QtySessionNoExecuted, setQtySessionNoExecuted] = useState<any>(0);
    const [vl_QtySesionRescheduled, setQtySesionRescheduled] = useState<any>(0);
    const [vl_QtySesionCanceled, setQtySesionCanceled] = useState<any>(0);
    //info catail cita
    const isMounted = useRef(false);
    const [isMode, setMode] = useState('R');
    const [isOpenModal, openAdmisionModal, closeAdmisionModal] = useModal();
    const [selectedDateCalendar, setSelectedDateCalendar] = useState<any>([]);

    const [dataPlanSesion, setDataPlanSesion] = useState<any>([]);
    const [dataPlanSesionDetalle, setDataPlanSesionDetalle] = useState<any>([]);
    const [vl_IdOrdenPago, setIdOrdenPago] = useState<any>('');
    const [vl_CountIdOrdenPago, setCountIdOrdenPago] = useState<any>(0);

    const o_detalleOrdenPago = useRef<OverlayPanel>(null);
    const [detalleOrdenPago, setDetalleOrdenPago] = useState<any>([]);

    const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>('');
    const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>('');
    const [vl_Nombres, setNombres] = useState<any>('');
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
    const [vl_TipoServicio, setTipoServicio] = useState<any>('');
    const [vl_Total, setTotal] = useState<any>(0);
    const [vl_Pagos, setPagos] = useState<any>(0);
    const [vl_Saldo, setSaldo] = useState<any>(0);
    const [dataSelectedRow, setDataSelectedRow] = useState<any>([]);
    //new
    const [vl_TipoRegistro, setTipoRegistro] = useState<any>('');
    // const [vl_IdOrdenPago, setIdOrdenPago] = useState<any>(0);
    const [vl_IdRegistro, setIdRegistro] = useState<any>(0);
    const [isOpenModal2, openModal2, closeModal2] = useModal();
    const [isOpenModal3, openModal3, closeModal3] = useModal();
    const isMounted2 = useRef(false);
    const isMounted3 = useRef(false);
    const setIsMounted = (val: boolean) => {
        isMounted2.current = val;
    }
    const handleClickDetalleCuota = () => {
        isMounted2.current = true;
        openModal2();
    }
    const setIsMounted3 = (val: boolean) => {
        isMounted3.current = val;
    }


    useEffect(() => {
        pa_get_paciente_plan_sesion(prop.IdCliente);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_paciente_plan_sesion = async (in_IdCliente: any) => {
        const param = {
            "VP_IdCliente": in_IdCliente,
            "VP_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('paciente/pa_get_paciente_plan_sesion', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        if (record.length === 0) {
            alert('No hay data plan de sesiones');
            return;
        }

        setDataPlanSesion(record);
        setCountIdOrdenPago(record?.length);
        //Valor x Default 
        setOnSelectionChange(record[0]);
        pa_get_paciente_plan_sesion_detalle(record[0]?.IdPlanPago);
    }

    const pa_get_paciente_plan_sesion_detalle = async (in_IdPlanPago: any) => {
        const param = {
            "VP_IdPlanPago": in_IdPlanPago
        }
        const response = await fetchSinToken('paciente/pa_get_paciente_plan_sesion_detalle', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        setDataPlanSesionDetalle(record);
    }
    const setOnSelectionChange = (data: any) => {
        console.log(data);
        // console.log(data.IdOrdenPago);
        if (!data.IdOrdenPago) return;
        setIdOrdenPago(data.IdOrdenPago);
        // setTotalInversion(data.Total);
        // setTotalPagos(data.Pagos);
        // setTotaltCuota(data.NumeroCuotas);
        // setCuotaPagada(data.NumeroCuotasPagado);
        // setSaldo(data.Saldo);

        setQtySesion(data.QtySesion);
        setQtySesionExecuted(data.QtySesionExecuted);
        setQtySesionPending(data.QtySesionPending);
        // --estadistic
        setQtySesionScheduled(data.QtySesionScheduled);
        setQtySessionExecuted(data.QtySesionExecuted);
        setQtySessionNoExecuted(data.QtySessionNoExecuted);
        setQtySesionRescheduled(data.QtySesionRescheduled);
        setQtySesionCanceled(data.QtySesionCanceled);

        setApellidoPaterno(data.ApellidoPaterno);
        setApellidoMaterno(data.ApellidoMaterno);
        setNombres(data.Nombres);
        setNumeroHistoriaClinica(data.NumeroHistoriaClinica);
        setTipoServicio(data.TipoServicio);
        setTotal(data.Total);
        setPagos(data.Pagos);
        setSaldo(data.Saldo);
    }


    const templateStatusSesion = (item: any) => {

        return (
            <>
                <span style={{ textAlign: 'center' }} className={item.EstadoSesion_bg + ' text-sm'} >{item.EstadoSesion_lbl === '' ? 'PENDIENTE' : item.EstadoSesion_lbl}</span>
            </>
        );
    }
    const templateStatusSesionAll = (item: any) => {
        return (
            <>
                <React.Fragment>
                    <span style={{ textAlign: 'center' }} className={item.EstadoPlanSesion} >{item.progreso}</span>
                </React.Fragment>

            </>
        );
    }

    const templateDetalleServicio = (item: any) => {
        return (
            <>
                <React.Fragment>
                    <label className="white-space-normal">{item.DetalleServicio}  </label>
                </React.Fragment>
            </>
        );
    }
    const templateStaff00 = (item: any) => {
        return (
            <>
                <React.Fragment>
                    <label className="white-space-normal">{item.staff00}  </label>
                </React.Fragment>
            </>
        );
    }


    const templateStatusPago = (item: any) => {

        return (
            <>
                <React.Fragment>
                    <Checkbox
                        style={{ textAlign: 'center' }} checked={item.Estado == 'T' ? true : false} > </Checkbox>
                </React.Fragment>

            </>
        );
    }

    const templateStatusPagoOP = (item: any) => {
        // console.log(item);
        const BtnStatusPending = () => {
            return (
                <>
                    <Button
                        type="button" title='Pago Pendiente'
                        className="p-button-sm pl-1 text-xs"
                    // icon="pi pi-minus-circle"
                    // style={{ height: '24px', width: '120px' }}
                    // label="Pendiente"
                    />
                </>
            );
        }
        const BtnStatusPagado = () => {
            return (
                <>
                    <Button
                        type="button" title='Pago total'
                        className="p-button-sm p-button-success pl-1 text-xs"
                    // icon="pi pi-check-circle"
                    // style={{ height: '24px', width: '120px' }}
                    // label="Pagado"
                    />
                </>
            );
        }
        const BtnStatusParcial = () => {
            return (
                <>
                    <Button
                        type="button" title='Pago parcial'
                        className="p-button-sm p-button-warning  pl-1 text-xs "
                    // icon="pi pi-spinner"
                    // style={{ height: '24px', width: '120px' }}
                    // label="Parcial"
                    />
                </>
            );
        }
        return (
            <>
                {item.EstadoPago === 'P' ? <BtnStatusPending /> : item.EstadoPago === 'T' ? <BtnStatusPagado /> : <BtnStatusParcial />}
            </>
        );
    }

    const handleEventClick_data = (item: any) => {
        let eventData: any = {
            "event": {
                "id": item.IdAgenda,
                "start": item.EventDateLong
            }
        }
        setSelectedDateCalendar(eventData);
        handleEventClick();
    }

    const templateDetalleCita = (item: any) => {

        if (!item.IdAgenda) {
            return '';
        }

        return (
            <>
                <Button
                    type="button"
                    className="p-button p-button-warning "
                    icon="pi pi-calendar"
                    title="Detalle de Cita"
                    style={{ height: '24px' }}
                    onClick={() => handleEventClick_data(item)}
                />
            </>
        );
    }

    const handleEventClick = () => {
        // setMode('R');
        setMode('V');
        isMounted.current = true;
        openAdmisionModal();
    }

    const pa_get_void = () => {
    }

    const handleSetisMounted = (val: boolean) => {
        // console.log('handleSetisMounted= '+ val );
        isMounted.current = val;
    }

    const AgendaFormView = () => {
        return (
            <AgendaForm
                isOpen={isOpenModal}
                isHide={closeAdmisionModal}
                isMounted={handleSetisMounted}
                isData={selectedDateCalendar}
                isMode={isMode}
                isCallfn={pa_get_void}
            />
        );
    }
    const pa_get_orden_pago_detalle = async (p_IdOrdenPago: any, e: any) => {

        if (Number(p_IdOrdenPago) > 0) {
            const param = {
                "IdOrdenPago": p_IdOrdenPago
            }
            const response = await fetchSinToken('admision/pa_get_orden_pago_detalle', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            // console.log(record);
            setDetalleOrdenPago(record);
            o_detalleOrdenPago.current?.toggle(e);
        }
    }
    const template_OrdenPagoDetalle = (item: any) => {
        const vl_IdOrdenPago: any = item.IdOrdenPago;
        return (
            <>
                <Button label={`${vl_IdOrdenPago}`} className="p-button-link pl-1" title='Ver detalle de la orden de pago'
                    onClick={(e) => pa_get_orden_pago_detalle(vl_IdOrdenPago, e)}
                />
            </>
        );
    }

    const handleEventClickAnular = (row: any) => {
        setDataSelectedRow(row);
        isMounted3.current = true;
        openModal3();
    }

    const template_OrdenPagoBaja = (row: any) => {
        // const vl_IdOrdenPago: any = row.IdOrdenPago;
        // console.log(row)
        // setDataSelectedRow(row);
        const BtnSuspedido = () => {
            return (
                <>
                    <Button type="button" className="p-button-sm p-button-danger " title='SERVICIO SE ENCUENTRA DE BAJA'
                        icon="pi pi-eye-slash"
                        onClick={() => {
                            console.log('Activar ?? ');
                        }}
                    />
                </>
            );
        }
        const BtnSuspender = () => {
            return (
                <>
                    <Button type="button" className="p-button-sm p-button " title='DAR DE BAJA AL SERVICIO'
                        icon="pi pi-stop-circle"
                        onClick={() => {
                            handleEventClickAnular(row);
                        }}
                    />
                </>
            );
        }

        return (
            <> {row.Estado === 'S' ? <BtnSuspedido /> : row.QtySesion === row.QtySesionExecuted ? '' : <BtnSuspender />} </>
        );
    }


    const renderFooter = () => {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-check" onClick={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }} autoFocus />
            </div>
        );
    }

    const renderHeader = () => {
        return (
            <>
                <div className="bg-indigo-500 pb-2">
                    <div className="col mt-0 ml-0 text-white font-italic h-3rem" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='text-base font-bold'> Paciente: {vl_ApellidoPaterno + ' ' + vl_ApellidoMaterno + ', ' + vl_Nombres} </label><br />
                        <label className='text-base font-bold'> Nº HC. {vl_NumeroHistoriaClinica} </label>
                    </div>
                </div>
            </>
        );
    }

    const templateNumeroCuotaPago = (row: any) => {
        // console.log(row);
        const BtnNumeroCuotaPagoT = () => {
            return (
                <>
                    <Button label={`${row.NumeroCuotaPago}`} className="p-button-link pl-1" title='Ver detalle de cuotas -terapia'
                        onClick={() => {
                            setIdOrdenPago(row.IdOrdenPago);
                            setTipoRegistro(row.Tipo); //TipoRegistro
                            setIdRegistro(row.IdRegistro);
                            handleClickDetalleCuota();
                        }}
                    />
                </>
            );
        }
        const BtnNumeroCuotaPagoA = () => {
            return (
                <>
                    <Button label={`${row.NumeroCuotaPago}`} className="p-button-link pl-1" title='Ver detalle de cuotas -atencion'
                        onClick={() => {
                            setIdOrdenPago(row.IdOrdenPago);
                            setTipoRegistro(row.Tipo);
                            setIdRegistro(row.IdRegistro);
                            handleClickDetalleCuota();
                        }}
                    />
                </>
            );
        }
        const BtnNumeroCuotaPago = () => {
            return (
                <>
                    <Button label={`${row.NumeroCuotaPago}`} className="p-button-link pl-1" title='Ver detalle de cuotas -otros'
                        onClick={() => {
                            setIdOrdenPago(row.IdOrdenPago);
                            setTipoRegistro(row.Tipo);
                            setIdRegistro(row.IdRegistro);
                            handleClickDetalleCuota();
                        }}
                    />
                </>
            );
        }

        return (
            <>
                {row.Tipo === 'T' ? <BtnNumeroCuotaPagoT /> : row.Tipo === 'A' ? <BtnNumeroCuotaPagoA /> : <BtnNumeroCuotaPago />}
            </>
        );
    }

    const AnularOrdenPagoNew = () => {
        return (
            <AnularOrdenPago
                isOpen={isOpenModal3}
                isHide={closeModal3}
                isData={dataSelectedRow}
                isCallfn={pa_get_paciente_plan_sesion}
                setIsMounted={setIsMounted3}
            />
        );
    }

    return (
        <>
            <React.Fragment>{isMounted3.current && <AnularOrdenPagoNew />}</React.Fragment>

            <DetalleOrdenPago
                o_detalleOrdenPago={o_detalleOrdenPago}
                detalleOrdenPago={detalleOrdenPago}
            />
            <React.Fragment>{isMounted2.current && <DetalleCuotas ModoForm='1'
                isOpen={isOpenModal2}
                isHide={closeModal2}
                setIsMounted={setIsMounted}
                TipoRegistro={vl_TipoRegistro}
                IdOrdenPago={vl_IdOrdenPago}
                IdRegistro={vl_IdRegistro}
                IdOrdenTerapia={0} // quitar
                TotalInversion={0} //quitar
                TotaltCuota={0} //quitar
            />
            }
            </React.Fragment>
            <React.Fragment>{isMounted.current && <AgendaFormView />}</React.Fragment>

            <Dialog position={'center'}
                // style={{ minWidth: '100vw', minHeight: '100vh', }}
                header={renderHeader}
                modal draggable resizable closable showHeader maximizable
                visible={prop.isOpen}
                footer={renderFooter}
                onHide={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }}
            >
                <div className="flex flex-nowrap" >
                    <div className="flex flex-wrap card-container w-full ">
                        <div className='card w-100 vh-75 m-1 p-1 '>
                            <div className='flex justify-content-between pt-2 '>
                                <div>
                                    <label className="col text-sm " style={{ textAlign: 'left' }} >
                                        <Badge value={vl_CountIdOrdenPago} severity="danger" ></Badge> {' '}
                                        Seleccionar una orden para ver detalle de sesiones.
                                    </label>
                                </div>
                                <div>
                                    <label className="col text-xs bg-indigo-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Pendiente</label>
                                    <label className="col text-xs bg-yellow-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Parcial</label>
                                    <label className="col text-xs bg-green-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Pagado</label>
                                </div>
                            </div>
                            <div className=''>
                                <DataTable
                                    size="small"
                                    value={dataPlanSesion ?? []}
                                    dataKey="IdPlanPago"
                                    resizableColumns
                                    responsiveLayout="scroll"
                                    columnResizeMode="fit"
                                    showGridlines
                                    stripedRows
                                    scrollable
                                    selectionMode="single"
                                    scrollDirection="horizontal"
                                    style={{ maxWidth: '49vw' }}
                                    scrollHeight="60vh"
                                    onSelectionChange={(e: any) => {
                                        setOnSelectionChange(e.value);
                                        pa_get_paciente_plan_sesion_detalle(e.value.IdPlanPago);
                                    }
                                    }
                                >
                                    <Column headerClassName="pi pi-stop-circle flex justify-content-center " style={{ maxWidth: '4rem' }}
                                        bodyClassName="flex justify-content-end"
                                        body={template_OrdenPagoBaja}
                                    ></Column>
                                    <Column field="IdOrdenPago" header="Nº OP" style={{ minWidth: '4rem' }}
                                        bodyClassName="flex justify-content-center"
                                        body={template_OrdenPagoDetalle}
                                    ></Column>
                                    <Column field="Sede" header="Sede" style={{ maxWidth: '4rem' }} bodyClassName="flex justify-content-center"></Column>
                                    <Column field="DetalleServicio" header="Servicio" style={{ minWidth: '14rem' }} bodyClassName="flex justify-content-start"
                                        body={templateDetalleServicio}
                                    >
                                    </Column>
                                    <Column field="staff00" header="Profesional" body={templateStaff00} style={{ minWidth: '10rem' }}  ></Column>
                                    <Column field="Fecha" header="Fecha" style={{ minWidth: '6rem' }} bodyClassName="flex justify-content-center" ></Column>
                                    <Column field="progreso" header="Sesiones" style={{ minWidth: '6rem' }} body={templateStatusSesionAll} headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center font-bold"></Column>
                                    <Column field="Total" header="Total" style={{ maxWidth: '8rem' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column>
                                    <Column field="NumeroCuotaPago" header="Cant. Cuota" style={{ maxWidth: '8rem' }} headerClassName="white-space-normal" bodyClassName="flex justify-content-center "
                                        body={templateNumeroCuotaPago}
                                    ></Column>
                                    <Column field="ImporteCuota" header="Importe Cuota" style={{ minWidth: '6rem' }} headerClassName="white-space-normal" bodyClassName="flex justify-content-end "></Column>
                                    <Column field="EstadoPago" header="Estado Pago" style={{ minWidth: '4rem' }} body={templateStatusPagoOP} headerClassName='white-space-normal' bodyClassName="flex justify-content-center " ></Column>
                                </DataTable>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-wrap card-container w-full ">
                        <div className='card w-100 vh-75 m-1 p-1'>
                            <div className="h-3rem w-100 bg-bluegray-100  mt-1 mb-2 border-bottom-round ">
                                <div className="flex flex-wrap align-items-center justify-content-center">
                                    <label className="col text-normal font-bold text-indigo-500" style={{ textAlign: 'left' }} >Nº OP: {vl_IdOrdenPago} </label>
                                    <label className="col text-normal font-bold" style={{ textAlign: 'left' }} >Servicio: {vl_TipoServicio}  </label>
                                    <label className="col text-normal font-bold" style={{ textAlign: 'left' }} >Total: {vl_Total}  </label>
                                    <label className="col text-normal font-bold" style={{ textAlign: 'left' }} >Pagos: {vl_Pagos}  </label>
                                    <label className="col text-normal font-bold" style={{ textAlign: 'left' }} >Saldo: {vl_Saldo}  </label>
                                </div>
                            </div>
                            <div className="flex flex-wrap card-container w-full ">
                                <div className="flex align-items-center justify-content-center m-1">
                                    <label className="p-col-fixed mr-1 ml-1 font-bold " style={{ textAlign: 'end' }}>Realizada</label>
                                    <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                                        inputStyle={{ height: '35px', width: '50px', textAlign: 'center' }}
                                        value={vl_QtySesionExecuted}
                                        readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                                    />
                                </div>
                                <div className="flex align-items-center justify-content-center m-1">
                                    <label className="p-col-fixed mr-1 ml-1 font-bold " style={{ textAlign: 'end' }}>de:</label>
                                    <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                                        inputStyle={{ height: '35px', width: '50px', textAlign: 'center' }}
                                        value={vl_QtySesion}
                                        readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                                    />
                                </div>
                                <div className="flex align-items-center justify-content-center m-1">
                                    <label className="p-col-fixed mr-1 ml-1 font-bold " style={{ textAlign: 'end' }}>Pendte.</label>
                                    <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round bg-yellow-500 '
                                        inputStyle={{ height: '35px', width: '50px', textAlign: 'center' }}
                                        value={vl_QtySesionPending}
                                        readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                                    />
                                </div>
                                <div className="flex align-items-center justify-content-end m-1">
                                    <i className="pi pi-calendar mt-1 ml-3 mr-2 p-text-secondary p-overlay-badge" title='PROGRAMADOS' style={{ fontSize: '1.6rem' }}><Badge value={vl_QtySesionScheduled} ></Badge></i>
                                    <i className="pi pi-check-circle mr-2  p-text-secondary p-overlay-badge" title='REALIZADOS' style={{ fontSize: '1.6rem' }}><Badge value={vl_QtySessionExecuted} severity="success" ></Badge></i>
                                    <i className="pi pi-bell mr-2  p-text-secondary p-overlay-badge" title='NO REALIZADOS' style={{ fontSize: '1.6rem' }}><Badge value={vl_QtySessionNoExecuted} severity="danger" ></Badge></i>
                                    <i className="pi pi-circle-off mr-2 p-text-secondary p-overlay-badge" title='REPROGRAMADOS' style={{ fontSize: '1.6rem' }}><Badge value={vl_QtySesionRescheduled} severity="info"></Badge></i>
                                    <i className="pi pi-volume-off p-text-secondary p-overlay-badge" title='CANCELADOS' style={{ fontSize: '1.6rem' }}><Badge value={vl_QtySesionCanceled} severity="danger"></Badge></i>
                                </div>
                            </div>
                            <div>
                                <DataTable
                                    size="small"
                                    value={dataPlanSesionDetalle ?? []}
                                    dataKey="IdPlanPagoDetalle"
                                    resizableColumns
                                    responsiveLayout="scroll"
                                    columnResizeMode="fit"
                                    showGridlines
                                    stripedRows
                                    scrollable
                                    selectionMode="single"
                                    //// style={{ maxWidth: '500px', height: '278px' }}
                                    style={{ maxWidth: '49vw' }}
                                    scrollHeight="50vh"
                                >
                                    <Column field="Cuota" header="# de Sesión" style={{ maxWidth: '4rem' }} headerClassName='white-space-normal' bodyClassName="flex justify-content-center"  ></Column>
                                    <Column field="Estado" header="Pagado" body={templateStatusPago} style={{ maxWidth: '68px' }} bodyClassName="flex justify-content-center"  ></Column>
                                    <Column field="Importe" header="Costo" style={{ maxWidth: '5rem', align: 'end' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end text-green-800 font-bold"></Column>
                                    <Column field="EstadoSesion" header="Estado cita" body={templateStatusSesion} style={{ maxWidth: '150px' }}  ></Column>
                                    <Column field="" header="Cita" body={templateDetalleCita} style={{ maxWidth: '45px' }} bodyClassName="flex justify-content-center text-cyan-800 font-bold"></Column>
                                    <Column field="EventDateLong" header="Fecha Cita" style={{ maxWidth: '7rem' }} headerClassName='white-space-normal' bodyClassName="flex justify-content-center white-space-normal" ></Column>
                                    <Column field="Staff" header="Profesional" style={{ minWidth: '150px' }} bodyClassName='white-space-normal text-sm' ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>

        </>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import { fetchSinToken } from '../../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';


const ReporteComisionDetallado = ({ ...prop }) => {

    const [vl_tipo, setTipo] = useState<any>('E');
    const [vl_Fecha1, setFecha1] = useState<any>(new Date());
    const [vl_Fecha2, setFecha2] = useState<any>(new Date());
    const [vl_IdSucursal, setIdSucursal] = useState<any>(0);
    const [vl_IdStaff, setIdStaff] = useState<any>(0);
    const [dataComisiones, setdataComisiones] = useState<any>([]);
    const [dataSede, setDataSede] = useState<any>([]);
    const [dataStaff, setDataStaff] = useState<any>([]);

    const toast = useRef<any>(null);

    useEffect(() => {
        pa_get_sys_sucursal();
        pa_get_staff();
        // pa_get_caja_rep_ingresos('0');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_staff_comisiones = async () => {
        // console.log(vl_IdStaff);
        const param = {
            "vp_tipo": vl_tipo,
            "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
            "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
            "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
            "vp_IdStaff": vl_IdStaff ? vl_IdStaff : 0
        }
        if (vl_Fecha1 === '' || vl_Fecha1 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
            return;
        }
        if (vl_Fecha2 === '' || vl_Fecha2 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
            return;
        }
        const response = await fetchSinToken('caja/pa_get_staff_comisiones', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setdataComisiones(record);
    }

    const pa_get_sys_sucursal = async () => {
        const param = {
            "VP_IdSucursal": 0
        }
        const response = await fetchSinToken('selectors/pa_get_sys_sucursal', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataSede(record);
    }

    const pa_get_staff = async () => {
        const param = {
            "vp_opcion": "A",
            "vp_IdStaff": 0,
            "VP_Codigo": "",
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        if(record.length===0){
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No hay registros', life: 8000 });
            return;
        }
        setDataStaff(record);
    }


    const pa_get_staff_comisiones_xls = async () => {
        if (vl_Fecha1 === '' || vl_Fecha1 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
            return;
        }
        if (vl_Fecha2 === '' || vl_Fecha2 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
            return;
        }
        let vp_IdSucursal = vl_IdSucursal ? vl_IdSucursal : 0;
        let vp_IdStaff = vl_IdStaff ? vl_IdStaff : 0;
        const endpoint = 'caja/pa_get_rep02Xls/?vp_Fecha1=' + setIntlDateTimeFormat(vl_Fecha1) + '&vp_tipo=' + vl_tipo + '&vp_IdSucursal=' + vp_IdSucursal + '&vp_IdStaff=' + vp_IdStaff + '&vp_Fecha2=' + setIntlDateTimeFormat(vl_Fecha2);
        // console.log(endpoint);
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    return (
        <>
            <Toast ref={toast} position="center"></Toast>
            <div className={prop.bhidden + ' card w-full p-2 ml-2'} >
                <div className='card p-1 bg-gray-50'>
                    <div className="p-col pb-1 ml-5 ">
                        <RadioButton value="E" name="btnTipofecha"
                            onChange={(e) => setTipo(e.value)}
                            checked={vl_tipo === "E"}
                        /><label className="p-col-fixed ml-2 mr-4 font-normal text-sm " style={{ textAlign: 'end' }}>F. Atención</label>
                        <RadioButton value="P" name="btnTipofecha"
                            onChange={(e) => setTipo(e.value)}
                            checked={vl_tipo === "P"}
                        /><label className="p-col-fixed ml-1 mr-0 font-normal text-sm " style={{ textAlign: 'end' }}>F. Pago</label>
                    </div>
                    <div className="flex flex-wrap card-container w-full ">
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed mr-1 ml-2 text-base font-semibold " style={{ textAlign: 'end' }}>Del:</label>
                            <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                                maxDate={new Date()}
                                value={vl_Fecha1}
                                onChange={(e) => setFecha1(e.value)}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Al:</label>
                            <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                                maxDate={new Date()}
                                value={vl_Fecha2}
                                onChange={(e) => setFecha2(e.value)}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Sede:</label>
                            <Dropdown style={{ width: '12rem' }} showClear
                                value={vl_IdSucursal}
                                options={dataSede}
                                onChange={(e: any) => {
                                    setIdSucursal(e.value);
                                }}
                                optionValue="IdSucursal"
                                optionLabel="Descripcion"
                                placeholder="Selecionar" />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Profesional:</label>
                            <Dropdown style={{ width: '15rem' }} filter showClear filterBy="staff"
                                value={vl_IdStaff}
                                options={dataStaff}
                                onChange={(e: any) => {
                                    setIdStaff(e.value);
                                }}
                                optionValue="IdStaff"
                                optionLabel="staff"
                                placeholder="Profesional" />
                                <Button title="Buscar"
                                icon="pi pi-search"
                                className="p-button-rounded  ml-2"
                                onClick={() => pa_get_staff_comisiones()}
                            />
                            <Button title="Exportar a excel"
                                icon="pi pi-file-excel"
                                className="p-button-rounded p-button-text ml-1 mr-1"
                                label='Excel'
                                onClick={(e: any) => pa_get_staff_comisiones_xls()}
                            />
                        </div>
                       
                    </div>
                </div>
                <div className='mt-1 p-0 w-full'>
                    <DataTable size="small" resizableColumns
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        value={dataComisiones}
                        selectionMode="single"
                        // scrollHeight="300px"
                        scrollHeight="50vh"
                        paginator
                        rows={50}
                    >
                        <Column field="Staff" header="Profesional" style={{ minWidth: '200px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="white-space-normal"
                        >
                        </Column>
                        <Column field="FechaEfectiva" header="Fecha" style={{ minWidth: '100px' }}  ></Column>
                        <Column field="Paciente" style={{ minWidth: '15rem' }} header="Paciente"></Column>                        
                        <Column field="NumeroHistoriaClinica" header="Nº HC" style={{ maxWidth: '7rem' }} headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center" ></Column>                        

                        <Column field="ServicioDet" header="Servicio" style={{ minWidth: '300px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="white-space-normal"
                        >
                        </Column>
                        <Column field="Base" header="Costo servicio" style={{ maxWidth: '100px' }}
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="Porcentage" header="% Apl."
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-center text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="ImporteFijo" header="Comisión fija"
                            headerClassName="flex justify-content-center white-space-normal "
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="Importe" header="Comisión aplicada"
                            headerClassName="flex justify-content-center  white-space-normal "
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="ImporteAdicional" header="Valor Adicional"
                            headerClassName="flex justify-content-center white-space-normal"
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        >
                        </Column>
                        <Column field="Descuentos" header="Descto."
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-end text-red-500 font-bold"
                        >
                        </Column>
                        <Column field="Total" header="Total"
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-end text-green-600 font-bold"
                        >
                        </Column>
                        
                    </DataTable>

                </div>
            </div>
        </>
    )
}

export default ReporteComisionDetallado;
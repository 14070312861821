import { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { fetchSinToken } from '../../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import { FooterGridQuantity } from '../../../components/util/FooterGrid';
import { Checkbox } from 'primereact/checkbox';

const ReporteAgendaLastDates = ({ ...prop }) => {

    const [VP_ExcluirFecha, setExcluirFecha] = useState<boolean>(true);
    const [VP_Fecha1, setFecha1] = useState<any>(new Date());
    const [VP_Fecha2, setFecha2] = useState<any>(new Date());
    const [VP_IdStaff, setIdStaff] = useState<any>(0);
    const [VP_IdSucursal, setIdSucursal] = useState<any>(0);
    const [VP_IdCliente, setIdCliente] = useState<any>(0);
    const [VP_EventState, setEventState] = useState<any>('');
    const [VP_EventType, setEventType] = useState<any>('');
    const [VP_TipoServicio, setTipoServicio] = useState<any>('');
    const [VP_Servicio, setServicio] = useState<any>('');
    const [VP_ServicioDescripcion, setServicioDescripcion] = useState<any>('');

    const [VP_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');

    // combos    
    const [dataSede, setDataSede] = useState<any>([]);
    const [dataStaff, setDataStaff] = useState<any>([]);
    const [dataServicio, setDataServicio] = useState<any>([]);
    const [dataTipoAtencion, setDataTipoAtencion] = useState<any>([]);
    const [dataEventState, setDataEventState] = useState<any>([]);

    //grid data
    const [dataGrid, setDataGrid] = useState<any>([]);

    const toast = useRef<any>(null);
    // const [vl_disabledEdad1, setDisabledEdad1] = useState<boolean>(true);


    useEffect(() => {
        pa_get_sys_sucursal();
        pa_get_staff();
        pa_get_tipo_servicio();
        pa_get_sys_config('TipoCita');
        pa_get_sys_config('EstadoCita');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_sys_sucursal = async () => {
        const param = {
            "VP_IdSucursal": 0
        }
        const response = await fetchSinToken('selectors/pa_get_sys_sucursal', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataSede(record);
    }
    const pa_get_staff = async () => {
        const param = {
            "vp_opcion": "A",
            "vp_IdStaff": 0,
            "VP_Codigo": "",
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataStaff(record);
    }

    const pa_get_tipo_servicio = async () => {
        const param = {
            "vp_Tipo": "", //all
            "vp_estado": ""
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_servicio', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataServicio(record);
    }

    const pa_get_sys_config = async (p_Identificador: any) => {
        const param = {
            "VP_Identificador": p_Identificador,
            "VP_Estado": "A"
        }
        const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record[0].RecordSet);
        switch (p_Identificador) {
            case 'TipoCita':
                setDataTipoAtencion(record[0].RecordSet);
                break;
            case 'EstadoCita':
                setDataEventState(record[0].RecordSet);
                break;
            default:
                break;
        }

    }

    const pa_get_agenda_reportes_ld = async () => {
        const param = {
            "VP_ExcluirFecha": VP_ExcluirFecha ? 1 : 0,
            "VP_Fecha1": setIntlDateTimeFormat(VP_Fecha1),
            "VP_Fecha2": setIntlDateTimeFormat(VP_Fecha2),
            "VP_IdStaff": VP_IdStaff,
            "VP_IdSucursal": VP_IdSucursal ? VP_IdSucursal : 0,
            "VP_IdCliente": VP_IdCliente ? VP_IdCliente : 0,
            "VP_EventState": VP_EventState ? VP_EventState : '',
            "VP_EventType": VP_EventType ? VP_EventType : '',
            "VP_TipoServicio": VP_TipoServicio ? VP_TipoServicio : '',
            "VP_Servicio": VP_ServicioDescripcion ? VP_ServicioDescripcion : '',
            "VP_NumeroHistoriaClinica": VP_NumeroHistoriaClinica ? VP_NumeroHistoriaClinica : ''
        }

        if (VP_ExcluirFecha) {
            if (!VP_Fecha1 || !VP_Fecha2) {
                toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar rango de fecha', life: 8000 });
                return;
            }
        }

        const response = await fetchSinToken('agenda/pa_get_agenda_reportes_ld', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record.length);
        if (record.length === 0) {
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No hay registros', life: 8000 });
            setDataGrid([]);
            return;
        }
        setDataGrid(record);
    }

    const pa_get_agenda_reportes_ld_xls = async () => {

        let VL_ExcluirFecha = VP_ExcluirFecha ? 1 : 0;
        let VL_Fecha1 = setIntlDateTimeFormat(VP_Fecha1);
        let VL_Fecha2 = setIntlDateTimeFormat(VP_Fecha2);
        let VL_IdStaff = VP_IdStaff;
        let VL_IdSucursal = VP_IdSucursal ? VP_IdSucursal : 0;
        let VL_IdCliente = VP_IdCliente ? VP_IdCliente : 0;
        let VL_EventState = VP_EventState ? VP_EventState : '';
        let VL_EventType = VP_EventType ? VP_EventType : '';
        let VL_TipoServicio = VP_TipoServicio ? VP_TipoServicio : '';
        let VL_Servicio = VP_ServicioDescripcion ? VP_ServicioDescripcion : '';
        let VL_NumeroHistoriaClinica = VP_NumeroHistoriaClinica ? VP_NumeroHistoriaClinica : '';

        if (VL_ExcluirFecha) {
            if (!VL_Fecha1 || !VL_Fecha2) {
                toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar rango de fecha', life: 8000 });
                return;
            }
        }
        const endpoint = 'agenda/pa_get_rep01Xls/?VP_ExcluirFecha=' + VL_ExcluirFecha +
            '&VP_Fecha1=' + VL_Fecha1 +
            '&VP_Fecha2=' + VL_Fecha2 +
            '&VP_IdStaff=' + VL_IdStaff +
            '&VP_IdSucursal=' + VL_IdSucursal +
            '&VP_IdCliente=' + VL_IdCliente +
            '&VP_EventState=' + VL_EventState +
            '&VP_EventType=' + VL_EventType +
            '&VP_TipoServicio=' + VL_TipoServicio +
            '&VP_Servicio=' + VL_Servicio +
            '&VP_NumeroHistoriaClinica=' + VL_NumeroHistoriaClinica;
        // console.log(endpoint);
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    const footer = () => {
        let vl_TotalBase: any = dataGrid.length;
        return (
            <>
                <div className='flex align-items-stretch justify-content-end'>
                    <FooterGridQuantity Total={vl_TotalBase} Title={'Ttl. Registros:'} />
                </div>
            </>
        );
    }

    return (
        <>
            <Toast ref={toast} position="top-center"></Toast>
            <div className={prop.bhidden + ' card w-full p-2 ml-2'} >
                <div className='card p-1 m-1 bg-gray-50'>
                    <div className="flex flex-wrap card-container w-full ">

                        <div className="flex align-items-center justify-content-center m-0">
                            <label className="p-checkbox-label text-base font-semibold pl-1 mr-2" style={{ textAlign: 'end' }} > Fecha </label>
                            <div className="field-checkbox">
                                <Checkbox className="mr-2" onChange={e => {
                                    setExcluirFecha(e.checked);
                                    // setFechaVigDesdeDisabled(false);
                                    // setFechaVigHastaDisabled(false);
                                    // if (!e.checked) {
                                    //     setFechaVigDesdeDisabled(true);
                                    //     setFechaVigHastaDisabled(true);
                                    // }
                                }} checked={VP_ExcluirFecha}> </Checkbox>
                            </div>
                            <label className="p-col-fixed mr-1 ml-2 text-base font-semibold " style={{ textAlign: 'end' }}>Del:</label>
                            <Calendar style={{ width: '150px' }} inputClassName="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true}
                                //maxDate={new Date()}
                                value={VP_Fecha1}
                                onChange={(e) => setFecha1(e.value)}
                            />
                            <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Al:</label>
                            <Calendar style={{ width: '150px' }} inputClassName="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true}
                                //maxDate={new Date()}
                                value={VP_Fecha2}
                                onChange={(e) => setFecha2(e.value)}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Sede:</label>
                            <Dropdown style={{ width: '12rem' }} showClear className="p-inputtext-sm"
                                value={VP_IdSucursal}
                                options={dataSede}
                                onChange={(e: any) => {
                                    setIdSucursal(e.value);
                                }}
                                optionValue="IdSucursal"
                                optionLabel="Descripcion"
                                placeholder="Selecionar" />
                        </div>
                        <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Servicio:</label>
                            <Dropdown style={{ width: '9rem' }} showClear className="p-inputtext-sm"
                                value={VP_TipoServicio}
                                options={[
                                    { "Codigo": "C", "Descripcion": "CONSULTA" },
                                    { "Codigo": "A", "Descripcion": "ATENCION" },
                                    { "Codigo": "T", "Descripcion": "TERAPIA" },
                                    { "Codigo": "P", "Descripcion": "OTROS" }
                                ]}
                                onChange={(e: any) => {
                                    setTipoServicio(e.value);
                                }}
                                optionValue="Codigo"
                                optionLabel="Descripcion"
                            />
                            <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'center' }}>-</label>
                            <Dropdown style={{ width: '15rem' }} filter showClear filterBy="Descripcion" className="p-inputtext-sm"
                                value={VP_Servicio}
                                options={dataServicio}
                                onChange={(e: any) => {
                                    setServicio(e.value);
                                    let itemsfiltered: any = dataServicio.filter(function (element: any) {
                                        return element.CodigoServicio === e.value;
                                    });
                                    setServicioDescripcion(itemsfiltered[0]?.Descripcion);
                                }}
                                optionValue="CodigoServicio"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />
                        </div>

                        <div className="flex align-items-center justify-content-center mt-1" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Estado:</label>
                            <Dropdown style={{ width: '9rem' }} showClear className="p-inputtext-sm"
                                value={VP_EventState}
                                options={dataEventState}
                                onChange={(e: any) => {
                                    setEventState(e.value);
                                }}
                                optionValue="Codigo"
                                optionLabel="Descripcion"
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>T. Cita:</label>
                            <Dropdown style={{ width: '10rem' }} showClear className="p-inputtext-sm"
                                options={dataTipoAtencion}
                                value={VP_EventType}
                                onChange={(e: any) => {
                                    setEventType(e.value);
                                }}
                                optionValue="Codigo"
                                optionLabel="Descripcion" />
                        </div>
                        <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Profesional:</label>
                            <Dropdown style={{ width: '15rem' }} filter showClear filterBy="staff" className="p-inputtext-sm"
                                value={VP_IdStaff}
                                options={dataStaff}
                                onChange={(e: any) => {
                                    setIdStaff(e.value);
                                }}
                                optionValue="IdStaff"
                                optionLabel="staff"
                                placeholder="Profesional" />

                            <Button title="Buscar"
                                icon="pi pi-search"
                                className="p-button-rounded  ml-2"
                                onClick={() => pa_get_agenda_reportes_ld()}
                            />
                            <Button title="Exportar a excel"
                                icon="pi pi-file-excel"
                                className="p-button-rounded p-button-text ml-1 mr-1"
                                label='Excel'
                                onClick={(e: any) => pa_get_agenda_reportes_ld_xls()}
                            />
                        </div>
                    </div>
                </div>
                <div className='card p-1 m-1'>
                    <DataTable size="small" resizableColumns
                        footer={footer}
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        value={dataGrid}
                        selectionMode="single"
                        // selection={selectedGrid1}
                        // onSelectionChange={e => setSelectedGrid1(e.value)}
                        scrollHeight="50vh"
                        paginator
                        rows={100}
                    >
                        <Column field="Paciente" style={{ minWidth: '15rem' }} header="Paciente"></Column>
                        <Column field="NumeroHistoriaClinica" header="Nº HC" style={{ minWidth: '7rem' }} headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center" ></Column>
                        <Column field="Edad" header="Edad" style={{ maxWidth: '4rem' }} bodyClassName="flex justify-content-center"></Column>
                        <Column field="Sexo" header="Genero" style={{ maxWidth: '4rem' }} bodyClassName="flex justify-content-center"></Column>
                        {/* <Column field="IdAgenda" header="#ID Agenda" headerClassName="white-space-normal" style={{ maxWidth: '5rem' }}></Column>
                        <Column field="Fecha" header="Fecha cita" style={{ maxWidth: '7rem' }} headerClassName="white-space-normal" ></Column>
                        <Column field="EventStart" header="Inicio" style={{ maxWidth: '7rem' }} headerClassName='white-space-normal' bodyClassName="flex justify-content-center" ></Column>
                        <Column field="EventEnd" header="Fin" style={{ maxWidth: '7rem' }} headerClassName='white-space-normal' bodyClassName="flex justify-content-center" ></Column> */}
                        <Column field="Psicologo" header="Psicólogo" style={{ minWidth: '15rem' }} headerClassName='white-space-normal' bodyClassName="flex justify-content-left" ></Column>
                        {/* <Column field="EventType_1" header="Tipo" style={{ maxWidth: '7rem' }} headerClassName='white-space-normal' bodyClassName="flex justify-content-left" ></Column>
                        <Column field="EventStateLabel" header="Estado" style={{ maxWidth: '7rem' }} headerClassName='white-space-normal' bodyClassName="flex justify-content-center" ></Column> */}
                        <Column field="EventTitle" header="Servicio" style={{ minWidth: '15rem' }}></Column>
                        {/* <Column field="Costo" header="Costo" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end"></Column> */}
                        <Column field="NumeroSesion" header="Sesión" style={{ maxWidth: '4rem' }} headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center"></Column>
                        <Column field="QtyTotalSesion" header="Total Sesión" style={{ maxWidth: '4rem' }} headerClassName="white-space-normal" bodyClassName="flex justify-content-center"></Column>
                        {/* <Column field="Pagado" header="Pagado" style={{ maxWidth: '4rem' }} headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center"></Column> */}
                        {/* <Column field="Notificado" header="Cant. Notif." style={{ maxWidth: '4rem' }} headerClassName="white-space-normal" bodyClassName="flex justify-content-center"></Column> */}
                        <Column field="Ultima_Sesion" header="F. última Sesión." style={{ minWidth: '8rem' }} headerClassName="white-space-normal" bodyClassName="white-space-normal"></Column>
                        <Column field="dias" header="Cant. dias" sortable style={{ maxWidth: '7rem' }} headerClassName="white-space-normal" bodyClassName="flex justify-content-center"></Column>
                        <Column field="Fecha_Now" header="A la Fecha" style={{ minWidth: '7rem' }} headerClassName="white-space-normal" bodyClassName="white-space-normal"></Column>
                        <Column field="Sede" header="Sede"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    )
}
export default ReporteAgendaLastDates;


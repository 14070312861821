import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const OrdenPagos = ({ ...props }) => {
    
    return (
        <>
            <DataTable
                size="small"
                value={props.dataOrdenPago ?? []}
                dataKey="IdOrdenPagoDetalle"
                resizableColumns
                responsiveLayout="scroll"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                selectionMode="single"
                scrollHeight='200px'                
                style={{ height: '210px'}}
                selection={ props.selectedIemsGridOP }
                onSelectionChange={
                    (e) => {
                        // console.log( e.value )
                        if(props.onMode === 'U' ) return;
                        props.setSelectedIemsGridOP(e.value);                        
                        props.onSelectedOP(e.value);
                    }
                }
            >
                <Column selectionMode="single" style={{ maxWidth: '50px' }} ></Column>
                <Column field="Tipo" header="Tipo"
                    // body={template_OrdenPagoDetalle} //no implementado x ahora
                    style={{ maxWidth: '70px' }}
                    bodyClassName="flex justify-content-center"  ></Column>                
                <Column field="Detalle" header="Detalle Servicio" bodyClassName="flex justify-content-left" ></Column>    
                <Column field="Importe" header="Importe" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column>                
                <Column field="NumeroCuotas" header="T. Ses." style={{ maxWidth: '70px' }}bodyClassName="flex justify-content-center" ></Column>                
                <Column field="Total" header="Total" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column>                
            </DataTable>
        </>
    )
}
import { Player } from '@lottiefiles/react-lottie-player';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
const NoMatchPage = () => {
    const navigate = useNavigate();
    const handleHomePage = () => {
        navigate('AccountHome');
    }
    return (
        <>
            <section className="border-round-bottom h-5rem bg-gradient-indigo-header">
                <div className="container pb-2">
                    <nav className="navbar navbar-expand-lg">
                        <img className="navbar-logo navbar-logo-light" src="../logo-sys.png" alt="Logo"></img>
                        <span className='text-sm font-base text-white ' >PSISABE </span>
                    </nav>
                </div>
            </section>

            <div className="flex justify-content-center flex-wrap card-container">
                <div className="flex align-items-center justify-content-center w-100 h-3rem font-bold text-base border-round m-2">
                    <h1>404 Not Found</h1>
                </div>
                <div className="flex align-items-center justify-content-center w-100 h-1rem font-bold text-base border-round m-2">
                    <h3>No match for <code>{window.location.pathname}</code></h3>
                </div>
                <div className="flex align-items-center justify-content-center w-100 h-1rem font-bold text-base border-round m-2">
                    <label>The requested URL was not found on this server.</label>
                </div>
                {/* <div className="flex align-items-center justify-content-center w-100 h-1rem font-bold text-base border-round m-2">
                    <img className="" src="../error-404.png" alt="error 404"></img>    
                </div> */}
                <div>
                    <Player
                        // ref={this.player} // set the ref to your class instance
                        autoplay={true}
                        loop={true}
                        controls={true}
                        src="../lottie-player/51972-error-404.json"
                        style={{ height: '400px', width: '400px' }}
                        speed={1}
                    ></Player>
                </div>
                <div className="flex align-items-center justify-content-center w-100 h-1rem font-bold text-base border-round m-2">
                    <Button label='Go to home page' icon='pi pi-home' onClick={handleHomePage} />
                </div>
            </div>


        </>
    )
};

export default NoMatchPage;
import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
// import { InputTextarea } from 'primereact/inputtextarea';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
// import { PickList } from 'primereact/picklist';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { fetchSinToken } from '../../../../helpers/fetch';
// import { setIntlDateTimeFormat, setIntlTimeFormat } from '../../../../helpers/functions';

// Componentes del formulario
import { Filter } from './Filter';
import { DatoPaciente } from './DatoPaciente';
import { OrdenPagos } from './OrdenPagos';
import { FooterGridNumber } from '../../util/FooterGrid';
import TitleForm from '../../util/TitleForm';


export const OrdenAtencionForm = ({ ...props }) => {

    //valores INPUT Cliente/Paciente
    const [vl_IdCliente, setInputValIdCliente] = useState<any>('0');
    const [vl_Nombre, setInputValNombre] = useState('');
    const [vl_ApPaterno, setInputValApPaterno] = useState('');
    const [vl_ApMaterno, setInputValApMaterno] = useState('');
    const [vl_IdTipoDocumento, setInputValIdTipoDocumento] = useState<any>('');
    const [vl_NumeroDocumento, setInputValNumeroDocumento] = useState('');
    const [vl_Edad, setInputValEdad] = useState<number>(0);
    const [vl_Sexo, setInputValSexo] = useState<any>('');

    // Inputs Crud 
    const [vl_ACTION, setInputValACTION] = useState('I');
    const [vl_IdRegistro, setInputValIdRegistro] = useState('0');
    const [vl_IdOrdenAtencion, setInputValIdOrdenAtencion] = useState('0');
    const [vl_FechaAtencion, setInputValFechaAtencion] = useState<any>(new Date());
    const [vl_HoraAtencion, setInputValHoraAtencion] = useState<any>(new Date());
    const [vl_NumeroOrden, setInputValNumeroOrden] = useState('');
    const [vl_IdHistoriaClinica, setInputValIdHistoriaClinica] = useState('0');
    const [vl_NumeroHistoriaClinica, setInputValNumeroHistoriaClinica] = useState('');
    // const [vl_Pagado, setInputValPagado] = useState<boolean>(false);
    // const [vl_MontoPagado, setInputValMontoPagado] = useState<any>('0.00');
    // const [vl_IdFormaPago, setInputValIdFormaPago] = useState<any>('');
    // const [vl_ReferenciaPago, setInputValReferenciaPago] = useState<any>('');
    const [vl_Observaciones, setInputValObservaciones] = useState<any>('');
    const [vl_IdEspecialidad, setInputValIdEspecialidad] = useState(1);
    const [vl_IdStaff, setInputValIdStaff] = useState(1);
    const [vl_Validez, setInputValValidez] = useState<boolean>(false);
    const [vl_Dias, setInputValDias] = useState('0');

    const [vl_Importe, setImporte] = useState<number>(0.0);
    const [vl_Descuentopje, setDescuentopje] = useState<number>(0.0);
    const [vl_Descuento, setDescuento] = useState<number>(0.0);

    const [vl_TotalInversion, setInputValTotalInversion] = useState<any>(0.0);
    const [vl_Cuota, setInputValCuota] = useState<boolean>(false);
    const [vl_NumeroCuotas, setInputValNumeroCuotas] = useState<number>(0);
    // const [vl_ImporteCuotas, setInputValImporteCuotas] = useState<number>(0);
    // const [vl_ImporteSaldo, setInputValImporteSaldo] = useState<number>(0);
    // const [vl_MontoTotalSel, setInputValMontoTotalSel] = useState<number>(0);
    // const [vl_TipoPago, setInputValTipoPago] = useState('M');
    const [vl_NumeroSesiones, setNumeroSesiones] = useState('0');
    const [vl_FrecuenciaAtencion, setFrecuenciaAtencion] = useState<any>('');
    const [vl_CantidadSel, setCantidadSel] = useState<any>('');


    const [dataEspecialidad, setDataEspecialidad] = useState([]);
    const [dataIdStaff, setDataIdStaff] = useState([]);
    const [dataCodigoServicios, setDataCodigoServicio] = useState<any>([]);
    const [dataIdTipoDocumento_1, setDataIdTipoDocumento_1] = useState([]);
    // const [dataIdFormaPago, setIdDataFormaPago] = useState([]);
    // const [dataPlanPagos, setDataPlanPagos] = useState<any>([]);
    const [dataOrdenPago, setDataOrdenPago] = useState<any>([]);
    const [dataPlanCuotasPrevio, setDataPlanCuotasPrevio] = useState<any>([]);


    const [selectedIemsGrid, setSelectedIemsGrid] = useState<any>([]);
    const [editingRows, setEditingRows] = useState<any>([]);

    const [activeIndex, setActiveIndex] = useState(0);
    const [chkValEvaluacion, setChkValEvaluacion] = useState<boolean>(false);
    const [chkValInterConsulta, setChkValInterConsulta] = useState<boolean>(false);
    const [chkValInformePsi, setChkValInformePsi] = useState<boolean>(false);

    //Visible
    const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const [b_visible1, setVisibleConfirmDialog1] = useState<boolean>(false);

    const [b_hiddenGrabar, setHiddenbtnGrabar] = useState<boolean>(false);
    // const [b_hiddenEdit, setHiddenbtnEdit] = useState<boolean>(false);
    const [b_hiddenAnular, setHiddenbtnAnular] = useState<boolean>(true);

    const [deleteItemDialog, setDeleteItemDialog] = useState<boolean>(false);

    //OverlayPanel
    // const [source, setSource] = useState<any>([]);
    const [target, setTarget] = useState<any>([]);

    //Messages
    const toast = useRef<any>(null);
    const toastConfirm = useRef<any>(null);

    const op = useRef<OverlayPanel>(null);
    const oplanPago = useRef<OverlayPanel>(null);

    // Input ref        
    // const inputMontoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputNumeroCuotasRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    // const inputImporteCuotasRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    // const inputReferenciaPagoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputObservacionesRef = useRef<HTMLElement | HTMLInputElement | any>(null);

    //
    const inputNumeroHistoriaClinicaRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputDiasRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    const inputDescuentopjeRef = useRef<HTMLElement | HTMLInputElement | any>(null);
    // const inputDescuentoRef = useRef<HTMLElement | HTMLInputElement | any>(null);

    //disabled INPUTs
    const [disabledNumeroHistoriaClinica, setDisabledNumeroHistoriaClinica] = useState(false);

    // const [disabledNumeroDocumento, setDisabledNumeroDocumento] = useState(false);
    // const [disabledMonto, setDisabledMonto] = useState(false);
    // const [disabledIdFormaPago, setDisabledIdFormaPago] = useState(false);
    // const [disabledReferenciaPago, setDisabledReferenciaPago] = useState(false);
    //
    // const [disabledNumeroCuotas, setDisabledNumeroCuotas] = useState(false);
    // const [disabledImporteCuotas, setDisabledImporteCuotas] = useState(false);
    const [disabledDias, setDisabledDias] = useState(false);
    const [disabledDescuentopje, setDisabledDescuentopje] = useState(true);
    const [disabledDescuento, setDisabledDescuento] = useState(true);
    //
    const [vl_aplDescuento, setAplDescuento] = useState<boolean>(false);
    const [vl_IdTipoDocumento_1, setIdTipoDocumento_1] = useState<any>('BVT');
    //new
    const [vl_NumeroCuotas2, setNumeroCuotas2] = useState<number>(0);
    const [dataAcuerdoCuotas, setDataAcuerdoCuotas] = useState<any>([]);

    // set Values
    const onIdEspecialidadChange = (e: { value: any }) => {
        setInputValIdEspecialidad(e.value);
    }
    // const onIdFormaPagoChange = (e: { value: any }) => {
    //     // setInputValIdFormaPago(e.value);
    // }
    const onIdStaffChange = (e: { value: any }) => {
        setInputValIdStaff(e.value);
    }
    const onChangeSwitchValidez = (e: any) => {
        const isValidez: boolean = e.value;
        setInputValValidez(isValidez);
        setInputValDias('0');
        setDisabledDias(true);
        if (isValidez) {
            setDisabledDias(false);
            setInputValDias('7');
            setTimeout(() => {
                inputDiasRef.current?.select();
            }, 100);
        }
    }
    const onChangeSwitchAplDescuento = (e: any) => {
        const isFlag: boolean = e.value;
        setAplDescuento(isFlag);
        setDisabledDescuentopje(true);
        setDescuentopje(0.00);
        setDescuento(0.00);
        setAplicarDescuentoPje(0.00);
        setAplicarDescuentoMontoFijo(0.00);
        if (isFlag) {
            setDisabledDescuentopje(false);
            setDisabledDescuento(false);
            setTimeout(() => {
                // console.log(inputDescuentopjeRef.current);
                inputDescuentopjeRef.current.focus();
            }, 100);
        }

    }
    const setAplicarDescuentoPje = (Descuentopje: any) => {
        let v_descuento: any = 0;
        v_descuento = (Descuentopje !== 0 ? vl_Importe * Descuentopje / 100 : 0);
        // console.log('Descuento>>' + v_descuento);
        let v_Total: any = vl_Importe - v_descuento;
        // console.log('v_Total>>' + v_descuento);
        setDescuento(v_descuento);
        setInputValTotalInversion(v_Total);
        //recalcula
        if (v_Total !== vl_TotalInversion) {
            pa_simulador_cuotas(v_Total, vl_NumeroCuotas);
            setImporteCuotas2(vl_NumeroCuotas2, v_Total);
        }

    }
    const setAplicarDescuentoMontoFijo = (Descuento: any) => {
        // console.log('vl_Importe>>' + vl_Importe);
        // console.log('Descuento>>' + Descuento);
        let v_Total: any = vl_Importe - Descuento;
        // console.log('v_Total>>' + v_Total);
        let v_descuento: any = (Descuento / vl_Importe * 100);
        setDescuentopje(v_descuento);
        setInputValTotalInversion(v_Total);
        //recalcula
        if (v_Total !== vl_TotalInversion) {
            pa_simulador_cuotas(v_Total, vl_NumeroCuotas);
            setImporteCuotas2(vl_NumeroCuotas2, v_Total);
        }
    }


    const onChangeSwitchCuota = (e: any) => {
        const isCuota: boolean = e.value;
        setInputValCuota(isCuota);
        setInputValNumeroCuotas(0);
        //setInputValImporteCuotas(0);
        // setDisabledNumeroCuotas(true);
        //setCalculaImporteSaldo(vl_TotalInversion, vl_MontoPagado, isCuota);
        if (isCuota) {
            // setDisabledNumeroCuotas(false);
            setInputValNumeroCuotas(1);
            //const vl_ImporteCuotas: number = setCalcularCuota(vl_TotalInversion);
            //setInputValImporteCuotas(vl_ImporteCuotas);
            //inputNumeroCuotasRef.current?.select();
        }
    }


    const pa_get_tipo_servicio = async () => {
        const param = {
            "vp_Tipo": "A",
            "vp_estado": ""
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_servicio', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataCodigoServicio(record);
        // setSource(record);
    }

    const pa_get_forma_pago = async () => {
        // const param = {
        //     "vp_IdFormaPago": "",
        //     "vp_estado": ""
        // }
        // const response = await fetchSinToken('selectors/pa_get_forma_pago', param, 'POST');
        // const resp = await response.json();
        // const record = resp.data;
        // setIdDataFormaPago(record);
    }

    const pa_get_especialidad = async () => {
        //e.preventDefault();        
        const param = {
            "vp_opcion": "S",
            "vp_IdEspecialidad": "0",
            "vp_Descripcion": "",
            "vp_Estado": "0"
        }
        const response = await fetchSinToken('selectors/pa_get_especialidad', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataEspecialidad(record);
    }

    const pa_get_tipo_comprobante = async () => {
        const param = {
            "VP_Grupo": "V"
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_comprobante', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataIdTipoDocumento_1(record);
    }

    const pa_get_staff = async () => {
        //e.preventDefault();
        const param = {
            "vp_opcion": "",
            "vp_IdStaff": "0",
            "VP_Codigo": "",
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataIdStaff(record);
    }

    // const pa_get_plan_pagos = async (IdPlanPago: any) => {
    //     // console.log('IdPlanPago:'+ IdPlanPago );        
    //     if (Number(IdPlanPago) > 0) {
    //         const param = {
    //             "vp_IdPlanPago": IdPlanPago
    //         }
    //         const response = await fetchSinToken('admision/pa_get_plan_pagos', param, 'POST');
    //         const resp = await response.json();
    //         const record = resp.data;
    //         setDataPlanPagos(record);
    //     }
    // }
    const pa_get_orden_pago = async (IdRegistro: any) => {
        // console.log('IdPlanPago:'+ IdPlanPago );        
        if (Number(IdRegistro) > 0) {
            const param = {
                "VP_IdRegistro": IdRegistro,
                "VP_IdCliente": '0',
                "VP_NumHistoriaClinica": '',
                "vp_FechaDesde": '',
                "vp_FechaHasta": '',
                "vp_EstadoPago": '',
                "vp_IdSucursal": localStorage.getItem('authIdSucursal')
            }
            const response = await fetchSinToken('admision/pa_get_orden_pago', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataOrdenPago(record);
        }
    }
    const pa_simulador_cuotas = async (VP_Total: any, VP_NumeroCuotas: any) => {
        const param = {
            "VP_Total": VP_Total,
            "VP_NumeroCuotas": VP_NumeroCuotas
        }
        // console.log(VP_Total);
        if (parseFloat(VP_Total) === 0) {
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'Ingrese importe', life: 8000 });
            return;
        }
        if (parseFloat(VP_NumeroCuotas) === 0) {
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'Ingrese Numero de Cuotas', life: 8000 });
            return;
        }
        const response = await fetchSinToken('ordenPago/pa_simulador_cuotas', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataPlanCuotasPrevio(record);
    }

    //
    // useEffect: Se ejecuta después de cada cambio y cuando este renderizado por completado
    //
    useEffect(() => {
        // console.log(props.isMode);

        // VALOR NO USADO
        setEditingRows([]);

        setInputValACTION(props.isMode);
        pa_get_tipo_servicio();
        pa_get_especialidad();
        pa_get_staff();
        pa_get_forma_pago();
        pa_get_tipo_comprobante();

        //control form segun mode(crud)
        setHiddenbtnGrabar(true);
        setHiddenbtnAnular(true);
        //setHiddenbtnEdit(true);
        setDisabledNumeroHistoriaClinica(false);
        switch (props.isMode) {
            // case 'I': //INSERT MODE
            //     setTimeout(() => {
            //         inputNumeroHistoriaClinicaRef.current?.focus();
            //         setHiddenbtnGrabar(false);
            //     }, 100);
            //     break;
            case 'G': //GENERA OP
                getRecorOrdenatencion();
                setDisabledNumeroHistoriaClinica(true);
                //setHiddenbtnEdit(false);
                setHiddenbtnGrabar(false);
                break;
            case 'A': //ANULAR O/P MODE
                getRecorOrdenatencion();
                setHiddenbtnGrabar(true);
                setHiddenbtnAnular(false);
                setDisabledNumeroHistoriaClinica(true);
                setTimeout(() => {
                    inputObservacionesRef.current?.select();
                }, 100);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRecorOrdenatencion = () => {
        // console.log('getRecorOrdenatencion');
        console.log(props.isData);
        setInputValIdRegistro(props.isData.IdRegistro);
        setInputValIdOrdenAtencion(props.isData.IdOrdenAtencion);
        setInputValNumeroHistoriaClinica(props.isData.NumHistoriaClinica);
        setInputValIdHistoriaClinica(props.isData.IdHistoriaClinica);
        // console.log( new Date(Date.parse( '2022-01-10 17:48:54' )) );    
        // console.log(new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'long' }).format( props.isData.HoraAtencion ));
        // console.log(new Date(Date.parse(props.isData.FechaAtencion )));
        setInputValFechaAtencion(new Date(Date.parse(props.isData.FechaAtencion)));
        setInputValHoraAtencion(new Date(Date.parse(props.isData.FechaAtencion_2)));
        setInputValNumeroOrden(props.isData.NumeroOrden);
        setInputValIdCliente(props.isData.IdCliente);
        //Cliente 
        setInputValNombre(props.isData.Nombres);
        setInputValApPaterno(props.isData.ApPaterno);
        setInputValApMaterno(props.isData.ApMaterno);
        setInputValIdTipoDocumento(props.isData.tipo_doc);
        setInputValNumeroDocumento(props.isData.NumeroDocumento);
        setInputValEdad(props.isData.Edad);
        setInputValSexo(props.isData.Sexo);

        setInputValIdEspecialidad(props.isData.IdEspecialidad);
        setInputValIdStaff(props.isData.IdStaff);
        // console.log(props.isData.Validez);
        setInputValValidez(props.isData.Validez);
        // setDisabledValidez(true);
        setInputValDias(props.isData.Validez);
        if (Number(props.isData.Validez) > 0) {
            setInputValValidez(true);
            // setDisabledValidez(false);            
        }
        if (Object.keys(props.isData.Tipo).length > 0) {
            setChkValEvaluacion(props.isData.Tipo[0].Evaluacion === 1 ? true : false);
            setChkValInterConsulta(props.isData.Tipo[0].InterConsulta === 1 ? true : false);
            setChkValInformePsi(props.isData.Tipo[0].InformePsicologico === 1 ? true : false);
        }

        // console.log(props.isData);
        setInputValTotalInversion(props.isData.Total);

        //Plan de cuotas ref: tb_orden_pago
        // console.log('props.isData.PagoCuotas');
        // console.log(props.isData.PagoCuotas);
        setInputValCuota(props.isData.PagoCuotas === 1 ? true : false);
        setInputValNumeroCuotas(props.isData.NumeroCuotas);
        // setInputValImporteCuotas(props.isData.ImporteCuotas);
        // setInputValImporteSaldo(props.isData.SaldoInicial);
        // setInputValMontoPagado(props.isData.PagoInicial);

        // setInputValPagado(false);
        // setDisabledMonto(true);
        // setDisabledIdFormaPago(true);
        // setDisabledReferenciaPago(true);
        // if (props.isData.PagoInicial > 0) {
        //     // setInputValPagado(true);
        //     setDisabledMonto(false);
        //     setDisabledIdFormaPago(false);
        //     setDisabledReferenciaPago(false);
        // }
        //pagos  
        // setInputValMontoPagado(props.isData.PagoInicial);
        // setInputValIdFormaPago(props.isData.IdFormaPago);
        // setInputValReferenciaPago(props.isData.ReferenciaPago);

        setInputValObservaciones(props.isData.Observaciones);
        setTarget(props.isData.Procedimientos);
        setCalculaTotalInversion_view_mode(props.isData.Procedimientos);
        setCantidadSel(props.isData.Procedimientos.length);

        setNumeroSesiones(props.isData.NumeroSesiones);
        setInputValNumeroCuotas(props.isData.NumeroSesiones); //default numeor de sesiones desde al OA   
        setInputValCuota(true);
        setFrecuenciaAtencion(props.isData.FrecuenciaAtencion);
        pa_get_orden_pago(props.isData.IdRegistro);

        // pa_get_plan_pagos(props.isData.IdPlanPago);
        // console.log('props.isData.PagoCuotas: ' + props.isData.PagoCuotas);
        // setDisabledbtnPlanPago(props.isData.PagoCuotas == 1 ? false : true);

    }


    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';

        if (vl_IdRegistro === "") {
            vl_valida = false;
            vl_message = 'Campo IdRegistro no puede ser vacio';
        } else if (vl_IdCliente === "0" || String(vl_IdCliente).trim() === "") {
            vl_valida = false;
            vl_message = 'Seleccionar un Cliente';
        } else if (Number(vl_TotalInversion) === 0 || (!Number(vl_TotalInversion))) {
            vl_valida = false;
            vl_message = 'Debe Ingresar importe total';
        } else if (Number(vl_NumeroCuotas2) === 0 || (!Number(vl_NumeroCuotas2))) {
            vl_valida = false;
            vl_message = 'Debe Ingresar numero de cuotas';
        }
        return [vl_valida, vl_message];
    }

    const handleCloseShowConfirmSave = () => {
        setVisibleConfirmDialog(false);
    }

    const handleCloseShowConfirmAnular = () => {
        setVisibleConfirmDialog1(false);
    }

    //new 
    const setVisibleConfirmDialogGenera = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 4000 });
            return;
        }
        setVisibleConfirmDialog(true)
    }

    const onProcesarOrdenPago = async () => {
        switch (vl_ACTION) {
            case 'I': case 'U': case 'G':
                setVisibleConfirmDialog(false);
                break;
            case 'A':
                setVisibleConfirmDialog1(false);
                break;
        }
        // switch (vl_ACTION) {
        //     case 'I': case 'U': case 'G':
        //         setVisibleConfirmDialog(false);
        //         const IsValidaCampos = onValidarCampos();
        //         const Isvalido = IsValidaCampos[0];
        //         const IsMessage = IsValidaCampos[1];
        //         if (!Isvalido) {
        //             toast.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 4000 });
        //             return;
        //         }
        //         break;
        //     case 'A':
        //         setVisibleConfirmDialog1(false);
        //         break;
        //     default:
        //         break;
        // }

        const param = {
            VP_ACTION: vl_ACTION,
            VP_IdRegistro: vl_IdRegistro,
            VP_IdCliente: vl_IdCliente,
            // VP_IdHistoriaClinica: vl_IdHistoriaClinica,
            // VP_NumeroHistoriaClinica: vl_NumeroHistoriaClinica,
            // VP_FechaAtencion: setIntlDateTimeFormat(vl_FechaAtencion),
            // VP_HoraAtencion: setIntlTimeFormat(vl_FechaAtencion),
            // VP_TipoRegistro: 'A',
            VP_Importe: vl_Importe,
            VP_Descuentopje: vl_Descuentopje,
            VP_Descuento: vl_Descuento,
            VP_Total: vl_TotalInversion,
            // VP_Pagos: vl_MontoPagado,
            // VP_Saldo: vl_ImporteSaldo,
            // VP_Pagado: vl_Pagado ? '1' : '0',
            // VP_EstadoPago: vl_ImporteSaldo == 0 ? 'T' : 'P',            
            // VP_EstadoRegistro: '',            
            // VP_IdOrdenAtencion: vl_IdOrdenAtencion,
            // VP_IdStaff: vl_IdStaff,
            // VP_IdEspecialidad: vl_IdEspecialidad,
            // VP_Tipo: JSON.stringify(jsonTipo),
            VP_Procedimientos: JSON.stringify(target),
            // VP_Validez: vl_Dias,
            // VP_MontoPagado: vl_MontoPagado,
            // VP_IdFormaPago: vl_IdFormaPago,
            // VP_ReferenciaPago: vl_ReferenciaPago,
            // newa
            // VP_TipoPago: vl_TipoPago,
            VP_PagoCuotas: vl_Cuota ? 1 : 0,
            VP_NumeroCuotas: vl_NumeroCuotas,
            VP_Observaciones: vl_Observaciones,
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_IdTipoDocumento: vl_IdTipoDocumento_1,
            VP_NumeroCuotaPago: vl_NumeroCuotas2, // new
            VP_DetalleCuotas: JSON.stringify(dataAcuerdoCuotas) // new
        };

        // console.log(param);
        // return;

        const response = await fetchSinToken('ordenPago/pa_registra_orden_pago', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        console.log(record);

        if (record) {
            const message = record.sql_message;
            toast.current.show({ severity: 'info', summary: 'Message', detail: message, life: 8000 });
            //eventos de la pantalla anterior cuando el proceso es OK
            if (record.sql_error === 0) {
                props.setBuscar();
                props.setIsMounted(false);
                props.isHide();
                props.setSelectedGrid1([]); //limpiar seleccionados, para forzar al usuario volver a seleccionar nueva data
            }
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
        }
        // console.log(resp)
    }

    //initializa valores de input 
    const setInitInputValCliente = () => {

        switch (vl_ACTION) {
            case 'I':
                break;
            case 'V':
                break;
            case 'A':
                break;
            default:
                break;
        }

        // setDisabledNumeroDocumento(false);
        // setInputValIdCliente('0');
        // setInputValNumeroDocumento('');
        // setInputValIdTipoDocumento('1');
        // // setInputValMinoriaEdad(false);
        // setInputValNombre('');
        // setInputValApPaterno('');
        // setInputValApMaterno('');
        // setInputValFechaNacimiento('');
        // setInputValEdad(0);
        // setInputValSexo('');
        // setInputValDireccionEmail('');
        // setInputValNumeroTelefono('');

    }

    // buscar CLIENTE x numero de documento
    const getBuscarCliente = async (e: any) => {
        e.preventDefault();
        const param = {
            "VP_IdCliente": 0,
            "VP_NumeroDocumento": "",
            "VP_NumeroHistoriaClinica": vl_NumeroHistoriaClinica,
            "VP_Nombres": "''",
            "VP_Apellidos": "''"
        }
        const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log(record);
        setInitInputValCliente();

        if (record) {
            //si existe cliente dehabilitar datos del cliente      
            // setDisabledNumeroDocumento(true);
            setInputValIdCliente(record.IdCliente);
            setInputValNombre(record.Nombres);
            setInputValApPaterno(record.ApPaterno);
            setInputValApMaterno(record.ApMaterno);
            setInputValEdad(record.Edad);
            setInputValNumeroDocumento(record.NumeroDocumento);
            setInputValIdTipoDocumento(record.tipo_doc);
            setInputValSexo(record.Sexo);
            setInputValIdHistoriaClinica(record.IdHistoriaClinica);
            // setInputValNumeroHistoriaClinica(record.NumeroHistoriaClinica);
            // setInputValFechaNacimiento(new Date(Date.parse(record.FechaNacimiento)));
            // const jsonInformacionContacto = record.InformacionContacto;
            // console.log(jsonInformacionContacto);
            // if (Object.keys(jsonInformacionContacto).length > 0) {
            //     setInputValDireccionEmail(jsonInformacionContacto.DirecccionEmail);
            //     setInputValNumeroTelefono(jsonInformacionContacto.NumeroTelefono);
            // }
            // const jsonDireccion = record.Direccion;
            // if (Object.keys(jsonDireccion).length > 0) {
            //     setInputValDireccionDistrito(jsonDireccion[0].Ubigeo);
            //     setInputValDireccionTipovia(jsonDireccion[0].TipoVia);
            //     setInputValDireccionNombrevia(jsonDireccion[0].NombreVia);
            //     setInputValDireccionNumero(jsonDireccion[0].Numero);
            //     setInputValDireccionManzana(jsonDireccion[0].Manzana);
            //     setInputValDireccionLote(jsonDireccion[0].Lote);
            //     setInputValDireccionDpto(jsonDireccion[0].Dpto);
            //     setInputValDireccionUrbanizacion(jsonDireccion[0].Urbanizacion);
            // }

        } else {
            const message = 'No se encontro cliente';
            toast.current.show({ severity: 'warn', summary: 'Message', detail: message, life: 10000 });
            inputNumeroHistoriaClinicaRef.current.focus();
            inputNumeroHistoriaClinicaRef.current.select();
        }
    }

    // const setModeEdit = () => {
    //     setInputValACTION('U');
    //     setHiddenbtnGrabar(false);
    //     setHiddenbtnAnular(true);
    //     setHiddenbtnEdit(true);
    // }

    const renderFooter = () => {
        return (
            <>
                <Button label="Grabar" hidden={b_hiddenGrabar} icon="pi pi-save" onClick={() => setVisibleConfirmDialogGenera()} />
                <Button label="Anular" hidden={b_hiddenAnular} icon="pi pi-ban" onClick={() => setVisibleConfirmDialog1(true)} />
                <Button label="Imprimir" icon="pi pi-print" className="p-button-text" onClick={() => setReportOP()} />
                <Button label="Cerrar" icon="pi pi-times" className="p-button-outlined p-button-danger ml-2" onClick={() => {
                    props.setIsMounted(false);
                    props.isHide();
                }} />
            </>
        );
    }


    const setCalculaTotalInversion = (p_target: any) => {
        let Costo = 0;
        p_target.forEach(
            function (currentValue: any) {
                Costo = Costo + parseFloat(currentValue.Importe);
            }
        );
        // console.log(Costo);
        // setInputValMontoTotalSel(Costo);
        // setInputValTotalInversion(Costo);
        // reiniciar tab: PAGO
        onChangeSwitchCuota(false);
        setInputValNumeroCuotas(0);
        // onChangeSwitchPagar(false);
        // setCalculaImporteSaldo(Costo, Costo, false);
        // setInputValImporteCuotas(0);
    }
    const setCalculaTotalInversion_view_mode = (p_target: any) => {
        let Costo = 0;
        p_target.forEach(
            function (currentValue: any) {
                Costo = Costo + parseFloat(currentValue.Importe);
            }
        );
        // setInputValMontoTotalSel(Costo);
        setImporte(Costo);
        setInputValTotalInversion(Costo);
    }

    const textEditor = (options: any) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    const textEditor2 = (options: any) => {
        return <InputText type="text" className='w-full p-inputtext-sm ' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }

    const isPositiveInteger = (val: any) => {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        let n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }

    const onCellEditComplete = (e: any) => {
        let { rowData, newValue, field, originalEvent: event } = e;
        // console.log(rowData.Importe +'--'+ newValue);
        switch (field) {
            case 'Cantidad': // no usado aqui
                if (isPositiveInteger(newValue))
                    rowData[field] = newValue;
                else
                    event.preventDefault();
                // recalcular totales si hay variacion del precio            
                setCalculaTotalInversion(target);
                break;
            case 'Importe':
                rowData[field] = newValue;
                // event.preventDefault();
                // recalcular totales si hay variacion del precio        
                setCalculaTotalInversion(target);
                break;
            default:
                if (newValue.trim().length > 0)
                    rowData[field] = newValue;
                else
                    event.preventDefault();
                break;
        }
    }

    const onCellEditComplete2 = (e: any) => {
        // console.log(e);
        let { rowData, newValue, field, originalEvent: event } = e;
        switch (field) {
            default:
                if (newValue) {
                    if (newValue.trim().length > 0)
                        rowData[field] = newValue;
                    else
                        event.preventDefault();
                }
                break;
        }
    }

    // CONFIRMACION DE QUITAR ITEMS    
    // Delete directo del boton trash sin confirmacion
    // const btnDeleteTemplate = (item: any) => {
    //     return (
    //         <>
    //             <Button icon="pi pi-trash" tooltip="Quitar" style={{ height: '28px' }}
    //                 className="p-button-rounded p-button-warning"
    //                 onClick={() => gridDeleteItem(item)} />
    //         </>
    //     );
    // }

    const gridDeleteItem = (item: any) => {
        let itemsfiltered = target.filter(function (element: any) {
            // console.log(element);
            return element.CodigoServicio ? element.CodigoServicio !== item.CodigoServicio : [];
        });
        setTarget(itemsfiltered);
        //close DIALOG CONFIRM
        setDeleteItemDialog(false);
    }

    // const actionBodyTemplate = (rowData: any) => {
    //     return (
    //         <React.Fragment>
    //             <Button icon="pi pi-trash" tooltip="Click para quitar Item" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteItem(rowData)} />
    //         </React.Fragment>
    //     );
    // }
    // const confirmDeleteItem = (rowData: any) => {
    //     setEditingRows(rowData);
    //     setDeleteItemDialog(true);
    // }

    const deleteItemDialogFooter = () => {
        // console.log( editingRows );
        return (
            <React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteItemDialog(false)} />
                <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={() => gridDeleteItem(editingRows)} />
            </React.Fragment>
        );
    }

    const setReportOP = async () => {
        // const config = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
        const endpoint = 'reports/ordenAtencionOP/?IdOrdenAtencion=' + vl_IdOrdenAtencion;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    const setImporteCuotas2 = (NumCuota: any, vp_TotalInversion: any) => {
        let vl_Importe: any = 0;
        let vl_Total: any = 0;
        let vl_ajuste: any = 0;
        if (parseFloat(vp_TotalInversion) === 0 || NumCuota === 0)
            return;

        vl_Importe = vp_TotalInversion / NumCuota;
        vl_ajuste = 0;
        let array: any = [];
        for (let index = 1; index <= NumCuota; index++) {
            vl_Total = parseFloat(vl_Total) + parseFloat(vl_Importe.toFixed(2));
            if (index === NumCuota) {
                vl_ajuste = parseFloat(vp_TotalInversion) - parseFloat(vl_Total);
                vl_Importe = vl_Importe + vl_ajuste;
            }
            array.push(
                {
                    "Importe": vl_Importe.toFixed(2),
                    "Cuota": index,
                    "Nota": ''
                }
            );
        }
        // console.log( vl_TotalInversion - vl_Total);
        setDataAcuerdoCuotas(array);
    }


    const renderHeader = () => {
        // let vl_paciente: any = vl_ApPaterno + ' ' + vl_ApMaterno + ' ' + vl_Nombre;
        return (
            <>
                <TitleForm title={vl_NumeroOrden ? 'Orden de Atención Nº: ' + vl_NumeroOrden : 'Orden de Atención'} />
                {/* <div className="bg-indigo-600 p2 border-bottom-2 text-white">
                    <div className="col mt-0 ml-0 font-italic" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='mb-2 font-bold '> {vl_NumeroOrden ? 'Orden de Atención Nº: ' + vl_NumeroOrden : 'Orden de Atención'} </label><br />
                        <label className='pb-1 text-base font-semibold '> Fecha y hora: {props.isData.FechaAtencion_2}  </label><br />
                        <label className='pb-1 text-base font-semibold '> Paciente: {vl_paciente}, Nº HC. {vl_NumeroHistoriaClinica} </label><br />
                        <label className='pb-1 text-base font-semibold'> Nº Doc.: {vl_IdTipoDocumento}-{vl_NumeroDocumento}, Edad: {vl_Edad} años, Sexo: {vl_Sexo}  </label><br />
                    </div>
                </div> */}
            </>
        );
    }

    return (
        <Dialog
            header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
            visible={props.isOpen}
            footer={renderFooter}
            onHide={() => {
                props.setIsMounted(false);
                props.isHide();
            }}
        >

            {/* <Filter/> */}
            {/* <DatoPaciente/> */}
            <div className="flex flex-wrap card-container w-full bg-bluegray-100 ">
                <div className="flex align-items-center justify-content-center m-1">                                        
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '100px', textAlign: 'end' }}>Nº Orden:</label>
                    <label className='p-col-fixed mr-1 text-base font-bold '> {vl_NumeroOrden}  </label> 
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '140px', textAlign: 'end' }}>Fecha y hora:</label>
                    <label className='p-col-fixed mr-1 text-base font-bold '> {props.isData.FechaAtencion_2}  </label> 
                </div>
            </div>
            <div className="flex flex-wrap card-container w-full bg-bluegray-100 ">
                <div className="flex align-items-center justify-content-center m-1">
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '100px', textAlign: 'end' }}>Paciente:</label>
                    <label className="p-col-fixed mr-1 font-bold text-base " style={{ textAlign: 'end' }}>{vl_ApPaterno + ' ' + vl_ApMaterno + ' ' + vl_Nombre}</label>
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '80px', textAlign: 'end' }}>H.C:</label>
                    <label className="p-col-fixed mr-1 font-bold text-xl text-blue-500 " style={{ textAlign: 'end' }}>{vl_NumeroHistoriaClinica}</label>
                </div>
            </div>
            <div className="flex flex-wrap card-container w-full bg-bluegray-100 pb-2 ">
                <div className="flex align-items-center justify-content-center m-1">                    
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '100px', textAlign: 'end' }}>Nº Doc.:</label>
                    <label className="p-col-fixed mr-1 font-bold text-base " style={{ textAlign: 'end' }}>{vl_IdTipoDocumento}-{vl_NumeroDocumento}</label>
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '80px', textAlign: 'end' }}>Edad:</label>
                    <label className="p-col-fixed mr-1 font-bold text-base " style={{ textAlign: 'end' }}>{vl_Edad}</label>
                    <label className="p-col-fixed mr-1 font-semibold text-normal " style={{ width: '80px', textAlign: 'end' }}>Sexo:</label>
                    <label className="p-col-fixed mr-1 font-bold text-base " style={{ textAlign: 'end' }}>{vl_Sexo}</label>
                </div>
            </div>

            <TabView className="tabview-custom"
                activeIndex={activeIndex}
                onTabChange={(e: any) => {
                    setActiveIndex(e.index);
                    switch (e.index) {
                        case 0: break;
                        case 1:
                            //if (vl_ACTION !== 'V')
                            // setCalculaImporteSaldo(vl_TotalInversion, vl_MontoPagado, vl_Cuota);
                            break;
                        default: break;
                    }
                }}
            >
                <TabPanel header="Procedimientos" leftIcon="pi pi-th-large mr-2" >
                    <div className="p-field p-grid mb-1">
                        <div className="p-col">
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Especialidad:</label>
                            <Dropdown style={{ height: '35px', width: '18rem' }}
                                // filter filterBy="Descripcion"
                                value={vl_IdEspecialidad} disabled
                                options={dataEspecialidad}
                                onChange={onIdEspecialidadChange}
                                optionValue="IdEspecialidad"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />

                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Profesional:</label>
                            <Dropdown style={{ height: '35px', width: '18rem' }}
                                // filter filterBy="Descripcion"
                                value={vl_IdStaff} disabled
                                options={dataIdStaff}
                                onChange={onIdStaffChange}
                                optionValue="IdStaff"
                                optionLabel="staff"
                                placeholder="Seleccionar" />
                        </div>

                        <div className="p-col mt-1">
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Validez:</label>
                            {/* <InputSwitch checked={vl_Validez} style={{ height: '28px' }}
                                //tooltip='Especifique la validez de la orden en dias'
                                onChange={(e: any) => onChangeSwitchValidez(e)}
                                disabled
                            /> */}
                            {/* <label htmlFor="" className="p-col-fixed mr-1 font-bold" style={{ width: '70px', textAlign: 'end' }}>Dias:</label> */}
                            <InputText type="text" style={{ height: '30px', width: '5rem', textAlign: 'center' }}
                                value={vl_Dias + ' dias'}
                                disabled
                                ref={inputDiasRef}
                                onChange={(e) => setInputValDias(String(e.currentTarget.value))}
                            />

                            <Checkbox className="ml-3" inputId="cb1" disabled
                                onChange={e => setChkValEvaluacion(e.checked)}
                                checked={chkValEvaluacion}> </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label pl-1">Evaluación</label>
                            <Checkbox className="ml-3" inputId="cb1" disabled
                                onChange={e => setChkValInterConsulta(e.checked)}
                                checked={chkValInterConsulta}> </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label pl-1">Interconsulta</label>
                            <Checkbox className="ml-3" inputId="cb1" disabled
                                onChange={e => setChkValInformePsi(e.checked)}
                                checked={chkValInformePsi}> </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label pl-1">Informe psicológico</label>
                        </div>
                        <div className="p-col mt-1">
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold" style={{ width: '120px', textAlign: 'end' }}>Nº sesiones:</label>
                            <InputText type="number" style={{ height: '30px', width: '60px', textAlign: 'center' }}
                                value={vl_NumeroSesiones}
                                disabled
                            />
                            <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold" style={{ textAlign: 'end' }}>Frecuencia atención</label>
                            <InputText type="text" style={{ height: '30px', width: '50%', textAlign: 'left' }}
                                value={vl_FrecuenciaAtencion}
                                disabled
                            />
                        </div>

                        <div className="p-col pt-1 ">
                            <DataTable size="small"
                                resizableColumns
                                // footer={footer}
                                value={target ?? []}  //props.isData.Procedimientos
                                dataKey="CodigoServicio"
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                scrollHeight="220px"
                                selectionMode="single"
                                editMode="cell"
                            // selection={editingRows}
                            // onSelectionChange={e => setEditingRows(e.value)}
                            >
                                <Column field="CodigoServicio" header="Código" style={{ minWidth: '5rem' }}> </Column>
                                <Column field="Descripcion" header="Detalle Atención" bodyClassName='white-space-normal'></Column>
                                <Column field="Nota" header="Comentario" bodyClassName='white-space-normal text-sm'
                                    editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete}>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Orden de pago"
                    disabled={dataOrdenPago.length > 0 ? false : true}
                    leftIcon="pi pi-book mr-2">
                    <OrdenPagos
                        oplanPago={oplanPago}
                        dataOrdenPago={dataOrdenPago}
                    />
                </TabPanel>
                <TabPanel header="Generar nuevo pago" leftIcon="pi pi-file mr-2">
                    <div className="p-field p-grid mb-1">
                        <div className="p-col mb-1 " >
                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Importe:</label>
                            <InputNumber type="text"
                                inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }}
                                autoFocus locale="en-US" mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                value={vl_Importe}
                                onChange={(e: any) => {
                                    setImporte(e.value);
                                    setInputValTotalInversion(e.value);
                                    if (vl_aplDescuento) {
                                        setAplicarDescuentoPje(vl_Descuentopje);
                                        setAplicarDescuentoMontoFijo(vl_Descuento);
                                    }
                                }}
                                onBlur={(e: any) => {
                                    if (Number(e.target.value) > 0) {
                                        pa_simulador_cuotas(Number(e.target.value), vl_NumeroCuotas);
                                        setImporteCuotas2(vl_NumeroCuotas2, vl_TotalInversion); //new 
                                    }
                                }}

                            />
                            <label htmlFor="" className="p-col-fixed mr-1 ml-5 font-bold " style={{ textAlign: 'end' }}>Nº Sesiones</label>
                            {/* <InputSwitch checked={vl_Cuota} disabled onChange={(e: any) => onChangeSwitchCuota(e)} /> */}
                            <label htmlFor="" className="p-col-fixed mr-2 " style={{ textAlign: 'end' }}></label>
                            <InputText type="number" style={{ height: '35px', width: '70px', textAlign: 'right' }}
                                value={vl_NumeroCuotas} readOnly
                                ref={inputNumeroCuotasRef}
                                onChange={(e) => setInputValNumeroCuotas(Number(e.currentTarget.value))}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        // setInputValImporteCuotas(setCalcularCuota(vl_ImporteSaldo));
                                    }
                                }}
                                onBlur={(e: any) => {
                                    // setInputValImporteCuotas(setCalcularCuota(vl_ImporteSaldo));
                                }}
                            />
                            <Button
                                type="button"
                                className="p-button-sm ml-1 mt-1 "
                                icon="pi pi-calendar"
                                label="Calc. Cuota"
                                title='Refrescar plan de cuotas'
                                onClick={() => {
                                    pa_simulador_cuotas(vl_TotalInversion, vl_NumeroCuotas);
                                }}
                            />
                        </div>
                        <div className="p-col mb-1 " >
                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Descuento</label>
                            <InputSwitch checked={vl_aplDescuento} style={{ height: '28px' }}
                                onChange={(e) => onChangeSwitchAplDescuento(e)}
                            />
                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>%</label>
                            <InputText type="number" style={{ height: '35px', width: '70px', textAlign: 'center' }}
                                value={vl_Descuentopje}
                                disabled={disabledDescuentopje}
                                ref={inputDescuentopjeRef}
                                onChange={(e) => setDescuentopje(Number(e.currentTarget.value))}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        setAplicarDescuentoPje(Number(e.target.value));
                                    }
                                }}
                                onBlur={(e: any) => {
                                    setAplicarDescuentoPje(Number(e.target.value));
                                }}
                            />
                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>S/.  </label>
                            <InputNumber type="text" inputStyle={{ height: '35px', width: '90px', textAlign: 'center' }}
                                value={vl_Descuento} locale="en-US"
                                disabled={disabledDescuento}
                                //ref={inputDescuentoRef} //no funciona con: InputNumber
                                onChange={(e) => setDescuento(Number(e.value))}
                                mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        // console.log(e.value);
                                        setAplicarDescuentoMontoFijo(Number(e.target.value))
                                    }
                                }}
                                onBlur={(e: any) => {
                                    // console.log( e.target.value);
                                    setAplicarDescuentoMontoFijo(Number(e.target.value));
                                }}
                            />
                            <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}> Total: </label>
                            <InputNumber type="text" inputStyle={{ height: '35px', width: '7rem', textAlign: 'right' }}
                                value={vl_TotalInversion} inputClassName='bg-green-600 font-semibold text-white border-round'
                                readOnly locale="en-US" prefix='S/ '
                                mode="decimal"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                            />
                            <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Comprobante:</label>
                            <Dropdown style={{ height: '36px', width: '9rem' }}
                                // filter filterBy="Descripcion"
                                value={vl_IdTipoDocumento_1}
                                options={dataIdTipoDocumento_1}
                                onChange={(e: any) => {
                                    setIdTipoDocumento_1(e.value);
                                }}
                                optionValue="Codigo"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />
                        </div>

                        <div className='flex justify-content-between'>
                            <div className="p-field p-grid mb-1">
                                <DataTable header="Plan de Cuotas(Sesiones) "
                                    size="small"
                                    value={dataPlanCuotasPrevio ?? []}
                                    dataKey="Cuota"
                                    resizableColumns
                                    responsiveLayout="scroll"
                                    columnResizeMode="fit"
                                    showGridlines
                                    stripedRows
                                    scrollable
                                    selectionMode="single"
                                    scrollHeight='220px'
                                // style={{ height: '210px' }}
                                >
                                    <Column field="Cuota" header="# de sesión" style={{ minWidth: '4rem' }}
                                        bodyClassName="flex justify-content-center" headerClassName='white-space-normal'  ></Column>
                                    <Column field="Importe" header="Importe Cuota" style={{ maxWidth: '130px' }}
                                        headerClassName="flex justify-content-end white-space-normal "
                                        bodyClassName="flex justify-content-end "></Column>
                                    <Column field="Saldo" header="Saldo" style={{ maxWidth: '90px' }}
                                        headerClassName="flex justify-content-end"
                                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                    > </Column>
                                    <Column field="FechaVencimiento" header="Fecha Vencimiento" headerClassName='white-space-normal' style={{ maxWidth: '150px' }}
                                        bodyClassName="flex justify-content-center" ></Column>
                                </DataTable>
                            </div>
                            <div className='m-1' >
                                <div className="p-col mb-1 ">
                                    <div className="h-2rem w-100 bg-bluegray-600 mt-1 mb-2 border-round-top text-white ">
                                        <div className="flex flex-wrap align-items-center justify-content-center">
                                            <label className="col font-normal" style={{ textAlign: 'left' }} > Acuerdo de pago </label>
                                        </div>
                                    </div>
                                    <label className="p-col-fixed mr-2 " style={{ textAlign: 'end' }}>Numero de cuotas</label>
                                    <InputText type="number" style={{ width: '5rem', height: '35px', textAlign: 'right' }}
                                        value={vl_NumeroCuotas2} min={1}
                                        onChange={(e) => setNumeroCuotas2(Number(e.currentTarget.value))}
                                        onKeyPress={(e: any) => {
                                            if (e.key === 'Enter')
                                                setImporteCuotas2(Number(e.target.value), vl_TotalInversion);
                                        }}
                                        onBlur={(e: any) => {
                                            setImporteCuotas2(Number(e.target.value), vl_TotalInversion);
                                        }}
                                    />
                                </div>
                                <DataTable
                                    // header="Plan de Cuotas(Sesiones) "
                                    size="small"
                                    value={dataAcuerdoCuotas ?? []}
                                    dataKey="Cuota"
                                    resizableColumns
                                    responsiveLayout="scroll"
                                    columnResizeMode="fit"
                                    showGridlines
                                    stripedRows
                                    scrollable
                                    selectionMode="single"
                                    scrollHeight='180px'
                                    editMode="cell"
                                    style={{ width: '450px' }}
                                >
                                    <Column field="Cuota" header="Cuota" style={{ maxWidth: '4rem' }} bodyClassName="flex justify-content-center" headerClassName='flex justify-content-center'></Column>
                                    <Column field="Importe" header="Importe" style={{ maxWidth: '8rem' }}
                                        headerClassName="flex justify-content-end white-space-normal"
                                        bodyClassName="flex justify-content-end text-cyan-800 font-bold "
                                    ></Column>
                                    <Column field="Nota" header="Notas" headerClassName='pi pi-file-edit' style={{ minWidth: '12rem', maxWidth: '15rem' }} bodyClassName='white-space-normal text-sm'
                                        editor={(options) => textEditor2(options)} onCellEditComplete={onCellEditComplete2}></Column>
                                    {/* <Column field="Nota" header="Fecha Venc." style={{ minWidth: '10rem' }} bodyClassName='white-space-normal text-sm'
                                        editor={(options) => textEditor2(options)} onCellEditComplete={onCellEditComplete}></Column> */}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </TabPanel>

            </TabView>

            <OverlayPanel
                ref={op}
                showCloseIcon
                id="overlay_panel"
                style={{ width: "450px" }}
            >
                <DataTable
                    size="small"
                    resizableColumns
                    value={dataCodigoServicios ?? []}
                    dataKey="CodigoServicio"
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    selectionMode="single"
                    paginator
                    rows={10}
                    selection={selectedIemsGrid}
                    onSelectionChange={
                        (e) => {
                            setSelectedIemsGrid(e.value);
                            //console.log(e.value);                            
                            let targetModel = {
                                UnidadMedida: 'UNI',
                                Cantidad: 1,
                                CodigoServicio: e.value.CodigoServicio,
                                Descripcion: e.value.Descripcion,
                                Importe: e.value.Costo,
                                Descuento: 0,
                                Impuesto: 0,
                                Total: e.value.Costo,
                                Nota: ''
                            };
                            target.push(targetModel);
                            setCalculaTotalInversion(target);
                        }
                    }
                >
                    <Column selectionMode="single" style={{ maxWidth: '50px' }} ></Column>
                    <Column field="Descripcion" filter header="Catálago Atención" ></Column>
                    <Column field="Costo" header="Costo" style={{ maxWidth: '90px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-green-800 font-bold"
                    ></Column>
                </DataTable>

                <Button
                    type="button"
                    icon="pi pi-check"
                    label="Aceptar"
                    onClick={
                        (e) => {
                            op.current?.hide()
                        }
                    }
                    className="ml-1 mt-1"
                />
            </OverlayPanel>


            <Toast ref={toast} position="top-center"></Toast>
            <Toast ref={toastConfirm} position="center"></Toast>

            <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
                message={dataOrdenPago.length > 0 ? "Ya existe una orden de pago. ¿Estas seguro de reemplazar?" : "¿Generar orden de pago?"}
                header="Confirmar para continuar"
                icon="pi pi-exclamation-triangle"
                accept={onProcesarOrdenPago}
                reject={handleCloseShowConfirmSave}
            />

            <ConfirmDialog visible={b_visible1} onHide={() => setVisibleConfirmDialog1(false)}
                message="¿Anular orden de pago?"
                header="Confirmar para continuar"
                icon="pi pi-exclamation-triangle"
                accept={onProcesarOrdenPago}
                reject={handleCloseShowConfirmAnular}
            />

            <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirmar" modal
                footer={deleteItemDialogFooter}
                onHide={() => setDeleteItemDialog(false)} >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span> ¿Estás seguro(a) de eliminar?</span>
                </div>
            </Dialog>

        </Dialog>
    )
};
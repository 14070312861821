
const FooterLayout = () => {
  return (    
    <footer className="footer">         
      <div className="ms-auto text-xs">PSISABE-{ new Date().getFullYear() } &copy;Todos los derechos reservados 
      {/* &nbsp;<a href="https://www.psisabe.com">Power by Inc.</a> */}
      </div>
    </footer>
  )
}

export default FooterLayout

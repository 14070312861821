import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
// import { Column } from 'primereact/column';
// import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { AppContext01 } from '../../../context/AppContext';
import { fetchSinToken } from '../../../helpers/fetch';
import useModal from '../../../context/WindowsOpen';
import ServicioForm from '../../components/catalogo/ServicioForm';
import { Toast } from 'primereact/toast';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import '../../../assets/scss/_dataView.scss';
// import { ToggleButton } from 'primereact/togglebutton';
// import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';


const ServicioView = () => {
    const [vl_filter, setFilter] = useState<any>('');
    const [dataServicios, setDataServicios] = useState<any>([]);
    const [selectedGrid, setSelectedGrid] = useState<any>([]);

    const [isMode, setMode] = useState('I');
    const isMounted = useRef(false);
    const [isOpenModal, openModal, closeModal] = useModal();
    const toast = useRef<any>(null);

    const [layout, setLayout] = useState('grid');
    const [selectedCategory, setSelectedCategory] = useState<any>('');


    useEffect(() => {
        handleSetTitle();
        pa_set_tipo_servicio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/Catálogo/Servicios');
    }
    const pa_set_tipo_servicio = async () => {
        const param = {
            "VP_action": 'L',
            "VP_filter": vl_filter,
            "VP_CodigoServicio": '',
            "VP_IdSucursal": 0,
            "VP_Descripcion": '',
            "VP_Detalle": '',
            "VP_Tipo": '',
            "VP_IdEspecialidad": '0',
            "VP_Estado": '',
            "VP_Image": '',
            "VP_Forma": '',
            "VP_IdUsuario": '0',
            "VP_CostoServicio": '0'            
        }
        const response = await fetchSinToken('catalogo/pa_set_tipo_servicio', param, 'POST');
        const resp = await response.json();

        // console.log(resp.data.errno);
        if (!resp.data.errno) {
            setDataServicios(resp.data);
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
        }

    }


    const handleClickNew = () => {
        setMode('I');
        isMounted.current = true;
        setSelectedGrid([]);
        openModal();
    }
    const handleClickEdit = () => {
        if (Object.keys(selectedGrid).length <= 0) {
            const message = 'seleccionar un item';
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
            return;
        }
        setMode('U');
        isMounted.current = true;
        openModal();
    }

    const handleSetIsMounted = (val: boolean) => {
        isMounted.current = val;
    }

    // const template_NumberCurrency = (item: any) => {
    //     return (
    //         <>
    //             <label className='text-color-green-400'>${item.Costo}</label>
    //         </>
    //     );
    // }

    const renderListItem = (data: any) => {
        return (
            <div className="col-12">
                <div className="product-list-item">
                    <img src={`${data.Image}`} onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.Descripcion} />
                    <div className="product-list-detail">
                        <div className="product-name">{data.Descripcion}</div>
                        <div className="product-description">{data.Detalle}</div>
                        {/* <Rating value={data.rating} readOnly cancel={false}></Rating> */}
                        <i className="pi pi-tag product-category-icon"></i><span className="product-category">{data.Tipo_1}</span>
                    </div>
                    <div className="product-list-action">
                        <span className="product-price">${data.Costo}</span>
                        <div key={data.CodigoServicio} className="field-radiobutton">
                            <RadioButton inputId={data.CodigoServicio} name="category"
                                value={data}
                                onChange={(e) => {
                                    setSelectedCategory(e.value);
                                    setSelectedGrid(e.value);
                                }}
                                checked={selectedCategory.CodigoServicio === data.CodigoServicio}
                            // disabled={data.CodigoServicio === 'R'} 
                            />
                            {/* <label htmlFor={data.CodigoServicio}>{data.CodigoServicio}</label> */}
                            <label className='font-semibold text-lg text-indigo-600' htmlFor={data.CodigoServicio}>Seleccionar</label>
                        </div>
                        <div>
                            <span className={data.Estado === 'A' ? `col text-sm bg-green-600 text-white` : 'col text-sm bg-pink-600 text-white'}
                                style={{ textAlign: 'center' }}>{data.Estado_1}</span>
                        </div>
                        {/* <Button icon="pi pi-shopping-cart" label="Add to Cart" disabled={data.Estado === 'OUTOFSTOCK'}></Button> */}
                        {/* <span className={`product-badge status-${data.Estado.toLowerCase()}`}>{data.Estado}</span> */}
                    </div>
                </div>
            </div>
        );
    }
    const renderGridItem = (data: any) => {
        return (
            <div className="col-12 md:col-4">
                <div className="product-grid-item card">
                    <div className="product-grid-item-top">
                        <div>
                            <i className="pi pi-tag product-category-icon"></i>
                            <span className="product-category">{data.Tipo_1}</span>
                        </div>
                        <div>
                            <span className={data.Estado === 'A' ? `col text-sm bg-green-600 text-white` : 'col text-sm bg-pink-600 text-white'}
                                style={{ textAlign: 'center' }}>{data.Estado_1}</span>
                        </div>
                    </div>
                    <div className="product-grid-item-content">
                        {/* <img src={`images/product/device-ssd-fill.svg`} onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={data.Descripcion} /> */}
                        <img src={`${data.Image}`} onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                            alt={data.Descripcion} />
                        <div className="product-name">{data.Descripcion}</div>
                        <div className="product-description">{data.Detalle}</div>
                        {/* <Rating value={data.rating} readOnly cancel={false}></Rating> */}
                    </div>
                    <div className="product-grid-item-bottom p-1 ">
                        <span className="product-price">${data.Costo}</span>
                        {/* <label className="p-col-fixed ml-2" style={{ textAlign: 'end' }}> Seleccionar </label> */}
                        <div key={data.CodigoServicio} className="field-radiobutton">
                            <RadioButton inputId={data.CodigoServicio} name="category"
                                value={data}
                                onChange={(e) => {
                                    setSelectedCategory(e.value);
                                    setSelectedGrid(e.value);
                                }}
                                checked={selectedCategory.CodigoServicio === data.CodigoServicio}
                            // disabled={data.CodigoServicio === 'R'} 
                            />
                            {/* <label htmlFor={data.CodigoServicio}>{data.CodigoServicio}</label> */}
                            <label className='font-semibold text-lg text-indigo-600' htmlFor={data.CodigoServicio}>Seleccionar</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const itemTemplate = (product: any, layout: any) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }
    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{ textAlign: 'left' }}>
                    {/* <Dropdown options={sortOptions} value={sortKey} optionLabel="label" placeholder="Sort By Price" onChange={onSortChange}/> */}
                    <div className="card-container gray-container p-1 ">
                        <InputText type="search" style={{ width: '25rem', textAlign: 'left' }}
                            autoFocus
                            placeholder='Filtrar...'
                            value={vl_filter}
                            onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    pa_set_tipo_servicio();
                                }
                            }}
                        />
                        <Button label=""
                            onClick={(e: any) => pa_set_tipo_servicio()}
                            icon="pi pi-search"
                            tooltip='Buscar '
                            className="p-button-rounded ml-2"
                        />
                    </div>
                </div>
                <div className="col-6" style={{ textAlign: 'right' }}>
                    <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                </div>
            </div>
        );
    }
    const header = renderHeader();

    return (
        <>
            <>
                {isMounted.current && <ServicioForm
                    isMode={isMode}
                    isOpen={isOpenModal}
                    isHide={closeModal}
                    isMounted={handleSetIsMounted}
                    isData={selectedGrid}
                    isSetSelectedGrid={setSelectedGrid}
                    setSelectedCategory={setSelectedCategory}
                    isCallfn={pa_set_tipo_servicio} />}
            </>
            <Toast ref={toast} position="top-center"></Toast>

            <div className="flex justify-content-between pt-1 pl-3 ">
                <div className='md:border-solid border-yellow-500 shadow-1 m-2 flex align-items-center justify-content-center p-1' >
                    <label className='text-base font-italic'> * Configurar servicios y tarifas</label>
                </div>
                <div>
                    <span className="p-buttonset">
                        <Button
                            label="Nuevo"
                            icon="pi pi-file"
                            onClick={() => handleClickNew()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                        <Button
                            label="Editar"
                            icon="pi pi-pencil"
                            onClick={() => handleClickEdit()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                    </span>
                </div>
            </div>

            {/* <div className="card-container gray-container p-1 ">
                <InputText type="search" style={{ width: '50%', textAlign: 'left' }}
                    autoFocus
                    placeholder='Filtrar...'
                    value={vl_filter}
                    onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            pa_set_tipo_servicio();
                        }
                    }}
                />
                <Button label=""
                    onClick={(e: any) => pa_set_tipo_servicio()}
                    icon="pi pi-search"
                    tooltip='Buscar '
                    className="p-button-rounded ml-2"
                />
            </div> */}
            <div className='dataview-demo' >
                <DataView value={dataServicios}
                    layout={layout}
                    header={header}
                    itemTemplate={itemTemplate}
                    paginator rows={6}
                    // paginatorPosition='both'
                // sortOrder={sortOrder} 
                // sortField={sortField} 
                />
            </div>

            {/* <div className="card-container gray-container">
                <DataTable size="small" resizableColumns
                    // footer={footer}
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    value={dataServicios}
                    dataKey="CodigoServicio"
                    selectionMode="single"
                    selection={selectedGrid}
                    onSelectionChange={e => setSelectedGrid(e.value)}
                    scrollHeight="400px"
                    paginator
                    rows={10}
                >
                    <Column field="CodigoServicio" header="Código" style={{ maxWidth: '120px' }}   ></Column>
                    <Column field="Descripcion" header="Descripción"  ></Column>
                    <Column field="Costo" header="Costo" style={{ maxWidth: '120px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        body={template_NumberCurrency}  ></Column>
                    <Column field="Tipo_1" header="Tipo" style={{ maxWidth: '120px' }}   ></Column>
                    <Column field="Especialidad" header="Especialidad" style={{ maxWidth: '120px' }}  ></Column>
                    <Column field="Estado_1" header="Estado" style={{ maxWidth: '125px' }}  ></Column>
                </DataTable>
            </div> */}

        </>
    )
}

export default ServicioView
import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog tag
import { fetchSinToken } from '../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../helpers/functions';
import { InputNumber } from 'primereact/inputnumber';
import TitleForm from '../util/TitleForm';
import useModal from '../../../context/WindowsOpen';
import BuscadorClientes from '../util/BuscadorClientes';

const AdmisionForm = ({ ...props }) => {

  // console.log('AdmisionForm');

  const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
  const [b_visible1, setVisibleConfirmDialog1] = useState<boolean>(false);
  const [b_hiddenGrabar, setHiddenbtnGrabar] = useState<boolean>(false);
  const [b_hiddenAnular, setHiddenbtnAnular] = useState<boolean>(true);

  //filter
  const [vl_NumeroDocumento_f, setInputValNumeroDocumento_f] = useState('');

  // Inputs Crud 
  const [vl_ACTION, setInputValACTION] = useState('I');
  const [vl_IdAdmision, setInputValIdAdmision] = useState('0');
  const [vl_IdRegistro, setInputValIdRegistro] = useState('0');
  const [vl_FechaRegistro, setInputValFechaRegistro] = useState('');

  const [vl_IdCliente, setInputValIdCliente] = useState('0');
  const [vl_MinoriaEdad, setInputValMinoriaEdad] = useState<boolean>(false);
  const [vl_IdHistoriaClinica, setInputValIdHistoriaClinica] = useState('0');
  const [vl_NumeroHistoriaClinica, setInputValNumeroHistoriaClinica] = useState('');
  const [vl_IdTipoDocumento, setInputValIdTipoDocumento] = useState<any>(1);
  const [vl_NumeroDocumento, setInputValNumeroDocumento] = useState('');
  const [vl_Nombre, setInputValNombre] = useState('');
  const [vl_ApPaterno, setInputValApPaterno] = useState('');
  const [vl_ApMaterno, setInputValApMaterno] = useState('');
  // const [vl_FecNacimiento, setInputValFechaNacimiento] = useState<any>('');
  const [vl_FecNacimiento, setInputValFechaNacimiento] = useState<Date | Date[] | undefined>(undefined);
  const [vl_Edad, setInputValEdad] = useState<number>(0);
  const [vl_Sexo, setInputValSexo] = useState<any>('');
  const [vl_DireccionEmail, setInputValDireccionEmail] = useState<any>('');
  const [vl_NumeroTelefono, setInputValNumeroTelefono] = useState<any>('');
  const [vl_NombreAcomp, setInputValNombreAcomp] = useState<any>('');
  const [vl_NumDocumentoAcomp, setInputValNumDocumentoAcomp] = useState<any>('');
  const [vl_IdParentescoAcomp, setInputValIdParentescoAcomp] = useState<any>('0');
  const [vl_CodigoServicio, setInputValCodigoServicio] = useState<any>('');
  const [vl_CostoServicio, setInputValCostoServicio] = useState<any>('0.00');
  const [vl_Pagado, setInputValPagado] = useState<boolean>(false);
  const [vl_MontoPagado, setInputValMontoPagado] = useState<any>('0.00');
  const [vl_IdFormaPago, setInputValIdFormaPago] = useState<any>('CA');
  const [vl_ReferenciaPago, setInputValReferenciaPago] = useState<any>('');
  const [vl_Observaciones, setInputValObservaciones] = useState<any>('');
  //direccion valor DEFAULT LIMA-LIMA-LIMA
  const [vl_DireccionDepartamento, setInputValDireccionDepartamento] = useState<any>('15');
  const [vl_DireccionProvincia, setInputValDireccionProvincia] = useState<any>('1501');
  const [vl_DireccionDistrito, setInputValDireccionDistrito] = useState<any>('140101');
  const [vl_DireccionTipovia, setInputValDireccionTipovia] = useState<any>('AV.');
  const [vl_DireccionNombrevia, setInputValDireccionNombrevia] = useState<any>('');
  const [vl_DireccionNumero, setInputValDireccionNumero] = useState<any>('');
  const [vl_DireccionManzana, setInputValDireccionManzana] = useState<any>('');
  const [vl_DireccionLote, setInputValDireccionLote] = useState<any>('');
  const [vl_DireccionDpto, setInputValDireccionDpto] = useState<any>('');
  const [vl_DireccionUrbanizacion, setInputValDireccionUrbanizacion] = useState<any>('');


  // VP_EstadoRegistro char(1),
  // VP_UsuarioRegistro varchar(10) ,
  // VP_UsuarioActualizacion varchar(10)  

  const [dataIdTipoDocumento, setDataIdTipoDocumento] = useState([]);
  const [dataCodigoServicios, setDataCodigoServicio] = useState([]);
  const [dataIdFormaPago, setIdDataFormaPago] = useState([]);
  const [dataIdParentescoAcomp, setDataIdParentescoAcomp] = useState([]);
  const [dataDireccionDepartamento, setDataDireccionDepartamento] = useState([]);
  const [dataDireccionProvincia, setDataDireccionProvincia] = useState([]);
  const [dataDireccionDistrito, setDataDireccionDistrito] = useState([]);
  const [dataDireccionTipovia, setDataDireccionTipovia] = useState([]);

  // const [switchPagar, setSwitchPagar] = useState<any>(false);

  //messages
  const toast = useRef<any>(null);
  const toastConfirm = useRef<any>(null);

  // Input ref    
  const inputNumeroDocumento_f_Ref = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputMontoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputNumeroDocumentoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputNombreRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputApPaternoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputApMaternoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputFechaNacimientoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputDireccionEmailRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputNumeroTelefonoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputCostoServicioRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputReferenciaPagoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputObservacionesRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputDireccionNombreviaRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputDireccionNumeroRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputDireccionManzanaRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputDireccionLoteRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputDireccionDptoRef = useRef<HTMLElement | HTMLInputElement | any>(null);
  const inputDireccionUrbRef = useRef<HTMLElement | HTMLInputElement | any>(null);

  //disabled INPUTs
  const [disabledNumeroDocumento_f, setDisabledNumeroDocumento_f] = useState(false);
  const [disabledNumeroDocumento, setDisabledNumeroDocumento] = useState(false);
  const [disabledMonto, setDisabledMonto] = useState(false);
  const [disabledIdFormaPago, setDisabledIdFormaPago] = useState(false);
  const [disabledReferenciaPago, setDisabledReferenciaPago] = useState(false);

  const [isOpenModal1, openModal1, closeModal1] = useModal(); //buscador
  const isMounted = useRef(false);

  // set Values
  const onIdTipoDocumentoChange = (e: { value: any }) => {
    setInputValIdTipoDocumento(e.value);
  }
  const onCodigoServicioChange = (e: { value: any }) => {
    // console.log(e);
    setInputValCodigoServicio(e.value);
    //e.value = codigo de servicio seleccionado
    //obtener costo del servicio, segun tarifario vigente
    pa_get_tarifario(e.value).then(
      CostoServicio => {
        // console.log(CostoServicio);
        setInputValCostoServicio(CostoServicio);
        //por defauld pagado 
        // setInputValPagado(true);
        // setInputValMontoPagado(CostoServicio);
        // inputReferenciaPagoRef.current?.select();
        inputObservacionesRef.current?.select();
      }
    );

  }
  const onIdFormaPagoChange = (e: { value: any }) => {
    setInputValIdFormaPago(e.value);
  }
  const onIdParentescoAcompChange = (e: { value: any }) => {
    setInputValIdParentescoAcomp(e.value);
  }
  const onDireccionDepartamentoChange = (e: { value: any }) => {
    setInputValDireccionDepartamento(e.value);
    pa_get_ubigeo('P', e.value, '');
  }
  const onDireccionProvinciaChange = (e: { value: any }) => {
    setInputValDireccionProvincia(e.value);
    pa_get_ubigeo('I', '', e.value);
  }
  const onDireccionDistritoChange = (e: { value: any }) => {
    setInputValDireccionDistrito(e.value);
  }
  const onDireccionTipoviaChange = (e: { value: any }) => {
    setInputValDireccionTipovia(e.value);
  }

  const onChangeSwitchPagar = (e: any) => {
    // console.log(inpMonto); 
    const switchPagarValue: boolean = e.value;
    // console.log('switchPagarValue:' + switchPagarValue);
    setInputValPagado(switchPagarValue);
    // console.log( vl_Pagado );    
    setInputValMontoPagado('0.00');
    setInputValIdFormaPago('');
    //Disable for inputs pago
    setDisabledMonto(true);
    setDisabledIdFormaPago(true);
    setDisabledReferenciaPago(true);
    if (switchPagarValue) {
      setDisabledMonto(false);
      setDisabledIdFormaPago(false);
      setDisabledReferenciaPago(false);
      setInputValMontoPagado(vl_CostoServicio);
      setInputValIdFormaPago('CA');
      setTimeout(() => {
        inputMontoRef.current?.select();
      }, 100);
    }
  }

  const onChangeSwitchMinoriaEdad = (e: any) => {
    const chkMinoriaEdad: any = e.value;
    //console.log(chkMinoriaEdad);
    setInputValMinoriaEdad(chkMinoriaEdad);
    // console.log('vl_MinoriaEdad:' + vl_MinoriaEdad);
    if (chkMinoriaEdad) {
      setTimeout(() => {
        inputNombreRef.current?.select();
      }, 100);
    }
  }

  const pa_get_tipo_documento = async () => {
    //e.preventDefault();
    const param = {
      "vp_IdTipoDocumento": "0",
      "vp_Codigo": ""
    }
    const response = await fetchSinToken('selectors/pa_get_tipo_documento', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataIdTipoDocumento(record);
  }

  const pa_get_tipo_parentesco = async () => {
    //e.preventDefault();
    const param = {
      "vp_IdCodigo": "0",
      "vp_CodigoTipo": "CO",
      "vp_GradoParentesco": "0"
    }
    const response = await fetchSinToken('selectors/pa_get_tipo_parentesco', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataIdParentescoAcomp(record);
  }

  const pa_get_ubigeo = async (in_opcion: any, in_DepartamentoInei: any, in_ProvinciaInei: any) => {
    //e.preventDefault();
    const param = {
      "vp_opcion": in_opcion,
      "vp_DepartamentoInei": in_DepartamentoInei,
      "vp_ProvinciaInei": in_ProvinciaInei
    }
    const response = await fetchSinToken('selectors/pa_get_ubigeo', param, 'POST');
    const resp = await response.json();
    const record = resp.data;

    if (in_opcion === 'D') setDataDireccionDepartamento(record);
    if (in_opcion === 'P') setDataDireccionProvincia(record);
    if (in_opcion === 'I') setDataDireccionDistrito(record);

  }

  const pa_get_tipo_via = async (in_opcion: any) => {
    const param = {
      "vp_opcion": in_opcion
    }
    const response = await fetchSinToken('selectors/pa_get_tipo_via', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataDireccionTipovia(record);
  }

  const pa_get_tarifario = async (IN_CodigoServicio: any) => {
    const param = {
      "vp_opcion": "T",
      "vp_CodigoServicio": IN_CodigoServicio
    }
    const response = await fetchSinToken('selectors/pa_get_tarifario', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    // operador ternario
    return (record) ? record.Costo : 0.0;
  }
  const pa_get_tipo_servicio = async () => {
    const param = {
      "vp_Tipo": "S",
      "vp_estado": ""
    }
    const response = await fetchSinToken('selectors/pa_get_tipo_servicio', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataCodigoServicio(record);
    // setSource(record);
  }

  const pa_get_forma_pago = async () => {
    const param = {
      "vp_IdFormaPago": "",
      "vp_estado": ""
    }
    const response = await fetchSinToken('selectors/pa_get_forma_pago', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setIdDataFormaPago(record);
  }


  const getApiConultaDNI = async (p_numero_dni: any) => {

    const param = {
      vp_numero_dni: p_numero_dni
    };
    const response = await fetchSinToken('admision/apiConsultaDNI', param, 'POST');
    const resp = await response.json();

    // initial values
    setInputValNumeroDocumento('');
    setInputValIdTipoDocumento(1);
    setInputValNombre('');
    setInputValApPaterno('');
    setInputValApMaterno('');

    // console.log(resp);
    if (!resp.error) {
      const record = resp.data;
      setInputValNumeroDocumento(record.numeroDocumento);
      setInputValIdTipoDocumento(parseFloat(record.tipoDocumento));
      setInputValNombre(record.nombres);
      setInputValApPaterno(record.apellidoPaterno);
      setInputValApMaterno(record.apellidoMaterno);
    } else {
      toast.current.show({ severity: 'info', summary: 'Response', detail: resp.data.error, life: 8000 });
    }
  }

  useEffect(() => {

    setInputValACTION(props.isMode);
    // console.log(vl_ACTION);
    pa_get_tipo_servicio();
    pa_get_forma_pago();
    pa_get_tipo_documento();
    pa_get_tipo_parentesco();
    //UBIGEO DEFAULT LIMA-LIMA-LIMA
    pa_get_ubigeo('D', '', '');
    pa_get_ubigeo('P', '15', '');
    pa_get_ubigeo('I', '', '1501');
    pa_get_tipo_via('L');

    //control form segun mode(crud)
    setHiddenbtnGrabar(false);
    setHiddenbtnAnular(true);
    setDisabledNumeroDocumento_f(false);
    switch (props.isMode) {
      case 'I':
        setTimeout(() => {
          inputNumeroDocumento_f_Ref.current?.focus();
        }, 100);
        break;
      case 'U':
        getRecordAdmision();
        setDisabledNumeroDocumento_f(true);
        break;
      case 'A':
        getRecordAdmision();
        setHiddenbtnGrabar(true);
        setHiddenbtnAnular(false);
        setDisabledNumeroDocumento_f(true);
        setTimeout(() => {
          inputObservacionesRef.current?.select();
        }, 100);
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);


  const getRecordAdmision = () => {
    // console.log('getRecordAdmision');
    // console.log(props.isData);
    setInputValIdAdmision(props.isData.IdAdmision);
    setInputValIdRegistro(props.isData.IdRegistro);
    setInputValNumeroHistoriaClinica(props.isData.NumeroHistoriaClinica);
    setInputValIdHistoriaClinica(props.isData.IdHistoriaClinica);
    setInputValFechaRegistro(props.isData.FechaRegistro + ' ' + props.isData.HoraRegistro);
    setInputValIdCliente(props.isData.IdCliente);
    setInputValMinoriaEdad(false);
    if (props.isData.MinoriaEdad === 1)
      setInputValMinoriaEdad(true);
    // VP_IdHistoriaClinica: vl_IdHistoriaClinica,      
    setInputValIdTipoDocumento(props.isData.IdTipoDocumento);
    setInputValNumeroDocumento(props.isData.NumeroDocumento);
    setInputValNombre(props.isData.Nombres);
    setInputValApPaterno(props.isData.ApPaterno);
    setInputValApMaterno(props.isData.ApMaterno);
    setInputValFechaNacimiento(new Date(Date.parse(props.isData.FechaNacimiento)));
    setInputValEdad(props.isData.Edad);
    setInputValSexo(props.isData.Sexo);
    setInputValDireccionEmail(props.isData.InformacionContacto[0].DireccionEmail);
    setInputValNumeroTelefono(props.isData.InformacionContacto[0].NumeroTelefono);

    setInputValNombreAcomp(props.isData.NombreAcomp);
    setInputValNumDocumentoAcomp(props.isData.NumDocumentoAcomp);
    setInputValIdParentescoAcomp(props.isData.IdParentescoAcomp);

    setInputValCodigoServicio(props.isData.CodigoServicio);
    setInputValCostoServicio(props.isData.CostoTotal);
    setInputValPagado(false);
    setDisabledMonto(true);
    setDisabledIdFormaPago(true);
    setDisabledReferenciaPago(true);
    // console.log(props.isData.Pagado);
    if (props.isData.PagoInicial > 0) {
      setInputValPagado(true);
      setDisabledMonto(false);
      setDisabledIdFormaPago(false);
      setDisabledReferenciaPago(false);
    }
    //pagos (caja)
    setInputValMontoPagado(props.isData.PagoInicial);
    setInputValIdFormaPago(props.isData.IdFormaPago);
    setInputValReferenciaPago(props.isData.ReferenciaPago);
    setInputValObservaciones(props.isData.Observaciones);

    // VP_EstadoRegistro: '1',
    // VP_UsuarioRegistro: 'VHIDALGO',
    // VP_UsuarioActualizacion: '',
    // VP_Direccion: JSON.stringify(jsonDir)
    // console.log(props.isData.Direccion);
    if (Object.keys(props.isData.Direccion).length > 0) {

      let UbProvincia: any = String(props.isData.Direccion[0].Ubigeo).substring(0, 2);
      let ProvinciaInei: any = String(props.isData.Direccion[0].Ubigeo).substring(0, 4);
      setInputValDireccionDepartamento(UbProvincia);
      pa_get_ubigeo('P', UbProvincia, '');
      //Provincias de un departamento: 02
      setInputValDireccionProvincia(ProvinciaInei);
      //Distritos de una provincia: 0210
      pa_get_ubigeo('I', '', ProvinciaInei);
      setInputValDireccionDistrito(String(props.isData.Direccion[0].Ubigeo));

      setInputValDireccionTipovia(props.isData.Direccion[0].TipoVia);
      setInputValDireccionNombrevia(props.isData.Direccion[0].NombreVia);
      setInputValDireccionNumero(props.isData.Direccion[0].Numero);
      setInputValDireccionManzana(props.isData.Direccion[0].Manzana);
      setInputValDireccionLote(props.isData.Direccion[0].Lote);
      setInputValDireccionDpto(props.isData.Direccion[0].Dpto);
      setInputValDireccionUrbanizacion(props.isData.Direccion[0].Urbanizacion);
    }

  }


  const onValidarCampos = () => {
    let vl_valida = true;
    let vl_message = '';

    // console.log(vl_MinoriaEdad);

    if (vl_IdAdmision === "") {
      vl_valida = false;
      vl_message = 'Campo IdAdmision no puede ser vacio';
    } else if (vl_IdCliente === "") {
      vl_valida = false;
      vl_message = 'Campo IdCliente no puede ser vacio';
    } else if (String(vl_NumeroDocumento).trim() === '') {
      vl_valida = false;
      vl_message = 'Ingresar numero de documento';
    } else if (vl_IdTipoDocumento === '0') {
      vl_valida = false;
      vl_message = 'Seleccionar tipo de documento';
    } else if (String(vl_Nombre).trim() === '') {
      vl_valida = false;
      vl_message = 'Ingresar Nombres';
    } else if (String(vl_ApPaterno).trim() === '') {
      vl_valida = false;
      vl_message = 'Ingresar Apellido Paterno';
    } else if (String(vl_ApMaterno).trim() === '') {
      vl_valida = false;
      vl_message = 'Ingresar Apellido Materno';
    } else if (vl_FecNacimiento === null || vl_FecNacimiento === undefined) {
      vl_valida = false;
      vl_message = 'Ingresar Fecha de Nacimiento';
    } else if (vl_Sexo === "") {
      vl_valida = false;
      vl_message = 'Seleccionar Sexo';
    } else if (vl_CodigoServicio === "") {
      vl_valida = false;
      vl_message = 'Seleccionar Servicio';
    } else if (Number(vl_CostoServicio) === 0 || (!Number(vl_CostoServicio))) {
      vl_valida = false;
      vl_message = 'Ingresar costo de servicio';
    } else if (vl_Pagado) {
      // console.log(vl_Pagado);
      if (Number(vl_MontoPagado) === 0 || (!Number(vl_MontoPagado))) {
        vl_valida = false;
        vl_message = 'Ingresar un Importe de pago';
      } else if (Number(vl_MontoPagado) > Number(vl_CostoServicio)) {
        vl_valida = false;
        vl_message = 'Importe no puede ser mayor al costo del servicio';
      } else if (vl_IdFormaPago === '') {
        vl_valida = false;
        vl_message = 'Seleccionar una forma de Pago';
      }
    }

    if (vl_MinoriaEdad) {
      // console.log(vl_NombreAcomp);
      if (String(vl_NombreAcomp).trim() === '') {
        vl_valida = false;
        vl_message = 'Ingresar apoderado/Acompañante';
      }
      if (String(vl_NumDocumentoAcomp).trim() === '') {
        vl_valida = false;
        vl_message = 'Ingresar nº documento Acompañante';
      }
      if (vl_IdParentescoAcomp === '0') {
        vl_valida = false;
        vl_message = 'Especificar el parentesco del Acompañante';
      }
    }

    return [vl_valida, vl_message];
  }

  // const showConfirmSave = (e:any) => {
  //   // setVisible(true);
  //   // console.log(b_visible);
  //   confirmDialog({
  //     message: '¿Guardar Registro?',
  //     header: 'Confirmar para continuar',
  //     icon: 'pi pi-exclamation-triangle',
  //     accept: () => handleOnSubmitAdmision(),
  //     reject: () => hanldeCloseShowConfirmSave(),
  //     visible: b_visible
  //   });
  // }

  const handleCloseShowConfirmSave = () => {
    setVisibleConfirmDialog(false);
  }

  const handleCloseShowConfirmAnular = () => {
    setVisibleConfirmDialog1(false);
  }


  const handleOnSubmitAdmision = async () => {

    switch (vl_ACTION) {
      case 'I': case 'U':
        setVisibleConfirmDialog(false);
        const IsValidaCampos = onValidarCampos();
        // console.log(IsValidaCampos);
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
          toast.current.show({ severity: 'info', summary: 'Campo no válido', detail: IsMessage, life: 8000 });
          return;
        }
        break;
      case 'A':
        setVisibleConfirmDialog1(false);

        break;
      default:
        break;
    }

    const jsonDir: any = [];
    jsonDir.push({
      'Ubigeo': vl_DireccionDistrito,
      'TipoVia': String(vl_DireccionTipovia).trim(),
      'NombreVia': String(vl_DireccionNombrevia).trim(),
      'Numero': String(vl_DireccionNumero).trim(),
      'Manzana': String(vl_DireccionManzana).trim(),
      'Lote': String(vl_DireccionLote).trim(),
      'Dpto': String(vl_DireccionDpto).trim(),
      'Urbanizacion': String(vl_DireccionUrbanizacion).trim()
    });

    const param = {
      VP_ACTION: vl_ACTION,
      VP_IdRegistro: vl_IdRegistro,
      VP_IdAdmision: vl_IdAdmision,
      VP_IdCliente: vl_IdCliente,
      VP_MinoriaEdad: (vl_MinoriaEdad) ? 1 : 0,
      VP_NumeroHistoriaClinica: vl_NumeroHistoriaClinica,
      VP_IdHistoriaClinica: vl_IdHistoriaClinica,
      VP_IdTipoDocumento: vl_IdTipoDocumento,
      VP_NumeroDocumento: vl_NumeroDocumento,
      VP_Nombres: vl_Nombre,
      VP_ApPaterno: vl_ApPaterno,
      VP_ApMaterno: vl_ApMaterno,
      VP_FechaNacimiento: setIntlDateTimeFormat(vl_FecNacimiento),
      VP_Edad: vl_Edad,
      VP_Sexo: vl_Sexo,
      VP_DireccionEmail: vl_DireccionEmail,
      VP_NumeroTelefono: vl_NumeroTelefono,
      VP_NombreAcomp: vl_NombreAcomp,
      VP_NumDocumentoAcomp: vl_NumDocumentoAcomp,
      VP_IdParentescoAcomp: vl_IdParentescoAcomp,
      VP_CodigoServicio: vl_CodigoServicio,
      VP_CostoServicio: vl_CostoServicio,
      VP_Pagado: (vl_Pagado) ? 1 : 0,
      VP_MontoPagado: vl_MontoPagado,
      VP_IdFormaPago: vl_IdFormaPago,
      VP_ReferenciaPago: vl_ReferenciaPago,
      VP_Observaciones: vl_Observaciones,
      VP_EstadoRegistro: 'G',
      VP_IdUsuario: localStorage.getItem('authIdUsuario'),
      VP_IdSucursal: localStorage.getItem('authIdSucursal'),
      VP_Direccion: JSON.stringify(jsonDir)
    };

    // console.log(param);
    // return;

    const response = await fetchSinToken('admision/pa_set_registro_admision', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];

    console.log(record);

    if (record) {
      const message = record.sql_message;
      toast.current.show({ severity: 'info', summary: 'Message', detail: message, life: 8000 });
      //eventos de la pantalla anterior
      if (record.sql_error === 0) {
        props.setOpenedAdmisionForm(false);
        props.isHide();
        props.setBuscar();
      }

    } else {
      const message =
        'Code: ' + resp.data.code + '<br>' +
        'Errno: ' + resp.data.errno + '<br>' +
        'Sql: ' + resp.data.sql + '<br>' +
        'SqlState: ' + resp.data.sqlState + '<br>' +
        'SqlMessage: ' + resp.data.sqlMessage + '<br>';
      toast.current.show({ severity: 'error', summary: 'Error Message', detail: message, life: 3000 });
    }
    // console.log(resp)
  }


  //initializa valores de input 
  const setInitInputValCliente = () => {
    //enable
    setDisabledNumeroDocumento(false);
    setInputValIdCliente('0');
    setInputValIdHistoriaClinica('0');
    setInputValNumeroHistoriaClinica('');
    setInputValFechaRegistro('');
    setInputValNumeroDocumento('');
    setInputValIdTipoDocumento('1');
    setInputValMinoriaEdad(false);
    setInputValNombre('');
    setInputValApPaterno('');
    setInputValApMaterno('');
    setInputValFechaNacimiento((new Date(1, 1, 1900)));
    setInputValEdad(0);
    setInputValSexo('');
    setInputValDireccionEmail('');
    setInputValNumeroTelefono('');
    setInputValDireccionDepartamento('15');
    setInputValDireccionProvincia('1501');
    setInputValDireccionDistrito('140101');
    setInputValDireccionTipovia('AV.');
    setInputValDireccionNombrevia('');
    setInputValDireccionNumero('');
    setInputValDireccionManzana('');
    setInputValDireccionLote('');
    setInputValDireccionDpto('');
    setInputValDireccionUrbanizacion('');

  }

  // buscar CLIENTE x numero de documento
  const getBuscarCliente = async (in_NumeroDocumento_f: any) => {
    //e.preventDefault();
    const param = {
      "VP_IdCliente": 0,
      "VP_NumeroDocumento": in_NumeroDocumento_f,
      "VP_NumeroHistoriaClinica": "",
      "VP_Nombres": "",
      "VP_Apellidos": ""
    }
    const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    // console.log(record);
    setInitInputValCliente();
    if (record) {
      //si existe cliente dehabilitar datos del cliente      
      setDisabledNumeroDocumento(true);
      setInputValIdCliente(record.IdCliente);
      setInputValNombre(record.Nombres);
      setInputValApPaterno(record.ApPaterno);
      setInputValApMaterno(record.ApMaterno);
      setInputValFechaNacimiento(new Date(Date.parse(record.FechaNacimiento)));
      setInputValEdad(record.Edad);
      setInputValNumeroDocumento(record.NumeroDocumento);
      setInputValIdTipoDocumento(record.IdTipoDocumento);
      setInputValSexo(record.Sexo);
      setInputValNumeroHistoriaClinica(record.NumeroHistoriaClinica);
      setInputValIdHistoriaClinica(record.IdHistoriaClinica);
      const jsonInformacionContacto = record.InformacionContacto;
      // console.log(jsonInformacionContacto);
      if (Object.keys(jsonInformacionContacto).length > 0) {
        setInputValDireccionEmail(jsonInformacionContacto[0].DireccionEmail);
        setInputValNumeroTelefono(jsonInformacionContacto[0].NumeroTelefono);
      }
      const jsonDireccion = record.Direccion;
      if (Object.keys(jsonDireccion).length > 0) {
        setInputValDireccionDistrito(jsonDireccion[0].Ubigeo);
        setInputValDireccionTipovia(jsonDireccion[0].TipoVia);
        setInputValDireccionNombrevia(jsonDireccion[0].NombreVia);
        setInputValDireccionNumero(jsonDireccion[0].Numero);
        setInputValDireccionManzana(jsonDireccion[0].Manzana);
        setInputValDireccionLote(jsonDireccion[0].Lote);
        setInputValDireccionDpto(jsonDireccion[0].Dpto);
        setInputValDireccionUrbanizacion(jsonDireccion[0].Urbanizacion);
      }

    } else {
      const message = 'No existe cliente registrado.';
      toast.current.show({ severity: 'warn', summary: '::PSISABE', detail: message, life: 10000 });
      inputNumeroDocumentoRef.current.focus();
    }
  }

  // new buscador clientes
  const handleClickBuscar = (e: any) => {
    isMounted.current = true;
    openModal1();
  }
  const setIsMounted = (val: boolean) => {
    isMounted.current = val;
  }
  const BuscadorClientesForm = () => {
    return (
      <BuscadorClientes
        isOpen={isOpenModal1}
        isHide={closeModal1}
        getDataCliente={getDataCliente}
        setIsMounted={setIsMounted}
      />
    );
  }
  const getDataCliente = (data: any) => {
    // console.log(data);
    getBuscarCliente(data.NumeroDocumento);
  }

  const renderFooter = () => {
    return (
      <div className="border-top-2 border-yellow-500 pt-1" >
        <Button label="Grabar" hidden={b_hiddenGrabar} icon="pi pi-save" onClick={() => setVisibleConfirmDialog(true)} />
        <Button label="Anular" hidden={b_hiddenAnular} icon="pi pi-ban" onClick={() => setVisibleConfirmDialog1(true)} />
        <Button label="Cerrar" icon="pi pi-times" className="p-button-rounded p-button-danger  ml-2"
          onClick={() => {
            props.setOpenedAdmisionForm(false);
            props.isHide()
          }} />
      </div>
    );
  }
  const renderHeader = () => {
    return (
      <>
        <TitleForm title={vl_IdAdmision !== '0' ? 'Registro de servicio #ID: ' + vl_IdAdmision : 'Registro de servicio'} />
      </>
    );
  }

  return (
    <Dialog
      header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
      visible={props.isOpen}
      footer={renderFooter}
      onHide={() => {
        props.setOpenedAdmisionForm(false);
        props.isHide();
      }}
    // visible={props.isOpen} modal position='center' closeOnEscape closable={false} showHeader={false}
    // style={{ width: '850px', height: '660px' }}
    // footer={renderFooter}
    // onHide={() => {
    //   props.setOpenedAdmisionForm(false);
    //   props.isHide()
    // }}
    >

      <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
      <div className="p-field p-grid mb-1">
        <div className="p-col">
          <label htmlFor="lastname3" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Buscar Cliente:</label>
          <InputText type="text" style={{ width: '120px' }} aria-describedby="username1-help"
            maxLength={8}
            disabled={disabledNumeroDocumento_f}
            ref={inputNumeroDocumento_f_Ref}
            value={vl_NumeroDocumento_f}
            placeholder='Ingrese DNI'
            onChange={(e: any) => setInputValNumeroDocumento_f(String(e.currentTarget.value).toUpperCase())}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                getBuscarCliente(vl_NumeroDocumento_f);
                // inputNumeroDocumentoRef.current.focus();
              }
            }}
          />

          <Button label=""
            onClick={(e: any) => getBuscarCliente(e)}
            // style={{ height: '35px' }}
            icon="fas fa-search" className="p-button ml-1" />

          <Button label="" tooltip='Busqueda de Pacientes'
            onClick={(e: any) => handleClickBuscar(e)}
            icon="pi pi-user-plus" className="p-button-rounded mt-0 p-button-warning ml-2 "
          />

          <label htmlFor="" className="p-col-fixed ml-4 mr-1 font-bold" style={{ textAlign: 'end' }}>#HC:</label>
          <InputText type="text" className='font-bold text-lg' style={{ width: '110px', textAlign: 'center' }} readOnly
            value={vl_NumeroHistoriaClinica}
          />
          <label htmlFor="" className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>F. Registro:</label>
          <InputText type="text" className='text-sm' style={{ height: '30px', width: '155px' }} readOnly value={vl_FechaRegistro} />
          {/* <InputText type="hidden" value={vl_IdHistoriaClinica} /> */}
        </div>
      </div>

      {/* <div className="p-field p-grid mb-1">
        <div className="p-col mb-0">
          <InputText type="hidden" value={vl_IdAdmision} />
          <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '567px', textAlign: 'end' }}>Fecha y Hora:</label>
          <InputText type="text" className='text-sm' style={{ height: '30px', width: '170px' }} readOnly value={vl_FechaRegistro} />
        </div>
      </div> */}

      <div className="p-field p-grid mb-1">
        <div className="p-col">
          <label className="p-col-fixed mr-1 font-bold " style={{ width: '110px', textAlign: 'end' }}>Nº Doc.:</label>
          <InputText type="text" style={{ height: '30px', width: '120px', textAlign: 'center' }}
            value={vl_NumeroDocumento}
            onChange={(e: any) => setInputValNumeroDocumento(String(e.currentTarget.value).trim().toUpperCase())}
            ref={inputNumeroDocumentoRef}
            disabled={disabledNumeroDocumento}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                getApiConultaDNI(String(e.currentTarget.value).trim().toUpperCase());
                inputNombreRef.current.focus();
                // e.preventDefault();
              }
            }}
          />

          <Button
            onClick={(e: any) => {
              getApiConultaDNI(vl_NumeroDocumento);
            }}
            icon="pi pi-user-plus" className="p-button-rounded ml-1"
            tooltip="Obtener Nombres con numero de DNI ingresado"
          />

          <label className="p-col-fixed mr-1 font-bold " style={{ width: '60px', textAlign: 'end' }}>Tipo:</label>
          <Dropdown style={{ height: '35px', width: '200px' }}
            value={vl_IdTipoDocumento}
            options={dataIdTipoDocumento}
            onChange={onIdTipoDocumentoChange}
            optionValue="IdTipoDocumento"
            optionLabel="Codigo"
            placeholder="Seleccionar" />
          <label className="font-light text-base ml-3" style={{ textAlign: 'end' }}>Menor de edad</label>
          <InputSwitch checked={vl_MinoriaEdad} style={{ height: '30px' }}
            onChange={(e) => onChangeSwitchMinoriaEdad(e)}
          // onChange={(e) => setInputValMinoriaEdad(e.value)}              
          />
        </div>
      </div>

      <div className="p-field p-grid mb-1">
        <div className="p-col">
          <InputText type="hidden" value={vl_IdCliente} />
          <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '110px', textAlign: 'end' }}>Nombre:</label>
          <InputText type="text" style={{ height: '30px', width: '210px' }}
            value={vl_Nombre}
            onChange={(e) => setInputValNombre(String(e.currentTarget.value).toUpperCase())}
            ref={inputNombreRef}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                inputApPaternoRef.current.focus();
              }
            }}
          />
          <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '110px', textAlign: 'end' }}>Apellidos:</label>
          <InputText type="text" style={{ height: '30px', width: '150px' }} placeholder='Paterno'
            value={vl_ApPaterno}
            onChange={(e) => setInputValApPaterno(String(e.currentTarget.value).toUpperCase())}
            ref={inputApPaternoRef}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                inputApMaternoRef.current.focus();
              }
            }}
          />
          <label htmlFor="" className="p-col-fixed mr-0 font-bold " style={{ width: '10px', textAlign: 'center' }}></label>
          <InputText type="text" style={{ height: '30px', width: '150px' }} placeholder='Materno'
            value={vl_ApMaterno}
            onChange={(e) => setInputValApMaterno(String(e.currentTarget.value).toUpperCase())}
            ref={inputApMaternoRef}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                // inputFechaNacimientoRef.current.focus();
              }
            }}

          />
        </div>
      </div>

      <div className="p-field p-grid mb-1">
        <div className="p-col">
          <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '110px', textAlign: 'end' }}>Fecha Nac.:</label>
          <Calendar dateFormat="mm-dd-yy"
            showIcon={true} style={{ height: '30px', width: '170px' }}     // monthNavigator yearNavigator yearRange="1930:2050"
            maxDate={new Date()}
            value={vl_FecNacimiento}
            ref={inputFechaNacimientoRef}
            onChange={(e: any) => setInputValFechaNacimiento(e.value)}
          />

          <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Edad:</label>
          <InputText type="number" style={{ height: '30px', width: '70px', textAlign: 'center' }}
            disabled value={vl_Edad}
          />

          <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Sexo:</label>
          <RadioButton value="M" name="btn_sexo" style={{ height: '30px' }}
            onChange={(e) => setInputValSexo(e.value)}
            checked={vl_Sexo === "M"}
          />
          <label htmlFor="cb1" className="p-checkbox-label ml-1 mr-2 ">Masculino</label>
          <RadioButton value="F" name="btn_sexo" style={{ height: '30px' }}
            onChange={(e) => setInputValSexo(e.value)}
            checked={vl_Sexo === "F"}
          />
          <label htmlFor="cb2" className="p-checkbox-label ml-1">Femenino</label>
        </div>
      </div>

      <div className="p-field p-grid mb-1">
        <div className="p-col">
          <label className="p-col-fixed mr-1 font-bold " style={{ width: '110px', textAlign: 'end' }}>Correo:</label>
          <InputText type="email" style={{ height: '30px', width: '300px' }}
            value={vl_DireccionEmail}
            onChange={(e) => setInputValDireccionEmail(String(e.currentTarget.value).toUpperCase())}
            ref={inputDireccionEmailRef}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                // inputFechaNacimientoRef.current.focus();
              }
            }}

          />
          <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Nº Celular:</label>
          <InputText type="text" style={{ height: '30px', width: '200px' }}
            value={vl_NumeroTelefono}
            onChange={(e) => setInputValNumeroTelefono(String(e.currentTarget.value).toUpperCase())}
            ref={inputNumeroTelefonoRef}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                // inputFechaNacimientoRef.current.focus();
              }
            }}
          />

        </div>
      </div>

      <TabView>
        <TabPanel header="Servicio" leftIcon="pi pi-calendar">
          <div className="p-field p-grid mb-1">
            <div className="p-col">
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '90px', textAlign: 'end' }}>Servicio:</label>
              <Dropdown style={{ height: '38px', width: '400px' }} filter showClear filterBy="Descripcion"
                value={vl_CodigoServicio}
                options={dataCodigoServicios}
                onChange={onCodigoServicioChange}
                optionValue="CodigoServicio"
                optionLabel="Descripcion"
                placeholder="Seleccionar opción" />

              <label htmlFor="" className="p-col-fixed ml-3 mr-1 font-bold " style={{ textAlign: 'end' }}>Costo:</label>
              <InputNumber type="text" inputStyle={{ height: '30px', width: '90px', textAlign: 'right' }}
                inputClassName='bg-yellow-500 font-bold text-gray-900 border-round '
                mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                value={vl_CostoServicio}
                onChange={
                  (e) => {
                    setInputValCostoServicio(Number(e.value));
                    setInputValMontoPagado(Number(e.value));
                  }
                }
                ref={inputCostoServicioRef}
              />
            </div>
          </div>

          <div className="p-field p-grid mb-1">
            <div className="p-col mb-1 " >
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '90px', textAlign: 'end' }}>Pagar</label>
              <InputSwitch checked={vl_Pagado}
                onChange={(e: any) => onChangeSwitchPagar(e)}
              />
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Importe:</label>
              <InputText type="text" style={{ height: '35px', width: '90px', textAlign: 'right' }}
                value={vl_MontoPagado}
                disabled={disabledMonto}
                ref={inputMontoRef}
                onChange={(e) => setInputValMontoPagado(String(e.currentTarget.value))}
              />

              <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Forma Pago:</label>
              <Dropdown style={{ height: '38px', width: '300px' }}
                value={vl_IdFormaPago}
                disabled={disabledIdFormaPago}
                options={dataIdFormaPago}
                optionValue="IdFormaPago"
                optionLabel="Descripcion"
                onChange={onIdFormaPagoChange}
                placeholder="Seleccionar" />

            </div>
            <div className="p-col">
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '160px', textAlign: 'end' }}>Referencia Pago:</label>
              <InputText type="text" style={{ height: '35px', width: '50%', textAlign: 'left' }}
                value={vl_ReferenciaPago}
                disabled={disabledReferenciaPago}
                ref={inputReferenciaPagoRef}
                onChange={(e) => setInputValReferenciaPago(String(e.currentTarget.value))}
              />
            </div>
            <div className="p-field p-grid mb-0">
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '110px', textAlign: 'end' }}>Comentario:</label>
              <div className="p-col mt-1 ">
                <InputTextarea rows={2} cols={30}
                  style={{ width: '99%' }}
                  value={vl_Observaciones}
                  ref={inputObservacionesRef}
                  onChange={(e: any) => setInputValObservaciones(e.target.value)}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Dirección" leftIcon="pi pi-map-marker">
          <div className="p-field p-grid mb-1">
            <div className="p-col mb-1">
              <label className="p-col-fixed mr-1 font-bold " style={{ width: '220px', textAlign: 'start' }}>Departamento:</label>
              <label className="p-col-fixed mr-1 font-bold " style={{ width: '210px', textAlign: 'start' }}>Provincia:</label>
              <label className="p-col-fixed mr-1 font-bold " style={{ width: '90px', textAlign: 'end' }}>Distrito:</label>
            </div>
            <div className="p-col mb-1 ">
              <Dropdown style={{ height: '38px', width: '220px' }} filter filterBy="Departamento"
                value={vl_DireccionDepartamento}
                options={dataDireccionDepartamento}
                onChange={onDireccionDepartamentoChange}
                optionValue="DepartamentoInei"
                optionLabel="Departamento"
                placeholder="Seleccionar" />
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '1px', textAlign: 'center' }}></label>
              <Dropdown style={{ height: '38px', width: '220px' }} filter filterBy="Provincia"
                value={vl_DireccionProvincia}
                options={dataDireccionProvincia}
                onChange={onDireccionProvinciaChange}
                optionValue="ProvinciaInei"
                optionLabel="Provincia"
                placeholder="Seleccionar" />
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '1px', textAlign: 'center' }}></label>
              <Dropdown style={{ height: '38px', width: '220px' }} filter filterBy="Distrito"
                value={vl_DireccionDistrito}
                options={dataDireccionDistrito}
                onChange={onDireccionDistritoChange}
                optionValue="UbigeoInei" //UbigeoReniec
                optionLabel="Distrito"
                placeholder="Seleccionar" />
            </div>

            <div className="p-col mb-1 ">
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '70px', textAlign: 'end' }}>Tipo Vía:</label>
              <Dropdown style={{ height: '38px', width: '180px' }} filter filterBy="Distrito"
                value={vl_DireccionTipovia}
                options={dataDireccionTipovia}
                onChange={onDireccionTipoviaChange}
                optionValue="CodigoVia"
                optionLabel="DescripcionVia"
                placeholder="Seleccionar" />

              <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Nombre Vía:</label>
              <InputText type="text" style={{ height: '35px', width: '270px', textAlign: 'start' }}
                value={vl_DireccionNombrevia}
                onChange={(e) => setInputValDireccionNombrevia(String(e.currentTarget.value).toUpperCase())}
                ref={inputDireccionNombreviaRef}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    inputDireccionNumeroRef.current.focus();
                  }
                }}
              />
            </div>

            <div className="p-col mb-1 ">
              <label className="p-col-fixed mr-1 font-bold " style={{ width: '70px', textAlign: 'end' }}>Número:</label>
              <InputText type="text" style={{ height: '35px', width: '70px', textAlign: 'start' }}
                value={vl_DireccionNumero}
                onChange={(e) => setInputValDireccionNumero(String(e.currentTarget.value).toUpperCase())}
                ref={inputDireccionNumeroRef}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    inputDireccionManzanaRef.current.focus();
                  }
                }}
              />

              <label className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Manzana:</label>
              <InputText type="text" style={{ height: '35px', width: '70px', textAlign: 'start' }}
                value={vl_DireccionManzana}
                onChange={(e) => setInputValDireccionManzana(String(e.currentTarget.value).toUpperCase())}
                ref={inputDireccionManzanaRef}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    inputDireccionLoteRef.current.focus();
                  }
                }}
              />

              <label className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Lote:</label>
              <InputText type="text" style={{ height: '35px', width: '70px', textAlign: 'start' }}
                value={vl_DireccionLote}
                onChange={(e) => setInputValDireccionLote(String(e.currentTarget.value).toUpperCase())}
                ref={inputDireccionLoteRef}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    inputDireccionDptoRef.current.focus();
                  }
                }}
              />

              <label className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Dpto:</label>
              <InputText type="text" style={{ height: '35px', width: '70px', textAlign: 'start' }}
                value={vl_DireccionDpto}
                onChange={(e) => setInputValDireccionDpto(String(e.currentTarget.value).toUpperCase())}
                ref={inputDireccionDptoRef}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    inputDireccionUrbRef.current.focus();
                  }
                }}
              />

            </div>
            <div className="p-col mb-1 ">
              <label className="p-col-fixed mr-1 font-bold " style={{ width: '70px', textAlign: 'end' }}>Urb.:</label>
              <InputText type="text" style={{ height: '35px', width: '50%', textAlign: 'start' }}
                value={vl_DireccionUrbanizacion}
                onChange={(e) => setInputValDireccionUrbanizacion(String(e.currentTarget.value).toUpperCase())}
                ref={inputDireccionUrbRef}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    //inputDireccionUrbRef.current.focus();
                  }
                }}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Inf. Adicional" leftIcon="pi pi-user" >
          <div className="p-field p-grid mb-1">
            <div className="p-col mb-1">
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Acompañante:</label>
              <InputText type="text" style={{ height: '35px', width: '300px' }}
                value={vl_NombreAcomp}
                onChange={(e) => setInputValNombreAcomp(String(e.currentTarget.value))}
              />
              <label htmlFor="" className="p-col-fixed mr-1 ml-3 font-bold " style={{ textAlign: 'end' }}>Nº Doc.:</label>
              <InputText type="text" style={{ height: '35px', width: '120px' }}
                value={vl_NumDocumentoAcomp} maxLength={8}
                onChange={(e) => setInputValNumDocumentoAcomp(String(e.currentTarget.value))}
              />
            </div>

            <div className="p-col">
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Parentesco:</label>
              <Dropdown style={{ height: '38px', width: '200px' }}
                options={dataIdParentescoAcomp}
                value={vl_IdParentescoAcomp}
                onChange={onIdParentescoAcompChange}
                optionValue="IdCodigo"
                optionLabel="Descripcion"
                placeholder="Seleccionar" />
            </div>
          </div>
        </TabPanel>
      </TabView>

      <Toast ref={toast} position="top-center"></Toast>
      <Toast ref={toastConfirm} position="center"></Toast>
      <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
        message="¿Guardar Registro?"
        header="Confirmar para continuar"
        icon="pi pi-exclamation-triangle"
        accept={handleOnSubmitAdmision}
        reject={handleCloseShowConfirmSave}
      />
      <ConfirmDialog visible={b_visible1} onHide={() => setVisibleConfirmDialog1(false)}
        message="Anular Registro?"
        header="Confirmar para continuar"
        icon="pi pi-exclamation-triangle"
        accept={handleOnSubmitAdmision}
        reject={handleCloseShowConfirmAnular}
      />

    </Dialog>
  )
};

export default AdmisionForm;
import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from 'react';

// import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Calendar } from 'primereact/calendar';
import useModal from '../../../context/WindowsOpen';
import { fetchSinToken } from '../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../helpers/functions';
import { Toast } from 'primereact/toast';
// import { InputNumber } from 'primereact/inputnumber';
import { DerivacionPacienteForm } from '../../components/admision/derivacionPaciente/DerivacionPacienteForm';
import { AppContext01 } from '../../../context/AppContext';
import { FooterGridQuantity } from '../../components/util/FooterGrid';
import { useParams } from 'react-router-dom';
import BuscadorClientes from '../../components/util/BuscadorClientes';


const DerivacionPacienteView = () => {
  const params = useParams();
  const isMounted = useRef(false);
  // const [isOpenOrdenAtencionForm, setIsOpenOrdenAtencionForm] = useState(false);
  const [valFechaDesde, setvalFechaDesde] = useState<any>(new Date());
  const [valFechaHasta, setvalFechaHasta] = useState<any>(new Date());
  const [valNumeroHistoria, setvalNumeroHistoria] = useState(params.id ? params.id : '');
  const [ordenAtencion, setOrdenAtencion] = useState([]);

  //PARA FORMULARIO REGISTRO
  const [isMode, setMode] = useState('I');
  const [selectedGrid1, setSelectedGrid1] = useState<any>([]);

  // Para open/close formularios
  const [isOpenModal, openFormModal, closeFormModal] = useModal();
  // mensaje
  const toast = useRef<any>(null);

  const handleClickNew = (e: any) => {
    setMode('I');
    setSelectedGrid1([]);
    isMounted.current = true;
    openFormModal();
  }
  const handleClickEdit = (e: any) => {
    // console.log(selectedGrid1);
    setMode('U');
    // console.log(isMode);
    if (Object.keys(selectedGrid1).length <= 0) {
      // console.log('seleccionar un registro');
      const message = 'Seleccionar un registro';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // setIsOpenOrdenAtencionForm(true);
    isMounted.current = true;
    openFormModal();
  }

  const handleClickAnular = (e: any) => {
    setMode('A');
    if (Object.keys(selectedGrid1).length <= 0) {
      const message = 'Seleccionar un registro';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // console.log(selectedGrid1.EstadoRegistro);
    if (selectedGrid1.Estado === 0) {
      const message = '¡Registro ya fue anulado!';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // setIsOpenOrdenAtencionForm(true);
    isMounted.current = true;
    openFormModal();
  }

  const setIsMounted = (val: boolean) => {
    isMounted.current = val;
  }


  // Agrega titulo a la barra de navegacion superior  
  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/Proceso/Derivación Paciente');
  }

  const DerivacionPacienteFormNew = () => {
    return (
      <DerivacionPacienteForm
        isOpen={isOpenModal}
        isHide={closeFormModal}
        isData={selectedGrid1}
        isMode={isMode}
        setBuscar={setBuscar}
        setIsMounted={setIsMounted}
      />
    );
  }

  useEffect(() => {
    handleSetTitle();
    pa_get_derivacion_paciente();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const pa_get_derivacion_paciente = async () => {
    const param = {
      "vp_NumeroDocumento": '',
      "vp_NumeroHistoria": valNumeroHistoria,
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      VP_IdSucursal: localStorage.getItem('authIdSucursal')
    }
    setSelectedGrid1([]); //new
    const response = await fetchSinToken('admision/pa_get_derivacion_paciente', param, 'POST');
    const resp = await response.json();
    // console.log(resp.data.length);
    if (resp.data.errno) {
      const message =
        'Code: ' + resp.data.code +
        'Errno: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 3000 });
    } else {
      if (resp.data.length > 0)
        setOrdenAtencion(resp.data);
      else
        toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No available data ', life: 3000 });
    }

  }

  //buscador
  const pa_get_derivacion_paciente02 = async (p_NumeroHistoria: any) => {
    const param = {
      "vp_NumeroDocumento": '',
      "vp_NumeroHistoria": p_NumeroHistoria,
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      VP_IdSucursal: localStorage.getItem('authIdSucursal')
    }
    setSelectedGrid1([]); //new
    const response = await fetchSinToken('admision/pa_get_derivacion_paciente', param, 'POST');
    const resp = await response.json();
    // console.log(resp.data.length);
    if (resp.data.errno) {
      const message =
        'Code: ' + resp.data.code +
        'Errno: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 3000 });
    } else {
      if (resp.data.length > 0)
        setOrdenAtencion(resp.data);
      else
        toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No available data ', life: 3000 });
    }

  }

  const setBuscar = () => {
    pa_get_derivacion_paciente();
  }

  // new buscador clientes
  const [isOpenModal1, openModal1, closeModal1] = useModal(); //buscador
  const handleClickBuscar = (e: any) => {
    isMounted.current = true;
    openModal1();
  }
  const setIsMounted1 = (val: boolean) => {
    isMounted.current = val;
  }
  const BuscadorClientesForm = () => {
    return (
      <BuscadorClientes
        isOpen={isOpenModal1}
        isHide={closeModal1}
        getDataCliente={getDataCliente}
        setIsMounted={setIsMounted1}
      />
    );
  }

  const getDataCliente = (data: any) => {
    // console.log(data);    
    pa_get_derivacion_paciente02(data.NumeroHistoriaClinica);
  }


  const footer = () => {
    let vl_Total: any = ordenAtencion.length;
    // ordenAtencion.forEach(
    //   function (currentValue: any) {
    //     Total = Total + parseFloat(currentValue.CostoTotal);
    //   }
    // );
    return (
      <>
        <FooterGridQuantity Total={vl_Total} />
        {/* <div className="block bg-yellow-500 font-bold text-end p-1 mt-1 mb-2 flex justify-content-end">
          <label htmlFor="" className="p-col-fixed mr-1 pt-1 font-bold" style={{ textAlign: 'end' }}>Registros:</label>
          <InputNumber type="text" inputStyle={{ height: '30px', width: '100px', textAlign: 'end' }}
            value={Total} readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
          />
        </div> */}
      </>
    );
  }

  const rowClassName = (data: any) => {
    // return isSelectable(data.Situacion, 'Situacion') ? '' : 'p-disabled bg-gray-100 line-through text-red-500';
    return data.Estado === 1 ? '' : 'bg-gray-100 line-through text-red-500';
  }


  return (
    <>
      <div className="card p-1 mt-0" style={{ marginTop: '.1em' }}  >
        <div className="card-container gray-container">
          <div className="block bg-gray-10 font-bold text-end p-2 mb-2 flex justify-content-end border-bottom-1 ">
            {/* <Menubar model={MenubarItems} style={{ height: '60px' }} /> */}
            <span className="p-buttonset">
              <Button
                label="Nuevo"
                icon="pi pi-file"
                onClick={(e) => handleClickNew(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Editar"
                icon="pi pi-pencil"
                onClick={(e) => handleClickEdit(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Anular"
                icon="pi pi-trash"
                onClick={(e) => handleClickAnular(e)}
                className="p-button-sm p-button-danger p-button-raised"
              />
              {/* <Button
                label="Imprimir"
                icon="pi pi-print"
                onClick={(e) => {
                  alert('Vaya! no implementado');
                }}
                className="p-button-sm p-button-primary p-button-raised"
              /> */}

            </span>
          </div>
          <div className="block bg-gray-100 filter-view font-bold text-left p-1  mb-1">
            <div className="p-field p-grid mb-1">
              <div className="p-col">
                <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Desde:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                  maxDate={new Date()}
                  value={valFechaDesde}
                  onChange={(e) => setvalFechaDesde(e.value)}
                />
                <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>Hasta:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                  maxDate={new Date()}
                  value={valFechaHasta}
                  onChange={(e) => setvalFechaHasta(e.value)}
                />
                <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>N° HC.:</label>
                <InputText type="text" style={{ width: '120px' }} className="p-inputtext-sm" autoFocus
                  value={valNumeroHistoria}
                  onChange={(e) => setvalNumeroHistoria(String(e.currentTarget.value).toUpperCase())}
                />
                <Button title="Buscar"
                  onClick={(e) => setBuscar()}
                  icon="pi pi-search" className="p-button-rounded ml-2" />
                <Button label="" tooltip='Busqueda de Pacientes'
                  onClick={(e: any) => handleClickBuscar(e)}
                  icon="pi pi-user-plus" className="p-button-rounded mt-0 p-button-warning ml-2 "
                />
              </div>
            </div>

          </div>
          <div className="text-center p-1  mb-1">
            <DataTable size="small" resizableColumns footer={footer}
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              stripedRows
              scrollable rowClassName={rowClassName}
              value={ordenAtencion}
              dataKey="IdDerivacion"
              selectionMode="single"
              selection={selectedGrid1}
              onSelectionChange={e => setSelectedGrid1(e.value)}
              scrollHeight="70vh"
              paginator
              rows={20}
            >
              <Column field="IdDerivacion" header="Nº" style={{ maxWidth: '4rem' }}   ></Column>
              <Column field="FechaDerivacion_1" header="Fecha" style={{ maxWidth: '7rem' }}  ></Column>
              <Column field="NumeroHistoriaClinica" header="Nº HC" style={{ maxWidth: '6rem' }}  ></Column>
              <Column field="NombrePaciente" header="Paciente/Usuario"  ></Column>
              <Column field="NumeroDocumento" header="Nº Doc." style={{ maxWidth: '7rem' }} bodyClassName='flex justify-content-center' ></Column>
              <Column field="FechaNacimiento_1" header="Fecha Nac." style={{ maxWidth: '7rem' }} ></Column>
              <Column field="Edad" header="Edad" style={{ maxWidth: '4rem' }} bodyClassName='flex justify-content-center'  ></Column>
              <Column field="NombreProfesional" header="Derivado de"
                body={(item: any) => {
                  return (item.NombreProfesional === '' ? item.DerivacionExterna : item.NombreProfesional);
                }}   ></Column>
              <Column field="NombreProfesionalDerivado" header="Derivado a"
                body={(item: any) => {
                  return (item.NombreProfesionalDerivado === '' ? item.DerivacionExternaHacia : item.NombreProfesionalDerivado);
                }}  ></Column>
              <Column field="TipoDerivacion_1" header="Tipo"  ></Column>
              <Column field="Estado" header="Estado" style={{ maxWidth: '4rem' }} bodyClassName='flex justify-content-center'
                body={(item: any) => {
                  return (<label title={'' + item.Estado_1} className={`${item.Estado === 1 ? ' pi pi-thumbs-up-fill ' : ' pi pi-thumbs-down-fill '}`}></label>);
                }}
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Toast ref={toast} position="top-center"></Toast>
      <React.Fragment>{
        isMounted.current && <DerivacionPacienteFormNew />
      }
      </React.Fragment>
      <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
    </>
  )
}

export default DerivacionPacienteView

import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Editor } from 'primereact/editor';
// import { Dropdown } from 'primereact/dropdown';
// import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react'
import { fetchSinToken } from '../../../helpers/fetch';

const NotificacionesView = ({ ...prop }) => {

    const [dataIdMessage, setDataIdMessage] = useState<any>([]);
    const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>('');
    const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>('');
    const [vl_Nombres, setNombres] = useState<any>('');
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
    const [vl_CountMessages, setCountMessages] = useState<any>(0);

    const [vl_SId, setSId] = useState<any>('');
    const [vl_MsFechaRegistro, setMsFechaRegistro] = useState<any>('');
    const [vl_MsTo, setMsTo] = useState<any>('');
    const [vl_MsAsunto, setMsAsunto] = useState<any>('');
    const [vl_MsBody, setMsBody] = useState<any>('');



    useEffect(() => {
        pa_get_whatsapp_messages(prop.IdCliente);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_whatsapp_messages = async (in_IdCliente: any) => {
        const param = {
            "VP_IdCliente": in_IdCliente,
            "VP_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('paciente/pa_get_whatsapp_messages', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        setCountMessages(record.length);
        setApellidoPaterno(record[0].ApellidoPaterno);
        setApellidoMaterno(record[0].ApellidoMaterno);
        setNombres(record[0].Nombres);
        setNumeroHistoriaClinica(record[0].NumeroHistoriaClinica);
        // console.log(record);
        setDataIdMessage(record);
        //selec x defaul la primera fila
        setOnSelectionChange(record[0]);
    }

    const setOnSelectionChange = (data: any) => {
        // console.log(data);
        setSId(data.SId);
        setMsFechaRegistro(data.MsFechaRegistro);
        setMsTo(data.MsTo);
        setMsAsunto(data.MsAsunto);
        setMsBody(data.MsBody);
    }

    const renderFooter = () => {
        return (
            <>
                <Button label="Cerrar" icon="pi pi-times" onClick={
                    () => {
                        prop.setIsMounted(false);
                        prop.isHide();
                    }
                } />
            </>
        );
    }
    const renderHeader = () => {
        return (
            <>
                <div className="bg-indigo-500 pb-2">
                    <div className="col mt-0 ml-0 text-white font-italic h-3rem" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='text-base font-bold'> Paciente: {vl_ApellidoPaterno + ' ' + vl_ApellidoMaterno + ', ' + vl_Nombres} </label><br />
                        <label className='text-base font-bold'> Nº HC. {vl_NumeroHistoriaClinica} </label>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Dialog
                header={renderHeader}
                modal draggable resizable closable showHeader maximizable
                visible={prop.isOpen}
                footer={renderFooter}
                onHide={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }}
            >
                <div className="flex flex-nowrap" >
                    <div className="justify-content-start pr-1 border-right-2 border-yellow-500">
                        <div className="col mb-1 text-black mr-1 " >
                            <label className="col text-sm " style={{ textAlign: 'left' }} >
                                <Badge value={vl_CountMessages} severity="danger" ></Badge> {'  '}Mensaje(s){'. '}
                                Seleccionar una para ver detalles.
                            </label>
                        </div>
                        <div className="p-col mb-1 " >
                            <DataTable
                                size="small"
                                value={dataIdMessage ?? []}
                                dataKey="IdMessage"
                                resizableColumns
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                selectionMode="single"
                                scrollDirection="horizontal"
                                onSelectionChange={(e: any) => {
                                    // console.log(e.value);
                                    setOnSelectionChange(e.value);
                                    // pa_get_paciente_plan_pagos(e.value.IdOrdenPago);
                                }}
                            >
                                <Column field="IdMessage" header="#ID" style={{ maxWidth: '70px' }} ></Column>
                                <Column field="MsFechaRegistro" header="Fecha envio" style={{ maxWidth: '220px', align: 'end' }}
                                    bodyClassName="flex justify-content-start"  ></Column>
                                {/* <Column field="MsDirection" header="Tipo" style={{ maxWidth: '220px' }}bodyClassName="flex justify-content-start" ></Column> */}
                                {/* <Column field="MsTo" header="Destination"headerClassName="flex justify-content-start"bodyClassName="flex justify-content-start "></Column> */}
                                <Column field="EventTitle" header="Asunto" style={{ maxWidth: '350px', minWidth: '250px' }} headerClassName="flex justify-content-start"
                                    bodyClassName="flex justify-content-start "></Column>
                                {/* <Column field="MsStatus" header="Estado" style={{ maxWidth: '150px', align: 'end' }}headerClassName="flex justify-content-start"bodyClassName="flex justify-content-start font-bold"></Column> */}
                            </DataTable>
                        </div>
                    </div>
                    <div className="justify-content-start pl-1 " >
                    <div className="p-col mb-1 ">
                            <label className="p-col-fixed pb-2 text-xl font-bold " style={{ textAlign: 'end' }}>SId Message:</label>
                            <label className="p-col-fixed mr-1 ml-1 font-normal " style={{ textAlign: 'end' }}>{vl_SId}</label>
                        </div>
                        <div className="p-col mb-1 ">
                            <label className="p-col-fixed pb-2 text-xl font-bold " style={{ textAlign: 'end' }}>Enviado:</label>
                            <label className="p-col-fixed mr-1 ml-1 font-normal " style={{ textAlign: 'end' }}>{vl_MsFechaRegistro}</label>
                        </div>
                        <div className="p-col mb-1 ">
                            <label className="p-col-fixed pb-2 text-xl font-bold " style={{ textAlign: 'end' }}># destino:</label>
                            <label className="p-col-fixed mr-1 ml-1 font-normal " style={{ textAlign: 'end' }}>{vl_MsTo}</label>
                        </div>
                        <div className="p-col mb-1 ">
                            <label className="p-col-fixed pb-2 text-xl font-bold " style={{ textAlign: 'end' }}>Asunto:</label>
                            <label className="p-col-fixed mr-1 ml-1 font-normal " style={{ textAlign: 'end' }}>{vl_MsAsunto}</label>
                        </div>
                        <div className="p-col mb-1 ">
                            <label className="p-col-fixed pb-2 text-xl font-bold " style={{ textAlign: 'end' }}>Mensaje:</label>
                            <Editor 
                                style={{ maxWidth: '600px' }}
                                // headerTemplate={header01}
                                readOnly
                                value={vl_MsBody}
                                onTextChange={(e) => setMsBody(String(e.htmlValue))}
                            />
                        </div>
                    </div>
                </div>

            </Dialog>
        </>
    )
}

export default NotificacionesView
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Fieldset } from 'primereact/fieldset'
import { InputNumber } from 'primereact/inputnumber'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { RadioButton } from 'primereact/radiobutton'
import { fetchSinToken } from '../../../helpers/fetch'
import { setIntlDateTimeFormat } from '../../../helpers/functions'
import { useNavigate, useParams } from 'react-router-dom'
import CajaPagoDetalle from './CajaPagoDetalle'
import useModal from '../../../context/WindowsOpen'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'

const CajaPagoComisionView = () => {
  const params = useParams();
  const [dataGridComisionStaff, setDataGridComisionStaff] = useState<any>([]);
  const [dataGridSelected, setDataGridSelected] = useState<any>([]);
  const [dataGridDetalleStaff, setDataGridDetalleStaff] = useState<any>([]);
  const [isOpenModal, openModal, closeModal] = useModal();
  const isMounted = useRef(false);
  // const [isMode, setMode] = useState('I');
  const [vl_mode, setModeCrud] = useState(params.mode === 'Edit' ? 'U' : 'I');
  //input C R U D
  const [vl_IdLiquidacionPago, setIdLiquidacionPago] = useState<any>(params.id);
  const [vl_TipoFecha, setTipoFecha] = useState<any>('E');
  const [vl_FechaEfectiva1, setFechaEfectiva1] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [vl_FechaEfectiva2, setFechaEfectiva2] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 15));
  const [vl_FechaPago, setFechaPago] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 15));
  const [vl_TotalPago, setTotalPago] = useState<any>(0);
  const [vl_Tipo, setTipo] = useState<any>('Q'); // QUINCELA X DEFAULT
  const [vl_Estado, setEstado] = useState<any>('I'); // APERTURADO O INICIADO...
  const [vl_Observaciones, setObservaciones] = useState<any>('');
  const [vl_ChkPsicologos, setChkPsicologos] = useState<boolean>(true);
  const [vl_ChkAdministrativo, setChkAdministrativo] = useState<boolean>(false);
  // Valores adicionales a toda la planilla
  const [vl_SwicthAdicional, setSwicthAdicional] = useState<boolean>(false);
  const [vl_ImporteBono, setImporteBono] = useState<any>(0.0);
  const [vl_ImporteBonoPor, setImporteBonoPor] = useState<any>(0); // % de incremento sobre el total de pago
  const [vl_ImporteDescto, setImporteDescto] = useState<any>(0.0);
  const [vl_ImporteDesctoPor, setImporteDesctoPor] = useState<any>(0); // % de descuento sobre el total de pago

  const refImporteBono = useRef<HTMLElement | HTMLInputElement | any>(null);  
  const refImporteDescto = useRef<HTMLElement | HTMLInputElement | any>(null);  
  const [disabledImporteBono, setDisabledImporteBono] = useState<boolean>(true);
  const [disabledImporteBonoPor, setDisabledImporteBonoPor] = useState<boolean>(true);
  const [disabledImporteDescto, setdisabledImporteDescto] = useState<boolean>(true);
  const [disabledImporteDesctoPor, setdisabledImporteDesctoPor] = useState<boolean>(true);
     

  useEffect(() => {
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const setData = () => {
    switch (vl_mode) {
      case 'I':
        let today = new Date();
        if (today.getDate() > 15) {
          let lastDayOfMonth: any = new Date(today.getFullYear(), today.getMonth(), 0);
          setFechaEfectiva1(new Date(today.getFullYear(), today.getMonth(), 15));
          setFechaEfectiva2(new Date(today.getFullYear(), today.getMonth(), lastDayOfMonth.getDate()));
        }
        // pa_get_pago_staff_comisiones();
        break;
      case 'U':
        getDataPagos();
        break;
      default:
        break;
    }
  }

  // obtener data para editar
  const getDataPagos = () => {
    // console.log('getDataPagos');
    pa_get_liquidacion_pagos_detalle(vl_IdLiquidacionPago);
  }

  const pa_get_liquidacion_pagos_detalle = async (in_IdLiquidacionPago: any) => {
    // in VP_IdLiquidacionPago int,
    // in VP_IdUsuario int,
    // in VP_IdSucursal int
    const param = {
      "VP_IdLiquidacionPago": in_IdLiquidacionPago,
      "VP_IdUsuario": localStorage.getItem('authIdUsuario'),
      "VP_IdSucursal": localStorage.getItem('authIdSucursal')
    }
    const response = await fetchSinToken('caja/pa_get_liquidacion_pagos_detalle', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    set_DataPagos(record);
  }

  const set_DataPagos = (record: any) => {
    // console.log(record);    
    if (record[0].length <= 0) {
      console.log('No hay detalle');
      return;
    }
    setIdLiquidacionPago(record[0].IdLiquidacionPago);
    setTipoFecha(record[0].TipoFecha);
    setFechaEfectiva1(new Date(Date.parse(record[0].PeriodoDesde)));
    setFechaEfectiva2(new Date(Date.parse(record[0].PeriodoHasta)));
    setFechaPago(new Date(Date.parse(record[0].FechaPago)));
    setTotalPago(record[0].TotalPago);
    setTipo(record[0].Tipo);
    setEstado(record[0].Estado);
    setObservaciones(record[0].Observaciones);
    //DETALLE PLANILLA
    pa_get_liquidacion_pagos_staff(record[0].IdLiquidacionPago);
  }

  const pa_get_liquidacion_pagos_staff = async (in_IdLiquidacionPago: any) => {
    // in VP_IdLiquidacionPago int,
    // in VP_IdUsuario int,
    // in VP_IdSucursal int
    const param = {
      "VP_IdLiquidacionPago": in_IdLiquidacionPago,
      "VP_IdUsuario": localStorage.getItem('authIdUsuario'),
      "VP_IdSucursal": localStorage.getItem('authIdSucursal')
    }
    const response = await fetchSinToken('caja/pa_get_liquidacion_pagos_staff', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataGridComisionStaff(record);
  }


  const setCamposAdicional=(bol:any)=>{
    setDisabledImporteBono(!bol);
    setDisabledImporteBonoPor(!bol);
    setdisabledImporteDescto(!bol);
    setdisabledImporteDesctoPor(!bol);
    if(!bol){
      setImporteBono(0.0);
      setImporteBonoPor(0);
      setImporteDescto(0.0);
      setImporteDesctoPor(0);
    }        
  }

  const template_btnDetalles = (item: any) => {
    // console.log(item);
    //const vl_IdOrdenPago = item.IdOrdenPago;
    return (
      <>
        <Button icon="pi pi-cog" className="p-button-sm p-button-raised p-button-text p-button-plain"
          onClick={() => handleEventClick_data(item)}
        />
      </>
    );
  }
  const handleEventClick_data = (item: any) => {
    setDataGridDetalleStaff(item);
    handleEventClick();
  }

  const handleEventClick = () => {
    // setMode('P');
    isMounted.current = true;
    openModal();
  }

  const setIsMounted = (val: boolean) => {
    isMounted.current = val;
  }

  const navigate = useNavigate();
  const linkGoBack = () => {
    navigate('/ComisionesView');
  }
  const link_form_close = () => {
    navigate('/WelcomePage');
  }

  const DetallePago = () => {
    return (
      <CajaPagoDetalle
        isOpen={isOpenModal}
        isHide={closeModal}
        isData={dataGridDetalleStaff}
        isCallfn={setData}
        setIsMounted={setIsMounted}
      />
    );
  }

  // C R U D 
  const showDialogMessage = useRef<any>(null);
  const [saveConfirmDialog, setSaveConfirmDialog] = useState<boolean>(false);
  const setVisibleConfirmDialog = () => {
    const IsValidaCampos = onValidarCampos();
    const Isvalido = IsValidaCampos[0];
    const IsMessage = IsValidaCampos[1];
    if (!Isvalido) {
      showDialogMessage.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 8000 });
      return;
    }
    setSaveConfirmDialog(true);
  }
  const handleCancel = () => {
    setSaveConfirmDialog(false);
  }
  const pa_set_liquidacion_pagos = async () => {
    setSaveConfirmDialog(false);    

    let vl_ParametrosPago: any = {
      "ImporteAdicional": {
        "ImporteBono": vl_ImporteBono,
        "ImporteBonoPor": vl_ImporteBonoPor,
        "ImporteDescto": vl_ImporteDescto,
        "ImporteDesctoPor": vl_ImporteDesctoPor
      }
    };               
    let vl_Conceptos: any = [];
    const param = {
      VP_Action: vl_mode,
      VP_IdLiquidacionPago: vl_IdLiquidacionPago,
      VP_FechaPago: setIntlDateTimeFormat(vl_FechaPago),
      VP_Tipo: vl_Tipo, // DEFAULT NULL COMMENT 'D=DIARIO, Q=QUINCENAL, M=MENSUAL, O=OTROS',
      VP_TotalPago: vl_TotalPago,
      VP_TipoFecha: vl_TipoFecha,
      VP_PeriodoDesde: setIntlDateTimeFormat(vl_FechaEfectiva1),
      VP_PeriodoHasta: setIntlDateTimeFormat(vl_FechaEfectiva2),
      VP_Estado: vl_Estado, // CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL COMMENT 'P=PROCESADO, A=ANULADO',
      VP_ParametrosPago: JSON.stringify(vl_ParametrosPago),  //json, -- DEFAULT NULL COMMENT 'Valores adicionales aplicados',
      VP_Observaciones: vl_Observaciones,  //varchar(150), -- CHARACTER SET utf8mb4 COLLATE utf8mb4_0900_ai_ci DEFAULT NULL,       
      VP_IdLiquidacionPagosStaff: 0,
      VP_Conceptos: JSON.stringify(vl_Conceptos),
      VP_IdUsuario: localStorage.getItem('authIdUsuario'),
      VP_IdSucursal: localStorage.getItem('authIdSucursal'),
      VP_ChkPsicologos: vl_ChkPsicologos ? 1 : 0,
      VP_ChkAdministrativo: vl_ChkAdministrativo ? 1 : 0
    };

    console.log(param);
    // return;
    const response = await fetchSinToken('Caja/pa_set_liquidacion_pagos', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    console.log(record);
    if (record) {
      const message = record.sql_message;
      // console.log(message);
      showDialogMessage.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
      if (record.sql_error === 0) {
          setModeCrud('U');
          pa_get_liquidacion_pagos_detalle(record.IdLiquidacionPago);          
      }
    } else {
      const message =
        'Code: ' + resp.data.code +
        'Err: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      // console.log(message);
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
    }

  }
  const onValidarCampos = () => {
    let vl_valida = true;
    let vl_message = '';

    if (!vl_ChkPsicologos && !vl_ChkAdministrativo) {
      vl_valida = false;
      vl_message = 'Marcar Psicologos o Administrativo';
    } else if (vl_FechaEfectiva1 == '' || vl_FechaEfectiva2 == '') {
      vl_valida = false;
      vl_message = 'Seleccionar periodo';
    } else if (vl_Tipo === '') {
      vl_valida = false;
      vl_message = 'Seleccionar tipo de pago';
    } else if (vl_FechaPago === '') {
      vl_valida = false;
      vl_message = 'Seleccionar fecha de pago';
    }else{
      if(vl_SwicthAdicional){
        if( parseFloat(vl_ImporteDescto) === 0 && parseFloat(vl_ImporteDesctoPor) === 0 ){
          if( parseFloat(vl_ImporteBono) === 0 && parseFloat(vl_ImporteBonoPor) === 0 ){
            vl_valida = false;
            vl_message = 'Ingresar importe o % de bono o descuento';
          }        
        } 
        if( parseFloat(vl_ImporteBono) < 0 || parseFloat(vl_ImporteBonoPor) < 0 ){
          vl_valida = false;
          vl_message = 'Importe o % de bono no válido, ingresar valor mayor a 0.00';
        }       
      }
    }

    return [vl_valida, vl_message];
  }

  // A N U L A  
  const [anulaConfirmDialog, setAnulaConfirmDialog] = useState<boolean>(false);
  const setVisibleConfirmDialogAnula = () => {    
    if ( parseFloat(vl_IdLiquidacionPago) === 0 ) {
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail:'Debe generar un registro de pago', life: 8000 });
      return;
    }
    if ( vl_Estado === 'A' ) {
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail:'El pago ya esta anulado!', life: 8000 });
      return;
    }
    if ( vl_Estado === 'P' ) {
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail:'El pago esta cerrado!', life: 8000 });
      return;
    }
    setAnulaConfirmDialog(true);
  }
  const handleCancel2 = () => {
    setAnulaConfirmDialog(false);
  }

  const pa_setAnularPago = async()=>{    
    setAnulaConfirmDialog(false);    
    let vl_ParametrosPago: any = {
      "ImporteAdicional": {
        "ImporteBono": vl_ImporteBono,
        "ImporteBonoPor": vl_ImporteBonoPor,
        "ImporteDescto": vl_ImporteDescto,
        "ImporteDesctoPor": vl_ImporteDesctoPor
      }
    };               
    let vl_Conceptos: any = [];
    const param = {
      VP_Action: 'A',
      VP_IdLiquidacionPago: vl_IdLiquidacionPago,
      VP_FechaPago: setIntlDateTimeFormat(vl_FechaPago),
      VP_Tipo: vl_Tipo, // DEFAULT NULL COMMENT 'D=DIARIO, Q=QUINCENAL, M=MENSUAL, O=OTROS',
      VP_TotalPago: vl_TotalPago, //NO USADO
      VP_TipoFecha: vl_TipoFecha,//NO USADO
      VP_PeriodoDesde: setIntlDateTimeFormat(vl_FechaEfectiva1),//NO USADO
      VP_PeriodoHasta: setIntlDateTimeFormat(vl_FechaEfectiva2),//NO USADO
      VP_Estado: vl_Estado, // COMMENT 'P=PROCESADO, A=ANULADO',
      VP_ParametrosPago: JSON.stringify(vl_ParametrosPago),  //json, -- DEFAULT NULL COMMENT 'Valores adicionales aplicados',
      VP_Observaciones: vl_Observaciones,  //varchar(150)       
      VP_IdLiquidacionPagosStaff: 0,//NO USADO
      VP_Conceptos: JSON.stringify(vl_Conceptos),//NO USADO
      VP_IdUsuario: localStorage.getItem('authIdUsuario'),
      VP_IdSucursal: localStorage.getItem('authIdSucursal'),
      VP_ChkPsicologos: vl_ChkPsicologos ? 1 : 0, //NO USADO
      VP_ChkAdministrativo: vl_ChkAdministrativo ? 1 : 0 //NO USADO
    };

    console.log(param);
    // return;
    const response = await fetchSinToken('Caja/pa_set_liquidacion_pagos', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    // console.log(record);
    if (record) {
      const message = record.sql_message;
      // console.log(message);
      showDialogMessage.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
      if (record.sql_error === 0) {
          linkGoBack();
          // setModeCrud('I'); 
          // pa_get_liquidacion_pagos_detalle(record.IdLiquidacionPago);          
      }
    } else {
      const message =
        'Code: ' + resp.data.code +
        'Err: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      // console.log(message);
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
    }
  }

  // C I E R R E D E PAGO
  const [procesarConfirmDialog, setProcesarConfirmDialog] = useState<boolean>(false);
  const setVisibleConfirmDialogCierrePago = () => {  
      
    if ( parseFloat(vl_IdLiquidacionPago) === 0 ) {
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail:'Debe generar un registro de pago', life: 8000 });
      return;
    }
    if ( vl_Estado === 'A' ) {
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail:'El pago esta anulado!', life: 8000 });
      return;
    }
    if ( vl_Estado === 'P' ) {
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail:'El pago ya esta cerrado!', life: 8000 });
      return;
    }
    setProcesarConfirmDialog(true);
  }
  const handleCancel3 = () => {
    setProcesarConfirmDialog(false);
  }
  const pa_setProcesarCierrePago = async ()=>{
    setProcesarConfirmDialog(false);    
    let vl_ParametrosPago: any = {
      "ImporteAdicional": {
        "ImporteBono": vl_ImporteBono,
        "ImporteBonoPor": vl_ImporteBonoPor,
        "ImporteDescto": vl_ImporteDescto,
        "ImporteDesctoPor": vl_ImporteDesctoPor
      }
    };               
    let vl_Conceptos: any = [];
    const param = {
      VP_Action: 'P',
      VP_IdLiquidacionPago: vl_IdLiquidacionPago,
      VP_FechaPago: setIntlDateTimeFormat(vl_FechaPago),
      VP_Tipo: vl_Tipo, // DEFAULT NULL COMMENT 'D=DIARIO, Q=QUINCENAL, M=MENSUAL, O=OTROS',
      VP_TotalPago: vl_TotalPago, //NO USADO
      VP_TipoFecha: vl_TipoFecha,//NO USADO
      VP_PeriodoDesde: setIntlDateTimeFormat(vl_FechaEfectiva1), 
      VP_PeriodoHasta: setIntlDateTimeFormat(vl_FechaEfectiva2), 
      VP_Estado: vl_Estado, // COMMENT 'P=PROCESADO, A=ANULADO',
      VP_ParametrosPago: JSON.stringify(vl_ParametrosPago),  //json, -- DEFAULT NULL COMMENT 'Valores adicionales aplicados',
      VP_Observaciones: vl_Observaciones,  //varchar(150)       
      VP_IdLiquidacionPagosStaff: 0,//NO USADO
      VP_Conceptos: JSON.stringify(vl_Conceptos),//NO USADO
      VP_IdUsuario: localStorage.getItem('authIdUsuario'),
      VP_IdSucursal: localStorage.getItem('authIdSucursal'),
      VP_ChkPsicologos: vl_ChkPsicologos ? 1 : 0, //NO USADO
      VP_ChkAdministrativo: vl_ChkAdministrativo ? 1 : 0 //NO USADO
    };

    console.log(param);
    // return;
    const response = await fetchSinToken('Caja/pa_set_liquidacion_pagos', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    // console.log(record);
    if (record) {
      const message = record.sql_message;
      // console.log(message);
      showDialogMessage.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
      if (record.sql_error === 0) {
          linkGoBack();
          // setModeCrud('U'); 
          // pa_get_liquidacion_pagos_detalle(record.IdLiquidacionPago);          
      }
    } else {
      const message =
        'Code: ' + resp.data.code +
        'Err: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      // console.log(message);
      showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
    }
  }

  return (
    <>
      <React.Fragment>{isMounted.current && <DetallePago />}</React.Fragment>
      <Toast ref={showDialogMessage} position="bottom-right"></Toast>
      <ConfirmDialog visible={saveConfirmDialog} onHide={() => setSaveConfirmDialog(false)}
        message="¿Aperturar periodo de pago?"
        header="Confirmar!"
        icon="pi pi-exclamation-triangle"
        accept={pa_set_liquidacion_pagos}
        reject={handleCancel}
      />
      <ConfirmDialog visible={anulaConfirmDialog} onHide={() => setAnulaConfirmDialog(false)}
        message="Seguro de anular periodo de pago?"
        header="Confirmar!"
        icon="pi pi-exclamation-triangle"
        accept={pa_setAnularPago}
        reject={handleCancel2}
      />
      <ConfirmDialog visible={procesarConfirmDialog} onHide={() => setProcesarConfirmDialog(false)}
        message="Seguro de cerrar el periodo de pago?"
        header="Confirmar!"
        icon="pi pi-exclamation-triangle"
        accept={pa_setProcesarCierrePago}
        reject={handleCancel3}
      />
      <div className="flex justify-content-between p-2 border-gray-500 pt-1 border-bottom-2 ">
        <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
          <div className="col mt-0 ml-0 text-base font-bold font-italic h-2rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
            <label className='pb-1'> Pago de Honorarios / Comisiones </label>
          </div>
        </div>
        <div>
          <span className="p-buttonset">
            <Button
              label="Cerrar pagos"
              icon="pi pi-unlock"
              onClick={() => setVisibleConfirmDialogCierrePago()}
              className="p-button-sm p-button-primary p-button-raised"
            />
            <Button
              label="Anular"
              icon="pi pi-trash"
              onClick={() => setVisibleConfirmDialogAnula()}
              className="p-button-sm p-button-danger p-button-text"
            />   
            <Button
            icon="pi pi-times"
            onClick={() => link_form_close()}
            title="Cerrar formulario"
            className="p-button-sm p-button-outlined p-button-danger ml-1 "
          // className="p-button-rounded p-button-danger p-button ml-1" aria-label="Cancel"
          />         
          </span>
          
        </div>
      </div>
      <div className='flex justify-content-between pb-2  ' >
        <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-2 pl-2 ">
          <span className="p-buttonset">
            <Button icon="pi pi-arrow-left" label='Volver'
              className="p-button-rounded p-button-warning p-button-raised"
              onClick={() => linkGoBack()}
            />
          </span>
        </div>
      </div>

      <div className="flex align-items-stretch flex-nowrap justify-content-center p-2 ">
        <div className='card p-1 ' >
          <Fieldset legend="Datos de pago">
            <div className="p-field p-grid mt-1">
              <div className="p-col pb-1 ">
                <label className="p-col-fixed  mr-1 font-bold text-xl " style={{ width: '80px', textAlign: 'end' }}>#ID:</label>
                <InputText type="text" style={{ width: '5rem' }} className="font-bold text-primary-800 text-center"
                  value={vl_IdLiquidacionPago} readOnly
                />
                <label className="p-checkbox-label pl-1"  >
                  <Checkbox className="ml-2" onChange={e => setChkPsicologos(e.checked)} checked={vl_ChkPsicologos}> </Checkbox>
                  Psicólogos
                </label>
                <label className="p-checkbox-label pl-1"  >
                  <Checkbox className="ml-2" onChange={e => setChkAdministrativo(e.checked)} checked={vl_ChkAdministrativo}> </Checkbox>
                  Administrativo
                </label>
              </div>

              <div className="p-col pb-3 ml-6 ">
                <RadioButton value="E" name="btnTipofecha"
                  onChange={(e) => setTipoFecha(e.value)}
                  checked={vl_TipoFecha === "E"}
                /><label className="p-col-fixed ml-2 mr-4 font-normal text-sm " style={{ textAlign: 'end' }}>F. Atención</label>
                <RadioButton value="R" name="btnTipofecha"
                  onChange={(e) => setTipoFecha(e.value)}
                  checked={vl_TipoFecha === "R"}
                /><label className="p-col-fixed ml-1 mr-0 font-normal text-sm " style={{ textAlign: 'end' }}>F. Calendario</label>

                <label className="p-col-fixed ml-1 mr-0 font-normal text-sm " style={{ textAlign: 'end' }}> (Para comisiones)</label>
                {/* <Button icon="pi pi-refresh" className="p-button-sm p-button-raised p-button-text p-button-plain" title='Actualizar lista de pagos'
                  onClick={pa_get_pago_staff_comisiones}
                /> */}
              </div>
              <div className="p-col pb-1 ">
                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Desde:</label>
                <Calendar style={{ width: '10rem' }} inputClassName="font-semibold text-primary-800 text-center"
                  value={vl_FechaEfectiva1}
                  onChange={(e: any) => setFechaEfectiva1(e.value)}
                  dateFormat="dd-mm-yy" showIcon={true}
                />
                <label className="p-col-fixed ml-2 mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Al:</label>
                <Calendar style={{ width: '10rem' }} inputClassName="font-semibold text-primary-800 text-center"
                  value={vl_FechaEfectiva2}
                  onChange={(e: any) => setFechaEfectiva2(e.value)}
                  dateFormat="dd-mm-yy" showIcon={true}
                />
              </div>
              <div className="p-col pb-1 ">
                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Tipo pago:</label>
                <Dropdown style={{ width: '12rem' }}
                  value={vl_Tipo}
                  options={[                                      
                    { "IdTipo": "M", "Descripcion": "Mensual" },  
                    { "IdTipo": "Q", "Descripcion": "Quincenal" },                    
                    { "IdTipo": "S", "Descripcion": "Semanal" },
                    { "IdTipo": "D", "Descripcion": "Diario" }
                  ]}
                  onChange={(e: any) => {
                    setTipo(e.value);
                  }}
                  optionValue="IdTipo"
                  optionLabel="Descripcion"
                  placeholder="Seleccionar" />
              </div>

              <div className="p-col pb-1 ">
                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Fecha Pago:</label>
                <Calendar style={{ width: '10rem' }} inputClassName="font-semibold text-primary-800 text-center"
                  value={vl_FechaPago}
                  onChange={(e: any) => setFechaPago(e.value)}
                  dateFormat="dd-mm-yy" showIcon={true}
                />
                <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Total:</label>
                <InputNumber type="text" inputStyle={{ width: '8rem', textAlign: 'right' }} locale="en-US"
                  prefix='S/ '
                  value={vl_TotalPago} readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                  inputClassName={'bg-green-600 text-white font-semibold '}                
                />
              </div>
              <div className="p-col pb-1 ">
                <InputSwitch checked={vl_SwicthAdicional}
                  onChange={(e: any) => {
                    setSwicthAdicional(e.value);
                    setCamposAdicional(e.value);
                    setTimeout(() => {
                      refImporteBono.current?.select();
                    }, 100);
                  }}
                />
                <label className="p-col-fixed ml-2 font-semibold text-sm " style={{ textAlign: 'end' }}>Bono/Extra o descuento (afecta a todos) </label>
              </div>
              <div className="p-col pb-1 ">
                <label className="p-col-fixed  mr-1  font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Importe:</label>                
                <InputNumber type="text" inputStyle={{ width: '6rem', textAlign: 'center' }}
                  value={vl_ImporteBono} mode="decimal" minFractionDigits={2} maxFractionDigits={2}                  
                  inputRef={refImporteBono} locale="en-US"                
                  disabled={disabledImporteBono}                
                  onChange={(e) =>{
                    setImporteBono(Number(e.value));
                    setImporteBonoPor(0);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      // setAplicarDescuentoMontoFijo(Number(e.target.value))
                    }
                  }}
                  onBlur={(e: any) => {
                    // setAplicarDescuentoMontoFijo(Number(e.target.value));
                  }}
                />

                <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}> o' % </label>
                <InputNumber type="text" inputStyle={{ width: '5rem', textAlign: 'center' }}
                  value={vl_ImporteBonoPor}
                  disabled={disabledImporteBonoPor}  
                  min={0} max = {100}  mode="decimal" minFractionDigits={2} maxFractionDigits={2} locale="en-US"           
                  onChange={(e) =>{
                    setImporteBonoPor(Number(e.value));
                    setImporteBono(0.00);
                  }}                
                  onBlur={(e: any) => {
                    // setAplicarDescuentoPje(Number(e.target.value));
                  }}
                />
                <Button icon="pi pi-info-circle" className="p-button-rounded p-button-text p-button-plain" tooltip='% de bono aplica sobre el total de pago de cada profesional' />
              </div>
              <div className="p-col pb-1 ">
                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Descto:</label>
                <InputNumber type="text" inputStyle={{ width: '6rem', textAlign: 'center' }}
                  value={vl_ImporteDescto} inputRef={refImporteDescto}
                  // prefix={'$'}
                  // suffix={'.'}
                  locale="en-US"
                  disabled={disabledImporteDescto}                  
                  onChange={(e) =>{
                    setImporteDescto(Number(e.value));
                    setImporteDesctoPor(0);
                  }}
                  mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      // setAplicarDescuentoMontoFijo(Number(e.target.value))
                    }
                  }}
                  onFocus={(e: any) => {
                    setTimeout(() => {
                      refImporteDescto.current?.select();
                    }, 100);
                  }}
                  onBlur={(e: any) => {
                    // setAplicarDescuentoMontoFijo(Number(e.target.value));
                  }}
                />
                <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>o' %</label>
                <InputNumber type="text"  inputStyle={{ width: '5rem', textAlign: 'center' }}
                  value={vl_ImporteDesctoPor}
                  disabled={disabledImporteDesctoPor}                                    
                  min={0} max = {100} mode="decimal" minFractionDigits={2} maxFractionDigits={2}  locale="en-US"
                  onChange={(e) =>{
                    setImporteDesctoPor(Number(e.value));
                    setImporteDescto(0.00);
                  }}                  
                  onBlur={(e: any) => {
                    // setAplicarDescuentoPje(Number(e.target.value));
                  }}
                />
                <Button icon="pi pi-info-circle" className="p-button-rounded p-button-text p-button-plain" tooltip='% descuento aplica sobre el total pago de cada profesional' />
              </div>
              <div className="p-col pb-1 ">
                <label className="p-col-fixed pb-1 font-bold text-sm " style={{ textAlign: 'end' }}>Observaciones:</label>
                <InputTextarea rows={2} cols={30}
                  style={{ width: '99%' }}
                  value={vl_Observaciones}
                  // ref={inputObservacionesRef}
                  onChange={(e: any) => setObservaciones(e.target.value)}
                />
              </div>
              <div className="p-col pb-1 ">
                <Button icon="pi pi-lock-open" className="p-button-warning"
                  label='Iniciar' disabled={vl_mode === 'I' ? false : true}
                  onClick={() => {
                    setVisibleConfirmDialog();
                  }}
                />
              </div>

            </div>
          </Fieldset>
        </div>
        <div className='card p-1 ml-1 pr-3 ' >
          <div className="flex justify-content-between bg-gray-100 h-2rem pt-1 ">
            <label className="p-col-fixed ml-2 mr-2 font-semibold" style={{ textAlign: 'end' }}>Lista de trabajadores</label>
          </div>
          <DataTable size="small" resizableColumns showGridlines stripedRows scrollable
            responsiveLayout="scroll"
            columnResizeMode="fit"                                    
            value={dataGridComisionStaff}
            scrollHeight="500px"
            // dataKey="CodigoServicio"
            // selectionMode="single"
            // selection={selectedGrid}                        
            // paginator
            // rows={100}
            // rowClassName={rowClassName}
            // isDataSelectable={isRowSelectable}
            // editMode="row"
            // selectionMode="checkbox"
            // onRowEditComplete={props.onRowEditComplete}
            // onRowEditInit={props.onRowEditInit}
            // onRowSelect={props.onRowSelect}
            // onRowEditSave={props.onRowEditSave}
            // onRowUnselect={props.onRowUnselect}
            // selection={dataGridSelected}
            // onSelectionChange={(e) => {              
            //   setDataGridSelected(e.value);
            //   setTotalPagoGrid(e.value);
            // }}
          >
            {/* <Column selectionMode="multiple" style={{ maxWidth: '50px' }} ></Column> */}
            <Column header="Conceptos" bodyClassName="flex justify-content-center" style={{ minWidth: '6rem' }} headerClassName='flex justify-content-center white-space-normal'
              body={template_btnDetalles}
            ></Column>
            <Column field="Staff" header="Nombres y apell." bodyClassName='white-space-normal' filter style={{ minWidth: '250px' }} ></Column>
            {/* <Column field="CantAtencion" header="Cntd." style={{ maxWidth: '70px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-center text-cyan-800 font-semibold" ></Column> */}
            <Column field="TotalPago" header="Total" headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end text-green-600 font-semibold" ></Column>
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default CajaPagoComisionView
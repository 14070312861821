import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useModal from '../../../context/WindowsOpen';
import { fetchSinToken } from '../../../helpers/fetch';
import HeaderForm from '../util/HeaderForm';
import ProgressBarUtil from '../util/ProgressBarUtil';
import PacienteDocumentAdjuntos from './PacienteDocumentAdjuntos';

const PacientePlanEvaluacion = () => {
    const params = useParams();


    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
    const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>('');
    const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>('');
    const [vl_Nombres, setNombres] = useState<any>('');
    const [vl_Edad, setEdad] = useState<any>(0);
    // const [vl_FechaNacimiento, setFechaNacimiento] = useState<any>('');    
    // const [vl_NumeroDocumento, setNumeroDocumento] = useState<any>('');
    // const [vl_Sexo, setSexo] = useState<any>('');

    //util
    const toast = useRef<any>(null);
    const toastConfirm = useRef<any>(null);

    // CRUD
    const [cbmOrdenAtencion, setCbmOrdenAtencion] = useState([]);
    //const [vl_IdOrdenAtencion, setIdOrdenAtencion] = useState<any>(0);
    const [gridDataOrdenAtencionDetalles, setGridDataOrdenAtencionDetalles] = useState<any>([]);
    //PLAN DE EVALUACION 
    const [vl_PlanEvaIdOrdenPago, setPlanEvaIdOrdenPago] = useState<any>(0);    
    const [vl_PlanEvaIdOrdenAtencion, setPlanEvaIdOrdenAtencion] = useState<any>(0);
    const [vl_PlanEvaNumeroOrden, setPlanEvaNumeroOrden] = useState<any>('');
    const [vl_PlanEvaIdPlanpago, setPlanEvaIdPlanpago] = useState<any>(0);
    const [vl_PlanEvaTotalSesion, setPlanEvaTotalSesion] = useState<any>(0);
    const [vl_PlanEvaIdStaff, setPlanEvaIdStaff] = useState<any>(0);
    const [vl_PlanEvaStaff, setPlanEvaStaff] = useState<any>('');
    const [vl_PlanEvaFecha, setPlanEvaFecha] = useState<any>('');
    const [vl_PlanEvaDocAdjuntoPath, setPlanEvaDocAdjuntoPath] = useState<any>('');
    
    // PLAN DE EVALUACION DETALLE
    const [vl_PlanEvaPruebaCodigoServicio, setPlanEvaPruebaCodigoServicio] = useState<any>('');
    const [vl_PlanEvaDescripcionArea, setPlanEvaDescripcionArea] = useState<any>('');    
    const [vl_PlanEvaPruebaPsicologica, setvPlanEvaPruebaPsicologica] = useState<any>('');
    const [vl_PlanEvaResultados, setvPlanEvaResultados] = useState<any>('');
        
    const refPlanEvaPruebaPsicologica = useRef<HTMLElement | HTMLInputElement | any>(null);
    const refPlanEvaResultados = useRef<HTMLElement | HTMLInputElement | any>(null);
    //DOCUMENTOS ADJUNTOS
    const [dataDocumentosAdjuntos, setDataDocumentosAdjuntos] = useState<any>([]);


    useEffect(() => {
        // console.log(params);
        pa_get_paciente(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_paciente = async (in_IdHistoriaClinica: any) => {
        const param = {
            "VP_IdHistoriaClinica": in_IdHistoriaClinica
        }
        const response = await fetchSinToken('selectors/pa_get_paciente', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        if (record) {
            setNombres(record.Nombres);
            setApellidoPaterno(record.ApellidoPaterno);
            setApellidoMaterno(record.ApellidoMaterno);
            setEdad(record.Edad);
            // setFechaNacimiento(new Date(Date.parse(record.FechaNacimiento)));            
            // setNumeroDocumento(record.NumeroDocumento);
            // setSexo(record.Sexo);
            setNumeroHistoriaClinica(record.NumeroHistoriaClinica);
            pa_get_hc_orden_atencion(record.IdCliente);

            setDataDocumentosAdjuntos([]);
            if (record.DocumentosAdjuntos?.PLAN_EVALUCION) {
                // console.log('DocumentosAdjuntos PLAN_EVALUCION');
                setDataDocumentosAdjuntos(record.DocumentosAdjuntos?.PLAN_EVALUCION);
            }
            // console.log(record.DocumentosAdjuntos?.PLAN_EVALUCION);
        }
    }
    const pa_get_hc_orden_atencion = async (IN_IdCliente: any) => {
        const param = {
            "vp_IdCliente": IN_IdCliente,
            "vp_IdSucursal": localStorage.getItem('authIdSucursal'),
            "VP_opcion": 1
        }
        const response = await fetchSinToken('paciente/pa_get_hc_orden_atencion', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setCbmOrdenAtencion(record);
        // Item default selected
        if (record[0]?.IdOrdenPago) {
            setPlanEvaIdOrdenPago(record[0]?.IdOrdenPago);
            setSelectedItemDefault(record[0]);
        }
    }

    const setSelectedItemDefault = (data: any) => {
        // console.log(data);
        // setGridDataOrdenAtencionDetalles(JSON.parse(data.PlanEvaluacion));
        if (data?.PlanEvaluacion) {
            let vl_PlanEvaluacion: any = JSON.parse(data.PlanEvaluacion);
            let vl_DetallePlan: any = [];
            if (vl_PlanEvaluacion.length === 0) {
                setGridDataOrdenAtencionDetalles([]);
                return
            };
            //a Nivel del combo
            setPlanEvaIdOrdenPago(vl_PlanEvaluacion[0].IdOrdenPago);
            setPlanEvaIdOrdenAtencion(vl_PlanEvaluacion[0].IdOrdenAtencion);
            setPlanEvaNumeroOrden(vl_PlanEvaluacion[0].NumeroOrden);
            setPlanEvaIdPlanpago(vl_PlanEvaluacion[0].IdPlanpago);
            setPlanEvaTotalSesion(vl_PlanEvaluacion[0].TotalSesion);
            setPlanEvaIdStaff(vl_PlanEvaluacion[0].IdStaff);
            setPlanEvaStaff(vl_PlanEvaluacion[0].Staff);
            setPlanEvaFecha(vl_PlanEvaluacion[0].Fecha);
            setPlanEvaDocAdjuntoPath(vl_PlanEvaluacion[0].DocAdjuntoPath);
            
            // detalle de sessiones de cada ORDEN DE TERAPIA
            vl_PlanEvaluacion[0].Detalle.forEach(
                function (record: any) {
                    let { Prueba } = record;
                    let { Resultado } = record;                    
                    vl_DetallePlan.push({
                        // 'IdPlanPagoDetalle': record.IdPlanPagoDetalle,
                        'CodigoServicio': record.CodigoServicio,
                        'Descripcion': record.Descripcion,
                        //PRUEBA
                        'PDescripcion': Prueba.PDescripcion,
                        'PFechaRegistro': Prueba.PFechaRegistro,
                        'PUsuarioRegistro': Prueba.PUsuarioRegistro,
                        'PDocAdjuntoURL': Prueba.PDocAdjuntoURL,
                        //RESULTADO
                        'RDescripcion': Resultado.RDescripcion,
                        'RFechaRegistro': Resultado.RFechaRegistro,
                        'RUsuarioRegistro': Resultado.RUsuarioRegistro,
                        'RDocAdjuntoURL': ''//Resultado.RDocAdjuntoURL (NO SE GURDA)
                    });
                }
            );            
            setGridDataOrdenAtencionDetalles(vl_DetallePlan);
            // setgridDataOrdenTerapiaDetalles(JSON.parse(itemsfiltered[0].PlanTerapia[0].Detalle[0].Plan));
        }
    }

    const setSelectedItem = (id: any) => {
        // console.log(id);                                                                
        let itemsfiltered: any = cbmOrdenAtencion.filter(function (element: any) {
            return element.IdOrdenPago === id;
        });
        //console.log(itemsfiltered);
        if (itemsfiltered.length === 0) {
            setGridDataOrdenAtencionDetalles([]);
            setPlanEvaIdOrdenAtencion(0);
            setPlanEvaNumeroOrden('');
            setPlanEvaPruebaCodigoServicio('');
            setPlanEvaDescripcionArea('');
            setvPlanEvaPruebaPsicologica('');
            setvPlanEvaResultados(''); //new            
            return;
        }
        // setGridDataOrdenAtencionDetalles(JSON.parse(itemsfiltered[0].PlanEvaluacion));        
        if (itemsfiltered[0].PlanEvaluacion) {
            let vl_PlanEvaluacion: any = JSON.parse(itemsfiltered[0].PlanEvaluacion);
            let vl_DetallePlan: any = [];
            if (vl_PlanEvaluacion.length === 0) {
                setGridDataOrdenAtencionDetalles([]);
                return
            };
            //a Nivel del combo
            setPlanEvaIdOrdenPago(vl_PlanEvaluacion[0].IdOrdenPago);
            setPlanEvaIdOrdenAtencion(vl_PlanEvaluacion[0].IdOrdenAtencion);
            setPlanEvaNumeroOrden(vl_PlanEvaluacion[0].NumeroOrden);
            setPlanEvaIdPlanpago(vl_PlanEvaluacion[0].IdPlanpago);
            setPlanEvaTotalSesion(vl_PlanEvaluacion[0].TotalSesion);
            setPlanEvaIdStaff(vl_PlanEvaluacion[0].IdStaff);
            setPlanEvaStaff(vl_PlanEvaluacion[0].Staff);
            setPlanEvaFecha(vl_PlanEvaluacion[0].Fecha);
            setPlanEvaDocAdjuntoPath(vl_PlanEvaluacion[0].DocAdjuntoPath);
            
            if (!vl_PlanEvaluacion[0].Detalle) {
                console.log('no hay vl_PlanEvaluacion[0].Detalle');
                return
            };
            // console.log(vl_PlanEvaluacion[0].Detalle);
            // detalle de sessiones de cada ORDEN DE TERAPIA
            vl_PlanEvaluacion[0].Detalle.forEach(
                function (record: any) {
                    let { Prueba } = record;
                    let { Resultado } = record;       
                    // console.log(Resultado);             
                    vl_DetallePlan.push({
                        // 'IdPlanPagoDetalle': record.IdPlanPagoDetalle,
                        'CodigoServicio': record.CodigoServicio,
                        'Descripcion': record.Descripcion,
                        //PRUEBA
                        'PDescripcion': Prueba.PDescripcion,
                        'PFechaRegistro': Prueba.PFechaRegistro,
                        'PUsuarioRegistro': Prueba.PUsuarioRegistro,
                        'PDocAdjuntoURL': Prueba.PDocAdjuntoURL,
                        //RESULTADO
                        'RDescripcion': Resultado.RDescripcion, 
                        'RFechaRegistro': Resultado.RFechaRegistro,
                        'RUsuarioRegistro': Resultado.RUsuarioRegistro,
                        'RDocAdjuntoURL': '' //Resultado.RDocAdjuntoURL (NO SE GURDA)
                    });
                }
            );            
            // console.log(vl_DetallePlan);
            setGridDataOrdenAtencionDetalles(vl_DetallePlan);
            // setgridDataOrdenTerapiaDetalles(JSON.parse(itemsfiltered[0].PlanTerapia[0].Detalle[0].Plan));
        }
    }

    // crud: PLAN DE EVALUACION

    const setPlanEvaluacionAgregar = () => {
        let emptyProduct6 = {           
            CodigoServicio: '',
            Descripcion: '',
            PDescripcion: '',
            RDescripcion: '',
            PDocAdjuntoURL: ''
        };
        
        if (vl_PlanEvaPruebaCodigoServicio === '') {
            toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Seleccionar un registro ', life: 8000 });
            return;
        }
        if (vl_PlanEvaPruebaPsicologica === '') {
            toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Debe ingresar texto ', life: 8000 });
            return;
        }
        emptyProduct6 = {            
            CodigoServicio: vl_PlanEvaPruebaCodigoServicio,
            Descripcion: vl_PlanEvaDescripcionArea,
            PDescripcion: vl_PlanEvaPruebaPsicologica,
            RDescripcion: vl_PlanEvaResultados,
            PDocAdjuntoURL: ''
        }
        // console.log(emptyProduct5);        
        let _products = [...gridDataOrdenAtencionDetalles];
        let _product = { ...emptyProduct6 };
        if (vl_PlanEvaPruebaCodigoServicio !== '') {
            const index = findIndexById6(emptyProduct6.CodigoServicio);
            _products[index] = _product;
        }
        /*else {
            _product.AFuncionalId = createId5();
            _product.AFuncionalAntecedente = emptyProduct5.AFuncionalAntecedente;
            _product.AFuncionalConducta = emptyProduct5.AFuncionalConducta;
            _product.AFuncionalConsecuencia = emptyProduct5.AFuncionalConsecuencia
            _products.push(_product);
        }*/
        setGridDataOrdenAtencionDetalles(_products);
        setPlanEvaluacionNuevo();
    }
    const setPlanEvaluacionNuevo = () => {        
        setPlanEvaPruebaCodigoServicio('');
        setPlanEvaDescripcionArea('');
        setvPlanEvaPruebaPsicologica('');
        setvPlanEvaResultados('');
    }

    const findIndexById6 = (id: any) => {
        let index = -1;
        for (let i = 0; i < gridDataOrdenAtencionDetalles.length; i++) {
            if (gridDataOrdenAtencionDetalles[i].CodigoServicio === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    // const createId6 = () => {
    //     let index = 0;
    //     gridDataOrdenAtencionDetalles.forEach(function (row: any) {
    //         index = parseFloat(row.AFuncionalId);
    //     });
    //     index = index + 1;
    //     return index;
    // }

    const actionBodyTemplate7 = (row: any) => {
        // console.log(row);
        let vl_RatingValue = 0;
        if (row.PDescripcion !== '' && row.PDescripcion) vl_RatingValue = 1;
        if (row.RDescripcion !== '' && row.RDescripcion) vl_RatingValue = vl_RatingValue + 1;
        // console.log(vl_RatingValue);
        return (
            <React.Fragment>
                <Rating value={vl_RatingValue} readOnly stars={2} cancel={false} /> {row.Descripcion}
            </React.Fragment>
        );
    }

    // FIN crud: PLAN DE EVALUACION

    //CRUD MAIN
    const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const [b_ProgressBar, setProgressBar] = useState<boolean>(false);

    const pa_set_historia_clinica_json = async () => {
        // console.log('pa_set_historia_clinica_json');
        setVisibleConfirmDialog(false);

        // const vl_PlanEvaluacion: any = gridDataOrdenAtencionDetalles;
        const vl_PlanEvaluacionDet: any = [];
        gridDataOrdenAtencionDetalles.forEach(
            function (record: any) {
                // if( String(record.Objetivo).trim() !== '' ) QPlanNroSesion++;
                // if( String(record.EDescripcion).trim() !== '' ) QEvolucionNroSesion++;
                vl_PlanEvaluacionDet.push({
                    // 'IdPlanPagoDetalle': record.IdPlanPagoDetalle,
                    'CodigoServicio': record.CodigoServicio,
                    'Descripcion': record.Descripcion,                    
                    'Prueba': {
                        'PFechaRegistro': record.PDescripcion?new Date():'',
                        'PUsuarioRegistro': record.PDescripcion?localStorage.getItem('authUserName'):'',
                        'PDescripcion': record.PDescripcion,
                        'PDocAdjuntoURL': record.PDocAdjuntoURL,
                    },
                    'Resultado': {
                        'RFechaRegistro': record.RDescripcion?new Date():'',
                        'RUsuarioRegistro': record.RDescripcion?localStorage.getItem('authUserName'):'',
                        'RDescripcion': record.RDescripcion,
                        'RDocAdjuntoURL': record.RDocAdjuntoURL
                    }
                });
            }
        );
        let VL_json: any = {
            "IdOrdenPago": vl_PlanEvaIdOrdenPago,
            "IdOrdenAtencion":vl_PlanEvaIdOrdenAtencion,
            "NumeroOrden": vl_PlanEvaNumeroOrden,
            "IdPlanpago": vl_PlanEvaIdPlanpago,
            "TotalSesion": vl_PlanEvaTotalSesion,  
            "IdStaff": vl_PlanEvaIdStaff,
            "Staff": vl_PlanEvaStaff,
            "Fecha": vl_PlanEvaFecha,  
            "DocAdjuntoPath":vl_PlanEvaDocAdjuntoPath,
            "Detalle": vl_PlanEvaluacionDet
        };
        

        const param = {
            VP_ACTION: 'PEV',
            VP_IdHistoriaClinica: params.id,
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_json: JSON.stringify(VL_json)
        }
        console.log(param);
        // return;

        setProgressBar(true);
        const response = await fetchSinToken('paciente/pa_set_historia_clinica_json', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];

        console.log('RESPONSE:');
        console.log(record);
        if (record) {
            const message = record.sql_message;
            toast.current.show({ severity: 'success', summary: '::PSISABE', detail: message, life: 8000 });
            //recargar informacion
            // if (record.sql_error === 0) {
            //     getHistoriaClinica(vl_NumeroHistoriaClinica);
            // }

        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
        }
        setProgressBar(false);
    }
    const onRejectAction = () => {
        setVisibleConfirmDialog(false);
    }
    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';

        // if (Number(vl_IdCliente) === 0 || (!Number(vl_IdCliente))) {
        //     vl_valida = false;
        //     vl_message = 'Debe buscar un paciente';
        // }
        return [vl_valida, vl_message];
    }

    const setConfirmarGrabarHistoriaClinicaJson = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toastConfirm.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 4000 });
            return;
        }
        // si no hay campos invalidos procesar...    
        setVisibleConfirmDialog(true);
    }
    //FIN CRUD MAIN
    const navigate = useNavigate();
    const link_formHC = () => {
        // vl_activeIndex = 4 > PLAN EVALUACION
        navigate('/HistoriaClinicaView/' + vl_NumeroHistoriaClinica + '/4');
    }
    const link_form_close = () => {
        navigate('/WelcomePage');
    }

    //open modal (view documents)
    const [isOpenModal01, openModal01, closeModal01] = useModal();
    const isMounted01 = useRef(false);
    const setIsMounted01 = (val: boolean) => {
        isMounted01.current = val;
    }
    const handleClickUploadFiles = (e: any) => {
        // if( parseInt(vl_IdCliente) === 0 ){
        //     messagesHistory.current.show([                                                                                                              
        //         { severity: 'warn', detail: 'No se puede mostrar agenda, busque un paciente primero', life: 10000 }
        //     ]);
        //     return;
        // } 
        isMounted01.current = true;
        openModal01();
    }
    const PacienteDocumentAdjuntosForm = () => {
        return (
            <PacienteDocumentAdjuntos
                isOpen={isOpenModal01}
                isHide={closeModal01}
                setIsMounted={setIsMounted01}
                IdHistoriaClinica={params.id}
                TitleFormulario={'PLAN DE EVALUACION'}
                NumeroOrden={vl_PlanEvaNumeroOrden}
                ConfigS3={{
                    bucket: dataDocumentosAdjuntos[0]?.DocumentBucket ? dataDocumentosAdjuntos[0].DocumentBucket : 'psisabe.bucket.files',
                    folder: dataDocumentosAdjuntos[0]?.DocumentFolder ? dataDocumentosAdjuntos[0].DocumentFolder : 'HISTORIA_CLINICA',
                    subfolder: dataDocumentosAdjuntos[0]?.DocumentSubFolder ? dataDocumentosAdjuntos[0].DocumentSubFolder : vl_NumeroHistoriaClinica,
                    tipofolder: dataDocumentosAdjuntos[0]?.DocumentTipoFolder ? dataDocumentosAdjuntos[0].DocumentTipoFolder : 'PLAN_EVALUACION',
                    folderCreated: dataDocumentosAdjuntos[0]?.DocumentTipoFolder ? 'S' : 'N'
                }
                }
            />
        );
    }

    return (
        <>
            <Toast ref={toast} position="bottom-right"></Toast>
            <ProgressBarUtil
                ProgressBarVisible={b_ProgressBar}
                ProgressBarMessage='Please wait, loading...'
                setProgressBar={setProgressBar}
            />
            <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
                message="¿Guardar registro?"
                header="Confirmar para continuar.."
                icon="pi pi-exclamation-triangle"
                accept={pa_set_historia_clinica_json}
                reject={onRejectAction}
            />
            <React.Fragment>{isMounted01.current && <PacienteDocumentAdjuntosForm />}</React.Fragment>

            <HeaderForm
                TitleForm={'PLAN DE EVALUACIÓN.'}
                Paciente={vl_ApellidoPaterno + ' ' + vl_ApellidoMaterno + ', ' + vl_Nombres}
                NumeroHistoriaClinica={vl_NumeroHistoriaClinica}
                Edad={vl_Edad}
                linkGoBack={link_formHC}
                link_form_close={link_form_close}
                setOnClickButtonSave={setConfirmarGrabarHistoriaClinicaJson}
            ></HeaderForm>

            <ScrollPanel style={{ height: '600px' }}>
                {/* Conten */}
                <div className='pl-3 pr-3 bg-white text-gray-800' >
                    <div className="p-field p-grid mt-1 pb-4">
                        <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                            <p className="font-italic border-round border-0 p-1"> <li>Registro de pruebas psicológicas y resultados</li></p>
                        </div>
                        {/* <div className="p-col pb-2"> */}
                        <div className='flex justify-content-between pb-2 pt-2' >
                            <div>
                                <label className="p-col-fixed mr-1 font-semibold p-1" style={{ width: '100%' }}>Orden de atención:</label>
                                <Dropdown style={{ width: '100%' }} filter filterBy="Descripcion" showClear
                                    value={vl_PlanEvaIdOrdenPago}
                                    options={cbmOrdenAtencion}
                                    onChange={(e: any) => {
                                        setPlanEvaIdOrdenPago(e.value);
                                        setSelectedItem(e.value);

                                    }}
                                    optionValue="IdOrdenPago"
                                    optionLabel="Detalle"
                                    placeholder="Seleccionar" />
                            </div>
                            <div className='flex justify-content-end align-items-center ' >
                                <label className="p-col-fixed mr-1 ml-2 text-sm font-semibold pb-2 " style={{ textAlign: 'end' }}>Documentos adjuntos:</label>
                                <Button icon="pi pi-folder" label='Abrir' className="p-button-small p-button-warning"
                                    onClick={(e: any) => { handleClickUploadFiles(e) }}
                                />
                            </div>
                        </div>


                        <div className='p-col w-full p-1 border-bottom-2 border-gray-500 surface-overlay '>
                            <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                <p className="font-italic border-round border-0 p-1"> <li>Seleccionar un registro para agregar las pruebas y resultados</li></p>
                            </div>
                            <DataTable size="small"
                                resizableColumns
                                style={{ minWidth: '500px' }} //maxWidth:'700px'
                                value={gridDataOrdenAtencionDetalles ?? []}
                                dataKey="CodigoServicio"
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                scrollHeight="220px"
                                selectionMode="single"
                                //editMode="row"
                                // onRowEditComplete={onRowEditComplete3}
                                // selection={gridDataEvolucionSelected}
                                onSelectionChange={
                                    (e) => {
                                        // console.log(e);
                                        // setPlanEvaIdOrdenAtencion(vl_PlanEvaIdOrdenAtencion);
                                        // setPlanEvaNumeroOrden(e.value.NumeroOrden);
                                        setPlanEvaPruebaCodigoServicio(e.value.CodigoServicio);
                                        setPlanEvaDescripcionArea(e.value.Descripcion);
                                        setvPlanEvaPruebaPsicologica(e.value?.PDescripcion);
                                        setvPlanEvaResultados(e.value?.RDescripcion);
                                        refPlanEvaPruebaPsicologica.current.focus();
                                    }
                                }
                            >
                                <Column field="Descripcion" header="Área de evaluación" body={actionBodyTemplate7}></Column>
                                <Column field="PDescripcion" header="Pruebas" bodyClassName='white-space-normal'  ></Column>
                                <Column field="RDescripcion" header="Resultados" bodyClassName='white-space-pre' style={{ maxWidth: '22rem' }} ></Column>
                            </DataTable>

                        </div>
                        <div className='pt-3 ' >
                            {/* <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                <p className="font-italic border-round border-0 p-1"> <li> Ingresar datos, luego boton Actualizar, finalmente Grabar para confirmar todo</li></p>
                            </div> */}
                            <div className='p-col p-1 w-full ' >
                                <div className='pb-2' >
                                    <h4 className='font-semibold text-primary-800 ' >{vl_PlanEvaDescripcionArea}</h4>
                                    {/* <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 " style={{ width: '100%' }}>Área de evaluación.</label> */}                                    
                                    {/* <InputText type="text" style={{ width: '99%', textAlign: 'left' }}
                                        value={vl_PlanEvaDescripcionArea} disabled className='font-semibold text-primary-800'
                                        onChange={(e: any) => {
                                            setPlanEvaDescripcionArea(String(e.currentTarget.value));
                                        }}
                                    /> */}
                                </div>
                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 " style={{ width: '100%' }}>Prueba psicológica:</label>
                                <InputTextarea rows={6} style={{ width: '99%' }} className="border-green-700"
                                    value={vl_PlanEvaPruebaPsicologica} ref={refPlanEvaPruebaPsicologica}
                                    onChange={(e: any) => {
                                        setvPlanEvaPruebaPsicologica(String(e.currentTarget.value));
                                    }}
                                />
                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 " style={{ width: '100%' }}>Resultados:</label>
                                <InputTextarea rows={8} style={{ width: '99%' }} className="border-green-700"
                                    value={vl_PlanEvaResultados} ref={refPlanEvaResultados}
                                    onChange={(e: any) => {
                                        setvPlanEvaResultados(String(e.currentTarget.value));
                                    }}
                                />
                                <div className="p-col pt-1" >
                                    <Button type="button" icon="pi pi-check" label='agregar'
                                        onClick={(e) => setPlanEvaluacionAgregar()}
                                        className="p-button-success mr-1"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ScrollPanel>
            {/* footer */}
            {/* <div className='flex justify-content-between pb-2 pt-2' >
                <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-1">
                </div>
                <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-1 ">
                    <Button icon="pi pi-save" label='Grabar' className="p-button-sm p-button p-button-raised" onClick={() => setConfirmarGrabarHistoriaClinicaJson()} />
                    <Button icon="pi pi-times" label='Cerrar' className="p-button-sm p-button-danger ml-2"
                        onClick={() => link_form_close()}
                    />
                </div>
            </div> */}
        </>
    )
}

export default PacientePlanEvaluacion;
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { OrdenPagos02 } from './ordenPago/OrdenPagos02';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { PlanPagos } from './ordenPago/PlanPagos';
import { fetchSinToken } from '../../../helpers/fetch';
import { InputSwitch } from 'primereact/inputswitch';
import { setIntlDateTimeFormat } from '../../../helpers/functions';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { DetalleOrdenPago } from './ordenPago/DetalleOrdenPago';
import { OverlayPanel } from 'primereact/overlaypanel';
import TitleForm from '../util/TitleForm';

const ProcesarPago = ({ ...props }) => {

  //console.log(props);

  const [vl_isMode, setIsMode] = useState<any>('I');
  const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
  const [dataPlanPagos, setDataPlanPagos] = useState<any>([]);
  const [dataIdFormaPago, setIdDataFormaPago] = useState([]);
  const [dataIdBancos, setIdDataBancos] = useState([]);
  const [dataCuentaIngreso, setCuentaIngreso] = useState([]);

  // const [dataOrdenPago, setDataOrdenPago] = useState<any>([]);
  const [dataSelectedCuotas, setSelectedCuotas] = useState<any>([]);
  const [detalleOrdenPago, setDetalleOrdenPago] = useState<any>([]);


  //inputs

  const [vl_IdCliente, setIdCliente] = useState<any>('0');
  const [vl_IdOrdenPago, setIdOrdenPago] = useState<any>('0');
  const [vl_FechaPago, setFechaPago] = useState<any>(new Date());
  const [vl_IdFormaPago, setIdFormaPago] = useState<any>('');
  const [vl_ReferenciaPago, setReferenciaPago] = useState<any>('');
  const [VL_IdBanco, setIdBanco] = useState<any>('');
  const [VL_RefNumFacturaRecibo, setRefNumFacturaRecibo] = useState<any>('');
  const [VL_IdCuentaIngreso, setIdCuentaIngreso] = useState<any>('');

  const [vl_TotalPago, setTotalPago] = useState<any>('0.00');
  const [vl_Nota, setNota] = useState<any>('');

  const [vl_Cuota, setInputValCuota] = useState<boolean>(false);

  //Messages
  const toast = useRef<any>(null);
  const toastConfirm = useRef<any>(null);

  //overlay
  const o_detalleOrdenPago = useRef<OverlayPanel>(null);

  useEffect(() => {
    // console.log(props.isMode);
    setIsMode(props.isMode);
    getDataOP();
    pa_get_plan_pagos(props.isData[0]?.IdPlanPago); //revisar
    pa_get_forma_pago();
    pa_get_sys_config('Bancos');
    pa_get_sys_config('CuentaIngreso');
    setCalculaTotalPago();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseShowConfirmSave = () => {
    setVisibleConfirmDialog(false);
  }
  const onIdFormaPagoChange = (e: { value: any }) => {
    setIdFormaPago(e.value);
  }

  const pa_get_forma_pago = async () => {
    const param = {
      "vp_IdFormaPago": "",
      "vp_estado": ""
    }
    const response = await fetchSinToken('selectors/pa_get_forma_pago', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setIdDataFormaPago(record);
  }

  const pa_get_sys_config = async (p_Identificador: any) => {
    const param = {
      "VP_Identificador": p_Identificador,
      "VP_Estado": "A"
    }
    const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    // console.log(record[0].RecordSet);
    switch (p_Identificador) {
      case 'Bancos':
        setIdDataBancos(record[0].RecordSet);
        break;
      case 'CuentaIngreso':
        setCuentaIngreso(record[0].RecordSet);
        break;

      default:
        break;
    }

  }
  const pa_get_plan_pagos = async (IdPlanPago: any) => {
    // console.log('IdPlanPago:'+ IdPlanPago );        
    if (Number(IdPlanPago) > 0) {
      const param = {
        "vp_IdPlanPago": IdPlanPago
      }
      const response = await fetchSinToken('admision/pa_get_plan_pagos', param, 'POST');
      const resp = await response.json();
      const record = resp.data;
      setDataPlanPagos(record);
    }
  }

  const pa_get_orden_pago_detalle = async (p_IdOrdenPago: any, e: any) => {

    if (Number(p_IdOrdenPago) > 0) {
      const param = {
        "IdOrdenPago": p_IdOrdenPago
      }
      const response = await fetchSinToken('admision/pa_get_orden_pago_detalle', param, 'POST');
      const resp = await response.json();
      const record = resp.data;
      // console.log(record);
      setDetalleOrdenPago(record);
      o_detalleOrdenPago.current?.toggle(e);
    }
  }

  const fn_setSelectedCuotas = async (data: any) => {
    // console.log('setSelectedCuotas');
    // console.log(data);
    setSelectedCuotas(data);
    if (data.length > 0) {
      setCalculaTotalPagoCuotas(data);
      setInputValCuota(true);
    } else {
      setCalculaTotalPago();
      setInputValCuota(false);
    }


  }

  //desde detalle
  const setCalculaTotalPagoCuotas = (p_data: any) => {
    let TotalPago = 0;
    // console.log(p_data);
    p_data.forEach(
      function (currentValue: any) {
        //TotalPago = TotalPago + parseFloat(currentValue.Saldop);
        // console.log(currentValue.Amortizacion);
        // TotalPago = TotalPago + parseFloat(currentValue.Amortizacion); // old 
        if (currentValue.Amortizacion)
          TotalPago = TotalPago + parseFloat(currentValue.Amortizacion);
        else TotalPago = TotalPago + parseFloat(currentValue.Saldop);

      }
    );
    setTotalPago(TotalPago);
  }


  //para calcular total pago de todas las cuotas(no aplica cuando es parcial)
  // Saldo = Desde el total de orden o plan de pagos
  const setCalculaTotalPago = () => {
    // console.log('setCalculaTotalPago');
    // console.log(props.isData);
    let TotalPago = 0;
    props.isData.forEach(
      function (currentValue: any) {
        // TotalPago = TotalPago + parseFloat(currentValue.Saldo);
        // new: primero calcular con el importe de amortizacion (si hubiera)
        if (currentValue.Amortizacion)
          TotalPago = TotalPago + parseFloat(currentValue.Amortizacion);
        else TotalPago = TotalPago + parseFloat(currentValue.Saldo);
      }
    );
    setTotalPago(TotalPago);
  }

  const onChangeSwitchCuota = (e: any) => {
    // console.log('ssss')
    const isCuota: boolean = e.value;
    setInputValCuota(isCuota);
    if (isCuota) {
      setTotalPago(0.00);
    } else {
      setCalculaTotalPago();
      setSelectedCuotas([]); //new 
    }
  }

  const importePagoEditor = (options: any) => {
    // console.log(options.value);
    return <InputNumber type="text" inputStyle={{ width: '70px', textAlign: 'end' }}
      value={options.value} mode="decimal" minFractionDigits={2} maxFractionDigits={2}
      onValueChange={(e) => options.editorCallback(e.value)}
      inputClassName='bg-green-500 font-bold text-gray-900 border-round '
    />
  }

  const onRowEditInit = (e: any) => {
    // console.log('onRowEditInit')
    // console.log(e);
    let vl_Amortizacion = parseFloat(e.data.Saldop);
    e.data.Amortizacion = vl_Amortizacion.toFixed(2);
    // e.data.Saldop = parseFloat(e.data.Saldop) - vl_Amortizacion;
  }

  const onRowSelect = (e: any) => {
    // console.log('onRowSelect')
    // console.log(e);    
    let vl_Amortizacion = parseFloat(e.data.Importe) - parseFloat(e.data.Pago) //parseFloat(e.data.Saldop);
    e.data.Amortizacion = vl_Amortizacion;
    // e.data.Saldop = parseFloat(e.data.Saldop)-vl_Amortizacion;
  }

  const onRowEditSave = (e: any) => {
    // console.log('onRowEditSave');
    // console.log(e);
    // e.data.Pago = parseFloat(e.data.Importe);
    // e.data.Saldop = 0;
  }
  const onRowUnselect = (e: any) => {
    // console.log('onRowUnselect');
    // console.log(e);    
    e.data.Amortizacion = 0;
    // e.data.Saldop = parseFloat(e.data.Importe)-parseFloat(e.data.Pago);
  }


  const onRowEditComplete = (e: any) => {

    // console.log('onRowEditComplete');

    if (dataSelectedCuotas.length === 0) {
      let vl_message = 'Para modificar debe seleccionar la cuota';
      toastConfirm.current.show({ severity: 'info', summary: '::PSISABE', detail: vl_message, life: 8000 });
      return;
    }

    let editTarget = [...dataPlanPagos];
    let { newData, index } = e;

    if (parseFloat(newData.Amortizacion) > parseFloat(newData.Saldop)) {
      let vl_message = 'El valor ingresado es mayor al saldo';
      toastConfirm.current.show({ severity: 'info', summary: '::PSISABE', detail: vl_message, life: 8000 });
      return;
    }
    // new
    if (parseFloat(newData.Amortizacion) <= 0) {
      let vl_message = 'El valor ingresado debe ser mayor a 0.00';
      toastConfirm.current.show({ severity: 'info', summary: '::PSISABE', detail: vl_message, life: 8000 });
      return;
    }

    newData.Amortizacion = parseFloat(newData.Amortizacion);
    editTarget[index] = newData;
    setDataPlanPagos([...editTarget]);
    // edit data set Selected rows
    // setEditTargetSelectedCuotas(e);

    // RECALCULAR TOTAL DE PAGO *new
    setOnRowEditCompleteTotalPago(editTarget);
  }

  // RECACULAR PAGO CON EL IMPORTE AMORTIZADO *new
  const setOnRowEditCompleteTotalPago = (peditTarget: any) => {
    // console.log(peditTarget);
    let TotalPago = 0;
    peditTarget.forEach(
      function (currentValue: any) {
        if (currentValue.Amortizacion)
          TotalPago = TotalPago + parseFloat(currentValue.Amortizacion);
      }
    );
    setTotalPago(TotalPago);
  }

  // const setEditTargetSelectedCuotas = (e: any) => {
  //   let editTarget2 = [...dataSelectedCuotas];
  //   let { newData, index } = e;
  //   // let match = dataSelectedCuotas.filter(function (element: any) {
  //   //   return element.IdPlanPagoDetalle == newData.IdPlanPagoDetalle ? false : true;
  //   // });
  //   editTarget2[index] = newData;    
  //   setSelectedCuotas([...editTarget2]);    
  // }


  // FUNCIONA , PERO SE DESACTIVO A PEDIDO
  // const pa_get_recibo_pago_rpte = async (vp_IdPago: any) => {
  //   // const config = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
  //   const endpoint = 'reports/reciboPago/?IdPago=' + vp_IdPago;
  //   const baseUrl = process.env.REACT_APP_API_URL;
  //   const url = `${baseUrl}/${endpoint}`;
  //   window.open(url);
  // }


  const getDataOP = () => {
    // console.log('fn:=>getDataOP0');
    // console.log(props.isData);
    const IdOrdenPago = props.isData[0]?.IdOrdenPago;
    setIdCliente(props.isData[0]?.IdCliente);
    setIdOrdenPago(IdOrdenPago);
  }

  const onValidarCampos = () => {
    let vl_valida = true;
    let vl_message = '';

    if (Number(vl_TotalPago) === 0 || (!Number(vl_TotalPago))) {
      vl_valida = false;
      vl_message = 'Total a pagar debe ser mayor a 0';
    } else if (String(vl_FechaPago).trim() === '') {
      vl_valida = false;
      vl_message = 'Ingresar fecha de Pago';
    } else if (vl_IdFormaPago === '') {
      vl_valida = false;
      vl_message = 'Seleccionar una forma de Pago';
    }

    // si es pago x cuotas
    if (vl_Cuota) {
      // console.log(dataSelectedCuotas.length);
      if (dataSelectedCuotas.length === 0) {
        vl_valida = false;
        vl_message = 'Seleccionar cuotas a pagar';
      }

      let flag: any = 0;
      dataSelectedCuotas.forEach(
        function (currentValue: any) {
          // campo: "Amortizacion" se crea cuando se inserta datos en col: "Pagar" 
          if (!currentValue.Amortizacion) flag = 1
        }
      );
      if (flag === 1) {
        vl_valida = false;
        vl_message = 'Ingresar el importe a "Pagar" en cada cuota';
      }

    }

    return [vl_valida, vl_message];
  }

  const setProcesar = () => {
    const IsValidaCampos = onValidarCampos();
    const Isvalido = IsValidaCampos[0];
    const IsMessage = IsValidaCampos[1];
    if (!Isvalido) {
      toastConfirm.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 8000 });
      return;
    }
    // si no hay campos invalidos procesar...    
    setVisibleConfirmDialog(true);
  }

  const onProcesarPago = async () => {

    // console.log('dataSelectedCuotas');
    // console.log(dataSelectedCuotas);
    // console.log('dataPlanPagos');
    // console.log(dataPlanPagos);

    // PAGO X CUOTAS
    let array: any = [];
    if (vl_Cuota && dataSelectedCuotas.length > 0) {
      dataPlanPagos.forEach(
        function (currentValue: any) {
          if (currentValue.Amortizacion)
            array.push(currentValue);
        }
      );
    }
    // SI NO PAGA X CUOTAS CANCELA TODO    
    if (!vl_Cuota && dataSelectedCuotas.length === 0) {
      array = [];
      dataPlanPagos.forEach(
        function (currentValue: any) {
          array.push(currentValue);
        }
      );
    }

    // console.log('array');
    // console.log(array);
    // return;

    setVisibleConfirmDialog(false);
    const param = {
      VP_ACTION: vl_isMode,
      VP_IdCliente: vl_IdCliente,
      VP_IdOrdenPago: vl_IdOrdenPago,
      VP_FechaPago: setIntlDateTimeFormat(vl_FechaPago),
      VP_HoraPago: '00:00:00',
      VP_IdFormaPago: vl_IdFormaPago,
      VP_ReferenciaPago: vl_ReferenciaPago,
      VP_IdBanco: VL_IdBanco ? VL_IdBanco : '',
      VP_RefNumFacturaRecibo: VL_RefNumFacturaRecibo,
      VP_CuentaIngreso: VL_IdCuentaIngreso ? VL_IdCuentaIngreso : '',
      VP_MontoPagado: vl_TotalPago,
      // VP_CuotasPagarJson: JSON.stringify(dataSelectedCuotas),
      VP_PagoxCuota: vl_Cuota ? 'S' : 'N',
      VP_CuotasPagarJson: JSON.stringify(array),
      vp_nota: vl_Nota,
      vp_IdUsuario: localStorage.getItem('authIdUsuario'),
      vp_IdSucursal: localStorage.getItem('authIdSucursal')
    };

    // console.log(param);
    // return;

    const response = await fetchSinToken('caja/pa_set_procesar_pagos', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    if (record) {
      //console.log(record);
      const message = record.sql_message;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
      //eventos de la pantalla anterior
      if (record.sql_error === 0) {
        // pa_get_recibo_pago_rpte(record.IdPago); //no impimir z default
        props.setIsMounted(false);
        props.isHide();
        props.pa_get_orden_pago();
      }

    } else {
      const message =
        'Code: ' + resp.data.code + '<br>' +
        'Errno: ' + resp.data.errno + '<br>' +
        'Sql: ' + resp.data.sql + '<br>' +
        'SqlState: ' + resp.data.sqlState + '<br>' +
        'SqlMessage: ' + resp.data.sqlMessage + '<br>';
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 3000 });
    }
    // console.log(resp)
  }

  const renderFooter = () => {
    return (
      <>
        <Button label="Procesar" icon="pi pi-save" onClick={() => setProcesar()} />
        <Button label="Cerrar" icon="pi pi-times" className="p-button-rounded p-button-danger  ml-2" onClick={() => {
          props.setIsMounted(false);
          props.isHide();
        }} />
      </>
    );
  }
  const renderHeader = () => {
    return (
      <>
        <TitleForm title={'Registrar Pago'} />
      </>
    );
  }

  return (
    <>
      <Dialog
        header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
        visible={props.isOpen}
        footer={renderFooter}
        onHide={() => {
          props.setIsMounted(false);
          props.isHide();
        }}
      >

        <div className="p-field p-grid mb-1">
          <div className="p-col">
            <InputText type="hidden" value={vl_IdCliente} />
            <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Cliente:</label>
            <InputText type="text" style={{ height: '35px', width: '350px' }} readOnly className='border-cyan-500'
              value={props.isData[0]?.Cliente}
            />
            <label htmlFor="" className="p-col-fixed mr-1 pl-2 font-bold " style={{ textAlign: 'end' }}>H.C:</label>
            <InputText type="text" style={{ height: '35px', width: '110px' }} readOnly className='border-cyan-500'
              value={props.isData[0]?.NumeroHistoriaClinica}
            />
            <label htmlFor="" className="p-col-fixed mr-1 pl-2 font-bold " style={{ textAlign: 'end' }}>Doc.</label>
            <InputText type="text" style={{ height: '35px', width: '150px' }} readOnly className='border-cyan-500'
              value={props.isData[0]?.tipo_doc + '-' + props.isData[0]?.NumeroDocumento}
            />
          </div>
        </div>
        <div className="p-field p-grid mb-1">
          <OrdenPagos02
            dataOrdenPago={props.isData}
            pa_get_orden_pago_detalle={pa_get_orden_pago_detalle}
          />
        </div>

        <div className="flex align-items-stretch flex-nowrap justify-content-center p-2 ">
          <div className='card p-1 mr-1'>
            <PlanPagos
              dataPlanPagos={dataPlanPagos}
              setSelectedCuotas={fn_setSelectedCuotas}
              dataSelectedCuotas={dataSelectedCuotas}
              importePagoEditor={importePagoEditor}
              onRowEditInit={onRowEditInit}
              onRowEditComplete={onRowEditComplete}
              onRowSelect={onRowSelect}
              onRowEditSave={onRowEditSave}
              onRowUnselect={onRowUnselect}
            />
          </div>
          <div className='card p-1'>
            <div className="p-field p-grid pl-1 pr-1 pt-1 pb-1">
              <div className="p-col mb-1 " >
                <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Total Pago:</label>
                <InputNumber type="text" inputClassName='bg-yellow-500 font-bold text-gray-900 border-round ' locale='en-US'
                  inputStyle={{ width: '6rem', textAlign: 'right' }} value={vl_TotalPago}
                  readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                />
                <label title='Pagar por cuotas' className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Cuota</label>
                <InputSwitch checked={vl_Cuota} title='Pagar por cuotas'
                  onChange={(e: any) => onChangeSwitchCuota(e)}
                />
              </div>
              <div className="p-col mb-1 " >
                <label htmlFor="" className="p-col-fixed mr-1 pb-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Fecha Pago:</label>
                <Calendar value={vl_FechaPago} style={{ width: '10rem' }}
                  onChange={(e: any) => setFechaPago(e.value)}
                  maxDate={new Date()} showIcon
                  dateFormat="yy/mm/dd"
                />
              </div>
              <div className="p-col mb-1 " >
                <label htmlFor="" className="p-col-fixed mr-1 pb-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Forma Pago:</label>
                <Dropdown style={{ width: '12rem' }}
                  value={vl_IdFormaPago}
                  options={dataIdFormaPago}
                  onChange={onIdFormaPagoChange}
                  optionValue="IdFormaPago"
                  optionLabel="Descripcion"
                  placeholder="Seleccionar" />
              </div>
              <div className="p-col">
                <label htmlFor="" className="p-col-fixed mr-1 pb-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Nº Factura/Recibo:</label>
                <InputText type="text" style={{ width: '12rem', textAlign: 'left' }}
                  value={VL_RefNumFacturaRecibo}
                  onChange={(e) => setRefNumFacturaRecibo(String(e.currentTarget.value))}
                />
              </div>
              <div className="p-col">
                <label htmlFor="" className="p-col-fixed mr-1 pb-1 font-bold " style={{ textAlign: 'end' }}>Referencia Pago</label>
                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                  value={vl_ReferenciaPago}
                  onChange={(e) => setReferenciaPago(String(e.currentTarget.value))}
                />
              </div>
              <div className="p-col mb-1 mt-1 " >
                <label htmlFor="" className="p-col-fixed mr-1 pb-1 font-bold " style={{ width: '90px', textAlign: 'end' }}>Banco:</label>
                <Dropdown style={{ width: '14rem' }} showClear
                  value={VL_IdBanco}
                  options={dataIdBancos}
                  onChange={(e: any) => {
                    setIdBanco(e.value)
                  }}
                  optionValue="Codigo"
                  optionLabel="Descripcion"
                  placeholder="Seleccionar" />
              </div>
              <div className="p-col">
                <label htmlFor="" className="p-col-fixed mr-1 pb-1 font-bold " style={{ width: '90px', textAlign: 'end' }}>Ingreso a:</label>
                <Dropdown style={{ width: '14rem' }} showClear
                  value={VL_IdCuentaIngreso}
                  options={dataCuentaIngreso}
                  onChange={(e: any) => {
                    setIdCuentaIngreso(e.value)
                  }}
                  optionValue="Codigo"
                  optionLabel="Descripcion"
                  placeholder="Seleccionar" />
                {/* <label htmlFor="" className="p-col-fixed mr-1 pb-1 font-bold " style={{  textAlign: 'start' }}>Notas</label> */}
                {/* <InputTextarea rows={2} cols={30} style={{ width: '80px' }}
                    value={vl_Nota} placeholder='Nota'
                    onChange={(e) => setNota(String(e.currentTarget.value))}
                  /> */}
              </div>
            </div>
          </div>
        </div>

        <DetalleOrdenPago
          o_detalleOrdenPago={o_detalleOrdenPago}
          detalleOrdenPago={detalleOrdenPago}
        />

        <Toast ref={toast} position="center"></Toast>
        <Toast ref={toastConfirm} position="center"></Toast>

        <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
          message="¿Guardar Registro?"
          header="Confirmar para continuar"
          icon="pi pi-exclamation-triangle"
          accept={onProcesarPago}
          reject={handleCloseShowConfirmSave}
        />

      </Dialog>
    </>
  );
};
export default ProcesarPago;

import { Player } from '@lottiefiles/react-lottie-player';
import { Dropdown } from 'primereact/dropdown';

import { ProgressBar } from 'react-bootstrap';
import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext01 } from '../../../context/AppContext';
import { fetchSinToken } from '../../../helpers/fetch';
import AgendaPsi from '../../components/account/AgendaPsi';

import { ScrollPanel } from 'primereact/scrollpanel';
import { ScrollTop } from 'primereact/scrolltop';
import '../../../assets/scss/pacienteTimeline.scss';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { setDateAdd, setIntlDateTimeFormat, toTimeZone, toTimeZoneF2 } from '../../../helpers/functions';
import { Calendar } from 'primereact/calendar';

const Index = () => {

  const vl_authUserFullName: any = localStorage.getItem('authUserFullName');
  const vl_IdSucursal: any = localStorage.getItem('authIdSucursal');
  const vl_authIdStaff: any = localStorage.getItem('authIdStaff');
  // const vl_authIdPerfil: any = localStorage.getItem('authIdPerfil');
  const [dataPeriodo, setDataPeriodo] = useState<any>([]);
  const [vl_periodo, setPeriodo] = useState<any>(new Date().getFullYear());
  const [dataPeriodoMeses, setDataPeriodoMeses] = useState<any>([]);
  const [vl_periodoMes, setPeriodoMes] = useState<any>(new Date().getMonth() + 1);
  const [vl_mesTexto, setMesTexto] = useState<any>('');
  const [vl_filter, setFilter] = useState<any>('');

  const toast = useRef<any>(null);

  //totales de sesiones
  const [vl_TtlSesion, setTtlSesion] = useState<any>(0);
  const [vl_TtlSProgramada, setTtlSProgramada] = useState<any>(0);
  const [vl_TtlSConfirmada, setTtlSConfirmada] = useState<any>(0);
  const [vl_TtlCReprogramada, setTtlCReprogramada] = useState<any>(0);
  const [vl_TtlCCancelada, setTtlCCancelada] = useState<any>(0);
  const [vl_TtlCNoRealizada, setTtlCNoRealizada] = useState<any>(0);
  const [vl_TtlCRealizada, setTtlCRealizada] = useState<any>(0);
  const [vl_TtlSPendiente, setTtlSPendiente] = useState<any>(0);

  const [dataSesionStaffCli, setDataSesionStaffCli] = useState<any>([]);
  const [dataGridDiasTrans, setDataGridDiasTrans] = useState<any>([]);

  const [VP_Fecha1, setFecha1] = useState<any>(setDateAdd(new Date(), -30));
  const [VP_Fecha2, setFecha2] = useState<any>(new Date());

  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/HOME');
  }

  useEffect(() => {
    handleSetTitle();

    //call init
    const periodo: any = new Date().getFullYear();
    // const mes: any = new Date().getMonth() + 1;
    const mes: any = new Date().getMonth() + 1; // mes all
    let dsPeriodo: any = [];
    let anio = 0;
    for (let i = (periodo - 3); i <= (periodo + 1); i++) {
      anio = i;
      dsPeriodo.push(
        { "lstPeriodo": anio }
      );
    }
    //console.log(dsPeriodo);
    setDataPeriodo(dsPeriodo);
    setPeriodo(periodo);
    //mes
    let vl_mes = ['Todo', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let dsPeriodoMes: any = [];
    for (let i = 0; i <= 12; i++) {
      dsPeriodoMes.push(
        { "lstMes": vl_mes[i], "IdMes": i }
      );
    }
    setDataPeriodoMeses(dsPeriodoMes);
    setPeriodoMes(mes);
    setMesTexto(vl_mes[mes]); //new 
    pa_get_sesiones_staff(vl_periodo, vl_periodoMes);
    pa_get_sesiones_staff_cliente(vl_periodo, vl_periodoMes);
    // pa_get_agenda_reportes_ld(VP_Fecha1, VP_Fecha2); //no cargar al inicio
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pa_get_sesiones_staff = async (vp_periodo: any, vp_mes: any) => {
    const param = {
      "vp_opcion": '1',
      "vp_IdStaff": vl_authIdStaff,
      "vp_periodo": vp_periodo,
      "vp_mes": vp_mes,
      "vp_IdSucursal": vl_IdSucursal
    }
    const response = await fetchSinToken('dashboard/pa_get_sesiones_staff', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    // console.log(record);
    setTtlSesion(record.TtlSesion);
    setTtlSProgramada(record.TtlSProgramada);
    setTtlSConfirmada(record.TtlSConfirmada);
    setTtlCReprogramada(record.TtlCReprogramada);
    setTtlCCancelada(record.TtlCCancelada);
    setTtlCNoRealizada(record.TtlCNoRealizada);
    setTtlCRealizada(record.TtlCRealizada);
    setTtlSPendiente(record.TtlSPendiente);
  }

  const pa_get_sesiones_staff_cliente = async (vp_periodo: any, vp_mes: any) => {
    const param = {
      "vp_IdStaff": vl_authIdStaff,
      "vp_periodo": vp_periodo,
      "vp_mes": vp_mes,
      "vp_IdSucursal": vl_IdSucursal,
      "vp_filter": vl_filter
    }
    const response = await fetchSinToken('dashboard/pa_get_sesiones_staff_cliente', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    // console.log(record);
    if (record.length === 0 && vl_filter.length > 0) {
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No se encontro información!', life: 8000 });
    } else {
      setDataSesionStaffCli(record);
    }

    // // console.log(record);
    // setTtlSesion(record.TtlSesion);
    // setTtlSProgramada(record.TtlSProgramada);
    // setTtlCReprogramada(record.TtlCReprogramada);
    // setTtlCCancelada(record.TtlCCancelada);
    // setTtlCNoRealizada(record.TtlCNoRealizada);
    // setTtlCRealizada(record.TtlCRealizada);
    // setTtlSPendiente(record.TtlSPendiente);
  }

  const pa_get_agenda_reportes_ld = async (IN_Fecha1: any, IN_Fecha2: any) => {

    const param = {
      "VP_ExcluirFecha": 1,
      "VP_Fecha1": setIntlDateTimeFormat(IN_Fecha1),
      "VP_Fecha2": setIntlDateTimeFormat(IN_Fecha2),
      "VP_IdStaff": vl_authIdStaff,
      "VP_IdSucursal": vl_IdSucursal,
      "VP_IdCliente": 0,
      "VP_EventState": '',
      "VP_EventType": '',
      "VP_TipoServicio": '',
      "VP_Servicio": '',
      "VP_NumeroHistoriaClinica": ''
    }
    const response = await fetchSinToken('agenda/pa_get_agenda_reportes_ld', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    if (record.length === 0) {
      // toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No hay registros', life: 8000 });
      setDataGridDiasTrans([]);
      return;
    }
    setDataGridDiasTrans(record);
  }

  const itemTemplate = (item: any) => {
    return (
      <>
        <div className='w-full' >
          <div className="text-blue-900 border-0 surface-overlay p-1 pb-2 ">
            <label className='text-1xl mb-2' >{item.Paciente} / {item.TipoRegistro_1 + ' ' }  
            / Total Sesión: 
            <Badge className='ml-1 mr-2' value={item.QtySesion} severity="info" >
            </Badge>
            / Progreso:
            <Badge className='ml-1' value={ (item.QtySesionExecuted / item.QtySesion * 100).toFixed(0) + '%'  } severity="warning" >
            </Badge>            
            </label>
            <ProgressBar
              min={0} 
              now={item.QtySesionExecuted}
              max={item.QtySesion}
              label={item.QtySesionExecuted === item.QtySesion ? 'Completado' : 'Realizadas ' + item.QtySesionExecuted + ' de ' + item.QtySesion } 
              variant={item.QtySesionExecuted === item.QtySesion ? 'success' : 'info'}>
            </ProgressBar>
          </div>
        </div>
      </>
    );
  }



  return (
    <>
      <Toast ref={toast} position="center"></Toast>
      <div className="flex justify-content-between pb-2 mb-1 bg-gray-200">
        <div className="pb-2 p-2 w-full">
          <div className="col mt-0 ml-0 text-black font-italic h-3rem" style={{ textAlign: 'start', minWidth: '15rem' }} >
            <label className='text-base font-bold'> Bienvenido(a), {vl_authUserFullName} </label><br />
            <label className='text-base font-semibold'>Agenda {vl_mesTexto + ' ' + vl_periodo}  </label>
          </div>
        </div>
        <div className="flex align-items-center justify-content-end w-30rem h-4rem p-2 ">
          <Dropdown style={{ width: '120px' }}
            value={vl_periodo}
            options={dataPeriodo}
            onChange={(e: any) => {
              setPeriodo(e.value);
              setPeriodoMes(1);
              setMesTexto('Jan'); //new 
              pa_get_sesiones_staff_cliente(e.value, 1);
              pa_get_sesiones_staff(e.value, vl_periodoMes);
            }}
            optionValue="lstPeriodo"
            optionLabel="lstPeriodo"
            placeholder="" />
          <label className='ml-1'>  </label>
          <Dropdown style={{ width: '120px' }}
            value={vl_periodoMes}
            options={dataPeriodoMeses}
            onChange={(e: any) => {
              setPeriodoMes(e.value);
              //
              let array_mes = ['Todo', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              setMesTexto(array_mes[e.value]); //new 
              pa_get_sesiones_staff_cliente(vl_periodo, e.value);
              pa_get_sesiones_staff(vl_periodo, e.value);
            }}
            optionValue="IdMes"
            optionLabel="lstMes"
            placeholder="" />
          <Button type="button" icon="pi pi-refresh"
            onClick={() => pa_get_sesiones_staff_cliente(vl_periodo, vl_periodoMes)}
            className="p-button-rounded p-button-primary p-button-outlined ml-2"
          />
        </div>
      </div>

      <div className='scrollpanel-demo' >
        <div className="card pt-3">
          <ScrollPanel className="custombar1" style={{ width: '100%', height: '700px' }}>
            <ScrollTop />
            <div className="flex flex-wrap justify-content-center text-base">
              <div className="col-2">
                <Player autoplay={true} loop={true} speed={1} controls={true} style={{ height: '100px', width: '100px' }}
                  src="../lottie-player/51971-hello.json"
                />
                <div className='col'>
                  <label className='font-semibold text-lg text-cyan-800 flex justify-content-center pb-2' >Resumen Sesiones</label>
                  <div className="flex justify-content-center">
                    <div className="flex align-items-stretch flex-wrap card-container blue-container">
                      <div className="flex align-items-center justify-content-center font-bold text-white border-round m-1" >
                        <div className="border-round h-5rem w-5rem bg-gray-800 text-white font-bold flex align-items-center justify-content-center">
                          <div className="grid-col">
                            <div className="p-col flex justify-content-center  ">
                              <label className="text-sm font-medium" style={{ textAlign: 'center' }}>Total</label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <label className='text-3xl' >{vl_TtlSesion} </label>
                            </div>
                            <img src='../icon/quantity.svg' alt="" style={{ width: '99%' }} />
                          </div>
                        </div>
                      </div>
                      <div className="flex align-items-center justify-content-center font-bold text-white border-round m-1" >
                        <div className="border-round h-5rem w-5rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                          <div className="grid-col">
                            <div className="p-col flex justify-content-center  ">
                              <label className="text-sm font-medium" style={{ textAlign: 'center' }}>Progr.</label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <label className='text-3xl' > {vl_TtlSProgramada} </label>
                            </div>
                            <img src='../icon/quantity.svg' alt="" style={{ width: '99%' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-content-center">
                    <div className="flex align-items-stretch flex-wrap card-container blue-container">
                      <div className="flex align-items-center justify-content-center font-bold text-white border-round m-1" >
                        <div className="border-round h-5rem w-5rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                          <div className="grid-col">
                            <div className="p-col mb-0 flex justify-content-center  ">
                              <label className="text-sm font-medium" style={{ textAlign: 'center' }}>Confirmada</label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <label className='text-3xl' > {vl_TtlSConfirmada} </label>
                            </div>
                            <img src='../icon/quantity.svg' alt="" style={{ width: '99%' }} />
                          </div>
                        </div>
                      </div>
                      <div className="flex align-items-center justify-content-center font-bold text-white border-round m-1" >
                        <div className="border-round h-5rem w-5rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                          <div className="grid-col">
                            <div className="p-col mb-0 flex justify-content-center  ">
                              <label className="text-sm font-medium" style={{ textAlign: 'center' }}>Reprogr.</label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <label className='text-3xl' > {vl_TtlCReprogramada} </label>
                            </div>
                            <img src='../icon/quantity.svg' alt="" style={{ width: '99%' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-content-center">
                    <div className="flex align-items-stretch flex-wrap card-container blue-container">
                      <div className="flex align-items-center justify-content-center font-bold text-white border-round m-1" >
                        <div className="border-round h-5rem w-5rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                          <div className="grid-col">
                            <div className="p-col mb-0 flex justify-content-center  ">
                              <label className="text-sm font-medium" style={{ textAlign: 'center' }}>Cancelada</label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <label className='text-3xl' > {vl_TtlCCancelada} </label>
                            </div>
                            <img src='../icon/rate.svg' alt="" style={{ width: '99%' }} />
                          </div>
                        </div>
                      </div>
                      <div className="flex align-items-center justify-content-center font-bold text-white border-round m-1" >
                        <div className="border-round h-5rem w-5rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                          <div className="grid-col">
                            <div className="p-col mb-0 flex justify-content-center  ">
                              <label className="text-sm font-medium" style={{ textAlign: 'center' }}>No Realizada</label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <label className='text-3xl' > {vl_TtlCNoRealizada} </label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <img src='../icon/quantity.svg' alt="" style={{ width: '98%' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-content-center">
                    <div className="flex align-items-stretch flex-wrap card-container blue-container">
                      <div className="flex align-items-center justify-content-center font-bold text-white border-round m-1" >
                        <div className="border-round h-5rem w-5rem bg-gray-700 text-white font-bold flex align-items-center justify-content-center">
                          <div className="grid-col">
                            <div className="p-col mb-0 flex justify-content-center  ">
                              <label className="text-sm font-medium" style={{ textAlign: 'center' }}>Realizada</label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <label className='text-3xl' > {vl_TtlCRealizada} </label>
                            </div>
                            <img src='../icon/quantity.svg' alt="" style={{ width: '99%' }} />
                          </div>
                        </div>
                      </div>
                      <div className="flex align-items-center justify-content-center font-bold text-white border-round m-1" >
                        <div className="border-round h-5rem w-5rem bg-gray-800 text-white font-bold flex align-items-center justify-content-center">
                          <div className="grid-col">
                            <div className="p-col mb-0 flex justify-content-center  ">
                              <label className="text-sm font-medium" style={{ textAlign: 'center' }}>Pendiente</label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <label className='text-3xl' > {vl_TtlSPendiente} </label>
                            </div>
                            <div className="p-col flex justify-content-center  ">
                              <img src='../icon/quantity.svg' alt="" style={{ width: '98%' }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <AgendaPsi
                  vp_periodo={vl_periodo}
                  vp_mes={vl_periodoMes}
                  vp_IdSucursal={vl_IdSucursal}
                  vp_IdStaff={vl_authIdStaff}
                />
              </div>
            </div>

            <div className="col border-yellow-500 border-top-2 mt-2">
              <div className='pb-2'>
                <label className='font-semibold text-xl text-cyan-800  flex justify-content-center' >Progreso de sesiones</label>
              </div>
            </div>
            <div className="card p-2 m-1 " >
              <div className="flex flex-wrap card-container  ">
                <div className="flex align-items-center justify-content-center m-0 w-full ">
                  <div className='p-col p-2'>
                    <label className='font-semibold text-xl pr-2 '>Buscar paciente: </label>
                    <InputText type="search" style={{ width: '49%', textAlign: 'left' }}
                      placeholder='Apellidos o Nº HC .. '
                      value={vl_filter}
                      onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                      onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                          pa_get_sesiones_staff_cliente(vl_periodo, vl_periodoMes);
                        }
                      }}
                    />
                    <Button icon="pi pi-search" title='Ejecutar busqueda' className="p-button-rounded ml-2"
                      onClick={(e: any) => {
                        if (vl_filter.length > 3) pa_get_sesiones_staff_cliente(vl_periodo, vl_periodoMes);
                        else toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'Ingrese por lo menos 3 caracteres de búsqueda', life: 8000 });
                      }}
                    />
                  </div>
                </div>

                <div className="flex align-items-center justify-content-center m-0 w-full">
                  <DataTable
                    size="small"
                    // footer={footer}
                    style={{ width: '70vw' }}
                    value={dataSesionStaffCli ?? []}
                    // dataKey="IdOrdenPago"
                    resizableColumns
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    // scrollHeight="610px"
                    scrollHeight="70vh"
                    scrollDirection='horizontal'
                    paginator
                    rows={20}
                  >
                    <Column field="Paciente"
                      headerClassName="flex justify-content-start "
                      bodyClassName="text-cyan-800 font-normal"
                      body={itemTemplate}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>

            <div className="col border-yellow-500 border-top-2 mt-2 ">
              <div className='pb-2'>
                <label className='font-semibold text-xl text-cyan-800  flex justify-content-center' >Últimas sesiones</label>
              </div>
            </div>
            <div className="card p-2 m-1 " >
              <div className="flex align-items-center justify-content-center m-0 w-full pb-2 ">
                <div className='p-col p-2'>
                  <label className='font-semibold text-xl pr-2 '>Desde: </label>
                  <Calendar style={{ width: '10rem' }} inputClassName="p-inputtext-sm" icon="pi pi-calendar-plus"
                    dateFormat="yy/mm/dd" showIcon={true} maxDate={new Date()}
                    value={VP_Fecha1}
                    onChange={(e) => setFecha1(e.value)}
                  />
                  <Button icon="pi pi-search" title='Actualizar busqueda' className="p-button-rounded ml-2"
                    onClick={(e: any) => {
                      if (VP_Fecha1) pa_get_agenda_reportes_ld(VP_Fecha1, VP_Fecha2);
                      else toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'Ingrese la fecha Desde', life: 8000 });
                    }}
                  />
                </div>
              </div>
              <label className='font-normal pb-2'>Dias transcurridos desde las últimas sessiones programadas o realizadas al: {toTimeZoneF2(VP_Fecha2, 'America/Lima')}</label>
              <DataTable size="small" resizableColumns
                // footer={footer}
                responsiveLayout="scroll"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                value={dataGridDiasTrans}
                selectionMode="single"
                scrollHeight="50vh"
                paginator
                rows={100}
              >
                <Column field="Paciente" filter style={{ minWidth: '18rem' }} header="Paciente"></Column>
                <Column field="NumeroHistoriaClinica" header="Nº HC" style={{ maxWidth: '8rem' }} headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center" ></Column>
                <Column field="EventTitle" header="Servicio" style={{ minWidth: '15rem' }}></Column>
                <Column field="NumeroSesion" header="#Sesión" style={{ maxWidth: '5rem' }} headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center"></Column>
                <Column field="QtyTotalSesion" header="Total Sesión" style={{ maxWidth: '5rem' }} headerClassName="white-space-normal" bodyClassName="flex justify-content-center"></Column>
                <Column field="Ultima_Sesion" header="Fecha última sesión." style={{ minWidth: '8rem' }} headerClassName="white-space-normal" bodyClassName="white-space-normal"></Column>
                <Column field="dias" header="Dias transc." sortable style={{ maxWidth: '7rem' }} headerClassName="white-space-normal" bodyClassName="flex justify-content-center"
                  body={(item: any) => {
                    return (<label className=' font-bold text-red-500' > {item.dias}  </label>)
                  }}
                >
                </Column>
              </DataTable>
            </div>

            <ScrollTop target="parent" threshold={100} className="custom-scrolltop" icon="pi pi-arrow-up" />
          </ScrollPanel>
        </div>
      </div>
    </>
  );
}

export default Index;

import { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import { fetchSinToken } from '../../../helpers/fetch';

const CitasProfesional = ({ ...prop }) => {
    // console.log(prop);
    const [dataSesiones, setdataSesiones] = useState<any>([]);

    useEffect(() => {
        // console.log('CitasProfesional=>useEffect');
        pa_agenda_resumen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.vp_periodo, prop.vp_mes]); //si cambia el mes volver a actualizar

    //pa_get_dashboard_resumen_sesiones (QUITAR)
    const pa_agenda_resumen = async () => {
        const param = {
            "vp_opcion": '2',
            "vp_periodo": prop.vp_periodo,
            "vp_mes": prop.vp_mes,
            "vp_IdSucursal":  prop.vp_IdSucursal
        }
        const response = await fetchSinToken('dashboard/pa_agenda_resumen', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        setdataSesiones(record);

    }

    // console.log(props);
    let DatasetLabels01: any = [];
    let datasets01: any = []; //TOTAL VIRTUAL
    let datasets02: any = []; //TOTAL PRESENCIAL
    let datasets03: any = []; //TOTAL
    let VL_TOTAL = 0;
    // console.log(dataSesiones);    
    dataSesiones.forEach(
        function (row: any) {
            DatasetLabels01.push(row.Staff);            
            datasets01.push(row.TtlVirtual);            
            datasets02.push(row.TtlPresencial);            
            datasets03.push(row.TtlSesion);
            VL_TOTAL = VL_TOTAL + parseFloat(row.TtlSesion);
        }
    );

    //filtrar a unique
    // const DatasetLabels01_unique = [...new Set(DatasetLabels01)];
    // console.log(DatasetLabels01);

    const basicData = {
        labels: DatasetLabels01,
        datasets: [
            // {
            //     label: 'VIRTUAL',
            //     backgroundColor: '#009688', //  --teal-500:#009688;
            //     data: datasets01 // data: [65, 59, 80, 81, 56, 55, 40]
            // },
            // {
            //     label: 'PRESENCIAL',
            //     backgroundColor: '#4caf50', //green-500:#4caf50;
            //     data: datasets02
            // },
            {
                label: 'Total realizadas: ' + VL_TOTAL ,
                backgroundColor: '#00A452',
                data: datasets03
            }
        ]
    };

    let horizontalOptions = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        aspectRatio: .8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };


    return (
        <>
            <div className="card">
                <Chart type="bar"
                    data={basicData}
                    options={horizontalOptions}
                    style={{ position: 'relative', width: '100%', height: '500px' }}
                />
            </div>

        </>
    );
};


export default CitasProfesional;
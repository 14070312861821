
import { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import TitleForm from '../../components/util/TitleForm';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmDialog } from 'primereact/confirmdialog';

const AnularOrdenPago = ({ ...props }) => {

    const [VP_Action, setAction] = useState<any>('I');
    const [VP_TipoProceso, setTipoProceso] = useState<any>('C');
    const [VP_IdAnulacion, setIdAnulacion] = useState<any>(0);
    const [VP_IdMotivo, setIdMotivo] = useState<any>(0);
    const [dataMotivo, setDataMotivo] = useState<any>([]); //array
    const [VP_DetalleAnulacion, setDetalleAnulacion] = useState<any>('');
    const [VP_ReferenciaID, setReferenciaID] = useState<any>(0);
    const toast = useRef<any>(null);
    const [b_visible1, setVisibleConfirmDialog1] = useState<boolean>(false);

    useEffect(() => {
        // console.log(props)
        setAction('I');
        setTipoProceso('O');
        setIdAnulacion(0);
        setReferenciaID(props.isData.IdOrdenPago);
        pa_get_sys_config();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const pa_set_anulaciones = async () => {

        const param = {
            "VP_Action": VP_Action,
            "VP_TipoProceso": VP_TipoProceso,
            "VP_IdAnulacion": VP_IdAnulacion,
            "VP_IdMotivo": VP_IdMotivo,
            "VP_DetalleAnulacion": VP_DetalleAnulacion,
            "VP_ReferenciaID": VP_ReferenciaID,
            "VP_IdUsuario": localStorage.getItem('authIdUsuario'),
            "VP_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        setVisibleConfirmDialog1(false);
        const response = await fetchSinToken('catalogo/pa_set_anulaciones', param, 'POST');
        const resp = await response.json();
        // console.log(resp);
        if (resp.data[0].sql_error === 1) {
            toast.current.show({ severity: 'error', summary: '.::.PSISABE', detail: resp.data[0].sql_message, life: 10000 });
        } else {
            toast.current.show({ severity: 'success', summary: '.::.PSISABE', detail: resp.data[0].sql_message, life: 10000 });
            props.setIsMounted(false);
            props.isCallfn(props.isData.IdCliente);
            props.isHide();
        }
    }
    const pa_get_sys_config = async () => {
        const param = {
            "VP_Identificador": 'MotivoAnulaOrden',
            "VP_Estado": "A"
        }
        const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataMotivo(record[0].RecordSet);
    }

    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';
        if (Number(VP_IdMotivo) === 0 || (!Number(VP_IdMotivo))) {
            vl_valida = false;
            vl_message = 'Seleccionar un motivo';
        } else if (String(VP_DetalleAnulacion).trim() === '') {
            vl_valida = false;
            vl_message = 'Ingresar detalle de anulación';
        }
        return [vl_valida, vl_message];
    }

    const setAnular = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 8000 });
            return;
        }
        // si no hay campos invalidos procesar...    
        setVisibleConfirmDialog1(true);
    }

    const renderHeader = () => {
        return (
            <>
                <TitleForm title={'Dar de baja a la orden '} />
            </>
        );
    }
    const renderFooter = () => {
        return (
            <>
                <Button label="Anular" icon="pi pi-trash" className="p-button-danger ml-2" onClick={() => setAnular()} />
                <Button label="Cerrar" icon="pi pi-times" className="p-button-outlined p-button-danger ml-2" onClick={() => {
                    props.setIsMounted(false);
                    props.isHide()
                }} />
            </>
        );
    }


    return (
        <>
            <Toast ref={toast} position="center"></Toast>
            <ConfirmDialog visible={b_visible1} onHide={() => setVisibleConfirmDialog1(false)}
                message="¿Seguro dar de baja la orden?"
                header="Confirmar."
                icon="pi pi-exclamation-triangle"
                accept={pa_set_anulaciones}
                reject={() => console.log('Cancelado')}
            />
            <Dialog
                header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
                visible={props.isOpen}
                footer={renderFooter}
                onHide={() => {
                    props.setIsMounted(false);
                    props.isHide();
                }}
            >
                <div className="flex flex-wrap card-container w-full bg-bluegray-200 ">
                    <div className="flex align-items-center justify-content-center m-1">
                        <label className="p-col-fixed mr-1 font-bold text-normal " style={{ width: '80px', textAlign: 'end' }}>Cliente:</label>
                        <label className="p-col-fixed mr-1 font-semibold text-base " style={{ textAlign: 'end' }}>{props.isData.ApellidoPaterno + ' ' + props.isData.ApellidoMaterno + ' ' + props.isData.Nombres}</label>
                        <label className="p-col-fixed mr-1 font-bold text-normal " style={{ width: '80px', textAlign: 'end' }}>H.C:</label>
                        <label className="p-col-fixed mr-1 font-semibold text-base " style={{ textAlign: 'end' }}>{props.isData.NumeroHistoriaClinica}</label>
                    </div>
                </div>
                <div className="flex flex-wrap card-container w-full bg-bluegray-200 ">
                    <div className="flex align-items-center justify-content-center m-1">
                        <label className="p-col-fixed  mr-1 font-bold text-normal " style={{ width: '80px', textAlign: 'end' }}>Nº OP:</label>
                        <label className="p-col-fixed  mr-1 font-semibold text-base " style={{ textAlign: 'end' }}>{props.isData.IdOrdenPago}</label>
                        <label className="p-col-fixed  mr-1 font-bold text-normal " style={{ width: '80px', textAlign: 'end' }}>Fecha:</label>
                        <label className="p-col-fixed  mr-1 font-semibold text-base " style={{ textAlign: 'end' }}>{props.isData.Fecha}</label>
                        <label className="p-col-fixed  mr-1 font-semibold text-base " style={{ width: '20px', textAlign: 'end' }}>{props.isData.Hora}</label>
                        <label className="p-col-fixed  mr-1 font-bold text-normal " style={{ width: '110px', textAlign: 'end' }}>Total:</label>
                        <label className="p-col-fixed  mr-1 font-semibold text-base " style={{ textAlign: 'end' }}>S/ {props.isData.Total}</label>
                    </div>
                </div>
                <div className="flex flex-wrap card-container w-full bg-bluegray-200 ">
                    <div className="flex align-items-center justify-content-center m-1">
                        <label className="p-col-fixed  mr-1 font-bold text-lg " style={{ width: '80px', textAlign: 'end' }}>Servicio:</label>
                        <label className="p-col-fixed  mr-1 font-semibold text-base " style={{ textAlign: 'end' }}>{props.isData.DetalleServicio}</label>
                        <label className="p-col-fixed  mr-1 font-bold text-lg " style={{ width: '80px', textAlign: 'end' }}>Sesiones:</label>
                        <label className="p-col-fixed  mr-1 font-semibold text-base " style={{ textAlign: 'end' }}>{props.isData.progreso}</label>
                    </div>
                </div>

                <div className="flex flex-wrap card-container w-full mt-3 ">
                    <div className="flex align-items-center justify-content-center m-1">
                        <label className="p-col-fixed mr-1 text-base font-semibold " style={{ width: '80px', textAlign: 'end' }}>Motivo:</label>
                        <Dropdown style={{ width: '22rem' }} showClear
                            value={VP_IdMotivo}
                            options={dataMotivo}
                            onChange={(e: any) => {
                                setIdMotivo(e.value);
                            }}
                            optionValue="Codigo"
                            optionLabel="Descripcion"
                            placeholder="Selecionar" />
                    </div>
                </div>
                <div className="flex flex-column card-container w-full ">
                    <div className="p-2">
                        <label className="p-col-fixed mr-1 pb-1 text-base font-semibold " style={{ width: '80px', textAlign: 'end' }}>Detalle:</label>
                        <InputTextarea rows={4} cols={30}
                            style={{ width: '100%' }}
                            value={VP_DetalleAnulacion}
                            onChange={(e: any) => setDetalleAnulacion(e.target.value)}
                        />
                    </div>
                </div>

            </Dialog>
        </>
    )
}

export default AnularOrdenPago;
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import { OrdenPagos } from '../caja/ordenPago/OrdenPagos';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressBar } from 'react-bootstrap';
import { setIntlDateTimeFormat, setIntlTimeFormat, toTimeZone } from '../../../helpers/functions';
import { ConfirmDialog } from 'primereact/confirmdialog';
// import { BlockUI } from 'primereact/blockui';
import useModal from '../../../context/WindowsOpen';
import BuscadorClientes from '../util/BuscadorClientes';
import TitleForm from '../util/TitleForm';
import { Badge } from 'primereact/badge';
import AgendaFormPro from './AgendaFormPro';
import ProgressBarUtil from '../util/ProgressBarUtil';

const AgendaForm = ({ ...props }) => {

    // console.log(props.isData.event);
    // console.log(props);

    // mode form
    const [vl_mode, setModeCrud] = useState('I');
    // const [blockedPanel, setBlockedPanel] = useState<boolean>(false);

    //calculos
    const [eventStart, setEventStart] = useState<any>(null);
    const [eventStartTime, setEventStartTime] = useState<any>(null);
    const [eventEnd, setEventEnd] = useState<any>(null);
    const [eventEndTime, setEventEndTime] = useState<any>(null);
    const [eventDuracion, setEventDuracion] = useState<any>('');

    //inputs 
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('20220000');
    const [vl_IdHistoriaClinica, setIdHistoriaClinica] = useState<any>('0');
    const [vl_IdCliente, setIdCliente] = useState<any>('0');
    const [vl_ClienteFullName, setClienteFullName] = useState<any>('');
    const [vl_ClienteNumeroTelefonoCodigoPais, setClienteNumeroTelefonoCodigoPais] = useState<any>('+51');
    const [vl_ClienteNumeroTelefono, setClienteNumeroTelefono] = useState<any>('');

    const [vl_IdAgenda, setIdAgenda] = useState<any>('0');
    const [vl_IdRegistro, setIdRegistro] = useState<any>('0');
    const [vl_EventTitle, setEventTitle] = useState<any>('');
    const [vl_IdStaff, setIdStaff] = useState<any>(0);
    // const [vl_Staff, setStaff] = useState<any>('');
    const [vl_EventType, setEventType] = useState<any>('P');
    const [vl_EventState, setEventState] = useState<any>('S');
    const [vl_Pagado, setPagado] = useState<boolean>(false);
    const [vl_Costo, setCosto] = useState<any>('0.00');

    //informacion adicional
    const [vl_NumeroSesion, setNumeroSesion] = useState<any>('0');
    const [vl_QtyTotalSesion, setQtyTotalSesion] = useState<any>('0');
    const [vl_Notificado, setNotificado] = useState<any>(true);
    const [vl_Notificado_1, setNotificado1] = useState<any>(0);
    const [vl_FechaNotificado, setFechaNotificado] = useState<any>('');
    const [vl_IdPlanPago, setIdPlanPago] = useState<any>('0');
    const [vl_IdPlanPagoDetalle, setIdPlanPagoDetalle] = useState<any>('0');
    const [vl_IdOrdenPago, setIdOrdenPago] = useState<any>('0');

    // dataSets    
    const [dataIdStaff, setDataIdStaff] = useState<any>([]);
    const [dataOrdenPago, setDataOrdenPago] = useState<any>([]);
    const [dataTipoAtencion, setDataTipoAtencion] = useState<any>([]);
    const [dataEventState, setDataEventState] = useState<any>([]);

    // const [dataAgendaSesionPendiente, setDataAgendaSesionPendiente] = useState<any>([{ "NbrSesion": 0, "TotalSesion": 0, "Importe": 0, "Estado": "" }]);

    // const [dataEventsTargetCalendar, setDataEventsTargetCalendar] = useState<any>([]);

    //de OP    
    const [selectedIemsGridOP, setSelectedIemsGridOP] = useState<any>([]);

    //flag
    const showDialogMessage = useRef<any>(null);
    const [saveConfirmDialog, setSaveConfirmDialog] = useState<boolean>(false);
    const [b_hiddenGrabar, setHiddenbtnGrabar] = useState<boolean>(false);
    const [b_hiddenEdit, setHiddenbtnEdit] = useState<boolean>(false);
    const [b_hiddenNew, setHiddenbtnNew] = useState<boolean>(false);

    //disabled/enabled
    const [disabledNumeroHistoriaClinica, setDisabledNumeroHistoriaClinica] = useState<boolean>(false);
    // const [disabledEventTitle, setDisabledEventTitle] = useState<boolean>(false);
    // const [disabledIdStaff, setDisabledIdStaff] = useState<boolean>(false);
    // const [disabledEventStart, setDisabledEventStart] = useState<boolean>(false);
    // const [disabledEventStartTime, setDisabledEventStartTime] = useState<boolean>(false);
    // const [disabledEventEnd, setDisabledEventEnd] = useState<boolean>(false);
    // const [disabledEventEndTime, setDisabledEventEndTime] = useState<boolean>(false);
    // const [disabledEventType, setDisabledEventType] = useState<boolean>(false);
    // const [disabledEventState, setDisabledEventState] = useState<boolean>(false);

    //ref 
    const ref_EventTitle = useRef<HTMLElement | HTMLInputElement | any>(null);
    const ref_NumeroHistoriaClinica = useRef<HTMLElement | HTMLInputElement | any>(null);

    //new
    let vl_FechaYear: any = new Date().getFullYear();
    const [p_IdStaff, setp_IdStaff] = useState<boolean>(true);
    const [b_ProgressBar, setProgressBar] = useState<boolean>(false);

    const [isOpenModal, openModal, closeModal] = useModal();
    const [isOpenModal01, openModal01, closeModal01] = useModal();
    const isMounted = useRef(false);
    const isMounted01 = useRef(false);

    const pa_get_staff = async () => {
        const param = {
            "vp_opcion": "A",
            "vp_IdStaff": "0",
            "VP_Codigo": "",
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataIdStaff(record);
    }

    useEffect(() => {
        // inicia
        setModeCrud(props.isMode);
        setHiddenbtnGrabar(true);
        setHiddenbtnEdit(true);

        //si perfil: ADMIN o ASISTENTES puede ver AGENDA
        if (localStorage.getItem('authIdPerfil') === '1' || localStorage.getItem('authIdPerfil') === '2') {
            setp_IdStaff(false);
        }

        pa_get_staff();
        pa_get_sys_config('TipoCita');
        pa_get_sys_config('EstadoCita');
        setDefaultTimedEventDuration();

        switch (props.isMode) {
            case 'I': //INSERT MODE                
                setFechaNotificado('');
                setTimeout(() => {
                    setHiddenbtnGrabar(false);
                    setModeView(false);
                }, 100);
                setDataEventNew();
                setHiddenbtnNew(true); //new
                break;
            case 'V': //VIEW MODE
                setNotificado(false);
                // setBlockedPanel(true);
                getRecordAgenda();
                setModeView(true);
                setHiddenbtnEdit(false);
                setHiddenbtnNew(false); //new
                break;
            case 'R': //VIEW READ ONLY
                setNotificado(false);
                // setBlockedPanel(true);
                getRecordAgenda();
                setModeView(true);
                setHiddenbtnEdit(true); //no se puede editar
                setHiddenbtnNew(true); //new
                break;
            case 'A': //ANULAR
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // funciones 
    const setDefaultTimedEventDuration = () => {
        setEventStart(new Date());
        setEventStartTime(new Date());
        let FechaNow: any = new Date();
        FechaNow.setUTCMinutes(FechaNow.getUTCMinutes() + 40);
        // FechaNow.setMinutes(FechaNow.getMinutes() + 15);
        setEventEnd(FechaNow);
        setEventEndTime(FechaNow);
        setEventEndTimeDuracion(new Date(), FechaNow);
    }

    const setEventEndChange = (valDate: any) => {
        const FechaSel: any = valDate;
        // console.log(FechaSel);
        if (FechaSel === null || FechaSel === '') return;

        setEventEnd(FechaSel);
        // FechaSel.setUTCHours(FechaSel.getUTCHours()); // new
        FechaSel.setUTCMinutes(FechaSel.getUTCMinutes() + 40);
        // FechaSel.setMinutes(FechaSel.getMinutes() + 15);
        setEventEndTime(FechaSel);
        setEventEndTimeDuracion(eventStartTime, eventEndTime);
    }

    const setEventEndTimeDuracion = (p_eventStartTime: any, p_eventEndTime: any) => {
        let diff = Math.abs(p_eventStartTime - p_eventEndTime);
        // let horas = Math.floor(diff / 60);
        // let minutos = diff - (horas * 60);
        let minutes = Math.floor((diff / 1000) / 60);
        // console.log(getCalculaIntervalTime('', ''));
        setEventDuracion(minutes + ' min');
    }

    // call sp database
    const getBuscarCliente = async (in_NumeroHistoriaClinica: any) => {
        // e.preventDefault();
        const param = {
            "VP_IdCliente": 0,
            "VP_NumeroDocumento": "",
            "VP_NumeroHistoriaClinica": in_NumeroHistoriaClinica,
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];

        if (record) {
            //si existe cliente dehabilitar datos del cliente      
            setIdHistoriaClinica(record.IdHistoriaClinica);
            setIdCliente(record.IdCliente);
            setClienteFullName(record.ClienteFullName);
            setClienteNumeroTelefono(record.InformacionContacto[0]?.NumeroTelefono);
            pa_get_orden_pago_cliente(record.IdCliente, 0);

        } else {
            const message = 'No se encontro cliente';
            showDialogMessage.current.show({ severity: 'warn', summary: '::PSISABE', detail: message, life: 10000 });

            // inputNumeroHistoriaClinicaRef.current.focus();
            // inputNumeroHistoriaClinicaRef.current.select();
        }
    }
    const pa_get_orden_pago_cliente = async (p_IdCliente: any, p_IdOrdenPago: any) => {

        const param = {
            "vp_IdCliente": p_IdCliente,
            "vp_IdOrdenPago": p_IdOrdenPago,
            "vp_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('caja/pa_get_orden_pago_cliente', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);        
        if (record.length > 0) {
            setDataOrdenPago(record);
        } else {
            const message = 'No hay sessiones pendientes de programar ';
            showDialogMessage.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
        }

    }
    const pa_get_agenda_sesion_pendiente = async (IdPlanpago: any) => {
        // console.log('IdPlanPago:'+ IdPlanPago );  
        let dataSet: any = [];
        if (Number(IdPlanpago) > 0) {
            const param = {
                "vp_IdPlanpago": IdPlanpago,
                "VP_NbrSesion": 0
            }
            const response = await fetchSinToken('agenda/pa_get_agenda_sesion_pendiente', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            //Estado e importe del pago de la cuota/sesion a programar
            // setPagado(record[0].Estado == 'P' ? false : true);
            // setCosto(record[0].Importe);
            // console.log(record);
            if (record.length > 0) {
                // setDataAgendaSesionPendiente(record);
                dataSet = record;
            }
        }
        return dataSet;
    }
    const pa_get_tipo_registro_info = async (p_TipoRegistro: any, p_IdRegistro: any) => {
        let dataSet: any = [];
        const param = {
            "vp_TipoRegistro": p_TipoRegistro,
            "vp_IdRegistro": p_IdRegistro
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_registro_info', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        dataSet = record;
        return dataSet;
    }
    const pa_get_sys_config = async (p_Identificador: any) => {
        const param = {
            "VP_Identificador": p_Identificador,
            "VP_Estado": "A"
        }
        const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record[0].RecordSet);
        switch (p_Identificador) {
            case 'TipoCita':
                setDataTipoAtencion(record[0].RecordSet);
                break;
            case 'EstadoCita':
                setDataEventState(record[0].RecordSet);
                break;
            default:
                break;
        }

    }

    const pa_get_agenda = async (p_IdAgenda: any) => {
        const param = {
            "vp_IdAgenda": p_IdAgenda,
            "vp_FechaYear": 0,
            "vp_FechaMonth": 0,
            "vp_IdStaff": 0,
            VP_IdSucursal: localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('selectors/pa_get_agenda', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // setDataEventsTargetCalendar(record);
        return record;
    }

    const setModeView = (flag: boolean) => {
        setDisabledNumeroHistoriaClinica(flag);
        // setDisabledEventTitle(flag);
        // setDisabledIdStaff(flag);
        // setDisabledEventStart(flag);
        // setDisabledEventStartTime(flag);
        // setDisabledEventEnd(flag);
        // setDisabledEventEndTime(flag);
        // setDisabledEventType(flag);
        // setDisabledEventState(flag);

    }

    const setDataEventNew = () => {

        setEventStart(new Date(Date.parse(props.isDataEvent.date)));
        setEventStartTime(new Date(Date.parse(props.isDataEvent.date)));
        setEventEnd(new Date(Date.parse(props.isDataEvent.date)));
        setEventEndTime(new Date(Date.parse(props.isDataEvent.date)));
        //pone duracion de 15miuntos por default
        setEventEndChange(new Date(Date.parse(props.isDataEvent.date)));
        setDefaultTimedEventDuration();

    }
    const getRecordAgenda = () => {
        const p_IdAgenda = props.isData.event.id;
        pa_get_agenda(p_IdAgenda).then(
            dataEvents => {
                // console.log(dataEvents);
                //console.log(dataEvents[0]);
                pa_get_orden_pago_cliente(0, dataEvents[0].IdOrdenPago);
                setNumeroHistoriaClinica(dataEvents[0].NumeroHistoriaClinica);
                setIdAgenda(dataEvents[0].id);
                setIdRegistro(dataEvents[0].IdRegistro);
                setIdPlanPagoDetalle(dataEvents[0].IdPlanPagoDetalle);
                setIdPlanPago(dataEvents[0].IdPlanPago);
                setIdOrdenPago(dataEvents[0].IdOrdenPago);
                setEventTitle(dataEvents[0].EventTitle);
                setIdCliente(dataEvents[0].IdCliente);
                setClienteFullName(dataEvents[0].ClienteFullName);
                setClienteNumeroTelefono(dataEvents[0].InformacionContacto[0]?.NumeroTelefono);
                setIdStaff(parseInt(dataEvents[0].IdStaff));                
                setEventStart(new Date(Date.parse(dataEvents[0].start)));
                setEventStartTime(new Date(Date.parse(dataEvents[0].start)));
                setEventEnd(new Date(Date.parse(dataEvents[0].end)));
                setEventEndTime(new Date(Date.parse(dataEvents[0].end)));
                setEventDuracion(dataEvents[0].EventSlot);
                setEventType(dataEvents[0].EventType);
                setEventState(dataEvents[0].EventState);
                setPagado(dataEvents[0].Pagado === 1 ? true : false);
                setCosto(dataEvents[0].Costo);
                setNumeroSesion(dataEvents[0].NumeroSesion);
                setQtyTotalSesion(dataEvents[0].QtyTotalSesion);
                setFechaNotificado(dataEvents[0]?.FechaNotificado_1);
                setNotificado1(dataEvents[0]?.Notificado);
            }
        );
    }


    // set record set OPTION INPUTs
    const onChangeTipoAtencion = (e: any) => {
        setEventType(e.value);
    }
    const onChangeEventState = (e: any) => {
        setEventState(e.value);
    }
    const onChangeIdStaff = (e: any) => {
        setIdStaff(e.value);        
    }
    const onChangePagado = (e: any) => {
        setPagado(e.value);
    }
    const onSelectedOPReiniciar = () => {
        setNumeroSesion(0);
        setQtyTotalSesion(0);
        setIdPlanPago(0);
        setIdPlanPagoDetalle(0);
        setIdOrdenPago(0);
        setCosto(0);
        setPagado(false);
        setEventTitle('');
    }

    //Obtiene estado de sesion(plan de pagos)
    const onSelectedOP = (e: any) => {
        // console.log(e);        
        if (e === null) return;
        onSelectedOPReiniciar();
        pa_get_agenda_sesion_pendiente(e.IdPlanPago).then(
            dataSet => {
                // console.log(dataSet);
                if (dataSet.length > 0) {
                    setNumeroSesion(dataSet[0].NbrSesion);
                    setQtyTotalSesion(dataSet[0].TotalSesion);
                    setIdPlanPago(dataSet[0].IdPlanPago);
                    setIdPlanPagoDetalle(dataSet[0].IdPlanPagoDetalle);
                    setIdOrdenPago(dataSet[0].IdOrdenPago);
                    setCosto(dataSet[0].Importe);
                    setPagado(dataSet[0].Estado === 'T' ? true : false);
                    let EventTitleDetail = String(e.Detalle).trim() + ' // S:' + dataSet[0].NbrSesion + ' DE ' + dataSet[0].TotalSesion;
                    setEventTitle(EventTitleDetail);
                } else {
                    //aviso
                    const message = 'El servicio seleccionado ya se encuentra programado/ejecutado';
                    showDialogMessage.current.show({ severity: 'info', summary: 'Message', detail: message, life: 9000 });
                }
            }
        );

        // Informacion adicional segun tipo de registro 
        // C=ADMISION(NO_APLICA) A=ORDEN DE ATENCION T=ORDEN DE TERAPIA
        setIdRegistro(e.IdRegistro);
        pa_get_tipo_registro_info(e.Tipo, e.IdRegistro).then(
            dataSet => {
                if (dataSet[0].IdStaff) {
                    setIdStaff(parseInt(dataSet[0].IdStaff));                    
                }
                else setIdStaff(0);
            }
        );

    }

    const onValidarCampos = () => {

        let vl_valida = true;
        let vl_message = '';

        // console.log(vl_IdStaff);

        if (parseFloat(vl_IdPlanPagoDetalle) === 0 || (selectedIemsGridOP.length === 0 && vl_mode === 'I')) {
            vl_valida = false;
            vl_message = 'Seleccionar un servicio ';
        } else if (parseFloat(vl_IdStaff) === 0 || String(vl_IdStaff).trim() === "" || vl_IdStaff === undefined) {
            vl_valida = false;
            vl_message = 'Seleccionar profesional';
        } else if (eventStart === "") {
            vl_valida = false;
            vl_message = 'Seleccionar fecha inicio';
        } else if (eventStartTime === "") {
            vl_valida = false;
            vl_message = 'Seleccionar hora inicio';
        } else if (eventEnd === "") {
            vl_valida = false;
            vl_message = 'Seleccionar fecha fin';
        } else if (eventEndTime === "") {
            vl_valida = false;
            vl_message = 'Seleccionar hora fin';
        } else {
            // si se notifica via WhatsApp
            if (vl_Notificado) {
                if (vl_ClienteNumeroTelefonoCodigoPais.length !== 3) {
                    vl_valida = false;
                    vl_message = 'Codigo de pais del teléfono no es válido';
                }
                if (vl_ClienteNumeroTelefono === '' || vl_ClienteNumeroTelefono.length !== 9) {
                    vl_valida = false;
                    vl_message = 'Numero de teléfono no es válido';
                }
            }
        }

        return [vl_valida, vl_message];
    }

    const setVisibleConfirmDialog = () => {
        setSaveConfirmDialog(true);
    }

    const handleCancel = () => {
        setSaveConfirmDialog(false);
    }

    const handleClickBuscar = (e: any) => {
        isMounted.current = true;
        openModal();
    }
    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }

    const BuscadorClientesForm = () => {
        return (
            <BuscadorClientes
                isOpen={isOpenModal}
                isHide={closeModal}
                getDataCliente={getDataCliente}
                setIsMounted={setIsMounted}
            />
        );
    }

    const getDataCliente = (data: any) => {
        // console.log(data);
        // setEdad(data.Edad);
        // setCliente(data.ClienteFullName);
        // setIdCliente(data.IdCliente);
        setIdHistoriaClinica(data.IdHistoriaClinica);
        setNumeroHistoriaClinica(data.NumeroHistoriaClinica);
        // setDireccionEmail(data.InformacionContacto[0].DireccionEmail);
        // setNumeroTelefono(data.InformacionContacto[0].NumeroTelefono);
        // setNumeroDocumento(data.NumeroDocumento);
        // setTipoDocumento(data.tipo_doc);
        // // cargar informacion del paciente seleccionado
        getBuscarCliente(data.NumeroHistoriaClinica);
    }

    const handleOnSubmitSave = async () => {

        switch (vl_mode) {
            case 'I': case 'U':
                setSaveConfirmDialog(false);
                const IsValidaCampos = onValidarCampos();
                const Isvalido = IsValidaCampos[0];
                const IsMessage = IsValidaCampos[1];
                if (!Isvalido) {
                    showDialogMessage.current.show({ severity: 'info', summary: 'Campos Requeridos', detail: IsMessage, life: 4000 });
                    return;
                }
                break;
            // case 'A':
            //     setSaveConfirmDialog1(false);
            //     break;
            default:
                break;
        }

        const param = {
            VP_ACTION: vl_mode,
            VP_IdAgenda: vl_IdAgenda,
            VP_Fecha: setIntlDateTimeFormat(eventStart),
            VP_Hora: setIntlTimeFormat(eventStartTime),
            VP_EventDateLong: '',
            VP_EventStart: setIntlDateTimeFormat(eventStart) + ' ' + setIntlTimeFormat(eventStartTime),
            VP_EventEnd: setIntlDateTimeFormat(eventEnd) + ' ' + setIntlTimeFormat(eventEndTime),
            VP_EventSlot: eventDuracion,
            VP_IdStaff: vl_IdStaff,
            VP_IdSucursal: localStorage.getItem('authIdSucursal'), //VP_IdSede: '0', // vl_IdSede               
            VP_EventTitle: vl_EventTitle,
            VP_IdCliente: vl_IdCliente,
            VP_IdRegistro: vl_IdRegistro,
            VP_EventState: vl_EventState,
            VP_EventType: vl_EventType,
            VP_Pagado: vl_Pagado ? 1 : 0,
            VP_Costo: vl_Costo,
            VP_PorcentajePagoStaff: 0, //CALCULA EN BD
            VP_ImportePagoStaff: 0, //CALCULA EN BD
            VP_NumeroSesion: vl_NumeroSesion,
            VP_QtyTotalSesion: vl_QtyTotalSesion,
            VP_Notificado: vl_Notificado ? 1 : 0,
            VP_FechaNotificado: vl_FechaNotificado,
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_IdPlanPago: vl_IdPlanPago,
            VP_IdPlanPagoDetalle: vl_IdPlanPagoDetalle,
            VP_IdOrdenPago: vl_IdOrdenPago,
            VP_ClienteNumeroTelefono: vl_ClienteNumeroTelefonoCodigoPais + vl_ClienteNumeroTelefono
        };

        // console.log(param);
        // return;
        setProgressBar(true);
        const response = await fetchSinToken('agenda/pa_set_scheduled', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log(record);
        setProgressBar(false);
        if (record) {

            const message = record.sql_message;
            // console.log(message);
            showDialogMessage.current.show({ severity: record.sql_error === 1 ? 'error' : 'success', summary: '::PSISABE', detail: message, life: 8000 });
            if (record.sql_error === 0) {
                // se va a imprimir a demanda
                // if (vl_mode === 'I')
                //     buildPDFReservaCita(record.IdAgenda); //PRINT reserva cita

                // enviar notificacion por WhatsApp
                if (vl_Notificado) {
                    sendWhatsAppTwilio(record.IdAgenda).then(
                        (response: any) => {
                            console.log('AppTwilio response:');
                            console.log(response);
                        });
                }

                //eventos de la pantalla anterior
                props.isMounted(false);
                props.isHide();
                props.isCallfn();  //call fn: pa_get_agenda 
            }

        } else {
            const message =
                'Code: ' + resp.data.code +
                'Err: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            // console.log(message);
            showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
        }

    }
    const setModeEdit = () => {
        // setBlockedPanel(false);
        setModeCrud('U');
        setModeView(false);
        setHiddenbtnGrabar(false);
        setHiddenbtnEdit(true);
    }
    //new
    const setModeNew = () => {
        setModeCrud('I');
        setModeView(false);
        setHiddenbtnGrabar(false);
        setHiddenbtnEdit(true);
        setHiddenbtnNew(true);
        setReiniciarValores();
    }

    const setReiniciarValores = () => {
        setDataOrdenPago([]);
        onSelectedOPReiniciar();
        setFechaNotificado('');
        setNotificado1(0);
        setEventState('S');
        let vl_date: any = new Date();
        setEventStart(new Date(Date.parse(vl_date)));
        setEventStartTime(new Date(Date.parse(vl_date)));
        setEventEnd(new Date(Date.parse(vl_date)));
        setEventEndTime(new Date(Date.parse(vl_date)));
        //pone duracion de 40min por default
        setEventEndChange(new Date(Date.parse(vl_date)));
        setDefaultTimedEventDuration();
        setTimeout(() => {
            // setModeView(false);
            ref_NumeroHistoriaClinica.current.focus();
        }, 100);
    }


    // 
    const buildPDFReservaCita = async (p_IdAgenda: any) => {
        // console.log(p_IdAgenda);
        if (parseFloat(p_IdAgenda) === 0) {
            showDialogMessage.current.show({ severity: 'info', summary: 'Message', detail: 'Seleccionar o generar un cita para imprimir', life: 6000 });
            return;
        }
        // const config = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
        const endpoint = 'reports/reservaCita/?IdAgenda=' + p_IdAgenda;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    //envio automatico desdepues de la cita
    const sendWhatsAppTwilio = async (p_IdAgenda: any) => {
        // console.log(p_IdAgenda);
        if (p_IdAgenda === 0) {
            showDialogMessage.current.show({ severity: 'info', summary: ':PSISABE', detail: 'No hay cita generada', life: 6000 });
            return;
        }
        if (vl_ClienteNumeroTelefonoCodigoPais.length !== 3) {
            showDialogMessage.current.show({ severity: 'info', summary: ':PSISABE', detail: 'Codigo de pais del teléfono no es válido', life: 6000 });
            return;
        }
        if (vl_ClienteNumeroTelefono === '' || vl_ClienteNumeroTelefono.length !== 9) {
            showDialogMessage.current.show({ severity: 'info', summary: ':PSISABE', detail: 'Numero de teléfono no es válido', life: 6000 });
            return;
        }

        const param = {
            "VP_IdAgenda": p_IdAgenda,
            "VP_ClienteNumeroTelefono": vl_ClienteNumeroTelefonoCodigoPais + vl_ClienteNumeroTelefono,
            "VP_IdSucursal": localStorage.getItem('authIdSucursal'),
            "VP_IdUsuario": localStorage.getItem('authIdUsuario')
        }
        const response = await fetchSinToken('twilioApi/pa_sendMessageWhatsApp', param, 'POST');
        // console.log(response);
        const resp = await response.json();
        // console.log(resp);
        //envia automatico despues de crear cita(segun check: Notificacion)
        if (!resp.ok) {
            showDialogMessage.current.show({ severity: resp.severity, summary: 'Response', detail: resp.message, life: 6000 });
            return response.ok;
        }
        return response.ok;
    }

    //para envios individuales
    const sendWhatsAppTwilio01 = async (p_IdAgenda: any) => {
        // console.log(p_IdAgenda);
        if (p_IdAgenda === 0) {
            showDialogMessage.current.show({ severity: 'info', summary: 'Message', detail: 'No hay cita generada', life: 6000 });
            return;
        }
        if (vl_ClienteNumeroTelefonoCodigoPais.length !== 3) {
            showDialogMessage.current.show({ severity: 'info', summary: 'Message', detail: 'Codigo de pais del teléfono no es válido', life: 6000 });
            return;
        }
        if (vl_ClienteNumeroTelefono === '' || vl_ClienteNumeroTelefono.length !== 9) {
            showDialogMessage.current.show({ severity: 'info', summary: 'Message', detail: 'Numero de teléfono no es válido', life: 6000 });
            return;
        }

        const param = {
            "VP_IdAgenda": p_IdAgenda,
            "VP_ClienteNumeroTelefono": vl_ClienteNumeroTelefonoCodigoPais + vl_ClienteNumeroTelefono,
            "VP_IdSucursal": localStorage.getItem('authIdSucursal'),
            "VP_IdUsuario": localStorage.getItem('authIdUsuario')
        }
        // const response = await fetchSinToken('paciente/pa_get_paciente_plan_sesion', param, 'POST');
        const response = await fetchSinToken('twilioApi/pa_sendMessageWhatsApp', param, 'POST');
        // console.log(response);
        const resp = await response.json();
        console.log(resp);
        // let vl_response_detail = '';
        // let vl_severity= 'success';
        // if (resp.ok){     
        //     vl_response_detail = 'Mensaje enviado correctamente';
        //     vl_severity= 'success';
        // }else{
        //     vl_response_detail = 'No se pudo enviar el mensaje';
        //     vl_severity= 'error';
        // }   
        showDialogMessage.current.show({ severity: resp.severity, summary: 'Response', detail: resp.message, life: 6000 });
        return response.ok;
    }

    const handleClickAgendaPro = (e: any) => {
        if (vl_IdStaff === 0) {
            showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar un profesional', life: 8000 });
            return;
        }
        isMounted01.current = true;
        openModal01();
    }
    const setIsMounted01 = (val: boolean) => {
        isMounted01.current = val;
    }

    const getDataCalendar = (vp_date: any) => {
        // console.log(vp_date);
        if (vp_date) {
            setEventStart(vp_date);
            setEventStartTime(new Date(Date.parse(vp_date)));
            setEventEndChange(vp_date);
            showDialogMessage.current.show({ severity: 'success', summary: '::PSISABE', detail: 'Horario seleccionado correctamente!', life: 4000 });
        } else {
            showDialogMessage.current.show({ severity: 'warn', summary: '::PSISABE', detail: 'No ha seleccionado un horario!', life: 4000 });
        }

    }

    const AgendaFormProView = () => {
        
        let itemsfiltered: any = dataIdStaff.filter(function (element: any) {
            return element.IdStaff === vl_IdStaff;
        });        
        let vl_Staff =  itemsfiltered[0].Apellidos + ', ' + itemsfiltered[0].Nombres;

        return (
            <AgendaFormPro
                isOpen={isOpenModal01}
                isHide={closeModal01}
                setIsMounted={setIsMounted01}
                getDataCalendar={getDataCalendar}
                vp_periodo={vl_FechaYear}
                vp_mes={0}
                vp_IdSucursal={0}
                vp_IdStaff={vl_IdStaff}
                vp_Staff={vl_Staff}
            />
        );
    }

    const renderFooter = () => {
        return (
            <>
                <div className="border-top-2 border-yellow-500 pt-1">
                    <Button label="Grabar" hidden={b_hiddenGrabar} icon="pi pi-save" onClick={() => setVisibleConfirmDialog()} />
                    <Button label="Editar" hidden={b_hiddenEdit} icon="pi pi-pencil" onClick={() => setModeEdit()} />
                    <Button label="Nuevo" title='Crear nueva cita' hidden={b_hiddenNew} icon="pi pi-file" className="p-button-text" onClick={() => setModeNew()} />
                    <Button title="Imprimir cita" icon="pi pi-print" className="p-button-text" onClick={() => buildPDFReservaCita(vl_IdAgenda)} />
                    <Button label="Cerrar" icon="pi pi-times" className="p-button-danger p-button-outlined " onClick={() => {
                        props.isMounted(false);
                        props.isHide();
                    }} />
                </div>
            </>
        );
    }

    const renderHeader = () => {
        return (
            <>
                <TitleForm title={vl_mode === 'I' ? ' Programar nueva cita ' : 'Cita Programada #ID ' + props.isData.event.id} />
            </>
        );
    }

    return (
        <>
            <ProgressBarUtil
                ProgressBarVisible={b_ProgressBar}
                ProgressBarMessage='Please wait, loading...'
                setProgressBar={setProgressBar}
            />
            <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
            <React.Fragment>{isMounted01.current && <AgendaFormProView />}</React.Fragment>
            <Dialog
                header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable
                visible={props.isOpen}
                footer={renderFooter}
                onHide={() => {
                    props.isMounted(false);
                    props.isHide();
                }}
            >
                <div className="p-field p-grid">
                    <div className="p-col mb-1 ">
                        <label className="p-col-fixed mr-2 font-bold " style={{ textAlign: 'end' }}>N° H.C.</label>
                        <InputText type="hidden" value={vl_IdHistoriaClinica} />
                        <InputText type="hidden" value={vl_IdCliente} />
                        <InputText type="text" style={{ width: '110px', textAlign: 'center' }} aria-describedby="username1-help"
                            maxLength={8} autoFocus
                            value={vl_NumeroHistoriaClinica}
                            disabled={disabledNumeroHistoriaClinica}
                            ref={ref_NumeroHistoriaClinica}
                            onChange={(e: any) => setNumeroHistoriaClinica(String(e.currentTarget.value).toUpperCase())}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    getBuscarCliente(vl_NumeroHistoriaClinica);
                                }
                            }}
                        />
                        <Button label=""
                            onClick={(e: any) => getBuscarCliente(vl_NumeroHistoriaClinica)}
                            // style={{ height: '35px' }}
                            icon="fas fa-search" className="p-button-rounded ml-1"
                        />
                        <Button label=""
                            onClick={(e: any) => handleClickBuscar(e)}
                            tooltip='Buscar clientes con otros filtros... '
                            icon="fas fa-user" className="p-button-rounded ml-1 p-button-outlined "
                        />

                        <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>  </label>
                        <InputText type="text" style={{ width: '400px' }}
                            value={vl_ClienteFullName} readOnly className='font-bold text-gree border-round'
                            placeholder='Paciente/Cliente'
                        />
                    </div>

                    <OrdenPagos
                        dataOrdenPago={dataOrdenPago}
                        selectedIemsGridOP={selectedIemsGridOP}
                        setSelectedIemsGridOP={setSelectedIemsGridOP}
                        onSelectedOP={onSelectedOP}
                        onMode={vl_mode}
                    />

                    <div className="p-field p-grid mt-1">
                        <div className="p-col">
                            <InputText type="text" style={{ height: '30px', width: '100%' }}
                                placeholder='Agregue un título o descripcion de la cita'
                                value={vl_EventTitle}
                                onChange={(e) => setEventTitle(String(e.currentTarget.value).toUpperCase())}
                                ref={ref_EventTitle}
                            // disabled={disabledEventTitle}
                            // onKeyPress={(e: any) => {
                            //     if (e.key == 'Enter') {
                            //         inputApPaternoRef.current.focus();
                            //     }
                            // }}
                            />
                        </div>
                        <div className="p-col mt-1">
                            <Button icon="pi pi-user-plus" title='Seleccionar profesional' style={{ height: '38px' }} className="p-button-success" />
                            <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}> </label>
                            <Dropdown style={{ height: '35px', width: '28rem' }} filter filterBy="staff"
                                value={vl_IdStaff}
                                options={dataIdStaff}
                                onChange={onChangeIdStaff}
                                optionValue="IdStaff"
                                optionLabel="staff"
                                placeholder="Seleccionar profesional" />
                            <Button icon="pi pi-calendar" disabled={p_IdStaff} title='Ver agenda del profesional y seleccionar horario' className="p-button-info p-button-outlined ml-3 p-button-rounded "
                                onClick={(e: any) => handleClickAgendaPro(e)}
                            />
                        </div>
                        <div className="p-col mt-1">
                            <Button icon="pi pi-clock" title='Selecionar un horario' style={{ height: '38px' }} className="p-button-success" />
                            <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}> </label>
                            <Calendar value={eventStart} style={{ width: '123px', height: '30px' }}
                                // disabled={disabledEventStart}
                                onChange={(e: any) => {
                                    setEventStart(e.value);
                                    setEventEndChange(e.value);
                                }
                                }
                                onBlur={(e: any) => {
                                    setEventEndChange(eventStart);
                                }
                                }
                                minDate={new Date()}
                                dateFormat="yy/mm/dd"
                            />
                            <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}> </label>
                            <Calendar value={eventStartTime} style={{ width: '90px', height: '30px' }} // disabled={disabledEventStartTime} // hourFormat="12"
                                showTime dateFormat="hh:mm" timeOnly
                                onChange={(e: any) => {
                                    setEventStartTime(e.value);
                                    // console.log(e.value);
                                    // console.log(e.value.getFullYear());
                                    // console.log(e.value.getMonth());
                                    // console.log(e.value.getDay());
                                    // console.log(e.value.getHours());
                                    // console.log(e.value.getMinutes());
                                    // console.log(e.value.getSeconds());
                                    let m = 40; //add minuto
                                    let fechaI2 = new Date(e.value);
                                    fechaI2.setTime(fechaI2.getTime() + (m * 60 * 1000));
                                    // console.log(fechaI2);
                                    setEventEndTime(fechaI2); //new                                        
                                }}
                            // onBlur={(e: any) => {
                            //     //new
                            //     //console.log(e);                                        
                            // }}

                            />
                            <i className="pi pi-chevron-right mr-0 mt-0" style={{ fontSize: '1.6rem' }}></i>
                            <Calendar value={eventEnd} style={{ width: '123px', height: '30px' }}
                                onChange={(e: any) => setEventEnd(e.value)} minDate={new Date()}
                                dateFormat="yy/mm/dd"
                            // disabled={disabledEventEnd}
                            />
                            <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}> </label>
                            <Calendar value={eventEndTime} style={{ width: '90px', height: '30px' }} showTime dateFormat="hh:mm" timeOnly
                                // disabled={disabledEventEndTime}
                                // stepMinute={40}
                                onChange={(e: any) => {
                                    setEventEndTime(e.value)
                                }}
                                onBlur={(e: any) => {
                                    setEventEndTimeDuracion(eventStartTime, eventEndTime);
                                }}
                            />
                            <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}> </label>
                            <InputText type="text" style={{ height: '30px', width: '100px' }}
                                value={eventDuracion} placeholder='Duración..' readOnly
                                className='bg-yellow-500 font-bold text-gray-900 border-round'
                            />

                        </div>
                        <div className="p-col mt-1">
                            <Button icon="pi pi-tags" title='Indicar tipo de Atención' style={{ height: '38px' }} className="p-button-success" />
                            <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}> </label>
                            <Dropdown style={{ height: '38px', width: '200px' }} // filter filterBy="Descripcion"
                                value={vl_EventType}
                                // disabled={disabledEventType}
                                options={dataTipoAtencion}
                                onChange={onChangeTipoAtencion}
                                optionValue="Codigo"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />

                            <label className="p-col-fixed ml-2 mr-1 " style={{ textAlign: 'end' }}> Estado:</label>
                            <Dropdown style={{ height: '38px', width: '240px' }}
                                value={vl_EventState}
                                // disabled={disabledEventState}
                                options={dataEventState}
                                onChange={onChangeEventState}
                                optionValue="Codigo"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />
                        </div>
                        <div className="p-col mt-1 mb-1">
                            <Button icon="pi pi-dollar" style={{ height: '38px' }} className="p-button-success" />
                            <label className="p-col-fixed ml-2 mr-1" style={{ textAlign: 'end' }}> Sesión pagada </label>
                            <InputSwitch checked={vl_Pagado} style={{ height: '30px' }} disabled
                                onChange={(e: any) => onChangePagado(e)}
                            />
                            <label className="p-col-fixed ml-3 mr-1" style={{ textAlign: 'end' }}> Costo Servicio: </label>
                            <InputNumber type="text" inputStyle={{ height: '30px', width: '100px', textAlign: 'end' }}
                                value={vl_Costo} inputClassName='bg-yellow-500 font-bold text-gray-900 border-round'
                                readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                            />
                        </div>
                        <div className="p-col mt-1 mb-1">
                            <Button icon="pi pi-whatsapp" title='Enviar notificación por WhatsApp' style={{ height: '38px' }} className="p-button-success" />
                            <label className="p-col-fixed ml-2 mr-1" style={{ textAlign: 'end' }}>Notificar</label>

                            <InputSwitch checked={vl_Notificado} style={{ height: '30px' }}
                                onChange={(e: any) => {
                                    setNotificado(e.value);
                                }}
                            />
                            <label className="p-col-fixed ml-2 mr-1" style={{ textAlign: 'end' }}>Teléfono:</label>
                            <InputText type="text" style={{ height: '30px', width: '60px', textAlign: 'center' }}
                                value={vl_ClienteNumeroTelefonoCodigoPais}
                                onChange={(e: any) => setClienteNumeroTelefonoCodigoPais(String(e.currentTarget.value).toUpperCase())}
                            />
                            <label htmlFor="" className="p-col-fixed mr-1" style={{ textAlign: 'end' }}> </label>
                            <InputText type="text" style={{ height: '30px', width: '120px', textAlign: 'center' }}
                                value={vl_ClienteNumeroTelefono}
                                // disabled                                    
                                onChange={(e: any) => setClienteNumeroTelefono(String(e.currentTarget.value).toUpperCase())}
                            />
                            <label className="p-col-fixed ml-2" style={{ textAlign: 'end' }}> </label>
                            <Button disabled={vl_Notificado ? false : true} icon="pi pi-send" onClick={() => sendWhatsAppTwilio01(vl_IdAgenda)} title='Enviar notificación por WhatsApp' style={{ height: '38px' }} />

                        </div>

                        <ProgressBar min={0} now={vl_NumeroSesion} max={vl_QtyTotalSesion}
                            label={'Sesión ' + vl_NumeroSesion + ' de ' + vl_QtyTotalSesion}
                            variant={vl_NumeroSesion === vl_QtyTotalSesion ? 'success' : 'info'}>
                        </ProgressBar>

                    </div>

                </div>

                <div className="flex flex-wrap align-items-center justify-content-start pb-2 absolute bottom-0">
                    <p className="shadow-8 font-italic text-green-700 border-round border-0 surface-overlay p-2">Notif.- {vl_FechaNotificado} <Badge value={vl_Notificado_1} severity="danger" ></Badge> </p>
                </div>

                {/* </BlockUI> */}

                <Toast ref={showDialogMessage} position="center"></Toast>
                <ConfirmDialog visible={saveConfirmDialog} onHide={() => setSaveConfirmDialog(false)}
                    message="¿Guardar programación de cita?"
                    header="Confirmar..."
                    icon="pi pi-exclamation-triangle"
                    accept={handleOnSubmitSave}
                    reject={handleCancel}
                />

            </Dialog>
        </>
    );
};

export default AgendaForm;

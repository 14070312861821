
export const fetchSinToken = (endpoint: string, data: any, method = 'GET') => {

  // console.log(`fetchSinToken`);
  const baseUrl = process.env.REACT_APP_API_URL;
  // console.log(baseUrl);
  const url = `${baseUrl}/${endpoint}`;

  if (method === 'GET') {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  }
}

export const fetchConToken = (endpoint: string, data: any, method = 'GET') => {
  
  const baseUrl = process.env.REACT_APP_API_URL;
  const url = `${baseUrl}/${endpoint}`;
  const token = localStorage.getItem('token') || '';
  
  if (method === 'GET') {
    return fetch(url, {
      method,
      headers: {
        'xtoken': token
      }
    });
  } else {
    return fetch(url, {
      method,
      headers: {        
        'Content-type': 'application/json',
        'xtoken': token
      },
      body: JSON.stringify(data)
    });
  }
}





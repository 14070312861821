
import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import avatar from "../../../assets/img/avatars/man.png";

const PerfilView = () => {
    const navigate = useNavigate();
    let vl_IdUsuario: any = localStorage.getItem('authIdUsuario')
    const [VP_IdUsuario, setIdUsuario] = useState<any>(vl_IdUsuario);
    const [VP_UserName, setUserName] = useState<any>('');
    const [VP_Email, setEmail] = useState<any>('');
    const [VP_PassWord, setPassWord] = useState<any>('');
    const [VP_Flag, setFlag] = useState<Boolean>(true);
    const [VP_IdPerfil, setIdPerfil] = useState<any>('0');

    const [VL_Staff, setStaff] = useState<any>('');
    const [VL_Cargo, setCargo] = useState<any>('');
    const [VL_Sede, setSede] = useState<any>('');

    return (
        <>

            <div className="flex justify-content-between pt-1 pl-3 border-bottom-1 p-3  ">
                <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
                    <div className="col mt-0 ml-0 text-base font-bold font-italic h-2rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='pb-1'>Mi perfil </label>
                    </div>
                </div>
                <div className='p-1'>
                    <span className="p-buttonset">                        
                        <Button className="p-button-sm p-button-outlined p-button-danger ml-1 "
                            icon="pi pi-times"
                            label='Cerrar'
                            onClick={() => {
                                navigate('/WelcomePage');
                            }}
                        />
                    </span>
                </div>
            </div>

            <div className="flex flex-column card-container w-full">
                <div className="flex align-items-center justify-content-center m-3">
                    <img src={avatar} alt="" className="img-fluid rounded-circle mb-2 w-2" />
                </div>
                <div className="flex align-items-center justify-content-center m-1">
                    <div className="font-italic font-normal text-sm ">
                        <label> {localStorage.getItem('authUserFullName')}</label>
                    </div>
                </div>
                <div className="flex align-items-center justify-content-center mb-3">
                    <small className="font-italic font-normal text-sm" > {localStorage.getItem('authUserCargo')} </small>
                </div>
            </div>
            <div className="flex flex-column card-container w-full">
                <div className="flex align-items-center justify-content-center m-1">
                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '150px', textAlign: 'end' }}>Nombre de usuario:</label>
                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>{localStorage.getItem('authUserName')}</label>
                </div>
                <div className="flex align-items-center justify-content-center m-1">
                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '150px', textAlign: 'end' }}>Clave:</label>
                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '80px', textAlign: 'start' }}>{'*********'}</label>
                </div>
                {/* <div className="flex align-items-center justify-content-center m-1">
                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '150px', textAlign: 'end' }}>Perfil:</label>
                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>{localStorage.getItem('authUserName')}</label>
                </div>
                <div className="flex align-items-center justify-content-center m-1">
                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '150px', textAlign: 'end' }}>Email:</label>
                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>{localStorage.getItem('authUserName')}</label>
                </div> */}

                <div className="flex align-items-center justify-content-center m-1">
                    <Button
                        type="button"
                        className="p-button-sm p-button-link "
                        icon="pi pi-key"
                        label='Cambiar contraseña'
                        onClick={() => {
                            navigate('/Auth/Change/Password/' + vl_IdUsuario);
                        }}
                    />
                </div>
                <div className="flex align-items-center justify-content-center m-1">
                    {/* <div className="field">
                        <button className="w-full btn btn-lg btn-warning" type="submit">Guardar los cambios</button>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default PerfilView
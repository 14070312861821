import { Button } from 'primereact/button';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../context/WindowsOpen';
import CajaAnularRecibo from '../../containers/caja/CajaAnularRecibo';

const HeaderMenubar = ({ ...props }) => {

  const navigate = useNavigate();
  const link_form_close = () => {
    navigate('/WelcomePage');
  }

  const [isOpenModal, openModal, closeModal] = useModal();
  const isMounted = useRef(false);
  const setIsMounted = (val: boolean) => {
    isMounted.current = val;
  }
  const handleEventClickAnular = () => {
    isMounted.current = true;
    openModal();
  }
  const CajaAnularReciboNew = () => {
    return (
      <CajaAnularRecibo
        isOpen={isOpenModal}
        isHide={closeModal}
        isData={[]}
        // isCallfn={pa_get_paciente_plan_sesion}
        setIsMounted={setIsMounted}
      />
    );
  }

  return (
    <>
    
      <React.Fragment>{isMounted.current && <CajaAnularReciboNew />}</React.Fragment>

      <span className="p-buttonset">
        <Button
          label="Registrar Pago"
          icon="pi pi-dollar"
          onClick={(e) => props.setFormProcesaPagos(e)}
          className="p-button-sm p-button-primary p-button-raised"
        />
        {/* <Button
          label="Ventas"
          icon="pi pi-desktop"
          onClick={(e) => {
            navigate('/CajaVentasView');
          }}
          className="p-button-sm p-button-primary p-button-raised"
        /> */}
        <Button
          label="Anular"
          icon="pi pi-trash"
          onClick={(e) => { handleEventClickAnular() }}
          className="p-button-sm p-button-danger p-button-text"
        />
        <Button
          icon="pi pi-times"
          onClick={() => link_form_close()}
          title="Cerrar"
          className="p-button-sm p-button-outlined p-button-danger ml-1 "
        />
        {/* <Button
          label="Reporte"
          icon="pi pi-file-pdf"
          onClick={
             () => {
                alert('Vaya no implementado!');
             }            
          }
          className="p-button-sm p-button-primary p-button-raised"
        /> */}
      </span>
    </>
  );
};


export default HeaderMenubar;

import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';


const HeaderOptions = ({ ...props }) => {

    return (
        <>
            <div className="p-field p-grid pl-1 pr-1 pt-1 pb-1 ">
                <div className="p-col">

                    <label className="p-col-fixed mr-1 ml-2 font-semibold " style={{ textAlign: 'end' }}>Del:</label>
                    <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                        dateFormat="yy/mm/dd" showIcon={true} 
                        // monthNavigator yearNavigator 
                        // yearRange={ 2020 +":" + 2023 }
                        maxDate={new Date()}
                        value={props.valFechaDesde}
                        onChange={(e) => props.setvalFechaDesde(e.value)}
                    />
                    <label className="p-col-fixed ml-1 mr-1 font-semibold " style={{ textAlign: 'end' }}>Al:</label>
                    <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                        dateFormat="yy/mm/dd" showIcon={true} 
                        // monthNavigator yearNavigator 
                        // yearRange={ 2020 +":" + new Date().getFullYear()+1 }
                        maxDate={new Date()}                        
                        value={props.valFechaHasta}
                        onChange={(e) => props.setvalFechaHasta(e.value)}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-semibold " style={{ textAlign: 'end' }}>N° HC:</label>
                    <InputText type="text" className='inputfield w-small' style={{ width: '110px', textAlign: 'center' }}
                        // aria-describedby="username1-help"
                        maxLength={8} autoFocus
                        value={props.NumeroHistoriaClinica}
                        // disabled={props.disabledNumeroHistoriaClinica}
                        // ref={props.inputNumeroHistoriaClinicaRef}
                        onChange={(e: any) => props.setNumeroHistoriaClinica(String(e.currentTarget.value).toUpperCase())}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                props.pa_get_orden_pago(e);

                            }
                        }}
                    />

                    <label className="p-col-fixed mr-3 ml-1 font-semibold " style={{ textAlign: 'end' }} >
                        <Checkbox checked={props.valCheckedPend} onChange={e => props.setChecked(e.checked)} />                        
                        Pendientes
                    </label>

                    <Button
                        title="Buscar"
                        icon="pi pi-search"
                        className="p-button-rounded ml-2 "
                        onClick={(e: any) => props.pa_get_orden_pago(e)}
                    />

                </div>
            </div>
        </>
    );
};

export default HeaderOptions;

import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { useNavigate, useParams } from 'react-router-dom';
import { Tree } from 'primereact/tree';
import { Checkbox } from 'primereact/checkbox';


const AuthUsuarioForm = ({ ...prop }) => {

    const { id: IdUsuario, mode } = useParams();
    // console.log(mode);
    const [vl_mode, setModeCrud] = useState(mode === 'New' ? 'I' : 'U');
    // console.log(vl_mode);
    const [b_confirmDialog, setSaveConfirmDialog] = useState<boolean>(false);

    //CRUD
    const [VP_IdUsuario, setIdUsuario] = useState<any>('0');
    const [VP_UserName, setUserName] = useState<any>('');
    const [VP_Email, setEmail] = useState<any>('');
    const [VP_PassWord, setPassWord] = useState<any>('');
    const [VP_Flag, setFlag] = useState<Boolean>(true);
    const [VP_IdPerfil, setIdPerfil] = useState<any>('0');
    const [VP_IdPerfilCurrent, setIdPerfilCurrent] = useState<any>('0');
    const [VP_IdStaff, setIdStaff] = useState<any>('0');
    const [VP_IdSysUsuarioPermiso, setIdSysUsuarioPermiso] = useState<any>(0);

    const [dataSede, setDataSede] = useState<any>([]);
    const [vl_IdSucursal, setIdSucursal] = useState<any>(localStorage.getItem('authIdSucursal')); //default

    const [VL_Staff, setStaff] = useState<any>('');
    const [VL_Cargo, setCargo] = useState<any>('');
    const [VL_Sede, setSede] = useState<any>('');


    const [disabledIdSucursal, setDisabledIdSucursal] = useState<boolean>(true);
    const [disabledSaveAll, setDisabledSaveAll] = useState<boolean>(false);
    const [activeIndex1, setActiveIndex1] = useState<any>(0);

    //combos
    // const [dataStaff, setDataStaff] = useState([]);
    const [dataPerfil, setPerfil] = useState([]);
    const [dataAuthMenu, setDataAuthMenu] = useState([]);
    const [dataAuthMenuAsignado, setDataAuthMenuAsignado] = useState([]);
    // const [dataUsuarioPermisos, setUsuarioPermisos] = useState<any>([]);
    //
    const [selectedKeys3, setSelectedKeys3] = useState<any>(null);

    //detalle formulario sel

    const [vl_IdMenu, setIdMenu] = useState<any>(0);
    const [vl_Menu, setMenu] = useState<any>('');
    const [vl_Crear, setCrear] = useState<boolean>(false);
    const [vl_Actualizar, setActualizar] = useState<boolean>(false);
    const [vl_Eliminar, setEliminar] = useState<boolean>(false);
    const [vl_Imprimir, setImprimir] = useState<boolean>(false);
    const [vl_Anular, setAnular] = useState<boolean>(false);
    // const [vl_Restricciones, setRestricciones] = useState<any>({});
    const [vl_RestricEmail, setRestricEmail] = useState<boolean>(false);
    const [vl_RestricFono, setRestricFono] = useState<boolean>(false);
    const [vl_RestricDire, setRestricDire] = useState<boolean>(false);

    const toast = useRef<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log('vl_IdSucursal>>', vl_IdSucursal);
        setModeCrud(vl_mode);
        // pa_get_staff();
        pa_get_sys_sucursal();
        pa_get_sys_usuario_perfil();
        setModeFormData(vl_mode);
        pa_get_sys_usuario_menu(vl_IdSucursal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vl_mode]);

    const pa_get_sys_sucursal = async () => {
        const param = {
            "VP_IdSucursal": 0
        }
        const response = await fetchSinToken('selectors/pa_get_sys_sucursal', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataSede(record);
    }
    const setModeFormData = (vl_isMode: any) => {
        switch (vl_isMode) {
            case 'I':
                setTimeout(() => {
                    // inputNumeroDocumento_f_Ref.current?.focus();
                }, 100);
                break;
            case 'U':
                getRecordUsuario();
                break;
            case 'A':
                break;
        }
    }
    const getRecordUsuario = async () => {
        // console.log(IdUsuario);
        setIdUsuario(IdUsuario);
        const param = {
            "VP_action": 'S',
            "VP_filter": '',
            "VP_IdUsuario": IdUsuario,
            "VP_UserName": '',
            "VP_Email": '',
            "VP_PassWord": '',
            "VP_Flag": '0',
            "VP_IdPerfil": '0',
            "VP_IdStaff": '0',
            "VP_IdUsuario_1": '0',
            "VP_IdSucursal": '0',
            "VP_sys_usuario_menu": '',
            "VP_sys_usuario_permiso": '',
            "VP_sys_usuario_sucursal": '',
            "VP_IdSysUsuarioPermiso": 0,
            "VP_IdMenu": 0
        }
        const response = await fetchSinToken('auth/pa_set_sys_usuario', param, 'POST');
        const resp = await response.json();

        // console.log(resp.data.errno);
        if (!resp.data.errno) {
            // console.log(resp.data);
            let row: any = resp.data[0];
            setUserName(row.UserName);
            setStaff(row.Staff);
            setCargo(row.Cargo);
            setSede(row.Sede);
            setIdPerfil(row.IdPerfil);
            setIdPerfilCurrent(row.IdPerfil); //guardar el ACTUAL
            setEmail(row.Email);
            setPassWord(row.PassWord);
            pa_get_auth_menu(vl_IdSucursal);
            pa_get_menu_asignado(vl_IdSucursal);
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
        }

    }

    const pa_get_auth_menu = async (IN_IdSucursal: any) => {
        const param = {
            VP_IdSucursal: IN_IdSucursal //localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('auth/pa_get_auth_menu', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        const temp: any = [];
        const rootTree: any = [];
        // const children: any = [];
        const root: any = [];

        let vj = 0;
        for (let row01 of record) {
            if (row01.id_menu !== 0 && row01.parent === 0) {
                temp.push({
                    'id_menu': row01.id_menu,
                    'name': String(row01.name).trim(),
                    'path': String(row01.path).trim(),
                    'parent': row01.parent,
                    'order': row01.order,
                    'icon': row01.icon,
                    'items': []
                });
                for (let row02 of record) {
                    if (row02.parent === row01.id_menu) {
                        temp[vj].items.push({
                            "key": row02.id_menu,
                            "label": String(row02.name).trim(),
                            "data": "Work Folder",
                            "icon": "pi pi-fw pi-cog",
                            'id_menu': row02.id_menu,
                            'name': String(row02.name).trim(),
                            'path': String(row02.path).trim(),
                            'parent': row02.parent,
                            'order': row02.order,
                            // 'icon': row02.icon,
                            'items': []
                        });
                    }
                }
                ++vj;
            }
        }
        // console.log(temp);

        vj = 0;
        for (let row of temp) {
            root.push({
                "key": row.id_menu,
                "label": row.name,
                "data": "Documents Folder",
                "icon": "pi pi-fw pi-folder",
                "children": row.items
            });
        }
        rootTree.push({ "root": root });
        setDataAuthMenu(rootTree[0].root);

    }

    const pa_get_menu_asignado = async (IN_IdSucursal: any) => {
        const param = {
            VP_IdSucursal: IN_IdSucursal, //localStorage.getItem('authIdSucursal'),
            VP_IdUsuario: IdUsuario
        }
        const response = await fetchSinToken('auth/pa_get_menu_asignado', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        const rootTree: any = [];
        const root: any = [];
        // console.log(record);
        for (let row of record) {
            root.push({
                "key": row.id_menu,
                "label": row.name,
                "data": "Documents Folder",
                "icon": "pi pi-fw pi-folder",
                "children": row.items
            });
        }
        rootTree.push({ "root": root });
        setDataAuthMenuAsignado(rootTree[0].root);

    }

    const setClearInputs = () => {
        setIdSysUsuarioPermiso(0);
        setCrear(false);
        setActualizar(false);
        setEliminar(false);
        setImprimir(false);
        setAnular(false);
        setRestricEmail(false);
        setRestricFono(false);
        setRestricDire(false);

    }
    const pa_get_sys_usuario_permiso = async (p_data: any) => {
        // console.log(p_data.children.length);        
        //nivel parent
        if (p_data.children?.length > 0)
            return;

        setClearInputs();
        const vl_IdMenu = p_data.key;
        const param = {
            VP_IdMenu: vl_IdMenu,
            VP_IdSucursal: vl_IdSucursal, //localStorage.getItem('authIdSucursal'),
            VP_IdUsuario: IdUsuario
        }
        const response = await fetchSinToken('auth/pa_get_sys_usuario_permiso', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        if (record.length === 0)
            return;

        setIdSysUsuarioPermiso(record[0].IdSysUsuarioPermiso);
        // setIdMenu(record[0].IdMenu);
        setCrear(record[0].Crear ? true : false);
        setActualizar(record[0].Actualizar ? true : false);
        setEliminar(record[0].Eliminar ? true : false);
        setImprimir(record[0].Imprimir ? true : false);
        setAnular(record[0].Anular ? true : false);
        // console.log(record[0].Restricciones);
        setRestricEmail(record[0].Restricciones?.Visible?.Email ? true : false);
        setRestricFono(record[0].Restricciones?.Visible?.Telefono ? true : false);
        setRestricDire(record[0].Restricciones?.Visible?.Direccion ? true : false);
        // setRestricciones(record[0].Restricciones ? record[0].Restricciones : []);
        // {
        //     'Visible': {
        //         "Email": vl_RestricEmail,
        //         "Telefono": vl_RestricFono,
        //         "Direccion": vl_RestricDire
        //     }
        // }
        // setUsuarioPermisos(record);
    }

    const pa_get_sys_usuario_perfil = async () => {
        const param = {
            "vp_action": ''
        }
        const response = await fetchSinToken('auth/pa_get_sys_usuario_perfil', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setPerfil(record);
    }

    const pa_get_sys_usuario_menu = async (IN_IdSucursal: any) => {
        setSelectedKeys3([]);
        const param = {
            "VP_IdUsuario": IdUsuario,
            "VP_IdSucursal": IN_IdSucursal //localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('auth/pa_get_sys_usuario_menu', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        const temp1: any = [];
        temp1.push(JSON.parse(record[0].MenuChecked[0]));
        setSelectedKeys3(temp1[0]);
    }


    const setVisibleConfirmDialog = () => {
        setSaveConfirmDialog(true);
    }

    const handleCancel = () => {
        // console.log(selectedKeys3);
        setSaveConfirmDialog(false);
    }

    const handleClickCancel = () => {
        navigate('/Auth');
    }

    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';

        // if (String(VP_Descripcion).trim() === '') {
        //     vl_message = 'Ingresar la descripcion';
        //     vl_valida = false;
        // } else if (String(VP_Tipo).trim() === '' || VP_Tipo === '0') {
        //     vl_message = 'Seleccionar tipo';
        //     vl_valida = false;
        // } else if (String(VP_IdEspecialidad).trim() === '' || VP_IdEspecialidad === '0') {
        //     vl_message = 'Seleccionar especialidad';
        //     vl_valida = false;
        // } else if (Number(VP_CostoServicio) === 0) {
        //     vl_message = 'Ingresar costo del servicio';
        //     vl_valida = false;
        // }

        return [vl_valida, vl_message];
    }

    const handleOnSubmitform = async () => {

        switch (vl_mode) {
            case 'I': case 'U':
                setVisibleConfirmDialog();
                const IsValidaCampos = onValidarCampos();
                const Isvalido = IsValidaCampos[0];
                const IsMessage = IsValidaCampos[1];
                if (!Isvalido) {
                    toast.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 9000 });
                    return;
                }
                break;
            default:
                break;
        }
        const vl_sys_usuario_permiso: any = []; //NA
        
        const param = {
            VP_action: vl_mode,
            VP_filter: '',
            VP_IdUsuario: VP_IdUsuario,
            VP_UserName: VP_UserName,
            VP_Email: VP_Email,
            VP_PassWord: VP_PassWord,
            VP_Flag: VP_Flag ? '1' : '0',
            VP_IdPerfil: VP_IdPerfil,
            VP_IdStaff: VP_IdStaff,
            VP_IdUsuario_1: localStorage.getItem('authIdUsuario'),
            VP_IdSucursal: vl_IdSucursal, //localStorage.getItem('authIdSucursal'),
            VP_sys_usuario_menu: JSON.stringify(selectedKeys3),
            VP_sys_usuario_permiso: JSON.stringify(vl_sys_usuario_permiso),
            VP_sys_usuario_sucursal: [],
            VP_IdSysUsuarioPermiso: VP_IdSysUsuarioPermiso,
            VP_IdMenu: 0
        };

        // console.log(param);
        // return;

        const response = await fetchSinToken('auth/pa_set_sys_usuario', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        if (record) {
            // console.log(record);
            const message = record.sql_message;
            toast.current.show({ severity: record.sql_error === 0 ? 'success' : 'error', summary: '::PSISABE', detail: message, life: 8000 });
            //RECARGAR
            if (record.sql_error === 0) {
                pa_get_auth_menu(vl_IdSucursal);
                pa_get_menu_asignado(vl_IdSucursal);
                pa_get_sys_usuario_menu(vl_IdSucursal);
            }

        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 3000 });
        }
        // console.log(resp)
    }




    const handleClickSysUsuarioPermiso = async () => {

        // console.log(VP_IdSysUsuarioPermiso);
        if (parseInt(vl_IdMenu) === 0) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar una opcion de menu', life: 9000 });
            return;
        }

        const vl_sys_usuario_permiso: any = [];
        vl_sys_usuario_permiso.push({
            'Crear': vl_Crear,
            'Actualizar': vl_Actualizar,
            'Eliminar': vl_Eliminar,
            'Imprimir': vl_Imprimir,
            'Anular': vl_Anular,
            'Restricciones': {
                'Visible': {
                    "Email": vl_RestricEmail,
                    "Telefono": vl_RestricFono,
                    "Direccion": vl_RestricDire
                }
            }
        });

        const param = {
            VP_action: 'P',
            VP_filter: '',
            VP_IdUsuario: VP_IdUsuario,
            VP_UserName: VP_UserName,
            VP_Email: '', //NA
            VP_PassWord: '',//NA
            VP_Flag: 0, //NA
            VP_IdPerfil: 0, //NA
            VP_IdStaff: 0, //NA
            VP_IdUsuario_1: localStorage.getItem('authIdUsuario'),
            VP_IdSucursal: vl_IdSucursal, //localStorage.getItem('authIdSucursal'),
            VP_sys_usuario_menu: [],//NA
            VP_sys_usuario_permiso: JSON.stringify(vl_sys_usuario_permiso),
            VP_sys_usuario_sucursal: [],//NA
            VP_IdSysUsuarioPermiso: VP_IdSysUsuarioPermiso,
            VP_IdMenu: vl_IdMenu
        };
        const response = await fetchSinToken('auth/pa_set_sys_usuario', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log(record);
        if (record) {
            const message = record.sql_message;
            toast.current.show({ severity: record.sql_error === 0 ? 'success' : 'error', summary: '::PSISABE', detail: message, life: 8000 });
            //RECARGAR
            if (record.sql_error === 0) {
                pa_get_auth_menu(vl_IdSucursal);
                pa_get_menu_asignado(vl_IdSucursal);
                pa_get_sys_usuario_menu(vl_IdSucursal);
            }

        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 3000 });
        }
    }

    return (
        <>

            <ConfirmDialog visible={b_confirmDialog} onHide={() => setSaveConfirmDialog(false)}
                message= { VP_IdPerfil !== VP_IdPerfilCurrent?'Seleccionó nuevo perfil, todo acceso será sustituido. ¿Seguro de continuar?': '¿Seguro de guardar cambios?' }  
                header="Confirmar..." className='font-bold font-italic'
                icon="pi pi-exclamation-triangle"
                accept={handleOnSubmitform}
                reject={handleCancel}
            />
            <Toast ref={toast} position="bottom-right"></Toast>

            <div className="flex justify-content-between pt-1 pl-3 border-bottom-2 p-3  ">
                <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
                    <div className="col mt-0 ml-0 text-base font-bold font-italic h-2rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='pb-1'>Configuración de usuarios </label>
                    </div>
                </div>
                <div className='p-1'>
                    <span className="p-buttonset">
                        <Button className="p-button-sm p-button-primary p-button-raised"
                            label="Grabar" disabled={disabledSaveAll}
                            icon="pi pi-save"
                            onClick={() => setVisibleConfirmDialog()}
                        />
                        <Button className="p-button-sm p-button-outlined p-button-danger ml-1 "
                            icon="pi pi-times"
                            tooltip='Cerrar'
                            onClick={() => {
                                navigate('/WelcomePage');
                            }}
                        />
                    </span>
                </div>
            </div>
            <div className='flex justify-content-between pb-2  ' >
                <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-2 pl-2 ">
                    <span className="p-buttonset">
                        <Button icon="pi pi-arrow-left" label='Volver'
                            className="p-button-rounded p-button-warning p-button-raised"
                            onClick={() => handleClickCancel()}
                        />
                    </span>
                </div>
                <div className='pb-1 pt-2 pl-2' >
                    <label className="p-col-fixed ml-1 mr-1 text-base font-semibold ">Sede:</label>
                    <Dropdown style={{ width: '12rem' }} showClear
                        value={parseInt(vl_IdSucursal)}
                        options={dataSede}
                        disabled={disabledIdSucursal}
                        onChange={(e: any) => {
                            setIdSucursal(e.value);
                            pa_get_auth_menu(e.value);
                            pa_get_menu_asignado(e.value);
                            pa_get_sys_usuario_menu(e.value);
                        }}
                        optionValue="IdSucursal"
                        optionLabel="Descripcion"
                        placeholder="Selecionar" />
                </div>
            </div>
            <div className="flex align-items-stretch flex-nowrap justify-content-center p-2 ">
                <div className='card p-1 '>
                    <TabView className="tabview-custom" activeIndex={activeIndex1}
                        onTabChange={(e) => {
                            // console.log(e.index);
                            setActiveIndex1(e.index);
                            if (e.index === 0) {
                                setDisabledIdSucursal(true);
                                setDisabledSaveAll(false);
                            }
                            if (e.index === 1) {
                                setDisabledIdSucursal(false);
                                setDisabledSaveAll(false);
                            }
                            if (e.index === 2) {
                                setDisabledIdSucursal(false);
                                setDisabledSaveAll(true);
                            }
                        }}
                    >
                        <TabPanel header="Datos de usuario" leftIcon="pi pi-user-edit pr-2">
                            <div className="flex flex-wrap card-container w-full">
                                <div className="flex align-items-center justify-content-center m-1">
                                    <label className="p-col-fixed  mr-1 font-bold text-xl " style={{ width: '80px', textAlign: 'end' }}>#ID:</label>
                                    <InputText type="text" style={{ width: '5rem' }} className="font-bold text-primary-800 text-center bg-gray-200"
                                        value={VP_IdUsuario} readOnly
                                    />
                                    <label className="p-col-fixed  mr-1 ml-1 font-bold text-sm " style={{ textAlign: 'end' }}>Trabajador:</label>
                                    <InputText type="text" style={{ width: '18rem' }} className="font-semibold text-normal text-left bg-gray-200"
                                        value={VL_Staff} readOnly
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap card-container w-full">
                                <div className="flex align-items-center justify-content-center m-1">
                                    <label className="p-col-fixed  mr-1  font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Sede:</label>
                                    <InputText type="text" style={{ width: '10rem' }} className="font-semibold text-normal text-left bg-gray-200"
                                        value={VL_Sede} readOnly
                                    />
                                    <label className="p-col-fixed  mr-1 ml-1 font-bold text-sm " style={{ textAlign: 'end' }}>Cargo:</label>
                                    <InputText type="text" style={{ width: '15rem' }} className="font-semibold text-normal text-left bg-gray-200"
                                        value={VL_Cargo} readOnly
                                    />
                                </div>
                            </div>
                            <div className="flex flex-wrap card-container w-full">
                                <div className="flex align-items-center justify-content-center m-1">
                                    <label className="p-col-fixed mr-1  font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Usuario:</label>
                                    <InputText type="text" style={{ width: '15rem' }} className="font-semibold text-normal text-left bg-gray-200"
                                        value={VP_UserName} readOnly
                                    />
                                    <label className="p-col-fixed ml-1 mr-1 " style={{ textAlign: 'end' }}>Activo</label>
                                    <InputSwitch checked={VP_Flag} onChange={(e) => setFlag(e.value)} />
                                </div>
                            </div>
                            <div className="flex flex-wrap card-container w-full">
                                <div className="flex align-items-center justify-content-center m-1">

                                    <label className="p-col-fixed  mr-1  font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Perfil:</label>
                                    <Dropdown style={{ width: '15rem' }}
                                        // filter filterBy="Descripcion"
                                        value={VP_IdPerfil}
                                        options={dataPerfil}
                                        onChange={(e: any) => {
                                            setIdPerfil(e.value);
                                        }}
                                        optionValue="IdPerfil"
                                        optionLabel="Descripcion"
                                        placeholder="Seleccionar" />

                                </div>
                            </div>
                            <div className="flex flex-wrap card-container w-full">
                                <div className="flex align-items-center justify-content-center m-1">
                                    <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Email:</label>
                                    <InputText type="email" style={{ width: '20rem' }} className="font-semibold text-normal text-left"
                                        value={VP_Email}
                                        onChange={(e) => {
                                            setEmail(e.currentTarget.value);
                                        }}
                                    />

                                </div>
                            </div>
                            <div className="flex flex-wrap card-container w-full">
                                <div className="flex align-items-center justify-content-center m-1">
                                    <label className="p-col-fixed  mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Clave:</label>
                                    <InputText type="password" style={{ width: '15rem' }} className="font-normal text-xs text-left bg-gray-200"
                                        value={VP_PassWord} readOnly
                                    />
                                </div>
                            </div>
                            {/* <div className="flex flex-wrap card-container w-full ">
                                <div className="flex align-items-center justify-content-center m-1">
                                    <Button
                                        type="button"
                                        className="p-button-sm p-button-link "
                                        icon="pi pi-key"
                                        label='Cambiar contraseña'
                                        onClick={() => {
                                            navigate('/Auth/Change/Password/' + IdUsuario);
                                        }}
                                    />
                                </div>
                            </div> */}

                        </TabPanel>
                        <TabPanel header="Permisos a menu" leftIcon="pi pi-bars pr-2">
                            <label className='text-blue-500 text-sm p-2' >Asignar acceso a opciones del sistema ({VP_UserName})</label>

                            <Tree
                                value={dataAuthMenu}
                                selectionMode="checkbox"
                                selectionKeys={selectedKeys3}
                                onSelect={(e: any) => {
                                    // console.log(e);
                                }}
                                onSelectionChange={(e: any) => {
                                    // console.log(e);
                                    for (var i in e.value) {
                                        // obj.hasOwnProperty() se usa para filtrar propiedades de la cadena de prototipos del objeto
                                        if (e.value.hasOwnProperty(i)) {                                            
                                            e.value[i].IdMenu = i;      
                                            //   result += `${objName}.${i} = ${obj[i]}\n`;                                      
                                        }
                                    }
                                    // console.log(e.value);
                                    setSelectedKeys3(e.value);
                                }}
                            />
                        </TabPanel>
                        <TabPanel header="Privilegios" leftIcon="pi pi-lock pr-2">
                            <div className="flex flex-wrap justify-content-center text-base ">
                                <div className='card p-2 mr-2'>
                                    <label className='text-blue-500 text-sm p-2' >Accesos habilitados.</label>
                                    <Tree
                                        value={dataAuthMenuAsignado}
                                        selectionMode="single"
                                        // selectionKeys={selectedKeys3}
                                        onSelect={(e: any) => {
                                            console.log(e.node);
                                            setIdMenu(e.node.key);
                                            setMenu(e.node.label);
                                            pa_get_sys_usuario_permiso(e.node);

                                        }}
                                        onSelectionChange={e => {
                                            // console.log(e.value);
                                            // setSelectedKeys3(e.value);                                    
                                        }}
                                    />
                                </div>
                                <div className='card p-2' >
                                    <div className='pb-2' >
                                        <label className='text-blue-500 text-normal p-2'>Asignar permisos a usuario {VP_UserName}</label>
                                    </div>
                                    <div className="p-col mb-1 " >
                                        <label className="p-col-fixed  mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Menu:</label>
                                        <InputText type="text" style={{ width: '4rem', textAlign: 'left' }}
                                            className="font-semibold text-normal text-left bg-gray-200" readOnly
                                            value={vl_IdMenu}
                                        />
                                        <label className="p-col-fixed ml-1 " style={{ textAlign: 'center' }}></label>
                                        <InputText type="text" style={{ width: '15rem', textAlign: 'left' }}
                                            className="font-semibold text-normal text-left bg-gray-200" readOnly
                                            value={vl_Menu}
                                        />
                                    </div>
                                    <div className='pb-2' >
                                        <label className='text-blue-500 text-normal p-2'>Acciones que puede hacer:</label>
                                    </div>
                                    <div className="flex flex-wrap card-container w-full">
                                        <div className="flex align-items-center justify-content-center m-1">
                                            <label className="p-col-fixed ml-1 mr-1 " style={{ width: '120px', textAlign: 'end' }}>Grabar</label>
                                            <InputSwitch checked={vl_Crear} onChange={(e: any) => setCrear(e.value)} />
                                            <Button icon="pi pi-save " tooltip='Crear registros' className="p-button-primary p-button-outlined p-button-rounded ml-2 " />
                                            <label className="p-col-fixed ml-1 mr-1 " style={{ width: '120px', textAlign: 'end' }}>Actualizar</label>
                                            <InputSwitch checked={vl_Actualizar} onChange={(e: any) => setActualizar(e.value)} />
                                            <Button icon="pi pi-refresh" tooltip='Modificar registros' className="p-button-warning p-button-outlined p-button-rounded ml-2 " />
                                        </div>

                                    </div>
                                    <div className="flex flex-wrap card-container w-full">
                                        <div className="flex align-items-center justify-content-center m-1">
                                            <label className="p-col-fixed ml-1 mr-1 " style={{ width: '120px', textAlign: 'end' }}>Eliminar</label>
                                            <InputSwitch checked={vl_Eliminar} onChange={(e: any) => setEliminar(e.value)} />
                                            <Button icon="pi pi-times" tooltip='Eliminar registros' className="p-button-danger p-button-outlined p-button-rounded ml-2 " />
                                            <label className="p-col-fixed ml-1 mr-1 " style={{ width: '120px', textAlign: 'end' }}>Imprimir</label>
                                            <InputSwitch checked={vl_Imprimir} onChange={(e: any) => setImprimir(e.value)} />
                                            <Button icon="pi pi-print" tooltip='Imprimir registros' className="p-button-help p-button-outlined p-button-rounded ml-2 " />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap card-container w-full">
                                        <div className="flex align-items-center justify-content-center m-1">
                                            <label className="p-col-fixed ml-1 mr-1 " style={{ width: '120px', textAlign: 'end' }}>Anular</label>
                                            <InputSwitch checked={vl_Anular} onChange={(e: any) => setAnular(e.value)} />
                                            <Button icon="pi pi-trash" tooltip='Anular registros' className="p-button-danger p-button-outlined p-button-rounded ml-2 " />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap card-container">
                                        <div className="flex align-items-center justify-content-start m-1  w-full">
                                            <label className="p-col-fixed mr-2 text-red-600 ">Información sensible (casos donde aplique). </label>
                                        </div>
                                        <div className="flex align-items-center justify-content-center m-1">
                                            <label className="p-col-fixed mr-2 " style={{ textAlign: 'end' }}>Visible </label>
                                            <Checkbox className="ml-0" onChange={e => { setRestricEmail(e.checked); }} checked={vl_RestricEmail}></Checkbox>
                                            <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Email</label>
                                            <Checkbox className="ml-0" onChange={e => { setRestricFono(e.checked); }} checked={vl_RestricFono}></Checkbox>
                                            <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}># Teléfono </label>
                                            <Checkbox className="ml-0" onChange={e => { setRestricDire(e.checked); }} checked={vl_RestricDire}></Checkbox>
                                            <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Dirección</label>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap card-container w-full mt-2">
                                        <div className="flex align-items-center justify-content-center m-1">
                                            <Button icon="pi pi-key" label='Grabar'
                                                className="p-button-rounded p-button-warning p-button-raised"
                                                onClick={() => handleClickSysUsuarioPermiso()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>


        </>
    )
}

export default AuthUsuarioForm;
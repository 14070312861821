import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const HeaderOptions = ({ ...props }) => {
    // console.log('HeaderOptions');
    // console.log(props);

    return (
        <>
            <div className="p-field p-grid pl-1 pr-1 pt-1 pb-2 ">
                <div className="p-col">
                    <label className="p-col-fixed mr-2 font-bold " style={{ width: '180px', textAlign: 'end' }}>N° HC:</label>
                    <InputText type="text" style={{ width: '100px', textAlign: 'center' }}
                        maxLength={8} autoFocus
                        value={props.NumeroHistoriaClinica}
                        onChange={(e: any) => props.setNumeroHistoriaClinica(String(e.currentTarget.value).toUpperCase())}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                props.getHistoriaClinica(String(e.currentTarget.value));
                            }
                        }}
                    />
                    <Button label=""
                        onClick={(e: any) => props.getHistoriaClinica(props.NumeroHistoriaClinica)}
                        icon="pi pi-search"
                        className="p-button p-button-rounded ml-2 mr-2"
                    />
                    <Button label="" tooltip='Buscar Paciente/Cliente'
                        onClick={(e: any) => props.handleClickBuscar(e)}
                        icon="pi pi-user-plus" className="p-button-rounded mt-0 p-button-warning mr-2 "
                    />
                    {/* <span className="p-buttonset">
                        <Button
                            label="Guardar"
                            icon="pi pi-save"
                            onClick={(e) => props.setConfirmarGrabarHistoriaClinica(e)}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                        <Button
                            label="Imprimir"
                            icon="pi pi-print"
                            onClick={(e: any) => props.setImprimirHistoriaClinica(e)}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                    </span>                     */}
                </div>
            </div>
        </>

    )

};

export default HeaderOptions;

import { Routes, Route } from 'react-router-dom';
import HomeLayout from '../views/containers/home/HomeLayout';
import AdmisionView from '../views/containers/admision/AdmisionView';
import OrdenAtencionView from '../views/containers/admision/OrdenAtencionView';
import OrdenTerapiaView from '../views/containers/admision/OrdenTerapiaView';
import AgendaView from '../views/containers/agenda/AgendaView';
import HistoriaClinicaView from '../views/containers/paciente/HistoriaClinicaView';
import CajaView from '../views/containers/caja/CajaView';
import OrdenPagoView from '../views/containers/ordenPago/OrdenPagoView';
import DerivacionPacienteView from '../views/containers/admision/DerivacionPacienteView';
import CajaCierreView from '../views/containers/caja/CajaCierreView';
import CronologiaView from '../views/containers/paciente/CronologiaView';
import StaffView from '../views/containers/catalogo/StaffView';
import Index from '../views/containers/index/Index';
import NoMatchPage from '../views/containers/index/NoMatchPage';
import DashboardView from '../views/containers/dashboard/DashboardView';
import ServicioView from '../views/containers/catalogo/ServicioView';
import AuthView from '../views/containers/auth/AuthView';
import AuthUsuarioForm from '../views/components/auth/AuthUsuarioForm';
import WelcomePage from '../views/containers/index/WelcomePage';
import AuthUsuarioChangePassword from '../views/components/auth/AuthUsuarioChangePassword';
import CajaComisionesView from '../views/containers/caja/CajaComisionesView';
import CajaPagoComisionView from '../views/containers/caja/CajaPagoComisionView';
import CajaEgresosView from '../views/containers/caja/CajaEgresosView';
import EgresoForm from '../views/components/caja/egreso/EgresoForm';
import CajaVentasView from '../views/containers/caja/CajaVentasView';
import RegistroVentaForm from '../views/components/caja/ingreso/RegistroVentaForm';
import PacienteDocumentInforme from '../views/components/paciente/PacienteDocumentInforme';
import PacientePlanEvaluacion from '../views/components/paciente/PacientePlanEvaluacion';
import PacientePlanTerapia from '../views/components/paciente/PacientePlanTerapia';
import ReporteCajaView from '../views/containers/reportes/caja/ReporteCajaView';
import ReporteComisionView from '../views/containers/reportes/comision/ReporteComisionView';
import ReporteAdmisionView from '../views/containers/reportes/admision/ReporteAdmisionView';
import ClienteView from '../views/containers/catalogo/ClienteView';
import ClientesAnulados from '../views/containers/catalogo/ClientesAnulados';
import ReporteAgendaView from '../views/containers/reportes/agenda/ReporteAgendaView';
import PerfilView from '../views/components/auth/PerfilView';

const AppDashboardRoutes = () => {
    // console.log('AppDashboardRoutes');
    return (
        <>
            <HomeLayout>
                <Routes>
                    <Route path="/WelcomePage" element={<WelcomePage />} />
                    <Route path="/DashboardView" element={<DashboardView />} />
                    <Route path="/AdmisionView" element={<AdmisionView />} />
                    <Route path="/OrdenAtencionView" element={<OrdenAtencionView />} />
                    <Route path="/OrdenTerapiaView" element={<OrdenTerapiaView />} />
                    <Route path="/OrdenPagoView" element={<OrdenPagoView />} />
                    <Route path="/DerivacionPacienteView" element={<DerivacionPacienteView />} />
                    <Route path="/DerivacionPaciente/:id" element={<DerivacionPacienteView />} />
                    <Route path="/AgendaView" element={<AgendaView />} />
                    <Route path="/CajaView" element={<CajaView />} />
                    <Route path="/CajaCierreView" element={<CajaCierreView />} />
                    {/* Comisiones */}
                    <Route path="/ComisionesView" element={<CajaComisionesView />} />
                    <Route path="/CajaPagoComisionView/:id/:mode" element={<CajaPagoComisionView />} />
                    
                    <Route path="/CajaEgresosView" element={<CajaEgresosView />} />
                    <Route path="/CajaEgresosForm/:id/:mode" element={<EgresoForm />} />

                    <Route path="/CajaVentasView" element={<CajaVentasView />} />
                    <Route path="/RegistroVentaForm/:id/:mode" element={<RegistroVentaForm />} />

                    <Route path="/CronologiaView" element={<CronologiaView />} />
                    <Route path="/StaffView" element={<StaffView />} />
                    <Route path="/ServicioView" element={<ServicioView />} />
                    <Route path="/AccountHome" element={<Index />} />
                    {/*Catalogo*/}
                    <Route path="/Auth" >
                        <Route path="/Auth" element={<AuthView />} />
                        <Route path=":id/:mode" element={<AuthUsuarioForm />} />
                    </Route>
                    <Route path="Auth/Change/Password/:id" element={<AuthUsuarioChangePassword />} />
                    <Route path="/PerfilView" element={<PerfilView />} />
                    <Route path="/ClientesView" element={<ClienteView />} />
                    <Route path="/ClientesAnulados" element={<ClientesAnulados />} />
                    
                    {/* formularios HC */}
                    <Route path="/HistoriaClinicaView" element={<HistoriaClinicaView />} />
                    <Route path="/HistoriaClinicaView/:id/:activeIndex" element={<HistoriaClinicaView />} />
                    <Route path="HistoriaClinicaForm/Informe/:id/:mode/:IdInforme" element={<PacienteDocumentInforme />} />
                    <Route path="HistoriaClinicaForm/PlanEvaluacion/:id/:mode" element={<PacientePlanEvaluacion />} />
                    <Route path="HistoriaClinicaForm/PlanTerapia/:id/:mode" element={<PacientePlanTerapia />} />
                    {/* reportes*/}
                    <Route path="/ReporteCajaView" element={<ReporteCajaView />} />
                    <Route path="/ReporteComisionView" element={<ReporteComisionView />} />
                    <Route path="/ReporteAdmisionView" element={<ReporteAdmisionView />} />
                    <Route path="/ReporteAgendaView" element={<ReporteAgendaView />} />
                    
                    <Route path="*" element={<NoMatchPage />} />
                    <Route path="/" element={<Index />} />

                </Routes>

            </HomeLayout>
        </>
    )
};

export default AppDashboardRoutes;
import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AppContext01 } from '../../../context/AppContext';
import { fetchSinToken } from '../../../helpers/fetch';
import useModal from '../../../context/WindowsOpen';
import ServicioForm from '../../components/catalogo/ServicioForm';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FooterGridNumber } from '../../components/util/FooterGrid';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import '../../../assets/scss/_dataView.scss';
import { setIntlDateTimeFormat } from '../../../helpers/functions';

const CajaVentasView = () => {
    const [vl_filter, setFilter] = useState<any>('');
    const [vp_tipo, setTipo] = useState<any>('E');
    const [vl_FechaDesde, setvalFechaDesde] = useState<any>(new Date());
    const [vl_FechaHasta, setvalFechaHasta] = useState<any>(new Date());
    const [vl_IdTipoDocumento_1, setIdTipoDocumento_1] = useState<any>('');

    const [dataVentas, setdataVentas] = useState<any>([]);
    const [selectedGrid, setSelectedGrid] = useState<any>([]);
    const [dataIdTipoDocumento_1, setDataIdTipoDocumento_1] = useState([]);

    const [isMode, setMode] = useState('I');
    const isMounted = useRef(false);
    const [isOpenModal, openModal, closeModal] = useModal();
    const toast = useRef<any>(null);

    // const [layout, setLayout] = useState('grid');
    const [selectedCategory, setSelectedCategory] = useState<any>('');

    const navigate = useNavigate();
    useEffect(() => {
        handleSetTitle();
        pa_get_tipo_comprobante();
        pa_get_registro_venta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/Registro de ventas');
    }

    const pa_get_tipo_comprobante = async () => {
        const param = {
            "VP_Grupo": "V"
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_comprobante', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataIdTipoDocumento_1(record);
    }
    const pa_get_registro_venta = async () => {
        const param = {
            "vp_NumeroDocumento": '',
            "vp_NumeroHistoria": '',
            "vp_FechaDesde": setIntlDateTimeFormat(vl_FechaDesde),
            "vp_FechaHasta": setIntlDateTimeFormat(vl_FechaHasta),
            "vp_IdTipoDocumento": '',
            "vp_Cliente": vl_filter,
            "vp_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('caja/pa_get_registro_venta', param, 'POST');
        const resp = await response.json();

        // console.log(resp.data.errno);
        if (!resp.data.errno) {
            setdataVentas(resp.data);
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
        }

    }


    const handleClickNew = () => {
        setMode('I');
        isMounted.current = true;
        setSelectedGrid([]);
        openModal();
    }
    const handleClickEdit = () => {
        if (Object.keys(selectedGrid).length <= 0) {
            const message = 'seleccionar un item';
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
            return;
        }
        setMode('U');
        isMounted.current = true;
        openModal();
    }

    const handleSetIsMounted = (val: boolean) => {
        isMounted.current = val;
    }

    const template_NumberCurrency = (item: any) => {
        return (
            <>
                <label className='text-color-green-400'>${item.Total}</label>
            </>
        );
    }

    const footer = () => {
        let vl_Importe: any = 0;
        let vl_Total: any = 0;
        let vl_igv: any = 0;
        dataVentas.forEach(
            function (currentValue: any) {
                vl_Importe = vl_Importe + parseFloat(currentValue.Importe);
                vl_Total = vl_Total + parseFloat(currentValue.Total);
                vl_igv = vl_igv + parseFloat(currentValue.Impuesto);

            }
        );
        return (
            <>
                <div className='flex flex-wrap justify-content-end' >
                    <FooterGridNumber Total={vl_Importe} Title={'Importe'} />
                    <FooterGridNumber Total={vl_igv} Title={'IGV'} />
                    <FooterGridNumber Total={vl_Total} Title={'Total'} />
                </div>
            </>
        );
    }

    //LINKS
    const ingresoForm = () => {
        navigate('/RegistroVentaForm/0/New');
    }


    const link_form_close = () => {
        navigate('/WelcomePage');
    }

    return (
        <>
            <>
                {isMounted.current && <ServicioForm
                    isMode={isMode}
                    isOpen={isOpenModal}
                    isHide={closeModal}
                    isMounted={handleSetIsMounted}
                    isData={selectedGrid}
                    isSetSelectedGrid={setSelectedGrid}
                    setSelectedCategory={setSelectedCategory}
                    isCallfn={pa_get_registro_venta} />}
            </>
            <Toast ref={toast} position="top-center"></Toast>

            <div className="flex justify-content-between pt-1 pl-3 ">
                <div className='md:border-solid border-blue-500 shadow-1 m-2 flex align-items-center justify-content-center p-1' >
                    <label className='text-base font-italic font-bold '> * Registro y control de ventas</label>
                </div>
                <div>
                    <span className="p-buttonset">
                        <Button
                            label="Nuevo"
                            icon="pi pi-file"
                            onClick={() => ingresoForm()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                        <Button
                            label="Editar"
                            icon="pi pi-pencil"
                            // onClick={() => handleClickEdit()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                        <Button
                            label="Reporte"
                            icon="pi pi-print"
                            // onClick={() => handleClickEdit()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                    </span>
                    <Button
                        icon="pi pi-times"
                        onClick={() => link_form_close()}
                        tooltip="Cerrar"
                        // className="p-button-rounded p-button-warning   p-button-outlined ml-2 "
                        className="p-button-rounded p-button-danger p-button ml-1" aria-label="Cancel"
                    />
                </div>
            </div>

            <div className="card-container gray-container p-1 pt-4 ">
                <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Tipo:</label>
                <Dropdown style={{ width: '10rem' }}
                    // filter filterBy="Descripcion"
                    value={vl_IdTipoDocumento_1}
                    options={dataIdTipoDocumento_1}
                    onChange={(e: any) => {
                        setIdTipoDocumento_1(e.value);
                    }}
                    optionValue="Codigo"
                    optionLabel="Descripcion"
                    placeholder="Seleccionar" />
                <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}></label>
                <InputText type="search" style={{ width: '8rem', textAlign: 'left' }}
                    autoFocus
                    placeholder='Nº ...'
                    value={vl_filter}
                    onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            pa_get_registro_venta();
                        }
                    }}
                />    
                <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>De:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                    dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                    maxDate={new Date()}
                    value={vl_FechaDesde}
                    onChange={(e) => setvalFechaDesde(e.value)}
                />
                <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>Al:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                    dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                    maxDate={new Date()}
                    value={vl_FechaHasta}
                    onChange={(e) => setvalFechaDesde(e.value)}
                />
                <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}></label>
                <InputText type="search" style={{ width: '12rem', textAlign: 'left' }}
                    autoFocus
                    placeholder='Buscar por Cliente'
                    value={vl_filter}
                    onChange={(e: any) => setFilter(String(e.currentTarget.value).toUpperCase())}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            pa_get_registro_venta();
                        }
                    }}
                />
                <Button label=""
                    onClick={(e: any) => pa_get_registro_venta()}
                    icon="pi pi-search"
                    tooltip='Buscar '
                    className="p-button-rounded ml-2"
                />
            </div>

            <div className="card-container gray-container">
                <DataTable size="small" resizableColumns
                    footer={footer}
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    value={dataVentas}
                    dataKey="IdRegistroVenta"
                    selectionMode="single"
                    // selection={selectedGrid}
                    // onSelectionChange={e => setSelectedGrid(e.value)}
                    scrollHeight="400px"
                    paginator
                    rows={10}
                >
                    <Column field="IdRegistroVenta" header="Id" style={{ maxWidth: '70px' }}   ></Column>
                    <Column field="IdTipoDocumento" header="Tipo" style={{ maxWidth: '75px' }}  ></Column>
                    <Column field="NumeroDocumento" header="Nº Documento" style={{ maxWidth: '130px' }}  ></Column>
                    <Column field="Fecha_1" header="F. Emisión" style={{ maxWidth: '100px' }}   ></Column>
                    <Column field="Cliente" header="Cliente"  ></Column>
                    <Column field="Importe" header="Importe" style={{ maxWidth: '100px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    // body={template_NumberCurrency}
                    >
                    </Column>
                    <Column field="Impuesto" header="Impuesto" style={{ maxWidth: '100px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    // body={template_NumberCurrency}
                    >
                    </Column>
                    <Column field="Descuento" header="Descto." style={{ maxWidth: '90px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    // body={template_NumberCurrency}
                    >
                    </Column>
                    <Column field="Total" header="Total" style={{ maxWidth: '110px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    // body={template_NumberCurrency}
                    >
                    </Column>

                    <Column field="Estado_1" header="Estado" style={{ maxWidth: '120px' }}   ></Column>
                </DataTable>
            </div>

        </>
    )
}

export default CajaVentasView;
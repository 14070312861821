import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext, AppContext01 } from '../../../context/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { fetchSinToken } from '../../../helpers/fetch';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { AppAuthContext } from '../../../context/AppAuthContext';
import { Sidebar } from 'primereact/sidebar';
import { getClaseName } from '../../../helpers/functions';
import { ListBox } from 'primereact/listbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';

const NavbarTitleMenu = (param: any) => {

  // console.log(param);
  return (
    // background: '#F48884'       
    <div style={{ width: '50%', margin: '5%' }}
      className="absolute bottom-1 left-1 border-round p-2 font-bold text-white">
      <h5>{`${param.itemMenuTitle}`}</h5>
    </div>
  );
};

const HeaderLayout = () => {

  const { logout } = useContext(AppAuthContext);
  const navigate = useNavigate();
  const { appState, showSidebar } = useContext(AppContext);
  const { appmenuTitle } = useContext(AppContext01);
  const ItemMenuTitle = appmenuTitle.isVisibleTitle;

  const authIdSucursal = localStorage.getItem('authIdSucursal');
  const authDescripcionSucursal = localStorage.getItem('authDescripcionSucursal');
  const [vl_confirmDialog, setConfirmDialog] = useState<boolean>(false);

  const [vl_TtlOrdenSesion, setTtlOrdenSesion] = useState<any>(0);
  const [vl_TtlCRealizada, setTtlCRealizada] = useState<any>(0);
  const [vl_TtlSPendiente, setTtlSPendiente] = useState<any>(0);
  // mensaje
  const toast = useRef<any>(null);

  //Alertas
  const [dataAlertas, setDataAlertas] = useState([]);
  const [visibleTop, setVisibleTop] = useState<boolean>(false);
  const [vl_Notificacion, setNotificacion] = useState<any>('');
  const [vl_NotificacionStyle, setNotificacionStyle] = useState<any>('');
  const [VL_MainNotify, setMainNotify] = useState<boolean>(false);


  useEffect(() => {
    //call init
    const periodo: any = new Date().getFullYear();
    pa_get_notificacion_staff('1', periodo);
    handleClickAlertarView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleBtn = (e: React.FormEvent) => {
    e.preventDefault();
    showSidebar(!appState.isVisibleSidebar);
  }

  const handleLogout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('auhtUser');
    window.localStorage.removeItem('authIdUsuario');
    logout(false);
    navigate('/login');
  }

  const setVisibleConfirmDialog = () => {
    setConfirmDialog(true);
  }

  const handleCancel = () => {
    setConfirmDialog(false);
  }

  const pa_get_notificacion_staff = async (in_opcion: any, in_periodo: any) => {
    const param = {
      "VP_OPCION": in_opcion,
      "VP_IdStaff": localStorage.getItem('authIdStaff'),
      "VP_periodo": in_periodo
    }
    const response = await fetchSinToken('core/pa_get_notificacion_staff', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    // console.log(record);
    if (!resp.data.errno) {
      setTtlOrdenSesion(record[0].TtlSesion);
      setTtlCRealizada(record[0].TtlCRealizada);
      setTtlSPendiente(record[0].TtlSPendiente);
    } else {
      const message =
        'Code: ' + resp.data.code + '</n>' +
        'Errno: ' + resp.data.errno + '<br>' +
        'Sql: ' + resp.data.sql + '<br>' +
        'SqlState: ' + resp.data.sqlState + '<br>' +
        'SqlMessage: ' + resp.data.sqlMessage + '<br>';
      toast.current.show({ severity: 'error', summary: 'Error Message', detail: message, sticky: false });
    }
  }

  const handleClickAlertarView = async () => {
    const param = {
      "VP_IdCliente": 0,
      "VP_IdNotificacion": 0,
      "VP_Estado": 'A'
    }
    const response = await fetchSinToken('catalogo/pa_get_alertas_main', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    // console.log(record);
    if (record.length > 0 && localStorage.getItem('mainNotify') === 'Y') {
      setVisibleTop(true);
      setDataAlertas(record);
    }
  }


  return (

    <header className="header header-sticky mb-1 mr-0 bg-indigo-900 ">
      <div className="container-fluid">
        <button className="header-toggler px-md-0 me-md-3" style={{ color: '#ffff' }} type="button"
          onClick={handleBtn}>
          <FontAwesomeIcon icon={['fas', 'bars']} style={{ marginRight: '5px' }} />
        </button>

        <NavbarTitleMenu
          itemMenuTitle={ItemMenuTitle}
        />

        <ul className="header-nav">
          <div className="p-field p-grid mb-3">
            <i className='pi pi-home mr-4 text-white' style={{ fontSize: '1rem' }} > {' Ofic. ' + authDescripcionSucursal}  </i>
            <i className="pi pi-bell mr-3 mt-2 p-text-secondary p-overlay-badge" title='Total de Sesiones' style={{ fontSize: '1.6rem' }}><Badge value={vl_TtlOrdenSesion} ></Badge></i>
            <i className="pi pi-calendar mr-3 mt-2  p-text-secondary p-overlay-badge" title='Sesiones Realizadas' style={{ fontSize: '1.6rem' }}><Badge value={vl_TtlCRealizada} severity="success" ></Badge></i>
            <i className="pi pi-circle-off mt-2 p-text-secondary p-overlay-badge" title='Sesiones Pendientes' style={{ fontSize: '1.6rem' }}><Badge value={vl_TtlSPendiente} severity="danger"></Badge></i>
            <i className="pi pi-sign-out mr-0 ml-4 mt-2 p-text-secondary p-overlay-badge cursor-pointer" onClick={setVisibleConfirmDialog} style={{ fontSize: '2rem' }}></i>
          </div>
        </ul>
      </div>
      <ConfirmDialog visible={vl_confirmDialog} onHide={() => setConfirmDialog(false)}
        message="¿Cerrar sesión de usuario?"
        header="Log out"
        icon="pi pi-lock"
        accept={handleLogout}
        reject={handleCancel}
      />
      <Toast ref={toast} position="top-center"></Toast>

      <Sidebar visible={visibleTop} closeOnEscape position="left" onHide={() => setVisibleTop(false)} className={vl_NotificacionStyle}  >
        <h4>Alertas</h4>
        <div className="field-checkbox">
          <Checkbox title='Estas alertas se van a volver a mostrar en el siguiente inicio de sesión'
            onChange={(e) => {
              setMainNotify(e.checked);
              if (e.checked) {
                window.localStorage.removeItem('mainNotify');
                setVisibleTop(false);
              }
            }} checked={VL_MainNotify}></Checkbox>
          <label className="p-checkbox-label text-xm font-normal " style={{ textAlign: 'end' }} >Dejar de mostrar </label>
        </div>

        <DataTable size="small" resizableColumns style={{ width: '100%' }}
          responsiveLayout="scroll"
          columnResizeMode="fit"
          scrollable
          value={dataAlertas}
          scrollHeight="80vh"
          dataKey="IdNotificacion"
          selectionMode="single"
        >
          <Column field="Paciente" bodyClassName="white-space-normal"
            body={(item: any) => {
              return (
                <div className='p-1 m-1 w-full ' >
                  <label className={`${item.Estado === 'A' ? getClaseName(item.Categoria) : ''} mb-1 `}>{' ' + item.Paciente}</label>
                  {/* <p className='pt-2' >{item.Mensaje.mensaje}</p> */}
                  <InputTextarea rows={7} cols={50} 
                    style={{ width: '100%' }} readOnly
                    value={item.Mensaje.mensaje}
                  />
                </div>
              );
            }}
          ></Column>

        </DataTable>
      </Sidebar>
    </header>

  )
}

export default HeaderLayout

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';
import TitleForm from '../../util/TitleForm';
import { fetchSinToken } from '../../../../helpers/fetch';
import { InputText } from 'primereact/inputtext';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';


export const DetalleCuotas = ({ ...props }) => {

    const [dataCuotas, setDataCuotas] = useState<any>([]);
    const [dataDetalleCuotas, setDataDetalleCuotas] = useState<any>([]);

    let vl_IdOrdenTerapia: any = props.IdOrdenTerapia ? props.IdOrdenTerapia : 0;
    let vl_IdRegistro: any = props.IdRegistro ? props.IdRegistro : 0;
    let vl_IdOrdenPago: any = props.IdOrdenPago ? props.IdOrdenPago : 0;

    useEffect(() => {
        // console.log(props);        
        // console.log(props.TipoRegistro);
        switch (props.TipoRegistro) {
            case "A":
                pa_get_orden_atencion_cuota();
                break;
            case "T":
                pa_get_orden_terapia_cuota();
                break;
            default:
                pa_get_orden_atencion_cuota();
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_orden_atencion_cuota = async () => {
        const param = {
            "VP_IdOrdenPago": vl_IdOrdenPago
        }
        const response = await fetchSinToken('admision/pa_get_orden_pago_cuota', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataCuotas(record);
    }

    const pa_get_orden_atencion_cuota_detalle = async (IN_IdOrdenPago: any) => {
        const param = {
            "VP_IdOrdenPago": IN_IdOrdenPago
        }
        const response = await fetchSinToken('admision/pa_get_orden_pago_cuota_detalle', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataDetalleCuotas(record);
    }


    const pa_get_orden_terapia_cuota = async () => {
        // console.log('vl_IdRegistro:' + vl_IdRegistro);
        const param = {
            "VP_Opcion": 'C',
            "VP_IdRegistro": vl_IdRegistro,
            "VP_IdOrdenTerapia": vl_IdOrdenTerapia,
            "VP_IdOrdenTerapiaCuota": 0
        }
        const response = await fetchSinToken('admision/pa_get_orden_terapia_cuota', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataCuotas(record);
    }
    const pa_get_orden_terapia_cuota_detalle = async (IN_IdOrdenTerapiaCuota: any) => {
        // console.log('vl_IdRegistro:' + vl_IdRegistro);
        const param = {
            "VP_Opcion": 'D',
            "VP_IdRegistro": 0,
            "VP_IdOrdenTerapia": 0,
            "VP_IdOrdenTerapiaCuota": IN_IdOrdenTerapiaCuota
        }
        const response = await fetchSinToken('admision/pa_get_orden_terapia_cuota', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataDetalleCuotas(record);
    }

    const getDetalleCuotas = async (data: any) => {
        switch (data.Tipo) {
            case "T":
                pa_get_orden_terapia_cuota_detalle(data.IdOrdenTerapiaCuota);
                break;
            case "A":
                pa_get_orden_atencion_cuota_detalle(data.IdOrdenPago);
                break;
            default:
                pa_get_orden_atencion_cuota_detalle(data.IdOrdenPago);
                break;
        }
    }

    const toast = useRef<any>(null);
    const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const setConfirmarGrabarCuotasNotas = () => {
        // const IsValidaCampos = onValidarCampos();
        // const Isvalido = IsValidaCampos[0];
        // const IsMessage = IsValidaCampos[1];
        // if (!Isvalido) {
        //     toast.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 8000 });
        //     return;
        // }
        setVisibleConfirmDialog(true)
    }

    const setGrabarCuotasNotas = async () => {
        setVisibleConfirmDialog(false);
        const param = {
            VP_Tipo: props.TipoRegistro, //T,A,C,V
            VP_DetalleCuotas: JSON.stringify(dataDetalleCuotas),
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_IdSucursal: localStorage.getItem('authIdSucursal')
        };
        // console.log(param);
        // return;
        const response = await fetchSinToken('admision/pa_set_cuota_detalle', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log(record);
        if (record) {
            const message = record.sql_message;
            toast.current.show({ severity: record.sql_error === 0 ? 'success' : 'error', summary: '::PSISABE', detail: message, sticky: false });
            //eventos de la pantalla anterior
            // if (record.sql_error === 0) {
            //     props.setIsMounted(false);
            //     props.isHide()
            // }
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, sticky: false });
        }
    }

    const renderFooter = () => {
        return (
            <>
                <Button label="Grabar" icon="pi pi-save" className="p-button ml-2" onClick={() => { setConfirmarGrabarCuotasNotas() }} />
                <Button label="Cerrar" icon="pi pi-times" className="p-button-outlined p-button-danger ml-2" onClick={() => {
                    props.setIsMounted(false);
                    props.isHide()
                }} />
            </>
        );
    }
    const renderHeader = () => {
        return (
            <>
                <TitleForm title={'Detalle de cuotas'} />
            </>
        );
    }
    const textEditor2 = (options: any) => {
        return <InputText type="text" className='w-full p-inputtext-sm ' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    const textEditor3 = (options: any) => {
        return <Calendar style={{ width: '150px' }} inputClassName="w-full p-inputtext-sm"
            dateFormat="yy-mm-dd"
            minDate={new Date()}
            value={options.value}
            onChange={(e: any) => {
                // console.log(e.value);
                options.editorCallback(e.value)
                // options.editorCallback( new Date(Date.parse(e.value)) )
            }}
        />
    }

    const onCellEditComplete2 = (e: any) => {
        // console.log(e);
        let { rowData, newValue, field, originalEvent: event } = e;
        // console.log(field)
        // console.log( new Date(Date.parse(newValue)));
        // return;
        switch (field) {
            case 'FechaVencimiento':
                if (newValue)
                    rowData[field] = setIntlDateTimeFormat(new Date(Date.parse(newValue)));
                else
                    event.preventDefault();
                break;
            default:
                if (newValue) {
                    if (newValue.trim().length > 0)
                        rowData[field] = newValue;
                    else
                        event.preventDefault();
                }
                break;
        }
    }

    return (
        <>
            <Toast ref={toast} position="bottom-right"></Toast>
            <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
                message="¿Seguro de guardar cambios?"
                header="Confirmar ..."
                icon="pi pi-exclamation-triangle"
                accept={setGrabarCuotasNotas}
                reject={() => console.log('Cancelado')}
            />
            <Dialog
                position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
                header={renderHeader}
                visible={props.isOpen}
                footer={renderFooter}
                onHide={() => {
                    props.setIsMounted(false);
                    props.isHide();
                }}
            >
                <div className="p-field p-grid mb-1">

                    <div className='card p-1' >
                        <DataTable
                            size="small"
                            value={dataCuotas ?? []}
                            // dataKey="IdOrdenTerapiaCuota"
                            resizableColumns
                            responsiveLayout="scroll"
                            columnResizeMode="fit"
                            showGridlines
                            stripedRows
                            scrollable
                            selectionMode="single"
                            onSelectionChange={(e) => {
                                // console.log(e);
                                getDetalleCuotas(e.value);
                            }}
                        >
                            <Column field="IdCuota" header="#ID" style={{ minWidth: '4rem', maxWidth: '4rem' }} ></Column>
                            <Column field="ImporteTotal" header="Importe" style={{ minWidth: '6rem' }}
                                headerClassName="flex justify-content-end"
                                bodyClassName="flex justify-content-end "></Column>
                            <Column field="CantidadCuota" header="Cantidad Cuotas" style={{ minWidth: '5rem' }}
                                bodyClassName="flex justify-content-center" headerClassName="white-space-normal"  ></Column>
                            <Column field="ImporteCuota" header="Importe Cuota" style={{ minWidth: '6rem' }}
                                headerClassName="white-space-normal"
                                bodyClassName="flex justify-content-end text-cyan-800 font-bold"> </Column>
                        </DataTable>
                    </div>
                    <div className='card p-1' >
                        <DataTable
                            size="small"
                            value={dataDetalleCuotas ?? []}
                            // dataKey="IdOrdenTerapiaCuota"
                            resizableColumns
                            responsiveLayout="scroll"
                            columnResizeMode="fit"
                            showGridlines
                            stripedRows
                            scrollable
                            selectionMode="single"
                            editMode="cell"
                        >
                            <Column field="Cuota" header="Nº Cuota" style={{ minWidth: '6rem', maxWidth: '6rem' }} ></Column>
                            <Column field="Importe" header="Importe" style={{ minWidth: '6rem', maxWidth: '6rem' }}
                                headerClassName="flex justify-content-end"
                                bodyClassName="flex justify-content-end text-green-600 font-semibold "></Column>
                            <Column field="Nota" header="Notas" headerClassName='pi pi-file-edit' style={{ minWidth: '12rem', maxWidth: '15rem' }}
                                bodyClassName='white-space-normal text-sm'
                                editor={(options) => textEditor2(options)} onCellEditComplete={onCellEditComplete2}>
                            </Column>
                            <Column field="FechaVencimiento" header="F.Vencimiento" headerClassName='pi pi-file-edit' style={{ minWidth: '8rem', maxWidth: '8rem' }}
                                bodyClassName='flex justify-content-center text-sm'
                                editor={(options) => textEditor3(options)} onCellEditComplete={onCellEditComplete2}>
                            </Column>
                        </DataTable>
                    </div>

                </div>
            </Dialog>
        </>
    )
}
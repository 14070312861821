import React, { useEffect, useState, useRef, useContext } from 'react';

import { fetchSinToken } from '../../../helpers/fetch';
import useModal from '../../../context/WindowsOpen';
import HeaderOptions from '../../components/caja/HeaderOptions';
import ProcesarPago from '../../components/caja/ProcesarPago';
import { OrdenPagos01 } from '../../components/caja/ordenPago/OrdenPagos01';
import HeaderMenubar from '../../components/caja/HeaderMenubar';
import { Toast } from 'primereact/toast';
import { AppContext01 } from '../../../context/AppContext';
import { setIntlDateTimeFormat } from '../../../helpers/functions';

const CajaView = () => {

  // const [dataCliente, setDataCliente] = useState<any>([]);
  const [dataOrdenPago, setDataOrdenPago] = useState<any>([]);
  const [dataSelectedOPItems, setSelectedOPItems] = useState<any>([]);
  //filter
  const [valCheckedPend, setChecked] = useState<boolean>(true);  
  const [valFechaDesde, setvalFechaDesde] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 1 ) );

  const [valFechaHasta, setvalFechaHasta] = useState<any>(new Date());
  const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState('');

  //flag
  const isMounted = useRef(false);
  const [isMode, setMode] = useState('I');

  //window
  const [isOpenModal, openAdmisionModal, closeAdmisionModal] = useModal();
  // mensaje
  const toast = useRef<any>(null);

  useEffect(() => {
    handleSetTitle();
    pa_get_orden_pago();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Agrega titulo a la barra de navegacion superior  
  const { showmenuTitle } = useContext( AppContext01 );        
  const handleSetTitle = () => {
    showmenuTitle('/Caja/Pagos');
  }

  // buscar CLIENTE x numero de documento
  // const getBuscarCliente = async (e: any) => {
  //   e.preventDefault();
  //   const param = {
  //     "VP_IdCliente": 0,
  //     "VP_NumeroDocumento": "",
  //     "VP_NumeroHistoriaClinica": vl_NumeroHistoriaClinica,
  //     "VP_Nombres": "",
  //     "VP_Apellidos": ""
  //   }
  //   const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
  //   const resp = await response.json();
  //   const record = resp.data[0];
  //   setDataCliente(record);
  //   // console.log(record);
  //   // setInitInputValCliente();
  //   if (record) {
  //     // pa_get_orden_pago();

  //     //si existe cliente dehabilitar datos del cliente      
  //     // setDisabledNumeroDocumento(true);
  //     // setInputValIdCliente(record.IdCliente);
  //     // setInputValNombre(record.Nombres);
  //     // setInputValApPaterno(record.ApPaterno);
  //     // setInputValApMaterno(record.ApMaterno);
  //     // setInputValEdad(record.Edad);
  //     // setInputValNumeroDocumento(record.NumeroDocumento);
  //     // setInputValIdTipoDocumento(record.tipo_doc);
  //     // setInputValSexo(record.Sexo);
  //     // setInputValIdHistoriaClinica(record.IdHistoriaClinica);

  //   } else {
  //     const message = 'No se encontro cliente';
  //     toast.current.show({ severity: 'warn', summary: 'Message', detail: message, life: 10000 });
  //     // inputNumeroHistoriaClinicaRef.current.focus();
  //     // inputNumeroHistoriaClinicaRef.current.select();
  //   }
  // }
  
  const pa_get_orden_pago = async () => {
    const param = {
      "VP_IdRegistro": 0,
      "VP_IdCliente": 0,
      "VP_NumHistoriaClinica": vl_NumeroHistoriaClinica,
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      "vp_EstadoPago": valCheckedPend?'P':'',
      "vp_IdSucursal": localStorage.getItem('authIdSucursal')
    }
    // LIMPIAR SELECCIONADOS
    setSelectedOPItems([]);
    const response = await fetchSinToken('admision/pa_get_orden_pago', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataOrdenPago(record);
  }

  const fn_setSelectedOPItems = (data: any) => {
    // console.log(data);
    setSelectedOPItems(data);
  }

  const setFormProcesaPagos = () => {
    // console.log(dataSelectedOPItems.length);
    if( dataSelectedOPItems.length === 0 ){
      toast.current.show({ severity: 'warn', summary: '::PSISABE', detail: 'Debe selecionar un registro', life: 10000 });
      return;
    }
    if( dataSelectedOPItems.length > 1 ){
      toast.current.show({ severity: 'warn', summary: '::PSISABE', detail: 'Debe selecionar un solo registro', life: 10000 });
      return;
    }
    isMounted.current = true;
    setMode('P');    
    openAdmisionModal();
  }
  const setIsMounted=(val:boolean)=>{
    isMounted.current = val;
  }

  const ProcesarPagoNew = () => {
    return (
      <ProcesarPago
        isOpen={isOpenModal}
        isHide={closeAdmisionModal}
        isData={dataSelectedOPItems}
        isMode={isMode}
        pa_get_orden_pago={pa_get_orden_pago}
        setIsMounted = {setIsMounted}
      />
    );
  }

  

  // const data: any = [];

  return (
    <>
      <div className="card p-1 mt-0" style={{ marginTop: '.1em' }}  >
        <div className="card-container gray-container">
          <div className="block bg-gray-10 font-bold text-end p-2 mb-2 border-bottom-1 flex justify-content-end">          
          <HeaderMenubar
              setFormProcesaPagos={setFormProcesaPagos}
          />            
          </div>
          <div className="block bg-gray-100 filter-view font-bold text-left p-1  mb-1">
            <HeaderOptions
              valCheckedPend={valCheckedPend}
              setChecked={setChecked}
              valFechaDesde={valFechaDesde}
              setvalFechaDesde={setvalFechaDesde}
              valFechaHasta={valFechaHasta}
              setvalFechaHasta={setvalFechaHasta}
              NumeroHistoriaClinica={vl_NumeroHistoriaClinica}
              setNumeroHistoriaClinica={setNumeroHistoriaClinica}
              pa_get_orden_pago={pa_get_orden_pago}              
            />
          </div>
          <div className="block bg-gray-100 font-bold text-start p-1  mb-1">
            <OrdenPagos01
              dataOrdenPago={dataOrdenPago}
              setSelectedOPItems={fn_setSelectedOPItems}
              dataSelectedOPItems={dataSelectedOPItems}
            />
          </div>
        </div>
      </div>
            
      <React.Fragment>{
        isMounted.current && <ProcesarPagoNew />
      }
      </React.Fragment>
      <Toast ref={toast} position="top-center"></Toast>
    </>
  );
};

export default CajaView;





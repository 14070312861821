import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import TitleForm from '../../components/util/TitleForm';

const CajaDetalleRecibo = ({ ...props }) => {
    let vl_IdPago: any = props.isData[0].IdPago;    
    const [vl_Total, setTotal] = useState<any>(0);

    useEffect(() => {
        // console.log(props.isData);
        let vl_Total: any = 0;
        props.isData.forEach(
            function (currentValue: any) {
                vl_Total = vl_Total + parseFloat(currentValue.Total);
            }
        );
        setTotal(vl_Total);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderHeader = () => {
        return (
            <>
                <TitleForm title={'Detalle recibos de pago'} />
            </>
        );
    }
    const renderFooter = () => {
        return (
            <>
                <Button label="Cerrar" icon="pi pi-times" className="p-button-outlined p-button-danger ml-2" onClick={() => {
                    props.setIsMounted(false);
                    props.isHide()
                }} />
            </>
        );
    }
    return (
        <>
            <Dialog
                header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
                visible={props.isOpen}
                footer={renderFooter}
                onHide={() => {
                    props.setIsMounted(false);
                    props.isHide();
                }}
            >
                <div className='p-1 m-1' >
                    <h3> {'Recibo # ' + vl_IdPago + ' *** S/ ' + vl_Total  } </h3>
                    <div className="flex flex-wrap card-container w-full ">
                        <div className="flex align-items-center justify-content-center m-1" >
                            <DataTable size="small" resizableColumns
                                // header="Recibos de pago"
                                responsiveLayout="scroll"
                                scrollDirection="horizontal"
                                style={{ maxWidth: '60vw' }}
                                scrollHeight="30vh"
                                columnResizeMode="fit"
                                value={props.isData ?? []}
                                dataKey="IdPago"
                                showGridlines
                                stripedRows
                                scrollable
                                // rowClassName={rowClassName}
                                selectionMode="single"
                                // selection={selectedIemsGrid}
                                onSelectionChange={(e: any) => {
                                    // console.log(e.value);
                                    // setSelectedIemsGrid(e.value);
                                    // // setIdPago(e.value.IdPago);
                                    // setReferenciaID(e.value.IdPago);
                                }}

                            >
                                {/* <Column field="IdPago" header="Nº Recibo" style={{ minWidth: '5rem' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-center text-red-500 font-bold text-lg"
                                >
                                </Column> */}
                                <Column field="Cuota" header="#Cuota" style={{ maxWidth: '5rem' }}
                                    headerClassName="white-space-normal"
                                    bodyClassName="flex justify-content-center"
                                >
                                </Column>
                                <Column field="Importe_1" header="Importe Cuota" style={{ minWidth: '6rem' }}
                                    headerClassName="white-space-normal"
                                    bodyClassName="flex justify-content-end text-blue-700 font-bold text-normal"
                                >
                                </Column>
                                <Column field="Total" header="Pagado" style={{ minWidth: '6rem' }}
                                    headerClassName="white-space-normal"
                                    bodyClassName="flex justify-content-end text-blue-700 font-bold text-normal"
                                >
                                </Column>
                                <Column field="Saldop" header="Saldo" style={{ minWidth: '6rem' }}
                                    headerClassName="white-space-normal"
                                    bodyClassName="flex justify-content-end text-blue-700 font-bold text-normal"
                                >
                                </Column>
                                <Column field="Detalle" header="Detalle Pago" style={{ minWidth: '22rem' }}
                                    headerClassName="flex justify-content-start white-space-normal "
                                    bodyClassName="white-space-normal"
                                >
                                </Column>
                            </DataTable>
                        </div>
                    </div>


                </div>


            </Dialog>
        </>
    )
}

export default CajaDetalleRecibo
import { Button } from 'primereact/button';
// import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressBar } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { fetchSinToken } from '../../../helpers/fetch';
import { Badge } from 'primereact/badge';


export const PlanPagosView = ({ ...prop }) => {
    // console.log('PlanPagos');
    // console.log(props.dataPlanPagos); 

    const [vl_TotalInversion, setTotalInversion] = useState<any>(0);
    const [vl_TotalPagos, setTotalPagos] = useState<any>(0);
    const [vl_TotaltCuota, setTotaltCuota] = useState<any>(0);
    const [vl_CuotaPagada, setCuotaPagada] = useState<any>(0);
    // const [vl_Saldo, setSaldo] = useState<any>(0);


    const [dataOrdenPago, setDataOrdenPago] = useState<any>([]);
    const [dataPlanPagos, setDataPlanPagos] = useState<any>([]);

    const [vl_IdOrdenPago, setIdOrdenPago] = useState<any>('');
    const [vl_CountIdOrdenPago, setCountIdOrdenPago] = useState<any>(0);

    const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>('');
    const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>('');
    const [vl_Nombres, setNombres] = useState<any>('');
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
    const [vl_TipoServicio, setTipoServicio] = useState<any>('');
    const [vl_Total, setTotal] = useState<any>(0);
    const [vl_Pagos, setPagos] = useState<any>(0);
    const [vl_Saldo, setSaldo] = useState<any>(0);


    useEffect(() => {
        pa_get_paciente_orden_pago(prop.IdCliente);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_paciente_orden_pago = async (in_IdCliente: any) => {
        const param = {
            "VP_IdCliente": in_IdCliente,
            "VP_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('paciente/pa_get_paciente_orden_pago', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        if (record.length === 0) {
            alert('No hay data plan pagos');
            return;
        }

        // console.log(record);
        setDataOrdenPago(record);
        setCountIdOrdenPago(record?.length);

        //Valor x Default 
        setOnSelectionChange(record[0]);
        pa_get_paciente_plan_pagos(record[0]?.IdOrdenPago);
    }

    const pa_get_paciente_plan_pagos = async (in_IdOrdenPago: any) => {
        const param = {
            "VP_IdOrdenPago": in_IdOrdenPago
        }
        const response = await fetchSinToken('paciente/pa_get_paciente_plan_pagos', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        setDataPlanPagos(record);
    }
    const setOnSelectionChange = (data: any) => {
        setIdOrdenPago(data.IdOrdenPago);
        setTotalInversion(data.Total);
        setTotalPagos(data.Pagos);
        setTotaltCuota(data.NumeroCuotas);
        setCuotaPagada(data.NumeroCuotasPagado);
        setSaldo(data.Saldo);
        // --new
        setApellidoPaterno(data.ApellidoPaterno);
        setApellidoMaterno(data.ApellidoMaterno);
        setNombres(data.Nombres);
        setNumeroHistoriaClinica(data.NumeroHistoriaClinica);
        setTipoServicio(data.TipoServicio);
        setTotal(data.Total);
        setPagos(data.Pagos);
    }


    const templateStatusPago = (item: any) => {
        // console.log(item);
        const BtnStatusPending = () => {
            return (
                <>
                    <Button
                        type="button" aria-readonly
                        className="p-button p-button-warning pl-1 text-xs"
                        icon="pi pi-minus-circle"
                        style={{ height: '24px', width: '120px' }}
                        label="Pendiente"
                    />
                </>
            );
        }
        const BtnStatusPagado = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button p-button-success pl-1 text-xs"
                        icon="pi pi-check-circle"
                        style={{ height: '24px', width: '120px' }}
                        label="Pagado"
                    />
                </>
            );
        }
        const BtnStatusParcial = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button p-button-warning  pl-1 text-xs "
                        icon="pi pi-spinner"
                        style={{ height: '24px', width: '120px' }}
                        label="Parcial"
                    />
                </>
            );
        }

        return (
            <>
                {/* {item.Estado === 'P' ? <BtnStatusPending /> : <BtnStatusPagado /> } */}
                {item.Estado === 'P' ? <BtnStatusPending /> : item.Estado === 'T' ? <BtnStatusPagado /> : <BtnStatusParcial />}
            </>
        );
    }
    const templateStatusPagoOP = (item: any) => {
        // console.log(item);
        const BtnStatusPending = () => {
            return (
                <>
                    <Button
                        type="button" aria-readonly
                        className="p-button p-button-warning pl-1 text-xs"
                        icon="pi pi-minus-circle"
                        style={{ height: '24px', width: '120px' }}
                        label="Pendiente"
                    />
                </>
            );
        }
        const BtnStatusPagado = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button p-button-success pl-1 text-xs"
                        icon="pi pi-check-circle"
                        style={{ height: '24px', width: '120px' }}
                        label="Pagado"
                    />
                </>
            );
        }
        const BtnStatusParcial = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button p-button-warning  pl-1 text-xs "
                        icon="pi pi-spinner"
                        style={{ height: '24px', width: '120px' }}
                        label="Parcial"
                    />
                </>
            );
        }
        return (
            <>
                {item.EstadoPago === 'P' ? <BtnStatusPending /> : item.EstadoPago === 'T' ? <BtnStatusPagado /> : <BtnStatusParcial />}
            </>
        );
    }
    const templateNumberCurrency = (item: any) => {
        return (
            <>
                ${item.Saldo}
            </>
        );
    }
    const renderFooter = () => {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-check" onClick={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }} autoFocus />
            </div>
        );
    }
    const renderHeader = () => {
        return (
            <>
                <div className="bg-indigo-500 pb-2">
                    <div className="col mt-0 ml-0 text-white font-italic h-3rem" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='text-base font-bold'> Paciente: {vl_ApellidoPaterno + ' ' + vl_ApellidoMaterno + ', ' + vl_Nombres} </label><br />
                        <label className='text-base font-bold'> Nº HC. {vl_NumeroHistoriaClinica} </label>
                    </div>
                </div>
            </>
        );
    }
    const templateProgresoPagos = (item: any) => {
        return (
            <>
                <React.Fragment>
                    <span style={{ textAlign: 'center' }} className={item.EstadoPlanPago} >{item.PagoCuotas}</span>
                </React.Fragment>

            </>
        );
    }
    const templateDetalleServicio = (item: any) => {
        return (
            <>
                <React.Fragment>
                    <label className="white-space-normal">{item.DetalleServicio}  </label>
                </React.Fragment>
            </>
        );
    }


    return (
        <>

            <Dialog
                visible={prop.isOpen}
                position={'bottom-right'}
                modal draggable resizable closable showHeader maximizable
                header={renderHeader}
                footer={renderFooter()}
                onHide={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }}
            >

                <div className="flex flex-nowrap">
                    <div className="justify-content-start pr-1 border-right-2 border-yellow-500 ">
                        <div className="col mb-1 text-black mr-1 ">
                            <label className="col text-sm " style={{ textAlign: 'left' }} >
                                <Badge value={vl_CountIdOrdenPago} severity="danger" ></Badge> {'  '} Seleccionar un registro para ver plan de pagos.
                            </label>
                        </div>
                        <div className="p-col mb-1 " >
                            <DataTable
                                size="small"
                                value={dataOrdenPago ?? []}
                                dataKey="IdOrdenPago"
                                resizableColumns
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                selectionMode="single"
                                scrollDirection="horizontal"
                                style={{ maxWidth: '700px' }}
                                scrollHeight="500px"
                                // selection={dataSelectedOPItems}
                                onSelectionChange={(e: any) => {
                                        // console.log(e.value);
                                        // setSelectedOPItems(e.value);                                
                                        setOnSelectionChange(e.value);
                                        pa_get_paciente_plan_pagos(e.value.IdOrdenPago);
                                    }
                                }
                            >
                                <Column field="IdOrdenPago" header="Nº OP"style={{ maxWidth: '70px' }}bodyClassName="flex justify-content-center"  ></Column>
                                {/* <Column field="TipoServicio" header="T. Servicio" style={{ maxWidth: '220px' }} bodyClassName="flex justify-content-start"  ></Column> */}
                                <Column field="TipoServicio" header="Servicio" body={templateDetalleServicio} 
                                style={{ minWidth: '200px' }} bodyClassName="flex justify-content-start"  ></Column>                                
                                <Column field="Fecha" header="Fecha" style={{ maxWidth: '130px' }}
                                    bodyClassName="flex justify-content-center" ></Column>
                                {/* <Column field="Importe" header="Importe" style={{ maxWidth: '90px' }}headerClassName="flex justify-content-end"bodyClassName="flex justify-content-end "></Column> */}
                                {/* <Column field="Descuento" header="Dscto." style={{ maxWidth: '70px' }}headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column> */}
                                <Column field="Total" header="Total" style={{ maxWidth: '90px' }}
                                    headerClassName="flex justify-content-end"
                                    bodyClassName="flex justify-content-end "></Column>
                                <Column field="PagoCuotas" header="Cuota" style={{ maxWidth: '50px', align: 'center' }}
                                    body={templateProgresoPagos}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-center font-bold"
                                ></Column>
                                {/* <Column field="Pagos" header="Pagos" colSpan={5} style={{ maxWidth: '120px', align: 'end' }}headerClassName="flex justify-content-center"bodyClassName="flex justify-content-center"></Column>                                 */}
                                {/* <Column field="Saldo" header="Saldo" style={{ maxWidth: '90px' }}headerClassName="flex justify-content-end"bodyClassName="flex justify-content-end text-cyan-800 font-bold"></Column> */}
                                <Column field="EstadoPago" header="Estado Pago"
                                    body={templateStatusPagoOP} style={{ maxWidth: '170px' }}
                                ></Column>

                            </DataTable>
                        </div>
                    </div>
                    <div className="justify-content-start pl-1 ">
                        <div className="h-3rem w-100 bg-gray-300 mt-1 mb-2 border-round ">
                        <div className="flex flex-wrap align-items-center justify-content-center">                                
                                <label className="col font-italic" style={{ textAlign: 'left' }} >Nº OP: {vl_IdOrdenPago} </label>
                                <label className="col font-italic" style={{ textAlign: 'left' }} >Servicio: {vl_TipoServicio}  </label>
                                <label className="col font-italic" style={{ textAlign: 'left' }} >Total: {vl_Total}  </label>
                                <label className="col font-italic" style={{ textAlign: 'left' }} >Pagos: {vl_Pagos}  </label>
                                <label className="col font-italic" style={{ textAlign: 'left' }} >Saldo: {vl_Saldo}  </label>
                            </div>
                        </div>
                        <div className="p-field p-grid mb-1">
                            <div className="p-col mb-1 " >
                                <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Total:</label>
                                <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                                    inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }}
                                    value={vl_TotalInversion}
                                    readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                />
                                <label className="p-col-fixed mr-1 ml-1 font-bold " style={{ textAlign: 'end' }}>C. Pagada:</label>
                                <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                                    inputStyle={{ height: '35px', width: '50px', textAlign: 'center' }}
                                    value={vl_CuotaPagada}
                                    readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                                />
                                <label className="p-col-fixed mr-1 ml-1 font-bold " style={{ textAlign: 'end' }}>de:</label>
                                <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                                    inputStyle={{ height: '35px', width: '50px', textAlign: 'right' }}
                                    value={vl_TotaltCuota}
                                    readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                                />
                                <label className="p-col-fixed mr-1 ml-1 font-bold " style={{ textAlign: 'end' }}>T.Pago:</label>
                                <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                                    inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }}
                                    value={vl_TotalPagos}
                                    readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                />
                                <label className="p-col-fixed mr-1 ml-1 font-bold " style={{ textAlign: 'end' }}>Saldo:</label>
                                <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round bg-yellow-500 '
                                    inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }}
                                    value={vl_Saldo}
                                    readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                />
                            </div>
                            <ProgressBar min={0} now={vl_CuotaPagada} max={vl_TotaltCuota} label={'Pagados ' + vl_CuotaPagada + ' de ' + vl_TotaltCuota} variant={vl_CuotaPagada === vl_TotaltCuota ? 'success' : 'info'}></ProgressBar>
                        </div>
                        <div className="p-col" >
                            <DataTable
                                // header="Plan de pagos"
                                size="small"
                                value={dataPlanPagos ?? []}
                                dataKey="IdPlanPagoDetalle"
                                resizableColumns
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                selectionMode="single"
                                // style={{ maxWidth: '790px', height: '260px' }}
                                // scrollHeight="250px"
                            >
                                {/* <Column field="IdPlanPagoDetalle" header="Id" style={{ maxWidth: '60px' }} ></Column> */}
                                <Column field="Cuota" header="C." style={{ maxWidth: '50px' }}
                                    bodyClassName="flex justify-content-center"  ></Column>
                                <Column field="FechaVencimiento" header="F. Vencimiento" style={{ maxWidth: '150px' }}
                                    bodyClassName="flex justify-content-center" ></Column>
                                <Column field="Importe" header="Importe" style={{ maxWidth: '90px' }}
                                    headerClassName="flex justify-content-end"
                                    bodyClassName="flex justify-content-end "></Column>
                                <Column field="Pago" header="Pagado" style={{ maxWidth: '90px', align: 'end' }}
                                    headerClassName="flex justify-content-end"
                                    bodyClassName="flex justify-content-end text-green-800 font-bold"></Column>
                                <Column field="FechaPago" header="Fecha Pago" style={{ maxWidth: '120px', align: 'end' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-center"></Column>
                                <Column field="Saldop" header="Saldo" style={{ maxWidth: '90px' }}
                                    headerClassName="flex justify-content-end"
                                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                    body={templateNumberCurrency} > </Column>
                                <Column field="Estado" header="Estado Cuota" body={templateStatusPago} style={{ maxWidth: '170px' }}  ></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                {/* <div className="p-field p-grid mb-1">
                    <div className="col mt-1 mb-1 border-round-bottom flex justify-content-end">
                        <label className="col text-sm bg-indigo-500 text-white" style={{ textAlign: 'left' }} > Orden de pago
                            {' '}
                            <Badge value={vl_CountIdOrdenPago} severity="danger" ></Badge>
                            {' '}
                            <Badge className='border-round-right border-round-bottom opacity-80' value="Seleccionar una orden para ver plan de pagos" severity="warning" ></Badge>
                        </label>
                    </div>

                </div> */}

                {/* <div className="col mt-1 mb-1 border-round-bottom flex justify-content-end">
                    <label className="col text-sm bg-indigo-500 text-white" style={{ textAlign: 'left' }} >Plan de pagos: {'Orden Pago Nº ' + vl_IdOrdenPago}</label>
                </div> */}

            </Dialog>
        </>
    )
}
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { fetchSinToken } from '../../../helpers/fetch';
import { InputText } from 'primereact/inputtext';
import { ScrollTop } from 'primereact/scrolltop';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import '../../../assets/scss/pacienteTimeline.scss';
import useModal from '../../../context/WindowsOpen';
import BuscadorClientes from '../../components/util/BuscadorClientes';
import AgendaView from './AgendaView';
import { PlanPagosView } from './PlanPagosView';
import { PlanSesionesView } from './PlanSesionesView';
import NotificacionesView from './NotificacionesView';
import { AppContext01 } from '../../../context/AppContext';
import PacienteInfo from '../../components/paciente/PacienteInfo';
import { InputSwitch } from 'primereact/inputswitch';
import { Messages } from 'primereact/messages';
import { Sidebar } from 'primereact/sidebar';
import { getClaseName } from '../../../helpers/functions';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { Carousel } from 'primereact/carousel';


const CronologiaView = () => {

    // const [vl_IdHistoriaClinica, setIdHistoriaClinica] = useState<any>(0);
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>(new Date().getFullYear() + '0000');
    const [vl_IdCliente, setIdCliente] = useState<any>(0); //int DEFAULT NULL COMMENT 'FK TB_CLIENTE',    
    const [vl_NumeroDocumento, setNumeroDocumento] = useState<any>(''); //varchar(20) DEFAULT NULL,    
    const [vl_TipoDocumento, setTipoDocumento] = useState<any>(''); //varchar(20) DEFAULT NULL,    
    const [vl_Cliente, setCliente] = useState<any>(''); //varchar(100) DEFAULT NULL,    
    const [vl_DireccionEmail, setDireccionEmail] = useState<any>('');
    const [vl_NumeroTelefono, setNumeroTelefono] = useState<any>('');
    const [vl_Edad, setEdad] = useState<any>(''); //int DEFAULT NULL,

    const [datTimeline, setDatTimeline] = useState<any>([]);
    const [dataCliente, setDataCliente] = useState<any>();

    const isMounted = useRef(false);
    const isMounted01 = useRef(false);
    const isMounted02 = useRef(false);
    const isMounted03 = useRef(false);
    const isMounted04 = useRef(false);

    const [isOpenModal, openModal, closeModal] = useModal();
    const [isOpenModal01, openModal01, closeModal01] = useModal();
    const [isOpenModal02, openModal02, closeModal02] = useModal();
    const [isOpenModal03, openModal03, closeModal03] = useModal();
    const [isOpenModal04, openModal04, closeModal04] = useModal();

    const [vl_bhistory, setBhistory] = useState<boolean>(false);
    const [visibleTop, setVisibleTop] = useState<boolean>(false);
    const [vl_Notificacion, setNotificacion] = useState<any>('');
    const [vl_NotificacionStyle, setNotificacionStyle] = useState<any>('');
    const [dataNotificacion, setDataNotificacion] = useState<any>([]);

    const messagesHistory = useRef<any>(null);


    useEffect(() => {
        handleSetTitle();
        // pa_get_paciente_timeline();
        // pa_get_agenda_paciente();        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_paciente_timeline = async (in_IdCliente: any) => {
        const param = {
            "VP_IdCliente": in_IdCliente
        }
        const response = await fetchSinToken('paciente/pa_get_paciente_timeline', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        setDatTimeline(record);
    }

    // const customizedMarker = (item: any) => {
    //     return (
    //         // custom-marker p-shadow-2
    //         <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.TimeLineColor }}>
    //             <i className={item.TimeLineIcon}></i>
    //         </span>
    //     );
    // };



    const customizedContent = (item: any) => {
        const subTitle = (
            <span className='text-gray-300'>
                {item.TimeLineFecha + ' ' + item.TimeLineHora}
            </span>
        );
        var JSONPrettyMon = require('react-json-pretty/dist/monikai');
        return (
            <Card
                title={item.TimeLineStatus}
                subTitle={subTitle}
                style={{ minWidth: "380px", maxWidth: "380px", height: "480px" }}
                className='border-round-bottom bg-yellow-600 text-gray-800 text-sm mb-0 overflow-y-auto'
            // footer={footer}
            >
                <JSONPretty
                    data={item.TimeLineDetalle}
                    style={{ textAlign: "left" }}
                    theme={JSONPrettyMon}
                    space={1}
                    themeClassName='border-solid' //dotted, solid, double 
                ></JSONPretty>
            </Card>
        );
    };


    // Agrega titulo a la barra de navegacion superior      
    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/PACIENTE/CRONOLOGIA');
    }

    const handleClickBuscar = (e: any) => {
        isMounted.current = true;
        openModal();
    }
    const handleClickAgendaView = (e: any) => {
        if (parseInt(vl_IdCliente) === 0) {
            messagesHistory.current.show([
                { severity: 'warn', detail: 'No se puede mostrar agenda, busque un paciente primero', life: 10000 }
            ]);
            return;
        }
        isMounted01.current = true;
        openModal01();
    }
    const handleClickPlanPagoView = (e: any) => {
        if (parseInt(vl_IdCliente) === 0) {
            messagesHistory.current.show([
                { severity: 'warn', detail: 'No se puede mostrar estado de cuenta, busque un paciente primero', life: 10000 }
            ]);
            return;
        }
        isMounted02.current = true;
        openModal02();
    }
    const handleClickPlanSesionesView = (in_IdCliente: any) => {
        if (parseInt(in_IdCliente) === 0) {
            messagesHistory.current.show([
                { severity: 'warn', detail: 'No se puede mostrar detalle de sesiones, busque un paciente primero', life: 10000 }
            ]);
            return;
        }
        isMounted03.current = true;
        openModal03();
    }
    const handleClickNotificacionesView = (e: any) => {
        if (parseInt(vl_IdCliente) === 0) {
            messagesHistory.current.show([
                { severity: 'warn', detail: 'No se puede mostrar, busque un paciente primero', life: 10000 }
            ]);
            return;
        }
        isMounted04.current = true;
        openModal04();
    }


    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }
    const setIsMounted01 = (val: boolean) => {
        isMounted01.current = val;
    }
    const setIsMounted02 = (val: boolean) => {
        isMounted02.current = val;
    }
    const setIsMounted03 = (val: boolean) => {
        isMounted03.current = val;
    }
    const setIsMounted04 = (val: boolean) => {
        isMounted04.current = val;
    }

    const getDataCliente = (data: any) => {
        // console.log(data);
        setEdad(data.Edad);
        setCliente(data.Paciente);
        setIdCliente(data.IdCliente);
        // setIdHistoriaClinica(data.IdHistoriaClinica);
        setNumeroHistoriaClinica(data.NumeroHistoriaClinica);
        setDireccionEmail(data.InformacionContacto[0].DireccionEmail);
        setNumeroTelefono(data.InformacionContacto[0].NumeroTelefono);
        setNumeroDocumento(data.NumeroDocumento);
        setTipoDocumento(data.tipo_doc);
        // cargar informacion del paciente seleccionado
        // console.log(vl_bhistory);
        if (vl_bhistory)
            pa_get_paciente_timeline(data.IdCliente);
        // X DEFAULT 
        handleClickPlanSesionesView(data.IdCliente);
        // notificaciones/alertas            
        if (data?.Alerta) {
            handleClickAlertarView(data.IdCliente);
        }

    }
    const BuscadorClientesForm = () => {
        return (
            <BuscadorClientes
                isOpen={isOpenModal}
                isHide={closeModal}
                getDataCliente={getDataCliente}
                setIsMounted={setIsMounted}
            />
        );
    }

    const AgendaViewForm = () => {
        return (
            <AgendaView
                isOpen={isOpenModal01}
                isHide={closeModal01}
                IdCliente={vl_IdCliente}
                setIsMounted={setIsMounted01}
            />
        );
    }

    const PlanPagosViewForm = () => {
        return (
            <PlanPagosView
                isOpen={isOpenModal02}
                isHide={closeModal02}
                IdCliente={vl_IdCliente}
                setIsMounted={setIsMounted02}
            />
        );
    }
    const PlanSesionesViewForm = () => {
        return (
            <PlanSesionesView
                isOpen={isOpenModal03}
                isHide={closeModal03}
                IdCliente={vl_IdCliente}
                setIsMounted={setIsMounted03}
            />
        );
    }
    const NotificacionesViewForm = () => {
        return (
            <NotificacionesView
                isOpen={isOpenModal04}
                isHide={closeModal04}
                IdCliente={vl_IdCliente}
                setIsMounted={setIsMounted04}
            />
        );
    }

    const getBuscarCliente = async (vp_NumeroHistoriaClinica: any) => {

        const param = {
            "VP_IdCliente": 0,
            "VP_NumeroDocumento": '',
            "VP_NumeroHistoriaClinica": vp_NumeroHistoriaClinica,
            "VP_Nombres": '',
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
        const resp = await response.json();
        // console.log(resp);
        if (resp.data.length === 0) {
            const message = 'Numero de historia clinica ingresada no existe!';
            messagesHistory.current.show([
                { severity: 'warn', detail: message, life: 10000 }
            ]);
            // toast.current.show({ severity: 'warn', summary: '::PSISABE', detail: message, life: 10000 });
        } else {
            setDataCliente(resp.data[0]); //new
            setEdad(resp.data[0].Edad);
            setCliente(resp.data[0].Paciente);
            setIdCliente(resp.data[0].IdCliente);
            // setIdHistoriaClinica(resp.data[0].IdHistoriaClinica);
            setNumeroHistoriaClinica(resp.data[0].NumeroHistoriaClinica);
            setDireccionEmail(resp.data[0].InformacionContacto[0].DireccionEmail);
            setNumeroTelefono(resp.data[0].InformacionContacto[0].NumeroTelefono);
            setNumeroDocumento(resp.data[0].NumeroDocumento);
            setTipoDocumento(resp.data[0].tipo_doc);
            // cargar informacion del paciente seleccionado
            if (vl_bhistory) {
                pa_get_paciente_timeline(resp.data[0].IdCliente);
            }
            // X DEFAULT 
            handleClickPlanSesionesView(resp.data[0].IdCliente);
            // notificaciones/alertas            
            if (resp.data[0]?.Alerta) {
                handleClickAlertarView(resp.data[0].IdCliente);
            }
        }
    }

    const handleClickAlertarView = async (VP_IdCliente: any) => {
        const param = {
            "VP_IdNotificacion": 0, //VP_IdNotificacion
            "VP_IdCliente": VP_IdCliente,
            "VP_NumeroDocumento": '',
            "VP_NumeroHistoriaClinica": '',
            "VP_Filter": "#99"
        }
        const response = await fetchSinToken('catalogo/pa_get_notificaciones', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        let array: any = [];
        if (record.length > 0) {
            record.forEach(
                function (row: any) {
                    if (row.Mostrar.S3) {
                        array.push(row);
                    }
                }
            );
            if (array.length > 0) {
                setDataNotificacion(array);
                setVisibleTop(true);
            }
            // console.log(array)    
        }

    }

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    return (
        <>

            <Sidebar visible={visibleTop} closeOnEscape position="left" onHide={() => setVisibleTop(false)}   >
                <h4>Alertas</h4>
                <button className="p-sidebar-icon p-link mr-1 p-2">
                    <span className="pi pi-arrow-right" />
                </button>
                <h6 >{vl_Cliente}</h6>
                <DataTable size="small" resizableColumns style={{ width: '100%' }}
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    scrollable
                    value={dataNotificacion}
                    scrollHeight="80vh"
                    dataKey="IdNotificacion"
                    selectionMode="single"
                >
                    <Column field="Paciente" bodyClassName="white-space-normal"
                        body={(item: any) => {
                            return (
                                <div className='p-1 m-1 w-full' >
                                    {/* <label className={`${ getClaseName(item.Categoria) }`}>{' ' + item.Mensaje.mensaje}</label> */}
                                    <InputTextarea rows={7} cols={50} className={`${getClaseName(item.Categoria)}`}
                                        style={{ width: '100%' }} readOnly
                                        value={item.Mensaje.mensaje}
                                    />
                                </div>
                            );
                        }}
                    ></Column>
                </DataTable>
            </Sidebar>

            <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
            <React.Fragment>{isMounted01.current && <AgendaViewForm />}</React.Fragment>
            <React.Fragment>{isMounted02.current && <PlanPagosViewForm />}</React.Fragment>
            <React.Fragment>{isMounted03.current && <PlanSesionesViewForm />}</React.Fragment>
            <React.Fragment>{isMounted04.current && <NotificacionesViewForm />}</React.Fragment>

            <div className="flex justify-content-between pt-1 pl-3 border-bottom-1 border-gray-600 ">
                <div className='m-2 flex align-items-center justify-content-center p-1' >
                </div>
                <div>
                    <div className="p-field p-grid pl-1 pr-1 pt-1 pb-2 ">
                        <div className="p-col">
                            <label className="p-col-fixed mr-2 font-bold " style={{ width: '150px', textAlign: 'end' }}>N° HC:</label>
                            <InputText type="text" style={{ width: '120px', textAlign: 'center' }}
                                maxLength={8} autoFocus
                                value={vl_NumeroHistoriaClinica}
                                onChange={(e: any) => setNumeroHistoriaClinica(String(e.currentTarget.value).toUpperCase())}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        getBuscarCliente(String(e.currentTarget.value));
                                    }
                                }}
                            />
                            <Button label=""
                                onClick={(e: any) => getBuscarCliente(vl_NumeroHistoriaClinica)}
                                icon="pi pi-search"
                                className="p-button p-button-rounded ml-1 mr-1"
                            />
                            <Button label=""
                                onClick={(e: any) => handleClickBuscar(e)}
                                icon="pi pi-user-plus"
                                // className="p-button-rounded mt-0  mr-2 "
                                className="p-button-rounded mt-0 p-button-warning mr-2 "
                            />
                        </div>
                    </div>
                </div>
                <div className='flex align-items-center' >
                    <span className="p-buttonset">
                        <Button
                            icon="pi pi-times"
                            // onClick={() => link_form_close()}
                            className="p-button-sm p-button-danger p-button-raised"
                        />
                    </span>
                </div>
            </div>
            <ScrollPanel style={{ height: '700px' }}>
                <div className='flex flex-no-wrap justify-content-center gray-container ' >
                    <div className='col-2 gray-container '>
                        <PacienteInfo PacienteInfo={dataCliente} />
                    </div>
                    <div className='col gray-container' >
                        <div className='flex justify-content-between'>
                            <div className='w-full'>
                                <div className='gray-container'>
                                    <div className='p-3'>
                                        <div className="col border-round-bottom bg-gray-700 text-white">
                                            <Button label="" tooltip='Agenda'
                                                onClick={(e: any) => { handleClickAgendaView(e) }}
                                                icon="pi pi-calendar-plus" className="p-button-rounded mt-0 p-button-info mr-1 "
                                            />
                                            <Button label="" tooltip='Estado de cuenta'
                                                onClick={(e: any) => handleClickPlanPagoView(e)}
                                                icon="pi pi-wallet" className="p-button-rounded mt-0 p-button-info mr-1 "
                                            />
                                            <Button label="" tooltip='Detalle de citas'
                                                onClick={(e: any) => handleClickPlanSesionesView(vl_Cliente)}
                                                icon="pi pi-comments" className="p-button-rounded mt-0 p-button-info mr-1 "
                                            />
                                            <Button label="" tooltip='WhatsApp Messages'
                                                onClick={(e: any) => handleClickNotificacionesView(e)}
                                                icon="pi pi-whatsapp" className="p-button-rounded mt-0 p-button-success mr-1 "
                                            />
                                        </div>
                                        <Messages ref={messagesHistory} />
                                    </div>
                                    <div className='p-field p-grid ' >                                        
                                        {/* <h2>History.</h2> */}
                                        <label className="p-col-fixed mr-1 font-bold text-lg " style={{ textAlign: 'end' }}>History</label>
                                        <InputSwitch checked={vl_bhistory}
                                            onChange={(e) => {
                                                if (parseInt(vl_IdCliente) === 0) {
                                                    messagesHistory.current.show([
                                                        { severity: 'warn', detail: 'Busca un paciente', sticky: true }
                                                    ]);
                                                    return;
                                                }
                                                setBhistory(e.value);
                                                if (e.value) pa_get_paciente_timeline(vl_IdCliente);
                                                else setDatTimeline([]);
                                            }}
                                        />
                                        {/* <div className="card" >
                                            <ScrollPanel className="custombar1" style={{ width: '100%', height: '700px' }}>
                                                <ScrollTop />
                                                <div className="timeline-paciente mt-3">
                                                    <Timeline
                                                        value={datTimeline}
                                                        align="alternate"
                                                        opposite={(item) => item.TimeLineStatus}
                                                        className="customized-timeline"
                                                        marker={customizedMarker}
                                                        content={customizedContent} />
                                                </div>
                                                <ScrollTop target="parent" threshold={100} className="custom-scrolltop" icon="pi pi-arrow-up" />
                                            </ScrollPanel>
                                        </div> */}
                                    </div>
                                    <div className="flex justify-content-center">
                                        <Carousel value={datTimeline} numVisible={2} numScroll={1}
                                            // verticalViewPortHeight="360px"
                                            responsiveOptions={responsiveOptions}
                                            orientation="horizontal"
                                            className="custom-carousel"
                                            itemTemplate={customizedContent}                                            
                                            style={{ maxWidth: '900px', marginTop: '2em' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollPanel>
        </>
    )
}

export default CronologiaView
import { AppState, AppMenuTitle } from '../interfaces/GlobalInterface';

type AppAction =
  | { type: 'showSidebar', payload: { state: boolean } }

export const AppReducer = (state: AppState, action: AppAction) => {
  switch (action.type) {
    case 'showSidebar':
      return {
        isVisibleSidebar: action.payload.state
      }
    default:
      return state;
  }
}

//BARRA DE NAVEGACION MENU
type AppAction01 = | {
  type01: 'showmenuTitle',
  payload01: { title: string }
}
export const AppReducer01 = (title: AppMenuTitle, action: AppAction01) => {
  switch (action.type01) {
    case 'showmenuTitle':
      return {
        isVisibleTitle: action.payload01.title
      }
    default:
      return title;
  }
}

// // datos de usuario :: test
// type AppAction02 = | {
//   type02: 'getAuth',
//   payload01: { data: [] }
// }
// export const AppReducer02 = (data: AuthResponse, action: AppAction02) => {
//   switch (action.type02) {
//     case 'getAuth':
//       return {
//         // isVisibleTitle: action.payload01.title
//         data: action.payload01.data
//       }
//     default:
//       return data;
//   }
// }

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import TitleForm from '../util/TitleForm';
import { Dropdown } from 'primereact/dropdown';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';


const ServicioForm = ({ ...prop }) => {

    const [vl_mode, setModeCrud] = useState('');
    const [b_confirmDialog, setSaveConfirmDialog] = useState<boolean>(false);

    //CRUD
    const [VP_CodigoServicio, setCodigoServicio] = useState<any>('');
    // const [VP_IdSucursal, setIdSucursal] = useState<any>('0');
    const [VP_Descripcion, setDescripcion] = useState<any>('');
    const [VP_Detalle, setDetalle] = useState<any>('');

    const [VP_Tipo, setTipo] = useState<any>('');
    const [VP_IdEspecialidad, setIdEspecialidad] = useState<any>('0');
    const [VP_Estado, setEstado] = useState<Boolean>(true);
    const [VP_Image, setImage] = useState<any>('');
    const [VP_Forma, setForma] = useState<any>('');

    // const [VP_IdUsuario, setIdUsuario] = useState('');

    const [VP_CostoServicio, setCostoServicio] = useState<any>(0);

    //combos
    const [dataEspecialidad, setDataEspecialidad] = useState([]);
    const [dataTipo, setCmbTipo] = useState([]);
    const [dataForma, setCmbForma] = useState([]);
    //
    // const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const toast = useRef<any>(null);

    useEffect(() => {
        setModeCrud(prop.isMode);
        pa_get_especialidad();
        pa_get_sys_config('TipoServicio');
        pa_get_sys_config('TipoAtencion'); //RELACIONADO A UN SERVICIO
        setModeFormData(prop.isMode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.isMode]);


    const setModeFormData = (vl_isMode: any) => {
        switch (vl_isMode) {
            case 'I':
                setTimeout(() => {
                    // inputNumeroDocumento_f_Ref.current?.focus();
                }, 100);
                break;
            case 'U':
                getRecordServicio();
                //   setDisabledNumeroDocumento_f(true);
                break;
            case 'A':
                //   getRecordAdmision();
                //   setHiddenbtnGrabar(true);
                //   setHiddenbtnAnular(false);
                //   setDisabledNumeroDocumento_f(true);
                //   setTimeout(() => {
                //     inputObservacionesRef.current?.select();
                //   }, 100);
                break;
        }
    }
    const getRecordServicio = async () => {
        // console.log(prop.isData);
        setCodigoServicio(prop.isData.CodigoServicio);
        setDescripcion(prop.isData.Descripcion);
        setDetalle(prop.isData.Detalle);
        setTipo(prop.isData.Tipo);
        setIdEspecialidad(prop.isData.IdEspecialidad);
        setEstado(prop.isData.Estado === 'A' ? true : false);
        setCostoServicio(prop.isData.Costo);
        setImage(prop.isData.Image);
        setForma(prop.isData.Forma); //forma de atencion de un servicio

        // const vl_Costo = await pa_get_tarifario(prop.isData.CodigoServicio);
        // setCostoServicio(vl_Costo);

    }
    // const pa_get_tarifario = async (IN_CodigoServicio: any) => {
    //     const param = {
    //         "vp_opcion": "T",
    //         "vp_CodigoServicio": IN_CodigoServicio
    //     }
    //     const response = await fetchSinToken('selectors/pa_get_tarifario', param, 'POST');
    //     const resp = await response.json();
    //     const record = resp.data[0];
    //     // operador ternario
    //     return (record) ? record.Costo : 0.0;
    // }

    const pa_get_especialidad = async () => {
        //e.preventDefault();        
        const param = {
            "vp_opcion": "S",
            "vp_IdEspecialidad": "0",
            "vp_Descripcion": "",
            "vp_Estado": "0"
        }
        const response = await fetchSinToken('selectors/pa_get_especialidad', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        setDataEspecialidad(record);
    }
    const pa_get_sys_config = async (p_Identificador: any) => {
        const param = {
            "VP_Identificador": p_Identificador,
            "VP_Estado": "A"
        }
        const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
        const resp = await response.json();
        const record = resp.data;

        // console.log(record[0].RecordSet);
        switch (p_Identificador) {
            case 'TipoServicio':
                setCmbTipo(record[0]?.RecordSet);
                break;
            case 'TipoAtencion':
                setCmbForma(record[0]?.RecordSet);
                break;
            default:
                break;
        }
    }

    const setVisibleConfirmDialog = () => {
        setSaveConfirmDialog(true);
    }

    const handleCancel = () => {
        setSaveConfirmDialog(false);
    }
    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';

        if (String(VP_Descripcion).trim() === '') {
            vl_message = 'Ingresar la descripcion';
            vl_valida = false;
        } else if (String(VP_Tipo).trim() === '' || VP_Tipo === '0') {
            vl_message = 'Seleccionar tipo';
            vl_valida = false;
        } else if (String(VP_IdEspecialidad).trim() === '' || VP_IdEspecialidad === '0') {
            vl_message = 'Seleccionar especialidad';
            vl_valida = false;
        } else if (Number(VP_CostoServicio) === 0) {
            vl_message = 'Ingresar costo del servicio';
            vl_valida = false;
        }

        return [vl_valida, vl_message];
    }

    const handleOnSubmitform = async () => {

        switch (vl_mode) {
            case 'I': case 'U':
                setVisibleConfirmDialog();
                const IsValidaCampos = onValidarCampos();
                const Isvalido = IsValidaCampos[0];
                const IsMessage = IsValidaCampos[1];
                if (!Isvalido) {
                    toast.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 9000 });
                    return;
                }
                break;
            default:
                break;
        }

        const param = {
            VP_action: vl_mode,
            VP_filter: '',
            VP_CodigoServicio: VP_CodigoServicio,
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_Descripcion: VP_Descripcion,
            VP_Detalle: VP_Detalle,
            VP_Tipo: VP_Tipo,
            VP_IdEspecialidad: VP_IdEspecialidad,
            VP_Estado: VP_Estado ? 'A' : 'D',
            VP_Image: VP_Image,
            VP_Forma: VP_Forma,
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_CostoServicio: VP_CostoServicio
        };

        // console.log(param);
        // return;

        const response = await fetchSinToken('catalogo/pa_set_tipo_servicio', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        if (record) {
            console.log(record);
            const message = record.sql_message;
            toast.current.show({ severity: 'info', summary: 'Message', detail: message, life: 3000 });
            //eventos de la pantalla anterior
            if (record.sql_error === 0) {
                prop.isSetSelectedGrid([])
                prop.setSelectedCategory([]);
                prop.isCallfn();
                prop.isMounted(false);
                prop.isHide();
            }


        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 3000 });
        }
        // console.log(resp)
    }

    // const footer = () => {
    //     return (
    //         <div className="absolute bottom-0 mb-2  ">
    //             <Button label="Grabar" icon="pi pi-save" onClick={() => setVisibleConfirmDialog()} />
    //             <Button label="Cerrar" icon="pi pi-times" onClick={
    //                 () => {
    //                     prop.isMounted(false);
    //                     prop.isHide();
    //                 }
    //             } className="p-button-text" />
    //         </div>
    //     );
    // }

    const renderFooter = () => {
        return (
            <div className="border-top-2 border-yellow-500 pt-1" >
                <Button label="Grabar" icon="pi pi-save" onClick={() => setVisibleConfirmDialog()} />
                <Button label="Cerrar" icon="pi pi-times" className="p-button-text p-button-danger  ml-2"
                    onClick={() => {
                        prop.isMounted(false);
                        prop.isHide();
                    }} />
            </div>
        );
    }
    const renderHeader = () => {
        return (
            <>
                <TitleForm title={vl_mode === 'I' ? ' Crear nuevo servicio/Producto' : 'Editar servicio/Producto '} />
            </>
        );
    }

    return (
        <>
            <Dialog
                header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
                visible={prop.isOpen}
                footer={renderFooter}
                onHide={() => {
                    prop.isMounted(false);
                    prop.isHide();
                }}
            // visible={prop.isOpen} footer={footer} modal position='center' closeOnEscape showHeader={false}
            // style={{ width: '750px', height: '480px' }}
            // onHide={() => {
            //     prop.isMounted(false);
            //     prop.isHide();
            // }}
            >
                <ConfirmDialog visible={b_confirmDialog} onHide={() => setSaveConfirmDialog(false)}
                    message="¿Guardar Registro?"
                    header="Confirmar para continuar"
                    icon="pi pi-exclamation-triangle"
                    accept={handleOnSubmitform}
                    reject={handleCancel}
                />
                <Toast ref={toast} position="top-center"></Toast>
                {/* <TitleForm title={vl_mode === 'I' ? ' Crear nuevo servicio' : 'Editar servicio '} /> */}
                <div className="flex flex-wrap justify-content-start align-items-center p-1 ">
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Código:</label>
                    <InputText type="text" style={{ width: '5rem', textAlign: 'center' }} disabled
                        value={VP_CodigoServicio}
                    />
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Descripción:</label>
                    <InputText type="text" style={{ width: '20rem', textAlign: 'left' }}
                        autoFocus
                        value={VP_Descripcion}
                        onChange={(e) => {
                            setDescripcion(e.currentTarget.value);
                        }}
                    />
                </div>
                <div className="flex flex-wrap justify-content-start align-items-center p-1 ">
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Detalle:</label>
                    <InputText type="text" style={{ width: '33rem', textAlign: 'left' }}
                        value={VP_Detalle}
                        onChange={(e) => {
                            setDetalle(e.currentTarget.value);
                        }}
                    />
                </div>
                <div className="flex flex-wrap justify-content-start align-items-center p-1 ">
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Especialidad:</label>
                    <Dropdown style={{ width: '15rem' }} filter filterBy="Descripcion"
                        value={VP_IdEspecialidad}
                        options={dataEspecialidad}
                        onChange={(e: any) => {
                            setIdEspecialidad(e.value);
                        }}
                        optionValue="IdEspecialidad"
                        optionLabel="Descripcion"
                        placeholder="Seleccionar" />

                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '80px', textAlign: 'end' }}>Tipo:</label>
                    <Dropdown style={{ width: '12rem' }} filter filterBy="Descripcion"
                        value={VP_Tipo}
                        options={dataTipo}
                        onChange={(e) => {
                            setTipo(e.value);
                        }}
                        optionValue="Codigo"
                        optionLabel="Descripcion"
                        placeholder="Seleccionar" />
                </div>
                <div className="flex flex-wrap justify-content-start align-items-center p-1 ">
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>T. Atención:</label>
                    <Dropdown style={{ width: '12rem' }} filter filterBy="Descripcion"
                        value={VP_Forma}
                        options={dataForma}
                        onChange={(e) => {
                            setForma(e.value);
                        }}
                        optionValue="Codigo"
                        optionLabel="Descripcion"
                        placeholder="Seleccionar" />

                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Activo:</label>
                    <InputSwitch
                        checked={VP_Estado}
                        onChange={(e) => setEstado(e.value)}
                    />
                </div>
                <div className="flex flex-wrap justify-content-start align-items-center p-1 ">
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Costo:</label>
                    <InputNumber type="text" inputStyle={{ width: '90px', textAlign: 'right' }}
                        inputClassName='bg-yellow-200 font-bold text-gray-900 border-round '
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        value={VP_CostoServicio}
                        onChange={
                            (e) => {
                                setCostoServicio(Number(e.value));
                                // setInputValMontoPagado(Number(e.value));
                            }
                        }
                    // ref={inputCostoServicioRef}
                    />
                </div>
                <div className="flex flex-wrap justify-content-start align-items-center p-1 ">
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Registrado:</label>
                    <InputText type="text" style={{ width: '11rem', textAlign: 'center' }} disabled
                        value={prop.isData.FechaRegistro ? prop.isData.FechaRegistro : ''}
                    />
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '1px', textAlign: 'end' }}></label>
                    <InputText type="text" style={{ width: '8rem', textAlign: 'center' }} disabled
                        value={prop.isData.UsuarioRegistro ? prop.isData.UsuarioRegistro : ''}
                    />

                </div>
                <div className="flex flex-wrap justify-content-start align-items-center p-1 ">
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '120px', textAlign: 'end' }}>Actualización:</label>
                    <InputText type="text" style={{ width: '11rem', textAlign: 'center' }} disabled
                        value={prop.isData.FechaActualizacion ? prop.isData.FechaActualizacion : ''}
                    />
                    <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ width: '1px', textAlign: 'end' }}></label>
                    <InputText type="text" style={{ width: '8rem', textAlign: 'center' }} disabled
                        value={prop.isData.UsuarioActualizacion ? prop.isData?.UsuarioActualizacion : ''}
                    />
                </div>

            </Dialog>

        </>
    )
}

export default ServicioForm
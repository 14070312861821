
const TitleForm = ({ ...prop }) => {
    
    return (
        <>
            <div className="w-100 pb-1 text-white bg-indigo-600 border-round-bottom mb-1">            
                <div className="col mt-0 ml-0 text-lg font-italic">
                    {prop.title}
                </div>
            </div>
        </>
    )
}
export default TitleForm;
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import { fetchSinToken } from '../../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import { FooterGridQuantity } from '../../../components/util/FooterGrid';

const ReporteAdmisionDetallado = ({ ...prop }) => {

    const [vl_Fecha1, setFecha1] = useState<any>(new Date());
    const [vl_Fecha2, setFecha2] = useState<any>(new Date());
    const [vl_IdSucursal, setIdSucursal] = useState<any>(0);
    const [vl_CodigoServicio, setCodigoServicio] = useState<any>('');
    const [vl_FiltroEdad, setFiltroEdad] = useState<any>(0);
    const [vl_Edad, setEdad] = useState<any>(0);
    const [vl_Edad1, setEdad1] = useState<any>(0);
    const [vl_Genero, setGenero] = useState<any>('');
    const [vl_Distrito, setDistrito] = useState<any>('');
    const [vl_IdStaff, setIdStaff] = useState<any>(0);
    // combos    
    const [dataSede, setDataSede] = useState<any>([]);
    // const [dataStaff, setDataStaff] = useState<any>([]);
    const [dataServicio, setDataServicio] = useState<any>([]);
    const [dataDistrito, setDataDistrito] = useState<any>([]);

    //grid data
    const [dataGrid, setDataGrid] = useState<any>([]);

    const toast = useRef<any>(null);
    const [vl_disabledEdad1, setDisabledEdad1] = useState<boolean>(true);


    useEffect(() => {
        pa_get_sys_sucursal();
        // pa_get_staff();
        pa_get_tipo_servicio();
        pa_get_ubigeo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_sys_sucursal = async () => {
        const param = {
            "VP_IdSucursal": 0
        }
        const response = await fetchSinToken('selectors/pa_get_sys_sucursal', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataSede(record);
    }
    // const pa_get_staff = async () => {
    //     const param = {
    //         "vp_opcion": "A",
    //         "vp_IdStaff": 0,
    //         "VP_Codigo": "",
    //         "VP_Nombres": "",
    //         "VP_Apellidos": ""
    //     }
    //     const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
    //     const resp = await response.json();
    //     const record = resp.data;
    //     setDataStaff(record);
    // }

    const pa_get_tipo_servicio = async () => {
        const param = {
            "vp_Tipo": "S", //all
            "vp_estado": ""
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_servicio', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataServicio(record);
    }

    const pa_get_ubigeo = async () => {
        const param = {
            "vp_opcion": "S",
            "vp_DepartamentoInei": "",
            "vp_ProvinciaInei": ""
        }
        const response = await fetchSinToken('selectors/pa_get_ubigeo', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataDistrito(record);
    }

    const pa_get_admision_rep_detallado = async () => {
        const param = {
            "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
            "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
            "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
            "vp_CodigoServicio": vl_CodigoServicio ? vl_CodigoServicio : '',
            "vp_FiltroEdad": vl_FiltroEdad ? vl_FiltroEdad : 0,
            "vp_Edad": vl_Edad,
            "vp_Edad1": vl_Edad1,
            "vp_Genero": vl_Genero ? vl_Genero : '',
            "vp_Distrito": vl_Distrito ? vl_Distrito : '',
            "vp_IdStaff": 0
        }

        if (vl_FiltroEdad) {
            if (vl_FiltroEdad === 4) {
                if (vl_Edad <= 0 || vl_Edad1 <= 0) {
                    toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar rango de edades', life: 8000 });
                    return;
                }
                if (vl_Edad > vl_Edad1) {
                    toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Rango de edades no válido', life: 8000 });
                    return;
                }
            }
            if (vl_FiltroEdad !== 4 && vl_Edad <= 0) {
                toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar edad', life: 8000 });
                return;
            }

        }

        const response = await fetchSinToken('admision/pa_get_admision_rep_detallado', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record.length);
        if (record.length === 0) {
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No hay registros', life: 8000 });
            setDataGrid([]);
            return;
        }
        setDataGrid(record);
    }

    const pa_get_admision_rep_detallado_xls = async () => {
        let vp_IdSucursal = vl_IdSucursal ? vl_IdSucursal : 0;
        let vp_CodigoServicio = vl_CodigoServicio ? vl_CodigoServicio : '';
        let vp_FiltroEdad = vl_FiltroEdad ? vl_FiltroEdad : 0;
        let vp_Genero = vl_Genero ? vl_Genero : '';
        let vp_Distrito = vl_Distrito ? vl_Distrito : '';
        let vp_IdStaff = 0;

        if (vl_FiltroEdad) {
            if (vl_FiltroEdad === 4) {
                if (vl_Edad <= 0 || vl_Edad1 <= 0) {
                    toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar rango de edades', life: 8000 });
                    return;
                }
                if (vl_Edad > vl_Edad1) {
                    toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Rango de edades no válido', life: 8000 });
                    return;
                }
            }
            if (vl_FiltroEdad !== 4 && vl_Edad <= 0) {
                toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar edad', life: 8000 });
                return;
            }
        }
        const endpoint = 'admision/pa_get_rep01Xls/?vp_Fecha1=' + setIntlDateTimeFormat(vl_Fecha1) + '&vp_Fecha2=' + setIntlDateTimeFormat(vl_Fecha2) + '&vp_IdSucursal=' + vp_IdSucursal + '&vp_CodigoServicio=' + vp_CodigoServicio + '&vp_FiltroEdad=' + vp_FiltroEdad + '&vp_Edad=' + vl_Edad + '&vp_Edad1=' + vl_Edad + '&vp_Genero=' + vp_Genero + '&vp_Distrito=' + vp_Distrito + '&vp_IdStaff=' + vp_IdStaff;
        // console.log(endpoint);
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    const footer = () => {
        let vl_TotalBase: any = dataGrid.length;
        return (
            <>
                <div className='flex align-items-stretch justify-content-end'>
                    <FooterGridQuantity Total={vl_TotalBase} Title={'Ttl. Registros:'} />
                </div>
            </>
        );
    }

    return (
        <>
            <Toast ref={toast} position="top-center"></Toast>
            <div className={prop.bhidden + ' card w-full p-2 ml-2'} >
                <div className='card p-1 m-1 bg-gray-50'>
                    <div className="flex flex-wrap card-container w-full ">
                        <div className="flex align-items-center justify-content-center m-0">
                            <label className="p-col-fixed mr-1 ml-2 text-base font-semibold " style={{ textAlign: 'end' }}>Del:</label>
                            <Calendar style={{ width: '150px' }} inputClassName="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true}
                                maxDate={new Date()}
                                value={vl_Fecha1}
                                onChange={(e) => setFecha1(e.value)}
                            />
                            <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Al:</label>
                            <Calendar style={{ width: '150px' }} inputClassName="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true}
                                maxDate={new Date()}
                                value={vl_Fecha2}
                                onChange={(e) => setFecha2(e.value)}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Sede:</label>
                            <Dropdown style={{ width: '12rem' }} showClear className="p-inputtext-sm"
                                value={vl_IdSucursal}
                                options={dataSede}
                                onChange={(e: any) => {
                                    setIdSucursal(e.value);
                                }}
                                optionValue="IdSucursal"
                                optionLabel="Descripcion"
                                placeholder="Selecionar" />
                        </div>
                        <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Servicio:</label>
                            <Dropdown style={{ width: '15rem' }} filter showClear filterBy="Descripcion" className="p-inputtext-sm"
                                value={vl_CodigoServicio}
                                options={dataServicio}
                                onChange={(e: any) => {
                                    setCodigoServicio(e.value);
                                }}
                                optionValue="CodigoServicio"
                                optionLabel="Descripcion"
                                placeholder="Seleccionar" />
                        </div>
                        <div className="flex align-items-center justify-content-center mt-1" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Edad:</label>
                            <Dropdown style={{ width: '9rem' }} showClear className="p-inputtext-sm"
                                value={vl_FiltroEdad}
                                options={
                                    [
                                        { "IdFilter": 1, "Descripcion": "Igual a" },
                                        { "IdFilter": 2, "Descripcion": "Mayor a" },
                                        { "IdFilter": 3, "Descripcion": "Menor a" },
                                        { "IdFilter": 4, "Descripcion": "Entre " }
                                    ]
                                }
                                onChange={(e: any) => {
                                    setFiltroEdad(e.value);
                                    // console.log(e.value);
                                    if (!e.value) {
                                        setEdad(0);
                                        setEdad1(0);
                                        return;
                                    }
                                    if (e.value !== 4) {
                                        setEdad1(0);
                                        setDisabledEdad1(true);
                                    }
                                    if (e.value === 4) {
                                        setEdad1(0);
                                        setDisabledEdad1(false);
                                    }
                                }}
                                optionValue="IdFilter"
                                optionLabel="Descripcion"
                            />
                            <InputNumber type="text" inputStyle={{ width: '3rem', textAlign: 'right' }}
                                inputClassName='p-inputtext-sm ml-1' minFractionDigits={0} maxFractionDigits={0}
                                value={vl_Edad}
                                // ref={inputCostoServicioRef}
                                onChange={(e) => {
                                    setEdad(Number(e.value));
                                }}
                            />
                            <InputNumber type="text" inputStyle={{ width: '3rem', textAlign: 'right' }}
                                inputClassName='p-inputtext-sm ml-1' minFractionDigits={0} maxFractionDigits={0}
                                value={vl_Edad1}
                                disabled={vl_disabledEdad1}
                                onChange={(e) => {
                                    setEdad1(Number(e.value));
                                }}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Genero:</label>
                            <Dropdown style={{ width: '10rem' }} showClear className="p-inputtext-sm"
                                options={
                                    [
                                        { "IdFilter": "M", "Descripcion": "Masculino" },
                                        { "IdFilter": "F", "Descripcion": "Femenino" }
                                    ]
                                }
                                value={vl_Genero}
                                onChange={(e: any) => {
                                    setGenero(e.value);
                                }}
                                optionValue="IdFilter"
                                optionLabel="Descripcion" />
                        </div>
                        <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Distrito:</label>
                            <Dropdown style={{ width: '12rem' }} showClear filter filterBy="Distrito" className="p-inputtext-sm"
                                options={dataDistrito}
                                value={vl_Distrito}
                                onChange={(e: any) => {
                                    setDistrito(e.value);
                                }}
                                optionValue="UbigeoInei"
                                optionLabel="Distrito_1" />

                            <Button title="Buscar"
                                icon="pi pi-search"
                                className="p-button-rounded  ml-2"
                                onClick={() => pa_get_admision_rep_detallado()}
                            />
                            <Button title="Exportar a excel"
                                icon="pi pi-file-excel"
                                className="p-button-rounded p-button-text ml-1 mr-1"
                                label='Excel'
                                onClick={(e: any) => pa_get_admision_rep_detallado_xls()}
                            />
                        </div>
                        {/* <div className="flex align-items-center justify-content-center m-0" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Profesional:</label>
                            <Dropdown style={{ width: '15rem' }} filter showClear filterBy="staff" className="p-inputtext-sm"
                                value={vl_IdStaff}
                                options={dataStaff}
                                onChange={(e: any) => {
                                    setIdStaff(e.value);
                                }}
                                optionValue="IdStaff"
                                optionLabel="staff"
                                placeholder="Profesional" />
                        </div> */}
                    </div>
                </div>
                <div className='card p-1 m-1'>
                    <DataTable size="small" resizableColumns
                        footer={footer}
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        value={dataGrid}
                        selectionMode="single"
                        // selection={selectedGrid1}
                        // onSelectionChange={e => setSelectedGrid1(e.value)}
                        scrollHeight="50vh"
                        paginator
                        rows={100}
                    >
                        <Column field="IdAdmision" header="#ID" style={{ maxWidth: '4rem' }}></Column>
                        <Column field="FechaRegistro" header="Fecha Registro" style={{ maxWidth: '6rem' }} headerClassName="white-space-normal" ></Column>
                        <Column field="Cliente" header="Cliente/Paciente"></Column>
                        <Column field="NumeroDocumento" header="Nº Documento" style={{ maxWidth: '7rem' }} headerClassName="white-space-normal" ></Column>
                        <Column field="NumeroHistoriaClinica" header="Nº HC" style={{ maxWidth: '7rem' }} headerClassName="flex justify-content-center" ></Column>
                        <Column field="FechaNacimiento_1" header="Fecha Nacimiento" style={{ maxWidth: '7rem' }} headerClassName='white-space-normal' bodyClassName="flex justify-content-center" ></Column>
                        <Column field="Edad" header="Edad" style={{ maxWidth: '4rem' }} bodyClassName="flex justify-content-center"></Column>
                        <Column field="Sexo" header="Genero" style={{ maxWidth: '4rem' }} bodyClassName="flex justify-content-center"></Column>
                        <Column field="Descripcion" header="Servicio"></Column>
                        <Column field="CostoTotal" header="Costo" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end"></Column>
                        <Column field="EstadoRegistro_1" header="Estado" style={{ maxWidth: '90px' }}></Column>
                        <Column field="Sede" header="Sede"></Column>
                        <Column field="Direccion_1" header="Dirección" ></Column>
                        <Column field="NombreAcomp" header="Nombre Acompañante" ></Column>
                        <Column field="NumDocumentoAcomp" header="Doc. Acompañante" ></Column>
                        <Column field="ParentescoAcomp" header="Parentesco Acompañante" ></Column>
                    </DataTable>
                </div>
            </div>
        </>
    )
}
export default ReporteAdmisionDetallado;
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react'
import { fetchSinToken } from '../../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import { DatoPaciente } from '../ordenAtencion/DatoPaciente';

import { ListBox } from 'primereact/listbox';
import TitleForm from '../../util/TitleForm';

export const DerivacionPacienteForm = ({ ...props }) => {

  // console.log(props);

  const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
  const [b_visible1, setVisibleConfirmDialog1] = useState<boolean>(false);
  const [vl_ACTION, setAction] = useState<any>('');
  const [vl_IdDerivacion, setIdDerivacion] = useState<any>('0');
  const [vl_IdHistoriaClinica, setIdHistoriaClinica] = useState<any>('0');
  const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
  const [vl_IdStaff, setIdStaff] = useState<any>('');
  const [vl_IdStaffDerivado, setIdStaffDerivado] = useState<any>('');
  const [vl_FechaDerivacion, setFechaDerivacion] = useState<any>(new Date());
  const [vl_TipoDerivacion, setTipoDerivacion] = useState<any>('');
  const [vl_MotivoDerivacion, setMotivoDerivacion] = useState<any>('');
  const [vl_Recomendacion, setRecomendacion] = useState<any>('');
  // const [vl_UsuarioRegistro, setUsuarioRegistro] = useState<any>('');

  const [dataIdStaff, setDataIdStaff] = useState([]);
  const [dataIdStaffDeriv, setDataIdStaffDeriv] = useState([]);

  const [chkEmocional, setChkEmocional] = useState<boolean>(false);
  const [chkConductual, setChkConductual] = useState<boolean>(false);
  const [chkAtencion, setChkAtencion] = useState<boolean>(false);
  const [chkAprendizaje, setChkAprendizaje] = useState<boolean>(false);
  const [chkOcupacional, setChkOcupacional] = useState<boolean>(false);
  const [chkLenguaje, setChkLenguaje] = useState<boolean>(false);
  const [chkPareja, setChkPareja] = useState<boolean>(false);
  const [chkFamiliar, setChkFamiliar] = useState<boolean>(false);
  const [chkAutoestima, setChkAutoestima] = useState<boolean>(false);
  const [chkMotricidad, setChkMotricidad] = useState<boolean>(false);
  const [chkModificacionconducta, setChkModificacionconducta] = useState<boolean>(false);
  const [chkOrientacionVocacional, setChkOrientacionVocacional] = useState<boolean>(false);
  const [chkOtros, setChkOtros] = useState<boolean>(false);
  const [chkOtrosDetalle, setOtrosDetalle] = useState<any>('');


  const [vl_FechaSeguimiento, setFechaSeguimiento] = useState<any>('');
  const [vl_ProfTratante, setProfTratante] = useState<any>('');
  const [vl_ProfTratanteNombre, setProfTratanteNombre] = useState<any>('');
  const [vl_NumeroSesiones, setNumeroSesiones] = useState<any>('0');
  const [vl_FechaAlta, setFechaAlta] = useState<any>('');
  const [vl_Comentario, setComentario] = useState<any>('');
  const [vl_Estado, setEstado] = useState<any>(1);

  // const [vl_DerivacionExterna, setDerivacionExterna] = useState<any>('');
  // const [vl_DerivacionExternaHacia, setDerivacionExternaHacia] = useState<any>('');
  

  const toast = useRef<any>(null);
  const toastConfirm = useRef<any>(null);

  //buscar cliente
  const [selectedPaciente, setSelectedPaciente] = useState(null);
  const [dataSetPacientes, setDataSetPacientes] = useState([]);
  const [vl_NumeroHistoriaClinica_s, setNumeroHistoriaClinica_s] = useState('');
  // const [vl_IdCliente_s, setIdCliente_s] = useState<any>('0');
  const [vl_Nombres_s, setNombres_s] = useState('');
  const [vl_NumeroDocumento_s, setNumeroDocumento_s] = useState('');
  const [dialogSearch, setdialogSearch] = useState<boolean>(false);
  const [qtyResult, setQtyResult] = useState<any>(0);

  // datos de paciente / cliente  
  const [vl_IdCliente, setIdCliente] = useState<any>('0');
  const [vl_Nombres, setNombres] = useState<any>('');
  const [vl_ApPaterno, setApPaterno] = useState<any>('');
  const [vl_ApMaterno, setApMaterno] = useState<any>('');
  const [vl_IdTipoDocumento, setIdTipoDocumento] = useState<any>('');
  const [vl_NumeroDocumento, setNumeroDocumento] = useState<any>('');
  const [vl_Edad, setEdad] = useState<any>('');
  const [vl_Sexo, setSexo] = useState<any>('');


  const setDataCliente = (data: any) => {
    // console.log(data);
    setIdCliente(data.IdCliente);
    setNombres(data.Nombres);
    setApPaterno(data.ApPaterno);
    setApMaterno(data.ApMaterno);
    setIdTipoDocumento(data.tipo_doc);
    setNumeroDocumento(data.NumeroDocumento);
    setNumeroHistoriaClinica(data.NumeroHistoriaClinica);
    setIdHistoriaClinica(data.IdHistoriaClinica);
    setEdad(data.Edad);
    setSexo(data.Sexo);
  }

  // console.log(props);  
  const onIdStaffChange = (e: { value: any }) => {
    setIdStaff(e.value);
  }
  const onIdStaffDerivadoChange = (e: { value: any }) => {
    setIdStaffDerivado(e.value);
  }
  const onIdStaffTratanteChange = (e: { value: any }) => {
    // console.log(e);
    setProfTratante(e.value);
    // console.log(dataIdStaff);
    let itemsfiltered: any = dataIdStaff.filter(function (element: any) {
      return element.IdStaff === e.value;
    });
    // console.log( itemsfiltered[0].Apellidos +', '+ itemsfiltered[0].Nombres  );
    setProfTratanteNombre(itemsfiltered[0].Apellidos + ', ' + itemsfiltered[0].Nombres);
  }

  const pa_get_staff = async () => {
    //e.preventDefault();
    const param = {
      "vp_opcion": "B",
      "vp_IdStaff": "0",
      "VP_Codigo": "",
      "VP_Nombres": "",
      "VP_Apellidos": ""
    }
    const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataIdStaff(record);
    setDataIdStaffDeriv(record);
  }

  // buscar CLIENTE x numero de documento
  const getBuscarCliente = async () => {
    // e.preventDefault();
    const param = {
      "VP_IdCliente": '0',
      "VP_NumeroDocumento": vl_NumeroDocumento_s,
      "VP_NumeroHistoriaClinica": vl_NumeroHistoriaClinica_s,
      "VP_Nombres": vl_Nombres_s,
      "VP_Apellidos": ""
    }
    const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
    const resp = await response.json();
    // const record = resp.data[0];
    setQtyResult(resp.data.length);
    setDataSetPacientes(resp.data);
    // console.log(record);
    // setInitInputValCliente();
    if (!resp.data[0]) {
      const message = 'No se encontro cliente';
      toast.current.show({ severity: 'warn', summary: 'Message', detail: message, life: 10000 });
      // inputNumeroHistoriaClinicaRef.current.focus();
      // inputNumeroHistoriaClinicaRef.current.select();
    }
  }

  useEffect(() => {

    setAction(props.isMode);
    pa_get_staff();

    //  console.log(props.isData.IdCliente);
    if (props.isData.IdCliente) {
      setDataCliente(props.isData);
    }

    switch (props.isMode) {
      case 'I': //INSERT MODE            
        break;
      case 'U': //VIEW MODE   
        getDataDerivacionPaciente();
        break;
      case 'A': //ANULAR MODE  
        getDataDerivacionPaciente();         
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getDataDerivacionPaciente = () => {
    // console.log('xxxxxxxxxxxxxxxxxx') ;
    setDataCliente(props.isData);
    setIdDerivacion(props.isData.IdDerivacion);
    setIdStaff(props.isData.IdStaff>0?props.isData.IdStaff: props.isData.DerivacionExterna );
    setIdStaffDerivado(props.isData.IdStaffDerivado>0?props.isData.IdStaffDerivado:props.isData.DerivacionExternaHacia);   
    setFechaDerivacion(new Date(Date.parse(props.isData.FechaDerivacion)));
    setTipoDerivacion(props.isData.TipoDerivacion);

    if (Object.keys(props.isData.MotivoAreas).length > 0) {
      setChkEmocional(props.isData.MotivoAreas.Emocional === 1 ? true : false);
      setChkConductual(props.isData.MotivoAreas.Conductual === 1 ? true : false);
      setChkAprendizaje(props.isData.MotivoAreas.Aprendizaje === 1 ? true : false);
      setChkOcupacional(props.isData.MotivoAreas.Ocupacional === 1 ? true : false);
      setChkLenguaje(props.isData.MotivoAreas.Lenguaje === 1 ? true : false);
      setChkPareja(props.isData.MotivoAreas.Pareja === 1 ? true : false);
      setChkFamiliar(props.isData.MotivoAreas.Familiar === 1 ? true : false);
      setChkAutoestima(props.isData.MotivoAreas.Autoestima === 1 ? true : false);
      setChkMotricidad(props.isData.MotivoAreas.Motricidad === 1 ? true : false);
      setChkModificacionconducta(props.isData.MotivoAreas.Modificacionconducta === 1 ? true : false);
      setChkOrientacionVocacional(props.isData.MotivoAreas.OrientacionVocacional === 1 ? true : false);
      setChkOtros(props.isData.MotivoAreas.Otros === 1 ? true : false);
      setOtrosDetalle(props.isData.MotivoAreas.OtrosDetalle);
    }
    setMotivoDerivacion(props.isData.MotivoDerivacion.MotivoDerivacion);
    setRecomendacion(props.isData.Recomendacion.Recomendacion);
    //seguimiento
    setFechaSeguimiento(new Date(Date.parse(props.isData.Seguimiento.FechaSeguimiento)));
    setProfTratante(props.isData.Seguimiento.ProfTratante);
    setNumeroSesiones(props.isData.Seguimiento.NumeroSesiones);
    setFechaAlta(new Date(Date.parse(props.isData.Seguimiento.FechaAlta)));
    setComentario(props.isData.Seguimiento.Comentario);
    setEstado(props.isData.Estado);
  }

  const onRejectAction = () => {
    setVisibleConfirmDialog(false);
  }
  const onValidarCampos = () => {
    let vl_valida = true;
    let vl_message = '';

    // console.log(vl_ProfTratante);
    if ( vl_Estado === 0 ) {
      vl_valida = false;
      vl_message = 'No puede editar, el registro esta anulado!';
    } else if (Number(vl_IdCliente) === 0 || (!Number(vl_IdCliente))) {
      vl_valida = false;
      vl_message = 'Seleccionar cliente';
    } else if (vl_IdHistoriaClinica === 0) {
      vl_valida = false;
      vl_message = 'No hay Id de HC para el cliente';
    } else if (vl_IdStaff === 0 || vl_IdStaff === '' || !vl_IdStaff  ) {
      vl_valida = false;
      vl_message = 'Ingresar o seleccionar de donde derivar';
    } else if (vl_IdStaffDerivado===0 || vl_IdStaffDerivado === '' || !vl_IdStaffDerivado ) {
      vl_valida = false;
      vl_message = 'Ingresar o seleccionar a donde derivar';
    } else if (String(vl_TipoDerivacion).trim() === '') {
      vl_valida = false;
      vl_message = 'Seleccionar Tipo Derivacion';
    }
    return [vl_valida, vl_message];

  }

  const setProcesar = () => {
    const IsValidaCampos = onValidarCampos();
    const Isvalido = IsValidaCampos[0];
    const IsMessage = IsValidaCampos[1];
    if (!Isvalido) {
      toastConfirm.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 4000 });
      return;
    }
    // si no hay campos invalidos procesar...    
    setVisibleConfirmDialog(true);
  }

  const setAnular = () => {
    // const IsValidaCampos = onValidarCampos();
    // const Isvalido = IsValidaCampos[0];
    // const IsMessage = IsValidaCampos[1];
    // if (!Isvalido) {
    //   toastConfirm.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 4000 });
    //   return;
    // }
    // si no hay campos invalidos procesar...    
    setVisibleConfirmDialog1(true);
  }


  const accept = () => {
    props.setIsMounted(false);
    props.isHide();
    props.setBuscar();
  }

  const onProcesarDerivacionPaciente = async () => {
    // setVisibleConfirmDialog(false);
    switch (vl_ACTION) {
      case 'I': case 'U':
        setVisibleConfirmDialog(false);
        break;
      case 'A':
        setVisibleConfirmDialog1(false);
        break;
      default:
        break;
    }

    let vl_MotivoAreas_1 = {
      'Emocional': chkEmocional ? 1 : 0,
      'Conductual': chkConductual ? 1 : 0,
      'Atencion': chkAtencion ? 1 : 0,
      'Aprendizaje': chkAprendizaje ? 1 : 0,
      'Ocupacional': chkOcupacional ? 1 : 0,
      'Lenguaje': chkLenguaje ? 1 : 0,
      'Pareja': chkPareja ? 1 : 0,
      'Familiar': chkFamiliar ? 1 : 0,
      'Autoestima': chkAutoestima ? 1 : 0,
      'Motricidad': chkMotricidad ? 1 : 0,
      'Modificacionconducta': chkModificacionconducta ? 1 : 0,
      'OrientacionVocacional': chkOrientacionVocacional ? 1 : 0,
      'Otros': chkOtros ? 1 : 0,
      'OtrosDetalle': chkOtrosDetalle
    };

    let vl_Seguimiento_1 = {
      "FechaSeguimiento": vl_FechaSeguimiento?setIntlDateTimeFormat(vl_FechaSeguimiento):'',
      "ProfTratante": vl_ProfTratante,
      "ProfTratanteNombres": vl_ProfTratanteNombre,
      "NumeroSesiones": vl_NumeroSesiones,
      "FechaAlta": vl_FechaAlta?setIntlDateTimeFormat(vl_FechaAlta):'',
      "Comentario": vl_Comentario
    };

    let vl_MotivoDerivacion_1 = {
      MotivoDerivacion: vl_MotivoDerivacion
    };

    let vl_Recomendacion_1 = {
      Recomendacion: vl_Recomendacion
    };

    const param = {
      VP_ACTION: vl_ACTION,
      VP_IdDerivacion: vl_IdDerivacion,
      VP_IdCliente: vl_IdCliente,
      VP_IdHistoriaClinica: vl_IdHistoriaClinica,
      VP_NumeroHistoriaClinica: vl_NumeroHistoriaClinica,
      VP_IdStaff: Number(vl_IdStaff)?vl_IdStaff:0,
      VP_IdStaffDerivado: Number(vl_IdStaffDerivado)?vl_IdStaffDerivado:0,
      VP_FechaDerivacion: setIntlDateTimeFormat(vl_FechaDerivacion),
      VP_TipoDerivacion: vl_TipoDerivacion,
      VP_MotivoAreas: JSON.stringify(vl_MotivoAreas_1),
      VP_MotivoDerivacion: JSON.stringify(vl_MotivoDerivacion_1),
      VP_Recomendacion: JSON.stringify(vl_Recomendacion_1),
      VP_Seguimiento: JSON.stringify(vl_Seguimiento_1),
      VP_IdUsuario: localStorage.getItem('authIdUsuario'),
      VP_IdSucursal: localStorage.getItem('authIdSucursal'),
      VP_DerivacionExterna: !Number(vl_IdStaff)?vl_IdStaff:'',
      VP_DerivacionExternaHacia: !Number(vl_IdStaffDerivado)?vl_IdStaffDerivado:''
    };

    // console.log(param);
    // return;

    const response = await fetchSinToken('admision/pa_set_registra_derivacion_paciente', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    if (record) {
      // console.log(record);
      const message = record.sql_message;
      if (record.sql_error > 0) {
        toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
      }else{
        toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
        accept();
      }

      // // const p_IdDerivacion = record.IdDerivacion;
      // //mensajes de alerta  
      // if (record.sql_error > 0) {
      //   toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
      // }
      // // console.log(record.sql_error);
      // //Cuando es OK      
      // if (record.sql_error === 0) {
      //   // console.log('xxxxxxxxxxx');
      //   //buildPDFFormatoDerivacion(p_IdDerivacion); //print
      //   ProcesarDerivacionPacienteConfirm(message);
      // }

    } else {
      const message =
        'Code: ' + resp.data.code  +
        'Errno: ' + resp.data.errno  +
        'Sql: ' + resp.data.sql  +
        'SqlState: ' + resp.data.sqlState  +
        'SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 3000 });
    }
  }


  const buildPDFFormatoDerivacion = async (p_IdDerivacion: any) => {
    // setReportView(true);         
    // const config = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
    const endpoint = 'reports/formatoDerivacion/?IdDerivacion=' + p_IdDerivacion;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }

  const renderFooter = () => {
    return (
      <>
        <Button label="Grabar" icon="pi pi-save" disabled={vl_Estado===0?true:false} hidden={ vl_ACTION==='A'?true:false} onClick={() => setProcesar()} />
        <Button label="Anular" icon="pi pi-trash" disabled={vl_Estado===0?true:false} className='p-button-danger' hidden={ vl_ACTION==='A'?false:true} onClick={() => setAnular()} />
        <Button label="Imprimir" icon="pi pi-print" disabled={vl_Estado===0?true:false} className="p-button-text" onClick={() => buildPDFFormatoDerivacion(vl_IdDerivacion)} />
        <Button label="Cerrar" icon="pi pi-times" onClick={() => {
          props.setIsMounted(false);
          props.isHide();
        }} className="p-button-text" />
      </>
    );
  }

  return (
    <>
      <Dialog
        header={<TitleForm title={'Derivación de Paciente/Cliente'} />}
        position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
        visible={props.isOpen}
        footer={renderFooter}
        onHide={() => {
          props.setIsMounted(false);
          props.isHide();
        }}
      >
        <Button label="" tooltip='Buscar Paciente/Cliente'
          onClick={() => setdialogSearch(true)}
          icon="pi pi-user-plus" className="absolute p-button-rounded ml-0 mt-0 p-button-warning"
        />

        <DatoPaciente
          IdCliente={vl_IdCliente}
          Nombre={vl_Nombres} //props.isData.Nombres
          ApPaterno={vl_ApPaterno}
          ApMaterno={vl_ApMaterno}
          IdTipoDocumento={vl_IdTipoDocumento} //DNI/RUC,ETC
          NumeroDocumento={vl_NumeroDocumento}
          NumeroHistoriaClinica={vl_NumeroHistoriaClinica}
          IdHistoriaClinica={vl_IdHistoriaClinica}
          Edad={vl_Edad}
          Sexo={vl_Sexo}
        />

        <TabView className="tabview-custom"
          activeIndex={0}
        >
          <TabPanel header="Derivación" leftIcon="pi pi-directions" >
            <div className="p-field p-grid mb-1">

              <div className="p-col">
                <label  className="p-col-fixed mr-1 font-bold " style={{ width: '110px', textAlign: 'end' }}>Derivar de:</label>
                <Dropdown style={{ height: '35px', width: '280px' }} filter filterBy="staff" editable
                  value={vl_IdStaff}
                  options={dataIdStaff}
                  onChange={onIdStaffChange}
                  optionValue="IdStaff"
                  optionLabel="staff"
                  placeholder="Seleccionar o digitar texto" />

                <label  className="p-col-fixed mr-1 ml-2  font-bold " style={{ textAlign: 'end' }}>Derivar a:</label>
                <Dropdown style={{ height: '35px', width: '280px' }} filter filterBy="staff" editable
                  value={vl_IdStaffDerivado}
                  options={dataIdStaffDeriv}
                  onChange={onIdStaffDerivadoChange}
                  optionValue="IdStaff"
                  optionLabel="staff"
                  placeholder="Seleccionar o digitar texto" />
              </div>
              <div className="p-col pt-1 pb-1" >
                <label className="p-col-fixed mr-1  font-bold " style={{ width: '110px', textAlign: 'end' }}>Fecha:</label>
                <Calendar style={{ width: '135px', height: '30px' }}
                  value={vl_FechaDerivacion}
                  onChange={(e: any) => setFechaDerivacion(e.value)} minDate={new Date()}
                  dateFormat="yy/mm/dd"
                />
                <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Tipo:</label>

                <label className="p-checkbox-label pl-1">
                  <RadioButton className="ml-0"
                    value='T'
                    onChange={e => setTipoDerivacion(e.value)}
                    checked={vl_TipoDerivacion === 'T'}
                  />
                  Temporal</label>


                <label className="p-checkbox-label pl-1">
                  <RadioButton className="ml-3" value='D'
                    onChange={e => setTipoDerivacion(e.value)}
                    checked={vl_TipoDerivacion === 'D'}
                  />
                  Definitiva
                </label>


                <label className="p-checkbox-label pl-1">
                  <RadioButton className="ml-3"
                    value='A'
                    onChange={e => setTipoDerivacion(e.value)}
                    checked={vl_TipoDerivacion === 'A'}
                  />
                  Alternada</label>

              </div>

              <label className="p-col-fixed mr-1 ml-2 font-bold">Marque el(las) áreas(s) que movitivan la derivación del Paciente/Usuario:</label>

              <div className="p-col pt-1" >
                <label className="p-checkbox-label pl-1" style={{ width: '220px' }} >
                  <Checkbox className="ml-3" onChange={e => setChkEmocional(e.checked)} checked={chkEmocional}> </Checkbox>
                  Emocional
                </label>

                <label style={{ width: '150px' }} className="p-checkbox-label pl-1">
                  <Checkbox className="ml-3" onChange={e => setChkLenguaje(e.checked)} checked={chkLenguaje}> </Checkbox>
                  Lenguaje</label>

                <label className="p-checkbox-label pl-1">
                  <Checkbox className="ml-3" onChange={e => setChkModificacionconducta(e.checked)} checked={chkModificacionconducta}> </Checkbox>
                  Modificación de conducta</label>
              </div>
              <div className="p-col pt-1" >
                <label className="p-checkbox-label pl-1" style={{ width: '220px' }}>
                  <Checkbox className="ml-3" onChange={e => setChkConductual(e.checked)} checked={chkConductual}> </Checkbox>
                  Conductual</label>
                <label className="p-checkbox-label pl-1" style={{ width: '150px' }} >
                  <Checkbox className="ml-3" onChange={e => setChkPareja(e.checked)} checked={chkPareja}> </Checkbox>
                  Pareja</label>

                <label className="p-checkbox-label pl-1">
                  <Checkbox className="ml-3" onChange={e => setChkOrientacionVocacional(e.checked)} checked={chkOrientacionVocacional}></Checkbox>
                  Orientación vocacional</label>
              </div>
              <div className="p-col pt-1" >

                <label className="p-checkbox-label pl-1" style={{ width: '240px' }}>
                  <Checkbox className="ml-3" onChange={e => setChkAtencion(e.checked)} checked={chkAtencion}> </Checkbox>
                  Atención y concentración</label>

                <label className="p-checkbox-label pl-1" style={{ width: '150px' }} >
                  <Checkbox className="ml-3" onChange={e => setChkFamiliar(e.checked)} checked={chkFamiliar}> </Checkbox>
                  Familiar</label>

                <label htmlFor="cb1" className="p-checkbox-label pl-1">
                  <Checkbox className="ml-3" onChange={e => setChkOtros(e.checked)} checked={chkOtros}> </Checkbox>
                  Otros</label>
              </div>
              <div className="p-col pt-1" >
                <label className="p-checkbox-label pl-1" style={{ width: '220px' }}>
                  <Checkbox className="ml-3" onChange={e => setChkAprendizaje(e.checked)} checked={chkAprendizaje}> </Checkbox>
                  Aprendizaje</label>

                <label className="p-checkbox-label pl-1" style={{ width: '150px' }} >
                  <Checkbox className="ml-3" onChange={e => setChkAutoestima(e.checked)} checked={chkAutoestima}> </Checkbox>
                  Autoestima</label>
                <label htmlFor="cb1" className="p-checkbox-label pl-1">Especifique si marcó "otros"</label>
              </div>
              <div className="p-col pt-1" >

                <label className="p-checkbox-label pl-1" style={{ width: '220px' }}>
                  <Checkbox className="ml-3" onChange={e => setChkOcupacional(e.checked)} checked={chkOcupacional}> </Checkbox>
                  Ocupacional</label>


                <label className="p-checkbox-label pl-1" style={{ width: '150px' }} >
                  <Checkbox className="ml-3" onChange={e => setChkMotricidad(e.checked)} checked={chkMotricidad}> </Checkbox>
                  Motricidad</label>

                <InputText type="text" className='absolute' style={{ height: '30px', width: '250px' }}
                  value={chkOtrosDetalle}
                  onChange={
                    (e) => {
                      setOtrosDetalle(String(e.currentTarget.value))
                    }
                  }
                />
              </div>
              <div className="p-col pt-3" >
                <label className="font-bold pb-1 w-50 " >Motivo de la derivación:</label>
                <label className="font-bold pl-1 w-50 " >Recomendaciones:</label>
              </div>
              <div className="p-col pt-1" >
                <InputTextarea className='mr-1' rows={4} cols={30} style={{ width: '49%' }}
                  value={vl_MotivoDerivacion}
                  onChange={
                    (e) => {
                      setMotivoDerivacion(String(e.currentTarget.value))
                    }
                  }
                />
                <InputTextarea rows={4} cols={30} style={{ width: '49%' }}
                  value={vl_Recomendacion}
                  onChange={
                    (e) => {
                      setRecomendacion(String(e.currentTarget.value))
                    }
                  }
                />
              </div>

            </div>
          </TabPanel>
          <TabPanel header="Seguimiento" leftIcon="pi pi-eye" >
            <div className="p-col" >
              <label className="text-orange-500 font-ligth pb-1 " >*Para ser llenado por el profesional que recibe al Paciente/Usuario derivado:</label>
            </div>
            <div className="p-col mb-1" >
              <label htmlFor="" className="p-col-fixed mr-1  font-bold " style={{ textAlign: 'end' }}>Fecha:</label>
              <Calendar style={{ width: '135px', height: '30px' }}
                value={vl_FechaSeguimiento}
                onChange={(e: any) => setFechaSeguimiento(e.value)} minDate={new Date()}
                dateFormat="yy/mm/dd"
              />
              <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '110px', textAlign: 'end' }}>Prof. tratante:</label>
              <Dropdown style={{ height: '35px', width: '250px' }} filter filterBy="staff"
                value={vl_ProfTratante}
                options={dataIdStaff}
                onChange={onIdStaffTratanteChange}
                optionValue="IdStaff"
                optionLabel="staff"
                placeholder="Seleccionar" />
            </div>
            <div className="p-col pb-1" >
              <label className="p-col-fixed mr-1 font-bold" style={{ textAlign: 'end' }}>N° de sesiones (aprox) en que se realizará el procedimiento:</label>
              <InputText type="number" style={{ height: '30px', width: '80px' }}
                value={vl_NumeroSesiones}
                onChange={(e: any) => setNumeroSesiones(String(e.currentTarget.value))}
              />
            </div>
            <div className="p-col" >
              <label className="p-col-fixed mr-1  font-bold " style={{ textAlign: 'end' }}>Fecha de alta (en caso la derivación fuera temporal):</label>
              <Calendar style={{ width: '135px', height: '30px' }}
                value={vl_FechaAlta}
                onChange={(e: any) => setFechaAlta(e.value)} minDate={new Date()}
                dateFormat="yy/mm/dd"
              />
            </div>
            <div className="p-col" >
              <label className="p-col-fixed mr-1 mb-1  font-bold " style={{ textAlign: 'end' }}>Comentarios:</label>
              <InputTextarea rows={8} cols={30} style={{ width: '100%' }}
                value={vl_Comentario}
                onChange={(e: any) => setComentario(e.target.value)}
              />
            </div>

          </TabPanel>
        </TabView>

        <Toast ref={toast} position="top-left"></Toast>
        <Toast ref={toastConfirm} position="center"></Toast>

        <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
          message="¿Guardar Registro?"
          header="Confirmar para continuar"
          icon="pi pi-exclamation-triangle"
          accept={onProcesarDerivacionPaciente}
          reject={onRejectAction}
        />
        <ConfirmDialog visible={b_visible1} onHide={() => setVisibleConfirmDialog1(false)}
          message="Anular Registro?"
          header="Confirmar para continuar"
          icon="pi pi-exclamation-triangle"
          accept={onProcesarDerivacionPaciente}
          reject={()=>console.log('cancelado...')}
        />

      </Dialog>

      <Dialog
        visible={dialogSearch}
        style={{ width: '600px', height: '410px' }}
        onHide={() => {
          props.setIsMounted(false);
          props.isHide();
        }
        }
        modal position='center' draggable={true} closeOnEscape
        showHeader={false}
      >
        <div className="w-100 pt-1 pb-2">
          <div className="rounded-bottom mt-0 mb-0 pt-1 pb-2 pr-0 pl-1 font-bold text-white bg-indigo-500">
            Buscar Paciente/Cliente <i className="mr-2 pi pi-search" style={{ 'fontSize': '1em' }}></i>
          </div>
        </div>

        <div className="p-field p-grid mb-2 mt-1">
          <div className="p-col mb-1">
            <label className="p-col-fixed mr-2 font-bold " style={{ width: '150px', textAlign: 'end' }}>N° Documento:</label>
            <InputText type="text" style={{ height: '30px', width: '20%', textAlign: 'center' }} aria-describedby="username1-help"
              maxLength={8}
              value={vl_NumeroDocumento_s}
              // disabled={props.disabledNumeroHistoriaClinica}
              // ref={props.inputNumeroHistoriaClinicaRef}
              onChange={(e: any) => setNumeroDocumento_s(String(e.currentTarget.value).toUpperCase())}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  getBuscarCliente();
                }
              }}
            />
          </div>
          <div className="p-col mb-1">
            <label className="p-col-fixed mr-2 font-bold " style={{ width: '150px', textAlign: 'end' }}>Nombres:</label>
            <InputText type="text" style={{ height: '30px', width: '70%', textAlign: 'left' }} aria-describedby="username1-help"
              maxLength={8}
              value={vl_Nombres_s}
              // disabled={props.disabledNumeroHistoriaClinica}
              // ref={props.inputNumeroHistoriaClinicaRef}
              onChange={(e: any) => setNombres_s(String(e.currentTarget.value).toUpperCase())}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  getBuscarCliente();
                }
              }}
            />
          </div>
          <div className="p-col">
            <label className="p-col-fixed mr-2 font-bold " style={{ width: '150px', textAlign: 'end' }}>N° Historia Clínica:</label>
            <InputText type="text" style={{ height: '30px', width: '120px', textAlign: 'center' }} aria-describedby="username1-help"
              maxLength={8}
              value={vl_NumeroHistoriaClinica_s}
              // disabled={props.disabledNumeroHistoriaClinica}
              // ref={props.inputNumeroHistoriaClinicaRef}
              onChange={(e: any) => setNumeroHistoriaClinica_s(String(e.currentTarget.value).toUpperCase())}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  getBuscarCliente();
                }
              }}
            />
            <Button label=""
              onClick={(e: any) => getBuscarCliente()}
              style={{ height: '35px' }}
              icon="fas fa-search" className="absolute p-button ml-1"
            />
          </div>

          <label className="p-col-fixed mt-2 font-bold ">Resultados: {qtyResult} </label>
          <ListBox
            value={selectedPaciente}
            options={dataSetPacientes}
            onChange={
              (e) => {
                // console.log(e.value);
                setSelectedPaciente(e.value)
                setDataCliente(e.value);
                setdialogSearch(false);
              }
            }
            optionLabel="ClienteFullName"
            listStyle={{ height: '150px' }}
            style={{ width: '100%', height: '160px' }} />
        </div>
        <Button label="Aceptar" icon="pi pi-check" onClick={() => setdialogSearch(false)} />
      </Dialog>

    </>
  )
};


import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import { fetchSinToken } from '../../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { FooterGridNumber } from '../../../components/util/FooterGrid';
import { ScrollPanel } from 'primereact/scrollpanel';

const ReporteResumen = ({ ...prop }) => {

  const [vl_GroupByD, setGroupByD] = useState<boolean>(true);
  const [vl_GroupByM, setGroupByM] = useState<boolean>(false);
  const [vl_GroupByF, setGroupByF] = useState<boolean>(false);
  const [vl_GroupByS, setGroupByS] = useState<boolean>(false);

  const [vl_tipo, setTipo] = useState<any>('E');
  const [vl_Fecha1, setFecha1] = useState<any>(new Date());
  const [vl_Fecha2, setFecha2] = useState<any>(new Date());
  const [vl_IdSucursal, setIdSucursal] = useState<any>(0);
  const [vl_IdUsuario, setIdUsuario] = useState<any>(0);
  const [dataPagosD, setDataPagosD] = useState<any>([]);
  const [dataPagosM, setDataPagosM] = useState<any>([]);
  const [dataPagosF, setDataPagosF] = useState<any>([]);
  const [dataPagosS, setDataPagosS] = useState<any>([]);
  const [dataSede, setDataSede] = useState<any>([]);
  const [dataUsuario, setDataUsuario] = useState<any>([]);

  const toast = useRef<any>(null);

  useEffect(() => {
    pa_get_sys_sucursal();
    pa_get_sys_usuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pa_get_sys_sucursal = async () => {
    const param = {
      "VP_IdSucursal": 0
    }
    const response = await fetchSinToken('selectors/pa_get_sys_sucursal', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataSede(record);
  }
  const pa_get_sys_usuario = async () => {
    const param = {
      "vp_IdUsuario": 0
    }
    const response = await fetchSinToken('selectors/pa_get_sys_usuario', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataUsuario(record);
  }

  const setBtnSearcOnClick = () => {
    if (!vl_GroupByD && !vl_GroupByM && !vl_GroupByF && !vl_GroupByS) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar un tipo de agrupamiento', life: 8000 });
      return;
    }
    if (vl_GroupByD) pa_get_caja_rep_ingresos_sumaryD();
    if (vl_GroupByM) pa_get_caja_rep_ingresos_sumaryM();
    if (vl_GroupByF) pa_get_caja_rep_ingresos_sumaryF();
    if (vl_GroupByS) pa_get_caja_rep_ingresos_sumaryS();
  };

  const pa_get_caja_rep_ingresos_sumaryD = async () => {
    const param = {
      "vp_groupby": 'D',
      "vp_tipo": vl_tipo,
      "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
      "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
      "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
      "vp_IdUsuario": vl_IdUsuario ? vl_IdUsuario : 0
    }
    if (vl_Fecha1 === '' || vl_Fecha1 === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
      return;
    }
    if (vl_Fecha2 === '' || vl_Fecha2 === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
      return;
    }
    const response = await fetchSinToken('caja/pa_get_caja_rep_ingresos_sumary', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataPagosD(record);
  }
  const pa_get_caja_rep_ingresos_sumaryM = async () => {
    const param = {
      "vp_groupby": 'M',
      "vp_tipo": vl_tipo,
      "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
      "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
      "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
      "vp_IdUsuario": vl_IdUsuario ? vl_IdUsuario : 0
    }
    if (vl_Fecha1 === '' || vl_Fecha1 === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
      return;
    }
    if (vl_Fecha2 === '' || vl_Fecha2 === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
      return;
    }
    const response = await fetchSinToken('caja/pa_get_caja_rep_ingresos_sumary', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataPagosM(record);
  }
  const pa_get_caja_rep_ingresos_sumaryF = async () => {
    const param = {
      "vp_groupby": 'F',
      "vp_tipo": vl_tipo,
      "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
      "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
      "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
      "vp_IdUsuario": vl_IdUsuario ? vl_IdUsuario : 0
    }
    if (vl_Fecha1 === '' || vl_Fecha1 === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
      return;
    }
    if (vl_Fecha2 === '' || vl_Fecha2 === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
      return;
    }
    const response = await fetchSinToken('caja/pa_get_caja_rep_ingresos_sumary', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataPagosF(record);
  }
  const pa_get_caja_rep_ingresos_sumaryS = async () => {
    // console.log(vl_IdStaff);
    const param = {
      "vp_groupby": 'S',
      "vp_tipo": vl_tipo,
      "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
      "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
      "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
      "vp_IdUsuario": vl_IdUsuario ? vl_IdUsuario : 0
    }
    if (vl_Fecha1 === '' || vl_Fecha1 === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
      return;
    }
    if (vl_Fecha2 === '' || vl_Fecha2 === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
      return;
    }
    const response = await fetchSinToken('caja/pa_get_caja_rep_ingresos_sumary', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataPagosS(record);
  }

  // Exportacion a EXCEL
  const pa_get_caja_rep_ingresos_sumaryXLS = async () => {

    let vl_GroupByD1: any = vl_GroupByD ? 'D' : '';
    let vl_GroupByM1: any = vl_GroupByM ? 'M' : '';
    let vl_GroupByF1: any = vl_GroupByF ? 'F' : '';
    let vl_GroupByS1: any = vl_GroupByS ? 'S' : '';
    let VL_tipo1 = vl_tipo;
    let VL_Fecha1 = setIntlDateTimeFormat(vl_Fecha1);
    let VL_Fecha2 = setIntlDateTimeFormat(vl_Fecha2);
    let VL_IdSucursal1 = vl_IdSucursal ? vl_IdSucursal : 0;
    let VL_IdUsuario1 = vl_IdUsuario ? vl_IdUsuario : 0;

    if (!vl_GroupByD && !vl_GroupByM && !vl_GroupByF && !vl_GroupByS) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar un tipo de agrupamiento', life: 8000 });
      return;
    }
    if (!vl_Fecha1 || !vl_Fecha1) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
      return;
    }
    if (!vl_Fecha2 || !vl_Fecha2) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
      return;
    }
    const endpoint = 'caja/pa_get_rep04Xls/?vp_GroupByD=' + vl_GroupByD1 +
      '&vp_GroupByM=' + vl_GroupByM1 +
      '&vp_GroupByF=' + vl_GroupByF1 +
      '&vp_GroupByS=' + vl_GroupByS1 +
      '&vp_tipo=' + VL_tipo1 +
      '&vp_Fecha1=' + VL_Fecha1 +
      '&vp_Fecha2=' + VL_Fecha2 +
      '&vp_IdSucursal=' + VL_IdSucursal1 +
      '&vp_IdUsuario=' + VL_IdUsuario1;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }

  const footer1 = () => {
    let VL_TotalPago: any = 0;
    dataPagosD.forEach(
      function (currentValue: any) {
        VL_TotalPago = VL_TotalPago + parseFloat(currentValue.TotalPago);
      }
    );
    return (
      <>
        <div className='flex align-items-stretch justify-content-end'>
          <FooterGridNumber Total={VL_TotalPago} Title={'Total'} />
        </div>
      </>
    );
  }
  const footer2 = () => {
    let VL_TotalPago: any = 0;
    dataPagosM.forEach(
      function (currentValue: any) {
        VL_TotalPago = VL_TotalPago + parseFloat(currentValue.TotalPago);
      }
    );
    return (
      <>
        <div className='flex align-items-stretch justify-content-end'>
          <FooterGridNumber Total={VL_TotalPago} Title={'Total'} />
        </div>
      </>
    );
  }
  const footer3 = () => {
    let VL_TotalPago: any = 0;
    dataPagosF.forEach(
      function (currentValue: any) {
        VL_TotalPago = VL_TotalPago + parseFloat(currentValue.TotalPago);
      }
    );
    return (
      <>
        <div className='flex align-items-stretch justify-content-end'>
          <FooterGridNumber Total={VL_TotalPago} Title={'Total'} />
        </div>
      </>
    );
  }
  const footer4 = () => {
    let VL_TotalPago: any = 0;
    dataPagosS.forEach(
      function (currentValue: any) {
        VL_TotalPago = VL_TotalPago + parseFloat(currentValue.TotalPago);
      }
    );
    return (
      <>
        <div className='flex align-items-stretch justify-content-end'>
          <FooterGridNumber Total={VL_TotalPago} Title={'Total'} />
        </div>
      </>
    );
  }

  const header1 = () => {
    return <>
      <div className='flex justify-content-between align-items-center' >
        <label>Resumen diario</label>
      </div>
    </>
  }
  const header2 = () => {
    return <>
      <div className='flex justify-content-between align-items-center' >
        <label>Resumen mensual</label>        
      </div>
    </>
  }
  const header3 = () => {
    return <>
      <div className='flex justify-content-between align-items-center' >
        <label>Resumen forma de pago</label>        
      </div>
    </>
  }
  const header4 = () => {
    return <>
      <div className='flex justify-content-between align-items-center' >
        <label>Resumen por servicio</label>        
      </div>
    </>
  }


  return (
    <>
      <Toast ref={toast} position="top-center"></Toast>
      <div className={prop.bhidden + ' card w-full p-2 ml-2'} >
        <div className='card p-1 bg-gray-50'>
          <div className="flex justify-content-between p-2 ">
            <div>
              <RadioButton value="E" name="btnTipofecha"
                onChange={(e) => setTipo(e.value)}
                checked={vl_tipo === "E"}
              /><label className="p-col-fixed ml-2 mr-3 font-normal text-sm " style={{ textAlign: 'end' }}>F. Registro</label>
              <RadioButton value="P" name="btnTipofecha"
                onChange={(e) => setTipo(e.value)}
                checked={vl_tipo === "P"}
              /><label className="p-col-fixed ml-1 mr-0 font-normal text-sm " style={{ textAlign: 'end' }}>F. Pago</label>
            </div>
            <div className='p-1' >
              <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Agrupar:</label>
              <Checkbox className="ml-3" onChange={e => {
                setGroupByD(e.checked);
                if (e.checked) pa_get_caja_rep_ingresos_sumaryD();
                else setDataPagosD([]);
              }} checked={vl_GroupByD}> </Checkbox>
              <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Diario</label>

              <Checkbox className="ml-3" onChange={e => {
                setGroupByM(e.checked);
                if (e.checked) pa_get_caja_rep_ingresos_sumaryM();
                else setDataPagosM([]);
              }} checked={vl_GroupByM}> </Checkbox>
              <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Mensual</label>

              <Checkbox className="ml-3" onChange={e => {
                setGroupByF(e.checked);
                if (e.checked) pa_get_caja_rep_ingresos_sumaryF();
                else setDataPagosF([]);
              }} checked={vl_GroupByF}> </Checkbox>
              <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>F. Pago</label>

              <Checkbox className="ml-3" onChange={e => {
                setGroupByS(e.checked);
                if (e.checked) pa_get_caja_rep_ingresos_sumaryS();
                else setDataPagosS([]);
              }} checked={vl_GroupByS}> </Checkbox>
              <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Servicio</label>
            </div>
          </div>
          <div className="flex flex-wrap card-container w-full ">
            <div className="flex align-items-center justify-content-center m-1" >
              <label className="p-col-fixed mr-1 ml-2 text-base font-semibold " style={{ textAlign: 'end' }}>Del:</label>
              <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                maxDate={new Date()}
                value={vl_Fecha1}
                onChange={(e) => setFecha1(e.value)}
              />
            </div>
            <div className="flex align-items-center justify-content-center m-1" >
              <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Al:</label>
              <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                maxDate={new Date()}
                value={vl_Fecha2}
                onChange={(e) => setFecha2(e.value)}
              />
            </div>
            <div className="flex align-items-center justify-content-center m-1" >
              <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Sede:</label>
              <Dropdown style={{ width: '12rem' }} showClear
                value={vl_IdSucursal}
                options={dataSede}
                onChange={(e: any) => {
                  setIdSucursal(e.value);
                }}
                optionValue="IdSucursal"
                optionLabel="Descripcion"
                placeholder="Selecionar" />
            </div>
            <div className="flex align-items-center justify-content-center m-1" >
              <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Cajero:</label>
              <Dropdown style={{ width: '10rem' }} showClear filter
                value={vl_IdUsuario}
                options={dataUsuario}
                onChange={(e: any) => {
                  setIdUsuario(e.value);
                }}
                optionValue="IdUsuario"
                optionLabel="UserName"
                placeholder="Seleccionar" />
              <Button title="Buscar"
                icon="pi pi-search"
                className="p-button-rounded  ml-2"
                onClick={() => {
                  setBtnSearcOnClick();
                }}
              />
              <Button label='Excel' title="Exportar a excel"
                icon="pi pi-file-excel"
                className="p-button-rounded p-button-text ml-1 mr-1"
                onClick={(e: any) => pa_get_caja_rep_ingresos_sumaryXLS()}
              />
            </div>
          </div>
        </div>
        <ScrollPanel className='bg-gray-100 pt-2' style={{ width: '100%', height: '60vh' }}>
          <div className="flex flex-wrap justify-content-center w-full ">
            <div className="flex justify-content-center m-1 w-49" >
              <div className='card p-1' style={{ width: '100%' }} >
                <DataTable size="small" resizableColumns footer={footer1} header={header1}
                  responsiveLayout="scroll"
                  columnResizeMode="fit"
                  showGridlines
                  stripedRows
                  scrollable
                  value={dataPagosD}
                  selectionMode="single"
                  scrollHeight="50vh"
                  paginator
                  rows={31}
                >
                  <Column field="Fecha" header="Fecha" style={{ minWidth: '18rem' }}
                    headerClassName="flex justify-content-center"
                    bodyClassName="flex justify-content-center"
                  ></Column>
                  <Column field="TotalPago" header="Total pago" style={{ maxWidth: '10rem' }}
                    headerClassName="flex justify-content-center white-space-normal"
                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    body={(item: any) => {
                      return <InputNumber type="text" inputClassName='border-0 text-green-600 font-bold w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={item.TotalPago}
                      />
                    }}
                  >
                  </Column>
                </DataTable>
              </div>
            </div>
            <div className="flex justify-content-center m-1 w-49" >
              <div className='card p-1' style={{ width: '100%' }} >
                <DataTable size="small" resizableColumns footer={footer2} header={header2}
                  responsiveLayout="scroll"
                  columnResizeMode="fit"
                  showGridlines
                  stripedRows
                  scrollable
                  value={dataPagosM}
                  selectionMode="single"
                  scrollHeight="50vh"
                  paginator
                  rows={50}
                >
                  <Column field="Fecha" header="Mes" style={{ minWidth: '18rem' }}
                    headerClassName="flex justify-content-center"
                    bodyClassName="white-space-normal"
                  >
                  </Column>
                  <Column field="TotalPago" header="Total pago" style={{ maxWidth: '10rem' }}
                    headerClassName="flex justify-content-center white-space-normal"
                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    body={(item: any) => {
                      return <InputNumber type="text" inputClassName='border-0 text-green-600 font-bold w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={item.TotalPago}
                      />
                    }}
                  >
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-content-center w-full ">
            <div className="flex justify-content-center m-1 w-49" >
              <div className='card p-1' style={{ width: '100%' }} >
                <DataTable size="small" resizableColumns footer={footer3} header={header3}
                  responsiveLayout="scroll"
                  columnResizeMode="fit"
                  showGridlines
                  stripedRows
                  scrollable
                  value={dataPagosF}
                  selectionMode="single"
                  scrollHeight="50vh"
                >
                  <Column field="FormaPago" header="Forma Pago" style={{ minWidth: '18rem' }}
                    headerClassName="flex justify-content-center"
                    bodyClassName="white-space-normal"
                  >
                  </Column>
                  <Column field="TotalPago" header="Total pago" style={{ maxWidth: '10rem' }}
                    headerClassName="flex justify-content-center white-space-normal"
                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    body={(item: any) => {
                      return <InputNumber type="text" inputClassName='border-0 text-green-600 font-bold  w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={item.TotalPago}
                      />
                    }}
                  >
                  </Column>
                </DataTable>
              </div>
            </div>
            <div className="flex justify-content-center m-1 w-49" >
              <div className='card p-1' style={{ width: '100%' }} >
                <DataTable size="small" resizableColumns footer={footer4} header={header4}
                  responsiveLayout="scroll"
                  columnResizeMode="fit"
                  showGridlines
                  stripedRows
                  scrollable
                  value={dataPagosS}
                  selectionMode="single"
                  scrollHeight="50vh"
                >
                  <Column field="Servicio" header="Servicio" style={{ minWidth: '18rem', maxWidth: '18rem' }}
                    headerClassName="flex justify-content-center"
                    bodyClassName="white-space-normal"
                  >
                  </Column>
                  <Column field="TotalPago" header="Total pago" style={{ maxWidth: '10rem' }}
                    headerClassName="flex justify-content-center white-space-normal"
                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    body={(item: any) => {
                      return <InputNumber type="text" inputClassName='border-0 text-green-600 font-bold w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        locale="en-US" prefix='S/ ' readOnly value={item.TotalPago}
                      />
                    }}
                  >
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </ScrollPanel>
      </div>
    </>
  )
}

export default ReporteResumen;
import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from 'react';

// import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';

import { OrdenAtencionForm } from '../../components/ordenPago/ordenAtencion/OrdenAtencionForm';
import { OrdenTerapiaForm } from '../../components/ordenPago/ordenTerapia/OrdenTerapiaForm';
import useModal from '../../../context/WindowsOpen';
import { fetchSinToken } from '../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../helpers/functions';
import { Toast } from 'primereact/toast';
// import { InputNumber } from 'primereact/inputnumber';
import { AppContext01 } from '../../../context/AppContext';
import { FooterGridNumber } from '../../components/util/FooterGrid';
import BuscadorClientes from '../../components/util/BuscadorClientes';
// import { Badge } from 'primereact/badge';


const OrdenPagoView = () => {
  // console.log('OrdenAtencionView');
  //controla el estado de renderizacion: AdmisionForm 
  const isMountedOA = useRef(false);
  const isMountedOT = useRef(false);
  // new Date(year, month, day, hours, minutes, seconds, milliseconds)  
  const [valFechaDesde, setvalFechaDesde] = useState<any>(new Date(new Date().getFullYear(), new Date().getMonth(), 1 ) );
  const [valFechaHasta, setvalFechaHasta] = useState<any>(new Date());
  const [valNumeroHistoria, setvalNumeroHistoria] = useState('');
  const [ordenAtencion, setOrdenAtencion] = useState([]);

  //PARA FORMULARIO REGISTRO
  const [isMode, setMode] = useState('I');
  const [selectedGrid1, setSelectedGrid1] = useState<any>([]);

  // Para open/close formularioss
  const [isOpenModalOA, openFormAtencionModal, closeFormAtencionModal] = useModal();
  const [isOpenModalOT, openFormTerapiaModal, closeFormTerapiaModal] = useModal();

  // mensaje
  const toast = useRef<any>(null);

  // const handleClickNew = (e: any) => {
  //   setMode('I');
  //   // setIsOpenOrdenAtencionForm(true);      
  //   isMounted.current = true;
  //   openAdmisionModal();
  // }

  const handleClickGeneraOP = (e: any) => {
    // console.log(selectedGrid1);
    setMode('G');
    // console.log(isMode);
    if (Object.keys(selectedGrid1).length <= 0) {
      const message = 'Seleccionar una orden';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 8000 });
      return;
    }
    if (selectedGrid1.EstadoRegistro === 'A') {
      const message = 'La orden se encuentra ANULADA';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 8000 });
      return;
    }

    // console.log(selectedGrid1.TipoRegistro);
    switch (selectedGrid1.TipoRegistro) {
      case "A":
        isMountedOA.current = true;
        openFormAtencionModal();
        break;
      case "T":
        isMountedOT.current = true;
        openFormTerapiaModal();
        break;
      default:
        break;
    }

  }

  //ANULAR ORDE DE PAGO
  const handleClickAnular = (e: any) => {
    setMode('A');
    if (Object.keys(selectedGrid1).length <= 0) {
      const message = 'Seleccionar una orden';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // console.log(selectedGrid1.EstadoRegistro);
    if (selectedGrid1.EstadoRegistro === 'A') {
      const message = 'La orden se encuentra ANULADA';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // console.log(selectedGrid1);
    if (selectedGrid1.Estado !== 'O') {
      const message = 'No puede anular orden de pago ';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    switch (selectedGrid1.TipoRegistro) {
      case "A":
        isMountedOA.current = true;
        openFormAtencionModal();
        break;
      case "T":
        isMountedOT.current = true;
        openFormTerapiaModal();
        break;
      default:
        break;
    }
  }

  const handleClickImprimir = () => {
    // console.log(e);
    if (Object.keys(selectedGrid1).length <= 0) {
      const message = 'Seleccionar una orden';
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 10000 });
      return;
    }
    if (selectedGrid1.EstadoRegistro === 'A') {
      const message = 'La orden se encuentra ANULADA';
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 10000 });
      return;
    }
    if (selectedGrid1.Estado === 'G') {
      const message = 'No tiene orden de pago generado';
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 10000 });
      return;
    }
    // console.log(selectedGrid1);
    //Union all SQL: IdOrdenAtencion y IdOrdenTerapia => IdOrdenAtencion
    switch (selectedGrid1.TipoRegistro) {
      case "A":
        setReporsOrdenAtenciontOP(selectedGrid1.IdOrdenAtencion);
        break;
      case "T":
        setReportsOrdenTerapiaOP(selectedGrid1.IdOrdenAtencion);
        break;
      default:
        break;
    }

  }

  const setReportsOrdenTerapiaOP = async (in_IdOrdenTerapia: any) => {
    const endpoint = 'reports/ordenTerapiaOP/?IdOrdenTerapia=' + in_IdOrdenTerapia;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }

  const setReporsOrdenAtenciontOP = async (in_IdOrdenAtencion: any) => {
    const endpoint = 'reports/ordenAtencionOP/?IdOrdenAtencion=' + in_IdOrdenAtencion;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }




  // Agrega titulo a la barra de navegacion superior 
  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/Proceso/Generar orden de pago');
  }

  const setIsMountedOA = (val: boolean) => {
    isMountedOA.current = val;
  }

  const setIsMountedOT = (val: boolean) => {
    isMountedOT.current = val;
  }

  const OrdenAtencionFormNew = () => {
    return (
      <OrdenAtencionForm
        isOpen={isOpenModalOA}
        isHide={closeFormAtencionModal}
        setSelectedGrid1={setSelectedGrid1}
        isData={selectedGrid1}
        isMode={isMode}
        setBuscar={setBuscar}
        setIsMounted={setIsMountedOA}
      />
    );
  }

  const OrdenTerapiaFormNew = () => {
    return (
      <OrdenTerapiaForm
        isOpen={isOpenModalOT}
        isHide={closeFormTerapiaModal}
        setSelectedGrid1={setSelectedGrid1}
        isData={selectedGrid1}
        isMode={isMode}
        setBuscar={setBuscar}
        setIsMounted={setIsMountedOT}
      />
    );
  }

  useEffect(() => {
    // console.log( (new Date().getFullYear()) +''+ (new Date().getMonth()+1) + '01' );
    handleSetTitle();
    pa_get_orden();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const pa_get_orden = async () => {
    const param = {
      "vp_NumeroDocumento": '',
      "vp_NumeroHistoria": valNumeroHistoria,
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      VP_IdSucursal: localStorage.getItem('authIdSucursal')
    }
    const response = await fetchSinToken('ordenPago/pa_get_orden', param, 'POST');
    const resp = await response.json();

    // console.log('pa_get_orden:');
    // console.log(resp.data);

    if (resp.data.errno) {
      let message = 'Code:' + resp.data.code;
      message = message + ' Errno:' + resp.data.errno;
      message = message + ' Sql:' + resp.data.sql;
      message = message + ' SqlState:' + resp.data.sqlState;
      message = message + ' SqlMessage:' + resp.data.sqlMessage;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 10000 });

    } else {
      if (resp.data.length > 0)
        setOrdenAtencion(resp.data);
      else
        toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No available data', life: 10000 });
    }

  }
  
  const pa_get_orden02 = async (IN_NumeroHistoria:any) => {
    const param = {
      "vp_NumeroDocumento": '',
      "vp_NumeroHistoria": IN_NumeroHistoria,
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      VP_IdSucursal: localStorage.getItem('authIdSucursal')
    }
    const response = await fetchSinToken('ordenPago/pa_get_orden', param, 'POST');
    const resp = await response.json();

    // console.log('pa_get_orden:');
    // console.log(resp.data);

    if (resp.data.errno) {
      let message = 'Code:' + resp.data.code;
      message = message + ' Errno:' + resp.data.errno;
      message = message + ' Sql:' + resp.data.sql;
      message = message + ' SqlState:' + resp.data.sqlState;
      message = message + ' SqlMessage:' + resp.data.sqlMessage;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 10000 });

    } else {
      if (resp.data.length === 0)
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No results found', life: 10000 });
      setOrdenAtencion(resp.data);  
    }

  }

  const setBuscar = () => {
    pa_get_orden();
  }


  const footer = () => {
    let vl_Total: any = 0;
    ordenAtencion.forEach(
      function (currentValue: any) {
        vl_Total = vl_Total + parseFloat(currentValue.Total);
      }
    );
    return (
      <>
        <FooterGridNumber Total={vl_Total} />
      </>
    );
  }

  // new buscador clientes
  const [isOpenModal1, openModal1, closeModal1] = useModal(); //buscador
  const isMounted = useRef(false);
  const handleClickBuscar = (e: any) => {
    isMounted.current = true;
    openModal1();
  }
  const setIsMounted2 = (val: boolean) => {
    isMounted.current = val;
  }
  const BuscadorClientesForm = () => {
    return (
      <BuscadorClientes
        isOpen={isOpenModal1}
        isHide={closeModal1}
        getDataCliente={getDataCliente}
        setIsMounted={setIsMounted2}
      />
    );
  }
  const getDataCliente = (data: any) => {
    // console.log(data.NumeroDocumento);    
    pa_get_orden02(data.NumeroHistoriaClinica);
  }


  return (
    <>
      <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
      <div className="card p-1 mt-0" style={{ marginTop: '.1em' }}  >        
        <div className='flex justify-content-between' >
          <div className='md:border-solid border-yellow-500 shadow-1 m-2 flex align-items-center justify-content-center p-1' >
            <label className='text-sm font-italic'>* Generar ordenes de pago en base a una orden de atención o terapia</label>  
          </div>
          <div className="block bg-gray-10 font-bold text-end p-0 mb-2 flex justify-content-end">            
            <span className="p-buttonset">
              <Button
                label="Generar O. Pago"
                icon="pi pi-wallet"
                onClick={(e) => handleClickGeneraOP(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Anular"
                icon="pi pi-trash"
                onClick={(e) => handleClickAnular(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Imprimir"
                icon="pi pi-print"
                onClick={() => handleClickImprimir()}
                className="p-button-sm p-button-primary p-button-raised"
              />
            </span>
          </div>
        </div>

        <div className="card-container gray-container">          
          <div className="block bg-gray-100 filter-view font-bold text-left p-1  mb-1">
            <div className="p-field p-grid mb-1">
              <div className="p-col">
                <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Del:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                  maxDate={new Date()}
                  value={valFechaDesde}
                  onChange={(e) => setvalFechaDesde(e.value)}
                />
                <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>Al:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                  maxDate={new Date()}
                  value={valFechaHasta}
                  onChange={(e) => setvalFechaHasta(e.value)}
                />
                <label className="p-col-fixed ml-1 mr-1 font-bold " style={{ textAlign: 'end' }}>N° HC:</label>
                <InputText type="text" style={{ width: '120px' }} className="p-inputtext-sm" autoFocus
                  value={valNumeroHistoria}
                  onChange={(e) => setvalNumeroHistoria(String(e.currentTarget.value).toUpperCase())}
                />
                <Button label="Buscar"
                  onClick={(e) => setBuscar()}
                  icon="pi pi-search" className="p-button ml-2" 
                />
                <Button label="" tooltip='Busqueda de Pacientes'
                  onClick={(e: any) => handleClickBuscar(e)}
                  icon="pi pi-user-plus" className="p-button-rounded mt-0 p-button-warning ml-2 "
                />
              </div>
            </div>

          </div>
          <div className="block bg-gray-100 font-bold text-center p-1  mb-1">
            <DataTable size="small" resizableColumns footer={footer}
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              stripedRows
              scrollable
              value={ordenAtencion}
              dataKey="IdRegistro"
              selectionMode="single"
              selection={selectedGrid1}
              onSelectionChange={e => setSelectedGrid1(e.value)}
              scrollHeight="400px"
              paginator
              rows={10}
            >
              <Column field="TipoRegistro_00" header="Tipo" style={{ maxWidth: '120px' }}   ></Column>
              <Column field="NumeroOrden" header="Nº Orden" style={{ maxWidth: '120px' }}   ></Column>
              <Column field="FechaAtencion_1" header="Fecha" style={{ maxWidth: '120px' }}  ></Column>
              <Column field="HoraAtencion" header="Hora" style={{ maxWidth: '95px' }}  ></Column>
              <Column field="Estado_1" header="Estado" style={{ maxWidth: '125px' }}  ></Column>
              <Column field="NumHistoriaClinica" header="Nº HC" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-center" ></Column>
              <Column field="Paciente" header="Paciente/Usuario"  ></Column>
              <Column field="NumeroDocumento" header="Nº Doc." style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-center" ></Column>
              <Column field="FechaNacimiento_1" header="Fecha Nac." style={{ maxWidth: '125px' }} bodyClassName="flex justify-content-center" ></Column>
              <Column field="Edad" header="Edad" style={{ maxWidth: '70px' }}
                bodyClassName="flex justify-content-center"
              ></Column>
              <Column field="Total" header="Total" style={{ maxWidth: '90px' }}
                bodyClassName="flex justify-content-end text-green-600 font-bold"
              ></Column>
              {/* <Column field="EstadoRegistro" header="Estado" style={{ maxWidth: '90px', alignSelf: 'flex-end' }} body={statusItemTemplate} ></Column> */}
            </DataTable>
          </div>
        </div>
      </div>

      <Toast ref={toast} position="top-center"></Toast>

      <React.Fragment>{
        isMountedOA.current && <OrdenAtencionFormNew />
      }
      </React.Fragment>

      <React.Fragment>{
        isMountedOT.current && <OrdenTerapiaFormNew />
      }
      </React.Fragment>

    </>
  )
}

export default OrdenPagoView

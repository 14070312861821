import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import ReporteIngresos from './ReporteIngresos';
import ReporteSaldos from './ReporteSaldos';
import ReporteResumen from './ReporteResumen';
import { AppContext01 } from '../../../../context/AppContext';

const ReporteCajaView = () => {

    const [vl_hidden1, setHidden1] = useState<any>('');
    const [vl_hidden2, setHidden2] = useState<any>('hidden');
    const [vl_hidden3, setHidden3] = useState<any>('hidden');
    const [vl_btn1, setBtn1] = useState<any>('');
    const [vl_btn2, setBtn2] = useState<any>('p-button-link');
    const [vl_btn3, setBtn3] = useState<any>('p-button-link');
    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/Reportes/Pagos');
    }

    useEffect(() => {
        handleSetTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setHidden = (val: any) => {
        setBtn1('p-button-link');
        setBtn2('p-button-link');
        setBtn3('p-button-link');

        setHidden1('hidden');
        setHidden2('hidden');
        setHidden3('hidden');

        switch (val) {
            case 1:
                setHidden1('');
                setBtn1('');
                break;
            case 2:
                setHidden2('');
                setBtn2('');
                break;
            case 3:
                setHidden3('');
                setBtn3('');
                break;
            default:
                break;
        }
    }

    return (
        <>
            <div className="flex align-items-stretch flex-nowrap p-2   ">
                <div className='flex flex-column bg-gray-200 border-round w-10rem '>
                    <div className='h-4rem border-bottom-1 p-2 mb-3 mr-3 ' >
                        <img src="../icon/quantity.svg" width="100px"></img>
                        <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Reportes</label>
                    </div>
                    <div style={{ height: '70vh' }} className='flex flex-column p-2  '>
                        <Button icon="pi pi-dollar" label='Pagos' style={{ width: '8rem' }} onClick={() => setHidden(1)} className={'p-button-rounded p-button-secondary ' + vl_btn1} />                        
                        <Button icon="pi pi-chart-line" label='Resumen' style={{ width: '8rem' }} onClick={() => setHidden(3)} className={'p-button-rounded p-button-secondary ' + vl_btn3} />
                        <Button icon="pi pi-percentage" label='Saldos' style={{ width: '8rem' }} onClick={() => setHidden(2)} className={'p-button-rounded p-button-secondary ' + vl_btn2} />
                    </div>
                </div>
                <ReporteIngresos bhidden={vl_hidden1} />
                <ReporteSaldos bhidden={vl_hidden2} />
                <ReporteResumen bhidden={vl_hidden3} />
            </div>
        </>
    );
}
export default ReporteCajaView;
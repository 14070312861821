
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useParams, useNavigate } from 'react-router-dom';
import { FooterGridNumber } from '../../util/FooterGrid';

const RegistroVentaForm = () => {

    const params = useParams();
    console.log(params);

    const navigate = useNavigate();
    const btn_gobackto = () => {
        navigate('/CajaVentasView');
    }
    const footer = () => {
        // let vl_Total: any = 0;
        // admision.forEach(
        //   function (currentValue: any) {
        //     vl_Total = vl_Total + parseFloat(currentValue.CostoTotal);
        //   }
        // );
        return (
            <>
                <div className='flex flex-wrap justify-content-end' >
                    <FooterGridNumber Title={'Sub.Total'} Total={0} />
                    <FooterGridNumber Title={'IGV.'} Total={0} />
                    <FooterGridNumber Title={'Descto.'} Total={0} />
                    <FooterGridNumber Title={'Gran Total'} Total={0} />
                </div>
            </>
        );
    }

    return (
        <>
            <div className="flex justify-content-between pt-1 pl-3 ">
                <Button
                    icon="pi pi-arrow-left"
                    onClick={() => btn_gobackto()}
                    label="Back"
                    className="p-button-rounded p-button-warning p-button ml-1"
                />
                <div className='md:border-solid border-blue-500 shadow-1 m-2 flex align-items-center justify-content-center p-1' >
                    <label className='text-base font-italic font-bold'> * Registrar una nueva venta</label>
                </div>

                <div>
                    <span className="p-buttonset">
                        <Button
                            label="Grabar"
                            icon="pi pi-save"
                            // onClick={() => egresoForm()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            // onClick={() => egresoForm()}
                            className="p-button-sm p-button-danger p-button-raised"
                        />
                    </span>
                </div>
            </div>
            <ScrollPanel style={{ height: '700px' }}>
                <div className="flex justify-content-center gray-container p-1  ">
                    <div className="p-field p-grid mb-1">
                        <div className="col bg-bluegray-500 font-bold text-white  ">Datos generales</div>
                        <div className="p-col pt-2 ">
                            <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Id Registro:</label>
                            <InputText type="text" style={{ width: '80px', textAlign: 'center' }} className='font-bold'
                                // value={VP_IdStaff} 
                                readOnly
                            />
                            <label className="p-col-fixed mr-1 ml-1 font-bold text-sm " style={{ textAlign: 'end' }}>Fecha:</label>
                            <Calendar dateFormat="yy/mm/dd"
                                style={{ width: '130px' }}
                                monthNavigator yearNavigator yearRange="1970:2030"
                            // value={VP_FechaNacimento}
                            // ref={inputFechaNacimentoRef}
                            // onChange={(e: any) => setFechaNacimento(e.value)}
                            />
                            <label className="p-col-fixed m-1 font-bold text-sm " style={{ textAlign: 'end' }}>Hora:</label>
                            <InputText type="text" style={{ width: '100px' }} />
                        </div>

                        <div className="p-col pt-0 ">
                            <div className="p-col pt-1 ">
                                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Tipo Documento:</label>
                                <Dropdown
                                    style={{ width: '200px' }}
                                    // value={VP_IdDocumento}
                                    // options={dataIdTipoDocumento}
                                    onChange={(e: any) => {
                                        //   setIdDocumento(e.value);
                                        //   inputNumeroDocumentoRef.current.focus();
                                    }}
                                    optionValue="IdTipoDocumento"
                                    optionLabel="Codigo"
                                    placeholder="Seleccionar" />

                                <label className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>Nº:</label>
                                <InputText type="text"
                                    style={{ width: '130px' }}
                                    className='font-normal'
                                    // value={VP_NumeroDocumento}
                                    onChange={(e) => {
                                        //   setNumeroDocumento(String(e.currentTarget.value).toUpperCase());
                                    }}
                                    // ref={inputNumeroDocumentoRef}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter') {
                                            // inputFechaNacimentoRef.current.focus();
                                        }
                                    }}
                                />
                            </div>
                            
                            {/* <div className="col bg-bluegray-500 font-bold text-white mt-1 ">Cliente/Proveedor</div> */}
                            <div className="p-col mt-1">
                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '120px', textAlign: 'end' }}>Cliente:</label>
                                <InputText type="text" style={{ width: '70%' }}
                                    // value={props.Nombre}
                                    readOnly
                                />
                            </div>
                            <div className="col bg-bluegray-500 font-bold text-white mt-1 ">Detalle</div>
                            <div className="p-col mt-1">
                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm" style={{ textAlign: 'end' }}>Producto/Servicio:</label>
                                <Dropdown style={{ width: '350px' }}
                                    filter filterBy="Descripcion"
                                    // value={IdTipoAtencion }
                                    // options={dataTipoAtencion}
                                    // onChange={(e:any)=>{
                                    //     setIdTipoAtencion(e.value);
                                    // }}
                                    optionValue="CodigoServicio"
                                    optionLabel="Descripcion"
                                    placeholder="Seleccionar" />
                                <label className="pr-2" style={{ textAlign: 'end' }}> </label>
                                <Button
                                    type="button"
                                    icon="pi pi-plus"
                                    // style={{ height: '32px' }}
                                    label="Agregar"
                                    // onClick={(e) => op.current?.toggle(e)}
                                    aria-haspopup
                                    aria-controls="overlay_panel"
                                    className="p-button-small pl-1 pr-2"
                                />
                            </div>
                            <div className="p-col pt-3 ">
                                <DataTable size="small"
                                    resizableColumns
                                    footer={footer}
                                    style={{ height: '300px' }}
                                    // value={target ?? []}  //props.isData.Procedimientos
                                    dataKey="CodigoServicio"
                                    responsiveLayout="scroll"
                                    columnResizeMode="fit"
                                    showGridlines
                                    stripedRows
                                    scrollable
                                    scrollHeight="300px"
                                    selectionMode="single"
                                    editMode="cell"
                                // selection={editingRows}
                                // onSelectionChange={e => setEditingRows(e.value)}
                                >
                                    <Column field="CodigoServicio" header=""
                                        // body={actionBodyTemplate} 
                                        style={{ maxWidth: '64px' }}  ></Column>
                                    <Column field="Descripcion" style={{ maxWidth: '300px' }} header="Detalle producto/servicio"></Column>
                                    <Column field="Cant" header="Cant." style={{ maxWidth: '80px' }}
                                        // body={priceBodyTemplate}
                                        // editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete}
                                        headerClassName="flex justify-content-end"
                                        bodyClassName="flex justify-content-end text-green-800 font-bold"
                                    ></Column>
                                    <Column field="Importe" header="Importe" style={{ maxWidth: '100px' }}
                                        // body={priceBodyTemplate}
                                        // editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete}
                                        headerClassName="flex justify-content-end"
                                        bodyClassName="flex justify-content-end text-green-800 font-bold"
                                    ></Column>
                                    <Column field="Descuento" header="Descto." style={{ maxWidth: '100px' }}
                                        // body={priceBodyTemplate}
                                        // editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete}
                                        headerClassName="flex justify-content-end"
                                        bodyClassName="flex justify-content-end text-green-800 font-bold"
                                    ></Column>
                                    <Column field="Total" header="Total" style={{ maxWidth: '100px' }}
                                        // body={priceBodyTemplate}
                                        // editor={(options) => priceEditor(options)} onCellEditComplete={onCellEditComplete}
                                        headerClassName="flex justify-content-end"
                                        bodyClassName="flex justify-content-end text-green-800 font-bold"
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollPanel>

        </>
    )
}

export default RegistroVentaForm;
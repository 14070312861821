import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';
import { Image } from 'react-bootstrap';
import { DropdownItem } from "reactstrap";
import Menu from './Menu';
import avatar from "../../../assets/img/avatars/avatar.jpg";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

const SidebarLayout = () => {

  const { appState } = useContext(AppContext);
  const authUserFullName = localStorage.getItem('authUserFullName');
  const authUserCargo = localStorage.getItem('authUserCargo');
  const navigate = useNavigate();

  const handleHome = ()=>{
    navigate('/AccountHome');
  }

  return (
    <div className={!appState.isVisibleSidebar ?
      "sidebar sidebar-dark sidebar-fixed hide" :
      "sidebar sidebar-dark sidebar-fixed bg-indigo-900 "} id="sidebar">
      <div className="d-flex bd-highlight">
        <div className="sidebar-content ">
          <div className="sidebar-brand bg-indigo-900">
            <div className="p-1 flex-shrink-1 bd-highlight">
              <Image src="../apple-touch-icon.png" height={140} width={140} />
            </div>
          </div>
          <DropdownItem divider />
          <div className="sidebar-user">
            <img src={avatar} className="img-fluid rounded-circle mb-2" alt="No image avatar"
              onClick={handleHome}
            />
            <div className="font-weight-bold">
              {/* <a href='!#' style={{color:'white'}} onClick={handleHome} >{authUserFullName}</a> */}              
              <Button className='p-button-link p-button-text text-white' onClick={handleHome} label={''+authUserFullName} ></Button>
            </div>
            <small> {authUserCargo} </small>
          </div>
        </div>
      </div>
      <DropdownItem divider />
      <div className="d-flex align-items-stretch" >
        <h6 className="text-secondary ml-3 mt-1">Main</h6>
      </div>
      <Menu />
    </div>
  )
}

export default SidebarLayout

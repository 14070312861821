import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Rating } from 'primereact/rating';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import { Calendar } from 'primereact/calendar';
import { useNavigate, useParams } from 'react-router-dom';
import useModal from '../../../context/WindowsOpen';
import { fetchSinToken } from '../../../helpers/fetch';
import HeaderForm from '../util/HeaderForm';
import ProgressBarUtil from '../util/ProgressBarUtil';
import PacienteDocumentAdjuntos from './PacienteDocumentAdjuntos';
import { setIntlDateTimeFormat, toTimeZone } from '../../../helpers/functions';
import { InputSwitch } from 'primereact/inputswitch';
import { SelectButton } from 'primereact/selectbutton';
import { ProgressBar } from 'react-bootstrap';

const PacientePlanTerapia = () => {
    const params = useParams();
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
    const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>('');
    const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>('');
    const [vl_Nombres, setNombres] = useState<any>('');
    const [vl_Edad, setEdad] = useState<any>(0);
    // const [vl_FechaNacimiento, setFechaNacimiento] = useState<any>('');    
    // const [vl_NumeroDocumento, setNumeroDocumento] = useState<any>('');
    // const [vl_Sexo, setSexo] = useState<any>('');

    //util
    const toast = useRef<any>(null);
    const toastConfirm = useRef<any>(null);

    //COMBO
    const [cbmOrdenTerapia, setCbmOrdenTerapia] = useState([]);

    const [gridDataOrdenTerapiaDetalles, setgridDataOrdenTerapiaDetalles] = useState<any>([]);
    //PLAN DE TERAPIA        
    const [vl_PlanTerapiaIdOrdenTerapia, setPlanTerapiaIdOrdenTerapia] = useState<any>(0);
    const [vl_PlanTerapiaIdOrdenPago, setPlanTerapiaIdOrdenPago] = useState<any>(0);
    const [vl_PlanTerapiaIdPlanpago, setPlanTerapiaIdPlanpago] = useState<any>(0);
    const [vl_PlanTerapiaNumeroOrden, setPlanTerapiaNumeroOrden] = useState<any>('');
    const [vl_PlanTerapiaCodigoServicio, setPlanTerapiaCodigoServicio] = useState<any>('');
    const [vl_PlanTerapiaDescripcion, setPlanTerapiaDescripcion] = useState<any>('');
    const [vl_PlanTerapiaTotalSesiones, setPlanTerapiaTotalSesiones] = useState<any>(0);

    const [vl_PlanNroSesion, setPlanNroSesion] = useState<any>(0);
    const [vl_EvolucionNroSesion, setEvolucionNroSesion] = useState<any>(0);


    //SESIONES
    const [vl_PlanTerapiaFecha, setPlanTerapiaFecha] = useState<any>('');
    const [vl_PlanTerapiaSesion, setPlanTerapiaSesion] = useState<any>('');
    const [vl_IdPlanPagoDetalle, setIdPlanPagoDetalle] = useState<any>('');
    const [vl_PlanTerapiaObjetivo, setPlanTerapiaObjetivo] = useState<any>('');
    const [vl_PlanTerapiaTecnicas, setPlanTerapiaTecnicas] = useState<any>('');
    const [vl_PlanTerapiaTareaConductual, setPlanTerapiaTareaConductual] = useState<any>('');
    const [vl_PlanTerapiaInfComplementaria, setPlanTerapiaInfComplementaria] = useState<any>('');
    const [vl_PlanTerapiaEvolucion, setPlanTerapiaEvolucion] = useState<any>('');
    const [vl_PlanTerapiaIdStaff, setPlanTerapiaIdStaff] = useState<any>('');
    const [vl_PlanTerapiaStaff, setPlanTerapiaStaff] = useState<any>('');

    //ref        
    const refPlanTerapiaFecha = useRef<HTMLElement | HTMLInputElement | any>(null);
    const [vl_TipoRegistro, setTipoRegistro] = useState<any>('PLAN');

    // Campos ocultos segun @vl_TipoRegistro
    const [bhiddenHeader_Objetivo, setHiddenHeader_Objetivo] = useState<boolean>(false);
    const [bhiddenHeader_Tecnicas, setHiddenHeader_Tecnicas] = useState<boolean>(false);
    const [bhiddenHeader_TareaCon, setHiddenHeader_TareaCon] = useState<boolean>(false);
    const [bhiddenHeader_Evolucio, setHiddenHeader_Evolucio] = useState<boolean>(true);

    //DOCUMENTOS ADJUNTOS
    const [dataDocumentosAdjuntos, setDataDocumentosAdjuntos] = useState<any>([]);

    useEffect(() => {
        // console.log(params);
        pa_get_paciente(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_paciente = async (in_IdHistoriaClinica: any) => {
        const param = {
            "VP_IdHistoriaClinica": in_IdHistoriaClinica
        }
        const response = await fetchSinToken('selectors/pa_get_paciente', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        if (record) {
            setNombres(record.Nombres);
            setApellidoPaterno(record.ApellidoPaterno);
            setApellidoMaterno(record.ApellidoMaterno);
            setEdad(record.Edad);
            // setFechaNacimiento(new Date(Date.parse(record.FechaNacimiento)));            
            // setNumeroDocumento(record.NumeroDocumento);
            // setSexo(record.Sexo);
            setNumeroHistoriaClinica(record.NumeroHistoriaClinica);
            pa_get_hc_orden_atencion(record.IdCliente);

            setDataDocumentosAdjuntos([]);
            if (record.DocumentosAdjuntos?.PLAN_TERAPIA) {
                setDataDocumentosAdjuntos(record.DocumentosAdjuntos?.PLAN_TERAPIA);
            }
        }
    }
    const pa_get_hc_orden_atencion = async (IN_IdCliente: any) => {
        const param = {
            "vp_IdCliente": IN_IdCliente,
            "vp_IdSucursal": localStorage.getItem('authIdSucursal'),
            "VP_opcion": 2 //orden de terapia
        }
        const response = await fetchSinToken('paciente/pa_get_hc_orden_atencion', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setCbmOrdenTerapia(record);
        // Item default selected
        if (record[0]?.IdOrdenPago) {
            setPlanTerapiaIdOrdenPago(record[0]?.IdOrdenPago);
            setSelectedItemDefault(record[0]);
        }
    }

    const setSelectedItemDefault = (data: any) => {
        // console.log(data);
        // setgridDataOrdenTerapiaDetalles(JSON.parse(data.PlanTerapia));
        if (data?.PlanTerapia) {
            let vl_PlanTerapia: any = JSON.parse(data.PlanTerapia);
            let vl_DetallePlan: any = [];
            if (vl_PlanTerapia.length === 0) {
                setgridDataOrdenTerapiaDetalles([]);
                return
            };
            //a Nivel del combo
            setPlanTerapiaCodigoServicio(vl_PlanTerapia[0].CodigoServicio);
            setPlanTerapiaDescripcion(vl_PlanTerapia[0].Servicio);
            setPlanTerapiaIdOrdenTerapia(vl_PlanTerapia[0].IdOrdenTerapia);
            setPlanTerapiaNumeroOrden(vl_PlanTerapia[0].NumeroOrden);
            setPlanTerapiaIdPlanpago(vl_PlanTerapia[0].IdPlanpago);
            setPlanTerapiaTotalSesiones(vl_PlanTerapia[0].TotalSesion);
            setPlanNroSesion(vl_PlanTerapia[0].PlanNroSesion);
            setEvolucionNroSesion(vl_PlanTerapia[0].EvolucionNroSesion);

            // detalle de sessiones de cada ORDEN DE TERAPIA
            vl_PlanTerapia[0].Detalle.forEach(
                function (record: any) {
                    let { Plan } = record;
                    let { Evolucion } = record;
                    // console.log(Evolucion);
                    vl_DetallePlan.push({
                        'IdPlanPagoDetalle': record.IdPlanPagoDetalle,
                        'IdStaff': record.IdStaff,
                        'Staff': record.Staff,
                        'Fecha': record.Fecha,
                        'Sesion': record.Sesion,
                        //PLAN
                        'Objetivo': Plan.Objetivo,
                        'Tecnicas': Plan.Tecnicas,
                        'TareaConductual': Plan.TareaConductual,
                        'InfComplementaria': Plan.InfComplementaria,
                        //EVOLUCION
                        'EFechaRegistro': Evolucion.EFechaRegistro,
                        'EUsuarioRegistro': Evolucion.EUsuarioRegistro,
                        'EDescripcion': Evolucion.EDescripcion
                    });
                }
            );
            // console.log(vl_PlanTerapia[0].Detalle[0].Plan);
            // console.log('vl_DetallePlan');
            // console.log(vl_DetallePlan);
            setgridDataOrdenTerapiaDetalles(vl_DetallePlan);
            // setgridDataOrdenTerapiaDetalles(JSON.parse(itemsfiltered[0].PlanTerapia[0].Detalle[0].Plan));
        }
    }

    const setSelectedItem = (id: any) => {
        // console.log(cbmOrdenTerapia);
        let itemsfiltered: any = cbmOrdenTerapia.filter(function (element: any) {
            return element.IdOrdenPago === id;
        });
        // console.log('itemsfiltered:');
        // console.log(itemsfiltered);
        if (itemsfiltered.length === 0) {
            setgridDataOrdenTerapiaDetalles([]);
            setPlanTerapiaFecha((new Date(1, 1, 1900)));
            setPlanTerapiaSesion('');
            setPlanTerapiaCodigoServicio('');
            setPlanTerapiaDescripcion('');
            //Plan
            setPlanTerapiaObjetivo('');
            setPlanTerapiaTecnicas('');
            setPlanTerapiaTareaConductual('');
            setPlanTerapiaInfComplementaria('');
            //Evoluacion
            setPlanTerapiaEvolucion('');
            setPlanTerapiaIdStaff('');
            setPlanTerapiaStaff('');
            return;
        }
        if (itemsfiltered[0].PlanTerapia) {
            // console.log('xxxxxxxxxxxxx');
            // console.log(itemsfiltered);
            let vl_PlanTerapia: any = JSON.parse(itemsfiltered[0].PlanTerapia);
            let vl_DetallePlan: any = [];
            if (vl_PlanTerapia.length === 0) {
                setgridDataOrdenTerapiaDetalles([]);
                return
            };

            //a Nivel del combo
            setPlanTerapiaCodigoServicio(vl_PlanTerapia[0].CodigoServicio);
            setPlanTerapiaDescripcion(vl_PlanTerapia[0].Servicio);
            setPlanTerapiaIdOrdenTerapia(vl_PlanTerapia[0].IdOrdenTerapia);
            setPlanTerapiaNumeroOrden(vl_PlanTerapia[0].NumeroOrden);
            setPlanTerapiaIdPlanpago(vl_PlanTerapia[0].IdPlanpago);
            setPlanTerapiaTotalSesiones(vl_PlanTerapia[0].TotalSesion);
            setPlanNroSesion(vl_PlanTerapia[0].PlanNroSesion);
            setEvolucionNroSesion(vl_PlanTerapia[0].EvolucionNroSesion);

            if (!vl_PlanTerapia[0].Detalle) {
                console.log('no hay vl_PlanTerapia[0].Detalle');
                return
            };
            // detalle de sessiones de cada ORDEN DE TERAPIA
            vl_PlanTerapia[0].Detalle.forEach(
                function (record: any) {
                    let { Plan } = record;
                    let { Evolucion } = record;
                    // console.log(record.Fecha);
                    vl_DetallePlan.push({
                        'IdPlanPagoDetalle': record.IdPlanPagoDetalle,
                        'IdStaff': record.IdStaff,
                        'Staff': record.Staff,
                        // 'Fecha': setIntlDateTimeFormat(record.Fecha),  //record.Fecha,
                        // 'Fecha': (new Date(Date.parse(record.Fecha)) ) ,
                        'Fecha': record.Fecha,
                        'Sesion': record.Sesion,
                        //PLAN
                        'Objetivo': Plan.Objetivo,
                        'Tecnicas': Plan.Tecnicas,
                        'TareaConductual': Plan.TareaConductual,
                        'InfComplementaria': Plan.InfComplementaria,
                        //EVOLUCION
                        'EFechaRegistro': Evolucion.EFechaRegistro,
                        'EUsuarioRegistro': Evolucion.EUsuarioRegistro,
                        'EDescripcion': Evolucion.EDescripcion
                    });
                }
            );
            // console.log(vl_PlanTerapia[0].Detalle[0].Plan);
            // console.log('vl_DetallePlan');
            // console.log(vl_DetallePlan);
            setgridDataOrdenTerapiaDetalles(vl_DetallePlan);
            // setgridDataOrdenTerapiaDetalles(JSON.parse(itemsfiltered[0].PlanTerapia[0].Detalle[0].Plan));
        }


    }

    // crud: PLAN DE TERAPIA
    const setPlanTerapiaAgregar = () => {
        let emptyProduct6 = {
            IdPlanPagoDetalle: 0,
            Fecha: '',
            Sesion: '',
            Objetivo: '',
            Tecnicas: '',
            TareaConductual: '',
            InfComplementaria: '',
            EDescripcion: '',
            IdStaff: '',
            Staff: ''
        };
        
        console.log(vl_PlanTerapiaFecha);
        // Invalid Date
        if(vl_PlanTerapiaFecha === null ){
            toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Fecha no valida', life: 8000 });
            return;
        }
        if (vl_IdPlanPagoDetalle === 0 ) {
            toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Debe seleccionar un registro', life: 8000 });
            return;
        }    
        if (vl_TipoRegistro === 'PLAN') {
            console.log(vl_PlanTerapiaObjetivo,' >>', vl_PlanTerapiaTecnicas, ' >>', vl_PlanTerapiaTareaConductual );
            if (vl_PlanTerapiaObjetivo === '' && vl_PlanTerapiaTecnicas === '' && vl_PlanTerapiaTareaConductual === '') {
                toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Debe ingresar algun dato ', life: 8000 });
                return;
            }
            // if (vl_PlanTerapiaObjetivo === undefined && vl_PlanTerapiaTecnicas === undefined && vl_PlanTerapiaTareaConductual === undefined) {
            //     toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Debe ingresar algun dato ', life: 8000 });
            //     return;
            // }
        } else {
            if (vl_PlanTerapiaEvolucion === '') {
                toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Debe ingresar descrpcion de evolución ', life: 8000 });
                return;
            }
        }

        emptyProduct6 = {
            IdPlanPagoDetalle: vl_IdPlanPagoDetalle,
            Fecha: setIntlDateTimeFormat(vl_PlanTerapiaFecha),
            Sesion: vl_PlanTerapiaSesion,
            Objetivo: vl_PlanTerapiaObjetivo,
            Tecnicas: vl_PlanTerapiaTecnicas,
            TareaConductual: vl_PlanTerapiaTareaConductual,
            InfComplementaria: vl_PlanTerapiaInfComplementaria,
            EDescripcion: vl_PlanTerapiaEvolucion,
            IdStaff: vl_PlanTerapiaIdStaff,
            Staff: vl_PlanTerapiaStaff
        }
        // console.log(emptyProduct6);
        let _products = [...gridDataOrdenTerapiaDetalles];
        let _product = { ...emptyProduct6 };
        if (vl_IdPlanPagoDetalle !== 0) {
            const index = findIndexById6(emptyProduct6.IdPlanPagoDetalle);
            // console.log(index);
            _products[index] = _product;
        }
        /*else {
            _product.AFuncionalId = createId5();
            _product.AFuncionalAntecedente = emptyProduct5.AFuncionalAntecedente;
            _product.AFuncionalConducta = emptyProduct5.AFuncionalConducta;
            _product.AFuncionalConsecuencia = emptyProduct5.AFuncionalConsecuencia
            _products.push(_product);
        }*/
        setgridDataOrdenTerapiaDetalles(_products);
        setPlanTerapiaNuevo();
    }
    const setPlanTerapiaNuevo = () => {
        setIdPlanPagoDetalle(0);
        setPlanTerapiaFecha(new Date());        
        setPlanTerapiaSesion(0);
        //PLAN
        setPlanTerapiaObjetivo('');
        setPlanTerapiaTecnicas('');
        setPlanTerapiaTareaConductual('');
        setPlanTerapiaInfComplementaria('');
        //EVOLUCION
        setPlanTerapiaEvolucion('');
        setPlanTerapiaIdStaff(0);
        setPlanTerapiaStaff('');
    }

    const findIndexById6 = (id: any) => {
        let index = -1;
        for (let i = 0; i < gridDataOrdenTerapiaDetalles.length; i++) {
            if (gridDataOrdenTerapiaDetalles[i].IdPlanPagoDetalle === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    // const createId6 = () => {
    //     let index = 0;
    //     gridDataOrdenTerapiaDetalles.forEach(function (row: any) {
    //         index = parseFloat(row.AFuncionalId);
    //     });
    //     index = index + 1;
    //     return index;
    // }

    // const actionBodyTemplate7 = (row: any) => {
    //     // console.log(row);
    //     let vl_RatingValue = 0;
    //     if (row.PlanTerapiaObjetivo !== '' && row.PlanTerapiaObjetivo) vl_RatingValue = 1;
    //     // console.log(vl_RatingValue);
    //     return (
    //         <React.Fragment>
    //             <Rating value={vl_RatingValue} readOnly stars={1} cancel={false} /> {row.Descripcion}
    //         </React.Fragment>
    //     );
    // }

    const setHidden = (val: any) => {
        switch (val) {
            case 'EVOLUCION':
                setHiddenHeader_Objetivo(true);
                setHiddenHeader_Tecnicas(true);
                setHiddenHeader_TareaCon(true);
                setHiddenHeader_Evolucio(false);
                break;
            default:
                setHiddenHeader_Objetivo(false);
                setHiddenHeader_Tecnicas(false);
                setHiddenHeader_TareaCon(false);
                setHiddenHeader_Evolucio(true);
                break;
        }
    }

    // FIN crud: PLAN DE EVALUACION

    //CRUD MAIN
    const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const [b_ProgressBar, setProgressBar] = useState<boolean>(false);

    const pa_set_historia_clinica_json = async () => {
        // console.log('pa_set_historia_clinica_json');
        setVisibleConfirmDialog(false);

        // const vl_PlanTerapia: any = gridDataOrdenTerapiaDetalles; 
        // console.log(vl_PlanTerapia);
        // return;
        let QPlanNroSesion:any = 0;
        let QEvolucionNroSesion:any = 0;

        const vl_PlanTerapiaDet: any = [];
        gridDataOrdenTerapiaDetalles.forEach(
            function (record: any) {
                if( String(record.Objetivo).trim() !== '' ) QPlanNroSesion++;
                if( String(record.EDescripcion).trim() !== '' ) QEvolucionNroSesion++;

                vl_PlanTerapiaDet.push({
                    'IdPlanPagoDetalle': record.IdPlanPagoDetalle,
                    'IdStaff': record.IdStaff,
                    'Staff': record.Staff,
                    'Fecha': record.Fecha,
                    'Sesion': record.Sesion,
                    'Plan': {
                        'Objetivo': record.Objetivo,
                        'Tecnicas': record.Tecnicas,
                        'TareaConductual': record.TareaConductual,
                        'InfComplementaria': record.InfComplementaria,
                        'UsuarioRegistro':  record.Objetivo?localStorage.getItem('authUserName'):'',
                        'FechaRegistro': record.Objetivo?new Date():''
                    },
                    'Evolucion': {                        
                        'EDescripcion': record.EDescripcion,
                        'EUsuarioRegistro': record.EDescripcion?localStorage.getItem('authUserName'):'',
                        'EFechaRegistro': record.EDescripcion?new Date():''
                    }
                });
            }
        );
        let VL_json: any = {
            "IdOrdenPago": vl_PlanTerapiaIdOrdenPago,
            "IdOrdenTerapia": vl_PlanTerapiaIdOrdenPago,
            "NumeroOrden": vl_PlanTerapiaNumeroOrden,
            "IdPlanpago": vl_PlanTerapiaIdPlanpago,
            "CodigoServicio": vl_PlanTerapiaCodigoServicio,
            "Servicio": vl_PlanTerapiaDescripcion,
            "TotalSesion": vl_PlanTerapiaTotalSesiones,
            "PlanNroSesion": QPlanNroSesion, // vl_PlanNroSesion ***no usado (ver)
            "EvolucionNroSesion": QEvolucionNroSesion, //vl_EvolucionNroSesion ** no usado (ver)
            "Detalle": vl_PlanTerapiaDet
        };

        const param = {
            VP_ACTION: 'PTE',
            VP_IdHistoriaClinica: params.id,
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_json: JSON.stringify(VL_json)
        }
        // console.log(param);
        // return;

        setProgressBar(true);
        const response = await fetchSinToken('paciente/pa_set_historia_clinica_json', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log('RESPONSE:');
        console.log(record);
        if (record) {
            const message = record.sql_message;
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
            //recargar informacion
            // if (record.sql_error === 0) {
            //     getHistoriaClinica(vl_NumeroHistoriaClinica);
            // }

        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
        }
        setProgressBar(false);
    }
    const onRejectAction = () => {
        setVisibleConfirmDialog(false);
    }
    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';

        // if (Number(vl_IdCliente) === 0 || (!Number(vl_IdCliente))) {
        //     vl_valida = false;
        //     vl_message = 'Debe buscar un paciente';
        // }
        return [vl_valida, vl_message];
    }

    const setConfirmarGrabarHistoriaClinicaJson = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toastConfirm.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 4000 });
            return;
        }
        // si no hay campos invalidos procesar...    
        setVisibleConfirmDialog(true);
    }
    //FIN CRUD MAIN
    const navigate = useNavigate();
    const link_formHC = () => {
        // vl_activeIndex = 7 > PLAN TERAPIA
        navigate('/HistoriaClinicaView/' + vl_NumeroHistoriaClinica + '/7');
    }
    const link_form_close = () => {
        navigate('/WelcomePage');
    }

    //open modal (view documents)
    const [isOpenModal01, openModal01, closeModal01] = useModal();
    const isMounted01 = useRef(false);
    const setIsMounted01 = (val: boolean) => {
        isMounted01.current = val;
    }
    const handleClickUploadFiles = (e: any) => {
        // if( parseInt(vl_IdCliente) === 0 ){
        //     messagesHistory.current.show([                                                                                                              
        //         { severity: 'warn', detail: 'No se puede mostrar agenda, busque un paciente primero', life: 10000 }
        //     ]);
        //     return;
        // } 
        isMounted01.current = true;
        openModal01();
    }
    const PacienteDocumentAdjuntosForm = () => {
        return (
            <PacienteDocumentAdjuntos
                isOpen={isOpenModal01}
                isHide={closeModal01}
                setIsMounted={setIsMounted01}
                IdHistoriaClinica={params.id}
                TitleFormulario={'PLAN DE TERAPIA / EVOLUCION'}
                NumeroOrden={vl_PlanTerapiaNumeroOrden}
                ConfigS3={{
                    bucket: dataDocumentosAdjuntos[0]?.DocumentBucket ? dataDocumentosAdjuntos[0].DocumentBucket : 'psisabe.bucket.files',
                    folder: dataDocumentosAdjuntos[0]?.DocumentFolder ? dataDocumentosAdjuntos[0].DocumentFolder : 'HISTORIA_CLINICA',
                    subfolder: dataDocumentosAdjuntos[0]?.DocumentSubFolder ? dataDocumentosAdjuntos[0].DocumentSubFolder : vl_NumeroHistoriaClinica,
                    tipofolder: dataDocumentosAdjuntos[0]?.DocumentTipoFolder ? dataDocumentosAdjuntos[0].DocumentTipoFolder : 'PLAN_TERAPIA',
                    folderCreated: dataDocumentosAdjuntos[0]?.DocumentTipoFolder ? 'S' : 'N'
                }
                }
            />
        );
    }

    return (
        <>
            <Toast ref={toast} position="center"></Toast>
            <ProgressBarUtil
                ProgressBarVisible={b_ProgressBar}
                ProgressBarMessage='Please wait, loading...'
                setProgressBar={setProgressBar}
            />
            <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
                message="¿Guardar registro?"
                header="Confirmar para continuar.."
                icon="pi pi-exclamation-triangle"
                accept={pa_set_historia_clinica_json}
                reject={onRejectAction}
            />
            <React.Fragment>{isMounted01.current && <PacienteDocumentAdjuntosForm />}</React.Fragment>

            <HeaderForm
                TitleForm={'PLAN DE TERAPIA / EVOLUCIÓN.'}
                Paciente={vl_ApellidoPaterno + ' ' + vl_ApellidoMaterno + ', ' + vl_Nombres}
                NumeroHistoriaClinica={vl_NumeroHistoriaClinica}
                Edad={vl_Edad}
                linkGoBack={link_formHC}
                link_form_close={link_form_close}
                setOnClickButtonSave={setConfirmarGrabarHistoriaClinicaJson}
            ></HeaderForm>

            <ScrollPanel style={{ height: '600px' }}>
                {/* Conten */}
                <div className='pl-3 pr-3 bg-white text-gray-800' >
                    <div className="p-field p-grid mt-1 pb-4">
                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                            <p className="font-italic border-round border-0 p-1"> <li>Registrar plan de terapia y evolución</li></p>
                        </div>
                        <SelectButton value={vl_TipoRegistro} options={['PLAN', 'EVOLUCION']}
                            onChange={(e) => {
                                setTipoRegistro(e.value);
                                setHidden(e.value);
                            }}
                        />

                        {/* <div className="p-col pb-2"> */}
                        <div className='flex justify-content-between pb-2 pt-2' >
                            <div className='w-50' >
                                <label className="p-col-fixed mr-1 font-semibold p-1" style={{ width: '100%' }}>Orden de terapia:</label>
                                <Dropdown style={{ width: '100%' }} filter filterBy="Descripcion" showClear
                                    value={vl_PlanTerapiaIdOrdenPago}
                                    options={cbmOrdenTerapia}
                                    onChange={(e: any) => {
                                        console.log(e.value);
                                        setPlanTerapiaIdOrdenPago(e.value);
                                        setSelectedItem(e.value);
                                    }}
                                    optionValue="IdOrdenPago"
                                    optionLabel="Detalle"
                                    placeholder="Seleccionar" />
                            </div>
                            <div className='flex justify-content-end align-items-center ' >
                                <label className="p-col-fixed mr-1 ml-2 text-sm font-semibold pb-2 " style={{ textAlign: 'end' }}>Documentos adjuntos:</label>
                                <Button icon="pi pi-folder" label='Abrir' className="p-button-small p-button-warning"
                                    onClick={(e: any) => { handleClickUploadFiles(e) }}
                                />
                            </div>
                        </div>


                        <div className='p-col w-full p-1 border-bottom-2 border-gray-500 surface-overlay '>

                            <div className='pb-2 pt-3' >

                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 " style={{ width: '100%' }}>Terapia:</label>
                                <InputText type="text" style={{ width: '18rem', textAlign: 'left' }} readOnly
                                    value={vl_PlanTerapiaDescripcion} className='font-semibold text-primary-800'
                                    onChange={(e: any) => {
                                        setPlanTerapiaDescripcion(String(e.currentTarget.value));
                                    }}
                                />
                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 " style={{ width: '100px', textAlign: 'end' }}># Sesiones:</label>
                                <InputText type="text" style={{ width: '5rem', textAlign: 'center' }} readOnly
                                    value={vl_PlanTerapiaTotalSesiones} className='font-semibold text-primary-800'
                                    onChange={(e: any) => {
                                        setPlanTerapiaTotalSesiones(String(e.currentTarget.value));
                                    }}
                                />
                            </div>
                            <div className="flex flex-wrap align-items-center justify-content-start pb-0">
                                <p className="font-italic border-round border-0 p-1">Seleccionar una sesión para actualizar registro. </p>
                            </div>
                            <DataTable size="small"
                                resizableColumns
                                style={{ minWidth: '500px' }} //maxWidth:'700px'
                                value={gridDataOrdenTerapiaDetalles ?? []}
                                dataKey="CodigoServicio"
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                scrollHeight="220px"
                                selectionMode="single"
                                //editMode="row"
                                // onRowEditComplete={onRowEditComplete3}
                                // selection={gridDataEvolucionSelected}
                                onSelectionChange={(e: any) => {
                                    console.log(e.value);
                                    setIdPlanPagoDetalle(e.value.IdPlanPagoDetalle);
                                    let vl_fecha:any = new Date();                                    
                                    if(String(e.value.Fecha).trim()!==''){
                                        // vl_fecha = new Date(Date.parse(e.value.Fecha));
                                        vl_fecha = new Date(Date.parse(toTimeZone(e.value.Fecha,'America/Lima')));
                                    }
                                    // console.log( toTimeZone(vl_fecha,'America/Lima') );                                    
                                    setPlanTerapiaFecha(vl_fecha);
                                    
                                    // setPlanTerapiaFecha(e.value.Fecha);
                                    setPlanTerapiaSesion(e.value.Sesion);
                                    //PLAN
                                    setPlanTerapiaObjetivo(e.value?.Objetivo);
                                    setPlanTerapiaTecnicas(e.value?.Tecnicas);
                                    setPlanTerapiaTareaConductual(e.value?.TareaConductual);
                                    setPlanTerapiaInfComplementaria(e.value?.InfComplementaria);
                                    //EVOLUCION
                                    setPlanTerapiaEvolucion(e.value?.EDescripcion);
                                    setPlanTerapiaIdStaff(e.value?.IdStaff);
                                    setPlanTerapiaStaff(e.value?.Staff);

                                    // refPlanTerapiaFecha.current.focus();
                                }
                                }
                            >

                                <Column field="Fecha" header="Fecha" style={{ maxWidth: '120px' }}></Column>
                                <Column field="Sesion" header="Sesión" style={{ maxWidth: '100px' }}></Column>

                                <Column field="Objetivo" header="Objetivo" hidden={bhiddenHeader_Objetivo} style={{ maxWidth: '300px' }}></Column>
                                <Column field="Tecnicas" header="Técnicas" hidden={bhiddenHeader_Tecnicas} style={{ maxWidth: '300px' }}></Column>
                                <Column field="TareaConductual" header="Tarea conductual" hidden={bhiddenHeader_TareaCon} style={{ maxWidth: '300px' }}></Column>
                                <Column field="EDescripcion" header="Descripcion Evolución" hidden={bhiddenHeader_Evolucio} style={{ maxWidth: '300px' }}></Column>
                                <Column field="Staff" header="Profesional" style={{ maxWidth: '300px' }}></Column>
                            </DataTable>

                        </div>
                        <div className='pt-3 ' >
                            {/* <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                <p className="font-italic border-round border-0 p-1"> <li> Ingresar datos, luego boton Actualizar, finalmente Grabar para confirmar todo</li></p>
                            </div> */}
                            <div className='p-col p-1 w-full ' >

                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 " style={{ width: '100%' }}>Fecha:</label>
                                <Calendar dateFormat="dd-mm-yy" inputClassName="border-green-700"
                                    style={{ width: '11rem' }} showIcon maxDate={new Date()} //monthNavigator  yearNavigator yearRange="1930:2050"
                                    value={vl_PlanTerapiaFecha}
                                    ref={refPlanTerapiaFecha}
                                    onChange={(e: any) => {
                                        // console.log(e.value);
                                        setPlanTerapiaFecha(e.value);
                                    }}
                                />
                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 " style={{ width: '100px', textAlign: 'end' }}>Sesión:</label>
                                <InputText type="text" style={{ width: '4rem', textAlign: 'center' }} readOnly
                                    value={vl_PlanTerapiaSesion} className='font-bold text-green-500'
                                    onChange={(e: any) => {
                                        setPlanTerapiaSesion(String(e.currentTarget.value));
                                    }}
                                />
                                <div className='w-full p-2' >
                                    <ProgressBar min={0} now={vl_PlanTerapiaSesion} max={vl_PlanTerapiaTotalSesiones} label={vl_PlanTerapiaSesion === vl_PlanTerapiaTotalSesiones ? 'Completado!' : 'Sesión ' + vl_PlanTerapiaSesion + ' de ' + vl_PlanTerapiaTotalSesiones} variant={vl_PlanTerapiaSesion === vl_PlanTerapiaTotalSesiones ? 'success' : ''}></ProgressBar>
                                </div>

                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 pt-2 " hidden={bhiddenHeader_TareaCon} style={{ width: '100%' }}>Objetivo:</label>
                                <InputTextarea rows={4} style={{ width: '99%' }} className="border-green-700"
                                    value={vl_PlanTerapiaObjetivo} hidden={bhiddenHeader_Objetivo}
                                    // ref={refPlanEvaResultados}
                                    onChange={(e: any) => {
                                        setPlanTerapiaObjetivo(String(e.currentTarget.value));
                                    }}
                                />
                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 pt-2 " hidden={bhiddenHeader_TareaCon} style={{ width: '100%' }}>Técnicas:</label>
                                <InputTextarea rows={4} style={{ width: '99%' }} className="border-green-700"
                                    value={vl_PlanTerapiaTecnicas} hidden={bhiddenHeader_Tecnicas}
                                    // ref={refPlanEvaResultados}
                                    onChange={(e: any) => {
                                        setPlanTerapiaTecnicas(String(e.currentTarget.value));
                                    }}
                                />
                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 pt-2 " hidden={bhiddenHeader_TareaCon} style={{ width: '100%' }}>Tarea conductual:</label>
                                <InputTextarea rows={4} style={{ width: '99%' }} className="border-green-700"
                                    value={vl_PlanTerapiaTareaConductual} hidden={bhiddenHeader_TareaCon}
                                    // ref={refPlanEvaResultados}
                                    onChange={(e: any) => {
                                        setPlanTerapiaTareaConductual(String(e.currentTarget.value));
                                    }}
                                />
                                <label className="p-col-fixed mr-1 ml-2 font-semibold pb-2 pt-2 " hidden={bhiddenHeader_Evolucio} style={{ width: '100%' }}>Descripcion evolución:</label>
                                <InputTextarea rows={4} style={{ width: '99%' }} className="border-green-700"
                                    value={vl_PlanTerapiaEvolucion} hidden={bhiddenHeader_Evolucio}
                                    // ref={refPlanEvaResultados}
                                    onChange={(e: any) => {
                                        setPlanTerapiaEvolucion(String(e.currentTarget.value));
                                    }}
                                />
                                <div className="p-col pt-1" >
                                    <Button type="button" icon="pi pi-check" label='Agregar'
                                        onClick={(e) => setPlanTerapiaAgregar()}
                                        className="p-button-success mr-1"
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ScrollPanel>
        </>
    )
}

export default PacientePlanTerapia;
import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { fetchSinToken } from '../../../helpers/fetch';
import useModal from '../../../context/WindowsOpen';
import AgendaForm from '../agenda/AgendaForm';

const AgendaPsi = ({ ...prop }) => {
    // console.log(prop);
    const isMounted = useRef(false);
    const [isMode, setMode] = useState('R');
    const [isOpenModal, openAdmisionModal, closeAdmisionModal] = useModal();
    const [selectedDateCalendar, setSelectedDateCalendar] = useState<any>([]);

    const [dataAgenda, setDataAgenda] = useState<any>([]);
    useEffect(() => {
        pa_get_agenda();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.vp_periodo, prop.vp_mes]); //OJO

    const pa_get_agenda = async () => {
        // localStorage.getItem('authIdPerfil')
        // -- 1 ROOT
        // let vl_IdSucursal: any = localStorage.getItem('authIdSucursal');
        // if (localStorage.getItem('authIdPerfil') === '1' && tipo === '*') {
        //     vp_IdStaff = 0;
        //     vl_IdSucursal = 0;
        // }
        const param = {
            "vp_IdAgenda": 0,
            "vp_FechaYear": prop.vp_periodo,
            "vp_FechaMonth": prop.vp_mes,
            "vp_IdStaff": prop.vp_IdStaff,
            VP_IdSucursal: prop.vp_IdSucursal
        }
        // setProgressBar(true);
        const response = await fetchSinToken('selectors/pa_get_agenda', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // setProgressBar(false);        
        setDataAgenda(record);

    }

    const setSelectedDateCalendar_await = async (info: any) => {
        setSelectedDateCalendar(info);
        handleEventClick();
    }

    const handleEventClick = () => {
        setMode('R');
        isMounted.current = true;
        openAdmisionModal();
    }
    const AgendaFormAdd = () => {
        return (
            <AgendaForm
                isOpen={isOpenModal}
                isHide={closeAdmisionModal}
                isMounted={handleSetisMounted}
                isData={selectedDateCalendar}
                isMode={isMode}
                isCallfn={pa_get_agenda}
            />
        );
    }
    const handleSetisMounted = (val: boolean) => {
        // console.log('handleSetisMounted= '+ val );
        isMounted.current = val;
    }

    return (
        <>
            <div className="flex flex-wrap justify-content-end mb-1 opacity-80">
                <div>
                    <label className="col text-sm bg-indigo-500  text-white  " style={{ width: '10rem', textAlign: 'center' }}>Programada</label>
                    {/* <label className="col text-sm bg-pink-600 text-white" style={{ width: '12rem', textAlign: 'center' }}>Cancelada</label> */}
                    <label className="col text-sm bg-purple-500 text-white" style={{ width: '10rem', textAlign: 'center' }}>Confirmada</label>
                    <label className="col text-sm bg-orange-500 text-white" style={{ width: '10rem', textAlign: 'center' }}>Reprogramada</label>
                    <label className="col text-sm bg-green-600 text-white" style={{ width: '10rem', textAlign: 'center' }}>Realizada</label>
                    <label className="col text-sm bg-cyan-500 text-white" style={{ width: '10rem', textAlign: 'center' }}>No Realizada</label>
                </div>
            </div>
            <React.Fragment>{isMounted.current && <AgendaFormAdd />}</React.Fragment>
            <FullCalendar contentHeight={450}
                events={dataAgenda ?? []}
                initialView='dayGridMonth' //listMonth
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={{ left: 'prev,next,today,listMonth', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay' }}
                defaultRangeSeparator=' - '
                titleRangeSeparator=' al '
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                }}
                buttonText={
                    {
                        today: 'Hoy',
                        month: 'Mes',
                        week: 'Semana',
                        day: 'Dia',
                        list: 'Lista'
                    }
                }
                eventClick={function (info: any) {
                    setSelectedDateCalendar_await(info);
                }}
                editable selectable selectMirror dayMaxEvents />
        </>
    )
}

export default AgendaPsi;

import { createContext } from "react";
import { AuthData } from '../interfaces/AuthInterface';

export type AppAuthContextProps = {
    authData: AuthData;
    login:(isAutenticate: boolean) => void; 
    logout:(isAutenticate: boolean) => void;     
    // setAuthData:(data:any) => void  
}

export const AppAuthContext = createContext<AppAuthContextProps>({} as AppAuthContextProps);

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';

export const DetalleCierreCaja = ({ ...props }) => {

    // console.log(props.dataCierreCajaDetalle);    

    // const [vl_FechaCierre, setFechaCierre] = useState<any>(new Date());
    // const [vl_FechaRegistro, setFechaRegistro] = useState<any>(null);
    // const [vL_IdCaja, setIdCaja] = useState<any>(0);
    // const [vl_Cajero, setCajero] = useState<any>('');
    // const [vl_Total, setTotal] = useState<any>(0);
    
    // useEffect(() => {        
    //     const data:any = props.dataCierreCajaDetalle;
    //     setFechaCierre(data[0]?.Fecha); 
    //     setFechaRegistro(data[0]?.FechaRegistro);
    //     setIdCaja(data[0]?.IdCaja);
    //     setCajero(data[0]?.Cajero);
    //     setTotal(data[0]?.Total); 
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, []);

    const data:any = props.dataCierreCajaDetalle;
    const vl_FechaCierre = data[0]?.Fecha;    
    const vl_FechaRegistro = data[0]?.FechaRegistro;
    const vL_IdCaja = data[0]?.IdCaja;
    const vl_Cajero = data[0]?.Cajero;
    const vl_Total = data[0]?.Total;
         
    const pa_get_cierre_caja_rpte = async (vp_IdCaja: any) => {

        const vl_IdSucursal = localStorage.getItem('authIdSucursal');
        const endpoint = 'reports/reportecierreCaja/?IdCaja=' + vp_IdCaja + '&IdSucursal=' + vl_IdSucursal + '&Fecha=' + (vl_FechaCierre);
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    return (
        <>
            <OverlayPanel
                ref={props.oCierreCajaDetalle}
                showCloseIcon={false}
                style={{ width: "850px" }}
            >
                <div className="p-field p-grid mb-1">
                    <div className="p-col mb-1 " >
                        <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Fecha:</label>
                        <InputText type="text" className='border-cyan-500 font-bold text-gray-900 border-round '
                            style={{ height: '35px', width: '115px', textAlign: 'center' }}
                            value={vl_FechaCierre}
                            readOnly
                        />

                        <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Total:</label>
                        <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                            inputStyle={{ height: '35px', width: '110px', textAlign: 'right' }}
                            value={vl_Total}
                            readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        />

                        <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Usuario:</label>
                        <InputText type="text" className='border-cyan-500 font-bold text-gray-900 border-round '
                            style={{ height: '35px', width: '110px', textAlign: 'center' }}
                            value={vl_Cajero}
                            readOnly
                        />

                        <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Procesado:</label>
                        <InputText type="text" className='border-cyan-500 font-bold text-gray-900 border-round '
                            style={{ height: '35px', width: '150px', textAlign: 'center' }}
                            value={vl_FechaRegistro}
                            readOnly
                        />                        
                    </div>

                </div>

                <DataTable
                    // header="Detalle cierre de caja"
                    size="small"
                    value={props.dataCierreCajaDetalle ?? []}
                    dataKey="IdCajaDetalle"
                    resizableColumns
                    responsiveLayout="scroll"
                    scrollHeight="250px"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    selectionMode="single"
                >
                    <Column field="IdPago" header="Nº Rec." style={{ maxWidth: '70px' }} bodyClassName="flex justify-content-center" headerClassName="flex justify-content-center"  ></Column>
                    <Column field="FechaPago" header="Fecha" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-center" ></Column>
                    <Column field="DetalleMovimiento" header="Detalle Pago" style={{ align: 'end' }} headerClassName="flex justify-content-left" bodyClassName="flex justify-content-left"></Column>
                    <Column field="Importe_tcd" header="Importe" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column>
                    <Column field="FormaPago" header="Forma Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column>
                    <Column field="ReferenciaPago" header="Ref. Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column>

                </DataTable>
                <Button
                    type="button"
                    className="p-button-small p-button ml-1 mt-1 "
                    icon="pi pi-print"
                    label="Reporte cierre"
                    onClick={
                        (e) => {
                            pa_get_cierre_caja_rpte(vL_IdCaja)
                        }
                    }
                />
                <Button
                    type="button"
                    className="p-button-small p-button-danger ml-1 mt-1 "
                    icon="pi pi-times"
                    label="Cerrar"
                    onClick={
                        (e) => {
                            props.oCierreCajaDetalle.current?.hide()
                        }
                    }
                />
                {/* <Button label="Reporte Cierre" icon="pi pi-print" hidden={!b_cerrado} className="p-button-text" onClick={() => pa_get_cierre_caja_rpte(0)} /> */}
                
            </OverlayPanel>
        </>
    )
}
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from 'react'
import { fetchSinToken } from '../../../../helpers/fetch';

const ReporteSaldos = ({ ...prop }) => {
  const [dataPeriodo, setDataPeriodo] = useState<any>([]);
  const [vl_periodo, setPeriodo] = useState<any>(new Date().getFullYear());
  const [dataSaldos, setDataSaldos] = useState<any>([]);

  useEffect(() => {
    const periodo: any = new Date().getFullYear();
    let dsPeriodo: any = [];
    let anio = 0;
    for (let i = (periodo - 3); i <= (periodo + 1); i++) {
      anio = i;
      dsPeriodo.push(
        { "lstPeriodo": anio }
      );
    }
    setDataPeriodo(dsPeriodo);
    setPeriodo(periodo);
    pa_get_caja_rep_saldos(periodo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pa_get_caja_rep_saldos = async (IN_periodo: any) => {
    // console.log(vl_IdStaff);
    const param = {
      "VP_Periodo": IN_periodo
    }
    const response = await fetchSinToken('caja/pa_get_caja_rep_saldos', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setDataSaldos(record);
  }

  const pa_get_caja_rep_saldos_xls = async () => {
    const endpoint = 'caja/pa_get_rep03Xls/?VP_Periodo=' + vl_periodo;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }

  return (
    <>
      <div className={prop.bhidden + ' card w-full p-2 ml-2'} >
        <div className='card p-2'>
          <div className="p-field p-grid pl-1 pr-1 pt-1 pb-1 ">
            <div className="p-col ">
              <label className="p-col-fixed mr-1 ml-2 text-base font-semibold " style={{ textAlign: 'end' }}>Periodo:</label>
              <Dropdown style={{ width: '120px' }}
                value={vl_periodo}
                options={dataPeriodo}
                onChange={(e: any) => {
                  setPeriodo(e.value);
                  pa_get_caja_rep_saldos(e.value);
                }}
                optionValue="lstPeriodo"
                optionLabel="lstPeriodo"
                placeholder="" />

              <Button title="Buscar"
                icon="pi pi-search"
                className="p-button-rounded  ml-1"
                onClick={() => pa_get_caja_rep_saldos(vl_periodo)}
              />
              <Button title="Exportar a excel"
                icon="pi pi-file-excel"
                className="p-button-rounded p-button-text ml-1 mr-1"
                label='Excel'
                onClick={(e: any) => pa_get_caja_rep_saldos_xls()}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-content-center w-full ">
          <div className='card p-1' style={{ width: '100%' }}>
            <DataTable
              size="small"
              value={dataSaldos ?? []}
              resizableColumns
              responsiveLayout="scroll"
              columnResizeMode="fit"
              showGridlines
              stripedRows
              scrollable
              scrollHeight="60vh"
              scrollDirection='horizontal'
            >
              <Column field="Concepto" header="Concepto" bodyClassName="flex justify-content-start" style={{ minWidth: '10rem' }} ></Column>
              <Column field="Ene" header="Ene." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Ene} </label>)
                }}
              >
              </Column>
              <Column field="Feb" header="Feb." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Feb} </label>)
                }}
              >
              </Column>
              <Column field="Mar" header="Mar." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Mar} </label>)
                }}
              >
              </Column>
              <Column field="Abr" header="Abr." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Abr} </label>)
                }}
              >
              </Column>
              <Column field="May" header="May." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.May} </label>)
                }}
              >
              </Column>
              <Column field="Jun" header="Jun." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Jun} </label>)
                }}
              >
              </Column>
              <Column field="Jul" header="Jul." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Jul} </label>)
                }}
              >
              </Column>
              <Column field="Ago" header="Ago." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Ago} </label>)
                }}
              >
              </Column>
              <Column field="Sep" header="Sep." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Sep} </label>)
                }}
              >
              </Column>
              <Column field="Oct" header="Oct." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Oct} </label>)
                }}
              >
              </Column>
              <Column field="Nov" header="Nov." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Nov} </label>)
                }}
              >
              </Column>
              <Column field="Dic" header="Dic." style={{ minWidth: '7rem' }}
                headerClassName="flex justify-content-center white-space-normal"
                bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                body={(item: any) => {
                  let style = 'font-semibold';
                  if (Number(item.Orden) === 0) style = 'text-red-600 font-semibold';
                  if (Number(item.Orden) >= 5 && Number(item.Orden) <= 8) style = 'text-green-600 font-semibold';
                  if (Number(item.Orden) >= 9) style = 'text-blue-600 font-bold ';
                  return (<label className={'' + style} > {item.Dic} </label>)
                }}
              >
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReporteSaldos;
import { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import TitleForm from '../../components/util/TitleForm';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { getClaseName, setIntlDateTimeFormat } from '../../../helpers/functions';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';

const ClienteAlertaForm = ({ ...props }) => {

    const [VP_Action, setAction] = useState<any>('I');
    const [VP_IdNotificacion, setIdNotificacion] = useState<any>(0);
    const [VP_IdCliente, setIdCliente] = useState<any>(0);
    const [VP_Prioridad, setPrioridad] = useState<any>(1);
    const [dataPrioridad, setDataPrioridad] = useState<any>([]); //array
    const [dataIdNotificacion, setDataIdNotificacion] = useState<any>([]); //array
    const [VP_Estado, setEstado] = useState<boolean>(true);
    const [VP_Vigencia, setVigencia] = useState<boolean>(false);
    const [VP_FechaVigDesde, setFechaVigDesde] = useState<any>(null);
    const [VP_FechaVigHasta, setFechaVigHasta] = useState<any>(null);
    const [VP_Categoria, setCategoria] = useState<any>('A'); //COMMENT 'A=ALERTAS, I=INFORMATIVOS G=GRAVES',
    const [dataCategoria, setDataCategoria] = useState<any>([]); //COMMENT 'A=ALERTAS, I=INFORMATIVOS G=GRAVES',
    const [VP_Mensaje, setMensaje] = useState<any>('');

    const [vl_Chk1, setChk1] = useState<boolean>(true);
    const [vl_Chk2, setChk2] = useState<boolean>(false);
    const [vl_Chk3, setChk3] = useState<boolean>(false);

    const [vl_FechaVigDesdeDisabled, setFechaVigDesdeDisabled] = useState<boolean>(true);
    const [vl_FechaVigHastaDisabled, setFechaVigHastaDisabled] = useState<boolean>(true);
    
    const [vl_Categoria_info, setCategoria_info] = useState<any>('');
    const toast = useRef<any>(null);

    const [b_visible1, setVisibleConfirmDialog1] = useState<boolean>(false);

    useEffect(() => {
        setAction('I');
        setIdNotificacion(0);
        setDataPrioridad(set_dataPrioridad());
        setDataCategoria(set_dataCategoria());
        setIdCliente(props.isData.IdCliente);
        setOnClickNew();
        // setData();
        setDataAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setData = async (in_IdNotificacion:any) => {
        // console.log(props.isData);
        if (in_IdNotificacion>0) {
            const param = {
                "VP_IdNotificacion": in_IdNotificacion,
                "VP_IdCliente": 0,
                "VP_NumeroDocumento": '',
                "VP_NumeroHistoriaClinica": '',
                "VP_Filter": ""
            }
            const response = await fetchSinToken('catalogo/pa_get_notificaciones', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            // console.log(record);
            if (record.length > 0) {
                setAction('U');
                setIdNotificacion(record[0].IdNotificacion);
                setIdCliente(record[0].IdCliente);
                setPrioridad(record[0].Prioridad);
                setEstado(record[0].Estado_n === 'A' ? true : false);
                setVigencia(record[0].Vigencia ? true : false);

                // setFechaVigDesdeDisabled(false);
                // setFechaVigHastaDisabled(false);
                if (record[0].Vigencia) {
                    setFechaVigDesdeDisabled(false);
                    setFechaVigHastaDisabled(false);
                }
                setFechaVigDesde(new Date(Date.parse(record[0].FechaVigDesde)));
                setFechaVigHasta(new Date(Date.parse(record[0].FechaVigHasta)));
                setCategoria(record[0].Categoria);
                // console.log(dataCategoria);
                let itemsfiltered: any = set_dataCategoria().filter(function (element: any) {
                    return element.IdCategoria === record[0].Categoria;
                });
                // console.log(itemsfiltered)
                setCategoria_info(itemsfiltered[0]?.Info);
                setMensaje(record[0].Mensaje.mensaje);
                setChk1(record[0].Mostrar.S1); // start system
                setChk2(record[0].Mostrar.S2); // on History clinic 
                setChk3(record[0].Mostrar.S3); // on Cronology
            }
        }
    }
    const setDataAll = async () => {
        // console.log(props.isData);
        if (props.isData.IdCliente>0) {
            const param = {
                "VP_IdNotificacion": 0,
                "VP_IdCliente": props.isData.IdCliente,
                "VP_NumeroDocumento": '',
                "VP_NumeroHistoriaClinica": '',
                "VP_Filter": ""
            }
            setDataIdNotificacion([]);  
            setIdNotificacion(0);
            const response = await fetchSinToken('catalogo/pa_get_notificaciones', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            if (record.length > 0) 
            {
                setDataIdNotificacion(record);  
                setIdNotificacion(record[0].IdNotificacion); //el default  
                setData(record[0].IdNotificacion);
            }

                   
        }
    }

    const pa_set_notificaciones = async () => {

        let VL_Mensaje: any = {
            "mensaje": VP_Mensaje
        };
        let VL_Mostrar: any = {
            "S1": vl_Chk1,
            "S2": vl_Chk2,
            "S3": vl_Chk3
        };
        const param = {
            "VP_Action": VP_Action,
            "VP_IdNotificacion": VP_IdNotificacion?VP_IdNotificacion:0,
            "VP_IdCliente": VP_IdCliente,
            "VP_IdSucursal": localStorage.getItem('authIdSucursal'),
            "VP_Prioridad": VP_Prioridad,
            "VP_Estado": VP_Estado ? 'A' : 'I',
            "VP_Vigencia": VP_Vigencia ? 1 : 0,
            "VP_FechaVigDesde": VP_Vigencia?setIntlDateTimeFormat(VP_FechaVigDesde):'1900-01-01',
            "VP_FechaVigHasta": VP_Vigencia?setIntlDateTimeFormat(VP_FechaVigHasta):'1900-01-01',
            "VP_Categoria": VP_Categoria,
            "VP_Mensaje": JSON.stringify(VL_Mensaje),
            "VP_Mostrar": JSON.stringify(VL_Mostrar),
            "VP_IdUsuario": localStorage.getItem('authIdUsuario'),
        }
        setVisibleConfirmDialog1(false);
        const response = await fetchSinToken('catalogo/pa_set_notificaciones', param, 'POST');
        const resp = await response.json();
        // console.log(resp);
        if (resp.data[0].sql_error === 1) {
            toast.current.show({ severity: 'error', summary: '.::.PSISABE', detail: resp.data[0].sql_message, life: 10000 });
        } else {
            toast.current.show({ severity: 'success', summary: '.::.PSISABE', detail: resp.data[0].sql_message, life: 10000 });
            props.setIsMounted(false);
            props.isCallfn(true);
            props.isHide();
        }
    }
    const set_dataPrioridad = () => {
        let vl_data: any = [
            { "IdPrioridad": 1, "Descripcion": "NORMAL" },
            { "IdPrioridad": 2, "Descripcion": "MEDIA" },
            { "IdPrioridad": 3, "Descripcion": "ALTA" }
        ];
        return vl_data;
    }
    const set_dataCategoria = () => {
        let vl_data: any = [
            { "IdCategoria": "B", "Descripcion": "ALERTA BLANCA", "Info": "Probabilidades de ocurrencia nula o muy baja, de acciones peligrosas o eventos " },
            { "IdCategoria": "A", "Descripcion": "ALERTA AMARRILLA", "Info": "Probabilidades de ocurrencia significativa, de acciones peligrosas o eventos " },
            { "IdCategoria": "N", "Descripcion": "ALERTA NARANJA", "Info": "Probabilidades de ocurrencia inminente, de acciones peligrosas o eventos" },
            { "IdCategoria": "R", "Descripcion": "ALERTA ROJA", "Info": "Probabilidades de ocurrencia alta, de acciones peligrosas o eventos" }
        ];
        return vl_data;
    }
    const categoriaOptionTemplate = (option: any) => {        
        return (
            <div className="">
                <label className={`${getClaseName(option.IdCategoria)}`}>{' ' + option.Descripcion}</label>
            </div>
        );
    }

    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';
        // console.log(VP_Prioridad);
        if (Number(VP_Prioridad) === 0 || (!Number(VP_Prioridad))) {
            vl_valida = false;
            vl_message = 'Seleccionar prioridad';
        } else if (String(VP_Categoria).trim() === '') {
            vl_valida = false;
            vl_message = 'Seleccionar categoria';
        } else if (VP_Vigencia) {
            console.log(VP_FechaVigDesde);
            if (VP_FechaVigDesde === null || VP_FechaVigHasta === null) {
                vl_valida = false;
                vl_message = 'Ingresar fechas de vigencia';
            }
        }
        return [vl_valida, vl_message];
    }

    const setOnClickSave = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 8000 });
            return;
        }
        // si no hay campos invalidos procesar...    
        setVisibleConfirmDialog1(true);
    }
    const setOnClickNew = () => {
        setAction('I');
        setIdNotificacion(0);        
        setPrioridad(1);
        setEstado(true);
        setVigencia(false);
        setFechaVigDesdeDisabled(true);
        setFechaVigHastaDisabled(true);        
        setFechaVigDesde(null);
        setFechaVigHasta(null);
        setCategoria('');
        setCategoria_info('');
        setMensaje('');
        setChk1(true);
        setChk2(false);
        setChk3(false);
    }

    const renderHeader = () => {
        return (
            <>
                <TitleForm title={'Alertas / Notificaciones' + (VP_Action==='I'?' >Nuevo':' >Editar') } />
            </>
        );
    }
    const renderFooter = () => {
        return (
            <>
                <Button label="Grabar" icon="pi pi-trash" className="p-button ml-2" onClick={() => setOnClickSave()} />
                <Button label="Nuevo" icon="pi pi-file" disabled={VP_Action==='I'?true:false} className="p-button-text ml-2" onClick={() => setOnClickNew()} />
                <Button label="Cerrar" icon="pi pi-times" className="p-button-outlined p-button-danger ml-2" onClick={() => {
                    props.setIsMounted(false);
                    props.isHide()
                }} />
            </>
        );
    }

    return (
        <>
            <Toast ref={toast} position="center"></Toast>
            <ConfirmDialog visible={b_visible1} onHide={() => setVisibleConfirmDialog1(false)}
                message="¿Seguro de guardar la alerta?"
                header="Confirmar."
                icon="pi pi-exclamation-triangle"
                accept={pa_set_notificaciones}
                reject={() => console.log('Cancelado')}
            />
            <Dialog
                header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
                visible={props.isOpen}
                footer={renderFooter}
                onHide={() => {
                    props.setIsMounted(false);
                    props.isHide();
                }}
            >
                <div className="flex flex-wrap card-container w-full ">
                    <div className="flex align-items-center justify-content-center m-1">                        
                        <label className="p-col-fixed  mr-1 font-semibold text-base " style={{textAlign: 'end' }}>Cliente:</label>
                        <InputText type="text" style={{ width: '20rem' }} className="text-left bg-gray-300"
                            value={props.isData.Paciente} readOnly
                        />
                        <label className="p-col-fixed ml-2 mr-1 font-semibold text-base " style={{ textAlign: 'end' }}>HC:</label>
                        <InputText type="text" style={{ width: '7rem' }} className="font-bold text-red-600 text-center bg-gray-300"
                            value={props.isData.NumeroHistoriaClinica} readOnly
                        />
                        
                    </div>
                </div>
                
                <div className="flex flex-wrap card-container mt-2 w-full ">
                    <div className="flex align-items-center justify-content-center m-1">
                        <label className="p-col-fixed  mr-1 font-semibold text-base " style={{ width: '100px', textAlign: 'end' }}>#ID Alerta(s):</label>
                        {/* <InputText type="text" style={{ width: '5rem' }} className="font-bold text-primary-800 text-center bg-gray-300 "
                            value={VP_IdNotificacion} readOnly
                        /> */}
                        <Dropdown style={{ width: '7rem' }}
                            value={VP_IdNotificacion} disabled={VP_Action==='I'?true:false}
                            options={dataIdNotificacion}
                            onChange={(e: any) => {
                                setIdNotificacion(e.value);
                                setData(e.value);
                            }}
                            optionValue="IdNotificacion"
                            optionLabel="IdNotificacion"
                            placeholder="-0-" />
                        <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Prioridad:</label>
                        <Dropdown style={{ width: '12rem' }} showClear
                            value={VP_Prioridad}
                            options={dataPrioridad}
                            onChange={(e: any) => {
                                setPrioridad(e.value);
                            }}
                            optionValue="IdPrioridad"
                            optionLabel="Descripcion"
                            placeholder="Selecionar" />
                    </div>
                </div>
                <div className="flex flex-wrap card-container w-full ">
                    <div className="flex align-items-center justify-content-center m-1">
                        <label className="p-col-fixed mr-1  text-base font-semibold " style={{ width: '100px', textAlign: 'end' }}>Categoria:</label>
                        <Dropdown style={{ width: '18rem' }} showClear
                            value={VP_Categoria}
                            // valueTemplate={selectedCategoriaTemplate}
                            options={dataCategoria}
                            onChange={(e: any) => {
                                setCategoria(e.value);
                                let itemsfiltered: any = dataCategoria.filter(function (element: any) {
                                    return element.IdCategoria === e.value;
                                });
                                setCategoria_info(itemsfiltered[0]?.Info);
                            }}
                            itemTemplate={categoriaOptionTemplate}
                            optionValue="IdCategoria"
                            optionLabel="Descripcion"
                            placeholder="Selecionar" />
                            <Button icon="pi pi-info-circle" className="p-button-rounded p-button-text p-button-plain" tooltip={vl_Categoria_info} />
                    </div>
                </div>
                <div className="flex flex-column card-container w-full ">
                    <div className="field-checkbox">
                        <label className="p-checkbox-label text-base font-semibold pl-1 mr-2" style={{ width: '90px', textAlign: 'end' }} > Vigencia </label>
                        <Checkbox className="mr-2" onChange={e => {
                            setVigencia(e.checked);
                            setFechaVigDesdeDisabled(false);
                            setFechaVigHastaDisabled(false);
                            if (!e.checked) {
                                setFechaVigDesdeDisabled(true);
                                setFechaVigHastaDisabled(true);
                            }
                        }} checked={VP_Vigencia}> </Checkbox>
                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Del:</label>
                        <Calendar style={{ width: '9rem' }} inputClassName="font-semibold p-inputtext-sm text-center"
                            value={VP_FechaVigDesde} disabled={vl_FechaVigDesdeDisabled}
                            onChange={(e: any) => setFechaVigDesde(e.value)}
                            dateFormat="dd-mm-yy" showIcon={true}
                        />
                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Al:</label>
                        <Calendar style={{ width: '9rem' }} inputClassName="font-semibold p-inputtext-sm text-center"
                            value={VP_FechaVigHasta} disabled={vl_FechaVigHastaDisabled}
                            onChange={(e: any) => setFechaVigHasta(e.value)}
                            dateFormat="dd-mm-yy" showIcon={true}
                        />
                    </div>

                </div>
                <div className="flex flex-column card-container w-full ">
                    <div className="field-checkbox p-2">
                        <label className="p-col-fixed mr-1 text-base font-semibold " style={{ width: '80px', textAlign: 'end' }}>Mostrar</label>
                        <Checkbox className="ml-2" onChange={e => setChk1(e.checked)} checked={vl_Chk1}> </Checkbox>
                        <label className="p-checkbox-label pl-1"  > Al iniciar sistema</label>
                        <Checkbox className="ml-2" onChange={e => setChk2(e.checked)} checked={vl_Chk2}> </Checkbox>
                        <label className="p-checkbox-label pl-1"  >Historia Clinica</label>
                        <Checkbox className="ml-2" onChange={e => setChk3(e.checked)} checked={vl_Chk3}> </Checkbox>
                        <label className="p-checkbox-label pl-1"  >Cronologia</label>
                    </div>
                </div>
                <div className="flex flex-column card-container w-full ">
                    <div className="p-2">
                        <label className="p-col-fixed mr-1 pb-1 text-base font-semibold " style={{ width: '80px', textAlign: 'end' }}>Mensaje:</label>
                        <InputTextarea rows={4} cols={30}
                            style={{ width: '100%' }}
                            value={VP_Mensaje}
                            onChange={(e: any) => setMensaje(e.target.value)}
                        />
                    </div>
                </div>

                <div className="flex flex-column card-container w-full ">
                    <div className="p-0">
                        <label className="p-col-fixed mr-1 pb-1 text-base font-semibold " style={{ width: '80px', textAlign: 'end' }}>Activo</label>
                        <InputSwitch checked={VP_Estado}
                            onChange={(e: any) => {
                                setEstado(e.value);
                            }}
                        />
                    </div>
                </div>

            </Dialog>
        </>
    )
}

export default ClienteAlertaForm;
import { useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { fetchSinToken } from '../../../../helpers/fetch';
import { PlanPagos } from '../../admision/ordenAtencion/PlanPagos';
import { DetallePagos } from '../../admision/ordenAtencion/DetallePagos';
import { FooterGridNumber } from '../../util/FooterGrid';

export const OrdenPagos01 = ({ ...props }) => {

    const [dataPlanPagos, setDataPlanPagos] = useState<any>([]);
    const [dataDetallePagos, setDataDetallePagos] = useState<any>([]);

    const o_planPago = useRef<OverlayPanel>(null);
    const o_detallePago = useRef<OverlayPanel>(null);


    const template_statusPago = (item: any) => {
        // console.log(item);
        const BtnStatusPending = () => {
            return (
                <>
                    <Button
                        type="button" aria-readonly
                        className="p-button-sm p-button-info p-button-text pl-1"
                        // icon="pi pi-minus-circle"  
                        style={{ width: '7rem' }}                      
                        label="Pendiente"
                    />
                </>
            );
        }
        const BtnStatusPagado = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button-sm p-button-success p-button-text pl-1  "
                        // icon="pi pi-check-circle"
                        style={{ width: '7rem' }}
                        label="Pagado"
                    />
                </>
            );
        }
        const BtnStatusParcial = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button-sm p-button-warning p-button-text pl-1"
                        // icon="pi pi-spinner"
                        style={{ width: '7rem' }}
                        label="Parcial"
                    />
                </>
            );
        }


        return (
            <>
                {item.EstadoPago === 'P' ? <BtnStatusPending /> : item.EstadoPago === 'T' ? <BtnStatusPagado /> : <BtnStatusParcial />}
            </>
        );
    }
    const template_OrdenPagoDetalle = (item: any) => {
        const vl_TipoRegistro_00: any = item.TipoRegistro_00;
        const vl_NumeroOrden: any = item.NumeroOrden;
        return (<span>{vl_TipoRegistro_00 + ' Nº ' + vl_NumeroOrden}</span>)
        // return (
        //     <>
        //         <Button
        //             type="button"
        //             className="p-button-rounded p-button-warning "
        //             icon="pi pi-shopping-cart"
        //             // style={{ height: '26px' }}
        //             tooltip={`Ver Detalle O/P ${vl_IdOrdenPago}`}
        //         // label={vl_IdOrdenPago}
        //         // onClick={ (e) => pa_get_plan_pagos( vl_IdOrdenPago , e ) }
        //         />
        //     </>
        // );
    }
    const template_btnPlanPago = (item: any) => {
        const vl_IdPlanPago = item.IdPlanPago;
        return (
            <>
                <Button
                    type="button"
                    className="p-button-sm p-button-warning "
                    icon="pi pi-calendar-plus"
                    // style={{ height: '24px' }}
                    title="Cronograma de Pagos"
                    onClick={(e) => pa_get_plan_pagos(vl_IdPlanPago, e)}
                />
            </>
        );
    }
    const template_btnDetallePago = (item: any) => {
        const vl_IdOrdenPago = item.IdOrdenPago;
        return (
            <>
                <Button label={`${item.Pagos}`} className="p-button-link pl-1" title='Ver detalle de pagos'
                                onClick={(e) => pa_get_pago_detalle(vl_IdOrdenPago, e)} ></Button>
                {/* <Button
                    type="button"
                    className="p-button-sm p-button-warning "
                    icon="pi pi-dollar"
                    // style={{ height: '24px' }}
                    title="Detalle de Pagos"
                    onClick={(e) => pa_get_pago_detalle(vl_IdOrdenPago, e)}
                /> */}
            </>
        );
    }
    const template_NumberCurrency = (item: any) => {
        return (
            <>
                {item.Saldo}
            </>
        );
    }
    const pa_get_plan_pagos = async (IdPlanPago: any, e: any) => {
        // console.log('IdPlanPago:'+ IdPlanPago );        
        if (Number(IdPlanPago) > 0) {
            const param = {
                "vp_IdPlanPago": IdPlanPago
            }
            const response = await fetchSinToken('admision/pa_get_plan_pagos', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataPlanPagos(record);
            // Mostrar el Overlay Popup
            o_planPago.current?.toggle(e);
        }
    }
    const pa_get_pago_detalle = async (IdOrdenPago: any, e: any) => {
        // console.log('IdOrdenPago:'+ IdOrdenPago );        
        if (Number(IdOrdenPago) > 0) {
            const param = {
                "vp_IdOrdenPago": IdOrdenPago
            }
            const response = await fetchSinToken('admision/pa_get_pago_detalle', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataDetallePagos(record);
            // Mostrar el Overlay Popup
            // console.log('o_detallePago show');
            o_detallePago.current?.toggle(e);
        }
    }

    const footer = () => {
        let vl_TotalOrdenPago: any = 0;
        let vl_TotalPagos: any = 0;
        let vl_TotalSaldo: any = 0;
        props.dataOrdenPago.forEach(
            function (currentValue: any) {
                vl_TotalOrdenPago = vl_TotalOrdenPago + parseFloat(currentValue.Total);
                vl_TotalPagos = vl_TotalPagos + parseFloat(currentValue.Pagos);
                vl_TotalSaldo = vl_TotalSaldo + parseFloat(currentValue.Saldo);
            }
        );
        return (
            <>
                <div className='flex flex-wrap justify-content-end' >                
                    <FooterGridNumber Total={vl_TotalOrdenPago} Title={'Total OP'} />
                    <FooterGridNumber Total={vl_TotalPagos} Title={'Pagados'} />
                    <FooterGridNumber Total={vl_TotalSaldo} Title={'Saldo'} />
                </div>
            </>
        );
    }

    return (
        <>

            <div className="p-field p-grid mt-1 mb-0">                
                <div className="p-col">
                    <DataTable
                        size="small" footer={footer}
                        value={props.dataOrdenPago ?? []}
                        dataKey="IdOrdenPago"
                        resizableColumns
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        scrollHeight="450px"
                        paginator
                        rows={20}
                        selectionMode="multiple"
                        selection={props.dataSelectedOPItems}
                        onSelectionChange={(e) => {
                            props.setSelectedOPItems(e.value);
                        }}
                    >
                        <Column selectionMode="multiple" style={{ maxWidth: '40px' }} ></Column>
                        <Column field="IdOrdenPago" header="# O.P" style={{ maxWidth: '60px' }} bodyClassName="flex justify-content-center" ></Column>
                        <Column field="TipoRegistro_00" header="Referencia Orden pago"  style={{ minWidth: '8rem' }}
                            body={template_OrdenPagoDetalle}         
                            headerClassName="white-space-normal"
                            bodyClassName="white-space-normal"  ></Column>
                        <Column field="Cliente" header="Paciente/Usuario" style={{ minWidth: '250px' }} ></Column>
                        <Column field="Fecha" header="Fecha" style={{ minWidth: '7rem' }} bodyClassName="flex justify-content-center" ></Column>
                        <Column field="Total" header="Total" style={{ maxWidth: '90px' }}
                            headerClassName="flex justify-content-end"
                            bodyClassName="flex justify-content-end font-bold "
                            body={(item: any) => {
                                return (<> S/ {item.Total} </>
                                )
                            }}
                            ></Column>
                        <Column field="NumeroCuotas" header="Cant. Sesión" style={{ minWidth: '4rem' }}
                            headerClassName="flex justify-content-center white-space-normal"
                            bodyClassName="flex justify-content-center font-bold"
                        ></Column>
                        <Column header="Cronogr. Pagos" body={template_btnPlanPago} style={{ minWidth: '5rem' }}
                            headerClassName="white-space-normal"
                            bodyClassName="flex justify-content-center"
                        ></Column>
                        <Column field="Pagos" header="Total Pagos" style={{ maxWidth: '90px' }}
                            headerClassName="white-space-normal  "
                            bodyClassName="flex justify-content-end"
                            body={template_btnDetallePago}>
                            </Column>
                        {/* <Column field="" header="" body={template_btnDetallePago} style={{ maxWidth: '50px' }}
                            bodyClassName="flex justify-content-center text-cyan-800 font-bold"
                        ></Column> */}

                        <Column field="Saldo" header="Saldo" style={{ maxWidth: '90px' }}
                            headerClassName="flex justify-content-end"
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                            body={template_NumberCurrency} > </Column>
                        <Column field="EstadoPago" header="Estado Pago" body={template_statusPago} style={{ maxWidth: '170px', minWidth: '170px' }}  ></Column>

                    </DataTable>
                </div>
            </div>

            <PlanPagos
                oplanPago={o_planPago}
                dataPlanPagos={dataPlanPagos}
            />

            <DetallePagos
                o_detallePago={o_detallePago}
                dataDetallePagos={dataDetallePagos}
            />


        </>
    )
}
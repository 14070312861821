import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const PlanPagos = ({ ...props }) => {

    const isSelectable = (value: any, field: any, Estado: any) => {
        let isSelectable = true;
        // console.log(value);
        switch (field) {
            case 'Saldop':
                isSelectable = value > 0;
                break;
            // case 'name':
            // case 'category':
            //     isSelectable = value.startsWith('B') || value.startsWith('A');
            //     break;
            default:
                break;
        }
        //SI ESTA PENDIENTE DE PAGO FORZAR PODER SELECCIONAR AUN EL SALDO ESTE EN CERO
        if (Estado === 'P' || Estado === 'X') isSelectable = true

        return isSelectable;
    }

    const isRowSelectable = (event: any) => {
        const data = event.data;
        // console.log(data);
        return isSelectable(data.Saldop, 'Saldop', data.Estado);
    }

    const rowClassName = (data: any) => {
        return isSelectable(data.Saldop, 'Saldop', data.Estado) ? '' : 'p-disabled bg-gray-200';
    }

    return (
        <>
            <DataTable header="Plan cuotas por sesiones"
                size="small" 
                // style={{ height: "99%" }}
                value={props.dataPlanPagos ?? []}
                dataKey="IdPlanPagoDetalle"
                resizableColumns
                responsiveLayout="scroll"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                scrollHeight="300px"
                selection={props.dataSelectedCuotas}
                rowClassName={rowClassName}
                isDataSelectable={isRowSelectable}
                editMode="row"
                selectionMode="checkbox"
                onRowEditComplete={props.onRowEditComplete}
                onRowEditInit={props.onRowEditInit}
                onRowSelect={props.onRowSelect}
                onRowEditSave={props.onRowEditSave}
                onRowUnselect={props.onRowUnselect}
                onSelectionChange={(e) => {
                    // console.log(e);
                    props.setSelectedCuotas(e.value);
                }}
            >
                <Column selectionMode="multiple" headerClassName="flex justify-content-center" bodyClassName="flex justify-content-center" style={{ minWidth: '4rem', maxWidth: '4rem' }} ></Column>
                <Column field="Cuota" header="Nº S." style={{ minWidth: '4rem', maxWidth: '4rem' }} bodyClassName="flex justify-content-center"  ></Column>
                <Column field="Importe" header="Importe" style={{ minWidth: '90px', maxWidth: '90px' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end "></Column>
                <Column field="Pago" header="Pagado" style={{ minWidth: '5rem', maxWidth: '5rem' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end text-green-800 font-bold"
                >
                </Column>
                <Column field="Saldop" header="Saldo"
                    style={{ minWidth: '5rem', maxWidth: '5rem' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                >
                </Column>
                <Column field="Amortizacion" header="Pagar" style={{ minWidth: '5rem', maxWidth: '5rem' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end text-green-800 font-bold"
                    editor={(options) => props.importePagoEditor(options)}
                >
                </Column>
                <Column rowEditor style={{ maxWidth: '70px' }} bodyStyle={{ textAlign: 'center' }}></Column>
            </DataTable>
        </>
    )
}
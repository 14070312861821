import { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import TitleForm from './TitleForm';


// props requeridos 
// @isOpen
// @isHide
// fn:getBuscarCliente
// fn:setNumeroDocumento

const BuscadorClientes = ({ ...props }) => {
    // console.log('BuscadorClientes');

    // buscador de pacientes / clientes      
    const [selectedPaciente, setSelectedPaciente] = useState(null);
    const [dataSetPacientes, setDataSetPacientes] = useState([]);
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState('');
    const [vl_IdCliente, setIdCliente] = useState<any>('0');
    const [vl_Nombres, setNombres] = useState('');
    const [vl_NumeroDocumento, setNumeroDocumento] = useState('');
    const [qtyResult, setQtyResult] = useState<any>(0);
    //
    const toast = useRef<any>(null);

    // buscar CLIENTE x numero de documento
    const getBuscarCliente = async (e: any) => {
        e.preventDefault();
        setIdCliente('0');
        const param = {
            "VP_IdCliente": vl_IdCliente,
            "VP_NumeroDocumento": vl_NumeroDocumento,
            "VP_NumeroHistoriaClinica": vl_NumeroHistoriaClinica,
            "VP_Nombres": vl_Nombres,
            "VP_Apellidos": ""
        }

        if (vl_NumeroDocumento.length < 8 && vl_NumeroDocumento.length > 0) {
            const message = 'Numero de documento debe ser de 8 a más digitos';
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 10000 });
            return;
        }
        if (vl_NumeroHistoriaClinica.length < 8 && vl_NumeroHistoriaClinica.length > 0) {
            const message = 'Numero de HC debe ser de 8 digitos';
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 10000 });
            return;
        }
        if (vl_Nombres.length < 3 && vl_Nombres.length > 0) {
            const message = 'Nombres o apellidos debe tener al menos 3 digitos';
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 10000 });
            return;
        }

        const response = await fetchSinToken('selectors/pa_get_cliente', param, 'POST');
        const resp = await response.json();
        // const record = resp.data[0];
        setQtyResult(resp.data.length);
        setDataSetPacientes(resp.data);
        if (!resp.data[0]) {
            const message = 'No se encontro cliente o paciente';
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 10000 });
        }
    }
    const renderHeader = () => {
        return (
            <>
                <TitleForm title={' Buscar Paciente/Cliente'} />
            </>
        );
    }
    const renderFooter = () => {
        return (
            // <div className="absolute bottom-0 mb-2"></div>
            <>
                <div>
                    <Button label="Cerrar" icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => {
                        props.setIsMounted(false);
                        props.isHide();
                    }} />
                </div>
            </>
        );
    }


    return (

        <Dialog
            header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable={false}
            visible={props.isOpen}
            footer={renderFooter}
            onHide={() => {
                props.setIsMounted(false);
                props.isHide();
            }}
        >

            <div className='card p-2 m-2' >
                <div className="flex flex-wrap card-container w-full ">
                    <div className="flex align-items-center justify-content-center m-1" >
                        <label className="p-col-fixed mr-2 font-bold " style={{ width: '160px', textAlign: 'end' }}>N° Documento:</label>
                        <InputText type="text" style={{ width: '8rem', textAlign: 'center' }}
                            maxLength={8}
                            value={vl_NumeroDocumento}
                            onChange={(e: any) => setNumeroDocumento(String(e.currentTarget.value).toUpperCase())}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    getBuscarCliente(e);
                                    // props.setDataCliente(['xxxxxx']);                                
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap card-container w-full ">
                    <div className="flex align-items-center justify-content-center m-1" >
                        <label className="p-col-fixed mr-2 font-bold " style={{ width: '160px', textAlign: 'end' }}>Nombres o apellidos:</label>
                        <InputText type="search" style={{ width: '18rem', textAlign: 'left' }}
                            autoFocus
                            value={vl_Nombres}
                            onChange={(e: any) => setNombres(String(e.currentTarget.value).toUpperCase())}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    getBuscarCliente(e);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-wrap card-container w-full ">
                    <div className="flex align-items-center justify-content-center m-1" >
                        <label className="p-col-fixed mr-2 font-bold " style={{ width: '160px', textAlign: 'end' }}>N° Historia Clínica:</label>
                        <InputText type="search" style={{ width: '8rem', textAlign: 'center' }} 
                            maxLength={8}
                            value={vl_NumeroHistoriaClinica}
                            onChange={(e: any) => setNumeroHistoriaClinica(String(e.currentTarget.value).toUpperCase())}
                            onKeyPress={(e: any) => {
                                if (e.key === 'Enter') {
                                    getBuscarCliente(e);
                                }
                            }}
                        />
                        <Button title="Buscar"
                            onClick={(e: any) => getBuscarCliente(e)}
                            icon="fas fa-search"
                            className="p-button-rounded ml-1"
                        />
                    </div>
                </div>

            </div>

            <div className="p-field p-grid mb-2 mt-1">
                <label className="p-col-fixed mt-2 font-bold text-green-600 pb-2 "> Resultados: {qtyResult} registro(s) </label>
                <ListBox className='bg-gray-50'
                    value={selectedPaciente}
                    options={dataSetPacientes}
                    onChange={
                        (e) => {
                            // console.log(e.value);
                            setSelectedPaciente(e.value)
                            props.getDataCliente(e.value);
                            props.setIsMounted(false);
                            props.isHide();
                        }
                    }
                    optionLabel="Paciente"
                    listStyle={{ height: '180px' }}
                    virtualScrollerOptions={{ itemSize: 5 }}
                    style={{ width: '100%', height: '180px' }} />
            </div>
            <Toast ref={toast} position="bottom-right"></Toast>
        </Dialog>

    )
}

export default BuscadorClientes;
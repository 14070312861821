
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { InputNumber } from 'primereact/inputnumber';
// import { ProgressBar } from 'react-bootstrap';


export const DetalleOrdenPago = ({ ...props }) => {    
    // console.log(props);    
    // const vl_TotalInversion = props.dataDetallePagos[0]?.tpp_Importe;    

    return (
        <>
            <OverlayPanel
                ref={props.o_detalleOrdenPago}
                showCloseIcon={false}
                style={{ width: "800px" }}
            >                
                <DataTable header="Detalle Orden de Pago"
                    size="small"
                    value={props.detalleOrdenPago ?? []}
                    dataKey="IdOrdenPagoDetalle"
                    resizableColumns
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    selectionMode="single"  
                >                    
                    <Column field="IdOrdenPago" header="O/P" style={{ maxWidth: '70px' }} bodyClassName="flex justify-content-center" headerClassName="flex justify-content-center"  ></Column>
                    <Column field="CodigoServicio" header="Codigo" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-center" ></Column>                    
                    <Column field="Detalle" header="Detalle Pago" style={{  align: 'end' }} headerClassName="flex justify-content-left" bodyClassName="flex justify-content-left"></Column>                    
                    <Column field="Cantidad" header="T. Ses." style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-center "></Column>                    
                    <Column field="Importe" header="Importe" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column>                   
                    <Column field="Total" header="Total" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-end" ></Column>                                        
                </DataTable>

                <Button
                    type="button"
                    className="p-button-rounded p-button-warning ml-1 mt-1 "
                    icon="pi pi-times"
                    label="Cerrar"
                    onClick={
                        (e) => {
                            props.o_detalleOrdenPago.current?.hide()
                        }
                    }
                />
            </OverlayPanel>
        </>
    )
}
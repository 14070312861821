
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import TitleForm from '../../components/util/TitleForm';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useModal from '../../../context/WindowsOpen';
import CajaDetalleRecibo from './CajaDetalleRecibo';

const CajaAnularRecibo = ({ ...props }) => {

    const [VP_Action, setAction] = useState<any>('I');
    const [VP_TipoProceso, setTipoProceso] = useState<any>('R');
    const [VP_IdAnulacion, setIdAnulacion] = useState<any>(0);
    const [VP_IdMotivo, setIdMotivo] = useState<any>(0);
    const [dataMotivo, setDataMotivo] = useState<any>([]); //array
    const [VP_DetalleAnulacion, setDetalleAnulacion] = useState<any>('');
    const [VP_ReferenciaID, setReferenciaID] = useState<any>(0);
    const toast = useRef<any>(null);
    const [b_visible1, setVisibleConfirmDialog1] = useState<boolean>(false);

    const [VP_IdPago, setIdPago] = useState<any>('');
    const [VP_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
    const [VP_IdCliente, setIdCliente] = useState<any>(0);
    const [dataRecibosPago, setDataRecibosPago] = useState<any>([]);
    const [dataRecibosPagoDetalle, setDataRecibosPagoDetalle] = useState<any>([]);
    const [selectedIemsGrid, setSelectedIemsGrid] = useState<any>([]);


    useEffect(() => {
        // console.log(props)
        setAction('I');
        setTipoProceso('R');
        setIdAnulacion(0);
        setReferenciaID(0);
        pa_get_sys_config();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_caja_recibos = async () => {
        const param = {
            "VP_IdPago": VP_IdPago === '' ? 0 : VP_IdPago,
            "VP_NumeroHistoriaClinica": VP_NumeroHistoriaClinica,
            "VP_IdSucursal": localStorage.getItem('authIdSucursal'),
            "VP_IdCliente": VP_IdCliente
        }
        setSelectedIemsGrid([]);
        const response = await fetchSinToken('caja/pa_get_caja_recibos', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        if (record.length > 0) {
            setDataRecibosPago(record);
        } else {
            // console.log('xxxxxxxx')
            toast.current.show({ severity: 'error', summary: '.::.PSISABE', detail: 'No hay registro buscado', life: 10000 });
        }

    }

    const pa_set_anulaciones = async () => {

        const param = {
            "VP_Action": VP_Action,
            "VP_TipoProceso": VP_TipoProceso,
            "VP_IdAnulacion": VP_IdAnulacion,
            "VP_IdMotivo": VP_IdMotivo,
            "VP_DetalleAnulacion": VP_DetalleAnulacion,
            "VP_ReferenciaID": VP_ReferenciaID,
            "VP_IdUsuario": localStorage.getItem('authIdUsuario'),
            "VP_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        setVisibleConfirmDialog1(false);
        const response = await fetchSinToken('catalogo/pa_set_anulaciones', param, 'POST');
        const resp = await response.json();
        // console.log(resp);
        if (resp.data[0].sql_error === 1) {
            toast.current.show({ severity: 'error', summary: '.::.PSISABE', detail: resp.data[0].sql_message, life: 10000 });
        } else {
            toast.current.show({ severity: 'success', summary: '.::.PSISABE', detail: resp.data[0].sql_message, life: 10000 });
            pa_get_caja_recibos();
            // props.setIsMounted(false);
            // props.isCallfn(props.isData.IdCliente);
            // props.isHide();
        }
    }
    const pa_get_sys_config = async () => {
        const param = {
            "VP_Identificador": 'MotivoAnulaRecibo',
            "VP_Estado": "A"
        }
        const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataMotivo(record[0].RecordSet);
    }

    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';
        if (Number(VP_IdMotivo) === 0 || (!Number(VP_IdMotivo))) {
            vl_valida = false;
            vl_message = 'Seleccionar un motivo';
        } else if (String(VP_DetalleAnulacion).trim() === '') {
            vl_valida = false;
            vl_message = 'Ingresar detalle de anulación';
        }else{
            if (selectedIemsGrid.length === 0 ){
                vl_valida = false;
                vl_message = 'Seleccione el recibo';
            }
        }
        return [vl_valida, vl_message];
    }

    const setAnular = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: IsMessage, life: 8000 });
            return;
        }
        // si no hay campos invalidos procesar...    
        setVisibleConfirmDialog1(true);
    }

    const renderHeader = () => {
        return (
            <>
                <TitleForm title={'Anular recibos de pago'} />
            </>
        );
    }
    const renderFooter = () => {
        return (
            <>
                <Button label="Anular" icon="pi pi-trash" className="p-button-danger ml-2" onClick={() => setAnular()} />
                <Button label="Cerrar" icon="pi pi-times" className="p-button-outlined p-button-danger ml-2" onClick={() => {
                    props.setIsMounted(false);
                    props.isHide()
                }} />
            </>
        );
    }

    const rowClassName = (data: any) => {
        return data.Estado === 'A' ? 'p-disabled bg-gray-100 line-through text-red-500' : '';
    }

    const pa_get_caja_recibos_detalle = async (p_IdPago: any, e: any) => {
        if (Number(p_IdPago) > 0) {
            const param = {
                "VP_IdPago": p_IdPago
            }
            const response = await fetchSinToken('caja/pa_get_caja_recibos_detalle', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataRecibosPagoDetalle(record);
            handleEventClickAnular();
        }
    }

    const templateDetalleRecibo = (row: any) => {
        const vl_IdPago: any = row.IdPago;
        return (
            <>
                <Button label={`${vl_IdPago}`} className="p-button-link pl-1" title='Ver detalle de recibo'
                    onClick={(e) => pa_get_caja_recibos_detalle(vl_IdPago, e)}
                />
            </>
        );
    }

    const [isOpenModal, openModal, closeModal] = useModal();
    const isMounted = useRef(false);
    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }
    const handleEventClickAnular = () => {
        isMounted.current = true;
        openModal();
    }
    const CajaDetalleReciboNew = () => {
        return (
            <CajaDetalleRecibo
                isOpen={isOpenModal}
                isHide={closeModal}
                isData={dataRecibosPagoDetalle}
                setIsMounted={setIsMounted}
            />
        );
    }

    return (
        <>
            <Toast ref={toast} position="center"></Toast>
            <ConfirmDialog visible={b_visible1} onHide={() => setVisibleConfirmDialog1(false)}
                message={'¿Seguro de anular recibo # ' + VP_ReferenciaID + ' ?'}
                header="Confirmar."
                icon="pi pi-exclamation-triangle"
                accept={pa_set_anulaciones}
                reject={() => console.log('Cancelado')}
            />
            <Dialog
                header={renderHeader} position='center' modal draggable resizable closable showHeader maximizable closeOnEscape
                visible={props.isOpen}
                footer={renderFooter}
                onHide={() => {
                    props.setIsMounted(false);
                    props.isHide();
                }}
            >
                <div className='card p-2 m-2' >
                    <div className="flex flex-wrap card-container w-full ">
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed mr-2 font-bold " style={{ textAlign: 'end' }}>N° Recibo:</label>
                            <InputText type="text" style={{ width: '8rem', textAlign: 'center' }}
                                value={VP_IdPago} autoFocus
                                onChange={(e: any) => setIdPago(String(e.currentTarget.value))}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        pa_get_caja_recibos();
                                    }
                                }}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed mr-2 font-bold " style={{ textAlign: 'end' }}>N° HC:</label>
                            <InputText type="search" style={{ width: '10rem', textAlign: 'center' }}
                                maxLength={8}
                                value={VP_NumeroHistoriaClinica}
                                onChange={(e: any) => setNumeroHistoriaClinica(String(e.currentTarget.value))}
                                onKeyPress={(e: any) => {
                                    if (e.key === 'Enter') {
                                        pa_get_caja_recibos();
                                    }
                                }}
                            />
                            <Button title="Buscar"
                                onClick={(e: any) => pa_get_caja_recibos()}
                                icon="fas fa-search"
                                className="p-button-rounded ml-1"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap card-container w-full ">
                        <div className="flex align-items-center justify-content-center m-1" >
                            <DataTable size="small" resizableColumns
                                header="Recibos de pago"
                                responsiveLayout="scroll"
                                scrollDirection="horizontal"
                                style={{ maxWidth: '60vw' }}
                                scrollHeight="30vh"
                                columnResizeMode="fit"
                                value={dataRecibosPago ?? []}
                                dataKey="IdPago"
                                showGridlines
                                stripedRows
                                scrollable rowClassName={rowClassName}
                                selectionMode="single"
                                selection={selectedIemsGrid}
                                onSelectionChange={(e: any) => {
                                    // console.log(e.value);
                                    setSelectedIemsGrid(e.value);
                                    // setIdPago(e.value.IdPago);
                                    setReferenciaID(e.value.IdPago);
                                }}

                            >
                                <Column selectionMode="single" style={{ maxWidth: '40px' }} ></Column>
                                <Column field="Paciente" header="Paciente/Cliente" style={{ minWidth: '12rem' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="white-space-normal"
                                ></Column>
                                <Column field="Sede" header="Sede" style={{ maxWidth: '4rem' }}
                                    headerClassName="flex justify-content-center"

                                ></Column>
                                <Column field="IdPago" header="Nº Recibo" style={{ minWidth: '5rem' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-center text-red-500 font-bold text-lg"
                                    body={templateDetalleRecibo}
                                >
                                </Column>
                                <Column field="FechaPago" header="Fecha pago" style={{ minWidth: '7rem' }}
                                    headerClassName="white-space-normal"
                                    bodyClassName="white-space-normal"
                                >
                                </Column>
                                <Column field="TotalPago" header="Total Recibo" style={{ minWidth: '6rem' }}
                                    headerClassName="white-space-normal"
                                    bodyClassName="flex justify-content-end text-green-700 font-bold text-normal"
                                >
                                </Column>
                                <Column field="IdFormaPago" header="F. Pago" style={{ minWidth: '5rem' }}
                                    headerClassName="flex justify-content-start white-space-normal "
                                    bodyClassName="flex justify-content-start"
                                >
                                </Column>
                                <Column field="ReferenciaPago" header="Ref." style={{ minWidth: '6rem' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-start"
                                // editor={(options) => cantidadEditor(options)}
                                >
                                </Column>

                            </DataTable>
                        </div>
                    </div>


                </div>
                <div className='pt-2'>
                    <label className="p-col-fixed mr-1 text-lg font-bold " style={{ textAlign: 'end' }}>Requerido para anular.</label>
                </div>
                <div className="flex flex-wrap card-container w-full mt-3 ">

                    <div className="flex align-items-center justify-content-center m-1">
                        <label className="p-col-fixed mr-1 text-base font-semibold " style={{ width: '80px', textAlign: 'end' }}>Motivo:</label>
                        <Dropdown style={{ width: '22rem' }} showClear
                            value={VP_IdMotivo}
                            options={dataMotivo}
                            onChange={(e: any) => {
                                setIdMotivo(e.value);
                            }}
                            optionValue="Codigo"
                            optionLabel="Descripcion"
                            placeholder="Selecionar" />
                    </div>
                </div>
                <div className="flex flex-column card-container w-full ">
                    <div className="p-2">
                        <label className="p-col-fixed mr-1 pb-1 text-base font-semibold " style={{ width: '80px', textAlign: 'end' }}>Detalle:</label>
                        <InputTextarea rows={4} cols={30}
                            style={{ width: '100%' }}
                            value={VP_DetalleAnulacion}
                            onChange={(e: any) => setDetalleAnulacion(e.target.value)}
                        />
                    </div>
                </div>

            </Dialog>
            <React.Fragment>{isMounted.current && <CajaDetalleReciboNew />}</React.Fragment>

        </>
    )
}

export default CajaAnularRecibo;
// import { FileUpload } from 'primereact/fileupload'
// import { TabPanel, TabView } from 'primereact/tabview'
import { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
// import { ProgressBar } from 'primereact/progressbar';
// import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
// import { Carousel } from 'primereact/carousel';
import { Dialog } from 'primereact/dialog';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import { fetchSinToken } from '../../../helpers/fetch';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
import { Tree } from 'primereact/tree';
import UploadFiles from '../util/UploadFiles';
import useModal from '../../../context/WindowsOpen';
import { ConfirmDialog } from 'primereact/confirmdialog';

const PacienteDocumentAdjuntos = ({ ...prop }) => {
    // console.log(prop);
    // variables de HC
    const [vl_IdHistoriaClinica, setIdHistoriaClinica] = useState<any>(0);
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
    const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>('');
    const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>('');
    const [vl_Nombres, setNombres] = useState<any>('');
    const [vl_Edad, setEdad] = useState<any>(0);
    // const [vl_FechaNacimiento, setFechaNacimiento] = useState<any>('');
    // const [vl_NumeroDocumento, setNumeroDocumento] = useState<any>('');
    // const [vl_Sexo, setSexo] = useState<any>('');

    const [vl_fileObjeto, setFileObjeto] = useState<any>('');
    const [vl_fileKey, setFilekey] = useState<any>('');
    const [vl_fileSize, setFileSize] = useState<any>('');
    const [vl_fileLastModified, setFileLastModified] = useState<any>('');
    const [vl_fileStorageClass, setFileStorageClass] = useState<any>('');

    // upload
    const [vl_totalSize, setTotalSize] = useState(0);
    // const fileUploadRef = useRef<any>(null);

    // const [gridFilesLoaded, setGridFilesLoaded] = useState([]);
    const toast = useRef<any>(null);

    const { ConfigS3 } = prop;
    // console.log(prop.ConfigS3);
    // bucket:'psisabe.bucket.files',
    // folder:'HISTORIA_CLINICA',
    // subfolder:vl_NumeroHistoriaClinica,
    // tipofolder:'PLAN_EVALUACION'

    // URL: Upload files S3
    const parms: any = 'VP_IdHistoriaClinica=' + vl_IdHistoriaClinica + '&VP_NumeroOrden=' + prop.NumeroOrden +
        '&VP_IdSucursal=' + localStorage.getItem('authIdSucursal') +
        '&VP_IdUsuario=' + localStorage.getItem('authIdUsuario') +
        '&bucket=' + ConfigS3.bucket + '&folder=' + ConfigS3.folder + '&subfolder=' + ConfigS3.subfolder + '&tipofolder=' + ConfigS3.tipofolder + '&folderCreated=' + ConfigS3.folderCreated;
    const endpointUPLOAD = 'uploadFiles/uploadFileS3/?' + parms;
    const baseUrl = process.env.REACT_APP_API_URL;
    const uploadFileToS3BucketURL = `${baseUrl}/${endpointUPLOAD}`;

    useEffect(() => {
        pa_get_paciente(prop.IdHistoriaClinica);
        getListObjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_paciente = async (in_IdHistoriaClinica: any) => {
        const param = {
            "VP_IdHistoriaClinica": in_IdHistoriaClinica
        }
        const response = await fetchSinToken('selectors/pa_get_paciente', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        if (record) {
            setNumeroHistoriaClinica(record.NumeroHistoriaClinica);
            setIdHistoriaClinica(record.IdHistoriaClinica);
            setNombres(record.Nombres);
            setApellidoPaterno(record.ApellidoPaterno);
            setApellidoMaterno(record.ApellidoMaterno);
            setEdad(record.Edad);
            // setFechaNacimiento(new Date(Date.parse(record.FechaNacimiento)));            
            // setNumeroDocumento(record.NumeroDocumento);
            // setSexo(record.Sexo);            
        }
    }

    const onTemplateUpload = (resp: any) => {
        console.log('onTemplateUpload> ', resp);
        let _totalSize = 0;
        resp.files.forEach((file: any) => {
            _totalSize += (file.size || 0);
        });
        setTotalSize(_totalSize);

        if (resp.xhr.status === 200) {
            toast.current.show({ severity: 'success', summary: '::PSISABE', detail: 'Archivo cargado correctamente.', life: 8000 });
            // recargar datos 
            getListObjects();
            //cerrar VENTA upload
            setIsMounted(false);
            closeModal();
        }else{
            let vl_response = resp.xhr.response;
            toast.current.show({ severity: 'danger', summary: '::PSISABE', detail: vl_response, life: 8000 });
        }
    }


    const getDownloadfile = () => {

        const parms: any = 'VP_IdHistoriaClinica=' + vl_IdHistoriaClinica + '&VP_NumeroOrden=' + prop.NumeroOrden +
            '&VP_IdSucursal=' + localStorage.getItem('authIdSucursal') +
            '&VP_IdUsuario=' + localStorage.getItem('authIdUsuario') + '&VP_fileObjeto=' + vl_fileObjeto +
            '&VP_bucket=' + ConfigS3.bucket + '&VP_bucketKey=' + vl_fileKey + '&VP_tipofolder=' + ConfigS3.tipofolder;

        const endpoint = 'uploadFiles/getObject/?' + parms;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;

        Axios({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(function (response: any) {
            fileDownload(response.data, vl_fileObjeto);
        });
    }

    const [datFolderTree, setFolderTree] = useState([]);
    const getListObjects = async () => {
        // console.log(e);
        const param = {
            "VP_IdHistoriaClinica": vl_IdHistoriaClinica,
            "VP_NumeroHistoriaClinica": vl_NumeroHistoriaClinica,
            "VP_IdSucursal": localStorage.getItem('authIdSucursal'),
            "VP_IdUsuario": localStorage.getItem('authIdUsuario'),
            "ConfigS3": prop.ConfigS3
        }
        const response = await fetchSinToken('uploadFiles/getListObjects', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        // setGridFilesLoaded(record);
        const rootTree: any = [];
        const root: any = [];
        console.log(record);
        for (let row of record) {
            root.push({
                "key": row.key,
                "label": row.parent,
                "data": "Documents Folder",
                "icon": "pi pi-fw pi-folder",
                "children": row.items
            });
        }
        rootTree.push({ "root": root });
        setFolderTree(rootTree[0].root);

    }

    // const setCreateBucket = async (e: any, in_path: string, in_fileName: string) => {

    //     // const params = 'VP_IdHistoriaClinica=' + vl_IdHistoriaClinica +
    //     //     '&VP_IdSucursal=' + localStorage.getItem('authIdSucursal') +
    //     //     '&VP_IdUsuario=' + localStorage.getItem('authIdUsuario') +
    //     //     '&VP_Path=' + in_path;

    //     // const endpoint = 'uploadFiles/getListObjects/?' + params;
    //     // const baseUrl = process.env.REACT_APP_API_URL;
    //     // const url = `${baseUrl}/${endpoint}`;

    //     const param = {
    //         "Folder": "EVALUACION",
    //         "Path": ""
    //     }
    //     const response = await fetchSinToken('uploadFiles/setCreateBucket', param, 'POST');
    //     const resp = await response.json();
    //     const record = resp.data;
    //     console.log(record);
    // }

    const setDeleteObject = async () => {

        const param = {
            VP_IdHistoriaClinica: vl_IdHistoriaClinica,
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_bucket: ConfigS3.bucket,
            VP_bucketKey: vl_fileKey,
            VP_tipofolder: ConfigS3.tipofolder
        }
        const response = await fetchSinToken('uploadFiles/deleteObject', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log('response:');
        console.log(record);
        if (record) {
            toast.current.show({ severity: 'success', summary: '::PSISABE', detail: record.sql_message, life: 8000 });
            if (record.sql_error === 0) {
                // recargar datos 
                getListObjects();
            }
        } else toast.current.show({ severity: 'error', summary: '::PSISABE', detail: record.sql_message, life: 8000 });

    }


    const renderFooter = () => {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-check" onClick={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }} autoFocus />
            </div>
        );
    }

    const template_header = () => {
        return (
            <>
                <div className="bg-indigo-500 pb-2 p2 border-bottom-3  ">
                    <div className="col mt-0 ml-0 text-white font-italic h-3rem" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='text-base font-bold'> Paciente: {vl_ApellidoPaterno + ' ' + vl_ApellidoMaterno + ', ' + vl_Nombres} </label><br />
                        <label className='text-base font-bold'> Nº HC. {vl_NumeroHistoriaClinica}, Edad: {vl_Edad} años  </label>
                    </div>
                </div>
            </>
        );
    }

    const [isOpenModal, openModal, closeModal] = useModal();
    const isMounted = useRef(false);
    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }
    const handleClickUploadFiles = (e: any) => {
        isMounted.current = true;
        openModal();
    }

    // CONFIRMAR ACCION DELETE FILE 
    const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const toastConfirm = useRef<any>(null);
    const onRejectAction = () => {
        setVisibleConfirmDialog(false);
    }
    const setConfirmarDeleteObject = () => {
        const IsValidaCampos = [true, ''] // onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toastConfirm.current.show({ severity: 'danger', summary: '::PSISABE', detail: IsMessage, life: 8000 });
            return;
        }
        // si no hay campos invalidos procesar...    
        setVisibleConfirmDialog(true);
    }

    return (
        <>
            <UploadFiles isOpen={isOpenModal} isHide={closeModal} setIsMounted={setIsMounted}
                url={uploadFileToS3BucketURL}
                onUpload={onTemplateUpload}
            />
            <Toast ref={toast} position="bottom-right"></Toast>
            <Toast ref={toastConfirm} position="center"></Toast>
            <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
                message="¿Eliminar archivo de forma permanente?"
                // message = {`¿Eliminar archivo? ${vl_fileObjeto}`}
                header="Eliminar archivo " icon="pi pi-trash"
                acceptClassName='p-button-danger '
                rejectLabel="No, Conservar" acceptLabel="Si, Eliminar"
                rejectIcon='pi pi-thumbs-up' acceptIcon='pi pi-thumbs-down'
                accept={setDeleteObject}
                reject={onRejectAction}
            />

            <Dialog position={'bottom-right'} modal draggable resizable closable showHeader maximizable
                style={{ minWidth: "700px", minHeight: "400px" }}
                header={template_header}
                footer={renderFooter}
                visible={prop.isOpen}
                onHide={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }}
            >
                <div className="flex flex-wrap justify-content-start text-base pt-2" >
                    <div>
                        <Button icon="pi pi-cloud-upload"
                            onClick={(e: any) => { handleClickUploadFiles(e) }}
                            label='Cargar' className="p-button-warning p-button mr-2"
                        />
                    </div>
                </div>

                <div className="flex flex-nowrap justify-content-start text-base ">
                    <div className='p-2' >
                        <Tree
                            header={
                                <>
                                    <span className="p-buttonset">
                                        <Button icon="pi pi-refresh" onClick={() => { getListObjects() }}
                                            tooltip='Recargar carpetas' className="p-button-sm p-button-outlined " />
                                    </span>
                                </>
                            }
                            contentStyle={{ minWidth: "300px", maxWidth: "400px", minHeight: "400px" }}
                            className='text-cyan-700 font-semibold'
                            value={datFolderTree}
                            selectionMode="single"
                            // selectionKeys={selectedKeys3}
                            onSelect={(e: any) => {
                                console.log(e.node);
                                let md: any = ' KB';
                                var sizeByte: any = e.node.size;
                                var siezekiloByte: any = sizeByte / 1024;
                                if (siezekiloByte > 1024) {
                                    md = ' MB';
                                    siezekiloByte = siezekiloByte / 1024;
                                }
                                setFileObjeto(e.node.label);
                                setFilekey(e.node.key);
                                setFileSize(siezekiloByte.toFixed(2) + md);
                                setFileLastModified(e.node.lastModified);
                                setFileStorageClass(e.node.storageClass);
                            }}
                            onSelectionChange={e => {
                                // console.log(e.value);
                                // setSelectedKeys3(e.value);                                    
                            }}
                        />
                    </div>

                    <div className="product-item w-50 ">
                        <div className="product-item-content">
                            <div className="flex justify-content-center mb-3">
                                <img
                                    src={`/images/icons8-microsoft-word-2019-128.png`}
                                    onError={(e: any) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                                    alt={'Documento adjunto'}
                                    className="product-image" />
                            </div>
                            <div>
                                <div className="grid-col white-space-nowrap overflow-hidden text-overflow-ellipsis text-blue-800 text-xl ">
                                    <h4 className="mb-2">{vl_fileObjeto}</h4>
                                </div>
                                <div className='font-italic product-badge ' >
                                    <h6>Size: {vl_fileSize} </h6>
                                    <h6>Last Modified: {vl_fileLastModified}</h6>
                                    <h6>Storage Class: {vl_fileStorageClass}</h6>
                                    <h6>Key: {vl_fileKey}</h6>
                                </div>
                                <div className="car-buttons mt-5 border-gray-500 pt-1 border-top-3 ">
                                    <Button icon="pi pi-cloud-download"
                                        label='Descargar' className="p-button p-button-link mr-2"
                                        onClick={() => {
                                            getDownloadfile()
                                        }} />

                                    <Button icon="pi pi-trash"
                                        className="p-button-danger p-button-rounded mr-2" label='Eliminar'
                                        onClick={() => {
                                            setConfirmarDeleteObject()
                                        }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default PacienteDocumentAdjuntos;
import { InputNumber } from 'primereact/inputnumber'

export const FooterGridNumber = ({ ...prop }) => {

    return (
        <>
            <div className="block bg-gray-100 font-bold text-end p-1 mt-1 mb-2 flex justify-content-end align-items-center ">
                <label className="p-col-fixed mr-1 pt-1 font-bold" style={{ textAlign: 'end' }}>{prop.Title ? prop.Title : 'Gran Total:'}</label>
                <InputNumber type="text" inputClassName='text-xl ext-orange-500' inputStyle={{ width: '8rem', textAlign: 'end', color: 'var(--blue-600)' }}
                    value={prop.Total} readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                    locale="en-US"
                />
            </div>
        </>
    )
}

export const FooterGridQuantity = ({ ...prop }) => {

    return (
        <>
            <div className="block bg-gray-100 font-bold text-end p-1 mt-1 mb-2 flex justify-content-end align-items-center ">
                <label className="p-col-fixed mr-1 pt-1 font-bold" style={{ textAlign: 'end' }}>{prop.Title ? prop.Title : 'Item(s):'}</label>
                <InputNumber type="text" inputClassName='text-xl ext-orange-500' inputStyle={{ width: '7rem', textAlign: 'center', color: 'var(--blue-600)' }}
                    value={prop.Total} readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                    locale="en-US"
                />
            </div>
        </>
    )
}

import { useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { fetchSinToken } from '../../../../helpers/fetch';
// import { PlanPagos } from '../../admision/ordenAtencion/PlanPagos';
import { DetallePagos } from '../../admision/ordenAtencion/DetallePagos';
import { Toast } from 'primereact/toast';
import { DetalleCuotas } from '../../ordenPago/ordenTerapia/DetalleCuotas';
import useModal from '../../../../context/WindowsOpen';

export const OrdenPagos02 = ({ ...props }) => {
    //  console.log(props);        
    // const [dataPlanPagos, setDataPlanPagos] = useState<any>([]);
    const [dataDetallePagos, setDataDetallePagos] = useState<any>([]);

    // const o_planPago = useRef<OverlayPanel>(null);
    const o_detallePago = useRef<OverlayPanel>(null);
    const toast = useRef<any>(null);
    //new
    const [vl_TipoRegistro, setTipoRegistro] = useState<any>('');
    const [vl_IdOrdenPago, setIdOrdenPago] = useState<any>(0);
    const [vl_IdRegistro, setIdRegistro] = useState<any>(0);
    const [isOpenModal, openModal, closeModal] = useModal();
    const isMounted = useRef(false);
    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }

    const handleClickDetalleCuota = () => {
        isMounted.current = true;
        openModal();
    }

    const template_statusPago = (item: any) => {
        // console.log(item);
        const BtnStatusPending = () => {
            return (
                <>
                    <Button
                        type="button" aria-readonly
                        className="p-button-sm p-button-info p-button-text pl-1 "
                        icon="pi pi-minus-circle"                        
                        label="Pendiente"
                    />
                </>
            );
        }
        const BtnStatusPagado = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button-sm p-button-success p-button-text pl-1"
                        // icon="pi pi-check-circle"                        
                        label="Pagado"
                    />
                </>
            );
        }
        const BtnStatusParcial = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button-sm p-button-warning p-button-text pl-1  "
                        // icon="pi pi-spinner"                        
                        label="Parcial"
                    />
                </>
            );
        }


        return (
            <>
                {item.EstadoPago === 'P' ? <BtnStatusPending /> : item.EstadoPago === 'T' ? <BtnStatusPagado /> : <BtnStatusParcial />}
            </>
        );
    }
    const template_OrdenPagoDetalle = (item: any) => {
        const vl_IdOrdenPago: any = item.IdOrdenPago;
        // return <span className={`product-badge status-${option.value.toLowerCase()}`}>{option.label}</span>
        return (
            <>
                <Button label={`${vl_IdOrdenPago}`} className="p-button-link pl-1" title='Ver detalle de la orden de pago'
                    onClick={(e) => props.pa_get_orden_pago_detalle(vl_IdOrdenPago, e)}
                />
            </>
        );
    }

    const template_btnDetallePago = (item: any) => {
        const vl_IdOrdenPago = item.IdOrdenPago;
        return (
            <>
                <Button
                    type="button"
                    className="p-button-sm p-button-warning"
                    icon="pi pi-dollar"                    
                    title="Detalle de Pagos"
                    onClick={(e) => pa_get_pago_detalle(vl_IdOrdenPago, e)}
                />
            </>
        );
    }
    const template_NumberCurrency = (item: any) => {
        return (
            <>
                ${item.Saldo}
            </>
        );
    }

    // const pa_get_plan_pagos = async (IdPlanPago: any, e: any) => {
    //     // console.log('IdPlanPago:'+ IdPlanPago );        
    //     if (Number(IdPlanPago) > 0) {
    //         const param = {
    //             "vp_IdPlanPago": IdPlanPago
    //         }
    //         const response = await fetchSinToken('admision/pa_get_plan_pagos', param, 'POST');
    //         const resp = await response.json();
    //         const record = resp.data;
    //         // setDataPlanPagos(record);
    //         // Mostrar el Overlay Popup
    //         o_planPago.current?.toggle(e);
    //     }
    // }
    const pa_get_pago_detalle = async (IdOrdenPago: any, e: any) => {
        // console.log('IdOrdenPago:'+ IdOrdenPago );        
        if (Number(IdOrdenPago) > 0) {
            const param = {
                "vp_IdOrdenPago": IdOrdenPago
            }
            const response = await fetchSinToken('admision/pa_get_pago_detalle', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataDetallePagos(record);
            // console.log(record);
            // Mostrar el Overlay Popup    
            if (record.errno)
                // console.log(record.message);
                toast.current.show({ severity: 'info', summary: 'Error', detail: record.message, life: 8000 });
            else
                o_detallePago.current?.toggle(e);
        }
    }
    const templateNumeroCuotaPago = (row: any) => {
        // console.log(row);
        const BtnNumeroCuotaPagoT = () => {
            return (
                <>
                    <Button label={`${'Ver'}`} className="p-button-link pl-1" title='Ver detalle de cuotas'
                        onClick={(e) => {
                            setIdOrdenPago(row.IdOrdenPago);
                            setTipoRegistro(row.TipoRegistro);
                            setIdRegistro(row.IdRegistro);
                            handleClickDetalleCuota();
                        }}
                    />
                </>
            );
        }
        const BtnNumeroCuotaPagoA = () => {
            return (
                <>
                    <Button label={`${row.NumeroCuotaPago}`} className="p-button-link pl-1" title='Ver detalle de cuotas'
                        onClick={(e) => {
                            setIdOrdenPago(row.IdOrdenPago);
                            setTipoRegistro(row.TipoRegistro);
                            setIdRegistro(row.IdRegistro);
                            handleClickDetalleCuota();
                        }}
                    />
                </>
            );
        }
        const BtnNumeroCuotaPago = () => {
            return (
                <>
                    <Button label={`${row.NumeroCuotaPago}`} className="p-button-link pl-1" title='Ver detalle de cuotas'
                        onClick={(e) => {
                            setIdOrdenPago(row.IdOrdenPago);
                            setTipoRegistro(row.TipoRegistro);
                            setIdRegistro(row.IdRegistro);
                            handleClickDetalleCuota();
                        }}
                    />
                </>
            );
        }

        return (
            <>
                {row.TipoRegistro === 'T' ? <BtnNumeroCuotaPagoT /> : row.TipoRegistro === 'A' ? <BtnNumeroCuotaPagoA /> : <BtnNumeroCuotaPago />}
            </>
        );
    }

    return (
        <>
            <Toast ref={toast} position="top-left"></Toast>

            <div className="p-field p-grid mt-1 mb-1">
                <div className="p-col">
                    <DataTable
                        size="small"
                        // style={{ height: "150px" }}
                        value={props.dataOrdenPago ?? []}
                        dataKey="IdOrdenPago"
                        resizableColumns
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        scrollHeight="198px"
                    // selectionMode="multiple"
                    // selection={props.dataSelectedOPItems}
                    // onSelectionChange={
                    //     (e) => {
                    //         props.setSelectedOPItems(e.value);
                    //     }
                    // }

                    >
                        {/* <Column selectionMode="multiple" style={{ maxWidth: '50px' }} ></Column> */}
                        <Column field="IdOrdenPago" header="O/P"
                            body={template_OrdenPagoDetalle}
                            style={{ maxWidth: '60px' }}
                            bodyClassName="flex justify-content-center"  ></Column>
                        <Column field="Fecha" header="Fecha" style={{ minWidth: '6rem', maxWidth: '6rem' }}
                            bodyClassName="flex justify-content-center" ></Column>
                        <Column field="Total" header="Total" style={{ maxWidth: '90px' }}
                            headerClassName="flex justify-content-end"
                            bodyClassName="flex justify-content-end "></Column>
                        <Column field="NumeroCuotas" header="Cant. Sesion" style={{ minWidth: '5rem', maxWidth: '5rem' }}
                            headerClassName="white-space-normal"
                            bodyClassName="flex justify-content-center font-bold"
                        ></Column>
                        <Column field="NumeroCuotaPago" header="Cuotas pago" style={{ minWidth: '5rem', maxWidth: '5rem' }}
                            headerClassName="white-space-normal"
                            bodyClassName="flex justify-content-center font-bold"
                            body={templateNumeroCuotaPago}
                        ></Column>
                        <Column field="Pagos" header="Pagos" style={{ maxWidth: '90px' }}
                            headerClassName="flex justify-content-center"
                            bodyClassName="flex justify-content-end"></Column>
                        <Column  header="Detalle Pago" body={template_btnDetallePago} style={{ minWidth: '4rem', maxWidth: '4rem' }}
                            bodyClassName="flex justify-content-center"
                            headerClassName="white-space-normal"
                        ></Column>
                        <Column field="Saldo" header="Saldo" style={{ maxWidth: '90px' }}
                            headerClassName="flex justify-content-end"
                            bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                            body={template_NumberCurrency} > </Column>
                        <Column field="EstadoPago" header="Estado Pago" body={template_statusPago}  ></Column>

                    </DataTable>
                </div>
            </div>

            <DetallePagos
                o_detallePago={o_detallePago}
                dataDetallePagos={dataDetallePagos}
            />
            <>
                {
                    isMounted.current && <DetalleCuotas ModoForm='1'
                        isOpen={isOpenModal}
                        isHide={closeModal}
                        setIsMounted={setIsMounted}
                        TipoRegistro={vl_TipoRegistro}
                        IdOrdenPago={vl_IdOrdenPago}
                        IdRegistro={vl_IdRegistro}
                        IdOrdenTerapia={0} // quitar
                        TotalInversion={0} //quitar
                        TotaltCuota={0} //quitar
                    />
                }
            </>

        </>
    )
}
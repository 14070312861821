import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import { fetchSinToken } from '../../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { FooterGridNumber } from '../../../components/util/FooterGrid';

const ReporteComisionResumen = ({ ...prop }) => {

    const [vl_GroupByP, setGroupByP] = useState<boolean>(true);
    const [vl_GroupByS, setGroupByS] = useState<boolean>(false);
    const [vl_GroupByT, setGroupByT] = useState<boolean>(false);
    const [vl_tipo, setTipo] = useState<any>('E');
    const [vl_Fecha1, setFecha1] = useState<any>(new Date());
    const [vl_Fecha2, setFecha2] = useState<any>(new Date());
    const [vl_IdSucursal, setIdSucursal] = useState<any>(0);
    const [vl_IdStaff, setIdStaff] = useState<any>(0);
    const [dataComisiones, setdataComisiones] = useState<any>([]);
    const [dataComisionesS, setdataComisionesS] = useState<any>([]);
    const [dataComisionesT, setdataComisionesT] = useState<any>([]);
    const [dataSede, setDataSede] = useState<any>([]);
    const [dataStaff, setDataStaff] = useState<any>([]);

    const toast = useRef<any>(null);

    useEffect(() => {
        pa_get_sys_sucursal();
        pa_get_staff();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setBtnSearcOnClick = () => {
        if (!vl_GroupByP && !vl_GroupByS && !vl_GroupByT) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar un tipo de agrupamiento', life: 8000 });
            return;
        }
        if (vl_GroupByP) pa_get_staff_comisiones_sumaryP();
        if (vl_GroupByS) pa_get_staff_comisiones_sumaryS();
        if (vl_GroupByT) pa_get_staff_comisiones_sumaryT();
    };

    const pa_get_staff_comisiones_sumaryP = async () => {
        // console.log(vl_IdStaff);
        const param = {
            "vp_groupby": 'P',
            "vp_tipo": vl_tipo,
            "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
            "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
            "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
            "vp_IdStaff": vl_IdStaff ? vl_IdStaff : 0
        }

        // prop.filter(param);

        if (vl_Fecha1 === '' || vl_Fecha1 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
            return;
        }
        if (vl_Fecha2 === '' || vl_Fecha2 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
            return;
        }
        const response = await fetchSinToken('caja/pa_get_staff_comisiones_sumary', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        if(record.length===0){
            setdataComisiones([]);
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No hay registros', life: 8000 });
            return;
        }
        setdataComisiones(record);
    }
    const pa_get_staff_comisiones_sumaryS = async () => {
        // console.log(vl_IdStaff);
        const param = {
            "vp_groupby": 'S',
            "vp_tipo": vl_tipo,
            "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
            "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
            "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
            "vp_IdStaff": vl_IdStaff ? vl_IdStaff : 0
        }
        if (vl_Fecha1 === '' || vl_Fecha1 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
            return;
        }
        if (vl_Fecha2 === '' || vl_Fecha2 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
            return;
        }
        const response = await fetchSinToken('caja/pa_get_staff_comisiones_sumary', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        if(record.length===0){
            setdataComisionesS([]);
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No hay registros', life: 8000 });
            return;
        }
        setdataComisionesS(record);
    }
    const pa_get_staff_comisiones_sumaryT = async () => {
        // console.log(vl_IdStaff);
        const param = {
            "vp_groupby": 'T',
            "vp_tipo": vl_tipo,
            "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
            "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
            "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
            "vp_IdStaff": vl_IdStaff ? vl_IdStaff : 0
        }
        if (vl_Fecha1 === '' || vl_Fecha1 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
            return;
        }
        if (vl_Fecha2 === '' || vl_Fecha2 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
            return;
        }
        const response = await fetchSinToken('caja/pa_get_staff_comisiones_sumary', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        if(record.length===0){
            setdataComisionesT([]);
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No hay registros', life: 8000 });
            return;
        }
        setdataComisionesT(record);
    }

    const pa_get_sys_sucursal = async () => {
        const param = {
            "VP_IdSucursal": 0
        }
        const response = await fetchSinToken('selectors/pa_get_sys_sucursal', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataSede(record);
    }

    const pa_get_staff = async () => {
        const param = {
            "vp_opcion": "A",
            "vp_IdStaff": 0,
            "VP_Codigo": "",
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataStaff(record);
    }

    const footer1 = () => {
        let vl_TotalBase: any = 0;
        let vl_Total: any = 0;
        dataComisiones.forEach(
            function (currentValue: any) {
                vl_TotalBase = vl_TotalBase + parseFloat(currentValue.TotalBase);
                vl_Total = vl_Total + parseFloat(currentValue.Total);
            }
        );
        return (
            <>
                <div className='flex align-items-stretch justify-content-end'>
                    <FooterGridNumber Total={vl_TotalBase} Title={'Total'} />
                    <FooterGridNumber Total={vl_Total} Title={'Tlt.Comisión'} />
                </div>
            </>
        );
    }

    const footer2 = () => {
        let vl_TotalBase: any = 0;
        let vl_Total: any = 0;
        dataComisionesS.forEach(
            function (currentValue: any) {
                vl_TotalBase = vl_TotalBase + parseFloat(currentValue.TotalBase);
                vl_Total = vl_Total + parseFloat(currentValue.Total);
            }
        );
        return (
            <>
                <div className='flex align-items-stretch justify-content-end'>
                    <FooterGridNumber Total={vl_TotalBase} Title={'Total'} />
                    <FooterGridNumber Total={vl_Total} Title={'Tlt.Comisión'} />
                </div>
            </>
        );
    }
    const footer3 = () => {
        let vl_TotalBase: any = 0;
        let vl_Total: any = 0;
        dataComisionesT.forEach(
            function (currentValue: any) {
                vl_TotalBase = vl_TotalBase + parseFloat(currentValue.TotalBase);
                vl_Total = vl_Total + parseFloat(currentValue.Total);
            }
        );
        return (
            <>
                <div className='flex align-items-stretch justify-content-end'>
                    <FooterGridNumber Total={vl_TotalBase} Title={'Total'} />
                    <FooterGridNumber Total={vl_Total} Title={'Tlt.Comisión'} />
                </div>
            </>
        );
    }

    return (
        <>
            <Toast ref={toast} position="top-center"></Toast>
            <div className={prop.bhidden + ' card w-full p-2 ml-2'} >
                <div className='card p-1 bg-gray-50'>
                    <div className="flex justify-content-between p-2 ">
                        <div>
                            <RadioButton value="E" name="btnTipofecha"
                                onChange={(e) => setTipo(e.value)}
                                checked={vl_tipo === "E"}
                            /><label className="p-col-fixed ml-2 mr-3 font-normal text-sm " style={{ textAlign: 'end' }}>F. Atención</label>
                            <RadioButton value="P" name="btnTipofecha"
                                onChange={(e) => setTipo(e.value)}
                                checked={vl_tipo === "P"}
                            /><label className="p-col-fixed ml-1 mr-0 font-normal text-sm " style={{ textAlign: 'end' }}>F. Pago</label>
                        </div>
                        <div className='p-1' >
                            <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Agrupar:</label>
                            <Checkbox className="ml-3" onChange={e => {
                                setGroupByP(e.checked);
                                if (e.checked) pa_get_staff_comisiones_sumaryP();
                                else setdataComisiones([]);
                            }} checked={vl_GroupByP}> </Checkbox>
                            <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Profesional</label>
                            <Checkbox className="ml-3" onChange={e => {
                                setGroupByS(e.checked);
                                if (e.checked) pa_get_staff_comisiones_sumaryS();
                                else setdataComisionesS([]);
                            }} checked={vl_GroupByS}> </Checkbox>
                            <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>Servicio</label>
                            <Checkbox className="ml-3" onChange={e => {
                                setGroupByT(e.checked);
                                if (e.checked) pa_get_staff_comisiones_sumaryT();
                                else setdataComisionesT([]);
                            }} checked={vl_GroupByT}> </Checkbox>
                            <label className="p-col-fixed ml-1 mr-2 font-normal text-sm " style={{ textAlign: 'end' }}>T. Cita</label>
                        </div>
                    </div>
                    <div className="flex flex-wrap card-container w-full ">
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed mr-1 ml-2 text-base font-semibold " style={{ textAlign: 'end' }}>Del:</label>
                            <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                                maxDate={new Date()}
                                value={vl_Fecha1}
                                onChange={(e) => setFecha1(e.value)}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Al:</label>
                            <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                                maxDate={new Date()}
                                value={vl_Fecha2}
                                onChange={(e) => setFecha2(e.value)}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Sede:</label>
                            <Dropdown style={{ width: '12rem' }} showClear
                                value={vl_IdSucursal}
                                options={dataSede}
                                onChange={(e: any) => {
                                    setIdSucursal(e.value);
                                }}
                                optionValue="IdSucursal"
                                optionLabel="Descripcion"
                                placeholder="Selecionar" />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1" >
                            <label className="p-col-fixed ml-2 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Profesional:</label>
                            <Dropdown style={{ width: '15rem' }} filter showClear filterBy="staff"
                                value={vl_IdStaff}
                                options={dataStaff}
                                onChange={(e: any) => {
                                    setIdStaff(e.value);
                                }}
                                optionValue="IdStaff"
                                optionLabel="staff"
                                placeholder="Profesional" />
                            <Button title="Buscar"
                                icon="pi pi-search"
                                className="p-button-rounded  ml-2"
                                onClick={() => {
                                    setBtnSearcOnClick();
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap justify-content-center w-full ">
                    <div className="flex justify-content-center m-1 w-49" >
                        <div className='card p-1' style={{ width: '100%' }} >
                            <DataTable size="small" resizableColumns footer={footer1}
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                value={dataComisiones}
                                selectionMode="single"
                                // scrollHeight="300px"
                                scrollHeight="50vh"
                                paginator
                                rows={50}
                            >
                                <Column field="Staff" header="Profesional" style={{ minWidth: '300px' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="white-space-normal"
                                >
                                </Column>

                                <Column field="TotalBase" header="Total servicio" style={{ maxWidth: '8rem' }}
                                    headerClassName="flex justify-content-center white-space-normal"
                                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-cyan-800  w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='S/ ' readOnly value={item.TotalBase}
                                        />
                                    }}
                                >
                                </Column>
                                <Column field="Total" header="Total Comisión" style={{ maxWidth: '8rem' }}
                                    headerClassName="flex justify-content-center white-space-normal "
                                    bodyClassName="flex justify-content-end text-green-600 font-bold"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-green-600 w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='S/ ' readOnly value={item.Total}
                                        />
                                    }}
                                >
                                </Column>
                                <Column field="Porcentage" header="" style={{ maxWidth: '7rem' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-end"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-red-600  w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='% ' readOnly value={item.Porcentage}
                                        />
                                    }}
                                >
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div className="flex justify-content-center m-1 w-49" >
                        <div className='card p-1' style={{ width: '100%' }} >
                            <DataTable size="small" resizableColumns footer={footer2}
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                value={dataComisionesS}
                                selectionMode="single"
                                // scrollHeight="300px"
                                scrollHeight="50vh"
                                paginator
                                rows={50}
                            >
                                <Column field="Servicio" header="Servicio" style={{ minWidth: '300px' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="white-space-normal"
                                >
                                </Column>

                                <Column field="TotalBase" header="Total servicio" style={{ maxWidth: '8rem' }}
                                    headerClassName="flex justify-content-center white-space-normal"
                                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-cyan-800  w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='S/ ' readOnly value={item.TotalBase}
                                        />
                                    }}
                                >
                                </Column>
                                <Column field="Total" header="Total Comisión" style={{ maxWidth: '8rem' }}
                                    headerClassName="flex justify-content-center white-space-normal "
                                    bodyClassName="flex justify-content-end text-green-600 font-bold"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-green-600 w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='S/ ' readOnly value={item.Total}
                                        />
                                    }}
                                >
                                </Column>
                                <Column field="Porcentage" header="" style={{ maxWidth: '7rem' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-end"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-red-600  w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='% ' readOnly value={item.Porcentage}
                                        />
                                    }}
                                >
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-content-center w-full ">
                    <div className="flex justify-content-center m-1 w-49" >
                        <div className='card p-1' style={{ width: '100%' }} >
                            <DataTable size="small" resizableColumns footer={footer3}
                                responsiveLayout="scroll"
                                columnResizeMode="fit"
                                showGridlines
                                stripedRows
                                scrollable
                                value={dataComisionesT}
                                selectionMode="single"
                                // scrollHeight="300px"
                                scrollHeight="50vh"
                                // paginator
                                // rows={50}
                            >
                                 <Column field="Staff" header="Profesional" style={{ minWidth: '300px' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="white-space-normal"
                                >
                                </Column>
                                <Column field="EventType" header="Tipo cita" style={{ minWidth: '200px' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="white-space-normal"
                                >
                                </Column>

                                <Column field="TotalBase" header="Total servicio" style={{ maxWidth: '8rem' }}
                                    headerClassName="flex justify-content-center white-space-normal"
                                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-cyan-800  w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='S/ ' readOnly value={item.TotalBase}
                                        />
                                    }}
                                >
                                </Column>
                                <Column field="Total" header="Total Comisión" style={{ maxWidth: '8rem' }}
                                    headerClassName="flex justify-content-center white-space-normal "
                                    bodyClassName="flex justify-content-end text-green-600 font-bold"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-green-600 w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='S/ ' readOnly value={item.Total}
                                        />
                                    }}
                                >
                                </Column>
                                <Column field="Porcentage" header="" style={{ maxWidth: '7rem' }}
                                    headerClassName="flex justify-content-center"
                                    bodyClassName="flex justify-content-end"
                                    body={(item: any) => {
                                        return <InputNumber type="text" inputClassName='border-0 text-red-600  w-50' inputStyle={{ height: '30px', textAlign: 'right' }}
                                            mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                                            locale="en-US" prefix='% ' readOnly value={item.Porcentage}
                                        />
                                    }}
                                >
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReporteComisionResumen;
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressBar } from 'react-bootstrap';


export const PlanPagos = ({ ...props }) => {
    // console.log('PlanPagos');
    // console.log(props.dataPlanPagos);    
    const vl_TotalInversion = props.dataPlanPagos[0]?.tpp_Importe;
    const vl_TotalPagos = props.dataPlanPagos[0]?.Pagos;
    const vl_TotaltCuota = props.dataPlanPagos[0]?.tpp_Cuota;
    const vl_CuotaPagada = props.dataPlanPagos[0]?.CuotaPagada;
    const vl_Saldo = props.dataPlanPagos[0]?.tpp_Saldo;

    const templateStatusPago = (item: any) => {
        // console.log(item);
        const BtnStatusPending = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button p-button-warning "
                        icon="pi pi-minus-circle" style={{ height: '24px', width:'120px' }}
                        label="Pendiente"
                    />
                </>
            );
        }
        const BtnStatusPagado = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button p-button-success   "
                        icon="pi pi-check-circle" style={{ height: '24px', width:'120px' }}
                        label="Pagado"
                    />
                </>
            );
        }
        const BtnStatusParcial = () => {
            return (
                <>
                    <Button
                        type="button"
                        className="p-button p-button-warning   "
                        icon="pi pi-spinner"
                        style={{ height: '24px', width:'120px' }}
                        label="Parcial"
                    />
                </>
            );
        }


        return (
            <>
                {item.Estado === 'P' ? <BtnStatusPending /> : item.Estado === 'T' ? <BtnStatusPagado /> : <BtnStatusParcial />}
            </>
        );
        
    }
    const templateNumberCurrency = (item: any) => {
        return (
            <>
                ${item.Saldop}
            </>
        );
    }


    // var reactNodeLi = createElement('li', {className:''}, {id:'li1'}, 'one');

    return (
        <>
            <OverlayPanel
                ref={props.oplanPago}
                showCloseIcon={false}
                style={{ width: "800px" }}
            >
                <div className="p-field p-grid mb-1">
                    <div className="p-col mb-1 " >
                        <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '50px', textAlign: 'end' }}>Total:</label>
                        <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                            inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }}
                            value={vl_TotalInversion}
                            readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        />
                        <label className="p-col-fixed mr-1 font-bold " style={{ width: '100px', textAlign: 'end' }}>C. Pagada:</label>
                        <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                            inputStyle={{ height: '35px', width: '50px', textAlign: 'center' }}
                            value={vl_CuotaPagada}
                            readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                        />
                        <label className="p-col-fixed mr-1 font-bold " style={{ width: '35px', textAlign: 'end' }}>de:</label>
                        <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                            inputStyle={{ height: '35px', width: '50px', textAlign: 'right' }}
                            value={vl_TotaltCuota}
                            readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                        />
                        <label className="p-col-fixed mr-1 font-bold " style={{ width: '100px', textAlign: 'end' }}>T.Pago:</label>
                        <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                            inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }}
                            value={vl_TotalPagos}
                            readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        />
                        <label className="p-col-fixed mr-1 font-bold " style={{ width: '80px', textAlign: 'end' }}>Saldo:</label>
                        <InputNumber type="text" inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                            inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }}
                            value={vl_Saldo}
                            readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                        />
                    </div>
                    <ProgressBar
                        min={0}
                        now={vl_CuotaPagada}
                        max={vl_TotaltCuota}
                        label={'Pagados ' + vl_CuotaPagada + ' de ' + vl_TotaltCuota}
                        variant={vl_CuotaPagada === vl_TotaltCuota ? 'success' : 'info'} ></ProgressBar>
                </div>

                <DataTable header="Plan de Cuotas"
                    size="small"
                    value={props.dataPlanPagos ?? []}
                    dataKey="IdPlanPagoDetalle"
                    resizableColumns
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    selectionMode="single"  
                >
                    {/* <Column field="IdPlanPagoDetalle" header="Id" style={{ maxWidth: '60px' }} ></Column> */}
                    <Column field="Cuota" header="C." style={{ maxWidth: '50px' }}
                        bodyClassName="flex justify-content-center"  ></Column>
                    <Column field="FechaVencimiento" header="F. Vencimiento" style={{ maxWidth: '150px' }}
                        bodyClassName="flex justify-content-center" ></Column>
                    <Column field="Importe" header="Importe" style={{ maxWidth: '90px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end "></Column>
                    <Column field="Pago" header="Pagado" style={{ maxWidth: '100px', align: 'end' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-green-800 font-bold"></Column>
                    <Column field="FechaPago" header="Fecha Pago" style={{ maxWidth: '120px', align: 'end' }}
                        headerClassName="flex justify-content-center"
                        bodyClassName="flex justify-content-center"></Column>
                    <Column field="Saldop" header="Saldo" style={{ maxWidth: '100px' }}
                        headerClassName="flex justify-content-end"
                        bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                        body={templateNumberCurrency} > </Column>
                    <Column field="Estado" header="Estado Cuota" body={templateStatusPago} style={{ maxWidth: '170px' }}  ></Column>
                </DataTable>

                <Button
                    type="button"
                    className="p-button-rounded p-button-warning ml-1 mt-1 "
                    icon="pi pi-times"
                    label="Cerrar"
                    onClick={
                        (e) => {
                            props.oplanPago.current?.hide()
                        }
                    }
                />
            </OverlayPanel>
        </>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import { fetchSinToken } from '../../../../helpers/fetch';
import { Toast } from 'primereact/toast';


const ReporteIngresos = ({ ...prop }) => {

    const [vl_Fecha1, setFecha1] = useState<any>(new Date());
    const [vl_Fecha2, setFecha2] = useState<any>(new Date());
    const [vl_IdSucursal, setIdSucursal] = useState<any>(0);
    const [vl_IdUsuario, setIdUsuario] = useState<any>(0);
    const [dataIngresos, setDataIngresos] = useState<any>([]);
    const [dataSede, setDataSede] = useState<any>([]);
    const [dataUsuario, setDataUsuario] = useState<any>([]);

    const toast = useRef<any>(null);

    useEffect(() => {
        pa_get_sys_sucursal();
        pa_get_sys_usuario();
        pa_get_caja_rep_ingresos('0');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_caja_rep_ingresos = async (IN_opcion: any) => {
        const param = {
            "vp_opcion": IN_opcion,
            "vp_Fecha1": setIntlDateTimeFormat(vl_Fecha1),
            "vp_Fecha2": setIntlDateTimeFormat(vl_Fecha2),
            "vp_IdSucursal": vl_IdSucursal ? vl_IdSucursal : 0,
            "vp_IdUsuario": vl_IdUsuario ? vl_IdUsuario : 0

        }
        if (vl_Fecha1 === '' || vl_Fecha1 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
            return;
        }
        if (vl_Fecha2 === '' || vl_Fecha2 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
            return;
        }
        const response = await fetchSinToken('caja/pa_get_caja_rep_ingresos', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataIngresos(record);
    }

    const pa_get_sys_sucursal = async () => {
        const param = {
            "VP_IdSucursal": 0
        }
        const response = await fetchSinToken('selectors/pa_get_sys_sucursal', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataSede(record);
    }
    const pa_get_sys_usuario = async () => {
        const param = {
            "vp_IdUsuario": 0
        }
        const response = await fetchSinToken('selectors/pa_get_sys_usuario', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataUsuario(record);
    }

    const pa_get_caja_rep_ingresos_xls = async () => {
        if (vl_Fecha1 === '' || vl_Fecha1 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Desde', life: 8000 });
            return;
        }
        if (vl_Fecha2 === '' || vl_Fecha2 === null) {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar fecha Hasta', life: 8000 });
            return;
        }
        let vp_IdSucursal = vl_IdSucursal ? vl_IdSucursal : 0;
        let vp_IdUsuario = vl_IdUsuario ? vl_IdUsuario : 0;
        const endpoint = 'caja/pa_get_rep01Xls/?vp_Fecha1=' + setIntlDateTimeFormat(vl_Fecha1) + '&vp_opcion=0&vp_IdSucursal=' + vp_IdSucursal + '&vp_IdUsuario=' + vp_IdUsuario + '&vp_Fecha2=' + setIntlDateTimeFormat(vl_Fecha2);
        // console.log(endpoint);
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    return (
        <>
            <Toast ref={toast} position="center"></Toast>
            <div className={prop.bhidden + ' card w-full p-2 ml-2'} >
                <div className='card p-2'>
                    <div className="flex flex-wrap card-container w-full ">
                        <div className="flex align-items-center justify-content-center m-1">
                            <label className="p-col-fixed mr-1 ml-2 text-base font-semibold " style={{ textAlign: 'end' }}>Del:</label>
                            <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                                maxDate={new Date()}
                                value={vl_Fecha1}
                                onChange={(e) => setFecha1(e.value)}
                            />
                            <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Al:</label>
                            <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                                dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2020:2030"
                                maxDate={new Date()}
                                value={vl_Fecha2}
                                onChange={(e) => setFecha2(e.value)}
                            />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1">
                            <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Sede:</label>
                            <Dropdown style={{ width: '12rem' }} showClear
                                value={vl_IdSucursal}
                                options={dataSede}
                                onChange={(e: any) => {
                                    setIdSucursal(e.value);
                                }}
                                optionValue="IdSucursal"
                                optionLabel="Descripcion"
                                placeholder="Selecionar" />
                        </div>
                        <div className="flex align-items-center justify-content-center m-1">
                            <label className="p-col-fixed ml-1 mr-1 text-base font-semibold " style={{ textAlign: 'end' }}>Usuario:</label>
                            <Dropdown style={{ width: '10rem' }} showClear filter
                                value={vl_IdUsuario}
                                options={dataUsuario}
                                onChange={(e: any) => {
                                    setIdUsuario(e.value);
                                }}
                                optionValue="IdUsuario"
                                optionLabel="UserName"
                                placeholder="Seleccionar" />

                            <Button title="Buscar"
                                icon="pi pi-search"
                                className="p-button-rounded  ml-1"
                                onClick={() => pa_get_caja_rep_ingresos(0)}
                            />
                            <Button title="Exportar a excel"
                                icon="pi pi-file-excel"
                                className="p-button-rounded p-button-text ml-1 mr-1"
                                label='Excel'
                                onClick={(e: any) => pa_get_caja_rep_ingresos_xls()}
                            />
                        </div>
                    </div>
                </div>
                <div className='mt-1 p-0 w-full'>
                    <DataTable
                        size="small"
                        // footer={footer}                         
                        style={{ height: '100%', width: '100%' }}
                        value={dataIngresos ?? []}
                        resizableColumns
                        responsiveLayout="scroll"
                        columnResizeMode="fit"
                        showGridlines
                        stripedRows
                        scrollable
                        scrollHeight="60vh"
                        scrollDirection='horizontal'
                        paginator
                        rows={100}
                    >
                        <Column field="IdPago" header="# Recibo" bodyClassName="flex justify-content-center" ></Column>
                        <Column field="Detalle" header="Detalle movimiento" style={{ minWidth: '12rem' }} bodyClassName='white-space-normal'  ></Column>
                        <Column field="FechaPago" header="Fecha Pago" headerClassName="white-space-normal" ></Column>
                        <Column field="tpd_Total" header="Importe"
                            headerClassName="flex justify-content-end"
                            bodyClassName="flex justify-content-end text-green-600 font-semibold"
                        ></Column>
                        <Column field="FormaPago" header="Forma Pago" headerClassName="white-space-normal" ></Column>
                        <Column field="ReferenciaPago" header="Referencia Pago" headerClassName="white-space-normal" ></Column>
                        <Column field="IdBanco" header="Banco" bodyClassName="flex justify-content-left" ></Column>
                        <Column field="cta" header="Ingreso a" bodyClassName="flex justify-content-left" ></Column>
                        <Column field="RefNumFacturaRecibo" header="N° Factura(ref)" ></Column>
                        <Column field="NumeroHistoriaClinica" header="Nº HC" ></Column>
                        <Column field="Cliente" header="Cliente o Paciente" headerClassName="white-space-normal"  ></Column>
                        <Column field="Sucursal" header="Sede" headerClassName="white-space-normal"  ></Column>
                        <Column field="UsuarioRegistro" header="Usuario" headerClassName="white-space-normal"  ></Column>
                        <Column field="FechaRegistro" header="Fecha Registro" headerClassName="white-space-normal"  ></Column>
                    </DataTable>

                </div>
            </div>
        </>
    )
}

export default ReporteIngresos
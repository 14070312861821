import { useReducer } from 'react';
import { AppState, AppMenuTitle } from '../interfaces/GlobalInterface';

import { Props } from '../types/GlobalType';
import { AppContext, AppContext01 } from './AppContext';
import { AppReducer, AppReducer01 } from './AppReducer';


const INITIAL_STATE: AppState = {
  isVisibleSidebar: true
}

export const AppProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(AppReducer, INITIAL_STATE);
  
  // console.log('AppProvider');
  const showSidebar = (state: boolean) => {
    //  console.log(state);
    dispatch({
       type: 'showSidebar', payload: { state } 
      }
    );
  }



  return (
    <AppContext.Provider value={{
      appState: state,
      showSidebar
    }}>
      {children}
    </AppContext.Provider>
  )
}


const INITIAL_STATE_1: AppMenuTitle = {
  isVisibleTitle: ""
}
export const AppTitleMenu = ({ children }: Props) => {
  const [title, dispatch] = useReducer(AppReducer01, INITIAL_STATE_1);
  // console.log(title);
  const showmenuTitle = (title: string) => {    
    dispatch({ type01: 'showmenuTitle', payload01: { title } });
  }
  return (
    <AppContext01.Provider value={{
      appmenuTitle: title,
      showmenuTitle
    }}>
      {children}
    </AppContext01.Provider>
  )
}

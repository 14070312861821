import { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Dialog } from 'primereact/dialog';
import { fetchSinToken } from '../../../helpers/fetch';
import { Button } from 'primereact/button';

const AgendaView = ({ ...prop }) => {

    const [dataAgenda, setDataAgenda] = useState<any>([]);
    const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>('');
    const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>('');
    const [vl_Nombres, setNombres] = useState<any>('');
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');

    useEffect(() => {
        pa_get_agenda_paciente();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_get_agenda_paciente = async () => {
        const param = {
            "vp_IdAgenda": 0,
            "vp_FechaYear": 2022,
            "vp_FechaMonth": 0,
            "vp_IdCiente": prop.IdCliente,
            "vp_IdSucursal": '0'
        }
        const response = await fetchSinToken('paciente/pa_get_agenda_paciente', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        setApellidoPaterno(record[0].ApellidoPaterno);
        setApellidoMaterno(record[0].ApellidoMaterno);
        setNombres(record[0].Nombres);
        setNumeroHistoriaClinica(record[0].NumeroHistoriaClinica);
        
        setDataAgenda(record);
    }

    const renderFooter = () => {
        return (
            <div>
                <Button label="Aceptar" icon="pi pi-check" onClick={() =>{
                    prop.setIsMounted(false);
                    prop.isHide();
                } } autoFocus />
            </div>
        );
    }
    const renderHeader = () => {
        return (
            <>
                <div className="bg-indigo-500 pb-1">
                    <div className="col mt-0 ml-0 text-white font-italic h-3rem" style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='text-base font-bold'> Paciente: {vl_ApellidoPaterno + ' ' + vl_ApellidoMaterno + ', ' + vl_Nombres} </label><br />
                        <label className='text-base font-bold'> Nº HC. {vl_NumeroHistoriaClinica} </label>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Dialog
                header={renderHeader}
                modal draggable resizable closable showHeader maximizable
                visible={prop.isOpen}
                footer={renderFooter}
                onHide={() => {
                    prop.setIsMounted(false);
                    prop.isHide();
                }}
               > 
                <div className="card pt-1 pl-1 pr-1">
                    <div className="col mt-1 ml-1 mb-1 border-round-bottom flex justify-content-end">
                        <label className="col text-sm bg-indigo-500 text-white" style={{ textAlign: 'center' }} >Programada</label>
                        {/* <label className="col text-sm bg-pink-600 text-white" style={{ textAlign: 'center' }}>Cancelada</label> */}
                        <label className="col text-sm bg-purple-500 text-white" style={{ textAlign: 'center' }}>Confirmada</label>
                        <label className="col text-sm bg-orange-500 text-white" style={{ textAlign: 'center' }}>Reprogramada</label>
                        <label className="col text-sm bg-green-600 text-white" style={{ textAlign: 'center' }}>Realizada</label>
                        <label className="col text-sm bg-cyan-500 text-white" style={{ textAlign: 'center' }}>No Realizada</label>
                    </div>
                    <FullCalendar
                        events={dataAgenda}
                        // initialDate="2022-04-14"                        
                        initialView='listMonth' //dayGridMonth
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        headerToolbar={{ left: 'prev,next today listMonth', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay' }}
                        defaultRangeSeparator=' - '
                        titleRangeSeparator=' al '
                        eventTimeFormat={{
                            hour: 'numeric',
                            minute: '2-digit',
                            meridiem: 'short'
                        }}
                        buttonText={
                            {
                                today: 'Hoy',
                                month: 'Mes',
                                week: 'Semana',
                                day: 'Dia',
                                list: 'Lista'
                            }
                        }
                        editable selectable selectMirror dayMaxEvents />
                </div>

            </Dialog>
        </>
    )
}

export default AgendaView
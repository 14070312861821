import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useModal from '../../../../context/WindowsOpen';
import { fetchSinToken } from '../../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import BuscadorClientes from '../../util/BuscadorClientes';
import { FooterGridNumber } from '../../util/FooterGrid';

const EgresoForm = () => {

    const params = useParams();
    const navigate = useNavigate();
    // console.log(params.mode);
    // console.log(params.id);
    // Inputs Crud 
    const [VP_action, setAction] = useState(params.mode);
    const [VP_IdRegistroEgreso, setIdRegistroEgreso] = useState<any>('0');
    const [VP_IdTipoDocumento, setIdTipoDocumento] = useState<any>('1');
    const [VP_NumeroDocumento, setNumeroDocumento] = useState<any>('');
    const [VP_FechaEmision, setFechaEmision] = useState<any>(new Date());
    const [VP_HoraEmision, setHoraEmision] = useState<any>(new Date());
    const [VP_Fecha, setFecha] = useState<any>(new Date());
    const [VP_IdCentroCosto, setIdCentroCosto] = useState<any>(1);

    const [VP_Cliente, setCliente] = useState<any>('');
    const [VP_Importe, setImporte] = useState<any>(0);
    const [VP_PorcentageImpuesto, setPorcentageImpuesto] = useState<any>(0);
    const [VP_Impuesto, setImpuesto] = useState<any>(0);
    const [VP_Descuento, setDescuento] = useState<any>(0);
    const [VP_Retenciones, setRetenciones] = useState<any>(0);
    const [VP_Otros, setOtros] = useState<any>(0);
    const [VP_Total, setTotal] = useState<any>(0);
    const [VP_Estado, setEstado] = useState<any>('1');
    const [VP_result, setresult] = useState(1);
    //extras
    const [VP_IdTipoPersona, setIdTipoPersona] = useState<any>('X');
    const [b_contP, setContP] = useState<boolean>(true);
    const [b_contPN, setContPN] = useState<boolean>(true);
    const [b_contPJ, setContPJ] = useState<boolean>(true);

    //PERSONA / NATURAL O JURIDICA
    const [VP_IdCliente, setIdCliente] = useState<any>('0');
    //Empresa
    const [VP_NombreRazonSocial, setNombreRazonSocial] = useState<any>('');
    const [VP_NumeroRuc, setNumeroRuc] = useState<any>('');
    //Natural
    const [VP_Nombres, setNombres] = useState<any>('');
    const [VP_ApPaterno, setApPaterno] = useState<any>('');
    const [VP_ApMaterno, setApMaterno] = useState<any>('');
    const [VP_NumeroDNI, setNumeroDNI] = useState<any>('');
    const [VP_NumeroTelf, setNumeroTelf] = useState<any>('');
    //Ambas
    const [VP_Direccion, setDireccion] = useState<any>('');


    //combos
    const [dataIdTipoDocumento, setDataIdTipoDocumento] = useState<any>([]);
    const [dataIdCentroCosto, setDataIdCentroCosto] = useState<any>([]);
    const [dataProducts, setDataProducts] = useState<any>([]);

    //detalle
    const [IdRegistroEgresoDetalle, setIdRegistroEgresoDetalle] = useState<any>('0');
    const [CodigoProducto, setCodigoProducto] = useState<any>('');
    const [Detalle, setDetalle] = useState<any>('');
    const [Cantidad, setCantidad] = useState<any>('1');
    const [PrecioUnitario, setPrecioUnitario] = useState<any>(0);
    const [ImporteDetalle, setImporteDetalle] = useState<any>(0);
    const [DescuentoDetalle, setDescuentoDetalle] = useState<any>(0);
    const [TotalDetalle, setTotalDetalle] = useState<any>(0);
    // editor grid
    const [targetProducts, setTargetProducts] = useState<any>([]);
    const [selectedIemsGrid, setSelectedIemsGrid] = useState<any>([]);

    //
    const showDialogMessage = useRef<any>(null);


    useEffect(() => {

        setAction(params.mode);
        pa_get_sys_config('TipoDocGasto');
        pa_get_sys_config('CentroCostos');
        pa_get_tipo_servicio();

        switch (params.mode) {
            case 'New': //INSERT MODE
                setTimeout(() => {
                    // inputNumeroHistoriaClinicaRef.current?.focus();
                    // setHiddenbtnGrabar(false);
                }, 100);
                break;
            case 'Edit': //VIEW MODE
                pa_get_registro_egreso();
                // setDisabledNumeroHistoriaClinica(true);
                // setHiddenbtnEdit(false);
                // setHiddenbtnGrabar(false);
                break;
            case 'Delete': //ANULAR MODE
                // getRecorOrdenTerapia();
                // setHiddenbtnGrabar(true);
                // setHiddenbtnAnular(false);
                // setDisabledNumeroHistoriaClinica(true);
                // setTimeout(() => {
                //     inputObservacionesRef.current?.select();
                // }, 100);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const btn_gobackto = () => {
        navigate('/CajaEgresosView');
    }

    const pa_get_sys_config = async (p_Identificador: any) => {
        const param = {
            "VP_Identificador": p_Identificador,
            "VP_Estado": "A"
        }
        const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record[0].RecordSet);
        switch (p_Identificador) {
            case 'TipoDocGasto':
                setDataIdTipoDocumento(record[0].RecordSet);
                break;
            case 'CentroCostos':
                setDataIdCentroCosto(record[0].RecordSet);
                break;
            default:
                break;
        }
    }
    const pa_get_tipo_servicio = async () => {
        const param = {
            "vp_Tipo": "P",
            "vp_estado": ""
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_servicio', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setDataProducts(record);
    }

    const pa_get_registro_egreso = async () => {
        const param = {
            "VP_ExcluirFecha": 0,
            "VP_Fecha1": setIntlDateTimeFormat(new Date()),
            "VP_Fecha2": setIntlDateTimeFormat(new Date()),
            "VP_IdTipoDocumento": 0,
            "VP_NumeroDocumento": '',
            "VP_IdSucursal": 0,
            "VP_IdCliente": 0,
            "VP_IdCentroCosto": 0,
            "VP_IdRegistroEgreso": params.id
        }
        const response = await fetchSinToken('caja/pa_get_registro_egreso', param, 'POST');
        const resp = await response.json();
        console.log(resp.data);
        if (resp.data.length > 0) {
            setIdRegistroEgreso(resp.data[0].IdRegistroEgreso);
            setIdTipoDocumento(resp.data[0].IdTipoDocumento);
            setNumeroDocumento(resp.data[0].NumeroDocumento);
            setFechaEmision(new Date(Date.parse(resp.data[0].FechaEmision)));
            setHoraEmision(resp.data[0].HoraEmision);
            setFecha(new Date(Date.parse(resp.data[0].Fecha)));
            setIdCentroCosto(resp.data[0].IdCentroCosto);
            setIdTipoPersona(resp.data[0].TipoPersona ? resp.data[0].TipoPersona : 'X');
            setVisibleConten(resp.data[0].TipoPersona ? resp.data[0].TipoPersona : 'X');
            setIdCliente(resp.data[0].IdCliente);
            setCliente(resp.data[0].Cliente);    
            if (resp.data[0].TipoPersona == 'N') {
                setDataPersonas(resp.data[0]);
            }
            if (resp.data[0].TipoPersona == 'J') {
                setDataEmpresas(resp.data[0]);
            }            
            setImporte(resp.data[0].Importe);
            setPorcentageImpuesto(resp.data[0].PorcentageImpuesto);
            setImpuesto(resp.data[0].Impuesto);
            setDescuento(resp.data[0].Descuento);
            setRetenciones(resp.data[0].Retenciones);
            setOtros(resp.data[0].Otros);
            setTotal(resp.data[0].Total);
            setEstado(resp.data[0].Estado);
            pa_get_registro_egreso_detalle(resp.data[0].IdRegistroEgreso);
        }

    }
    const pa_get_registro_egreso_detalle = async (in_IdRegistroEgreso: any) => {
        const param = {
            "VP_IdRegistroEgreso": in_IdRegistroEgreso
        }
        const response = await fetchSinToken('caja/pa_get_registro_egreso_detalle', param, 'POST');
        const resp = await response.json();
        // console.log(resp.data);
        setTargetProducts(resp.data);
    }


    // pa_set_registro_egreso
    const [isOpenModal1, openModal1, closeModal1] = useModal(); //buscador
    const isMounted = useRef(false);
    const handleClickBuscar = () => {
        isMounted.current = true;
        openModal1();
    }
    const setIsMounted2 = (val: boolean) => {
        isMounted.current = val;
    }
    const BuscadorClientesForm = () => {
        return (
            <BuscadorClientes
                isOpen={isOpenModal1}
                isHide={closeModal1}
                getDataCliente={getDataCliente}
                setIsMounted={setIsMounted2}
            />
        );
    }

    const getDataCliente = (data: any) => {
        // console.log(data);
        switch (data.TipoCliente) {
            case 'E': //EMPRESA 
                setDataEmpresas(data);
                break;
            default:
                setDataPersonas(data);
                break;
        }
    }

    const setDataEmpresas = (data: any) => {
        setIdCliente(data.IdCliente);
        //Empresa
        setNombreRazonSocial(data.NombreRazonSocial ? data.NombreRazonSocial : '');
        setNumeroRuc(data.NumeroRuc ? data.NumeroRuc : '');
        let dir0: any = '';
        if (data.Direccion.length > 0) {
            dir0 = data?.Direccion[0]?.Ubigeo + ' ' + data?.Direccion[0]?.TipoVia + ' ' + data?.Direccion[0]?.NombreVia + ' ' + data?.Direccion[0]?.Urbanizacion;
        }
        setDireccion(dir0);
    }

    const setDataPersonas = (data: any) => {
        //Natural
        console.log(data);
        setNombres(data.Nombres ? data.Nombres : '');
        setApPaterno(data.ApPaterno ? data.ApPaterno : '');
        setApMaterno(data.ApMaterno ? data.ApMaterno : '');
        setNumeroDNI(data.NumeroDocumento ? data.NumeroDocumento : '');
        setNumeroTelf('');
        if (data.InformacionContacto){
            setNumeroTelf(data?.InformacionContacto[0]?.NumeroTelefono ? data?.InformacionContacto[0]?.NumeroTelefono : '');
        }   
        let dir0: any = '';
        if (data.Direccion) {
            if (data?.Direccion.length > 0) {
                dir0 = data?.Direccion[0]?.Ubigeo + ' ' + data?.Direccion[0]?.TipoVia + ' ' + data?.Direccion[0]?.NombreVia + ' ' + data?.Direccion[0]?.Urbanizacion;
            }
        }

        setDireccion(dir0);
    }


    const cantidadEditor = (options: any) => {
        return <InputNumber type="text" inputStyle={{ width: '4rem', textAlign: 'center' }}
            value={options.value} min={1} minFractionDigits={0} maxFractionDigits={0} locale="en-US"
            onValueChange={(e) => options.editorCallback(e.value)}
        />
    }
    const importeEditor = (options: any) => {
        return <InputNumber type="text" inputStyle={{ width: '6rem', textAlign: 'right' }}
            mode="decimal" minFractionDigits={2} maxFractionDigits={2} locale="en-US"
            value={options.value}
            onValueChange={(e: any) => {
                // console.log(typeof e.value, e.value);
                options.editorCallback(e.value);
            }}
        />
    }

    const conceptoTextEditor = (options: any) => {
        return <InputText type="text" maxLength={200} autoFocus style={{ width: '100%' }} value={options.value}
            onChange={(e) => options.editorCallback(e.target.value.toUpperCase())}
        />;
    }

    const onRowEditComplete = (e: any) => {
        // console.log(e);
        let editTarget = [...targetProducts];
        let { newData, index } = e;
        newData.Total = parseFloat(newData.Cantidad) * parseFloat(newData.Importe) - (parseFloat(newData.Descuento));
        editTarget[index] = newData;
        setTargetProducts([...editTarget]);
    }
    const createId = () => {
        let index = 0;
        targetProducts.forEach(function (row: any) {
            index = parseFloat(row.IdRegistroEgresoDetalle);
        });
        // Los index NEGTAIVOS son registros nuevos
        if (index > 0) index = index + 1;
        else index = index - 1;
        if (index > 0) index = index * -1;
        return index;
    }

    const setAgregarConcepto = () => {
        // console.log(Detalle);
        let targetModelEmpty = {
            IdRegistroEgresoDetalle: createId(),
            IdRegistroEgreso: VP_IdRegistroEgreso,
            CodigoProducto: CodigoProducto,
            Detalle: Detalle,
            Cantidad: 1,
            Importe: ImporteDetalle,
            Descuento: 0,
            Total: TotalDetalle,
            Crud: 'N' // NEW RECORD (NEW, MODIFY, DELETE)
        };
        targetProducts.push(targetModelEmpty);
        setTargetProducts([...targetProducts]);
    }
    const setRemoverConcepto = () => {
        // console.log(selectedIemsGrid);
        if (selectedIemsGrid.IdRegistroEgresoDetalle) {
            //Item Delete
            const vl_IdRegistroEgresoDetalle: any = selectedIemsGrid.IdRegistroEgresoDetalle;
            const itemsNew: any = [];
            targetProducts.forEach(
                function (row: any, index: any) {
                    // console.log(row);     
                    itemsNew.push(row);
                    if (row.IdRegistroEgresoDetalle === vl_IdRegistroEgresoDetalle) {
                        row.Crud = 'D';
                        itemsNew[index] = row;
                    }
                }
            );
            // console.log(itemsNew);
            setTargetProducts(itemsNew);
        } else {
            showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Seleccionar un registro', life: 8000 });
        }

    }

    const [saveConfirmDialog, setSaveConfirmDialog] = useState<boolean>(false);
    const setVisibleConfirmDialog = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            showDialogMessage.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 8000 });
            return;
        }
        setSaveConfirmDialog(true);
    }
    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';
        if (targetProducts.length > 0) {
            targetProducts.forEach(
                function (row: any) {
                    if (row.Crud !== 'D') {
                        if (String(row.Detalle).trim() === '') {
                            vl_valida = false;
                            vl_message = 'Ingresar detalle';
                        } else if (Number(row.Importe) === 0) {
                            vl_valida = false;
                            vl_message = 'Ingresar Importe';
                        } else if (Number(row.Cantidad) === 0) {
                            vl_valida = false;
                            vl_message = 'Ingresar Cantidad';
                        }
                    }
                }
            );
        }
        if (targetProducts.length === 0) {
            vl_valida = false;
            vl_message = 'No hay registros para guardar';
        }
        return [vl_valida, vl_message];
    }
    const pa_set_registro_egreso = async () => {
        // console.log('setGrabarDetalleConceptos');
        setSaveConfirmDialog(false);

        const param = {
            VP_action: VP_action == 'New' ? 'I' : 'U',
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_IdRegistroEgreso: VP_IdRegistroEgreso,
            VP_IdTipoDocumento: VP_IdTipoDocumento,
            VP_NumeroDocumento: VP_NumeroDocumento,
            VP_Fecha: setIntlDateTimeFormat(VP_Fecha),
            VP_IdCentroCosto: VP_IdCentroCosto,
            VP_IdCliente: VP_IdCliente ? VP_IdCliente : 0,
            VP_ClienteInfo: JSON.stringify([]),
            VP_Importe: VP_Importe,
            VP_PorcentageImpuesto: VP_PorcentageImpuesto,
            VP_Impuesto: VP_Impuesto,
            VP_Descuento: VP_Descuento,
            VP_Retenciones: VP_Retenciones,
            VP_Otros: VP_Otros,
            VP_Total: VP_Total,
            VP_Estado: VP_Estado,
            VP_detalle: JSON.stringify(targetProducts),
            VP_IdTipoPersona: VP_IdTipoPersona,
            VP_NombreRazonSocial: VP_NombreRazonSocial,
            VP_NumeroRuc: VP_NumeroRuc,
            VP_Nombres: VP_Nombres,
            VP_ApPaterno: VP_ApPaterno,
            VP_ApMaterno: VP_ApMaterno,
            VP_NumeroDNI: VP_NumeroDNI,
            VP_NumeroTelf: VP_NumeroTelf,
            VP_Direccion: VP_Direccion,
            VP_result: 1          //  0 =no mostrar result 1=SI MOSTRAR
        };

        // console.log(param);
        // return;
        const response = await fetchSinToken('caja/pa_set_registro_egreso', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log(record);
        if (record) {
            const message = record.sql_message;
            showDialogMessage.current.show({ severity: record.sql_error === 1 ? 'error' : 'success', summary: '::PSISABE', detail: message, life: 8000 });
            if (record.sql_error === 0) {
                // props.isCallfn();
                // props.setIsMounted(false);
                // props.isHide();
            }
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Err: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            // console.log(message);
            showDialogMessage.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
        }
    }

    const footer = () => {
        let vl_Total: any = 0;
        let vl_Descuento: any = 0;
        let vl_Importe: any = 0;
        let vl_IGV: any = 0;

        targetProducts.forEach(
            function (currentValue: any) {
                // console.log(currentValue);
                vl_Importe = vl_Importe + parseFloat(currentValue.Importe) * parseFloat(currentValue.Cantidad);
                vl_Descuento = vl_Descuento + parseFloat(currentValue.Descuento);
                vl_Total = vl_Total + parseFloat(currentValue.Total);
            }
        );

        //SOLO SI ES FACTURA
        let VL_PIGV = 0.0;
        if (VP_IdTipoDocumento === '1') {
            VL_PIGV = 0.18;
            vl_IGV = (vl_Total * VL_PIGV);
        }

        setImporte(vl_Importe);
        setPorcentageImpuesto(VL_PIGV);
        setImpuesto(vl_IGV);
        setDescuento(vl_Descuento);
        setRetenciones(0.0);
        setOtros(0.0);
        setTotal(vl_Total);

        return (
            <>
                <div className='flex flex-wrap justify-content-end' >
                    <FooterGridNumber Title={'Sub.Total'} Total={vl_Importe} />
                    <FooterGridNumber Title={'Descto.'} Total={vl_Descuento} />
                    <FooterGridNumber Title={'IGV.'} Total={vl_IGV.toFixed(2)} />
                    <FooterGridNumber Title={'Total'} Total={vl_Total} />
                </div>
            </>
        );
    }

    const setVisibleConten = (tipo: any) => {
        setContPN(true);
        setContPJ(true);
        setContP(true);
        if (tipo === 'N') {
            setContPN(false);
            setContPJ(true);
            setContP(false);
        }
        if (tipo === 'J') {
            setContPN(true);
            setContPJ(false);
            setContP(false);
        }
    }


    return (
        <>
            <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
            <Toast ref={showDialogMessage} position="center"></Toast>
            <ConfirmDialog visible={saveConfirmDialog} onHide={() => setSaveConfirmDialog(false)}
                message="¿Seguro de guardar registro?"
                header="Confirmar."
                icon="pi pi-exclamation-triangle"
                accept={pa_set_registro_egreso}
                reject={() => console.log('cancelado...')}
            />

            <div className="flex justify-content-between p-2 border-gray-500 pt-1 border-bottom-3 border-round-bottom ">
                <div className="bg-indigo-500 p2 border-bottom-2 text-white ">
                    <div className="col mt-0 ml-0 text-base font-bold font-italic h-2rem " style={{ textAlign: 'start', minWidth: '15rem' }} >
                        <label className='pb-1'> Registro de gastos </label>
                    </div>
                </div>
                <div>
                    <span className="p-buttonset">
                        <Button
                            label="Grabar"
                            icon="pi pi-save"
                            onClick={() => setVisibleConfirmDialog()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                        <Button
                            label="Nuevo"
                            icon="pi pi-file"
                            // onClick={() => setVisibleConfirmDialogAnula()}
                            className="p-button-sm p-button-text p-button-raised"
                        />
                        <Button
                            icon="pi pi-times"
                            // onClick={() => link_form_close()}
                            title="Cerrar formulario"
                            className="p-button-sm p-button-outlined p-button-danger ml-1 "
                        // className="p-button-rounded p-button-danger p-button ml-1" aria-label="Cancel"
                        />
                    </span>

                </div>
            </div>
            <div className='flex justify-content-between pb-2  ' >
                <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-2 pl-2 ">
                    <span className="p-buttonset">
                        <Button icon="pi pi-arrow-left" label='Volver'
                            className="p-button-rounded p-button-warning p-button-raised"
                            onClick={() => btn_gobackto()}
                        />
                    </span>
                </div>
            </div>

            <ScrollPanel style={{ height: '600px' }}>
                <div className="flex justify-content-center gray-container p-1  ">
                    <div className="card p-2">
                        <div className="p-col pt-2 ">
                            <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '130px', textAlign: 'end' }}>#ID:</label>
                            <InputText type="text" style={{ width: '80px', textAlign: 'center' }} className='font-bold text-lg bg-gray-100'
                                value={VP_IdRegistroEgreso} readOnly
                            />
                            <label className="p-col-fixed mr-1 ml-1 font-bold text-sm " style={{ textAlign: 'end' }}>Fecha:</label>
                            <Calendar dateFormat="yy/mm/dd" style={{ width: '8rem' }} inputClassName='font-bold bg-gray-100'
                                inputStyle={{ width: '8rem', textAlign: 'center' }} minDate={new Date()} maxDate={new Date()}
                                value={VP_FechaEmision}
                                onChange={(e: any) => setFechaEmision(e.value)}
                            />
                            <label className="p-col-fixed m-1 font-bold text-sm " style={{ textAlign: 'end' }}>Hora:</label>
                            <InputText type="text" className='font-bold bg-gray-100'
                                value={moment(new Date(), 'HH:mm').tz('America/Lima').format('HH:mm')}
                                style={{ width: '5rem', textAlign: 'center' }} readOnly />
                        </div>
                        <div className="p-col pt-0 ">
                            <div className="p-col pt-1 ">
                                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '130px', textAlign: 'end' }}>Tipo documento:</label>
                                <Dropdown
                                    style={{ width: '200px' }}
                                    value={String(VP_IdTipoDocumento)}
                                    options={dataIdTipoDocumento}
                                    onChange={(e: any) => {
                                        setIdTipoDocumento(e.value);
                                        //   inputNumeroDocumentoRef.current.focus();
                                    }}
                                    optionValue="Codigo"
                                    optionLabel="Descripcion"
                                    placeholder="Seleccionar" />

                                <label className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>Nº:</label>
                                <InputText type="text"
                                    style={{ width: '130px' }}
                                    className='font-normal'
                                    value={VP_NumeroDocumento}
                                    onChange={(e) => {
                                        setNumeroDocumento(String(e.currentTarget.value).toUpperCase());
                                    }}
                                    // ref={inputNumeroDocumentoRef}
                                    onKeyPress={(e: any) => {
                                        if (e.key === 'Enter') {
                                            // inputFechaNacimentoRef.current.focus();
                                        }
                                    }}
                                />
                                <label className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>Fecha:</label>
                                <Calendar dateFormat="yy/mm/dd" inputStyle={{ width: '8rem', textAlign: 'center' }}
                                    value={VP_Fecha} maxDate={new Date()}  // ref={inputFechaNacimentoRef}                                
                                    onChange={(e: any) => setFecha(e.value)}
                                />
                            </div>

                            <div className="p-col pt-1 ">
                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '130px', textAlign: 'end' }}>Centro de costo:</label>
                                <Dropdown
                                    style={{ width: '50%' }}
                                    value={String(VP_IdCentroCosto)}
                                    options={dataIdCentroCosto}
                                    onChange={(e: any) => {
                                        setIdCentroCosto(e.value);
                                        //   inputNumeroDocumentoRef.current.focus();
                                    }}
                                    optionValue="Codigo"
                                    optionLabel="Descripcion"
                                    placeholder="Seleccionar" />
                            </div>
                            <div className="p-col pt-1 ">
                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '130px', textAlign: 'end' }}>Tipo Persona:</label>
                                <Dropdown
                                    style={{ width: '15rem' }}
                                    value={VP_IdTipoPersona}
                                    options={[{ "Codigo": "N", "Descripcion": "PERSONA NATURAL" }, { "Codigo": "J", "Descripcion": "PERSONA JURIDICA" }, { "Codigo": "X", "Descripcion": "N/A" }]}
                                    onChange={(e: any) => {
                                        setIdTipoPersona(e.value);
                                        setVisibleConten(e.value);
                                    }}
                                    optionValue="Codigo"
                                    optionLabel="Descripcion"
                                    placeholder="Seleccionar" />

                                <Button title='Buscar clientes o empresas' disabled={b_contP ? true : false}
                                    onClick={() => handleClickBuscar()}
                                    icon="pi pi-user-plus" className="p-button-rounded mt-0 p-button-warning ml-2 "
                                />
                            </div>
                            <div className={b_contPN ? 'hidden' : ''} >
                                <div className="flex flex-wrap card-container w-full ">
                                    <div className="flex align-items-center justify-content-center pt-1" >
                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '130px', textAlign: 'end' }}>Nombre:</label>
                                        <InputText type="text" style={{ width: '200px' }}
                                            value={VP_Nombres}
                                            onChange={(e: any) => {
                                                setNombres(e.currentTarget.value);
                                            }}
                                        />
                                        <label className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>Apellidos:</label>
                                        <InputText type="text" style={{ width: '150px' }} placeholder='Paterno'
                                            value={VP_ApPaterno}
                                            onChange={(e: any) => {
                                                setApPaterno(e.currentTarget.value);
                                            }}
                                        />
                                        <label className="p-col-fixed mr-0 font-bold text-sm " style={{ width: '10px', textAlign: 'center' }}></label>
                                        <InputText type="text" style={{ width: '150px' }} placeholder='Materno'
                                            value={VP_ApMaterno}
                                            onChange={(e: any) => {
                                                setApMaterno(e.currentTarget.value);
                                            }}
                                        />
                                    </div>
                                    <div className="flex align-items-center justify-content-center pt-1" >
                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '130px', textAlign: 'end' }}>DNI:</label>
                                        <InputText type="text" style={{ width: '8rem' }}
                                            value={VP_NumeroDNI}
                                            onChange={(e: any) => {
                                                setNumeroDNI(e.currentTarget.value);
                                            }}
                                        />
                                        <label className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>Telf.:</label>
                                        <InputText type="text" style={{ width: '8rem' }}
                                            value={VP_NumeroTelf}
                                            onChange={(e: any) => {
                                                setNumeroTelf(e.currentTarget.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={b_contPJ ? 'hidden' : ''} >
                                <div className="flex flex-wrap card-container w-full ">
                                    <div className="flex align-items-center justify-content-center pt-1" >
                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '130px', textAlign: 'end' }}>Razón social:</label>
                                        <InputText type="text" style={{ width: '22rem' }}
                                            value={VP_NombreRazonSocial}
                                            onChange={(e: any) => {
                                                setNombreRazonSocial(e.currentTarget.value);
                                            }}
                                        />
                                        <label className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>RUC:</label>
                                        <InputText type="text" style={{ width: '10rem' }} maxLength={11}
                                            value={VP_NumeroRuc}
                                            onChange={(e: any) => {
                                                setNumeroRuc(e.currentTarget.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={b_contP ? 'hidden' : ''} >
                                <div className="flex flex-wrap card-container w-full ">
                                    <div className="flex align-items-center justify-content-center pt-1" >
                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '130px', textAlign: 'end' }}>Dirección:</label>
                                        <InputText type="text" style={{ width: '30rem' }}
                                            value={VP_Direccion}
                                            onChange={(e: any) => {
                                                setDireccion(e.currentTarget.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col bg-gray-700 font-bold text-white mt-3 ">Detalle de gasto</div>
                            <div className="p-col mt-1">
                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm" style={{ textAlign: 'end' }}>Productos o Servicios:</label>
                                <Dropdown style={{ width: '370px' }}
                                    filter filterBy="Descripcion" showClear
                                    value={CodigoProducto}
                                    options={dataProducts}
                                    onChange={(e: any) => {
                                        setCodigoProducto(e.value);
                                        let itemsfiltered: any = dataProducts.filter(function (element: any) {
                                            return element.CodigoServicio === e.value;
                                        });
                                        // console.log(itemsfiltered);
                                        if (itemsfiltered.length > 0) {
                                            setDetalle(itemsfiltered[0]?.Descripcion);
                                            setImporteDetalle(parseFloat(itemsfiltered[0]?.Costo));
                                            setTotalDetalle(parseFloat(itemsfiltered[0]?.Costo) * 1);
                                            // setAgregarConcepto();
                                        } else {
                                            setCodigoProducto('');
                                            setDetalle('');
                                            setImporteDetalle(0);
                                            setTotalDetalle(0);
                                        }
                                    }}
                                    optionValue="CodigoServicio"
                                    optionLabel="Descripcion"
                                    placeholder="Seleccionar" />

                                <Button
                                    type="button"
                                    icon="pi pi-plus"
                                    title="Agregar item"
                                    onClick={(e) => setAgregarConcepto()}
                                    className="p-button-small p-button-rounded p-button-warning ml-2 mr-1 "
                                />
                                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined mt-1" title='Quitar detalle'
                                    onClick={() => { setRemoverConcepto() }}
                                />
                            </div>
                            <div className="p-col pt-1 ">
                                <DataTable size="small"
                                    resizableColumns
                                    footer={footer}
                                    value={targetProducts ?? []}
                                    dataKey="CodigoServicio"
                                    responsiveLayout="scroll"
                                    columnResizeMode="fit"
                                    showGridlines
                                    stripedRows
                                    scrollable
                                    scrollHeight="50vh"
                                    selectionMode="single"
                                    editMode="row"
                                    onRowEditComplete={onRowEditComplete}
                                    selection={selectedIemsGrid}
                                    onSelectionChange={(e: any) => {
                                        setSelectedIemsGrid(e.value);
                                    }}
                                >
                                    <Column rowEditor bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="Detalle" header="Detalle de gasto" style={{ minWidth: '300px' }}
                                        headerClassName="flex justify-content-center"
                                        bodyClassName="white-space-normal"
                                        editor={(options) => conceptoTextEditor(options)}
                                        body={(item: any) => {
                                            return (<label className={`${item.Crud === 'D' ? 'line-through text-red-500' : ''}`}> {item.Detalle}</label>);
                                        }}
                                    ></Column>
                                    <Column field="Importe" header="Importe" style={{ minWidth: '8rem' }}
                                        headerClassName="flex justify-content-start white-space-normal "
                                        bodyClassName="flex justify-content-end text-cyan-800 font-semibold"
                                        editor={(options) => {
                                            // console.log(options);
                                            return importeEditor(options)
                                        }}
                                    >
                                    </Column>
                                    <Column field="Cantidad" header="Cant." style={{ minWidth: '5rem' }}
                                        headerClassName="flex justify-content-center"
                                        bodyClassName="flex justify-content-center text-cyan-800 font-semibold"
                                        editor={(options) => cantidadEditor(options)}
                                    >
                                    </Column>
                                    <Column field="Descuento" header="Desctos." style={{ minWidth: '8rem' }}
                                        headerClassName="flex justify-content-start white-space-normal "
                                        bodyClassName="flex justify-content-end text-cyan-800 font-semibold"
                                        editor={(options) => {
                                            // console.log(options);
                                            return importeEditor(options)
                                        }}
                                    >
                                    </Column>
                                    <Column field="Total" header="Total" style={{ minWidth: '6rem' }}
                                        headerClassName="flex justify-content-center"
                                        bodyClassName="flex justify-content-end text-green-600 font-semibold"
                                        body={(item: any) => {
                                            return (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.Total));
                                        }}
                                    >
                                    </Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </ScrollPanel>

        </>
    )
}

export default EgresoForm;
import { Props } from '../../../types/GlobalType';

const ContentLayout = (props: Props) => {
  return (
    <>
      <div className="body flex-grow-1 px-0 mt-0 ">
        <div className="container-fluid">
          {props.children}
        </div>
      </div>
    </>
  )
}

export default ContentLayout

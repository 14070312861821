import React, { useContext, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import avatar from "../../../assets/img/avatars/avatar.jpg";
import { fetchConToken } from '../../../helpers/fetch';
import { Messages } from 'primereact/messages';
import { AppAuthContext } from '../../../context/AppAuthContext';

const AuthUsuarioChangePassword = () => {
    const params = useParams();
    // console.log(params.id);
    const authUserFullName = localStorage.getItem('authUserFullName');
    const authUserCargo = localStorage.getItem('authUserCargo');

    const sha1 = require('js-sha1');
    const messageRef = useRef<any>(null);
    const { logout } = useContext(AppAuthContext);
    const navigate = useNavigate();

    const [vl_password, setPassword] = useState<any>('');
    const [vl_PasswordNew, setPasswordNew] = useState<any>('');
    const [vl_PasswordNewConfirm, setPasswordNewConfirm] = useState<any>('');

    const header = <h6>Elige una contraseña</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Sugerencias</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>Al menos una minúscula</li>
                <li>Al menos una mayúscula</li>
                <li>Al menos una numérica</li>
                <li>Un mínimo de 8 caracteres</li>
            </ul>
        </React.Fragment>
    );

    const handleEnviar = async (e: any) => {
        e.preventDefault();
        const param = {
            vp_Action: 'U',
            vp_IdUsuario: params.id,
            vp_IdSucursal: localStorage.getItem('authIdSucursal'),
            vp_Password: sha1(vl_password),
            vp_PasswordNew: sha1(vl_PasswordNew),
            vp_PasswordNewConfirm: sha1(vl_PasswordNewConfirm)
        }
        if (String(sha1(vl_password)).trim().length === 0) {
            const message = 'Ingresa la contraseña actual';
            messageRef.current?.show({
                closable: true, severity: 'error',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });
            return;
        }
        if (String(sha1(vl_PasswordNew)).trim().length === 0) {
            const message = 'Ingresa la nueva contraseña';
            messageRef.current?.show({
                closable: true, severity: 'error',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });
            return;
        }
        if (String(sha1(vl_PasswordNewConfirm)).trim().length === 0) {
            const message = 'Confirma la nueva contraseña';
            messageRef.current?.show({
                closable: true, severity: 'error',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });
            return;
        }

        const response = await fetchConToken('auth/pa_set_password', param, 'POST');
        const datajson = await response.json();
        // console.log(datajson);        
        if (datajson.data.sqlResult[0].sql_error === 0) {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('auhtUser');
            window.localStorage.removeItem('authIdUsuario');
            logout(false);
            navigate('/login');
            // const lastPath = '/AccountHome';
            // navigate(lastPath, {
            //     replace: true
            // });
        } else {
            const message = datajson.data.sqlResult[0].sql_message;
            messageRef.current?.show({
                closable: true, severity: 'error',
                detail: <div style={{ display: 'inline-block' }}
                    dangerouslySetInnerHTML={{ __html: message }} />, life: 10000
            });
        }
    }

    return (
        <>
            <div className="flex justify-content-center flex-column min-vh-70 ">
                <div className='flex flex-wrap justify-content-center pb-3 m-2 border-round-bottom border-bottom-3 border-gray-600 ' >
                    <div className="flex justify-content-between ">                        
                        <div className="w-full">
                            <img src={avatar} alt="" className="img-fluid rounded-circle mb-2 w-2" />
                            <div className="font-italic font-bold font-normal text-sm ">
                                <label> {authUserFullName}</label>
                            </div>
                            <small className="font-italic font-normal text-sm" > {authUserCargo} </small>
                        </div>
                        <div>
                            <Messages ref={messageRef} />
                        </div>
                    </div>
                </div>

                <main className="form-passwor">
                    <form onSubmit={handleEnviar} >
                        <div className='flex flex-column justify-content-center p-1 m-2 '>
                            <div className="field">
                                <label className='font-base text-xl text-pink-500 flex justify-content-center' >Cambiar contraseña.</label>
                            </div>
                            <div className="field">
                                <label htmlFor="username">Contraseña actual</label>
                                <Password value={vl_password} onChange={(e) => setPassword(e.target.value)}
                                    className="text-base text-color surface-overlay p-2 border-0 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" aria-describedby="username-help"
                                >
                                </Password>
                            </div>
                            <div className="field text-sm " >
                                <label>Seguridad de la contraseña:</label>
                                <label>
                                    Utiliza al menos 8 caracteres. No uses una contraseña demasiado obvio.
                                </label>
                            </div>
                            <div className="field">
                                <label htmlFor="username">Contraseña nueva</label>
                                <Password value={vl_PasswordNew} onChange={(e) => setPasswordNew(e.target.value)} header={header} toggleMask footer={footer}
                                    className="text-base text-color surface-overlay p-2 border-0 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" aria-describedby="username-help"
                                >
                                </Password>
                            </div>
                            <div className="field">
                                <label htmlFor="username">Confirma la nueva contraseña</label>
                                <Password value={vl_PasswordNewConfirm} onChange={(e) => setPasswordNewConfirm(e.target.value)} header={header} toggleMask footer={footer}
                                    className="text-base text-color surface-overlay p-2 border-0 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" aria-describedby="username-help">
                                </Password>
                            </div>
                            <div className="field">
                                <button className="w-full btn btn-lg btn-primary" type="submit">Cambiar la contraseña</button>
                            </div>

                        </div>
                    </form>
                </main>

            </div>
        </>
    )
}

export default AuthUsuarioChangePassword;
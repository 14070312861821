import { useContext, useEffect } from "react";
import { AppContext01 } from "../../../context/AppContext";
import { Player } from '@lottiefiles/react-lottie-player';

const WelcomePage = () => {
    const { showmenuTitle } = useContext(AppContext01);
    useEffect(() => {
        handleSetTitle();
    }, []);

    const handleSetTitle = () => {
        showmenuTitle('/');
    }

    return (
        <>
            <div className="flex-grow-1 flex align-items-center justify-content-center ">
                <div className="flex flex-wrap justify-content-center align-items-center ">
                    <div className="flex justify-content-center">
                        <img className="" src="../apple-touch-icon.png" alt="error 404"></img>
                    </div>
                    <div className="col" >
                        <Player autoplay={true} loop={true} controls={true} speed={1} style={{ height: '300px', width: '400px' }}
                            src="../lottie-player/94495-work-on-home.json"
                        ></Player>
                        <div className="flex align-items-center justify-content-center w-100 h-1rem text-base border-round m-2">
                            <span className='text-sm' > Centro Psicológico PSISABE&copy; { new Date().getFullYear() }  </span>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="pt-1" >
                <div className="flex justify-content-center flex-wrap card-container">
                    <div className="flex align-items-center justify-content-center w-100 text-base border-round m-2">
                         <img className="" src="../640x640-01.png" alt="error 404"></img> 
                    </div>
                    <div>
                        <Player
                            autoplay={true}
                            loop={true}
                            controls={true}
                            src="../lottie-player/94495-work-on-home.json"
                            style={{ height: '500px', width: '500px' }}
                            speed={1}
                        ></Player>
                    </div>
                    <div className="flex align-items-center justify-content-center w-100 h-1rem text-base border-round m-2">
                        <span className='text-sm' > Centro Psicológico Psisabe&copy; {2022}  </span>
                    </div>
                </div>
            </div> */}

        </>
    )
};

export default WelcomePage;
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import esLocale from '@fullcalendar/core/locales/es';

import { Button } from 'primereact/button';
import { fetchSinToken } from '../../../helpers/fetch';
import { Dropdown } from 'primereact/dropdown';
// import { Divider } from 'primereact/divider';
import useModal from '../../../context/WindowsOpen';
import AgendaForm from '../../components/agenda/AgendaForm';
import ProgressBarUtil from '../../components/util/ProgressBarUtil';
import { AppContext01 } from '../../../context/AppContext';

const AgendaView = () => {
  // console.log('AgendaView');
  const isMounted = useRef(false);
  const [isMode, setMode] = useState('I');
  const [isOpenModal, openAdmisionModal, closeAdmisionModal] = useModal();
  const [selectedDateCalendar, setSelectedDateCalendar] = useState<any>([]);
  const [selectedEventCalendar, setSelectedEventCalendar] = useState<any>([]); //por revisar
  const [eventsTargetCalendar, setEventsTargetCalendar] = useState<any>([]);

  let businessHoursDefualt: any = [];
  businessHoursDefualt.push({
    'daysOfWeek': [1, 2, 3, 4, 5, 6], //de lunes a sabado
    'startTime': '07:00', // 7am a 10pm
    'endTime': '22:00'
  });

  const [configSchedule, setConfigSchedule] = useState<any>(businessHoursDefualt);

  //filter calendar view
  const [vl_FechaYear, setFechaYear] = useState<any>(new Date().getFullYear());
  const [vl_FechaMonth, setFechaMonth] = useState<any>(new Date().getMonth() + 1);

  let authIdStaff: any = localStorage.getItem('authIdPerfil') === '3' ? localStorage.getItem('authIdStaff') : 0;

  const [vl_IdStaff, setIdStaff] = useState<any>(parseInt(authIdStaff));
  //dataSets
  const [dataIdStaff, setDataIdStaff] = useState<any>([]);
  const [b_ProgressBar, setProgressBar] = useState<boolean>(false);
  const { showmenuTitle } = useContext(AppContext01);


  // *************** ini
  const [dataPeriodo, setDataPeriodo] = useState<any>([]);
  // const [vl_periodo, setPeriodo] = useState<any>(new Date().getFullYear());
  const [dataPeriodoMeses, setDataPeriodoMeses] = useState<any>([]);
  // const [vl_periodoMes, setPeriodoMes] = useState<any>(new Date().getMonth());
  // const periodo: any = new Date().getFullYear();
  
  // ******************* fin

  useEffect(() => {
    showmenuTitle('/Agenda');
    // Pone año y mes corriente
    // El valor devuelto por getMonth() es un entero entre 0 y 11, donde 0 corresponde a Enero, 1 a Febrero y así sucesivamente.
    // console.log(new Date().getMonth() + 1);
    
    setLoadPeriodo(); //new
    setLoadPeriodoMes(); //new

    setFechaYear(new Date().getFullYear());
    setFechaMonth(new Date().getMonth() + 1);
    pa_get_staff();
    //si perfil: ADMIN o ASISTENTES CARGA TODA LA LISTA  
    let vl_authIdStaff: any = localStorage.getItem('authIdStaff');
    if (localStorage.getItem('authIdPerfil') === '1' || localStorage.getItem('authIdPerfil') === '2') {
      vl_authIdStaff = 0;
    }
    pa_get_agenda(vl_authIdStaff, '*');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const setLoadPeriodo = ()=> {
    //call init
    const periodo: any = new Date().getFullYear();
    // const mes: any = new Date().getMonth() + 1;    
    let dsPeriodo: any = [];
    let anio = 0;
    for (let i = (periodo - 3); i <= (periodo + 1); i++) {
      anio = i;
      dsPeriodo.push(
        { "lstPeriodo": anio }
      );
    }
    //console.log(dsPeriodo);
    setDataPeriodo(dsPeriodo);
    setFechaYear(periodo); 
  }  

  const setLoadPeriodoMes=()=>{
    //mes
    const mes: any = new Date().getMonth() + 1; // mes all
    let vl_mes = ['Todo', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let dsPeriodoMes: any = [];
    for (let i = 0; i <= 12; i++) {
      dsPeriodoMes.push(
        { "lstMes": vl_mes[i], "IdMes": i }
      );
    }
    setDataPeriodoMeses(dsPeriodoMes);
    setFechaMonth(mes);
  }

  //carga segun perfil
  const pa_get_staff = async () => {
    // console.log(vl_IdStaff);
    let p_IdStaff: any = localStorage.getItem('authIdStaff');

    //si perfil: ADMIN o ASISTENTES CARGA TODA LA LISTA    
    if (localStorage.getItem('authIdPerfil') === '1' || localStorage.getItem('authIdPerfil') === '2') {
      p_IdStaff = 0;
    }

    const param = {
      "vp_opcion": "A",
      "vp_IdStaff": p_IdStaff,
      "VP_Codigo": "",
      "VP_Nombres": "",
      "VP_Apellidos": ""
    }
    const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    // console.log(record);
    setDataIdStaff(record);
    // console.log(parseInt(record[0].ConfigSchedule.length));
    //APLICA CONFIGURACION DEL CALENDARIO PARA UN STAFF
    if (record.length === 1 && parseInt(record[0]?.ConfigSchedule.length) > 0) {
      setConfigSchedule(record[0].ConfigSchedule);
    }

  }

  const pa_get_agenda = async (vp_IdStaff: any, tipo: any) => {

    // localStorage.getItem('authIdPerfil')
    // -- 1 ROOT
    let vl_IdSucursal: any = localStorage.getItem('authIdSucursal');
    if (localStorage.getItem('authIdPerfil') === '1' && tipo === '*') {
      vp_IdStaff = 0;
      vl_IdSucursal = 0;
    }
    // perfil: PSICOLOGOS
    if (localStorage.getItem('authIdPerfil') === '3') {
      if (vp_IdStaff === undefined || vp_IdStaff === 0) {
        alert('Debe seleccionar un profesional');
        return;
      }
    }

    const param = {
      "vp_IdAgenda": "0",
      "vp_FechaYear": vl_FechaYear,
      "vp_FechaMonth": vl_FechaMonth,
      "vp_IdStaff": vp_IdStaff === undefined ? 0 : vp_IdStaff,
      VP_IdSucursal: vl_IdSucursal
    }
    setProgressBar(true);
    const response = await fetchSinToken('selectors/pa_get_agenda', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setProgressBar(false);
    // console.log(record);
    setEventsTargetCalendar(record);

  }

  const handleClickNew = (e: any) => {
    setMode('I');
    isMounted.current = true;
    setSelectedDateCalendar([]);
    openAdmisionModal();
  }

  //soluciona ERR:
  // index.js:1 Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak 
  // in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
  // Al cerrar la venta actual, poner estado: isMounted =false
  const handleSetisMounted = (val: boolean) => {
    // console.log('handleSetisMounted= '+ val );
    isMounted.current = val;
  }

  // const handleDateClickNew = (e: any) => {
  //   setMode('I');
  //   isMounted.current = true;
  //   openAdmisionModal();
  // }

  const handleEventClick = () => {
    setMode('V');
    isMounted.current = true;
    openAdmisionModal();
  }


  const onChangeIdStaff = (e: any) => {
    // console.log(e);
    setIdStaff(e.value);
    let itemsfiltered: any = dataIdStaff.filter(function (element: any) {
      return element.IdStaff === e.value;
    });
    // console.log(itemsfiltered[0].ConfigSchedule);
    if (parseInt(itemsfiltered[0]?.ConfigSchedule.length) > 0)
      setConfigSchedule(itemsfiltered[0].ConfigSchedule);
    //carga agenda
    pa_get_agenda(e.value, '');
  }

  const AgendaFormAdd = () => {
    return (
      <AgendaForm
        isOpen={isOpenModal}
        isHide={closeAdmisionModal}
        isMounted={handleSetisMounted}
        isData={selectedDateCalendar}
        isDataEvent={selectedEventCalendar}
        isMode={isMode}
        isCallfn={pa_get_agenda}
      />
    );
  }

  // const setSelectedDateCalendar_00 = async (info: any) => {
  //   setSelectedDateCalendar(info);
  //   return true;
  // }
  // const setSelectedDateCalendar_await = async (info: any) => {
  //   // setSelectedDateCalendar(info);
  //   const comp = await setSelectedDateCalendar_00(info);
  //   if (comp)
  //     handleEventClick();
  // }

  const setSelectedDateCalendar_await = async (info: any) => {
    setSelectedDateCalendar(info);
    handleEventClick();
  }


  return (
    <>
      <ProgressBarUtil
        ProgressBarVisible={b_ProgressBar}
        ProgressBarMessage='Please wait, loading...'
        setProgressBar={setProgressBar}
      />
      <React.Fragment>{isMounted.current && <AgendaFormAdd />}</React.Fragment>

      <div className='flex justify-content-between pb-2' >
        <div className='flex flex-wrap justify-content-start align-items-center ' >
          <Dropdown style={{ width: '350px' }} filter showClear filterBy="staff"
            value={vl_IdStaff}
            options={dataIdStaff}
            onChange={onChangeIdStaff}
            optionValue="IdStaff"
            optionLabel="staff"
            placeholder="Profesional" />
          <label className="p-col-fixed mr-2 " style={{ textAlign: 'end' }}></label>
          <Button
            type="button"
            icon="pi pi-search"
            tooltip="Mostrar la agenda de un profesional"
            onClick={(e) => pa_get_agenda(vl_IdStaff, '')}
            className="p-button-rounded"
          // className="p-button-sm p-button-primary p-button-raised"
          />
        </div>
        <div className="flex align-items-end justify-content-end ">
          <Dropdown style={{ width: '120px' }}
            value={vl_FechaYear}
            options={dataPeriodo}
            onChange={(e: any) => {
              setFechaYear(e.value);
              setFechaMonth(1);              
            }}
            optionValue="lstPeriodo"
            optionLabel="lstPeriodo"
            placeholder="" />

          <label className='ml-1'>  </label>
          <Dropdown style={{ width: '120px' }}
            value={vl_FechaMonth}
            options={dataPeriodoMeses}
            onChange={(e: any) => {
              setFechaMonth(e.value);                           
            }}
            optionValue="IdMes"
            optionLabel="lstMes"
            placeholder="" />

          <label className="pl-1" ></label>
          <Button type="button" icon="pi pi-refresh"
            onClick={() => pa_get_agenda(vl_IdStaff, '')}
            className="p-button-rounded p-button-primary p-button-outlined"
          />
        </div>
      </div>

      <div className='flex justify-content-between border-yellow-500 border-top-2 pt-2 ' >
        <div>
          <span className="p-buttonset">
            <Button
              type="button"              
              icon="pi pi-calendar-plus"
              label="Nueva cita"
              onClick={(e) => handleClickNew(e)}
              // className="p-button-warning"
              className="p-button-sm p-button-warning p-button-raised"
            />
          </span>
        </div>
        <div className='flex flex-wrap justify-content-end align-items-center font-bold '  >
          <label className="col text-xs bg-indigo-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Programada</label>
          {/* <label className="col text-xs bg-pink-600 text-white" style={{ width: '6rem', textAlign: 'center' }}>Cancelada</label> */}
          <label className="col text-xs bg-purple-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Confirmada</label>
          <label className="col text-xs bg-orange-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>Reprogramada</label>
          <label className="col text-xs bg-green-600 text-white" style={{ width: '6rem', textAlign: 'center' }}>Realizada</label>
          <label className="col text-xs bg-cyan-500 text-white" style={{ width: '6rem', textAlign: 'center' }}>No Realizada</label>
        </div>
      </div>

      <ScrollPanel className='pt-3' style={{ width: '100%', height: '700px' }}>
        <FullCalendar
          contentHeight={600}
          locale={esLocale}
          plugins={[timeGridPlugin, dayGridPlugin, listPlugin, interactionPlugin, momentPlugin]}
          // plugins: [ bootstrap5Plugin ],        
          initialView="listMonth" //dayGridWeek , timeGridWeek, listWeek, listMonth
          editable={true}
          selectable={true}
          navLinks={false}
          selectMirror={true}
          dayMaxEvents={true}
          allDaySlot={true}  //Determines if the “all-day” slot is displayed at the top of the calendar.
          slotMinTime = '07:00:00'
          slotDuration='00:40:00' //The frequency for displaying time slots. (30 minutes)
          nowIndicator={true}
          // initialDate={ new Date(Date.UTC(2022, 1, 21))  }                  
          //hiddenDays={[7]} //Exclude certain days-of-the-week from being displayed.
          // eventStartEditable={true}
          droppable={false} //para mover las fechas x ahora NO
          // titleFormat='{MMMM {D}}, YYYY'
          headerToolbar={{
            left: 'prev,next,today,list',
            // center: 'title addEventButton',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth' ////listDay, listWeek, listMonth, and listYear.
          }}
          // footerToolbar={{
          //   left: 'prev,next,today,list'
          // }}
          // timeZone='local'
          // timeZone= 'UTC' //evaluar si es necesario usar en el servridor ?
          // timeZone = 'America/Lima'

          defaultRangeSeparator=' - '
          titleRangeSeparator=' al '
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short',
            hour12: true
          }}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          }} //se visualizara de esta manera 01:00 AM en la columna de horas

          //en ES (no es necesario..)
          // buttonText={
          //   {
          //     today: 'Hoy',
          //     month: 'Mes',
          //     week: 'Semana',
          //     day: 'Dia',
          //     list: 'Lista'
          //   }
          // }
          // expandRows={true}                
          themeSystem='bootstrap5'
          // buttonIcons={{
          //   close: 'fa-times',
          //   prev: 'fa-chevron-left',
          //   next: 'fa-chevron-right',
          //   prevYear: 'fa-angle-double-left',
          //   nextYear: 'fa-angle-double-right'
          // }}        
          // eventClassNames='text-white'       
          // businessHours={true}

          // businessHours= {{
          //   // days of week. an array of zero-based day of week integers (0=Sunday)
          //   'daysOfWeek': [ 1, 2, 3, 4, 5, 6 ], // Lunes - Sabado
          //   'startTime': '07:00', // a start time (7am in this example)
          //   'endTime': '22:00' // an end time (10pm in this example)
          // }}
          businessHours={configSchedule}
          views={{
            day: {
              // options apply to dayGridDay and timeGridDay views
              titleFormat: { year: 'numeric', month: 'long', day: '2-digit' }
            },
            dayGridMonth: { // name of view
              titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
              // other view-specific options here
            },
            timeGridWeek: {
              titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
            }
          }}
          navLinkDayClick={
            function (date: any, jsEvent: any) {
              // console.log('day', date.toISOString());
              // console.log('coords', jsEvent.pageX, jsEvent.pageY);
            }
          }
          // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          eventClick={function (info: any) {
            //console.log(info);
            // alert('Event: ' + info.event.title);
            // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
            // alert('View: ' + info.view.type);
            // change the border color just for fun
            // info.el.style.borderColor = 'red';
            // setSelectedDateCalendar(info);
            setSelectedDateCalendar_await(info);
            // handleEventClick();
          }}
          eventMouseEnter={function (info: any) {
            // console.log(info);
            // setSelectedDateCalendar(info);          
          }}
          // select={function (info: any) {
          //   console.log('select');
          //   console.log(info);
          //   // setSelectedDateCalendar(info);          
          // }}

          // eventsSet={function (info: any) {
          //   console.log('eventsSet');
          //   console.log(info);  
          //   setSelectedDateCalendar(info);        
          // }}
          dateClick={
            (e: any) => {
              //para revisar no pinta la hora en form newagenda....
              // console.log(e);              
              // setSelectedEventCalendar(e);
              // handleClickNew(e);
            }
          }
          // dateClick = { (e)=>function(info:any) {
          //   console.log(info);          
          // }}
          // eventMouseEnter ={
          //   function( mouseEnterInfo ) { 
          //     // console.log(mouseEnterInfo)
          //   }
          // }
          //  you can update a remote database when these fire:
          // eventAdd={function () { }}
          // eventChange={function () { 
          //   console.log('eventChange');
          // }}
          // eventRemove={function () { }}

          events={eventsTargetCalendar}
        // events={[
        //   {
        //     title: 'TERAPIA 01 - PSI. EMA D.',
        //     start: '2022-01-20 15:40',
        //     end: '2022-01-20 17:40'
        //     // allDay : false,            
        //   },
        //   {
        //     title: 'SESION 4/5 PSI FFF',
        //     start: '2022-01-20 13:30',
        //     end: '2022-01-20 15:00'
        //   },
        //   {
        //     title: 'SESION 3/5 PSI FFF',
        //     start: '2022-01-21 10:30',
        //     allDay: false // will make the time show
        //   }
        // ]}
        // eventBackgroundColor='blue'
        // eventSources = {[
        //   // your event source
        //   {
        //     url: '/myfeed.php',
        //     method: 'POST',
        //     extraParams: {
        //       custom_param1: 'something',
        //       custom_param2: 'somethingelse'
        //     },
        //     failure: function() {
        //       alert('there was an error while fetching events!');
        //     },
        //     color: 'yellow',   // a non-ajax option
        //     textColor: 'black' // a non-ajax option
        //   }      
        //   // any other sources...      
        // ]}
        />
      </ScrollPanel>


    </>
  )

}

export default AgendaView;



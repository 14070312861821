import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useModal from '../../../context/WindowsOpen';
import { fetchSinToken } from '../../../helpers/fetch';
// import { TreeSelect } from 'primereact/treeselect';
import HeaderForm from '../util/HeaderForm';
import ProgressBarUtil from '../util/ProgressBarUtil';
import PacienteDocumentAdjuntos from './PacienteDocumentAdjuntos';
// import { Tree } from 'primereact/tree';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { setIntlDateTimeFormat, toTimeZone } from '../../../helpers/functions';
import { Dialog } from 'primereact/dialog';
import JSONPretty from 'react-json-pretty';

const PacienteDocumentInforme = () => {
  const params = useParams();

  //datos de paciente
  const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>('');
  const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>('');
  const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>('');
  const [vl_Nombres, setNombres] = useState<any>('');
  const [vl_NombrePaciente, setNombrePaciente] = useState<any>('');

  const [vl_Edad, setEdad] = useState<any>(0);
  const [vl_FechaNacimiento, setFechaNacimiento] = useState<any>('');
  const [vl_Sexo, setSexo] = useState<any>('');
  const [vl_LugarNacimiento, setLugarNacimiento] = useState<any>('');
  const [vl_NumeroHermanos, setNumeroHermanos] = useState<any>(0);
  const [vl_Escolaridad, setEscolaridad] = useState<any>('');
  const [vl_Referente, setReferente] = useState<any>('');
  const [vl_GradoInstruccion, setGradoInstruccion] = useState<any>('');
  const [vl_MotivoConsulta, setMotivoConsulta] = useState<any>('');
  // const [vl_PruebasTecnicas, setPruebasTecnicas] = useState<any>('');
  const [vl_ObservacionConducta, setObservacionConducta] = useState<any>('');
  const [vl_Antecedentes, setAntecedentes] = useState<any>('');
  const [vl_InterpretacionResultados, setInterpretacionResultados] = useState<any>('');
  const [vl_Conclusiones, setConclusiones] = useState<any>('');
  const [vl_Recomendaciones, setRecomendaciones] = useState<any>('');
  //datos de informe
  const [vl_IdInforme, setIdInforme] = useState<any>(0);
  const [vl_NumeroInforme, setNumeroInforme] = useState<any>(0);
  const [vl_FechaInforme, setFechaInforme] = useState<any>('');
  const [vl_NombreDocumento, setNombreDocumento] = useState<any>('');
  const [vl_IdStaff, setIdStaff] = useState<any>(0);
  const [vl_Staff, setStaff] = useState<any>('');
  const [vl_StaffFirma, setStaffFirma] = useState<any>('');

  const [CmbIdStaff, setCmbIdStaff] = useState<any>([]);

  const [vl_FechaCreacion, setFechaCreacion] = useState<any>('');
  const [vl_UsuarioCreacion, setUsuarioCreacion] = useState<any>('');
  const [vl_FechaModificacion, setFechaModificacion] = useState<any>('');
  const [vl_UsuarioModificacion, setUsuarioModificacion] = useState<any>('');
  const [vl_Estado, setEstado] = useState<any>("G");
  const CmbEstadoInforme: any = [
    { "IdEstado": "G", "Descripcion": "Generado" },
    { "IdEstado": "B", "Descripcion": "Borrador" },
    { "IdEstado": "E", "Descripcion": "Emitido" },
    { "IdEstado": "A", "Descripcion": "Anulado" }
  ];

  const [vl_ConfigTitulo, setConfigTitulo] = useState<any>('');
  const [vl_ConfigSubTitulo, setConfigSubTitulo] = useState<any>('');
  const [vl_ConfigFooter, setConfigFooter] = useState<any>('');
  const [vl_ConfigOtros, setConfigOtros] = useState<any>('');

  const [bConfigTituloReadOnly, setConfigTituloReadOnly] = useState<boolean>(true);
  const [bConfigSubTituloReadOnly, setConfigSubTituloReadOnly] = useState<boolean>(true);
  const [bConfigFooterReadOnly, setbConfigFooterReadOnly] = useState<boolean>(true);
  const [bConfigOtrosReadOnly, setbConfigOtrosReadOnly] = useState<boolean>(true);
  const [bStaffReadOnly, setbStaffReadOnly] = useState<boolean>(true);

  const refConfigTitulo = useRef<HTMLElement | HTMLInputElement | any>(null);
  const refConfigSubTitulo = useRef<HTMLElement | HTMLInputElement | any>(null);
  const refConfigFooter = useRef<HTMLElement | HTMLInputElement | any>(null);
  const refConfigOtros = useRef<HTMLElement | HTMLInputElement | any>(null);

  //DOCUMENTOS ADJUNTOS
  const [dataDocumentosAdjuntos, setDataDocumentosAdjuntos] = useState<any>([]);
  //util
  const toast = useRef<any>(null);
  const toastConfirm = useRef<any>(null);
  const [isVisible, setisVisible] = useState<boolean>(false);

  //ordenes de atencion  
  const [DataOrdenesAtencion, setDataOrdenesAtencion] = useState<any>(null);
  const [MetaDatajson, setMetaDatajson] = useState<any>([]);

  useEffect(() => {
    // console.log(params);
    pa_get_staff();
    pa_get_paciente(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pa_get_staff = async () => {
    const param = {
      "vp_opcion": "B",
      "vp_IdStaff": "0",
      "VP_Codigo": "",
      "VP_Nombres": "",
      "VP_Apellidos": ""
    }
    const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setCmbIdStaff(record);
  }

  const pa_get_paciente = async (in_IdHistoriaClinica: any) => {
    const param = {
      "VP_IdHistoriaClinica": in_IdHistoriaClinica
    }
    const response = await fetchSinToken('selectors/pa_get_paciente', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    console.log(record);

    if (record) {
      setNumeroHistoriaClinica(record.NumeroHistoriaClinica);
      setNombres(record.Nombres);
      setApellidoPaterno(record.ApellidoPaterno);
      setApellidoMaterno(record.ApellidoMaterno);

      setDataDocumentosAdjuntos([]);
      if (record.DocumentosAdjuntos?.PLAN_INFORME) {
        setDataDocumentosAdjuntos(record.DocumentosAdjuntos?.PLAN_INFORME);
      }
      //muestra ciertos campos segun edad: menores y mayores de edad
      setHiddenFieldSegunEdad(record.Edad);
      getOrdenesAtencion([]);

      // nuevo informe
      if (params.mode === 'New') {
        // Datos de filiación
        setIdInforme(0);
        setFechaInforme(new Date());
        setNombreDocumento('INFORME-HC-'+ record.NumeroHistoriaClinica);
        setNombrePaciente(record.ApellidoPaterno + ' ' + record.ApellidoMaterno + ', ' + record.Nombres);
        setEdad(record.Edad);
        setFechaNacimiento(new Date(Date.parse(record.FechaNacimiento)));
        setSexo(record.Sexo === 'F' ? 'FEMENINO' : 'MASCULINO');
        setIdStaff(record.IdStaff);
        setStaff(record.Staff);
        // console.log(record.FirmaDigital.Firma);
        setStaffFirma(record.FirmaDigital.Firma);

        setGradoInstruccion(record.IdGradoInstruccion_0);

        if (Object.keys(record.LugarNacimiento).length > 0)
          setLugarNacimiento(record.LugarNacimiento[0]?.NombreVia);
        if (Object.keys(record.InfComplementaria).length > 0) {
          setNumeroHermanos(record.InfComplementaria[0].NumeroHermanos);
          setEscolaridad(record.InfComplementaria[0].Escolaridad);
          setReferente(record.InfComplementaria[0].Referente);
        }
        // Motivo de consulta
        if (Object.keys(record.MotivoConsulta).length > 0) {
          setMotivoConsulta(record.MotivoConsulta[0]?.Txta);
        }
        // Pruebas y técnicas utilizadas
        if (record?.PlanEvaluacion)
          getOrdenesAtencion(record?.PlanEvaluacion);

        // Observación de conducta
        let vl_temp: any = '';
        let { Porte } = record.MotivoConsulta[0]?.Txtd[0] ? record.MotivoConsulta[0].Txtd[0] : '';
        let { Comportamiento } = record.MotivoConsulta[0]?.Txtd[0] ? record.MotivoConsulta[0].Txtd[0] : '';
        let { Actitud } = record.MotivoConsulta[0]?.Txtd[0] ? record.MotivoConsulta[0].Txtd[0] : '';
        // console.log(Porte);
        if (Porte && Porte !== '') vl_temp = Porte;
        if (Comportamiento && Comportamiento !== '') {
          if (vl_temp !== '') vl_temp = vl_temp + ' \n ' + Comportamiento;
          else vl_temp = Comportamiento;
        }
        if (Actitud && Actitud !== '') {
          if (vl_temp !== '') vl_temp = vl_temp + ' \n ' + Actitud;
          else vl_temp = Actitud;
        }
        setObservacionConducta(vl_temp);
        // Antecedentes
        // Interpretación de resultados
        // Conclusiones
        // Recomendaciones
        setEstado('G');
        //default               
        setConfigTitulo(record.TituloInforme);
        setConfigSubTitulo(record.SubTituloInforme);
        setConfigFooter(record.PiePaginaInforme.footer);
        setConfigOtros('');
      }

      //carga informe a editar
      if (params.mode === 'Edit') {
        // console.log('edit mode');
        getHistoriaClinicaInforme(params.IdInforme, record.InformePsicologico);
      }
    }
  }

  const getOrdenesAtencion = (data: any) => {
    // console.log(data)
    let items: any = [];
    let j = 0;
    for (let row of data) {
      row.Detalle.forEach(
        function (record: any) {
          j++;
          items.push({
            'IdItem': j,
            'IdOrdenAtencion': row.IdOrdenAtencion,
            'IdOrdenPago': row.IdOrdenPago,
            'IdPlanpago': row.IdPlanpago,
            'ItemChecked': true,
            'CodigoServicio': record.CodigoServicio,
            'Descripcion': String(record.Descripcion).trim(),
            'Prueba': record.Prueba.PDescripcion,
            'Resultado': record.Resultado.RDescripcion
          });
        }
      );
    }
    // console.log(items);
    setDataOrdenesAtencion(items);
  }

  const getOrdenesAtencionEdit = (data: any) => {
    let items: any = [];
    for (let row of data) {
      items.push({
        'IdItem': row.IdItem,
        'IdOrdenAtencion': row.IdOrdenAtencion,
        'IdOrdenPago': row.IdOrdenPago,
        'IdPlanpago': row.IdPlanpago,
        'ItemChecked': row.ItemChecked,
        'CodigoServicio': row.CodigoServicio,
        'Descripcion': String(row.Descripcion).trim(),
        'Prueba': row.Prueba,
        'Resultado': row.Resultado
      });
    }
    setDataOrdenesAtencion(items);
  }

  const getHistoriaClinicaInforme = (in_IdInforme: any, in_Data: any) => {
    // console.log(in_Data);
    let record: any = in_Data.filter(function (element: any) {
      return parseFloat(element.IdInforme) === parseFloat(in_IdInforme);
    });
    // console.log(record);
    setMetaDatajson(record);

    if (record.length > 0) {
      const rec = record[0];
      const { DatosFiliacion } = rec;
      const { Configuration } = rec;

      // Datos del informe      
      setIdInforme(in_IdInforme);
      setNumeroInforme(rec.NumeroInforme);    
      console.log( toTimeZone(rec.FechaInforme,'America/Lima') );            
      setFechaInforme(new Date(Date.parse( toTimeZone(rec.FechaInforme,'America/Lima') )));      
      setIdStaff(rec.IdStaff);
      setStaff(rec.Staff);

      setNombreDocumento(rec.NombreDocumento);
      setUsuarioCreacion(rec.UsuarioCreacion);
      setFechaCreacion(rec.FechaCreacion);
      setUsuarioModificacion(rec.UsuarioModificacion);
      setFechaModificacion(rec.FechaModificacion);

      // Datos de filiación:
      setNombrePaciente(DatosFiliacion.NombrePaciente);
      // setFechaNacimiento(new Date(Date.parse(DatosFiliacion.FechaNacimiento)));
      setFechaNacimiento(new Date(Date.parse(toTimeZone(DatosFiliacion.FechaNacimiento,'America/Lima'))));
      setEdad(DatosFiliacion.Edad);
      setLugarNacimiento(DatosFiliacion.LugarNacimiento);
      setNumeroHermanos(DatosFiliacion.NumeroHermanos);
      setSexo(DatosFiliacion.Sexo);
      setReferente(DatosFiliacion.Referente);
      setEscolaridad(DatosFiliacion.Escolaridad);
      setGradoInstruccion(DatosFiliacion.GradoInstruccion);

      // Motivo de consulta
      if (rec.MotivoConsulta?.Detalle)
        setMotivoConsulta(rec.MotivoConsulta?.Detalle);

      // Pruebas y técnicas utilizadas:
      if (rec?.PruebasTecnicas)
        getOrdenesAtencionEdit(rec?.PruebasTecnicas);
      // Observación de conducta
      if (rec?.ObservacionConducta?.Detalle)
        setObservacionConducta(rec?.ObservacionConducta?.Detalle);
      // Antecedentes
      if (rec?.Antecedentes?.Detalle)
        setAntecedentes(rec?.Antecedentes?.Detalle);
      // Interpretación de resultados
      if (rec?.InterpretacionResultados?.Detalle)
        setInterpretacionResultados(rec?.InterpretacionResultados?.Detalle);
      // Conclusiones
      if (rec?.Conclusiones?.Detalle)
        setConclusiones(rec?.Conclusiones?.Detalle);
      // Recomendaciones
      if (rec?.Recomendaciones?.Detalle)
        setRecomendaciones(rec?.Recomendaciones?.Detalle);
      // Configuration
      setEstado(rec.Estado);
      setConfigTitulo(Configuration.Titulo);
      setConfigSubTitulo(Configuration.SubTitulo);
      setConfigFooter(Configuration.Footer);
      setConfigOtros(Configuration.Otros);
      setStaffFirma(Configuration.StaffFirma);
    }

  }

  const [b_fieldsetHiddenMA, setFieldsetHiddenMA] = useState<boolean>(true);
  const [b_fieldsetHiddenME, setFieldsetHiddenME] = useState<boolean>(false);
  const setHiddenFieldSegunEdad = (in_edad: any) => {
    if (in_edad > 0 && in_edad < 18) {
      setFieldsetHiddenMA(true);
      setFieldsetHiddenME(false);
    }
    if (in_edad > 0 && in_edad >= 18) {
      setFieldsetHiddenMA(false);
      setFieldsetHiddenME(true);
    }
  }

  //CRUD MAIN
  const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
  const [b_ProgressBar, setProgressBar] = useState<boolean>(false);

  const pa_set_historia_clinica_json = async () => {

    setVisibleConfirmDialog(false);

    let vl_DatosFiliacion: any = {
      "NombrePaciente": vl_NombrePaciente,
      "FechaNacimiento": setIntlDateTimeFormat(vl_FechaNacimiento),
      "Edad": vl_Edad,
      "LugarNacimiento": vl_LugarNacimiento,
      "NumeroHermanos": vl_NumeroHermanos,
      "Sexo": vl_Sexo,
      "Escolaridad": vl_Escolaridad,
      "Referente": vl_Referente,
      "GradoInstruccion": vl_GradoInstruccion
    };
    let vl_MotivoConsulta_0: any = {
      "Detalle": vl_MotivoConsulta
    };

    let vl_PruebasTecnicas: any = [];
    DataOrdenesAtencion.forEach(
      function (row: any) {
        vl_PruebasTecnicas.push({
          'IdItem': row.IdItem,
          'IdOrdenAtencion': row.IdOrdenAtencion,
          'IdOrdenPago': row.IdOrdenPago,
          'IdPlanpago': row.IdPlanpago,
          'ItemChecked': row.ItemChecked,
          'CodigoServicio': row.CodigoServicio,
          'Descripcion': String(row.Descripcion).trim(),
          'Prueba': row.Prueba,
          'Resultado': row.Resultado
        });
      }
    );
    let vl_ObservacionConducta_0: any = {
      "Detalle": vl_ObservacionConducta
    };
    let vl_Antecedentes_0: any = {
      "Detalle": vl_Antecedentes
    };
    let vl_InterpretacionResultados_0: any = {
      "Detalle": vl_InterpretacionResultados
    };
    let vl_Conclusiones_0: any = {
      "Detalle": vl_Conclusiones
    };
    let vl_Recomendaciones_0: any = {
      "Detalle": vl_Recomendaciones
    };
    let vl_Configuration: any = {
      "Titulo": vl_ConfigTitulo,
      "SubTitulo": vl_ConfigSubTitulo,
      "Footer": vl_ConfigFooter,
      "Otros": vl_ConfigOtros,
      "StaffFirma": vl_StaffFirma
    };

    // console.log('vl_Configuration:');
    // console.log(vl_Configuration);

    let VL_json: any = {
      "IdInforme": vl_IdInforme,
      "NumeroInforme": vl_NumeroInforme,
      "FechaInforme": setIntlDateTimeFormat(vl_FechaInforme),
      "NombreDocumento": vl_NombreDocumento,
      "IdStaff": vl_IdStaff,
      "Staff": vl_Staff,
      "FechaCreacion": vl_IdInforme === 0 ? new Date() : vl_FechaCreacion,
      "FechaModificacion": vl_IdInforme !== 0 ? new Date() : vl_FechaModificacion,
      "UsuarioCreacion": vl_IdInforme === 0 ? localStorage.getItem('authUserName') : vl_UsuarioCreacion,
      "UsuarioModificacion": vl_IdInforme !== 0 ? localStorage.getItem('authUserName') : vl_UsuarioModificacion,
      "Estado": vl_Estado,
      "DatosFiliacion": vl_DatosFiliacion,
      "MotivoConsulta": vl_MotivoConsulta_0,
      "PruebasTecnicas": vl_PruebasTecnicas,
      "ObservacionConducta": vl_ObservacionConducta_0,
      "Antecedentes": vl_Antecedentes_0,
      "InterpretacionResultados": vl_InterpretacionResultados_0,
      "Conclusiones": vl_Conclusiones_0,
      "Recomendaciones": vl_Recomendaciones_0,
      "Configuration": vl_Configuration
    };

    const param = {
      VP_ACTION: 'PIN',
      VP_IdHistoriaClinica: params.id,
      VP_IdSucursal: localStorage.getItem('authIdSucursal'),
      VP_IdUsuario: localStorage.getItem('authIdUsuario'),
      VP_json: JSON.stringify(VL_json)
    }

    // console.log(param);
    // return;

    setProgressBar(true);
    const response = await fetchSinToken('paciente/pa_set_historia_clinica_json', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    // console.log('RESPONSE:');
    console.log(record);
    if (record) {
      const message = record.sql_message;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 8000 });
      //vuelve a HC-informes
      if (record.sql_error === 0) {
        link_formHC();
      }

    } else {
      const message =
        'Code: ' + resp.data.code +
        'Errno: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
    }
    setProgressBar(false);
  }
  const onRejectAction = () => {
    setVisibleConfirmDialog(false);
  }
  const onValidarCampos = () => {
    let vl_valida = true;
    let vl_message = '';

    // if (Number(vl_IdCliente) === 0 || (!Number(vl_IdCliente))) {
    //     vl_valida = false;
    //     vl_message = 'Debe buscar un paciente';
    // }
    return [vl_valida, vl_message];
  }

  const setConfirmarGrabarHistoriaClinicaJson = () => {
    const IsValidaCampos = onValidarCampos();
    const Isvalido = IsValidaCampos[0];
    const IsMessage = IsValidaCampos[1];
    if (!Isvalido) {
      toastConfirm.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 4000 });
      return;
    }
    // si no hay campos invalidos procesar...    
    setVisibleConfirmDialog(true);
  }

  const navigate = useNavigate();
  const link_formHC = () => {
    // vl_activeIndex = 8 > INFORME
    navigate('/HistoriaClinicaView/' + vl_NumeroHistoriaClinica + '/8');
  }
  const link_form_close = () => {
    navigate('/WelcomePage');
  }
  //open modal (view documents)
  const [isOpenModal01, openModal01, closeModal01] = useModal();
  const isMounted01 = useRef(false);
  const setIsMounted01 = (val: boolean) => {
    isMounted01.current = val;
  }
  const handleClickUploadFiles = (e: any) => {
    // if( parseInt(vl_IdCliente) === 0 ){
    //     messagesHistory.current.show([                                                                                                              
    //         { severity: 'warn', detail: 'No se puede mostrar agenda, busque un paciente primero', life: 10000 }
    //     ]);
    //     return;
    // } 
    isMounted01.current = true;
    openModal01();
  }
  const buildPDFInforme = async () => {
    if (vl_IdInforme === 0) {
      toastConfirm.current.show({ severity: 'info', detail: 'Debe generar el informe', life: 6000 });
      return;
    }
    const endpoint = 'reports/formatoInforme/?IdHistoriaClinica=' + params.id + '&IdInforme=' + vl_IdInforme;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    console.log(url);
    window.open(url);
  }

  const renderHeaderEditor = () => {
    return (
      <>
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
        </span>
      </>
    );
  }
  const headerEditor = renderHeaderEditor();

  const PacienteDocumentAdjuntosForm = () => {
    return (
      <PacienteDocumentAdjuntos
        isOpen={isOpenModal01}
        isHide={closeModal01}
        setIsMounted={setIsMounted01}
        IdHistoriaClinica={params.id}
        TitleFormulario={'INFORMES'}
        NumeroOrden={vl_NumeroInforme} //vl_PlanTerapiaNumeroOrden
        ConfigS3={{
          bucket: dataDocumentosAdjuntos[0]?.DocumentBucket ? dataDocumentosAdjuntos[0].DocumentBucket : 'psisabe.bucket.files',
          folder: dataDocumentosAdjuntos[0]?.DocumentFolder ? dataDocumentosAdjuntos[0].DocumentFolder : 'HISTORIA_CLINICA',
          subfolder: dataDocumentosAdjuntos[0]?.DocumentSubFolder ? dataDocumentosAdjuntos[0].DocumentSubFolder : vl_NumeroHistoriaClinica,
          tipofolder: dataDocumentosAdjuntos[0]?.DocumentTipoFolder ? dataDocumentosAdjuntos[0].DocumentTipoFolder : 'PLAN_INFORME',
          folderCreated: dataDocumentosAdjuntos[0]?.DocumentTipoFolder ? 'S' : 'N'
        }
        }
      />
    );
  }

  const gridDataOrdenesAtencionCkeckedColumn = (options: any) => {
    return (
      <>
        <InputSwitch
          checked={options.value}
          onChange={(e) => options.editorCallback(e.target.value)}
        />;
      </>
    );
  }
  const templateSelection = (row: any) => {
    return (
      <>
        <React.Fragment> <InputSwitch checked={row.ItemChecked} /> </React.Fragment>
      </>
    );
  }

  // const templatePruebas = (row: any) => {
  //   return (
  //     <>
  //       <React.Fragment>
  //         <Editor readOnly={true} showHeader={false} value={row.Prueba} />
  //       </React.Fragment>
  //     </>
  //   );
  // }


  const onRowEditComplete = (e: any) => {
    let _products2 = [...DataOrdenesAtencion];
    let { newData, index } = e;
    _products2[index] = newData;
    setDataOrdenesAtencion(_products2);
  }

  //crud 

  const [vl_setIdItem, setIdItem] = useState<any>(0);
  const [vl_IdOrdenAtencion, setIdOrdenAtencion] = useState<any>('0');
  const [vl_IdOrdenPago, setIdOrdenPago] = useState<any>(0);
  const [vl_IdPlanpago, setIdPlanpago] = useState<any>(0);
  const [vl_ItemChecked, setItemChecked] = useState<boolean>(false);
  const [vl_CodigoServicio, setCodigoServicio] = useState<any>('');
  const [vl_Descripcion, setDescripcion] = useState<any>('');
  const [vl_Prueba, setPrueba] = useState<any>('');
  const [vl_Resultado, setResultado] = useState<any>('');

  const setPlanEvaluacionAgregar = () => {
    let emptyProduct6 = {
      'IdItem': 0,
      'IdOrdenAtencion': 0,
      'IdOrdenPago': 0,
      'IdPlanpago': 0,
      'ItemChecked': true,
      'CodigoServicio': '',
      'Descripcion': '',
      'Prueba': '',
      'Resultado': ''
    };

    if (vl_IdOrdenPago === 0) {
      toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Seleccionar un registro ', life: 8000 });
      return;
    }
    if (vl_Prueba === '') {
      toast.current.show({ severity: 'info', sumamy: '::PSISABE', detail: 'Debe ingresar el dato ', life: 8000 });
      return;
    }
    emptyProduct6 = {
      'IdItem': vl_setIdItem,
      'IdOrdenAtencion': vl_IdOrdenAtencion,
      'IdOrdenPago': vl_IdOrdenPago,
      'IdPlanpago': vl_IdPlanpago,
      'ItemChecked': vl_ItemChecked,
      'CodigoServicio': vl_CodigoServicio,
      'Descripcion': vl_Descripcion,
      'Prueba': vl_Prueba,
      'Resultado': vl_Resultado
    }
    // console.log(emptyProduct6);        
    let _products = [...DataOrdenesAtencion];
    let _product = { ...emptyProduct6 };
    if (vl_setIdItem !== 0) {
      const index = findIndexById6(vl_setIdItem);
      console.log(index);
      _products[index] = _product;
    } else {
      _product.IdItem = createId();
      _product.IdOrdenAtencion = emptyProduct6.IdOrdenAtencion;
      _product.IdOrdenPago = emptyProduct6.IdOrdenPago;
      _product.IdPlanpago = emptyProduct6.IdPlanpago;
      _product.ItemChecked = emptyProduct6.ItemChecked;
      _product.CodigoServicio = emptyProduct6.CodigoServicio;
      _product.Descripcion = emptyProduct6.Descripcion;
      _product.Prueba = emptyProduct6.Prueba;
      _product.Resultado = emptyProduct6.Resultado;
      _products.push(_product);
    }
    setDataOrdenesAtencion(_products);
    setPlanEvaluacionNuevo();
  }

  const setPlanEvaluacionNuevo = () => {
    setIdItem(0);
    setIdOrdenAtencion(0);
    setIdOrdenPago(0);
    setIdPlanpago(0);
    setItemChecked(false);
    setCodigoServicio('');
    setDescripcion('');
    setPrueba('');
    setResultado('');
  }

  const createId = () => {
    let index = 0;
    DataOrdenesAtencion.forEach(function (row: any) {
      index = parseFloat(row.IdItem);
    });
    index = index + 1;
    return index;
  }
  const findIndexById6 = (id: any) => {
    let index = -1;
    for (let i = 0; i < DataOrdenesAtencion.length; i++) {
      if (DataOrdenesAtencion[i].IdItem === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  var JSONPrettyMon = require('react-json-pretty/dist/monikai');


  return (
    <>
      <Toast ref={toast} position="center"></Toast>
      <ProgressBarUtil
        ProgressBarVisible={b_ProgressBar}
        ProgressBarMessage='Please wait, loading...'
        setProgressBar={setProgressBar}
      />
      <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
        message="Generar informe?"
        header="Confirmar para continuar.."
        icon="pi pi-exclamation-triangle"
        accept={pa_set_historia_clinica_json}
        reject={onRejectAction}
      />
      <React.Fragment>{isMounted01.current && <PacienteDocumentAdjuntosForm />}</React.Fragment>

      <HeaderForm
        TitleForm={'INFORME PSICÓLOGICO.'}
        Paciente={vl_ApellidoPaterno + ' ' + vl_ApellidoMaterno + ', ' + vl_Nombres}
        NumeroHistoriaClinica={vl_NumeroHistoriaClinica}
        Edad={vl_Edad}
        linkGoBack={link_formHC}
        link_form_close={link_form_close}
        setOnClickButtonSave={setConfirmarGrabarHistoriaClinicaJson}
      ></HeaderForm>

      <ScrollPanel style={{ height: '700px' }}>
        <div className="flex justify-content-center gray-container p-1">
          <div className='card p-1 mr-1 max-w-full '>
            <div className="bg-gray-200 p2 text-black">
              <div className="col mt-0 ml-0 text-base font-bold h-3rem flex align-items-center justify-content-center " >
                <label> DATOS DEL INFORME </label>
              </div>
            </div>
            <div className="p-col pt-1 ">
              <label className="p-col-fixed mr-1 ml-1 font-bold text-sm " style={{ width: '70px', textAlign: 'end' }}>Nº:</label>
              <InputText type="text" style={{ width: '100px', textAlign: 'center' }} className=" font-semibold text-primary-800"
                value={vl_NumeroInforme} readOnly
                onChange={(e: any) => setNumeroInforme(String(e.currentTarget.value).toUpperCase())}
              />
            </div>
            <div className="p-col pt-1 ">
              <label className="p-col-fixed mr-1 ml-1 font-bold text-sm " style={{ width: '70px', textAlign: 'end' }}>Fecha:</label>
              <Calendar style={{ width: '10rem' }} inputClassName="font-semibold text-primary-800 text-center"
                value={vl_FechaInforme}
                onChange={(e: any) => setFechaInforme(e.value)}
                dateFormat="dd-mm-yy" showIcon={true}
              />
            </div>
            <div className="p-col pt-1 ">
              <label className="p-col-fixed mr-1 ml-1 pb-1 font-bold text-sm " style={{ textAlign: 'end' }}>Profesional:</label>
              <Button icon="pi pi-user-edit" title='Modificar Profesional' className="p-button-rounded p-button-outlined mb-1"
                onClick={() => {
                  setbStaffReadOnly(false);
                }}
              />
              <Dropdown style={{ width: '100%' }} filter filterBy="staff" disabled={bStaffReadOnly}
                value={vl_IdStaff}
                options={CmbIdStaff}
                onChange={(e: any) => {
                  setIdStaff(e.value);
                  let itemsfiltered: any = CmbIdStaff.filter(function (element: any) {
                    return element.IdStaff === e.value;
                  });
                  // console.log(itemsfiltered);                                                     
                  setStaff(itemsfiltered[0].staff);
                  setStaffFirma(itemsfiltered[0].FirmaDigital.Firma);
                }}
                optionValue="IdStaff"
                optionLabel="staff"
                placeholder="Seleccionar" />

            </div>
            <div className="p-col pt-1 ">
              <label className="p-col-fixed mr-1 ml-1 pb-1 font-bold text-sm " style={{ textAlign: 'end' }}>Nombre del documento:</label>
              <InputText type="text" style={{ width: '100%', textAlign: 'left' }} className=" font-semibold text-primary-800"
                value={vl_NombreDocumento}
                onChange={(e: any) => setNombreDocumento(String(e.currentTarget.value).toUpperCase())}
              />
            </div>
            <div className="p-col pt-1 ">
              <label className="p-col-fixed mr-1 ml-1 pb-1 font-bold text-sm " style={{ textAlign: 'end' }}>Estado:</label>
              <Dropdown style={{ width: '60%' }}
                value={vl_Estado}
                options={CmbEstadoInforme}
                onChange={(e: any) => {
                  setEstado(e.value);
                  // let itemsfiltered: any = CmbIdStaff.filter(function (element: any) {
                  //   return element.IdStaff === e.value;
                  // });                                                                    
                  // setStaff(itemsfiltered[0].staff);
                }}
                optionValue="IdEstado"
                optionLabel="Descripcion"
                placeholder="Seleccionar" />
            </div>

            <div className="p-col pt-3 ">
              <label className="p-col-fixed mr-1 ml-1 pb-1 font-semibold text-sm " style={{ textAlign: 'start' }}>Creado por: {vl_UsuarioCreacion} </label>
            </div>
            <div className="p-col pt-1 ">
              <label className="p-col-fixed mr-1 ml-1 pb-1 font-semibold text-sm " style={{ textAlign: 'start' }}>Fecha: {vl_FechaCreacion}</label>
            </div>
            <div className="p-col pt-1 ">
              <label className="p-col-fixed mr-1 ml-1 pb-1 font-semibold text-sm " style={{ textAlign: 'start' }}>Modificado por: {vl_UsuarioModificacion}</label>
            </div>
            <div className="p-col pt-1 ">
              <label className="p-col-fixed mr-1 ml-1 pb-1 font-semibold text-sm " style={{ textAlign: 'start' }}>Fecha: {vl_FechaModificacion}</label>
            </div>

            <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-3  ">
              <span className="p-buttonset">
                <Button icon="pi pi-file-pdf" title='Imprimir informe' className="p-button-sm p-button-outlined" onClick={() => { buildPDFInforme() }} />
                <Button icon="pi pi-folder" title='Documentos adjuntos ' className="p-button-sm p-button-outlined" onClick={(e: any) => { handleClickUploadFiles(e) }} />
                <Button icon="pi pi-database" title='Metadatos del informe' disabled={params.mode === 'Edit' ? false : true} className="p-button-sm p-button-outlined" onClick={(e: any) => { setisVisible(true) }} />
                {/* <Button icon="pi pi-trash" title='Anular el informe' className="p-button-sm p-button-danger" onClick={() => { }} /> */}
              </span>
            </div>
          </div>
          <div className='card p-1 w-100' >
            <Accordion activeIndex={0}>
              <AccordionTab header="Datos de filiación">
                <div className="p-field p-grid mt-1">
                  <div className="p-col">
                    <label className="p-col-fixed pb-1 mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Nombres y Ape. :</label>
                    <InputText type="text" style={{ width: '20rem' }} className=" font-semibold text-primary-800"
                      value={vl_NombrePaciente} readOnly
                      onChange={(e) => {
                        setNombrePaciente(String(e.currentTarget.value).toUpperCase());
                      }}
                    />
                  </div>
                  <div className="p-col pt-1">
                    <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Fecha Nacimiento:</label>
                    <Calendar dateFormat="dd-mm-yy" inputClassName=" font-semibold text-primary-800"
                      style={{ width: '11rem' }} showIcon maxDate={new Date()} readOnlyInput
                      value={vl_FechaNacimiento}
                      onChange={(e: any) => {
                        setFechaNacimiento(e.value);
                      }} />
                    <label className="p-col-fixed ml-2 mr-1 font-bold text-sm" style={{ textAlign: 'end' }}>Edad:</label>
                    <InputText type="text" style={{ width: '60px', textAlign: 'center' }} readOnly value={vl_Edad} className=" font-semibold text-primary-800"
                    />
                  </div>
                  <div className="p-col pt-1">
                    <label className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '125px', textAlign: 'end' }}>Lugar Nacimiento:</label>
                    <InputText type="text" style={{ width: '20rem', textAlign: 'left' }} readOnly value={vl_LugarNacimiento} className=" font-semibold text-primary-800" />
                  </div>
                  <div className="p-col pt-1">
                    <label className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '125px', textAlign: 'end' }}>Nº Hermanos:</label>
                    <InputText type="text" style={{ width: '80px', textAlign: 'center' }} readOnly value={vl_NumeroHermanos} className=" font-semibold text-primary-800" />
                    <label className="p-col-fixed mr-1 ml-2 font-bold text-sm" style={{ textAlign: 'end' }}>Sexo:</label>
                    <InputText type="text" style={{ width: '120px', textAlign: 'center' }} readOnly value={vl_Sexo} className=" font-semibold text-primary-800" />
                  </div>

                  <div className="p-col pt-1" hidden={b_fieldsetHiddenME}  >
                    <label className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '125px', textAlign: 'end' }}>Escolaridad:</label>
                    <InputText type="text" style={{ width: '20rem', textAlign: 'left' }} readOnly value={vl_Escolaridad} className=" font-semibold text-primary-800" />
                  </div>
                  <div className="p-col pt-1" hidden={b_fieldsetHiddenME} >
                    <label className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '125px', textAlign: 'end' }}>Referente:</label>
                    <InputText type="text" style={{ width: '20rem', textAlign: 'left' }} readOnly value={vl_Referente} className=" font-semibold text-primary-800" />
                  </div>
                  <div className="p-col pt-1" hidden={b_fieldsetHiddenMA} >
                    <label className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '125px', textAlign: 'end' }}>Grado instrucción:</label>
                    <InputText type="text" style={{ width: '20rem', textAlign: 'left' }} readOnly value={vl_GradoInstruccion} className=" font-semibold text-primary-800" />
                  </div>
                </div>
              </AccordionTab>
              <AccordionTab header="Motivo de consulta">
                <Editor style={{ height: '220px' }}
                  headerTemplate={headerEditor}
                  value={vl_MotivoConsulta}
                  onTextChange={(e) => {
                    console.log(e);
                    setMotivoConsulta(String(e.htmlValue ? e.htmlValue : ''));
                  }}
                />
              </AccordionTab>
              <AccordionTab header="Pruebas y técnicas utilizadas">
                <div className='pb-2'>
                  <label className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '150px', textAlign: 'end' }}>Ordenes de atención:</label>
                </div>
                <DataTable size="small"
                  // style={{ height: "290px" }}
                  value={DataOrdenesAtencion ?? []}
                  dataKey="IdPlanPagoDetalle"
                  resizableColumns
                  responsiveLayout="scroll"
                  columnResizeMode="fit"
                  showGridlines
                  stripedRows
                  scrollable
                  scrollHeight="290px"
                  // selection={props.dataSelectedCuotas}
                  // rowClassName={rowClassName}
                  // isDataSelectable={isRowSelectable}
                  editMode="row"
                  selectionMode="single"
                  onRowEditComplete={onRowEditComplete}
                  // onRowEditComplete={onRowEditComplete}
                  // onRowEditInit={onRowEditInit}
                  // onRowSelect={onRowSelect}
                  // onRowEditSave={onRowEditSave}
                  // onRowUnselect={onRowUnselect}
                  onSelectionChange={(e) => {
                    // console.log(e.value);
                    // setPruebasTecnicas(e.value.Prueba);
                    setIdItem(e.value.IdItem);
                    setIdOrdenAtencion(e.value.IdOrdenAtencion);
                    setIdOrdenPago(e.value.IdOrdenPago);
                    setIdPlanpago(e.value.IdPlanpago);
                    setCodigoServicio(e.value.CodigoServicio);
                    setDescripcion(e.value.Descripcion);
                    setItemChecked(e.value.ItemChecked);
                    setPrueba(e.value.Prueba);
                    setResultado(e.value.Resultado);
                  }}
                >
                  <Column rowEditor style={{ maxWidth: '70px' }} bodyStyle={{ textAlign: 'center' }}></Column>
                  <Column field="ItemChecked" style={{ maxWidth: '70px' }}
                    editor={(options) => gridDataOrdenesAtencionCkeckedColumn(options)}
                    body={templateSelection}
                  ></Column>
                  <Column field="Descripcion" header="Servicio" style={{ minWidth: '90px', maxWidth: '150px' }}  ></Column>
                  <Column field="Prueba" header="Pruebas realizadas"
                    headerClassName="flex justify-content-start"
                    bodyClassName="flex justify-content-start text-green-800 font-semibold white-space-normal "
                    // body={templatePruebas}
                  >
                  </Column>
                </DataTable>

                <div className='pt-1'>
                  <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                    <p className="font-italic border-round border-0 p-1"> (*) Seleccionar un registro para editar.</p>
                  </div>
                  <h4>{vl_Descripcion}</h4> 
                  <InputTextarea rows={8} style={{ width: '99%' }}
                    className="border-green-700"
                    value={vl_Prueba}                    
                    onChange={(e: any) => {
                      setPrueba(String(e.currentTarget.value));
                    }}
                  />
                  {/* <Editor style={{ height: '120px' }}
                    headerTemplate={headerEditor}
                    value={vl_Prueba}
                    onTextChange={(e) => {
                      // console.log(e);
                      setPrueba(String(e.htmlValue ? e.htmlValue : ''));
                    }}
                  /> */}
                  <div className="p-col pt-1" >
                    <Button type="button" icon="pi pi-refresh" label='Actualizar'
                      onClick={(e) => setPlanEvaluacionAgregar()}
                      className="p-button-success mr-1"
                    />
                  </div>
                </div>
              </AccordionTab>
              <AccordionTab header="Observación de conducta">
                <Editor style={{ height: '220px' }}
                  headerTemplate={headerEditor}
                  value={vl_ObservacionConducta}
                  onTextChange={(e) => setObservacionConducta(String(e.htmlValue ? e.htmlValue : ''))}
                />
              </AccordionTab>
              <AccordionTab header="Antecedentes">
                <Editor style={{ height: '220px' }}
                  headerTemplate={headerEditor}
                  value={vl_Antecedentes}
                  onTextChange={(e) => setAntecedentes(String(e.htmlValue ? e.htmlValue : ''))}
                />
              </AccordionTab>
              <AccordionTab header="Interpretación de resultados">
                <Editor style={{ height: '220px' }}
                  headerTemplate={headerEditor}
                  value={vl_InterpretacionResultados}
                  onTextChange={(e) => setInterpretacionResultados(String(e.htmlValue ? e.htmlValue : ''))}
                />
              </AccordionTab>
              <AccordionTab header="Conclusiones">
                <Editor style={{ height: '220px' }}
                  headerTemplate={headerEditor}
                  value={vl_Conclusiones}
                  onTextChange={(e) => setConclusiones(String(e.htmlValue ? e.htmlValue : ''))}
                />
              </AccordionTab>
              <AccordionTab header="Recomendaciones">
                <Editor style={{ height: '220px' }}
                  headerTemplate={headerEditor}
                  value={vl_Recomendaciones}
                  onTextChange={(e) => setRecomendaciones(String(e.htmlValue ? e.htmlValue : ''))}
                />
              </AccordionTab>
              <AccordionTab header="Configuración informe">
                <div className="p-field p-grid mt-1 ">
                  <div className="p-col pb-1">
                    <label className="p-col-fixed mr-1 pb-1 font-bold text-sm">Titulo del documento:</label>
                    <div className="flex align-items-stretch align-items-center flex-nowrap">
                      <div className='pr-1' >
                        <Button icon="pi pi-pencil" title='Editar' className="p-button-sm p-button-outlined"
                          onClick={() => {
                            setConfigTituloReadOnly(bConfigTituloReadOnly ? false : true);
                            setTimeout(() => {
                              refConfigTitulo.current.focus();
                            }, 200);
                          }}
                        />
                      </div>
                      <div className='w-100' >
                        <InputText type="text" style={{ width: '100%', textAlign: 'left' }} className="font-semibold text-primary-800"
                          disabled={bConfigTituloReadOnly} value={vl_ConfigTitulo} ref={refConfigTitulo}
                          // onChange={(e: any) => { setConfigTitulo(String(e.currentTarget.value)) }}
                          onChange={(e) => setConfigTitulo(String(e.currentTarget.value))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-col pb-1">
                    <label className="p-col-fixed mr-1 pb-1 font-bold text-sm">Sub Titulo:</label>
                    <div className="flex align-items-stretch align-items-center flex-nowrap">
                      <div className='pr-1' >
                        <Button icon="pi pi-pencil" title='Editar' className="p-button-sm p-button-outlined"
                          onClick={() => {
                            setConfigSubTituloReadOnly(bConfigSubTituloReadOnly ? false : true);
                            setTimeout(() => {
                              refConfigSubTitulo.current.focus();
                            }, 200);
                          }}
                        />
                      </div>
                      <div className='w-100' >
                        <InputText type="text" style={{ width: '100%', textAlign: 'left' }} className="font-semibold text-primary-800"
                          disabled={bConfigSubTituloReadOnly} value={vl_ConfigSubTitulo} ref={refConfigSubTitulo}
                          // onChange={(e: any) => { setConfigSubTitulo(String(e.currentTarget.value)); }}
                          onChange={(e) => setConfigSubTitulo(String(e.currentTarget.value))}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="p-col pb-1">
                    <label className="p-col-fixed mr-1 pb-1 font-bold text-sm" >Pie de pagina:</label>
                    <div className="flex align-items-stretch align-items-center flex-nowrap">
                      <div className='pr-1' >
                        <Button icon="pi pi-pencil" title='Editar' className="p-button-sm p-button-outlined"
                          onClick={() => {
                            setbConfigFooterReadOnly(bConfigFooterReadOnly ? false : true);
                            setTimeout(() => {
                              refConfigFooter.current.focus();
                            }, 200);
                          }}
                        />
                      </div>
                      <div className='w-100' >
                        <Editor style={{ height: '120px' }}
                          headerTemplate={headerEditor}
                          value={vl_ConfigFooter}
                          onTextChange={(e) => setConfigFooter(String(e.htmlValue ? e.htmlValue : ''))}
                        />
                        {/* <InputText type="text" style={{ width: '100%', textAlign: 'left' }} className="font-semibold text-primary-800"
                          disabled={bConfigFooterReadOnly} value={vl_ConfigFooter} ref={refConfigFooter} onChange={(e: any) => { setConfigFooter(e.value); }} /> */}
                      </div>
                    </div>
                  </div>
                  <div className="p-col">
                    <label className="p-col-fixed mr-1 pb-1 font-bold text-sm" >Otros:</label>
                    <div className="flex align-items-stretch align-items-center flex-nowrap">
                      <div className='pr-1' >
                        <Button icon="pi pi-pencil" title='Editar' className="p-button-sm p-button-outlined"
                          onClick={() => {
                            setbConfigOtrosReadOnly(bConfigOtrosReadOnly ? false : true);
                            setTimeout(() => {
                              refConfigOtros.current.focus();
                            }, 200);
                          }}
                        />
                      </div>
                      <div className='w-100' >
                        <InputText type="text" style={{ width: '100%', textAlign: 'left' }} className="font-semibold text-primary-800"
                          disabled={bConfigOtrosReadOnly} value={vl_ConfigOtros} ref={refConfigOtros}
                          onChange={(e: any) => { setConfigOtros(String(e.currentTarget.value)); }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </ScrollPanel>

      <Dialog position='center' modal draggable resizable closable showHeader maximizable closeOnEscape maximized
        breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }}
        header={
          <h3>Metadatos.</h3>
        }
        visible={isVisible}
        onHide={() => {
          setisVisible(false);
          // isHide();
        }}
      >
        <JSONPretty
          data={MetaDatajson}
          style={{ textAlign: "left" }}
          theme={JSONPrettyMon}
          space={4}
          themeClassName='border-solid' //border-solid dotted, solid, double 
        >
        </JSONPretty>

      </Dialog>

    </>
  )
}

export default PacienteDocumentInforme
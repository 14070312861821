import { useRef, useState } from 'react';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { InputNumber } from 'primereact/inputnumber';
import { ProgressBar } from 'react-bootstrap';
import { fetchSinToken } from '../../../../helpers/fetch';
import { PlanPagos } from './PlanPagos';
import { DetallePagos } from './DetallePagos';
import { DetalleCuotas } from '../ordenTerapia/DetalleCuotas';
import useModal from '../../../../context/WindowsOpen';

export const OrdenPagos = ({ ...props }) => {
    // console.log('OrdenPagos');
    // console.log(props);     
    let vl_IdOrdenPago:any = props.dataOrdenPago[0].IdOrdenPago;
    let t_TotalInversion = 0;
    let t_TotalPagos = 0;
    let t_TotaltCuota = 0;
    let t_CuotaPagada = 0;
    let t_Saldo = 0;
    props.dataOrdenPago.forEach(
        function (row: any) {
            t_TotalInversion = t_TotalInversion + parseFloat(row.Total);
            t_TotalPagos = t_TotalPagos + parseFloat(row.Pagos);
            t_TotaltCuota = t_TotaltCuota + parseFloat(row.NumeroCuotas);
            t_Saldo = t_Saldo + parseFloat(row.Saldo);
            t_CuotaPagada = t_CuotaPagada + parseFloat(row.NumeroCuotasPagado);
            // if( row.EstadoPago == 'T') 
            // t_CuotaPagada   = t_CuotaPagada + 1;                        
        }
    );
    const vl_TotalInversion = t_TotalInversion; //props.dataOrdenPago[0]?.Importe;
    const vl_TotalPagos = t_TotalPagos; //props.dataOrdenPago[0]?.Pagos;
    const vl_TotaltCuota = t_TotaltCuota; //props.dataOrdenPago[0]?.NumeroCuotas;
    const vl_CuotaPagada = t_CuotaPagada; // props.dataOrdenPago[0]?.CuotaPagada;
    const vl_Saldo = t_Saldo; //props.dataOrdenPago[0]?.Saldo;   

    const [dataPlanPagos, setDataPlanPagos] = useState<any>([]);
    const [dataDetallePagos, setDataDetallePagos] = useState<any>([]);
    const [dataDetalleCuotas, setDataDetalleCuotas] = useState<any>([]);

    const o_planPago = useRef<OverlayPanel>(null);
    const o_detallePago = useRef<OverlayPanel>(null);
    // const o_detalleCuota = useRef<OverlayPanel>(null);

    const [isOpenModal, openModal, closeModal] = useModal();
    const isMounted = useRef(false);
    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }
    const handleClickDetalleCuota = () => {
        // console.log('handleClickDetalleCuota');
        isMounted.current = true;
        openModal();
    }

    const template_statusPago = (item: any) => {
        // console.log(item);
        const BtnStatusPending = () => {
            return (
                <>
                    <label className="col text-sm bg-yellow-500" style={{ width: '8rem', textAlign: 'center' }}>Pendiente</label>
                </>
            );
        }
        const BtnStatusPagado = () => {
            return (
                <>
                    <label className="col text-sm bg-green-500 text-white" style={{ width: '8rem', textAlign: 'center' }}>Pagado</label>
                </>
            );
        }
        const BtnStatusParcial = () => {
            return (
                <>
                    <label className="col text-sm bg-yellow-500 text-white" style={{ width: '8rem', textAlign: 'center' }}>Parcial</label>
                </>
            );
        }


        return (
            <>
                {item.EstadoPago === 'P' ? <BtnStatusPending /> : item.EstadoPago === 'C' ? <BtnStatusPagado /> : <BtnStatusParcial />}
            </>
        );
    }

    const template_btnPlanPago = (item: any) => {
        const vl_IdPlanPago = item.IdPlanPago;
        return (
            <>
                <Button
                    type="button"
                    className="p-button-rounded p-button-warning "
                    icon="pi pi-calendar-plus"
                    // style={{ height: '24px' }}
                    title="Cronograma de pagos por sessiones"
                    onClick={(e) => pa_get_plan_pagos(vl_IdPlanPago, e)}
                />
            </>
        );
    }
    // const template_btnDetallePago = (item: any) => {
    //     const vl_IdOrdenPago = item.IdOrdenPago;
    //     return (
    //         <>
    //             <Button
    //                 type="button"
    //                 className="p-button-rounded p-button-warning "
    //                 icon="pi pi-dollar"
    //                 title="Detalle de pagos"
    //                 onClick={(e) => pa_get_pago_detalle(vl_IdOrdenPago, e)}
    //             />
    //         </>
    //     );
    // }
    const template_btnDetalleCuotas = (item: any) => {
        // const vl_IdOrdenPago = item.IdOrdenPago;
        return (
            <>
                <Button label={`${item.NumeroCuotaPago}`} className="p-button-link pl-1" title='Ver importe de cuotas'
                    // onClick={(e) => pa_get_orden_pago_cuota(vl_IdOrdenPago, e)}
                    onClick={()=>handleClickDetalleCuota()}
                />                
            </>
        );
    }
    const template_NumberCurrency = (item: any) => {
        return (
            <>
                ${item.Saldo}
            </>
        );
    }
    const pa_get_plan_pagos = async (IdPlanPago: any, e: any) => {
        // console.log('IdPlanPago:'+ IdPlanPago );        
        if (Number(IdPlanPago) > 0) {
            const param = {
                "vp_IdPlanPago": IdPlanPago
            }
            const response = await fetchSinToken('admision/pa_get_plan_pagos', param, 'POST');
            const resp = await response.json();
            const record = resp.data;
            setDataPlanPagos(record);
            // Mostrar el Overlay Popup
            o_planPago.current?.toggle(e);
        }
    }

    // desactivado
    // const pa_get_pago_detalle = async (IdOrdenPago: any, e: any) => {
    //     // console.log('IdOrdenPago:'+ IdOrdenPago );        
    //     if (Number(IdOrdenPago) > 0) {
    //         const param = {
    //             "vp_IdOrdenPago": IdOrdenPago
    //         }
    //         const response = await fetchSinToken('admision/pa_get_pago_detalle', param, 'POST');
    //         const resp = await response.json();
    //         const record = resp.data;
    //         setDataDetallePagos(record);
    //         // Mostrar el Overlay Popup
    //         // console.log('o_detallePago show');
    //         o_detallePago.current?.toggle(e);
    //     }
    // }

    // const pa_get_orden_pago_cuota = async (IdOrdenPago: any, e: any) => {
    //     // console.log('IdOrdenPago:'+ IdOrdenPago );        
    //     if (Number(IdOrdenPago) > 0) {
    //         const param = {
    //             "VP_IdOrdenPago": IdOrdenPago
    //         }
    //         const response = await fetchSinToken('admision/pa_get_orden_pago_cuota', param, 'POST');
    //         const resp = await response.json();
    //         const record = resp.data;
    //         setDataDetalleCuotas(record);            
    //         o_detalleCuota.current?.toggle(e);
    //     }
    // }

    return (
        <>
            <div className="p-field p-grid mb-1">
                <div className="p-col mb-1 " >
                    {/* <div className="border-solid border-yellow-200 m-1 pr-1 pb-1 pt-1  flex align-items-center justify-content-center"> */}
                    <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Total:</label>
                    <InputNumber type="text" 
                        // inputClassName='border-cyan-500 font-bold text-gray-900 border-round '
                        inputClassName='font-semibold'
                        inputStyle={{ height: '35px', width: '100px', textAlign: 'right' }}
                        value={vl_TotalInversion} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Sesion Pagada:</label>
                    <InputNumber type="text" inputClassName='font-semibold'
                        inputStyle={{ height: '35px', width: '50px', textAlign: 'center' }}
                        value={vl_CuotaPagada} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>de:</label>
                    <InputNumber type="text" inputClassName='font-semibold'
                        inputStyle={{ height: '35px', width: '50px', textAlign: 'right' }}
                        value={vl_TotaltCuota} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={0} maxFractionDigits={0}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>T.Pago:</label>
                    <InputNumber type="text" inputClassName='font-semibold'
                        inputStyle={{ height: '35px', width: '90px', textAlign: 'right' }}
                        value={vl_TotalPagos} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                    />
                    <label className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Saldo:</label>
                    <InputNumber type="text" 
                        inputClassName='font-semibold'
                        inputStyle={{ height: '35px', width: '100px', textAlign: 'right' }}
                        value={vl_Saldo} locale="en-US"
                        readOnly mode="decimal" minFractionDigits={2} maxFractionDigits={2}
                    />
                    {/* </div> */}
                </div>

                <ProgressBar
                    min={0}
                    now={vl_CuotaPagada}
                    max={vl_TotaltCuota}
                    label={'Pagados ' + vl_CuotaPagada + ' de ' + vl_TotaltCuota}
                    variant={vl_CuotaPagada === vl_TotaltCuota ? 'success' : 'info'} >
                </ProgressBar>

            </div>

            <DataTable
                size="small"
                value={props.dataOrdenPago ?? []}
                dataKey="IdOrdenPago"
                resizableColumns
                responsiveLayout="scroll"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                selectionMode="single"
                scrollDirection="horizontal"
                style={{ maxWidth: '920px' }}
            >
                <Column field="IdOrdenPago" header="O/P"
                    // body={template_OrdenPagoDetalle} // x ahora no hay detalle de O/P
                    style={{ maxWidth: '50px' }}
                    bodyClassName="flex justify-content-center"  ></Column>
                <Column field="Fecha" header="Fecha" style={{ maxWidth: '160px' }}
                    bodyClassName="flex justify-content-center text-sm" ></Column>
                <Column field="Importe" header="Importe" style={{ maxWidth: '90px' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end "></Column>
                <Column field="Descuento" header="Dscto." style={{ maxWidth: '70px' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end "></Column>
                <Column field="Total" header="Total" style={{ maxWidth: '90px' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end "></Column>
                <Column field="NumeroCuotas" header="Cant. Ses." style={{ maxWidth: '50px', align: 'end' }}
                    headerClassName="flex justify-content-center white-space-normal  "
                    bodyClassName="flex justify-content-center font-bold"
                ></Column>
                <Column field="" header="C.Pago" body={template_btnPlanPago} style={{ maxWidth: '70px' }}
                    bodyClassName="flex justify-content-center text-cyan-800 font-bold"
                ></Column>
                <Column field="Pagos" header="Pagos" colSpan={5} style={{ maxWidth: '120px', align: 'end' }}
                    headerClassName="flex justify-content-center"
                    bodyClassName="flex justify-content-center"></Column>
                {/* <Column field="" header="" body={template_btnDetallePago} style={{ maxWidth: '60px' }}
                    bodyClassName="flex justify-content-center text-cyan-800 font-bold"
                ></Column> */}                
                <Column field="Saldo" header="Saldo" style={{ maxWidth: '90px' }}
                    headerClassName="flex justify-content-end"
                    bodyClassName="flex justify-content-end text-cyan-800 font-bold"
                    body={template_NumberCurrency} > </Column>
                <Column field="NumeroCuotaPago" header="Cuotas" body={template_btnDetalleCuotas} style={{ minWidth: '4rem' }} 
                    bodyClassName="flex justify-content-center"
                ></Column>    
                <Column field="Estado" header="Estado" body={template_statusPago} style={{ maxWidth: '170px' }}  ></Column>

            </DataTable>

            <PlanPagos
                oplanPago={o_planPago}
                dataPlanPagos={dataPlanPagos}
            />
            <DetallePagos
                o_detallePago={o_detallePago}
                dataDetallePagos={dataDetallePagos}
            />            
            <>
                {
                    isMounted.current && <DetalleCuotas
                        isOpen={isOpenModal}
                        isHide={closeModal}
                        setIsMounted={setIsMounted}
                        IdOrdenTerapia={0}                        
                        TipoRegistro={'A'}
                        IdOrdenPago={vl_IdOrdenPago}
                        IdRegistro={0} 
                    />
                }
            </>

        </>
    )
}
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { fetchSinToken } from '../../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../../helpers/functions';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TitleForm from '../../util/TitleForm';
import { FooterGridNumber } from '../../util/FooterGrid';
import ProgressBarUtil from '../../util/ProgressBarUtil';

const ProcesarCierre = ({ ...props }) => {

  //console.log(props);
  const [vl_IdCaja, setIdCaja] = useState<any>(0);
  const [vl_isMode, setIsMode] = useState<any>('I');
  const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
  const [b_cerrado, setEstadoCierre] = useState<boolean>(false);
  const [dataMovimientos, setDataMovimientos] = useState<any>([]);
  //inputs
  const [vl_FechaCierre, setFechaCierre] = useState<any>(new Date());
  const [vl_TotalPago, setTotalPago] = useState<any>('0.00');
  const [vl_Nota, setNota] = useState<any>('CIERRE MANUAL');

  //Messages
  const toast = useRef<any>(null);
  const toastConfirm = useRef<any>(null);

  useEffect(() => {
    // console.log(props.isMode);
    setIsMode(props.isMode);
    setNota('CIERRE MANUAL');
    pa_get_caja_detalle_pago('M');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseShowConfirmSave = () => {
    setVisibleConfirmDialog(false);
  }

  const pa_get_caja_valida_cierre = async (IN_opcion: any) => {
    const param = {
      "vp_opcion": IN_opcion,
      "vp_Fecha": setIntlDateTimeFormat(vl_FechaCierre),
      "vp_IdSucursal": localStorage.getItem('authIdSucursal')
    }

    if (vl_FechaCierre === '' || vl_FechaCierre === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar una fecha', life: 8000 });
      return;
    }
    setEstadoCierre(true); //default OPEN
    const response = await fetchSinToken('caja/pa_get_caja_detalle_pago', param, 'POST');
    const resp = await response.json();
    const record = resp.data;
    setIdCaja(record[0].IdCaja);
    setEstadoCierre(record[0].sql_error);
  }

  const pa_get_caja_detalle_pago = async (IN_opcion: any) => {
    const param = {
      "vp_opcion": IN_opcion,
      "vp_Fecha": setIntlDateTimeFormat(vl_FechaCierre),
      "vp_IdSucursal": localStorage.getItem('authIdSucursal')
    }
    if (vl_FechaCierre === '' || vl_FechaCierre === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar una fecha', life: 8000 });
      return;
    }
    const response = await fetchSinToken('caja/pa_get_caja_detalle_pago', param, 'POST');
    const resp = await response.json();
    const record = resp.data;    
    setDataMovimientos(record);
    getTotalPagos(record);    
    // VALIDA CIERRE DE CAJA
    pa_get_caja_valida_cierre('V');

  }

  const getTotalPagos = (data: any) => {
    let TotalPago: any = 0;
    data.forEach(
      function (currentValue: any) {
        // TotalPago = TotalPago + parseFloat(currentValue.TotalPago);    
        TotalPago = TotalPago + parseFloat(currentValue.tpd_Total);
      }
    );
    setTotalPago(TotalPago);
  }

  const pa_get_cierre_caja_rpte = async (vp_IdCaja: any) => {
    console.log(vp_IdCaja)
    if (vp_IdCaja === '' || vp_IdCaja === 0) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Cerrar caja para imprimir ', life: 8000 });
      return;
    }
    const vl_IdSucursal = localStorage.getItem('authIdSucursal');
    const endpoint = 'reports/reportecierreCaja/?IdCaja=' + vp_IdCaja + '&IdSucursal=' + vl_IdSucursal + '&Fecha=' + setIntlDateTimeFormat(vl_FechaCierre);
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }
  // report to EXCEL
  const pa_get_reporte_movimientos = async () => {
    // alert('Not implemented..');
    // console.log(vl_FechaCierre);
    if (vl_FechaCierre === '' || vl_FechaCierre === null) {
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: 'Ingresar una fecha', life: 8000 });
      return;
    }
    const vl_IdSucursal = localStorage.getItem('authIdSucursal');
    const endpoint = 'caja/pa_get_reporte_movimientos/?vp_FechaCierre=' + setIntlDateTimeFormat(vl_FechaCierre) + '&vp_opcion=M&vp_IdSucursal=' + vl_IdSucursal;
    const baseUrl = process.env.REACT_APP_API_URL;
    const url = `${baseUrl}/${endpoint}`;
    window.open(url);
  }

  const onValidarCampos = () => {
    let vl_valida = true;
    let vl_message = '';

    if ((!Number(vl_TotalPago))) {
      vl_valida = false;
      vl_message = 'Total no valido';
    } else if (String(vl_FechaCierre).trim() === '' || vl_FechaCierre === null) {
      vl_valida = false;
      vl_message = 'Ingresar fecha';
    }

    return [vl_valida, vl_message];
  }

  const setProcesar = () => {
    const IsValidaCampos = onValidarCampos();
    const Isvalido = IsValidaCampos[0];
    const IsMessage = IsValidaCampos[1];
    if (!Isvalido) {
      toastConfirm.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 4000 });
      return;
    }
    // si no hay campos invalidos procesar...    
    setVisibleConfirmDialog(true);
  }

  const onProcesarCierre = async () => {


    setVisibleConfirmDialog(false);
    const param = {
      vp_ACTION: vl_isMode,
      vp_fecha: setIntlDateTimeFormat(vl_FechaCierre),
      vp_IdSucursal: localStorage.getItem('authIdSucursal'),
      vp_IdUsuario: localStorage.getItem('authIdUsuario'),
      vp_nota: vl_Nota,
      vp_Total: vl_TotalPago
    };

    // console.log(param);
    // return;
    setProgressBar(true);
    const response = await fetchSinToken('caja/pa_procesar_cierre_caja', param, 'POST');
    const resp = await response.json();
    const record = resp.data[0];
    if (record) {
      //console.log(record);
      const message = record.sql_message;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 6000 });
      //eventos de la pantalla anterior
      if (record.sql_error === 0) {
        pa_get_cierre_caja_rpte(record.IdCaja);
        props.setIsMounted(false);
        props.isHide();
        props.pa_get_cierre_caja();
      }

    } else {
      const message =
        'Code: ' + resp.data.code +
        'Errno: ' + resp.data.errno +
        'Sql: ' + resp.data.sql +
        'SqlState: ' + resp.data.sqlState +
        'SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 3000 });
    }
    setTimeout(() => {
      setProgressBar(false);
    }, 200);
    // console.log(resp)
  }

  // const RenderFooter = () => {
  //   return (
  //     <div className="absolute bottom-0 mb-2 p-1">
  //       <Button label="Procesar" icon="pi pi-save" onClick={() => setProcesar()} />
  //       <Button label="Reporte" icon="pi pi-print" hidden={!b_cerrado} className="p-button-text" onClick={() => pa_get_cierre_caja_rpte(0)} />
  //       <Button label="" icon="pi pi-times" className='p-button-danger' onClick={() => {
  //         props.setIsMounted(false);
  //         props.isHide();
  //       }} />
  //     </div>
  //   );
  // }

  // const footer = () => {
  //   // let vl_Total: any = 0;
  //   // admision.forEach(
  //   //   function (currentValue: any) {
  //   //     vl_Total = vl_Total + parseFloat(currentValue.CostoTotal);
  //   //   }
  //   // );
  //   return (
  //     <>
  //       <FooterGridNumber Total={vl_TotalPago} />
  //     </>
  //   );
  // }

  const [b_ProgressBar, setProgressBar] = useState<boolean>(false);
  const renderFooter = () => {
    return (
      <div>
        <Button label="Procesar" icon="pi pi-save" onClick={() => setProcesar()} />
        <Button label="Rpte Cierre" icon="pi pi-print" hidden={!b_cerrado} className="p-button-text" onClick={() => pa_get_cierre_caja_rpte(vl_IdCaja)} />
        <Button label="Exportar Mov." icon="pi pi-file-excel" className="p-button-text" onClick={() => pa_get_reporte_movimientos()} />
        <Button label="Cerrar" icon="pi pi-times" className='p-button-danger' onClick={() => {
          props.setIsMounted(false);
          props.isHide();
        }} />
      </div>
    );
  }

  const renderHeader = () => {
    return (
      <>
        <TitleForm title={'Cierre de caja: Movimientos del dia'} />
      </>
    );
  }


  return (
    <>
      <Dialog
        position={'center'}
        header={renderHeader}
        modal draggable resizable closable showHeader maximizable
        visible={props.isOpen}
        footer={renderFooter}
        onHide={() => {
          props.setIsMounted(false);
          props.isHide();
        }}
      >
        <ProgressBarUtil
          ProgressBarVisible={b_ProgressBar}
          ProgressBarMessage='Please wait, loading...'
          setProgressBar={setProgressBar}
        />

        <div className="p-field p-grid mb-0">
          <div className='flex justify-content-between pb-0' >
            <div>
              <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Dia de cierre:</label>
              <Calendar value={vl_FechaCierre} style={{ width: '160px' }}
                onChange={(e: any) => setFechaCierre(e.value)} showIcon
                dateFormat="yy/mm/dd"
              />
              <Button 
                // label="Buscar"
                onClick={(e: any) => pa_get_caja_detalle_pago('M')}
                icon="pi pi-search"
                className="p-button-rounded ml-1 mr-4"
              />
            </div>
            <div>
              <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-1">
                <div className="shadow-8 font-italic bg-yellow-500 text-blue-900 border-round-bottom border-0 p-2">
                    Caja {b_cerrado ? ' cerrada #ID ': ' abierta'}
                  </div>
              </div>
            </div>

          </div>
        </div>
        <div className="p-field p-grid">
          <div className="p-field p-grid mt-1 mb-1">
            <div className="flex justify-content-end pb-0" >
              <div className='w-full'>
                <FooterGridNumber Title={'Total del dia:'} Total={vl_TotalPago} />
              </div>
            </div>
            <div className="w-full">
              <DataTable
                size="small"
                // footer={footer} 
                // header={footer} 
                style={{ height: '400px', width: '990px' }}
                value={dataMovimientos ?? []}
                dataKey="IdOrdenPago"
                resizableColumns
                responsiveLayout="scroll"
                // breakpoint="960px"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                scrollHeight="410px"
                scrollDirection='horizontal'
                paginator
                rows={20}
                //selectionMode="multiple"
                // selection={dataSelectedOPItems}
                onSelectionChange={
                  (e) => {
                    // setSelectedOPItems(e.value);
                  }
                }
              >
                <Column field="IdPago" header="#Recibo" bodyClassName="flex justify-content-center" ></Column>
                <Column field="Detalle" header="Detalle Movimiento" style={{ minWidth: '320px' }}  ></Column>
                <Column field="FechaPago" header="Fecha Pago" bodyClassName="flex justify-content-left" ></Column>
                <Column field="tpd_Total" header="Importe"
                  headerClassName="flex justify-content-end"
                  bodyClassName="flex justify-content-end text-green-600 font-bold"
                ></Column>
                <Column field="FormaPago" header="Forma Pago" bodyClassName="flex justify-content-left" ></Column>
                <Column field="ReferenciaPago" header="Ref." bodyClassName="flex justify-content-left" ></Column>
                <Column field="IdBanco" header="Banco" bodyClassName="flex justify-content-left" ></Column>
                <Column field="cta" header="Ingreso a" bodyClassName="flex justify-content-left" ></Column>
                <Column field="RefNumFacturaRecibo" header="N° Factura(ref)" ></Column>
                <Column field="NumeroHistoriaClinica" header="HC" ></Column>
                <Column field="Cliente" header="Cliente/Paciente" style={{ minWidth: '320px' }} ></Column>
              </DataTable>
            </div>
          </div>
        </div>

        <Toast ref={toast} position="center"></Toast>
        <Toast ref={toastConfirm} position="center"></Toast>

        <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
          message="¿Procesar cierre de caja?"
          header="Confirmar para continuar"
          icon="pi pi-exclamation-triangle"
          accept={onProcesarCierre}
          reject={handleCloseShowConfirmSave}
        />

      </Dialog>
    </>
  );
};
export default ProcesarCierre;

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';



export const DetallePagos = ({ ...props }) => {
    // console.log(props.dataDetallePagos);    
    // const vl_TotalInversion = props.dataDetallePagos[0]?.tpp_Importe;    
    const dataPagos: any = [];
    let vl_IdPago:any = 0;
    props.dataDetallePagos.forEach(
        function (currentValue: any) {
            if(parseFloat(vl_IdPago) !== parseFloat(currentValue.IdPago)){
                vl_IdPago = currentValue.IdPago;
                dataPagos.push( currentValue );
            }
            
        }
    );
    // console.log(dataPagos);

    const pa_get_recibo_pago_rpte = async (vp_IdPago: any) => {
        // const config = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
        const endpoint = 'reports/reciboPago/?IdPago=' + vp_IdPago;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }

    const template_ImprimirRecibo = (item: any) => {
        const vl_IdPago: any = item.IdPago;
        return (
            <>
                <Button
                    type="button"
                    className="p-button  "
                    icon="pi pi-book"
                    tooltip={`Imprimir recibo Nº ${vl_IdPago}`}
                    onClick={(e) => pa_get_recibo_pago_rpte(vl_IdPago)}
                />
            </>
        );
    }

    return (
        <>
            <OverlayPanel
                ref={props.o_detallePago}
                showCloseIcon={false}
                style={{ width: "850px" }}
            >
                <DataTable
                    // header="Detalle de pagos realizados"
                    size="small"
                    value={dataPagos ?? []}
                    dataKey="IdPago"
                    resizableColumns
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    selectionMode="single"
                >
                    <Column field="" header="" style={{ maxWidth: '70px' }} bodyClassName="flex justify-content-center" headerClassName="flex justify-content-center"
                        body={template_ImprimirRecibo}
                    >
                    </Column>
                    <Column field="IdPago" header="Nº Recibo" style={{ maxWidth: '90px' }} bodyClassName="flex justify-content-center" headerClassName="flex justify-content-center"  ></Column>
                    <Column field="FechaPago" header="Fecha Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-center" ></Column>
                    {/* <Column field="Detalle" header="Detalle Pago" style={{ align: 'end' }} headerClassName="flex justify-content-left" bodyClassName="flex justify-content-left"></Column> */}
                    <Column field="TotalPago" header="Total" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column>
                    <Column field="FormaPago" header="Forma Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column> 
                    <Column field="ReferenciaPago" header="Ref. Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column> 
                    <Column field="IdOrdenPago" header="Id Orden Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column> 

                </DataTable>

                <DataTable header="Detalle de pagos realizados"
                    size="small"
                    value={props.dataDetallePagos ?? []}
                    dataKey="IdPagoDetalle"
                    resizableColumns
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    showGridlines
                    stripedRows
                    scrollable
                    selectionMode="single"
                >
                    {/* <Column field="" header="" style={{ maxWidth: '70px' }} bodyClassName="flex justify-content-center" headerClassName="flex justify-content-center"
                        body={template_ImprimirRecibo}
                    ></Column> */}
                    <Column field="IdPago" header="Nº Rec." style={{ maxWidth: '70px' }} bodyClassName="flex justify-content-center" headerClassName="flex justify-content-center"  ></Column>
                    <Column field="FechaPago" header="F. Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-center" ></Column>
                    <Column field="Detalle" header="Detalle Pago" style={{ align: 'end' }} headerClassName="flex justify-content-left" bodyClassName="flex justify-content-left"></Column>
                    <Column field="tpd_Total" header="Importe" style={{ maxWidth: '90px' }} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end "></Column>
                    <Column field="FormaPago" header="Forma Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column>
                    <Column field="ReferenciaPago" header="Ref. Pago" style={{ maxWidth: '120px' }} bodyClassName="flex justify-content-left" ></Column>

                </DataTable>

                <Button
                    type="button"
                    className="p-button-rounded p-button-warning ml-1 mt-1 "
                    icon="pi pi-times"
                    label="Cerrar"
                    onClick={
                        (e) => {
                            props.o_detallePago.current?.hide()
                        }
                    }
                />
            </OverlayPanel>
        </>
    )
}
import { Button } from 'primereact/button'

const HeaderForm = ({ ...prop }) => {
    return (
        <>
            {/* header */}
            <div className="bg-indigo-500 p2 border-bottom-2 text-white">
                <div className="col mt-0 ml-0 text-base font-bold  font-italic h-5rem" style={{ textAlign: 'start', minWidth: '15rem' }} >
                    <label className='pb-1'> {prop.TitleForm} </label><br />
                    <label className='pb-1'> Paciente: {prop.Paciente} </label><br />
                    <label> Nº HC. {prop.NumeroHistoriaClinica}, Edad: {prop.Edad} años  </label>
                </div>
            </div>

            {/* sub-header */}
            <div className='flex justify-content-between pb-2 border-gray-500 pt-1 border-bottom-2  ' >
                <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-2 pl-2 ">
                    <span className="p-buttonset">
                        <Button icon="pi pi-arrow-left" label='Volver'
                            className="p-button-rounded p-button-warning p-button-raised"
                            onClick={() => prop.linkGoBack()}
                        />
                    </span>
                </div>
                <div className='flex justify-content-between pb-2 pt-2' >
                    {/* <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-1">
                    </div> */}
                    <div className="flex flex-wrap align-items-center justify-content-start pb-1 pt-0  ">
                        <span className="p-buttonset">
                            <Button icon="pi pi-save" label='Grabar' className="p-button-sm p-button p-button-raised" onClick={() => prop.setOnClickButtonSave()} />
                            <Button icon="pi pi-times" label='Cerrar' className="p-button-sm p-button-danger ml-1"
                                onClick={() => prop.link_form_close()}
                            />
                        </span>

                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderForm
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
// import TitleForm from '../../util/TitleForm';

export const Filter = ({ ...props }) => {

    return (
        <>            
            <div className="p-field p-grid mb-2 mt-0">
                <div className="p-col">
                    <label className="p-col-fixed mr-2 font-bold " style={{ width: '107px', textAlign: 'end' }}>N° HC:</label>
                    <InputText type="text" style={{ width: '120px', textAlign: 'center' }} aria-describedby="username1-help"
                        maxLength={8}
                        value={props.NumeroHistoriaClinica}
                        disabled={props.disabledNumeroHistoriaClinica}
                        ref={props.inputNumeroHistoriaClinicaRef}
                        onChange={(e: any) => props.setInputValNumeroHistoriaClinica(String(e.currentTarget.value).toUpperCase())}
                        onKeyPress={(e: any) => {
                            if (e.key === 'Enter') {
                                props.getBuscarCliente(e);
                            }
                        }}
                    />
                    <Button label="" onClick={(e: any) => props.getBuscarCliente(e)}                        
                        icon="fas fa-search" className="p-button ml-1"
                    />
                    <Button label="" tooltip='Busqueda de Pacientes'
                        onClick={() => props.handleClickBuscar()}
                        icon="pi pi-user-plus" className="p-button-rounded mt-0 p-button-warning ml-2 "
                    />
                    <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '150px', textAlign: 'end' }}>Fecha:</label>
                    <Calendar value={props.FechaTerapia} style={{ width: '135px', height: '30px' }}
                        onChange={(e: any) => props.setInputValFechaAtencion(e.value)} minDate={new Date()}
                        dateFormat="yy/mm/dd"
                    />
                    <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '60px', textAlign: 'end' }}>Hora:</label>
                    <Calendar value={props.HoraTerapia} style={{ width: '70px', height: '30px' }}
                        onChange={(e: any) => props.setInputValHoraAtencion(e.value)} showTime minDate={new Date()}
                        dateFormat="hh:mm" timeOnly
                    />
                    <InputText type="hidden" value={props.IdHistoriaClinica} />
                    <InputText type="hidden" value={props.IdRegistro} />
                    <InputText type="hidden" value={props.IdOrdenTerapia} />

                </div>
            </div>
        </>
    )
}


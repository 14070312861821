import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';


export const DatoPaciente = ({ ...props }) => {

    return (
        <>
            <div className="p-field p-grid mb-1">
                <div className="p-col">
                    <InputText type="hidden" value={props.IdCliente} />
                    <label htmlFor="" className="p-col-fixed mr-1 ml-4 font-bold " style={{ width: '100px', textAlign: 'end' }}>Nombre:</label>
                    <InputText type="text" style={{ height: '30px', width: '200px' }}
                        value={props.Nombre}
                        readOnly
                    />
                    <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '100px', textAlign: 'end' }}>Apellidos:</label>
                    <InputText type="text" style={{ height: '30px', width: '150px' }} placeholder='Paterno'
                        value={props.ApPaterno}
                        readOnly
                    />
                    <label htmlFor="" className="p-col-fixed mr-0 font-bold " style={{ width: '10px', textAlign: 'center' }}></label>
                    <InputText type="text" style={{ height: '30px', width: '150px' }} placeholder='Materno'
                        value={props.ApMaterno}
                        readOnly
                    />
                </div>
            </div>

            <div className="p-col mb-1">
                <label htmlFor="" className="p-col-fixed mr-1 ml-4 font-bold " style={{ width: '100px', textAlign: 'end' }}>Nº Doc.:</label>
                <InputText type="text" style={{ height: '30px', width: '80px', textAlign: 'center' }}
                    value={props.IdTipoDocumento}
                    readOnly
                />
                <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ width: '.1px', textAlign: 'end' }}> </label>
                <InputText type="text" style={{ height: '30px', width: '116px', textAlign: 'center' }}
                    value={props.NumeroDocumento}
                    readOnly
                />
                <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Nº H.C:</label>
                <InputText type="text" style={{ height: '30px', width: '110px', textAlign: 'center' }}
                    value={props.NumeroHistoriaClinica}
                    readOnly
                />

                <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Edad:</label>
                <InputText type="text" style={{ height: '30px', width: '50px', textAlign: 'center' }}
                    value={props.Edad}
                    readOnly
                />
                <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold " style={{ textAlign: 'end' }}>Sexo:</label>
                <RadioButton value="M" name="btn_sexo"
                    checked={props.Sexo === "M"}
                    disabled
                />
                <label htmlFor="cb1" className="p-checkbox-label ml-1 mr-2 ">M</label>
                <RadioButton value="F" name="btn_sexo"
                    checked={props.Sexo === "F"}
                    disabled
                />
                <label htmlFor="cb2" className="p-checkbox-label ml-1">F</label>
            </div>
        </>
    );
}


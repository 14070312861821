import React, {
  useState,
  useEffect,
  useRef,
  useContext
} from 'react';

// import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Form, Row } from 'react-bootstrap';

import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { fetchConToken } from '../../../helpers/fetch';
import { setIntlDateTimeFormat } from '../../../helpers/functions';
import { AppContext01 } from '../../../context/AppContext';
import AdmisionForm from '../../components/admision/AdmisionForm';
import useModal from '../../../context/WindowsOpen';
import { FooterGridNumber } from '../../components/util/FooterGrid';
import BuscadorClientes from '../../components/util/BuscadorClientes';
// import { AppAuthContext } from '../../../context/AppAuthContext';
// import { useNavigate } from 'react-router-dom';

const AdmisionView = () => {
  // console.log('AdmisionView');
  // console.log(prop);
  //controla el estado de renderizacion: AdmisionForm 
  const [openedAdmisionForm, setOpenedAdmisionForm] = useState(false);
  const [valFechaDesde, setvalFechaDesde] = useState<any>(new Date());
  const [valFechaHasta, setvalFechaHasta] = useState<any>(new Date());
  const [valNumeroDocumento, setvalNumeroDocumento] = useState<any>('');
  const [vl_NumeroHistoria, setNumeroHistoria] = useState<any>('');
  const [admision, setAdmision] = useState([]);

  //PARA FORMULARIO REGISTRO DE SERVICIOS(ADMISION)
  const [isMode, setMode] = useState('I');
  const [selectedGrid1, setSelectedGrid1] = useState<any>([]);
  const isMounted = useRef(false);

  // Para open/close formularios
  const [isOpenModal, openAdmisionModal, closeAdmisionModal] = useModal();
  const [isOpenModal1, openModal1, closeModal1] = useModal(); //buscador
  // mensaje
  const toast = useRef<any>(null);


  const handleClickNew = (e: any) => {
    setMode('I');
    setOpenedAdmisionForm(true);
    openAdmisionModal();
  }
  const handleClickEdit = (e: any) => {
    // console.log(selectedGrid1);
    setMode('U');
    if (Object.keys(selectedGrid1).length <= 0) {
      // console.log('seleccionar un registro');
      const message = 'seleccionar un registro';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    setOpenedAdmisionForm(true);
    openAdmisionModal();
  }

  const handleClickAnular = (e: any) => {
    setMode('A');
    if (Object.keys(selectedGrid1).length <= 0) {
      const message = 'Seleccionar un registro';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    // console.log(selectedGrid1.EstadoRegistro);
    if (selectedGrid1.EstadoRegistro === 'A') {
      const message = '¡Registro ya se fue anulado!';
      toast.current.show({ severity: 'info', summary: 'Aviso', detail: message, life: 3000 });
      return;
    }
    setOpenedAdmisionForm(true);
    openAdmisionModal();
  }

  // Agrega titulo a la barra de navegacion superior      
  const { showmenuTitle } = useContext(AppContext01);
  const handleSetTitle = () => {
    showmenuTitle('/Admisión/Registro servicios');
  }

  useEffect(() => {
    // console.log('useEffect AdmisionView');
    handleSetTitle();
    getDataAdmision();
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []);

  // const { logout } = useContext(AppAuthContext);
  // const navigate = useNavigate();

  const getDataAdmision = async () => {

    const param = {
      "vp_NumeroDocumento": valNumeroDocumento,
      "vp_NumeroHistoria": vl_NumeroHistoria,
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      "vp_IdSucursal": localStorage.getItem('authIdSucursal')

    }
    // const response = await fetchSinToken('admision/getAdmision', param, 'POST');
    const response = await fetchConToken('admision/getAdmision', param, 'POST');
    const resp = await response.json();
    // console.log(resp);
    if (resp.data.errno) {
      const message =
        ' Code: ' + resp.data.code +
        ' Errno: ' + resp.data.errno +
        ' Sql: ' + resp.data.sql +
        ' SqlState: ' + resp.data.sqlState +
        ' SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, sticky: true });
    } else {
      if (resp.data.length === 0) 
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No results found', sticky: true });      
      setAdmision(resp.data);
    }

  }
  // 2 OPCION DE BUSQUEDA 
  const getDataAdmision02 = async (IN_NumeroDocumento: any) => {
    const param = {
      "vp_NumeroDocumento": IN_NumeroDocumento,
      "vp_NumeroHistoria": "",
      "vp_FechaDesde": setIntlDateTimeFormat(valFechaDesde),
      "vp_FechaHasta": setIntlDateTimeFormat(valFechaHasta),
      "vp_IdSucursal": localStorage.getItem('authIdSucursal')
    }
    // const response = await fetchSinToken('admision/getAdmision', param, 'POST');
    const response = await fetchConToken('admision/getAdmision', param, 'POST');
    const resp = await response.json();
    if (resp.data.errno) {
      const message =
        ' Code: ' + resp.data.code +
        ' Errno: ' + resp.data.errno +
        ' Sql: ' + resp.data.sql +
        ' SqlState: ' + resp.data.sqlState +
        ' SqlMessage: ' + resp.data.sqlMessage;
      toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, sticky: true });
    } else {
      if (resp.data.length === 0) {
        toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No results found', sticky: true });
      }
      setAdmision(resp.data);
    }

  }

  const setBuscar = () => {
    getDataAdmision();
  }

  const AdmisionFormNew = () => {
    return (
      <AdmisionForm
        isOpen={isOpenModal}
        isHide={closeAdmisionModal}
        isData={selectedGrid1}
        isMode={isMode}
        setBuscar={setBuscar}
        setOpenedAdmisionForm={setOpenedAdmisionForm}
      />
    )
  };

  const formatCurrency = (value: number) => {
    return value.toLocaleString(
      'en-US', {
      style: 'currency',
      currency: 'USD'
    }
    );
  }
  const priceBodyTemplate = (row: any) => {
    return formatCurrency(row.CostoTotal);
  }


  const footer = () => {
    let vl_Total: any = 0;
    admision.forEach(
      function (currentValue: any) {
        vl_Total = vl_Total + parseFloat(currentValue.CostoTotal);
      }
    );
    return (
      <>
        <FooterGridNumber Total={vl_Total} />
      </>
    );
  }

  // new buscador clientes
  const handleClickBuscar = (e: any) => {
    isMounted.current = true;
    openModal1();
  }
  const setIsMounted = (val: boolean) => {
    isMounted.current = val;
  }
  const BuscadorClientesForm = () => {
    return (
      <BuscadorClientes
        isOpen={isOpenModal1}
        isHide={closeModal1}
        getDataCliente={getDataCliente}
        setIsMounted={setIsMounted}
      />
    );
  }
  const getDataCliente = (data: any) => {
    // console.log(data.NumeroDocumento);    
    getDataAdmision02(data.NumeroDocumento);
  }

  return (
    <>
      <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
      <React.Fragment>{openedAdmisionForm && <AdmisionFormNew />}</React.Fragment>

      <div className="card p-1 mt-0" style={{ marginTop: '.1em' }}  >
        <div className="card-container gray-container">
          <div className="block bg-gray-10 font-bold text-end p-0 mb-2 flex justify-content-end">
            <span className="p-buttonset">
              <Button
                label="Nuevo"
                icon="pi pi-file"
                onClick={(e) => handleClickNew(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Editar"
                icon="pi pi-pencil"
                // tooltip='Editar registro seleccionado'
                onClick={(e) => handleClickEdit(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
              <Button
                label="Anular"
                icon="pi pi-trash"
                // tooltip='Editar registro seleccionado'
                onClick={(e) => handleClickAnular(e)}
                className="p-button-sm p-button-primary p-button-raised"
              />
            </span>
          </div>
          <div className="block bg-gray-100 filter-view font-bold text-left p-1 mb-1 pt-2 ">
            <div className="p-field p-grid mb-1">
              <div className="p-col">
                <label htmlFor="" className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Del:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2010:2030"
                  maxDate={new Date()}
                  value={valFechaDesde}
                  onChange={(e) => setvalFechaDesde(e.value)}
                // onChange={
                //     (e) => setvalFechaDesde(String(e.value))                    
                // }
                />
                <label htmlFor="" className="p-col-fixed mr-1 ml-1 font-bold " style={{ textAlign: 'end' }}>Al:</label>
                <Calendar style={{ width: '150px' }} className="p-inputtext-sm" icon="pi pi-calendar-plus"
                  dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator yearRange="2010:2030"
                  maxDate={new Date()}
                  value={valFechaHasta}
                  onChange={(e) => setvalFechaHasta(e.value)}
                />
                <label htmlFor="" className="p-col-fixed mr-1  ml-1 font-bold " style={{ textAlign: 'end' }}>Nº DNI:</label>
                <InputText type="text" style={{ width: '100px' }} className="p-inputtext-sm" autoFocus
                  value={valNumeroDocumento} maxLength={12}
                  onChange={(e) => setvalNumeroDocumento(String(e.currentTarget.value))}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      setBuscar();
                    }
                  }}
                />
                <label htmlFor="" className="p-col-fixed mr-1  ml-1 font-bold " style={{ textAlign: 'end' }}>Nº HC:</label>
                <InputText type="text" style={{ width: '100px' }} className="p-inputtext-sm"
                  value={vl_NumeroHistoria} maxLength={8}
                  onChange={
                    (e) => {
                      // console.log(String(e.currentTarget.value));
                      setNumeroHistoria(String(e.currentTarget.value));
                      // console.log(vl_NumeroHistoria);
                    }
                  }
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      setBuscar();
                    }
                  }}
                />

                <Button label="Buscar"
                  onClick={(e) => setBuscar()}
                  icon="fas fa-search" className="p-button ml-2" />

                <Button label="" tooltip='Busqueda de Pacientes'
                  onClick={(e: any) => handleClickBuscar(e)}
                  icon="pi pi-user-plus" className="p-button-rounded mt-0 p-button-warning ml-2 "
                />
              </div>
            </div>

          </div>
          <div className="block bg-gray-100 font-bold text-center p-1  mb-1">
            <Form.Group as={Row} style={{ marginTop: '.1em' }}>
              <DataTable size="small" resizableColumns footer={footer}
                responsiveLayout="scroll"
                columnResizeMode="fit"
                showGridlines
                stripedRows
                scrollable
                value={admision}
                dataKey="IdAdmision"
                selectionMode="single"
                selection={selectedGrid1}
                onSelectionChange={e => setSelectedGrid1(e.value)}
                scrollHeight="400px"
                paginator
                rows={20}
              >
                <Column field="IdAdmision" header="Nº" style={{ maxWidth: '60px' }}  ></Column>
                <Column field="Cliente" header="Cliente/Paciente"></Column>
                <Column field="NumeroDocumento" header="Nº Documento" style={{ maxWidth: '150px' }} headerClassName="flex justify-content-center" ></Column>
                <Column field="NumeroHistoriaClinica" header="Nº HC" style={{ maxWidth: '100px' }} headerClassName="flex justify-content-center" ></Column>
                <Column field="FechaNacimiento_1" header="Fecha Nac." style={{ maxWidth: '100px' }} bodyClassName="flex justify-content-center" ></Column>
                <Column field="Edad" header="Edad" style={{ maxWidth: '70px' }} ></Column>
                <Column field="Descripcion" header="Servicio"></Column>
                <Column field="CostoTotal" header="Costo" style={{ maxWidth: '90px', alignSelf: 'flex-end' }} body={priceBodyTemplate} headerClassName="flex justify-content-end" bodyClassName="flex justify-content-end"
                ></Column>
                <Column field="EstadoRegistro_1" header="Estado" style={{ maxWidth: '90px', alignSelf: 'flex-end' }}
                // body={statusItemTemplate} 
                >
                </Column>
              </DataTable>
            </Form.Group>
          </div>
        </div>
      </div>
      <Toast ref={toast} position="top-center"></Toast>

    </>
  )
}

export default AdmisionView

import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchConToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import ProgressBarUtil from '../util/ProgressBarUtil';
// import { InputText } from 'primereact/inputtext';

const AuthResetPassword = () => {
    const params = useParams();

    const [vl_PasswordNew, setPasswordNew] = useState<any>('');
    const [vl_PasswordNewConfirm, setPasswordNewConfirm] = useState<any>('');
    const vl_token = params.token;
    const vl_IdRequest = params.id;
    const [b_ProgressBar, setProgressBar] = useState<boolean>(false);
    const vl_toast_ref = useRef<any>(null);

    // const [vl_countdown, setCountdown] = useState<any>('');
    

    const navigate = useNavigate();

    const header = <h6>Elige una contraseña</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Sugerencias</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>Al menos una minúscula</li>
                <li>Al menos una mayúscula</li>
                <li>Al menos una numérica</li>
                <li>Un mínimo de 8 caracteres</li>
            </ul>
        </React.Fragment>
    );

    


    useEffect(() => {
        // Configuración inicial de la cuenta regresiva
    // var countdownDate = new Date("2023-03-26T20:58:00").getTime(); // fecha y hora del evento
    // var countdownInterval = setInterval(function () {
    //     // Obtenemos la fecha y hora actual
    //     var now = new Date().getTime();
    //     // Calculamos la diferencia entre la fecha del evento y la fecha actual
    //     var distance = countdownDate - now;
    //     // Calculamos los días, horas, minutos y segundos restantes
    //     var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //     var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    //     // Mostramos los días, horas, minutos y segundos restantes en la página web
    //     // document.getElementById("countdown").innerHTML = days + "d " + hours + "h "
    //     setCountdown(days + "d " + hours + "h " + "m " + minutes + ' s '+ seconds);            
    //     // Si la cuenta regresiva ha terminado, detenemos el intervalo
    //     if (distance < 0) {
    //         clearInterval(countdownInterval);
    //         // document.getElementById("countdown").innerHTML = "¡El evento ha comenzado!";
    //         setCountdown("¡Expiro la solicitud!");
    //     }
    // }, 1000); // Actualizamos la cuenta regresiva cada segundo
        pa_valida_token();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pa_valida_token = async () => {
        const param = {
            vp_IdRequest: vl_IdRequest,
            vp_token: vl_token
        }
        setProgressBar(true);
        const response = await fetchConToken('auth/pa_valida_token', param, 'POST');
        const resp = await response.json();
        setProgressBar(false);
        if (resp.data.errno === 1) {
            navigate('/account/authResetPasswordExpire');
        }

    }

    const handleEnviar = async (e: any) => {
        e.preventDefault();
        const sha1 = require('js-sha1');
        const param = {
            vp_newPassword: sha1(vl_PasswordNew),
            vp_passwordNewConfirm: sha1(vl_PasswordNewConfirm),
            vp_IdRequest: vl_IdRequest,
            vp_token: vl_token
        }
        if (String(sha1(vl_PasswordNew)).trim().length === 0) {
            const message = 'Ingresa la nueva contraseña';
            vl_toast_ref.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
            return;
        }
        if (String(sha1(vl_PasswordNewConfirm)).trim().length === 0) {
            const message = 'Confirma la nueva contraseña';
            vl_toast_ref.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
            return;
        }
        setProgressBar(true);
        const response = await fetchConToken('auth/reset_password', param, 'POST');
        const resp = await response.json();
        // console.log(resp);
        setProgressBar(false);
        vl_toast_ref.current.show({ severity: resp.data.errno === 1 ? 'error' : 'success', summary: '::PSISABE', detail: resp.data.sqlMessage, life: 8000 });
        if (resp.data.errno === 0) {
            navigate('/login');
        }

    }

    return (
        <>
            <ProgressBarUtil
                ProgressBarVisible={b_ProgressBar}
                ProgressBarMessage='Please wait, loading...'
                setProgressBar={setProgressBar}
            />
            <Toast ref={vl_toast_ref} position="top-center"></Toast>
            <div className="wrapper d-flex flex-column min-vh-100 bg-gradient-indigo text-white">
                <div className='flex flex-wrap justify-content-center pb-2 m-2 ' >
                    <div className="flex justify-content-between ">
                        <div>
                            <nav className="flex justify-content-center p-4">
                                <img src="../../../apple-touch-icon.png" alt="" height={'140px'} />
                            </nav>
                        </div>
                    </div>
                </div>
                <main className="form-passwor">
                    <form onSubmit={handleEnviar} autoComplete="off" >
                        <div className='flex flex-column justify-content-center p-1 m-2 '>
                            <div className="field">
                                <label className='font-base text-xl text-pink-500 flex justify-content-center' >Reset Password</label>
                                {/* <InputText type="text" style={{ width: '18rem' }} className="font-semibold text-normal text-left bg-gray-200"
                                        value={vl_countdown} readOnly
                                    /> */}
                            </div>
                            <div className="field text-base " >
                                <label>Seguridad de la contraseña:</label>
                                <label>
                                    Utiliza al menos 8 caracteres. No uses una contraseña demasiado obvio.
                                </label>
                            </div>
                            <div className="field p-1">
                                <label htmlFor="username">Contraseña nueva</label>
                                <Password autoComplete="off" value={vl_PasswordNew} onChange={(e) => setPasswordNew(e.target.value)} header={header} toggleMask footer={footer}
                                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" aria-describedby="username-help"
                                />
                            </div>
                            <div className="field p-1">
                                <label htmlFor="username">Confirma la nueva contraseña</label>
                                <Password autoComplete="off" value={vl_PasswordNewConfirm} onChange={(e) => setPasswordNewConfirm(e.target.value)} header={header} toggleMask footer={footer}
                                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" aria-describedby="username-help">
                                </Password>
                            </div>
                            <div className="field flex justify-content-center">
                                <button className="w-70 btn btn-lg btn-primary" type="submit">Restablecer Contraseña</button>
                            </div>

                        </div>
                    </form>
                </main>

            </div>
        </>
    )
}

export default AuthResetPassword;
import { AuthData } from '../interfaces/AuthInterface';

type AppAuthAction =
    | { type: 'login', payload: { state: boolean } }
    // | { type: 'login', payload: { data: [] } }
    | { type: 'logout', payload: { state: boolean }}
    | { type: 'setData', payload: { data: [] } }


export const AppAuthReducer = (state: AuthData, action: AppAuthAction): any => {

    switch (action.type) {
        case 'login':            
            return {
                ...action.payload,
                logged: action.payload.state
            }
        // case 'login':
        //     return {
        //         ...action.payload,
        //         logged: true,
        //         data: action.payload
        //     }

        case 'logout':
            return {
                logged: false
            }
        default:
            return state;
    }

}

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { InputSwitch } from 'primereact/inputswitch';
import { fetchSinToken } from '../../../helpers/fetch';
import { Toast } from 'primereact/toast';
import { getClaseName, setIntlDateTimeFormat } from '../../../helpers/functions';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { SelectButton } from 'primereact/selectbutton';
import { Slider } from 'primereact/slider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Knob } from 'primereact/knob';
import { Tag } from 'primereact/tag';
import ProgressBarUtil from '../../components/util/ProgressBarUtil';
import PacienteInfo from '../../components/paciente/PacienteInfo';
import HeaderOptions from '../../components/paciente/HeaderOptions';
import useModal from '../../../context/WindowsOpen';
import BuscadorClientes from '../../components/util/BuscadorClientes';
import '../../../assets/scss/pacienteTimeline.scss';
import Axios from 'axios';
import fileDownload from 'js-file-download';
import { ScrollTop } from 'primereact/scrolltop';
import { AppContext01 } from '../../../context/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Fieldset } from 'primereact/fieldset';
import { Tooltip } from 'primereact/tooltip';
import { Rating } from 'primereact/rating';
import { Sidebar } from 'primereact/sidebar';

const HistoriaClinicaView = () => {

    const params = useParams();
    // let vl_activeIndex: any = params.activeIndex ? parseInt(params.activeIndex) : 0;
    const [vl_activeIndex, setActiveIndex] = useState<any>(params.activeIndex ? parseInt(params.activeIndex) : 0);
    const [vl_IdHistoriaClinica, setIdHistoriaClinica] = useState<any>(0); // int NOT NULL AUTO_INCREMENT,    
    const [vl_NumeroHistoriaClinica, setNumeroHistoriaClinica] = useState<any>(params.id ? params.id : new Date().getFullYear() + '0000'); //varchar(8) NOT NULL COMMENT 'NUMERO DE HISTORIA CLINICA',
    const [vl_IdCliente, setIdCliente] = useState<any>(0); //int DEFAULT NULL COMMENT 'FK TB_CLIENTE',
    const [vl_FechaAfiliacion, setFechaAfiliacion] = useState<any>(''); //date DEFAULT NULL,
    const [vl_HoraAfiliacion, setHoraAfiliacion] = useState<any>(''); // time DEFAULT NULL,
    const [vl_IdTipoDocumento, setIdTipoDocumento] = useState<any>(''); //int DEFAULT NULL COMMENT 'FK_TB_TIPO_DOCUMENTO',
    const [vl_NumeroDocumento, setNumeroDocumento] = useState<any>(''); //varchar(20) DEFAULT NULL,
    const [vl_Sexo, setSexo] = useState<any>(''); //char(1) DEFAULT NULL,
    const [vl_ApellidoPaterno, setApellidoPaterno] = useState<any>(''); //varchar(100) DEFAULT NULL,
    const [vl_ApellidoMaterno, setApellidoMaterno] = useState<any>(''); //varchar(100) DEFAULT NULL,
    const [vl_Nombres, setNombres] = useState<any>(''); //varchar(80) DEFAULT NULL,
    // const [vl_FechaNacimiento, setFechaNacimiento] = useState<any>(''); //date DEFAULT NULL,
    const [vl_FechaNacimiento, setFechaNacimiento] = useState<Date | Date[] | undefined>(undefined);
    const [vl_Edad, setEdad] = useState<any>(''); //int DEFAULT NULL,
    const [vl_IdEstadoCivil, setIdEstadoCivil] = useState<any>('0'); //int DEFAULT NULL,
    const [vl_IdGradoInstruccion, setIdGradoInstruccion] = useState<any>('0'); //int DEFAULT NULL,
    const [vl_IdOcupacion, setIdOcupacion] = useState<any>(0); //int DEFAULT NULL,

    // @json [{}]
    // @@--const [vl_LugarNacimiento, setLugarNacimiento] = useState<any>(''); //json DEFAULT NULL,
    const [vl_LugarNacimientoDireccion, setLugarNacimientoDireccion] = useState<any>(''); //json DEFAULT NULL,
    // @json [{}]
    // @@--const [vl_LugarProcedencia, setLugarProcedencia] = useState<any>(''); //json DEFAULT NULL,
    const [vl_LugarProcedenciaDireccion, setLugarProcedenciaDireccion] = useState<any>(''); //json DEFAULT NULL,
    const [vl_ConquienesViven, setConquienesViven] = useState<any>(''); //json DEFAULT NULL,


    const [vl_IdReligion, setIdReligion] = useState<any>('0');
    const [vl_Religion, setReligion] = useState<any>('');
    const [vl_NumeroHermanos, setNumeroHermanos] = useState<any>('0/0');
    //inf. Menor de edad
    const [vl_Escolaridad, setEscolaridad] = useState<any>('');
    const [vl_InstEducativa, setInstEducativa] = useState<any>('');
    const [vl_Referente, setReferente] = useState<any>('');

    // @json [{}]
    // @@--const [vl_DomicilioActual, setDomicilioActual] = useState<any>({}); //json DEFAULT NULL,
    const [vl_DomicilioActualDepartamento, setDomicilioActualDepartamento] = useState<any>('15');
    const [vl_DomicilioActualProvincia, setDomicilioActualProvincia] = useState<any>('1501');
    const [vl_DomicilioActualDistrito, setDomicilioActualDistrito] = useState<any>('140101');
    const [vl_DomicilioActualTipovia, setDomicilioActualTipovia] = useState<any>('AV.');
    const [vl_DomicilioActualNombrevia, setDomicilioActualNombrevia] = useState<any>('');
    const [vl_DomicilioActualNumero, setDomicilioActualNumero] = useState<any>('');
    const [vl_DomicilioActualManzana, setDomicilioActualManzana] = useState<any>('');
    const [vl_DomicilioActualLote, setDomicilioActualLote] = useState<any>('');
    const [vl_DomicilioActualDpto, setDomicilioActualDpto] = useState<any>('');
    const [vl_DomicilioActualUrbanizacion, setDomicilioActualUrbanizacion] = useState<any>('');
    // @json [{}]
    // const [vl_DatoAcompanante, setDatoAcompanante] = useState<any>({}); //json DEFAULT NULL,
    const [vl_DatoAcompananteNombres, setDatoAcompananteNombres] = useState<any>('');
    const [vl_DatoAcompananteDni, setDatoAcompananteDni] = useState<any>('');
    const [vl_IdStaff, setIdStaff] = useState<any>(0);

    const [dataHistoriaClinica, setdataHistoriaClinica] = useState<any>();
    const [isOpenModal, openModal, closeModal] = useModal();
    // const [isOpenModal01, openModal01, closeModal01] = useModal();
    const [b_visible, setVisibleConfirmDialog] = useState<boolean>(false);
    const [b_visible2, setVisibleConfirmDialog2] = useState<boolean>(false); //para ir a bandeja de derivaciones (new)
    const [b_ProgressBar, setProgressBar] = useState<boolean>(false);
    const [b_fieldsetHiddenAA, setFieldsetHiddenAA] = useState<boolean>(true);
    const [b_fieldsetHiddenAM, setFieldsetHiddenAM] = useState<boolean>(false);


    const [vl_mode, setMode] = useState<any>('U');
    const isMounted = useRef(false);
    // const isMounted01 = useRef(false);
    const toast = useRef<any>(null);
    const toastConfirm = useRef<any>(null);
    // data combos
    const [cmbIdTipoDocumento, setCmbIdTipoDocumento] = useState([]);
    const [cmbIdEstadoCivil, setCmbIdEstadoCivil] = useState([]);
    const [cmbIdGradoInstruccion, setCmbIdGradoInstruccion] = useState([]);
    const [cbmIdOcupacion, setCbmIdOcupacion] = useState([]);
    const [cbmIdReligion, setCbmIdReligion] = useState([]);
    const [cbmIdTipoPariente, setCbmIdTipoPariente] = useState([]);

    // const [cbmOrdenAtencion, setCbmOrdenAtencion] = useState([]);
    // const [vl_IdOrdenAtencion, setIdOrdenAtencion] = useState<any>(0);
    const [gridDataOrdenAtencionDetalles, setGridDataOrdenAtencionDetalles] = useState<any>([]);
    const [gridDataOrdenTerapiaDetalles, setGridDataOrdenTerapiaDetalles] = useState<any>([]);
    const [gridDataInformePsicologico, setGridDataInformePsicologico] = useState<any>([]);


    // data combos - direccion domicilio actual
    const [cbmDireccionDepartamento, setCbmDireccionDepartamento] = useState([]);
    const [cbmDireccionProvincia, setCbmDireccionProvincia] = useState([]);
    const [cbmDireccionDistrito, setCbmDireccionDistrito] = useState([]);
    const [cbmDireccionTipovia, setCbmDireccionTipovia] = useState([]);
    const [cmbIdStaff, setCmbIdStaff] = useState([]);
    const [cmbServiciosCliente, setCmbServiciosCliente] = useState([]);

    // const [cmbPlanEvaluacionCliente, setCmbPlanEvaluacionCliente] = useState([]);

    // @@MOTIVO DE CONSULTA 
    const [vl_MotivoConsultaTxt_a, setMotivoConsultaTxt_a] = useState<any>('');
    const [vl_MotivoConsultaTxt_b, setMotivoConsultaTxt_b] = useState<any>('');
    const [vl_MotivoConsultaTxt_c, setMotivoConsultaTxt_c] = useState<any>('');
    const [vl_Porte, setPorte] = useState<string>('');
    const [vl_Comportamiento, setComportamiento] = useState<string>('');
    const [vl_Actitud, setActitud] = useState<string>('');

    // @@ANAMNESIS    
    // A. ADULTOS    
    // a.1 PRENATAL
    const [vl_AAPrenatal_qa, setAAPrenatal_qa] = useState<any>('');
    const [vl_AAPrenatal_qb, setAAPrenatal_qb] = useState<any>('SI');
    const [vl_AAPrenatal_qc, setAAPrenatal_qc] = useState<any>('SI');
    const [vl_AAPrenatalTipoControlM, setAAPrenatalTipoControlM] = useState<boolean>(false); // MEDICO
    const [vl_AAPrenatalTipoControlP, setAAPrenatalTipoControlP] = useState<boolean>(false); // PARTERA
    const [vl_AAPrenatalTipoControlN, setAAPrenatalTipoControlN] = useState<boolean>(false); // NO TUVO CONTROLES
    const [vl_AAPrenatal_qd, setAAPrenatal_qd] = useState<any>('');
    const [vl_AAPrenatal_qe, setAAPrenatal_qe] = useState<any>('');
    // a.1 PERINATAL
    const [vl_AAPerinatalTxtTNacio, setAAPerinatalTxtTNacio] = useState<any>('');
    const [vl_AAPerinatalDondeNacio, setAAPerinatalDondeNacio] = useState<any>('');
    const [vl_AAPerinatalDondeNacioOtros, setAAPerinatalDondeNacioOtros] = useState<any>('');
    const [vl_AAPerinatalTipoParto, setAAPerinatalTipoParto] = useState<any>('');
    const [vl_AAPerinatalTipoPartoInducido, setAAPerinatalTipoPartoInducido] = useState<any>('');
    const [vl_AAPerinatalPRNPesoAltura, setAAPerinatalPRNPesoAltura] = useState<any>('');
    const [vl_AAPerinatalTxtLloroNacer, setAAPerinatalTxtLloroNacer] = useState<any>('');
    const [vl_AAPerinatalTxtLloroNacerTiempo, setAAPerinatalTxtLloroNacerTiempo] = useState<any>('');
    const [vl_AAPerinatalEdadP, setAAPerinatalEdadP] = useState<any>('');
    const [vl_AAPerinatalEdadM, setAAPerinatalEdadM] = useState<any>('');
    // a.2 POSNATAL
    const [vl_AAPosnatalQa, setAAPosnatalQa] = useState<any>('NO');
    const [vl_AAPosnatalQb, setAAPosnatalQb] = useState<any>('NO');
    const [vl_AAPosnatalQc, setAAPosnatalQc] = useState<any>('NO');
    const [vl_AAPosnatalQdRespTxt, setAAPosnatalQdRespTxt] = useState<any>('');
    const [vl_AAPosnatalQe, setAAPosnatalQe] = useState<any>('NO');
    //  a.3 HISTORIA MEDICA
    const [vl_AAHistoriaMedicaTxta, setAAHistoriaMedicaTxta] = useState<any>('');
    const [vl_AAHistoriaMedicaTxtb, setAAHistoriaMedicaTxtb] = useState<any>('');
    const [vl_AAHistoriaMedicaQc, setAAHistoriaMedicaQc] = useState<any>('NO');
    const [vl_AAHistoriaMedicaConvulsion, setAAHistoriaMedicaConvulsion] = useState<boolean>(false);
    const [vl_AAHistoriaMedicaMareos, setAAHistoriaMedicaMareos] = useState<boolean>(false);
    const [vl_AAHistoriaMedicaEdadTenia, setAAHistoriaMedicaEdadTenia] = useState<any>('');
    const [vl_AAHistoriaMedicaComoFueAtendido, setAAHistoriaMedicaComoFueAtendido] = useState<any>('');
    const [vl_AAHistoriaMedicaOperaciones, setAAHistoriaMedicaOperaciones] = useState<any>('NO');
    const [vl_AAHistoriaMedicaOperacionesTxt, setAAHistoriaMedicaOperacionesTxt] = useState<any>('');
    const [vl_AAHistoriaMedicaExamenRealidadoN, setAAHistoriaMedicaExamenRealidadoN] = useState<boolean>(false);
    const [vl_AAHistoriaMedicaExamenRealidadoA, setAAHistoriaMedicaExamenRealidadoA] = useState<boolean>(false);
    const [vl_AAHistoriaMedicaExamenRealidadoP, setAAHistoriaMedicaExamenRealidadoP] = useState<boolean>(false);
    const [vl_AAHistoriaMedicaExamenRealidadoPsi, setAAHistoriaMedicaExamenRealidadoPsi] = useState<boolean>(false);
    const [vl_AAHistoriaMedicaExamenRealidadoOtros, setAAHistoriaMedicaExamenRealidadoOtros] = useState<boolean>(false);
    const [vl_AAHistoriaMedicaExamenRealidadoTxt, setAAHistoriaMedicaExamenRealidadoTxt] = useState<any>('');
    const [vl_AAHistoriaMedicaDomLatManual, setAAHistoriaMedicaDomLatManual] = useState<any>('DERECHA');
    const [vl_AAHistoriaMedicaEnfermedadActualTxt, setAAHistoriaMedicaEnfermedadActualTxt] = useState<any>('');
    const [vl_AAHistoriaMedicaEnfermedadActualTratamientoTxt, setAAHistoriaMedicaEnfermedadActualTratamientoTxt] = useState<any>('');

    // a.4 HABILIDAD PARA HABLAR
    const [vl_AAHabilidadHablaQ1, setAAHabilidadHablaQ1] = useState<any>('');
    const [vl_AAHabilidadHablaQ2, setAAHabilidadHablaQ2] = useState<any>('');
    const [vl_AAHabilidadHablaOmision, setAAHabilidadHablaOmision] = useState<boolean>(false);
    const [vl_AAHabilidadHablaSustitucion, setAAHabilidadHablaSustitucion] = useState<boolean>(false);
    const [vl_AAHabilidadHablaDistorsion, setAAHabilidadHablaDistorsion] = useState<boolean>(false);
    const [vl_AAHabilidadHablaQ3, setAAHabilidadHablaQ3] = useState<any>('NO');
    const [vl_AAHabilidadHablaQ4, setAAHabilidadHablaQ4] = useState<any>('NO');
    const [vl_AAHabilidadHablaQ5, setAAHabilidadHablaQ5] = useState<any>('NO');
    const [vl_AAHabilidadHablaCk1, setAAHabilidadHablaCk1] = useState<boolean>(false);
    const [vl_AAHabilidadHablaCk2, setAAHabilidadHablaCk2] = useState<boolean>(false);
    const [vl_AAHabilidadHablaCk3, setAAHabilidadHablaCk3] = useState<boolean>(false);
    const [vl_AAHabilidadHablaCk4, setAAHabilidadHablaCk4] = useState<boolean>(false);
    // a.5 ALIMENTACION
    const [vl_AAAlimentacionMaterno, setAAAlimentacionMaterno] = useState<boolean>(false);
    const [vl_AAAlimentacionArtificial, setAAAlimentacionArtificial] = useState<boolean>(false);
    const [vl_AAAlimentacionEdadDestete, setAAAlimentacionEdadDestete] = useState<any>('');
    const [vl_AAAlimentacionProblemaApetito, setAAAlimentacionProblemaApetito] = useState<any>(''); //SI-NO
    const [vl_AAAlimentacionProblemaApetitoDecripcion, setAAAlimentacionProblemaApetitoDecripcion] = useState<any>('');

    const [vl_AAAlimentacionQtyComidasAlDia, setAAAlimentacionQtyComidasAlDia] = useState<any>('3');
    const [vl_AAAlimentacionAboutDieta, setAAAlimentacionAboutDieta] = useState<any>('');
    const [vl_AAAlimentacionAboutDietaWhy, setAAAlimentacionAboutDietaWhy] = useState<any>('');
    // a.6 HIGIENE
    const [vl_AAHigieneQ1, setAAHigieneQ1] = useState<any>('');
    const [vl_AAHigieneQ2, setAAHigieneQ2] = useState<any>('');
    // a.7 sueño
    const [vl_AASuenioQ1, setAASuenioQ1] = useState<any>('');
    const [vl_AASuenioQ2, setAASuenioQ2] = useState<any>('');
    const [vl_AASuenioQ3Habla, setAASuenioQ3Habla] = useState<boolean>(false);
    const [vl_AASuenioQ3Grita, setAASuenioQ3Grita] = useState<boolean>(false);
    const [vl_AASuenioQ3SeMueve, setAASuenioQ3SeMueve] = useState<boolean>(false);
    const [vl_AASuenioQ3Transpira, setAASuenioQ3Transpira] = useState<boolean>(false);
    const [vl_AASuenioQ3Camina, setAASuenioQ3Camina] = useState<boolean>(false);
    const [vl_AASuenioQ4, setAASuenioQ4] = useState<any>('');
    const [vl_AASuenioQ5, setAASuenioQ5] = useState<any>(''); //SI-NO
    const [vl_AASuenioQ5Txt, setAASuenioQ5Txt] = useState<any>(''); //si es: SI
    // INDEPENDENCIA PERSONAL
    const [vl_AAIndPersonalQ1, setAAIndPersonalQ1] = useState<any>('');
    const [vl_AAIndPersonalQ2, setAAIndPersonalQ2] = useState<any>(''); //SI-NO
    const [vl_AAIndPersonalQ2Txt, setAAIndPersonalQ2Txt] = useState<any>(''); //respuesta
    const [vl_AAIndPersonalQ3, setAAIndPersonalQ3] = useState<any>(''); //SI-NO
    const [vl_AAIndPersonalQ3Txt, setAAIndPersonalQ3Txt] = useState<any>(''); //respuesta
    const [vl_AAIndPersonalQ4, setAAIndPersonalQ4] = useState<any>(''); //SI-NO
    const [vl_AAIndPersonalQ4Txt, setAAIndPersonalQ4Txt] = useState<any>(''); //respuesta
    // CONDUCTA
    const [vl_AAConductaQ1, setAAConductaQ1] = useState<any>('');
    const [vl_AAConductaQ2, setAAConductaQ2] = useState<any>('');
    const [vl_AAConductaQ3, setAAConductaQ3] = useState<any>('');
    const [vl_AAConductaQ4, setAAConductaQ4] = useState<any>('');
    const [vl_AAConductaQ5, setAAConductaQ5] = useState<any>('');
    const [vl_AAConductaQ6Txt, setAAConductaQ6Txt] = useState<any>('');
    const [vl_AAConductaQ7Txt, setAAConductaQ7Txt] = useState<any>('');
    const [vl_AAConductaQ8Txt, setAAConductaQ8Txt] = useState<any>('');
    const [vl_AAConductaQ9Txt, setAAConductaQ9Txt] = useState<any>('');
    //JUEGO-SOCIAL
    const [vl_AAJuegoSocialQ1, setAAJuegoSocialQ1] = useState<any>('');
    const [vl_AAJuegoSocialQ2, setAAJuegoSocialQ2] = useState<any>('');
    const [vl_AAJuegoSocialQ3, setAAJuegoSocialQ3] = useState<any>('');
    const [vl_AAJuegoSocialQ4, setAAJuegoSocialQ4] = useState<any>('');
    const [vl_AAJuegoSocialQ5, setAAJuegoSocialQ5] = useState<any>('');
    //HISTORIA EDUCATIVA
    const [vl_AAHistoriaEduIEdad, setAAHistoriaEduIEdad] = useState<any>('');
    const [vl_AAHistoriaEduIAdaptacion, setAAHistoriaEduIAdaptacion] = useState<any>('');
    const [vl_AAHistoriaEduIDificultades, setAAHistoriaEduIDificultades] = useState<any>('');
    const [vl_AAHistoriaEduIConductasDisfuncionales, setAAHistoriaEduIConductasDisfuncionales] = useState<any>('');
    const [vl_AAHistoriaEduPEdad, setAAHistoriaEduPEdad] = useState<any>('');
    const [vl_AAHistoriaEduPRendimientoBueno, setAAHistoriaEduPRendimientoBueno] = useState<boolean>(false);
    const [vl_AAHistoriaEduPRendimientoRegular, setAAHistoriaEduPRendimientoRegular] = useState<boolean>(false);
    const [vl_AAHistoriaEduPRendimientoBajo, setAAHistoriaEduPRendimientoBajo] = useState<boolean>(false);
    const [vl_AAHistoriaEduPDificultades, setAAHistoriaEduPDificultades] = useState<any>('');
    const [vl_AAHistoriaEduPNivelAdaptacion, setAAHistoriaEduPNivelAdaptacion] = useState<any>('');
    const [vl_AAHistoriaEduSEdad, setAAHistoriaEduSEdad] = useState<any>('');
    const [vl_AAHistoriaEduSRendimientoBueno, setAAHistoriaEduSRendimientoBueno] = useState<boolean>(false);
    const [vl_AAHistoriaEduSRendimientoRegular, setAAHistoriaEduSRendimientoRegular] = useState<boolean>(false);
    const [vl_AAHistoriaEduSRendimientoBajo, setAAHistoriaEduSRendimientoBajo] = useState<boolean>(false);
    const [vl_AAHistoriaEduSDificultades, setAAHistoriaEduSDificultades] = useState<any>('');
    const [vl_AAHistoriaEduSNivelAdaptacion, setAAHistoriaEduSNivelAdaptacion] = useState<any>('');
    const [vl_AAHistoriaEduSQ1Txt, setAAHistoriaEduSQ1Txt] = useState<any>('');
    const [vl_AAHistoriaEduSQ2Txt, setAAHistoriaEduSQ2Txt] = useState<any>('');
    const [vl_AAHistoriaEduSQ3, setAAHistoriaEduSQ3] = useState<any>('');
    const [vl_AAHistoriaEduSQ3Txt, setAAHistoriaEduSQ3Txt] = useState<any>('');
    //Conducta en clases. 
    const [vl_AAHistoriaEduSQ4Txt, setAAHistoriaEduSQ4Txt] = useState<any>('');
    const [vl_AAHistoriaEduSQ5Txt, setAAHistoriaEduSQ5Txt] = useState<any>('');
    const [vl_AAHistoriaEduSQ6Txt, setAAHistoriaEduSQ6Txt] = useState<any>('');
    const [vl_AAHistoriaEduSQ7Txt, setAAHistoriaEduSQ7Txt] = useState<any>('');
    const [vl_AAHistoriaEduSQ8Txt, setAAHistoriaEduSQ8Txt] = useState<any>('');
    // Opinión del profesor.
    const [vl_AAHistoriaEduSQ9Txt, setAAHistoriaEduSQ9Txt] = useState<any>('');
    const [vl_AAHistoriaEduSQ10Txt, setAAHistoriaEduSQ10Txt] = useState<any>('');
    const [vl_AAHistoriaEduSQ11Txt, setAAHistoriaEduSQ11Txt] = useState<any>('');

    // PSICOSEXUALIDAD
    const [vl_AAPsicosexualidadQ1, setAAPsicosexualidadQ1] = useState<any>('');
    const [vl_AAPsicosexualidadQ2, setAAPsicosexualidadQ2] = useState<any>('');
    const [vl_AAPsicosexualidadQ3, setAAPsicosexualidadQ3] = useState<any>('');
    const [vl_AAPsicosexualidadQ4, setAAPsicosexualidadQ4] = useState<any>('');
    const [vl_AAPsicosexualidadQ5, setAAPsicosexualidadQ5] = useState<any>('');

    //ÁREA FAMILIAR
    const [vl_AAAreaFamiliarRMamaRechazo, setAAAreaFamiliarRMamaRechazo] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRMamaPreocupacion, setAAAreaFamiliarRMamaPreocupacion] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRMamaVerguenza, setAAAreaFamiliarRMamaVerguenza] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRMamaIndiferencia, setAAAreaFamiliarRMamaIndiferencia] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRMamaAceptacion, setAAAreaFamiliarRMamaAceptacion] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRMamaEventoTxt, setAAAreaFamiliarRMamaEventoTxt] = useState<any>('');

    const [vl_AAAreaFamiliarRPapaRechazo, setAAAreaFamiliarRPapaRechazo] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRPapaPreocupacion, setAAAreaFamiliarRPapaPreocupacion] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRPapaVerguenza, setAAAreaFamiliarRPapaVerguenza] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRPapaIndiferencia, setAAAreaFamiliarRPapaIndiferencia] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRPapaAceptacion, setAAAreaFamiliarRPapaAceptacion] = useState<boolean>(false);
    const [vl_AAAreaFamiliarRPapaEventoTxt, setAAAreaFamiliarRPapaEventoTxt] = useState<any>('');
    const [vl_AAAreaFamiliarQ1Txt, setAAAreaFamiliarQ1Txt] = useState<any>('');
    const [vl_AAAreaFamiliarTxt1, setAAAreaFamiliarTxt1] = useState<any>('');
    const [vl_AAAreaFamiliarQ2Txt, setAAAreaFamiliarQ2Txt] = useState<any>('');
    const [vl_AAAreaFamiliarTxt2, setAAAreaFamiliarTxt2] = useState<any>(''); //Cuidadores principales en la niñez: 
    const [vl_AAAreaFamiliarQ3Txt, setAAAreaFamiliarQ3Txt] = useState<any>('');
    const [vl_AAAreaFamiliarTxt3, setAAAreaFamiliarTxt3] = useState<any>('');
    const [vl_AAAreaFamiliarTxt4, setAAAreaFamiliarTxt4] = useState<any>('');
    //Carácter de los padres:
    const [vl_AAAreaFamiliarCaractPapa, setAAAreaFamiliarCaractPapa] = useState<any>('');
    const [vl_AAAreaFamiliarCaractMama, setAAAreaFamiliarCaractMama] = useState<any>('');
    const [vl_AAAreaFamiliarQ4Txt, setAAAreaFamiliarQ4Txt] = useState<any>('');
    //Actualmente: ¿Cómo es la relación con?
    const [vl_AAAreaFamiliarQ5Txt, setAAAreaFamiliarQ5Txt] = useState<any>('');
    const [vl_AAAreaFamiliarQ6Txt, setAAAreaFamiliarQ6Txt] = useState<any>('');
    const [vl_AAAreaFamiliarQ7Txt, setAAAreaFamiliarQ7Txt] = useState<any>('');
    // ÁREA DE PAREJA
    const [vl_AAAreaParejaQ1txt, setAAAreaParejaQ1txt] = useState<any>('');
    const [vl_AAAreaParejaQ2txt, setAAAreaParejaQ2txt] = useState<any>('');
    const [vl_AAAreaParejaQ3txt, setAAAreaParejaQ3txt] = useState<any>('');
    const [vl_AAAreaParejaQ4txt, setAAAreaParejaQ4txt] = useState<any>('');
    // ÁREA LABORAL:
    const [vl_AAAreaLaboralQ1txt, setAAAreaLaboralQ1txt] = useState<any>('');

    // ANAMESIS>> Menores de edad

    // A. Niño y adolescente    
    // I. PRENATAL
    const [vl_AMPrenatal_q, setAMPrenatal_q] = useState<any>(''); // NEW
    const [vl_AMPrenatal_qa, setAMPrenatal_qa] = useState<any>('');
    const [vl_AMPrenatal_qb, setAMPrenatal_qb] = useState<any>('SI');
    const [vl_AMPrenatal_qc, setAMPrenatal_qc] = useState<any>('SI');
    const [vl_AMPrenatalTipoControlM, setAMPrenatalTipoControlM] = useState<boolean>(false); // MEDICO
    const [vl_AMPrenatalTipoControlP, setAMPrenatalTipoControlP] = useState<boolean>(false); // PARTERA
    const [vl_AMPrenatalTipoControlN, setAMPrenatalTipoControlN] = useState<boolean>(false); // NO TUVO CONTROLES
    const [vl_AMPrenatal_qd, setAMPrenatal_qd] = useState<any>('');
    const [vl_AMPrenatal_qe, setAMPrenatal_qe] = useState<any>('');
    const [vl_AMPrenatal_qf, setAMPrenatal_qf] = useState<any>('SI'); // new
    const [vl_AMPrenatal_qfTxt, setAMPrenatal_qfTxt] = useState<any>(''); // new

    // II. PERINATAL
    const [vl_AMPerinatalTxtTNacio, setAMPerinatalTxtTNacio] = useState<any>('');
    const [vl_AMPerinatalDondeNacio, setAMPerinatalDondeNacio] = useState<any>('');
    const [vl_AMPerinatalDondeNacioOtros, setAMPerinatalDondeNacioOtros] = useState<any>('');
    const [vl_AMPerinatalTipoParto, setAMPerinatalTipoParto] = useState<any>('');
    const [vl_AMPerinatalTipoPartoInducido, setAMPerinatalTipoPartoInducido] = useState<any>('');
    const [vl_AMPerinatalPRNPesoAltura, setAMPerinatalPRNPesoAltura] = useState<any>('');
    const [vl_AMPerinatalTxtLloroNacer, setAMPerinatalTxtLloroNacer] = useState<any>('');
    const [vl_AMPerinatalTxtLloroNacerTiempo, setAMPerinatalTxtLloroNacerTiempo] = useState<any>(''); // NEW CAMBIA TEXTO ??
    const [vl_AMPerinatalEdadP, setAMPerinatalEdadP] = useState<any>('');
    const [vl_AMPerinatalEdadM, setAMPerinatalEdadM] = useState<any>('');
    // III POSNATAL
    const [vl_AMPosnatalQa, setAMPosnatalQa] = useState<any>('NO');
    const [vl_AMPosnatalQb, setAMPosnatalQb] = useState<any>('NO');
    const [vl_AMPosnatalQc, setAMPosnatalQc] = useState<any>('NO');
    const [vl_AMPosnatalQdRespTxt, setAMPosnatalQdRespTxt] = useState<any>('');
    const [vl_AMPosnatalQe, setAMPosnatalQe] = useState<any>('NO');
    // IV HISTORIA MEDICA
    const [vl_AMHistoriaMedicaTxta, setAMHistoriaMedicaTxta] = useState<any>('');
    const [vl_AMHistoriaMedicaTxtb, setAMHistoriaMedicaTxtb] = useState<any>('');
    const [vl_AMHistoriaMedicaQc, setAMHistoriaMedicaQc] = useState<any>('NO');
    const [vl_AMHistoriaMedicaConvulsion, setAMHistoriaMedicaConvulsion] = useState<boolean>(false);
    const [vl_AMHistoriaMedicaMareos, setAMHistoriaMedicaMareos] = useState<boolean>(false);
    const [vl_AMHistoriaMedicaEdadTenia, setAMHistoriaMedicaEdadTenia] = useState<any>(''); //CAMBIA TEXTO 
    const [vl_AMHistoriaMedicaComoFueAtendido, setAMHistoriaMedicaComoFueAtendido] = useState<any>('');
    const [vl_AMHistoriaMedicaOperaciones, setAMHistoriaMedicaOperaciones] = useState<any>('NO');
    const [vl_AMHistoriaMedicaOperacionesTxt, setAMHistoriaMedicaOperacionesTxt] = useState<any>('');
    const [vl_AMHistoriaMedicaExamenRealidadoN, setAMHistoriaMedicaExamenRealidadoN] = useState<boolean>(false);
    const [vl_AMHistoriaMedicaExamenRealidadoA, setAMHistoriaMedicaExamenRealidadoA] = useState<boolean>(false);
    const [vl_AMHistoriaMedicaExamenRealidadoP, setAMHistoriaMedicaExamenRealidadoP] = useState<boolean>(false);
    const [vl_AMHistoriaMedicaExamenRealidadoPsi, setAMHistoriaMedicaExamenRealidadoPsi] = useState<boolean>(false);
    const [vl_AMHistoriaMedicaExamenRealidadoOtros, setAMHistoriaMedicaExamenRealidadoOtros] = useState<boolean>(false);
    const [vl_AMHistoriaMedicaExamenRealidadoTxt, setAMHistoriaMedicaExamenRealidadoTxt] = useState<any>('');
    // const [vl_AMHistoriaMedicaDomLatManual, setAMHistoriaMedicaDomLatManual] = useState<any>('DERECHA'); // NO HAY EN ADOLESCENTE ??

    // NUEVA SECCION
    // DESARROLLO NEUROMUSCULAR
    const [vl_AMDesarrolloNMEdadLevantarCabeza, setAMDesarrolloNMEdadLevantarCabeza] = useState<any>('');
    const [vl_AMDesarrolloNMEdadSentarse, setAMDesarrolloNMEdadSentarse] = useState<any>('');
    const [vl_AMDesarrolloNMEdadGatear, setAMDesarrolloNMEdadGatear] = useState<any>('');
    const [vl_AMDesarrolloNMEdadPararse, setAMDesarrolloNMEdadPararse] = useState<any>('');
    const [vl_AMDesarrolloNMEdadCaminar, setAMDesarrolloNMEdadCaminar] = useState<any>('');
    const [vl_AMDesarrolloNMDificultades, setAMDesarrolloNMDificultades] = useState<any>(''); //si-no
    const [vl_AMDesarrolloNMMovimientoSacudeBrazo, setAMDesarrolloNMMovimientoSacudeBrazo] = useState<boolean>(false);
    const [vl_AMDesarrolloNMMovimientoEstrujaMano, setAMDesarrolloNMMovimientoEstrujaMano] = useState<boolean>(false);
    const [vl_AMDesarrolloNMMovimientoTxt, setAMDesarrolloNMMovimientoTxt] = useState<any>('');
    const [vl_AMDesarrolloNMMovimientoTxt2, setAMDesarrolloNMMovimientoTxt2] = useState<any>('');
    const [vl_AMDesarrolloNMHabCaminar, setAMDesarrolloNMHabCaminar] = useState<boolean>(false);
    const [vl_AMDesarrolloNMHabSaltar1Pie, setAMDesarrolloNMHabSaltar1Pie] = useState<boolean>(false);
    const [vl_AMDesarrolloNMHabDesplazarSalt1Pie, setAMDesarrolloNMHabDesplazarSalt1Pie] = useState<boolean>(false);
    const [vl_AMDesarrolloNMDomLatManual, setAMDesarrolloNMDomLatManual] = useState<any>('DERECHA');

    // HABILIDAD PARA HABLAR
    const [vl_AMHabilidadHablaQ1, setAMHabilidadHablaQ1] = useState<any>(''); //CAMBIA TEXTO
    const [vl_AMHabilidadHablaQ2, setAMHabilidadHablaQ2] = useState<any>('');

    const [vl_AMHabilidadHablaEntenderGestos, setAMHabilidadHablaEntenderGestos] = useState<boolean>(false);
    const [vl_AMHabilidadHablaEntenderGritos, setAMHabilidadHablaEntenderGritos] = useState<boolean>(false);
    const [vl_AMHabilidadHablaEntenderHablando, setAMHabilidadHablaEntenderHablando] = useState<boolean>(false);
    const [vl_AMHabilidadHablaEntenderBalbuceando, setAMHabilidadHablaEntenderBalbuceando] = useState<boolean>(false);
    const [vl_AMHabilidadHablaEntenderOtros, setAMHabilidadHablaEntenderOtros] = useState<boolean>(false);

    const [vl_AMHabilidadHablaQ3, setAMHabilidadHablaQ3] = useState<any>(''); //new txt

    const [vl_AMHabilidadHablaDificultadPronOmision, setAMHabilidadHablaDificultadPronOmision] = useState<boolean>(false);
    const [vl_AMHabilidadHablaDificultadPronSustitucion, setAMHabilidadHablaDificultadPronSustitucion] = useState<boolean>(false);
    const [vl_AMHabilidadHablaDificultadPronDistorsion, setAMHabilidadHablaDificultadPronDistorsion] = useState<boolean>(false);
    // ¿Cómo es su pronunciación, se entiende, articulación trabada? Describir.
    const [vl_AMHabilidadHablaQ4, setAMHabilidadHablaQ4] = useState<any>('');
    const [vl_AMHabilidadHablaQ5, setAMHabilidadHablaQ5] = useState<any>('');
    const [vl_AMHabilidadHablaQ6, setAMHabilidadHablaQ6] = useState<any>('');
    const [vl_AMHabilidadHablaQ7, setAMHabilidadHablaQ7] = useState<any>('');
    const [vl_AMHabilidadHablaQ8, setAMHabilidadHablaQ8] = useState<any>('');
    const [vl_AMHabilidadHablaQ9, setAMHabilidadHablaQ9] = useState<any>(''); //si-no
    const [vl_AMHabilidadHablaQ10, setAMHabilidadHablaQ10] = useState<any>(''); //si-no
    const [vl_AMHabilidadHablaQ11, setAMHabilidadHablaQ11] = useState<any>(''); //si-no
    const [vl_AMHabilidadHablaQ12, setAMHabilidadHablaQ12] = useState<any>(''); //si-no
    const [vl_AMHabilidadHablaQ13, setAMHabilidadHablaQ13] = useState<any>(''); //si-no
    const [vl_AMHabilidadHablaQ14, setAMHabilidadHablaQ14] = useState<any>(''); //si-no
    // Habla demasiado?
    const [vl_AMHabilidadHablaDemasiado, setAMHabilidadHablaDemasiado] = useState<boolean>(false);
    const [vl_AMHabilidadHablaRapido, setAMHabilidadHablaRapido] = useState<boolean>(false);
    const [vl_AMHabilidadHablaLento, setAMHabilidadHablaLento] = useState<boolean>(false);
    const [vl_AMHabilidadHablaNormal, setAMHabilidadHablaNormal] = useState<boolean>(false);
    // ¿Su voz es normal, alterada? ¿De qué tipo? ¿Grita al hablar?
    const [vl_AMHabilidadHablaQ15Txt, setAMHabilidadHablaQ15Txt] = useState<any>('');

    // MOVIMIENTOS DE LA ZONA ORAL
    const [vl_AMMovZonaOralUsoBiberon, setAMMovZonaOralUsoBiberon] = useState<any>(''); //SI-NO
    const [vl_AMMovZonaOralQ1, setAMMovZonaOralQ1] = useState<any>('');
    // Masticación. hábitos de masticación: 
    const [vl_AMMovZonaOralMasticacionQ1, setAMMovZonaOralMasticacionQ1] = useState<any>('');
    const [vl_AMMovZonaOralMasticacionQ2, setAMMovZonaOralMasticacionQ2] = useState<any>('');
    const [vl_AMMovZonaOralMasticacionQ3, setAMMovZonaOralMasticacionQ3] = useState<any>('');
    const [vl_AMMovZonaOralMasticacionQ4, setAMMovZonaOralMasticacionQ4] = useState<any>('');  //si-no
    const [vl_AMMovZonaOralMasticacionQ5, setAMMovZonaOralMasticacionQ5] = useState<any>('');
    const [vl_AMMovZonaOralMasticacionQ6, setAMMovZonaOralMasticacionQ6] = useState<any>('');
    const [vl_AMMovZonaOralMasticacionQ7, setAMMovZonaOralMasticacionQ7] = useState<any>('');
    // Babea:
    const [vl_AMMovZonaOralBabeaAlDormir, setAMMovZonaOralBabeaAlDormir] = useState<boolean>(false);
    const [vl_AMMovZonaOralBabeaAlComer, setAMMovZonaOralBabeaAlComer] = useState<boolean>(false);
    const [vl_AMMovZonaOralBabeaEnTodoMomento, setAMMovZonaOralBabeaEnTodoMomento] = useState<boolean>(false);
    // Dificultades para respirar:
    const [vl_AMMovZonaOralDiffRespirarViaResp, setAMMovZonaOralDiffRespirarViaResp] = useState<boolean>(false);
    const [vl_AMMovZonaOralDiffRespirarAlergias, setAMMovZonaOralDiffRespirarAlergias] = useState<boolean>(false);
    const [vl_AMMovZonaOralDiffRespirarResfriado, setAMMovZonaOralDiffRespirarResfriado] = useState<boolean>(false);
    const [vl_AMMovZonaOralDiffRespirarAsma, setAMMovZonaOralDiffRespirarAsma] = useState<boolean>(false);
    const [vl_AMMovZonaOralDiffRespirarOtros, setAMMovZonaOralDiffRespirarOtros] = useState<boolean>(false);
    const [vl_AMMovZonaOralDiffRespirarOtrosTxt, setAMMovZonaOralDiffRespirarOtrosTxt] = useState<any>('');

    // FORMACIÓN DE HÁBITOS
    // ALIMENTACIÓN
    // Lactancia recibio su hijo: 
    const [vl_AMAlimentacionMaterno, setAMAlimentacionMaterno] = useState<boolean>(false);
    const [vl_AMAlimentacionArtificial, setAMAlimentacionArtificial] = useState<boolean>(false);
    const [vl_AMAlimentacionEdadDestete, setAMAlimentacionEdadDestete] = useState<any>('');
    // Actualmente ¿Requiere ayuda para comer? SI / NO
    const [vl_AMAlimentacionRequiereAyuda, setAMAlimentacionRequiereAyuda] = useState<any>(''); //SI-NO    
    const [vl_AMAlimentacionUsaCubiertos, setAMAlimentacionUsaCubiertos] = useState<any>(''); // ¿Usa cubiertos? SI / NO 
    const [vl_AMAlimentacionProblemaApetito, setAMAlimentacionProblemaApetito] = useState<any>(''); //SI-NO        
    const [vl_AMAlimentacionQtyComidasAlDia, setAMAlimentacionQtyComidasAlDia] = useState<any>('3');
    const [vl_AMAlimentacionAboutDieta, setAMAlimentacionAboutDieta] = useState<any>('');
    const [vl_AMAlimentacionAboutDietaWhy, setAMAlimentacionAboutDietaWhy] = useState<any>('');

    // HIGIENE
    const [vl_AMHigieneQ1, setAMHigieneQ1] = useState<any>('');
    const [vl_AMHigieneQ2, setAMHigieneQ2] = useState<any>('');

    // ¿Su hijo le pide cuando quiere hacer sus necesidades? SI / NO  Edad: 
    const [vl_AMHigieneQ3, setAMHigieneQ3] = useState<any>(''); //SI-NO
    const [vl_AMHigieneQ3Txt, setAMHigieneQ3Txt] = useState<any>('');

    const [vl_AMHigieneQ4, setAMHigieneQ4] = useState<any>(''); //¿Su hijo se asea solo? SI / NO. 
    const [vl_AMHigieneQ4Txt, setAMHigieneQ4Txt] = useState<any>(''); //Si, requiere ayuda. ¿cómo?

    // SUEÑO
    const [vl_AMSuenioQ1, setAMSuenioQ1] = useState<any>('');
    const [vl_AMSuenioQ2, setAMSuenioQ2] = useState<any>('');
    // Cuando su hijo está dormido: 
    const [vl_AMSuenioQ3Habla, setAMSuenioQ3Habla] = useState<boolean>(false);
    const [vl_AMSuenioQ3Grita, setAMSuenioQ3Grita] = useState<boolean>(false);
    const [vl_AMSuenioQ3SeMueve, setAMSuenioQ3SeMueve] = useState<boolean>(false);
    const [vl_AMSuenioQ3Transpira, setAMSuenioQ3Transpira] = useState<boolean>(false);
    const [vl_AMSuenioQ3Camina, setAMSuenioQ3Camina] = useState<boolean>(false);
    const [vl_AMSuenioQ4, setAMSuenioQ4] = useState<any>(''); //¿A qué edad?
    const [vl_AMSuenioQ5, setAMSuenioQ5] = useState<any>(''); //SI-NO
    const [vl_AMSuenioQ5Txt, setAMSuenioQ5Txt] = useState<any>(''); //si es: SI

    // INDEPENDENCIA PERSONAL
    const [vl_AMIndPersonalQ1, setAMIndPersonalQ1] = useState<any>(''); //SI-NO ¿Su hijo hace mandados?
    const [vl_AMIndPersonalQ2, setAMIndPersonalQ2] = useState<any>(''); //SI-NO ¿Dentro del hogar?
    const [vl_AMIndPersonalQ3, setAMIndPersonalQ3] = useState<any>(''); //SI-NO ¿Fuera del hogar? (barrio)
    const [vl_AMIndPersonalQ4, setAMIndPersonalQ4] = useState<any>(''); //SI-NO ¿Su hijo ayuda en casa? 
    const [vl_AMIndPersonalQ5Txt, setAAIndPersonalQ5Txt] = useState<any>(''); //¿Qué responsabilidades tiene? 
    const [vl_AMIndPersonalQ6, setAMIndPersonalQ6] = useState<any>(''); //SI-NO  Existencia de normas:
    const [vl_AMIndPersonalQ6Txt, setAMIndPersonalQ6Txt] = useState<any>(''); //¿Cuáles?

    const [vl_AAIndPersonalQ7, setAAIndPersonalQ7] = useState<any>(''); //SI-NO - Castigos 
    const [vl_AAIndPersonalQ8, setAAIndPersonalQ8] = useState<any>(''); //SI-NO - premios  
    const [vl_AAIndPersonalQ8Txt, setAAIndPersonalQ8Txt] = useState<any>(''); //¿Quiénes lo ejecutan? ¿Con qué frecuencia?

    // CONDUCTA
    // Conductas Inadaptativas
    const [vl_AMConductaQ1, setAMConductaQ1] = useState<any>(''); //¿Se come las uñas? SI/NO
    const [vl_AMConductaQ2, setAMConductaQ2] = useState<any>(''); //¿Se succiona los dedos?
    const [vl_AMConductaQ3, setAMConductaQ3] = useState<any>(''); //¿Se muerde el labio?
    const [vl_AMConductaQ4, setAMConductaQ4] = useState<any>(''); //¿Le sudan las manos? 
    const [vl_AMConductaQ5, setAMConductaQ5] = useState<any>(''); //¿Le tiemblan las manos y piernas?
    const [vl_AMConductaQ6, setAMConductaQ6] = useState<any>(''); //¿Agrede a las personas sin motivo?    
    const [vl_AMConductaQ7, setAMConductaQ7] = useState<any>(''); //¿Se le caen las cosas con facilidad?
    const [vl_AMConductaQ8, setAMConductaQ8] = useState<any>(''); //¿Problemas de alimentación?
    const [vl_AMConductaQ8Txt, setAMConductaQ8Txt] = useState<any>(''); //¿Cuáles? Sueño, concentración. Indisciplina (irritabilidad, hiperactividad) . Otros.
    const [vl_AMConductaQ9Txt, setAMConductaQ9Txt] = useState<any>(''); //Carácter del niño:
    //JUEGO-SOCIAL
    const [vl_AMJuegoSocialQ1, setAMJuegoSocialQ1] = useState<any>(''); //¿Su hijo juega solo? ¿Por qué? ¿dirige o es dirigido?
    const [vl_AMJuegoSocialQ2, setAMJuegoSocialQ2] = useState<any>(''); // ¿Qué juegos prefiere su hijo? ¿Cuáles son sus juguetes preferidos?
    const [vl_AMJuegoSocialQ3, setAMJuegoSocialQ3] = useState<any>(''); //¿Prefiere jugar con niños de su edad, con mayores o niños menores a su edad?
    const [vl_AMJuegoSocialQ4, setAMJuegoSocialQ4] = useState<any>(''); //¿Cuáles son las distracciones principales de su hijo? Uso del tiempo libre. Deportes.
    const [vl_AMJuegoSocialQ5, setAMJuegoSocialQ5] = useState<any>(''); //Conductas en el juego con otros niños: agresividad, ausencia del deseo del contacto.

    //HISTORIA EDUCATIVA 
    const [vl_AMHistoriaEduIEdad, setAMHistoriaEduIEdad] = useState<any>('');
    const [vl_AMHistoriaEduIAdaptacion, setAMHistoriaEduIAdaptacion] = useState<any>('');
    const [vl_AMHistoriaEduIDificultades, setAMHistoriaEduIDificultades] = useState<any>('');
    const [vl_AMHistoriaEduIConductasDisfuncionales, setAMHistoriaEduIConductasDisfuncionales] = useState<any>('');
    const [vl_AMHistoriaEduPEdad, setAMHistoriaEduPEdad] = useState<any>('');
    const [vl_AMHistoriaEduPRendimientoBueno, setAMHistoriaEduPRendimientoBueno] = useState<boolean>(false);
    const [vl_AMHistoriaEduPRendimientoRegular, setAMHistoriaEduPRendimientoRegular] = useState<boolean>(false);
    const [vl_AMHistoriaEduPRendimientoBajo, setAMHistoriaEduPRendimientoBajo] = useState<boolean>(false);
    const [vl_AMHistoriaEduPDificultades, setAMHistoriaEduPDificultades] = useState<any>('');
    const [vl_AMHistoriaEduPNivelAdaptacion, setAMHistoriaEduPNivelAdaptacion] = useState<any>('');
    const [vl_AMHistoriaEduSEdad, setAMHistoriaEduSEdad] = useState<any>('');
    const [vl_AMHistoriaEduSRendimientoBueno, setAMHistoriaEduSRendimientoBueno] = useState<boolean>(false);
    const [vl_AMHistoriaEduSRendimientoRegular, setAMHistoriaEduSRendimientoRegular] = useState<boolean>(false);
    const [vl_AMHistoriaEduSRendimientoBajo, setAMHistoriaEduSRendimientoBajo] = useState<boolean>(false);
    const [vl_AMHistoriaEduSDificultades, setAMHistoriaEduSDificultades] = useState<any>('');
    const [vl_AMHistoriaEduSNivelAdaptacion, setAMHistoriaEduSNivelAdaptacion] = useState<any>('');
    const [vl_AMHistoriaEduSQ1Txt, setAMHistoriaEduSQ1Txt] = useState<any>('');
    const [vl_AMHistoriaEduSQ2Txt, setAMHistoriaEduSQ2Txt] = useState<any>('');
    const [vl_AMHistoriaEduSQ3, setAMHistoriaEduSQ3] = useState<any>('');
    const [vl_AMHistoriaEduSQ3Txt, setAMHistoriaEduSQ3Txt] = useState<any>('');
    //Conducta en clases. 
    const [vl_AMHistoriaEduSQ4Txt, setAMHistoriaEduSQ4Txt] = useState<any>('');
    const [vl_AMHistoriaEduSQ5Txt, setAMHistoriaEduSQ5Txt] = useState<any>('');
    const [vl_AMHistoriaEduSQ6Txt, setAMHistoriaEduSQ6Txt] = useState<any>('');
    const [vl_AMHistoriaEduSQ7Txt, setAMHistoriaEduSQ7Txt] = useState<any>('');
    const [vl_AMHistoriaEduSQ8Txt, setAMHistoriaEduSQ8Txt] = useState<any>('');
    // Opinión del profesor.
    const [vl_AMHistoriaEduSQ9Txt, setAMHistoriaEduSQ9Txt] = useState<any>('');
    const [vl_AMHistoriaEduSQ10Txt, setAMHistoriaEduSQ10Txt] = useState<any>('');
    const [vl_AMHistoriaEduSQ11Txt, setAMHistoriaEduSQ11Txt] = useState<any>('');

    // PSICOSEXUALIDAD
    const [vl_AMPsicosexualidadQ1, setAMPsicosexualidadQ1] = useState<any>(''); //¿A qué edad hizo preguntas su hijo, sobre sexo y procreación? Información que se le brindó ¿Cómo?
    const [vl_AMPsicosexualidadQ2, setAMPsicosexualidadQ2] = useState<any>(''); //¿Tiene amigos(as) del sexo opuesto?
    const [vl_AMPsicosexualidadQ3, setAMPsicosexualidadQ3] = useState<any>(''); //¿Su hijo presenta conductas como tocar o sobar los genitales? SI/NO

    // OPINIÓN Y ACTITUDES DE LOS PADRES CON RELACIÓN AL HIJO:
    const [vl_AMOpinionActRelacionMamaRechazo, setAMOpinionActRelacionMamaRechazo] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionMamaPreocupacion, setAMOpinionActRelacionMamaPreocupacion] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionMamaVerguenza, setAMOpinionActRelacionMamaVerguenza] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionMamaIndiferencia, setAMOpinionActRelacionMamaIndiferencia] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionMamaAceptacion, setAMOpinionActRelacionMamaAceptacion] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionMamaDescrTxt, setAMOpinionActRelacionMamaDescrTxt] = useState<any>('');

    const [vl_AMOpinionActRelacionPadreRechazo, setAMOpinionActRelacionPadreRechazo] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionPadrePreocupacion, setAMOpinionActRelacionPadrePreocupacion] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionPadreVerguenza, setAMOpinionActRelacionPadreVerguenza] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionPadreIndiferencia, setAMOpinionActRelacionPadreIndiferencia] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionPadreAceptacion, setAMOpinionActRelacionPadreAceptacion] = useState<boolean>(false);
    const [vl_AMOpinionActRelacionPadreDescrTxt, setAMOpinionActRelacionPadreDescrTxt] = useState<any>('');
    const [vl_AMOpinionActQ1, setAMOpinionActQ1] = useState<any>('');  //¿Cuál fue el comportamiento del menor al nacimiento de su hermano?
    const [vl_AMOpinionActQ2, setAMOpinionActQ2] = useState<any>('');  //¿Cómo era la relación con sus hermanos en la infancia?
    const [vl_AMOpinionActQ3, setAMOpinionActQ3] = useState<any>('');  //Cambios: Aislarlo o dejarlo con el grupo, exigirle un comportamiento similar, mayor atención, sobreprotección
    const [vl_AMOpinionActQ4, setAMOpinionActQ4] = useState<any>('');  //Pautas de crianza: ¿Empleaba castigos, refuerzos positivos y con qué frecuencia? 
    // Comportamiento del niño con los padres, hermanos, amigos, entre otros
    const [vl_AMOpinionActQ5, setAMOpinionActQ5] = useState<any>('');  //Cuidadores principales en la infancia: 
    const [vl_AMOpinionActQ6, setAMOpinionActQ6] = useState<any>('');  //Cuidadores principales en la niñez
    const [vl_AMOpinionActQ7, setAMOpinionActQ7] = useState<any>('');  //Actualmente: ¿Con quién tiene mayor apego el niño? 

    //ÁREA FAMILIAR    
    const [vl_AMAreaFamiliarEnfermedadPsiquiatrica, setAAAreaFamiliarEnfermedadPsiquiatrica] = useState<any>(''); //Especificar enfermedades psiquiátricas
    const [vl_AMAreaFamiliarQ1, setAMAreaFamiliarQ1] = useState<any>(''); //Problemas del habla, dificultades en el aprendizaje, epilepsias, convulsiones, retardo mental, entre otros).        
    const [vl_AMAreaFamiliarCaraterPapa, setAMAreaFamiliarCaraterPapa] = useState<any>('');  //Carácter de los padres:
    const [vl_AMAreaFamiliarCaraterMama, setAMAreaFamiliarCaraterMama] = useState<any>('');  //Carácter de los padres:
    const [vl_AMAreaFamiliarQ2, setAMAreaFamiliarQ2] = useState<any>('');  //Relación de pareja: ¿Qué ha observado el niño de la relación?
    const [vl_AMAreaFamiliarQ3, setAMAreaFamiliarQ3] = useState<any>('');  //¿Cómo es la relación de su papá con el menor?
    const [vl_AMAreaFamiliarQ4, setAMAreaFamiliarQ4] = useState<any>('');  //¿Cómo es la relación de su mamá con el menor?
    const [vl_AMAreaFamiliarQ5, setAMAreaFamiliarQ5] = useState<any>('');  //¿Cómo es la relación con sus hermanos?
    const [vl_AMAreaFamiliarQ6, setAMAreaFamiliarQ6] = useState<any>('');  //Observaciones durante entrvista a los padres

    // @@ANAMNESIS ..end

    // @@ANTECEDENTES ..end
    const [vl_ExamenFisicoFRespiratoria, setExamenFisicoFRespiratoria] = useState<any>('');
    const [vl_ExamenFisicoFCardiaca, setExamenFisicoFCardiaca] = useState<any>('');
    const [vl_ExamenFisicoPArterial, setExamenFisicoPArterial] = useState<any>('');
    const [vl_ExamenFisicoTemperatura, setExamenFisicoTemperatura] = useState<any>('');
    const [vl_ExamenFisicoPeso, setExamenFisicoPeso] = useState<any>('');
    const [vl_ExamenFisicoTalla, setExamenFisicoTalla] = useState<any>('');
    const [vl_ExamenFisicoIMC, setExamenFisicoIMC] = useState<any>('');
    const [vl_ExamenFisicoEscalaNumericaMalestar, setExamenFisicoEscalaNumericaMalestar] = useState<any>([0, 100]);
    const [vl_ExamenFisicoOtros, setExamenFisicoOtros] = useState<any>('');
    const [vl_ExamenFisicoPNecesidadesEspeciales, setExamenFisicoPNecesidadesEspeciales] = useState<any>('NO'); //SI O NO
    // @[] ¿Paciente pertenece a uno de lo siguientes grupos de pacientes con necesidades especiales?
    const [vl_ExamenFisicoPNENino, setExamenFisicoPNENino] = useState<boolean>(false);
    const [vl_ExamenFisicoPNEAdolescente, setExamenFisicoPNEAdolescente] = useState<boolean>(false);
    const [vl_ExamenFisicoPNEAnciano, setExamenFisicoPNEAnciano] = useState<boolean>(false);
    const [vl_ExamenFisicoPNEDependenciaDrogas, setExamenFisicoPNEDependenciaDrogas] = useState<boolean>(false);
    const [vl_ExamenFisicoPNEVictimasAbusoAbandono, setExamenFisicoPNEVictimasAbusoAbandono] = useState<boolean>(false);
    const [vl_ExamenFisicoPNEQuimioterapiaRadioterapia, setExamenFisicoPNEQuimioterapiaRadioterapia] = useState<boolean>(false);
    const [vl_ExamenFisicoPNETrastornosEmocionalesPsiquiatricos, setExamenFisicoPNETrastornosEmocionalesPsiquiatricos] = useState<boolean>(false);
    const [vl_ExamenFisicoPNEEnfermedadInfecciosasContagiosas, setExamenFisicoPNEEnfermedadInfecciosasContagiosas] = useState<boolean>(false);
    const [vl_ExamenFisicoPNEEnfermedadTerminal, setExamenFisicoPNEEnfermedadTerminal] = useState<boolean>(false);
    const [vl_ExamenFisicoPNEFaseFinalEmbarazo, setExamenFisicoPNEFaseFinalEmbarazo] = useState<boolean>(false);
    const [vl_ExamenFisicoPNESistemaInmunologicoAfectado, setExamenFisicoPNESistemaInmunologicoAfectado] = useState<boolean>(false);
    // @@IMPRESION DIAGNOSTICA[grid]
    const [gridDataImpresionDiagnostica, setGridDataImpresionDiagnostica] = useState<any>([]);
    const [vl_ImpresionDiagnosticaNombre, setImpresionDiagnosticaNombre] = useState<any>('');
    const [vl_ImpresionDiagnosticaCodigoCIE10, setImpresionDiagnosticaCodigoCIE10] = useState<any>('');
    //@@PLAN DE TRABAJO
    const [vl_PlanTrabajoExamenesAyudaDiagnosticaPsicologicos, setPlanTrabajoExamenesAyudaDiagnosticaPsicologicos] = useState<any>('');
    const [vl_PlanTrabajoExamenesAyudaDiagnosticaOtros, setPlanTrabajoExamenesAyudaDiagnosticaOtros] = useState<any>('');
    const [vl_PlanTrabajoProcedimientos, setPlanTrabajoProcedimientos] = useState<any>('');
    const [vl_PlanTrabajoInterconsultas, setPlanTrabajoInterconsultas] = useState<any>('');
    const [vl_PlanTrabajoReferencia, setPlanTrabajoReferencia] = useState<any>('');
    const [vl_PlanTrabajoTratamiento, setPlanTrabajoTratamiento] = useState<any>('');
    //
    const [gridDataStaff, setGridDataStaff] = useState<any>([]);
    const [vl_ProfesionalTratanteNombreIdStaff, setProfesionalTratanteNombreIdStaff] = useState<any>('');
    const [vl_ProfesionalTratanteNombre, setProfesionalTratanteNombre] = useState<any>('');
    const [vl_ProfesionalTratantePeriodoDesde, setProfesionalTratantePeriodoDesde] = useState<any>('');
    const [vl_ProfesionalTratantePeriodoHasta, setProfesionalTratantePeriodoHasta] = useState<any>('');
    const [vl_ProfesionalTratanteEstado, setProfesionalTratanteEstado] = useState<any>('');

    //EVALUCION
    const [gridDataEvolucion, setGridDataEvolucion] = useState<any>([]);
    const [gridDataEvolucionSelected, setGridDataEvolucionSelected] = useState<any>([]);

    //Derivaciones
    const [gridDataDerivaciones, setGridDataDerivaciones] = useState<any>([]);


    const [vl_EvolucionNumber, setEvolucionNumber] = useState<any>('0');
    const [vl_EvolucionFecha, setEvolucionFecha] = useState<any>('');
    const [vl_EvolucionIdStaff, setEvolucionIdStaff] = useState<any>('');
    const [vl_EvolucionIdStaffNombre, setEvolucionIdStaffNombre] = useState<any>('');
    const [vl_EvolucionIdOrdenPago, setEvolucionIdOrdenPago] = useState<any>('');
    const [vl_EvolucionIdOrdenPagoDetalle, setEvolucionIdOrdenPagoDetalle] = useState<any>('');
    const [vl_EvolucionNumeroSesion, setEvolucionNumeroSesion] = useState<any>('0');
    const [vl_EvolucionNumeroSesionTotal, setEvolucionNumeroSesionTotal] = useState<any>('0');
    const [vl_EvolucionDetalleTxt, setEvolucionDetalleTxt] = useState<any>('');

    // FILE UPLOAD
    const [vl_totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef<any>(null);
    const [gridFilesLoaded, setGridFilesLoaded] = useState([]);

    //Parientes  
    const [gridDataPariente, setGridDataPariente] = useState<any>([]);
    // const [gridDataEvolucionSelected, setGridDataEvolucionSelected] = useState<any>([]);

    const [vl_IdPariente, setIdPariente] = useState<any>(0);
    const [vl_IdTipoPariente, setIdTipoPariente] = useState<any>('0');
    const [vl_TipoPariente, setTipoPariente] = useState<any>('');

    const [vl_NombresPariente, setNombresPariente] = useState<any>('');
    const [vl_EdadPariente, setEdadPariente] = useState<any>('');
    const [vl_IdGradoInstruccionPariente, setIdGradoInstruccionPariente] = useState<any>('0');
    const [vl_GradoInstruccionPariente, setGradoInstruccionPariente] = useState<any>('');
    const [vl_IdOcupacionPariente, setIdOcupacionPariente] = useState<any>('0');
    const [vl_OcupacionPariente, setOcupacionPariente] = useState<any>('');
    const [vl_OcupacionParienteDetalle, setOcupacionParienteDetalle] = useState<any>('');


    //Analisis funcional de la conducta
    const [vl_AFuncionalId, setAFuncionalId] = useState<any>(0);
    const [vl_AFuncionalAntecedente, setAFuncionalAntecedente] = useState<any>('');
    const [vl_AFuncionalConducta, setAFuncionalConducta] = useState<any>('');
    const [vl_AFuncionalConsecuencia, setAFuncionalConsecuencia] = useState<any>('');
    const [gridDataAFuncionalConducta, setGridDataAFuncionalConducta] = useState<any>([]);

    //PLAN DE EVALUACION 
    const [vl_PlanEvaDescripcionArea, setPlanEvaDescripcionArea] = useState<any>('');
    const [vl_PlanEvaPruebaPsicologica, setvPlanEvaPruebaPsicologica] = useState<any>('');
    const [vl_PlanEvaPruebaCodigoServicio, setPlanEvaPruebaCodigoServicio] = useState<any>('');
    const [vl_PlanEvaIdOrdenAtencion, setPlanEvaIdOrdenAtencion] = useState<any>(0);

    // Notificaciones
    const [visibleTop, setVisibleTop] = useState<boolean>(false);
    const [dataNotificacion, setDataNotificacion] = useState<any>([]);

    const navigate = useNavigate();
    const renderHeaderEditor01 = () => {
        return (
            <>
                <span className="ql-formats">
                    <button className="ql-bold" aria-label="Bold"></button>
                    <button className="ql-italic" aria-label="Italic"></button>
                    <button className="ql-underline" aria-label="Underline"></button>
                </span>
            </>
        );
    }
    const header01 = renderHeaderEditor01();

    const handleClickBuscar = (e: any) => {
        isMounted.current = true;
        openModal();
    }
    const setIsMounted = (val: boolean) => {
        isMounted.current = val;
    }
    const getDataCliente = (data: any) => {
        setNumeroHistoriaClinica(data.NumeroHistoriaClinica);
        getHistoriaClinica(data.NumeroHistoriaClinica);
    }
    const BuscadorClientesForm = () => {
        return (
            <BuscadorClientes
                isOpen={isOpenModal}
                isHide={closeModal}
                getDataCliente={getDataCliente}
                setIsMounted={setIsMounted}
            />
        );
    }

    const link_form_close = () => {
        navigate('/WelcomePage');
    }

    const link_form_informes = (in_IdHistoriaClinica: any, in_IdInforme: any, in_mode: any) => {
        if (in_IdHistoriaClinica === 0 || in_IdHistoriaClinica === undefined) {
            toastConfirm.current.show({ severity: 'info', detail: 'Debe buscar un paciente', life: 6000 });
            return;
        } else
            navigate('/HistoriaClinicaForm/Informe/' + in_IdHistoriaClinica + '/' + in_mode + '/' + in_IdInforme);
    }

    const link_formPlanEvaluacion = (in_IdHistoriaClinica: any, vmode: any) => {
        if (in_IdHistoriaClinica === 0 || in_IdHistoriaClinica === undefined) {
            toastConfirm.current.show({ severity: 'info', detail: 'Debe buscar un paciente', life: 6000 });
            return;
        } else
            navigate('/HistoriaClinicaForm/PlanEvaluacion/' + in_IdHistoriaClinica + '/' + vmode);
    }

    const link_formPlanTerapia = (in_IdHistoriaClinica: any, vmode: any) => {
        if (in_IdHistoriaClinica === 0 || in_IdHistoriaClinica === undefined) {
            toastConfirm.current.show({ severity: 'info', detail: 'Debe buscar un paciente', life: 6000 });
            return;
        } else
            navigate('/HistoriaClinicaForm/PlanTerapia/' + in_IdHistoriaClinica + '/' + vmode);

    }
    const link_form_derivacion = (in_NHistoriaClinica: any) => {
        if (in_NHistoriaClinica === '' || in_NHistoriaClinica === undefined) {
            toastConfirm.current.show({ severity: 'info', detail: 'Debe buscar un paciente', life: 6000 });
            return;
        } else
            navigate('/DerivacionPaciente/' + in_NHistoriaClinica);
    }

    // CRUD GRID IMPRESION DIAGNNOSTICA 
    const gridImpresionDiagnosticaTextEditor = (options: any) => {
        return <InputText type="text" className='w-100' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    const actionBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => gridDeleteItem(rowData)} />
            </React.Fragment>
        );
    }
    const gridDeleteItem = (item: any) => {
        let itemsfiltered = gridDataImpresionDiagnostica.filter(function (element: any) {
            // console.log(element);
            return element.ImpresionDiagnosticaNumber ? element.ImpresionDiagnosticaNumber !== item.ImpresionDiagnosticaNumber : [];
        });
        setGridDataImpresionDiagnostica(itemsfiltered);
    }

    let emptyProduct = {
        ImpresionDiagnosticaNumber: 0,
        ImpresionDiagnosticaNombre: '',
        ImpresionDiagnosticaCodigoCIE10: ''
    };
    const [product, setProduct] = useState(emptyProduct);
    const setImpresionDiagnosticaAgregar = () => {
        emptyProduct = {
            ImpresionDiagnosticaNumber: 0,
            ImpresionDiagnosticaNombre: vl_ImpresionDiagnosticaNombre,
            ImpresionDiagnosticaCodigoCIE10: vl_ImpresionDiagnosticaCodigoCIE10
        }

        if (vl_ImpresionDiagnosticaNombre.trim() === '') {
            toast.current.show({ severity: 'info', detail: 'Ingresar nombre del diagnóstico', life: 6000 });
            return;
        }
        if (vl_ImpresionDiagnosticaCodigoCIE10.trim() === '') {
            toast.current.show({ severity: 'info', detail: 'Ingresar codigo CIE-10', life: 6000 });
            return;
        }

        setProduct(emptyProduct);
        if (emptyProduct.ImpresionDiagnosticaNombre.trim()) {
            let _products = [...gridDataImpresionDiagnostica];
            let _product = { ...emptyProduct };
            if (product.ImpresionDiagnosticaNumber) {
                const index = findIndexById(emptyProduct.ImpresionDiagnosticaNumber);
                _products[index] = _product;
            }
            else {
                _product.ImpresionDiagnosticaNumber = createId();
                _product.ImpresionDiagnosticaNombre = emptyProduct.ImpresionDiagnosticaNombre;
                _product.ImpresionDiagnosticaCodigoCIE10 = emptyProduct.ImpresionDiagnosticaCodigoCIE10
                _products.push(_product);
            }
            setGridDataImpresionDiagnostica(_products);
            // reset
            setImpresionDiagnosticaNombre('');
            setImpresionDiagnosticaCodigoCIE10('');
        }
    }
    const findIndexById = (id: any) => {
        let index = -1;
        for (let i = 0; i < gridDataImpresionDiagnostica.length; i++) {
            if (gridDataImpresionDiagnostica[i].ImpresionDiagnosticaNumber === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const createId = () => {
        let index = 0;
        gridDataImpresionDiagnostica.forEach(function (row: any) {
            index = parseFloat(row.ImpresionDiagnosticaNumber);
        });
        index = index + 1;
        return index;
    }

    const onRowEditComplete1 = (e: any) => {
        let _products2 = [...gridDataImpresionDiagnostica];
        let { newData, index } = e;
        _products2[index] = newData;
        setGridDataImpresionDiagnostica(_products2);
    }
    // CRUD GRID IMPRESION DIAGNNOSTICA ...end


    //CRUD GRID PROFESIONAL TRATANTE

    const gridProfesionalTratanteEditor = (options: any) => {
        // return <Calendar className="p-inputtext-sm w-100" dateFormat="yy/mm/dd" showIcon={true} monthNavigator yearNavigator value={options.value} onChange={(e) => options.editorCallback(e.value)}/>
        return <InputText type="text" className='w-100' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    const gridProfesionalTratanteStatusEditor = (options: any) => {
        return <InputSwitch
            checked={options.value}
            onChange={(e) => options.editorCallback(e.target.value)}
        />;
        // return <InputText type="text" className='w-100' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    }
    const actionBodyTemplate2 = (rowData: any) => {
        // console.log(rowData);
        return (
            <React.Fragment> <InputSwitch checked={rowData.ProfesionalTratanteEstado} /> </React.Fragment>
        );
    }
    const actionBodyTemplate3 = (rowData: any) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-small p-button-danger" onClick={() => gridDeleteItem2(rowData)} />
            </React.Fragment>
        );
    }
    const gridDeleteItem2 = (item: any) => {
        let itemsfiltered = gridDataStaff.filter(function (element: any) {
            return element.ProfesionalTratanteNumber ? element.ProfesionalTratanteNumber !== item.ProfesionalTratanteNumber : [];
        });
        setGridDataStaff(itemsfiltered);
    }

    let emptyProduct2 = {
        ProfesionalTratanteNumber: 0,
        ProfesionalTratanteNombreIdStaff: '0',
        ProfesionalTratanteNombre: '',
        ProfesionalTratantePeriodoDesde: '',
        ProfesionalTratantePeriodoHasta: '',
        ProfesionalTratanteEstado: ''
    };
    const [product2, setProduct2] = useState(emptyProduct2);
    const setProfesionalTranteAgregar = () => {
        emptyProduct2 = {
            ProfesionalTratanteNumber: 0,
            ProfesionalTratanteNombreIdStaff: vl_ProfesionalTratanteNombreIdStaff,
            ProfesionalTratanteNombre: vl_ProfesionalTratanteNombre,
            ProfesionalTratantePeriodoDesde: vl_ProfesionalTratantePeriodoDesde,
            ProfesionalTratantePeriodoHasta: vl_ProfesionalTratantePeriodoHasta,
            ProfesionalTratanteEstado: vl_ProfesionalTratanteEstado
        }

        if (vl_ProfesionalTratanteNombreIdStaff === '0') {
            toastConfirm.current.show({ severity: 'info', detail: 'Seleccionar Profesional', life: 6000 });
            return;
        }

        setProduct2(emptyProduct2);
        if (emptyProduct2.ProfesionalTratanteNombreIdStaff !== '0') {
            let _products = [...gridDataStaff];
            let _product = { ...emptyProduct2 };
            if (product2.ProfesionalTratanteNumber) {
                const index = findIndexById2(emptyProduct2.ProfesionalTratanteNumber);
                _products[index] = _product;
            }
            else {
                _product.ProfesionalTratanteNumber = createId2();
                _product.ProfesionalTratanteNombreIdStaff = emptyProduct2.ProfesionalTratanteNombreIdStaff;
                _product.ProfesionalTratanteNombre = emptyProduct2.ProfesionalTratanteNombre;
                _product.ProfesionalTratantePeriodoDesde = emptyProduct2.ProfesionalTratantePeriodoDesde;
                _product.ProfesionalTratantePeriodoHasta = emptyProduct2.ProfesionalTratantePeriodoHasta;
                _product.ProfesionalTratanteEstado = emptyProduct2.ProfesionalTratanteEstado;
                _products.push(_product);
            }
            setGridDataStaff(_products);
            // reset
            // setImpresionDiagnosticaNombre('');
            // setImpresionDiagnosticaCodigoCIE10('');
        }
    }
    const findIndexById2 = (id: any) => {
        let index = -1;
        for (let i = 0; i < gridDataStaff.length; i++) {
            if (gridDataStaff[i].ProfesionalTratanteNumber === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const createId2 = () => {
        let index = 0;
        gridDataStaff.forEach(function (row: any) {
            index = parseFloat(row.ProfesionalTratanteNumber);
        });
        index = index + 1;
        return index;
    }

    const onRowEditComplete2 = (e: any) => {
        let _products2 = [...gridDataStaff];
        let { newData, index } = e;
        _products2[index] = newData;
        setGridDataStaff(_products2);
    }
    //CRUD GRID PROFESIONAL TRATANTE ...end

    const actionBodyTemplate8 = (row: any) => {

        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-small p-button-warning"
                    onClick={() => link_form_informes(vl_IdHistoriaClinica, row.IdInforme, 'Edit')}
                />
            </React.Fragment>
        );
    }

    //CRUD GRID evolucion
    // const gridEvolucionEditor = (options: any) => {
    //     return <InputText type="text" className='w-100' value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    // }

    const buildPDFFormatoEvolucionPaciente = async () => {
        if (vl_IdHistoriaClinica === 0) {
            toastConfirm.current.show({ severity: 'info', detail: 'Debe buscar un paciente para imprimir', life: 6000 });
            return;
        }
        const endpoint = 'reports/formatoEvolucionPaciente/?IdHistoriaClinica=' + vl_IdHistoriaClinica;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }
    const buildPDFFormatoHistoriaClinica = async () => {
        if (vl_IdHistoriaClinica === 0) {
            toastConfirm.current.show({ severity: 'info', detail: 'Debe buscar un paciente para imprimir', life: 6000 });
            return;
        }
        const endpoint = 'reports/formatoHistoriaClinica/?IdHistoriaClinica=' + vl_IdHistoriaClinica;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        window.open(url);
    }
    const actionBodyTemplate4 = (rowData: any) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-small p-button-danger" onClick={() => gridDeleteItem3(rowData)} />
            </React.Fragment>
        );
    }
    const gridDeleteItem3 = (item: any) => {
        let itemsfiltered = gridDataEvolucion.filter(function (element: any) {
            return element.EvolucionNumber ? element.EvolucionNumber !== item.EvolucionNumber : [];
        });
        setGridDataEvolucion(itemsfiltered);
    }

    let emptyProduct3 = {
        EvolucionNumber: 0,
        EvolucionFecha: '',
        EvolucionIdStaff: '',
        EvolucionIdStaffNombre: '',
        EvolucionIdOrdenPago: '',
        EvolucionIdOrdenPagoDetalle: '',
        EvolucionNumeroSesion: '',
        EvolucionNumeroSesionTotal: '',
        EvolucionDetalleTxt: ''
    };
    // const [product3, setProduct3] = useState(emptyProduct3);

    const setEvolucionNuevo = () => {
        setEvolucionNumber(0);
        setEvolucionFecha(new Date());
        setEvolucionIdStaff('0');
        setEvolucionIdStaffNombre('');
        setEvolucionIdOrdenPago(0);
        setEvolucionIdOrdenPagoDetalle('');
        setEvolucionNumeroSesion('0');
        setEvolucionNumeroSesionTotal('0');
        setEvolucionDetalleTxt('');
    }

    const setEvolucionAgregar = () => {
        emptyProduct3 = {
            EvolucionNumber: vl_EvolucionNumber,
            EvolucionFecha: setIntlDateTimeFormat(vl_EvolucionFecha),
            EvolucionIdStaff: vl_EvolucionIdStaff,
            EvolucionIdStaffNombre: vl_EvolucionIdStaffNombre,
            EvolucionIdOrdenPago: vl_EvolucionIdOrdenPago,
            EvolucionIdOrdenPagoDetalle: vl_EvolucionIdOrdenPagoDetalle,
            EvolucionNumeroSesion: vl_EvolucionNumeroSesion,
            EvolucionNumeroSesionTotal: vl_EvolucionNumeroSesionTotal,
            EvolucionDetalleTxt: vl_EvolucionDetalleTxt
        }

        if (vl_EvolucionFecha === '') {
            toastConfirm.current.show({ severity: 'info', detail: 'Ingresar fecha', life: 6000 });
            return;
        }
        if (vl_EvolucionIdStaff === '0') {
            toastConfirm.current.show({ severity: 'info', detail: 'Seleccionar Profesional', life: 6000 });
            return;
        }
        if (vl_EvolucionIdOrdenPago === '0') {
            toastConfirm.current.show({ severity: 'info', detail: 'Seleccionar Servicio', life: 6000 });
            return;
        }
        if (vl_EvolucionDetalleTxt === '') {
            toastConfirm.current.show({ severity: 'info', detail: 'Ingresar detalle evolución', life: 6000 });
            return;
        }

        // setProduct3(emptyProduct3);

        if (emptyProduct3.EvolucionIdStaff !== '0') {
            let _products = [...gridDataEvolucion];
            let _product = { ...emptyProduct3 };
            // console.log(product3);
            // if (product3.EvolucionNumber) {
            if (vl_EvolucionNumber > 0) {
                // console.log(emptyProduct3.EvolucionNumber);
                const index = findIndexById3(emptyProduct3.EvolucionNumber);
                _products[index] = _product;
            }
            else {
                _product.EvolucionNumber = createId3();
                _product.EvolucionFecha = emptyProduct3.EvolucionFecha;
                _product.EvolucionIdStaff = emptyProduct3.EvolucionIdStaff;
                _product.EvolucionIdStaffNombre = emptyProduct3.EvolucionIdStaffNombre;
                _product.EvolucionIdOrdenPago = emptyProduct3.EvolucionIdOrdenPago;
                _product.EvolucionIdOrdenPagoDetalle = emptyProduct3.EvolucionIdOrdenPagoDetalle;
                _product.EvolucionNumeroSesion = emptyProduct3.EvolucionNumeroSesion;
                _product.EvolucionNumeroSesionTotal = emptyProduct3.EvolucionNumeroSesionTotal;
                _product.EvolucionDetalleTxt = emptyProduct3.EvolucionDetalleTxt;
                _products.push(_product);
            }
            setGridDataEvolucion(_products);
            // reset
            // setImpresionDiagnosticaNombre('');
            // setImpresionDiagnosticaCodigoCIE10('');
        }
    }
    const findIndexById3 = (id: any) => {
        let index = -1;
        for (let i = 0; i < gridDataEvolucion.length; i++) {
            if (gridDataEvolucion[i].EvolucionNumber === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const createId3 = () => {
        let index = 0;
        gridDataEvolucion.forEach(function (row: any) {
            index = parseFloat(row.EvolucionNumber);
        });
        index = index + 1;
        return index;
    }

    // const onRowEditComplete3 = (e: any) => {
    //     let _products2 = [...gridDataEvolucion];
    //     let { newData, index } = e;
    //     _products2[index] = newData;
    //     setGridDataEvolucion(_products2);
    // }
    //CRUD GRID evolucion ...end


    // crud PARIENTES
    const actionBodyTemplate5 = (rowData: any) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-small p-button-danger" onClick={() => gridDeleteItem4(rowData)} />
            </React.Fragment>
        );
    }
    const gridDeleteItem4 = (item: any) => {
        let itemsfiltered = gridDataPariente.filter(function (element: any) {
            return element.IdPariente ? element.IdPariente !== item.IdPariente : [];
        });
        setGridDataPariente(itemsfiltered);
    }

    let emptyProduct4 = {
        IdPariente: 0,
        IdTipoPariente: '',
        TipoPariente: '',
        NombresPariente: '',
        EdadPariente: '0',
        IdGradoInstruccionPariente: '',
        GradoInstruccionPariente: '', //descrip
        IdOcupacionPariente: '',
        OcupacionPariente: '', //descrip
        OcupacionParienteDetalle: '' //detalle
    };
    // const [product3, setProduct3] = useState(emptyProduct3);

    const setParienteNuevo = () => {
        setIdPariente(0);
        setIdTipoPariente('0');
        setTipoPariente('');
        setNombresPariente('');
        setEdadPariente('0');
        setIdGradoInstruccionPariente('');
        setGradoInstruccionPariente('');
        setIdOcupacionPariente('');
        setOcupacionPariente('');
        setOcupacionParienteDetalle('');
    }

    const setParienteAgregar = () => {
        emptyProduct4 = {
            IdPariente: vl_IdPariente,
            IdTipoPariente: vl_IdTipoPariente,
            TipoPariente: vl_TipoPariente,
            NombresPariente: vl_NombresPariente,
            EdadPariente: vl_EdadPariente,
            IdGradoInstruccionPariente: vl_IdGradoInstruccionPariente,
            GradoInstruccionPariente: vl_GradoInstruccionPariente,
            IdOcupacionPariente: vl_IdOcupacionPariente,
            OcupacionPariente: vl_OcupacionPariente,
            OcupacionParienteDetalle: vl_OcupacionParienteDetalle
        }
        // console.log(vl_IdTipoPariente);
        // console.log(vl_NombresPariente);

        if (vl_IdTipoPariente === '0') {
            toastConfirm.current.show({ severity: 'info', detail: 'Seleccionar Tipo Pariente', life: 6000 });
            return;
        }
        if (vl_NombresPariente === '') {
            toastConfirm.current.show({ severity: 'info', detail: 'Ingresar nombre pariente', life: 6000 });
            return;
        }

        if (emptyProduct4.NombresPariente !== '') {
            let _products = [...gridDataPariente];
            let _product = { ...emptyProduct4 };
            if (vl_IdPariente > 0) {
                const index = findIndexById4(emptyProduct4.IdPariente);
                _products[index] = _product;
            }
            else {
                _product.IdPariente = createId4();
                _product.IdTipoPariente = emptyProduct4.IdTipoPariente;
                _product.TipoPariente = emptyProduct4.TipoPariente;
                _product.NombresPariente = emptyProduct4.NombresPariente;
                _product.EdadPariente = emptyProduct4.EdadPariente;
                _product.IdGradoInstruccionPariente = emptyProduct4.IdGradoInstruccionPariente;
                _product.GradoInstruccionPariente = emptyProduct4.GradoInstruccionPariente;
                _product.IdOcupacionPariente = emptyProduct4.IdOcupacionPariente;
                _product.OcupacionPariente = emptyProduct4.OcupacionPariente;
                _product.OcupacionParienteDetalle = emptyProduct4.OcupacionParienteDetalle;
                _products.push(_product);
            }
            setGridDataPariente(_products);
        }
        // nuevo
        setParienteNuevo();
    }
    const findIndexById4 = (id: any) => {
        let index = -1;
        for (let i = 0; i < gridDataPariente.length; i++) {
            if (gridDataPariente[i].IdPariente === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const createId4 = () => {
        let index = 0;
        gridDataPariente.forEach(function (row: any) {
            index = parseFloat(row.IdPariente);
        });
        index = index + 1;
        return index;
    }
    // crud PARIENTES ...FIN

    // crud: ANALISIS FUNCIONAL DE CONDUCTA
    let emptyProduct5 = {
        AFuncionalId: 0,
        AFuncionalAntecedente: '',
        AFuncionalConducta: '',
        AFuncionalConsecuencia: ''
    };
    const setAFuncionalConductaAgregar = () => {
        emptyProduct5 = {
            AFuncionalId: vl_AFuncionalId,
            AFuncionalAntecedente: vl_AFuncionalAntecedente,
            AFuncionalConducta: vl_AFuncionalConducta,
            AFuncionalConsecuencia: vl_AFuncionalConsecuencia
        }
        if (vl_AFuncionalAntecedente === '' || vl_AFuncionalConducta === '' || vl_AFuncionalConsecuencia === '') {
            toastConfirm.current.show({ severity: 'info', detail: 'Debe ingresar algun dato ', life: 8000 });
            return;
        }
        // console.log(emptyProduct5);        
        let _products = [...gridDataAFuncionalConducta];
        let _product = { ...emptyProduct5 };
        if (vl_AFuncionalId > 0) {
            const index = findIndexById5(emptyProduct5.AFuncionalId);
            _products[index] = _product;
        }
        else {
            _product.AFuncionalId = createId5();
            _product.AFuncionalAntecedente = emptyProduct5.AFuncionalAntecedente;
            _product.AFuncionalConducta = emptyProduct5.AFuncionalConducta;
            _product.AFuncionalConsecuencia = emptyProduct5.AFuncionalConsecuencia
            _products.push(_product);
        }
        setGridDataAFuncionalConducta(_products);
        setAFuncionalConductaNuevo();
    }
    const setAFuncionalConductaNuevo = () => {
        setAFuncionalId(0);
        setAFuncionalAntecedente('');
        setAFuncionalConducta('');
        setAFuncionalConsecuencia('');
    }

    const findIndexById5 = (id: any) => {
        let index = -1;
        for (let i = 0; i < gridDataAFuncionalConducta.length; i++) {
            if (gridDataAFuncionalConducta[i].AFuncionalId === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const createId5 = () => {
        let index = 0;
        gridDataAFuncionalConducta.forEach(function (row: any) {
            index = parseFloat(row.AFuncionalId);
        });
        index = index + 1;
        return index;
    }
    const actionBodyTemplate6 = (rowData: any) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-small p-button-danger" onClick={() => gridDeleteItem5(rowData)} />
            </React.Fragment>
        );
    }
    const gridDeleteItem5 = (item: any) => {
        let itemsfiltered = gridDataAFuncionalConducta.filter(function (element: any) {
            return element.AFuncionalId ? element.AFuncionalId !== item.AFuncionalId : [];
        });
        setGridDataAFuncionalConducta(itemsfiltered);
    }
    // FIN crud: ANALISIS FUNCIONAL DE CONDUCTA


    const progressBarPlan = (item: any) => {
        let porcentaje: any = (item.PlanNroSesion / item.TotalSesion * 100).toFixed(0);
        return (
            <div className='flex justify-content-center w-full' >
                <Knob value={porcentaje} valueTemplate={"{value}%"} size={70} min={0} max={100} />
            </div>
        );
    }
    const progressBarEvolucion = (item: any) => {
        let porcentaje: any = (item.EvolucionNroSesion / item.TotalSesion * 100).toFixed(0);
        return (
            <div className='flex justify-content-center w-full' >
                <Knob value={porcentaje} valueTemplate={"{value}%"} size={70} min={0} max={100}
                    valueColor={"SlateGray"} rangeColor={"MediumTurquoise"}
                />
            </div>
        );
    }

    const setInitValuesAll = async () => {

        setIdHistoriaClinica(0);
        setNumeroHistoriaClinica('');
        setIdCliente(0);
        setFechaAfiliacion('');  //readOnly
        setHoraAfiliacion(''); //readOnly
        setIdTipoDocumento(0);
        setNumeroDocumento('');
        setSexo('');
        setApellidoPaterno('');
        setApellidoMaterno('');
        setNombres('');
        setFechaNacimiento((new Date(1, 1, 1900)));
        setEdad(0);
        setIdEstadoCivil('0');
        setIdGradoInstruccion('0');
        setIdOcupacion('0');
        setIdReligion('0');//new
        setReligion('');
        setNumeroHermanos('0'); //new
        setConquienesViven('');
        setEscolaridad(''); //new
        setInstEducativa(''); //new
        setReferente(''); //new
        setLugarNacimientoDireccion('');
        setLugarProcedenciaDireccion('');
        setDomicilioActualDepartamento('');
        setDomicilioActualProvincia('');
        setDomicilioActualDistrito('');
        setDomicilioActualTipovia('');
        setDomicilioActualNombrevia('');
        setDomicilioActualNumero('');
        setDomicilioActualManzana('');
        setDomicilioActualLote('');
        setDomicilioActualDpto('');
        setDomicilioActualUrbanizacion('');
        setDatoAcompananteNombres('');
        setDatoAcompananteDni('');

        // @@MOTIVO DE CONSULTA
        setMotivoConsultaTxt_a('*');
        setMotivoConsultaTxt_b('*');
        setMotivoConsultaTxt_c('*');
        // d.       
        setPorte('*');
        setComportamiento('*');
        setActitud('*');

        // @@ANANMESIS
        // setTiempoEnfermedadTxt('0');
        // setFormaInicioEnfermedadTxt('N/A');
        // setMotivoConsultaTxt('');
        // setSintomaSignosTxt('');
        // setRelatoTxt('');

        //PRENATAL
        setAAPrenatal_qa('');
        setAAPrenatal_qb('');
        setAAPrenatal_qc('');
        setAAPrenatal_qd('');
        setAAPrenatal_qe('');
        setAAPrenatalTipoControlM(false);
        setAAPrenatalTipoControlP(false);
        setAAPrenatalTipoControlN(false);
        //PERINTAL            
        setAAPerinatalEdadM('');
        setAAPerinatalEdadP('');
        setAAPerinatalTipoParto('');
        setAAPerinatalTxtTNacio('');
        setAAPerinatalDondeNacio('');
        setAAPerinatalPRNPesoAltura('');
        setAAPerinatalTxtLloroNacer('');
        setAAPerinatalDondeNacioOtros('');
        setAAPerinatalTipoPartoInducido('');
        setAAPerinatalTxtLloroNacerTiempo('');
        //POSNTATAL
        setAAPosnatalQa('');
        setAAPosnatalQb('');
        setAAPosnatalQc('');
        setAAPosnatalQdRespTxt('');
        setAAPosnatalQe('');
        //HISTORIA CLINICA
        setAAHistoriaMedicaTxta('');
        setAAHistoriaMedicaTxtb('');
        setAAHistoriaMedicaQc('');
        setAAHistoriaMedicaConvulsion(false);
        setAAHistoriaMedicaMareos(false);
        setAAHistoriaMedicaEdadTenia('');
        setAAHistoriaMedicaComoFueAtendido('');
        setAAHistoriaMedicaOperaciones('');
        setAAHistoriaMedicaOperacionesTxt('');
        setAAHistoriaMedicaExamenRealidadoN(false);
        setAAHistoriaMedicaExamenRealidadoA(false);
        setAAHistoriaMedicaExamenRealidadoP(false);
        setAAHistoriaMedicaExamenRealidadoPsi(false);
        setAAHistoriaMedicaExamenRealidadoOtros(false);
        setAAHistoriaMedicaExamenRealidadoTxt('');
        setAAHistoriaMedicaDomLatManual('');
        setAAHistoriaMedicaEnfermedadActualTxt('');
        setAAHistoriaMedicaEnfermedadActualTratamientoTxt('');

        //HABILIDAD PARA HABLAR
        setAAHabilidadHablaQ1('');
        setAAHabilidadHablaQ2('');
        setAAHabilidadHablaOmision(false);
        setAAHabilidadHablaSustitucion(false);
        setAAHabilidadHablaDistorsion(false);
        setAAHabilidadHablaQ3('');
        setAAHabilidadHablaQ4('');
        setAAHabilidadHablaQ5('');
        setAAHabilidadHablaCk1(false);
        setAAHabilidadHablaCk2(false);
        setAAHabilidadHablaCk3(false);
        setAAHabilidadHablaCk4(false);
        // a.5 ALIMENTACION
        setAAAlimentacionMaterno(false);
        setAAAlimentacionArtificial(false);
        setAAAlimentacionEdadDestete('');
        setAAAlimentacionProblemaApetito('');
        setAAAlimentacionProblemaApetitoDecripcion('');
        setAAAlimentacionQtyComidasAlDia('3');
        setAAAlimentacionAboutDieta('');
        setAAAlimentacionAboutDietaWhy('');
        // a.6 HIGIENE
        setAAHigieneQ1('');
        setAAHigieneQ2('');
        // a.7 SUEÑO
        setAASuenioQ1('');
        setAASuenioQ2('');
        setAASuenioQ3Habla(false);
        setAASuenioQ3Grita(false);
        setAASuenioQ3SeMueve(false);
        setAASuenioQ3Transpira(false);
        setAASuenioQ3Camina(false);
        setAASuenioQ4('');
        setAASuenioQ5(''); //SI-NO
        setAASuenioQ5Txt(''); //si es: SI

        // INDEPENDENCIA PERSONAL
        setAAIndPersonalQ1('');
        setAAIndPersonalQ2(''); //SI-NO
        setAAIndPersonalQ2Txt(''); //respuesta
        setAAIndPersonalQ3(''); //SI-NO
        setAAIndPersonalQ3Txt(''); //respuesta
        setAAIndPersonalQ4(''); //SI-NO
        setAAIndPersonalQ4Txt(''); //respuesta
        // CONDUCTA
        setAAConductaQ1('');
        setAAConductaQ2('');
        setAAConductaQ3('');
        setAAConductaQ4('');
        setAAConductaQ5('');
        setAAConductaQ6Txt('');
        setAAConductaQ7Txt('');
        setAAConductaQ8Txt('');
        setAAConductaQ9Txt('');
        //JUEGO-SOCIAL
        setAAJuegoSocialQ1('');
        setAAJuegoSocialQ2('');
        setAAJuegoSocialQ3('');
        setAAJuegoSocialQ4('');
        setAAJuegoSocialQ5('');
        //HISTORIA EDUCATIVA
        setAAHistoriaEduIEdad('');
        setAAHistoriaEduIAdaptacion('');
        setAAHistoriaEduIDificultades('');
        setAAHistoriaEduIConductasDisfuncionales('');

        setAAHistoriaEduPEdad('');
        setAAHistoriaEduPRendimientoBueno(false);
        setAAHistoriaEduPRendimientoRegular(false);
        setAAHistoriaEduPRendimientoBajo(false);
        setAAHistoriaEduPDificultades('');
        setAAHistoriaEduPNivelAdaptacion('');

        setAAHistoriaEduSEdad('');
        setAAHistoriaEduSRendimientoBueno(false);
        setAAHistoriaEduSRendimientoRegular(false);
        setAAHistoriaEduSRendimientoBajo(false);
        setAAHistoriaEduSDificultades('');
        setAAHistoriaEduSNivelAdaptacion('');

        setAAHistoriaEduSQ1Txt('');
        setAAHistoriaEduSQ2Txt('');
        setAAHistoriaEduSQ3('');
        setAAHistoriaEduSQ3Txt('');
        //Conducta en clases. 
        setAAHistoriaEduSQ4Txt('');
        setAAHistoriaEduSQ5Txt('');
        setAAHistoriaEduSQ6Txt('');
        setAAHistoriaEduSQ7Txt('');
        setAAHistoriaEduSQ8Txt('');
        // Opinión del profesor.
        setAAHistoriaEduSQ9Txt('');
        setAAHistoriaEduSQ10Txt('');
        setAAHistoriaEduSQ11Txt('');

        // PSICOSEXUALIDAD
        setAAPsicosexualidadQ1('');
        setAAPsicosexualidadQ2('');
        setAAPsicosexualidadQ3('');
        setAAPsicosexualidadQ4('');
        setAAPsicosexualidadQ5('');

        //ÁREA FAMILIAR
        setAAAreaFamiliarRMamaRechazo(false);
        setAAAreaFamiliarRMamaPreocupacion(false);
        setAAAreaFamiliarRMamaVerguenza(false);
        setAAAreaFamiliarRMamaIndiferencia(false);
        setAAAreaFamiliarRMamaAceptacion(false);
        setAAAreaFamiliarRMamaEventoTxt('');

        setAAAreaFamiliarRPapaRechazo(false);
        setAAAreaFamiliarRPapaPreocupacion(false);
        setAAAreaFamiliarRPapaVerguenza(false);
        setAAAreaFamiliarRPapaIndiferencia(false);
        setAAAreaFamiliarRPapaAceptacion(false);
        setAAAreaFamiliarRPapaEventoTxt('');
        setAAAreaFamiliarQ1Txt('');
        setAAAreaFamiliarTxt1('');
        setAAAreaFamiliarQ2Txt('');
        setAAAreaFamiliarTxt2(''); //Cuidadores principales en la niñez: 
        setAAAreaFamiliarQ3Txt('');
        setAAAreaFamiliarTxt3('');
        setAAAreaFamiliarTxt4('');
        //Carácter de los padres:
        setAAAreaFamiliarCaractPapa('');
        setAAAreaFamiliarCaractMama('');
        setAAAreaFamiliarQ4Txt('');
        //Actualmente: ¿Cómo es la relación con?
        setAAAreaFamiliarQ5Txt('');
        setAAAreaFamiliarQ6Txt('');
        setAAAreaFamiliarQ7Txt('');

        // ÁREA DE PAREJA
        setAAAreaParejaQ1txt('*');
        setAAAreaParejaQ2txt('*');
        setAAAreaParejaQ3txt('*');
        setAAAreaParejaQ4txt('*');

        // ÁREA LABORAL:
        setAAAreaLaboralQ1txt('*');

        // ANAMESIS>> Menores de edad                  
        // A. Niño y adolescente                       
        // I. PRENATAL                                 
        setAMPrenatal_q(''); // NEW                               
        setAMPrenatal_qa('');
        setAMPrenatal_qb('SI');
        setAMPrenatal_qc('SI');
        setAMPrenatalTipoControlM(false); // MEDICO               
        setAMPrenatalTipoControlP(false); // PARTERA              
        setAMPrenatalTipoControlN(false); // NO TUVO CONTROLES    
        setAMPrenatal_qd('');
        setAMPrenatal_qe('');
        setAMPrenatal_qf('SI'); // new                            
        setAMPrenatal_qfTxt(''); // new                           
        setAMPerinatalTxtTNacio('');
        setAMPerinatalDondeNacio('');
        setAMPerinatalDondeNacioOtros('');
        setAMPerinatalTipoParto('');
        setAMPerinatalTipoPartoInducido('');
        setAMPerinatalPRNPesoAltura('');
        setAMPerinatalTxtLloroNacer('');
        setAMPerinatalTxtLloroNacerTiempo(''); // NEW CAMBIA TEXTO ??
        setAMPerinatalEdadP('');
        setAMPerinatalEdadM('');
        setAMPosnatalQa('NO');
        setAMPosnatalQb('NO');
        setAMPosnatalQc('NO');
        setAMPosnatalQdRespTxt('');
        setAMPosnatalQe('NO');
        setAMHistoriaMedicaTxta('');
        setAMHistoriaMedicaTxtb('');
        setAMHistoriaMedicaQc('NO');
        setAMHistoriaMedicaConvulsion(false);
        setAMHistoriaMedicaMareos(false);
        setAMHistoriaMedicaEdadTenia(''); //CAMBIA TEXTO             
        setAMHistoriaMedicaComoFueAtendido('');
        setAMHistoriaMedicaOperaciones('NO');
        setAMHistoriaMedicaOperacionesTxt('');
        setAMHistoriaMedicaExamenRealidadoN(false);
        setAMHistoriaMedicaExamenRealidadoA(false);
        setAMHistoriaMedicaExamenRealidadoP(false);
        setAMHistoriaMedicaExamenRealidadoPsi(false);
        setAMHistoriaMedicaExamenRealidadoOtros(false);
        setAMHistoriaMedicaExamenRealidadoTxt('');
        setAMDesarrolloNMEdadLevantarCabeza('');
        setAMDesarrolloNMEdadSentarse('');
        setAMDesarrolloNMEdadGatear('');
        setAMDesarrolloNMEdadPararse('');
        setAMDesarrolloNMEdadCaminar('');
        setAMDesarrolloNMDificultades(''); //si-no     
        setAMDesarrolloNMMovimientoSacudeBrazo(false);
        setAMDesarrolloNMMovimientoEstrujaMano(false);
        setAMDesarrolloNMMovimientoTxt('');
        setAMDesarrolloNMMovimientoTxt2('');
        setAMDesarrolloNMHabCaminar(false);
        setAMDesarrolloNMHabSaltar1Pie(false);
        setAMDesarrolloNMHabDesplazarSalt1Pie(false);
        setAMDesarrolloNMDomLatManual('DERECHA');
        setAMHabilidadHablaQ1(''); //CAMBIA TEXTO          
        setAMHabilidadHablaQ2('');
        setAMHabilidadHablaEntenderGestos(false);
        setAMHabilidadHablaEntenderGritos(false);
        setAMHabilidadHablaEntenderHablando(false);
        setAMHabilidadHablaEntenderBalbuceando(false);
        setAMHabilidadHablaEntenderOtros(false);
        setAMHabilidadHablaQ3(''); //new txt 
        setAMHabilidadHablaDificultadPronOmision(false);
        setAMHabilidadHablaDificultadPronSustitucion(false);
        setAMHabilidadHablaDificultadPronDistorsion(false);
        setAMHabilidadHablaQ4('');
        setAMHabilidadHablaQ5('');
        setAMHabilidadHablaQ6('');
        setAMHabilidadHablaQ7('');
        setAMHabilidadHablaQ8('');
        setAMHabilidadHablaQ9(''); //si-no  
        setAMHabilidadHablaQ10(''); //si-no 
        setAMHabilidadHablaQ11(''); //si-no 
        setAMHabilidadHablaQ12(''); //si-no 
        setAMHabilidadHablaQ13(''); //si-no 
        setAMHabilidadHablaQ14(''); //si-no 
        setAMHabilidadHablaDemasiado(false);
        setAMHabilidadHablaRapido(false);
        setAMHabilidadHablaLento(false);
        setAMHabilidadHablaNormal(false);
        setAMHabilidadHablaQ15Txt('');
        setAMMovZonaOralUsoBiberon(''); //SI-NO        
        setAMMovZonaOralQ1('');
        setAMMovZonaOralMasticacionQ1('');
        setAMMovZonaOralMasticacionQ2('');
        setAMMovZonaOralMasticacionQ3('');
        setAMMovZonaOralMasticacionQ4('');  //si-no    
        setAMMovZonaOralMasticacionQ5('');
        setAMMovZonaOralMasticacionQ6('');
        setAMMovZonaOralMasticacionQ7('');
        setAMMovZonaOralBabeaAlDormir(false);
        setAMMovZonaOralBabeaAlComer(false);
        setAMMovZonaOralBabeaEnTodoMomento(false);
        setAMMovZonaOralDiffRespirarViaResp(false);
        setAMMovZonaOralDiffRespirarAlergias(false);
        setAMMovZonaOralDiffRespirarResfriado(false);
        setAMMovZonaOralDiffRespirarAsma(false);
        setAMMovZonaOralDiffRespirarOtros(false);
        setAMMovZonaOralDiffRespirarOtrosTxt('');

        setAMAlimentacionMaterno(false);
        setAMAlimentacionArtificial(false);
        setAMAlimentacionEdadDestete('');
        setAMAlimentacionRequiereAyuda(''); //SI-NO                        
        setAMAlimentacionUsaCubiertos(''); // ¿Usa cubiertos? SI / NO      
        setAMAlimentacionProblemaApetito(''); //SI-NO                      
        setAMAlimentacionQtyComidasAlDia('3');
        setAMAlimentacionAboutDieta('');
        setAMAlimentacionAboutDietaWhy('');
        setAMHigieneQ1('');
        setAMHigieneQ2('');
        setAMHigieneQ3(''); //SI-NO           
        setAMHigieneQ3Txt('');
        setAMHigieneQ4(''); //¿Su hijo se asea solo? SI / NO. 
        setAMHigieneQ4Txt(''); //Si, requiere ayuda. ¿cómo?   
        setAMSuenioQ1('');
        setAMSuenioQ2('');
        setAMSuenioQ3Habla(false);
        setAMSuenioQ3Grita(false);
        setAMSuenioQ3SeMueve(false);
        setAMSuenioQ3Transpira(false);
        setAMSuenioQ3Camina(false);
        setAMSuenioQ4(''); //¿A qué edad? 
        setAMSuenioQ5(''); //SI-NO        
        setAMSuenioQ5Txt(''); //si es: SI 
        setAMIndPersonalQ1(''); //SI-NO ¿Su hijo hace mandados?                       
        setAMIndPersonalQ2(''); //SI-NO ¿Dentro del hogar?                            
        setAMIndPersonalQ3(''); //SI-NO ¿Fuera del hogar? (barrio)                    
        setAMIndPersonalQ4(''); //SI-NO ¿Su hijo ayuda en casa?                       
        setAAIndPersonalQ5Txt(''); //¿Qué responsabilidades tiene?                    
        setAMIndPersonalQ6(''); //SI-NO  Existencia de normas:                        
        setAMIndPersonalQ6Txt(''); //¿Cuáles?                                                                                                                             
        setAAIndPersonalQ7(''); //SI-NO - Castigos                                    
        setAAIndPersonalQ8(''); //SI-NO - premios                                     
        setAAIndPersonalQ8Txt(''); //¿Quiénes lo ejecutan? ¿Con qué frecuencia?  
        setAMConductaQ1(''); //¿Se come las uñas? SI/NO                                                                     
        setAMConductaQ2(''); //¿Se succiona los dedos?                                                                      
        setAMConductaQ3(''); //¿Se muerde el labio?                                                                         
        setAMConductaQ4(''); //¿Le sudan las manos?                                                                         
        setAMConductaQ5(''); //¿Le tiemblan las manos y piernas?                                                            
        setAMConductaQ6(''); //¿Agrede a las personas sin motivo?                                                           
        setAMConductaQ7(''); //¿Se le caen las cosas con facilidad?                                                         
        setAMConductaQ8(''); //¿Problemas de alimentación?                                                                  
        setAMConductaQ8Txt(''); //¿Cuáles? Sueño, concentración. Indisciplina (irritabilidad, hiperactividad) . Otros.      
        setAMConductaQ9Txt(''); //Carácter del niño:                                                                        
        setAMJuegoSocialQ1(''); //¿Su hijo juega solo? ¿Por qué? ¿dirige o es dirigido?                                 
        setAMJuegoSocialQ2(''); // ¿Qué juegos prefiere su hijo? ¿Cuáles son sus juguetes preferidos?                   
        setAMJuegoSocialQ3(''); //¿Prefiere jugar con niños de su edad, con mayores o niños menores a su edad?          
        setAMJuegoSocialQ4(''); //¿Cuáles son las distracciones principales de su hijo? Uso del tiempo libre. Deportes. 
        setAMJuegoSocialQ5(''); //Conductas en el juego con otros niños: agresividad, ausencia del deseo del contacto.  
        setAMHistoriaEduIEdad('');
        setAMHistoriaEduIAdaptacion('');
        setAMHistoriaEduIDificultades('');
        setAMHistoriaEduIConductasDisfuncionales('');
        setAMHistoriaEduPEdad('');
        setAMHistoriaEduPRendimientoBueno(false);
        setAMHistoriaEduPRendimientoRegular(false);
        setAMHistoriaEduPRendimientoBajo(false);
        setAMHistoriaEduPDificultades('');
        setAMHistoriaEduPNivelAdaptacion('');
        setAMHistoriaEduSEdad('');
        setAMHistoriaEduSRendimientoBueno(false);
        setAMHistoriaEduSRendimientoRegular(false);
        setAMHistoriaEduSRendimientoBajo(false);
        setAMHistoriaEduSDificultades('');
        setAMHistoriaEduSNivelAdaptacion('');
        setAMHistoriaEduSQ1Txt('');
        setAMHistoriaEduSQ2Txt('');
        setAMHistoriaEduSQ3('');
        setAMHistoriaEduSQ3Txt('');
        setAMHistoriaEduSQ4Txt('');
        setAMHistoriaEduSQ5Txt('');
        setAMHistoriaEduSQ6Txt('');
        setAMHistoriaEduSQ7Txt('');
        setAMHistoriaEduSQ8Txt('');
        setAMHistoriaEduSQ9Txt('');
        setAMHistoriaEduSQ10Txt('');
        setAMHistoriaEduSQ11Txt('');
        setAMPsicosexualidadQ1(''); //¿A qué edad hizo preguntas su hijo, sobre sexo y procreación? Información que se le brindó ¿Cómo?    
        setAMPsicosexualidadQ2(''); //¿Tiene amigos(as) del sexo opuesto?                                                                  
        setAMPsicosexualidadQ3(''); //¿Su hijo presenta conductas como tocar o sobar los genitales? SI/NO                                  
        setAMOpinionActRelacionMamaRechazo(false);
        setAMOpinionActRelacionMamaPreocupacion(false);
        setAMOpinionActRelacionMamaVerguenza(false);
        setAMOpinionActRelacionMamaIndiferencia(false);
        setAMOpinionActRelacionMamaAceptacion(false);
        setAMOpinionActRelacionMamaDescrTxt('');
        setAMOpinionActRelacionPadreRechazo(false);
        setAMOpinionActRelacionPadrePreocupacion(false);
        setAMOpinionActRelacionPadreVerguenza(false);
        setAMOpinionActRelacionPadreIndiferencia(false);
        setAMOpinionActRelacionPadreAceptacion(false);
        setAMOpinionActRelacionPadreDescrTxt('');
        setAMOpinionActQ1('');  //¿Cuál fue el comportamiento del menor al nacimiento de su hermano?                                              
        setAMOpinionActQ2('');  //¿Cómo era la relación con sus hermanos en la infancia?                                                          
        setAMOpinionActQ3('');  //Cambios: Aislarlo o dejarlo con el grupo, exigirle un comportamiento similar, mayor atención, sobreprotección   
        setAMOpinionActQ4('');  //Pautas de crianza: ¿Empleaba castigos, refuerzos positivos y con qué frecuencia?                                
        setAMOpinionActQ5('');  //Cuidadores principales en la infancia:                                                                          
        setAMOpinionActQ6('');  //Cuidadores principales en la niñez                                                                              
        setAMOpinionActQ7('');  //Actualmente: ¿Con quién tiene mayor apego el niño?                                                                                                                                                                                                                                                                                                                                                  
        setAAAreaFamiliarEnfermedadPsiquiatrica(''); //Especificar enfermedades psiquiátricas                                                     
        setAMAreaFamiliarQ1(''); //Problemas del habla, dificultades en el aprendizaje, epilepsias, convulsiones, retardo mental, entre otros).   
        setAMAreaFamiliarCaraterPapa('');  //Carácter de los padres:                                                                              
        setAMAreaFamiliarCaraterMama('');  //Carácter de los padres:                                                                              
        setAMAreaFamiliarQ2('');  //Relación de pareja: ¿Qué ha observado el niño de la relación?                                                 
        setAMAreaFamiliarQ3('');  //¿Cómo es la relación de su papá con el menor?                                                                 
        setAMAreaFamiliarQ4('');  //¿Cómo es la relación de su mamá con el menor?                                                                 
        setAMAreaFamiliarQ5('');  //¿Cómo es la relación con sus hermanos?                                                                        
        setAMAreaFamiliarQ6('');  //Observaciones durante entrvista a los padres                                                                  

        // @@ANANMESIS ..end


        // @@EXAMEN FISICO
        setExamenFisicoFRespiratoria('N/A');
        setExamenFisicoFCardiaca('N/A');
        setExamenFisicoPArterial('N/A');
        setExamenFisicoTemperatura('N/A');
        setExamenFisicoPeso('N/A');
        setExamenFisicoTalla('N/A');
        setExamenFisicoIMC('N/A');
        setExamenFisicoEscalaNumericaMalestar([0, 100]);
        setExamenFisicoPNecesidadesEspeciales('');
        // @[] ¿Paciente pertenece a uno de lo siguientes grupos de pacientes con necesidades especiales?
        setExamenFisicoPNENino(false);
        setExamenFisicoPNEAdolescente(false);
        setExamenFisicoPNEAnciano(false);
        setExamenFisicoPNEDependenciaDrogas(false);
        setExamenFisicoPNEVictimasAbusoAbandono(false);
        setExamenFisicoPNEQuimioterapiaRadioterapia(false);
        setExamenFisicoPNETrastornosEmocionalesPsiquiatricos(false);
        setExamenFisicoPNEEnfermedadInfecciosasContagiosas(false);
        setExamenFisicoPNEEnfermedadTerminal(false);
        setExamenFisicoPNEFaseFinalEmbarazo(false);
        setExamenFisicoPNESistemaInmunologicoAfectado(false);
        // @@EXAMEN FISICO ..end

        // @@ImpresionDiagnostica
        setGridDataImpresionDiagnostica([]);
        // @@ImpresionDiagnostica ..end

        // @@PLAN DE TRABAJO
        setPlanTrabajoExamenesAyudaDiagnosticaPsicologicos('N/A');
        setPlanTrabajoExamenesAyudaDiagnosticaPsicologicos('N/A');
        setPlanTrabajoProcedimientos('N/A');
        setPlanTrabajoInterconsultas('N/A');
        setPlanTrabajoReferencia('N/A');
        setPlanTrabajoTratamiento('N/A');
        // @@PLAN DE TRABAJO ..end
        setIdStaff(0);
        setGridDataStaff([]);
        //carga informacion EVOLUCION        
        setGridDataEvolucion([]);
        setEvolucionNumber(0);
        setEvolucionFecha(new Date());
        setEvolucionIdStaff('0');
        setEvolucionIdStaffNombre('');
        setEvolucionIdOrdenPago(0);
        setEvolucionIdOrdenPagoDetalle('');
        setEvolucionNumeroSesion('0');
        setEvolucionNumeroSesionTotal('0');
        setEvolucionDetalleTxt('');

        setProfesionalTratantePeriodoDesde('');
        setProfesionalTratantePeriodoHasta('');
        setProfesionalTratanteEstado('');
        // news
        setGridDataPariente([]);
        setGridDataAFuncionalConducta([]);

        setAFuncionalId(0);
        setAFuncionalAntecedente('');
        setAFuncionalConducta('');
        setAFuncionalConsecuencia('');

        setGridDataOrdenAtencionDetalles([]);
        setPlanEvaIdOrdenAtencion(0);
        setPlanEvaPruebaCodigoServicio('');
        setPlanEvaDescripcionArea('');
        setvPlanEvaPruebaPsicologica('');
        setGridDataOrdenTerapiaDetalles([]);
        setGridDataInformePsicologico([]);

        return true;
    }

    useEffect(() => {
        // console.log('useEffect:');
        handleSetTitle();
        setMode('U');
        pa_get_tipo_documento();
        pa_get_sys_config('EstadoCivil');
        pa_get_sys_config('GradoInstruccion');
        pa_get_sys_config('Ocupacion');
        pa_get_sys_config('Religion');
        pa_get_tipo_parentesco();
        pa_get_ubigeo('D', '', '');
        pa_get_ubigeo('P', '15', '');
        pa_get_ubigeo('I', '', '1501');
        pa_get_tipo_via('L');
        // pa_get_sys_config('FormaInicioEnfermedad');
        pa_get_staff();
        // console.log(vl_activeIndex);        
        getCargaDataMain(vl_activeIndex);
        if (params.id && params.id !== '') {
            // console.log('getHistoriaClinica init:');
            getHistoriaClinica(params.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCargaDataMain = (in_activeIndex: any) => {
        switch (parseFloat(in_activeIndex)) {
            case 10:
                pa_get_derivacion_paciente();
                break;
            default:
                break;
        }
    }

    const pa_get_tipo_documento = async () => {
        const param = {
            "vp_IdTipoDocumento": "0",
            "vp_Codigo": ""
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_documento', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setCmbIdTipoDocumento(record);
    }

    const pa_get_derivacion_paciente = async () => {
        const param = {
            "vp_NumeroDocumento": '',
            "vp_NumeroHistoria": vl_NumeroHistoriaClinica,
            "vp_FechaDesde": setIntlDateTimeFormat(new Date()), //cuando es por numero de HC no usa la fecha
            "vp_FechaHasta": setIntlDateTimeFormat(new Date()), //cuando es por numero de HC no usa la fecha
            VP_IdSucursal: localStorage.getItem('authIdSucursal')
        }
        const response = await fetchSinToken('admision/pa_get_derivacion_paciente', param, 'POST');
        const resp = await response.json();
        // console.log(resp.data.length);
        if (resp.data.errno) {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: message, life: 3000 });
        } else {
            if (resp.data.length > 0)
                setGridDataDerivaciones(resp.data);
            else
                toast.current.show({ severity: 'info', summary: '::PSISABE', detail: 'No available data ', life: 3000 });
        }
    }

    const pa_get_tipo_parentesco = async () => {
        //e.preventDefault();
        const param = {
            "vp_IdCodigo": "0",
            "vp_CodigoTipo": "CO",
            "vp_GradoParentesco": "0"
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_parentesco', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setCbmIdTipoPariente(record);
    }

    const pa_get_sys_config = async (p_Identificador: any) => {
        const param = {
            "VP_Identificador": p_Identificador,
            "VP_Estado": "A"
        }
        const response = await fetchSinToken('selectors/pa_get_sys_config', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record[0].RecordSet);
        switch (p_Identificador) {
            case 'EstadoCivil':
                setCmbIdEstadoCivil(record[0]?.RecordSet);
                break;
            case 'GradoInstruccion':
                setCmbIdGradoInstruccion(record[0]?.RecordSet);
                break;
            case 'Ocupacion':
                setCbmIdOcupacion(record[0]?.RecordSet);
                break;
            // case 'FormaInicioEnfermedad':
            //     setCbmFormaInicioEnfermedadTxt(record[0]?.RecordSet);
            //     break;
            case 'Religion':
                setCbmIdReligion(record[0]?.RecordSet);
                break;
            default:
                break;
        }
    }

    const pa_get_ubigeo = async (in_opcion: any, in_DepartamentoInei: any, in_ProvinciaInei: any) => {
        const param = {
            "vp_opcion": in_opcion,
            "vp_DepartamentoInei": in_DepartamentoInei,
            "vp_ProvinciaInei": in_ProvinciaInei
        }
        const response = await fetchSinToken('selectors/pa_get_ubigeo', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        if (in_opcion === 'D') setCbmDireccionDepartamento(record);
        if (in_opcion === 'P') setCbmDireccionProvincia(record);
        if (in_opcion === 'I') setCbmDireccionDistrito(record);

    }
    const pa_get_tipo_via = async (in_opcion: any) => {
        const param = {
            "vp_opcion": in_opcion
        }
        const response = await fetchSinToken('selectors/pa_get_tipo_via', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setCbmDireccionTipovia(record);
    }
    const pa_get_staff = async () => {
        const param = {
            "vp_opcion": "A",
            "vp_IdStaff": "0",
            "VP_Codigo": "",
            "VP_Nombres": "",
            "VP_Apellidos": ""
        }
        const response = await fetchSinToken('selectors/pa_get_staff', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setCmbIdStaff(record);
    }
    const pa_get_hc_servicios = async (IN_IdCliente: any) => {
        const param = {
            "vp_IdCliente": IN_IdCliente,
            "vp_IdSucursal": localStorage.getItem('authIdSucursal'),
            "VP_TipoRegistro": 'A'
        }
        const response = await fetchSinToken('paciente/pa_get_hc_servicios', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        setCmbServiciosCliente(record);
    }

    const getHistoriaClinica = async (vp_NumeroHistoriaClinica: any) => {
        const param = {
            "VP_NumeroHistoriaClinica": vp_NumeroHistoriaClinica,
            "VP_IdUsuario": localStorage.getItem('authIdUsuario'),
            "VP_IdSucursal": localStorage.getItem('authIdSucursal')
        }
        // console.log('getHistoriaClinica');
        setProgressBar(true);
        const initial = await setInitValuesAll();
        if (initial) {
            const response = await fetchSinToken('selectors/pa_get_historia_clinica', param, 'POST');
            const resp = await response.json();
            const record = resp.data[0];
            if (record) {
                setdataHistoriaClinica(record);
                const resp1 = await setData(record);
                if (resp1) {
                    setTimeout(() => {
                        setProgressBar(false);
                    }, 200);
                }
            } else {
                toast.current.show({ severity: 'warn', summary: '::PSISABE', detail: 'No se encontro historia clinica', life: 6000 });
                setTimeout(() => {
                    setProgressBar(false);
                }, 200);
            }

        }
    }

    // llena data de la historia clinica 
    const setData = async (data: any) => {

        console.log(data);
        pa_get_sys_config('Ocupacion'); //recargar 
        setIdHistoriaClinica(data.IdHistoriaClinica);
        setNumeroHistoriaClinica(data.NumeroHistoriaClinica);
        setIdCliente(data.IdCliente);
        setFechaAfiliacion(new Date(Date.parse(data.FechaAfiliacion)));  //readOnly
        setHoraAfiliacion(new Date(Date.parse(data.FechaAfiliacion_2))); //readOnly
        setIdTipoDocumento(data.IdTipoDocumento);
        setNumeroDocumento(data.NumeroDocumento);
        setSexo(data.Sexo);
        setApellidoPaterno(data.ApellidoPaterno);
        setApellidoMaterno(data.ApellidoMaterno);
        setNombres(data.Nombres);
        // console.log(new Date(Date.parse(data.FechaNacimiento)));
        // console.log(data.FechaNacimiento);
        setFechaNacimiento(new Date(Date.parse(data.FechaNacimiento)));
        setEdad(data.Edad);

        if (data.Edad > 0 && data.Edad < 18) {
            setFieldsetHiddenAA(false);
            setFieldsetHiddenAM(true);
        }
        if (data.Edad > 0 && data.Edad >= 18) {
            setFieldsetHiddenAA(true);
            setFieldsetHiddenAM(false);
        }

        setIdEstadoCivil(String(data.IdEstadoCivil));
        setIdGradoInstruccion(String(data.IdGradoInstruccion));
        setIdOcupacion(parseInt(data.IdOcupacion));
        // console.log(data.LugarNacimiento);
        if (Object.keys(data.LugarNacimiento).length > 0) {
            setLugarNacimientoDireccion(data.LugarNacimiento[0].NombreVia);
        }
        if (Object.keys(data.LugarProcedencia).length > 0) {
            setLugarProcedenciaDireccion(data.LugarProcedencia[0].NombreVia);
        }

        if (Object.keys(data.InfComplementaria).length > 0) {
            setIdReligion(data.InfComplementaria[0].IdReligion);
            setReligion(data.InfComplementaria[0].Religion);
            setNumeroHermanos(data.InfComplementaria[0].NumeroHermanos);
            setConquienesViven(data.InfComplementaria[0].ConquienesViven);
            setEscolaridad(data.InfComplementaria[0].Escolaridad);
            setInstEducativa(data.InfComplementaria[0].InstEducativa);
            setReferente(data.InfComplementaria[0].Referente);
        }

        const jsonDireccion = data.DomicilioActual;
        if (Object.keys(jsonDireccion).length > 0) {
            // console.log(jsonDireccion[0].Ubigeo);            
            let UbProvincia: any = String(jsonDireccion[0].Ubigeo).substring(0, 2);
            let ProvinciaInei: any = String(jsonDireccion[0].Ubigeo).substring(0, 4);

            setDomicilioActualDepartamento(UbProvincia);
            pa_get_ubigeo('P', UbProvincia, '');
            //Provincias de un departamento: 02
            setDomicilioActualProvincia(ProvinciaInei);
            //Distritos de una provincia: 0210
            pa_get_ubigeo('I', '', ProvinciaInei);
            setDomicilioActualDistrito(String(jsonDireccion[0].Ubigeo));

            setDomicilioActualTipovia(String(jsonDireccion[0].TipoVia));
            setDomicilioActualNombrevia(jsonDireccion[0].NombreVia);
            setDomicilioActualNumero(jsonDireccion[0].Numero);
            setDomicilioActualManzana(jsonDireccion[0].Manzana);
            setDomicilioActualLote(jsonDireccion[0].Lote);
            setDomicilioActualDpto(jsonDireccion[0].Dpto);
            setDomicilioActualUrbanizacion(jsonDireccion[0].Urbanizacion);
        }
        if (Object.keys(data.DatoAcompanante).length > 0) {
            setDatoAcompananteNombres(data.DatoAcompanante[0].Nombre);
            setDatoAcompananteDni(data.DatoAcompanante[0].NumeroDocumento);
        }

        // @@MOTIVO DE CONSULTA
        // console.log('data.MotivoConsulta:');
        // console.log(data.MotivoConsulta);
        if (Object.keys(data.MotivoConsulta).length > 0) {
            // console.log(data.MotivoConsulta[0]?.Txta);
            setMotivoConsultaTxt_a(data.MotivoConsulta[0]?.Txta);
            setMotivoConsultaTxt_b(data.MotivoConsulta[0]?.Txtb);
            setMotivoConsultaTxt_c(data.MotivoConsulta[0]?.Txtc);
            // d.       
            setPorte(data.MotivoConsulta[0].Txtd[0]?.Porte);
            setComportamiento(data.MotivoConsulta[0].Txtd[0]?.Comportamiento);
            setActitud(data.MotivoConsulta[0].Txtd[0]?.Actitud);
        }

        // @@ANANMESIS 
        if (Object.keys(data.Anamnesis).length > 0) {
            //PRENATAL
            setAAPrenatal_qa(data.Anamnesis[0].Prenatal[0].Questiona);
            setAAPrenatal_qb(data.Anamnesis[0].Prenatal[0].Questionb);
            setAAPrenatal_qc(data.Anamnesis[0].Prenatal[0].Questionc);
            setAAPrenatal_qd(data.Anamnesis[0].Prenatal[0].Questiond);
            setAAPrenatal_qe(data.Anamnesis[0].Prenatal[0].Questione);
            setAAPrenatalTipoControlM(data.Anamnesis[0].Prenatal[0].TipoControl[0].Medico);
            setAAPrenatalTipoControlP(data.Anamnesis[0].Prenatal[0].TipoControl[0].Partera);
            setAAPrenatalTipoControlN(data.Anamnesis[0].Prenatal[0].TipoControl[0].NoAsisttio);
            //PERINTAL            
            setAAPerinatalEdadM(data.Anamnesis[0].Perinatal[0].EdadMama);
            setAAPerinatalEdadP(data.Anamnesis[0].Perinatal[0].EdadPapa);
            setAAPerinatalTipoParto(data.Anamnesis[0].Perinatal[0].TipoParto);
            setAAPerinatalTxtTNacio(data.Anamnesis[0].Perinatal[0].TxtTNacio);
            setAAPerinatalDondeNacio(data.Anamnesis[0].Perinatal[0].DondeNacio);
            setAAPerinatalPRNPesoAltura(data.Anamnesis[0].Perinatal[0].PRNPesoAltura);
            setAAPerinatalTxtLloroNacer(data.Anamnesis[0].Perinatal[0].TxtLloroNacer);
            setAAPerinatalDondeNacioOtros(data.Anamnesis[0].Perinatal[0].DondeNacioOtros);
            setAAPerinatalTipoPartoInducido(data.Anamnesis[0].Perinatal[0].TipoPartoInducido);
            setAAPerinatalTxtLloroNacerTiempo(data.Anamnesis[0].Perinatal[0].TxtLloroNacerTiempo);
            // se pasa de: conducta
            setAAConductaQ7Txt(data.Anamnesis[0].Perinatal[0].Q7Txt);
            setAAConductaQ8Txt(data.Anamnesis[0].Perinatal[0].Q8Txt);
            setAAConductaQ9Txt(data.Anamnesis[0].Perinatal[0].Q9Txt);

            //POSNTATAL
            setAAPosnatalQa(data.Anamnesis[0].Posnatal[0].Questiona);
            setAAPosnatalQb(data.Anamnesis[0].Posnatal[0].Questionb);
            setAAPosnatalQc(data.Anamnesis[0].Posnatal[0].Questionc);
            setAAPosnatalQdRespTxt(data.Anamnesis[0].Posnatal[0].QuestiondRespTxt);
            setAAPosnatalQe(data.Anamnesis[0].Posnatal[0].Questiond);
            //HISTORIA CLINICA
            setAAHistoriaMedicaTxta(data.Anamnesis[0].HistoriaMedica[0].Txta);
            setAAHistoriaMedicaTxtb(data.Anamnesis[0].HistoriaMedica[0].Txtb);
            setAAHistoriaMedicaQc(data.Anamnesis[0].HistoriaMedica[0].Qc);
            setAAHistoriaMedicaConvulsion(data.Anamnesis[0].HistoriaMedica[0].Convulsion);
            setAAHistoriaMedicaMareos(data.Anamnesis[0].HistoriaMedica[0].Mareos);
            setAAHistoriaMedicaEdadTenia(data.Anamnesis[0].HistoriaMedica[0].QueEdadTenia);
            setAAHistoriaMedicaComoFueAtendido(data.Anamnesis[0].HistoriaMedica[0].ComoFueAtendido);
            setAAHistoriaMedicaOperaciones(data.Anamnesis[0].HistoriaMedica[0].Operaciones);
            setAAHistoriaMedicaOperacionesTxt(data.Anamnesis[0].HistoriaMedica[0].OperacionesTxt);
            setAAHistoriaMedicaExamenRealidadoN(data.Anamnesis[0].HistoriaMedica[0].ExamenRealidadoN);
            setAAHistoriaMedicaExamenRealidadoA(data.Anamnesis[0].HistoriaMedica[0].ExamenRealidadoA);
            setAAHistoriaMedicaExamenRealidadoP(data.Anamnesis[0].HistoriaMedica[0].ExamenRealidadoP);
            setAAHistoriaMedicaExamenRealidadoPsi(data.Anamnesis[0].HistoriaMedica[0].ExamenRealidadoPsi);
            setAAHistoriaMedicaExamenRealidadoOtros(data.Anamnesis[0].HistoriaMedica[0].ExamenRealidadoOtros);
            setAAHistoriaMedicaExamenRealidadoTxt(data.Anamnesis[0].HistoriaMedica[0].ExamenRealidadoTxt);
            setAAHistoriaMedicaDomLatManual(data.Anamnesis[0].HistoriaMedica[0].DomLatManual);
            setAAHistoriaMedicaEnfermedadActualTxt(data.Anamnesis[0].HistoriaMedica[0].EnfermedadActualTxt);
            setAAHistoriaMedicaEnfermedadActualTratamientoTxt(data.Anamnesis[0].HistoriaMedica[0].EnfermedadActualTratamientoTxt);

            // HABILIDAD PARA HABLAR
            setAAHabilidadHablaQ1(data.Anamnesis[0].HabilidadHabla[0].Question1);
            setAAHabilidadHablaQ2(data.Anamnesis[0].HabilidadHabla[0].Question2);
            setAAHabilidadHablaOmision(data.Anamnesis[0].HabilidadHabla[0].Omision);
            setAAHabilidadHablaSustitucion(data.Anamnesis[0].HabilidadHabla[0].Sustitucion);
            setAAHabilidadHablaDistorsion(data.Anamnesis[0].HabilidadHabla[0].Distorsion);
            setAAHabilidadHablaQ3(data.Anamnesis[0].HabilidadHabla[0].Question3);
            setAAHabilidadHablaQ4(data.Anamnesis[0].HabilidadHabla[0].Question4);
            setAAHabilidadHablaQ5(data.Anamnesis[0].HabilidadHabla[0].Question5);
            setAAHabilidadHablaCk1(data.Anamnesis[0].HabilidadHabla[0].HablaDemasiado);
            setAAHabilidadHablaCk2(data.Anamnesis[0].HabilidadHabla[0].HablaLento);
            setAAHabilidadHablaCk3(data.Anamnesis[0].HabilidadHabla[0].HablaNormal);
            setAAHabilidadHablaCk4(data.Anamnesis[0].HabilidadHabla[0].HablaRapido);
            // ALIMENTACION
            setAAAlimentacionMaterno(data.Anamnesis[0].Alimentacion[0].Materno);
            setAAAlimentacionArtificial(data.Anamnesis[0].Alimentacion[0].Artificial);
            setAAAlimentacionEdadDestete(data.Anamnesis[0].Alimentacion[0].EdadDestete);
            setAAAlimentacionProblemaApetito(data.Anamnesis[0].Alimentacion[0].ProblemaApetito); //SI-NO
            setAAAlimentacionProblemaApetitoDecripcion(data.Anamnesis[0].Alimentacion[0].ProblemaApetitoDecripcion);
            setAAAlimentacionQtyComidasAlDia(data.Anamnesis[0].Alimentacion[0].QtyComidasAlDia);
            setAAAlimentacionAboutDieta(data.Anamnesis[0].Alimentacion[0].AboutDieta);
            setAAAlimentacionAboutDietaWhy(data.Anamnesis[0].Alimentacion[0].AboutDietaWhy);
            //HIGIENE
            setAAHigieneQ1(data.Anamnesis[0].Higiene[0].Question1);
            setAAHigieneQ2(data.Anamnesis[0].Higiene[0].Question2);
            // SUEÑO
            setAASuenioQ1(data.Anamnesis[0].Suenio[0].Question1);
            setAASuenioQ2(data.Anamnesis[0].Suenio[0].Question2);
            setAASuenioQ3Habla(data.Anamnesis[0].Suenio[0].Question3Habla);
            setAASuenioQ3Grita(data.Anamnesis[0].Suenio[0].Question3Grita);
            setAASuenioQ3SeMueve(data.Anamnesis[0].Suenio[0].Question3SeMueve);
            setAASuenioQ3Transpira(data.Anamnesis[0].Suenio[0].Question3Transpira);
            setAASuenioQ3Camina(data.Anamnesis[0].Suenio[0].Question3Camina);
            setAASuenioQ4(data.Anamnesis[0].Suenio[0].Question4);
            setAASuenioQ5(data.Anamnesis[0].Suenio[0].Question5); //SI-NO
            setAASuenioQ5Txt(data.Anamnesis[0].Suenio[0].Question5Txt); //si es: SI

            // here
            // INDEPENDENCIA PERSONAL
            setAAIndPersonalQ1(data.Anamnesis[0].IndPersonal[0].Q1);
            setAAIndPersonalQ2(data.Anamnesis[0].IndPersonal[0].Q12); //SI-NO
            setAAIndPersonalQ2Txt(data.Anamnesis[0].IndPersonal[0].Q2Txt); //respuesta
            setAAIndPersonalQ3(data.Anamnesis[0].IndPersonal[0].Q3); //SI-NO
            setAAIndPersonalQ3Txt(data.Anamnesis[0].IndPersonal[0].Q3Txt); //respuesta
            setAAIndPersonalQ4(data.Anamnesis[0].IndPersonal[0].Q4); //SI-NO
            setAAIndPersonalQ4Txt(data.Anamnesis[0].IndPersonal[0].Q4Txt); //respuesta
            // CONDUCTA
            setAAConductaQ1(data.Anamnesis[0].Conducta[0].Q1);
            setAAConductaQ2(data.Anamnesis[0].Conducta[0].Q2);
            setAAConductaQ3(data.Anamnesis[0].Conducta[0].Q3);
            setAAConductaQ4(data.Anamnesis[0].Conducta[0].Q4);
            setAAConductaQ5(data.Anamnesis[0].Conducta[0].Q5);
            setAAConductaQ6Txt(data.Anamnesis[0].Conducta[0].Q6Txt);
            // setAAConductaQ7Txt(data.Anamnesis[0].Conducta[0].Q7Txt);
            // setAAConductaQ8Txt(data.Anamnesis[0].Conducta[0].Q8Txt);
            // setAAConductaQ9Txt(data.Anamnesis[0].Conducta[0].Q9Txt);
            //JUEGO-SOCIAL
            setAAJuegoSocialQ1(data.Anamnesis[0].JuegoSocial[0].Q1);
            setAAJuegoSocialQ2(data.Anamnesis[0].JuegoSocial[0].Q2);
            setAAJuegoSocialQ3(data.Anamnesis[0].JuegoSocial[0].Q3);
            setAAJuegoSocialQ4(data.Anamnesis[0].JuegoSocial[0].Q4);
            setAAJuegoSocialQ5(data.Anamnesis[0].JuegoSocial[0].Q5);
            //HISTORIA EDUCATIVA
            setAAHistoriaEduIEdad(data.Anamnesis[0].HistoriaEdu[0].IEdad);
            setAAHistoriaEduIAdaptacion(data.Anamnesis[0].HistoriaEdu[0].IAdaptacion);
            setAAHistoriaEduIDificultades(data.Anamnesis[0].HistoriaEdu[0].IDificultades);
            setAAHistoriaEduIConductasDisfuncionales(data.Anamnesis[0].HistoriaEdu[0].IConductasDisfuncionales);

            setAAHistoriaEduPEdad(data.Anamnesis[0].HistoriaEdu[0].PEdad);
            setAAHistoriaEduPRendimientoBueno(data.Anamnesis[0].HistoriaEdu[0].PRendimientoBueno);
            setAAHistoriaEduPRendimientoRegular(data.Anamnesis[0].HistoriaEdu[0].PRendimientoRegular);
            setAAHistoriaEduPRendimientoBajo(data.Anamnesis[0].HistoriaEdu[0].PRendimientoBajo);
            setAAHistoriaEduPDificultades(data.Anamnesis[0].HistoriaEdu[0].PDificultades);
            setAAHistoriaEduPNivelAdaptacion(data.Anamnesis[0].HistoriaEdu[0].PNivelAdaptacion);

            setAAHistoriaEduSEdad(data.Anamnesis[0].HistoriaEdu[0].SEdad);
            setAAHistoriaEduSRendimientoBueno(data.Anamnesis[0].HistoriaEdu[0].SRendimientoBueno);
            setAAHistoriaEduSRendimientoRegular(data.Anamnesis[0].HistoriaEdu[0].SRendimientoRegular);
            setAAHistoriaEduSRendimientoBajo(data.Anamnesis[0].HistoriaEdu[0].SRendimientoBajo);
            setAAHistoriaEduSDificultades(data.Anamnesis[0].HistoriaEdu[0].SDificultades);
            setAAHistoriaEduSNivelAdaptacion(data.Anamnesis[0].HistoriaEdu[0].SNivelAdaptacion);

            setAAHistoriaEduSQ1Txt(data.Anamnesis[0].HistoriaEdu[0].SQ1Txt);
            setAAHistoriaEduSQ2Txt(data.Anamnesis[0].HistoriaEdu[0].SQ2Txt);
            setAAHistoriaEduSQ3(data.Anamnesis[0].HistoriaEdu[0].SQ3);
            setAAHistoriaEduSQ3Txt(data.Anamnesis[0].HistoriaEdu[0].SQ3Txt);
            //Conducta en clases. 
            setAAHistoriaEduSQ4Txt(data.Anamnesis[0].HistoriaEdu[0].SQ4Txt);
            setAAHistoriaEduSQ5Txt(data.Anamnesis[0].HistoriaEdu[0].SQ4Txt);
            setAAHistoriaEduSQ6Txt(data.Anamnesis[0].HistoriaEdu[0].SQ6Txt);
            setAAHistoriaEduSQ7Txt(data.Anamnesis[0].HistoriaEdu[0].SQ7Txt);
            setAAHistoriaEduSQ8Txt(data.Anamnesis[0].HistoriaEdu[0].SQ8Txt);
            // Opinión del profesor.
            setAAHistoriaEduSQ9Txt(data.Anamnesis[0].HistoriaEdu[0].SQ9Txt);
            setAAHistoriaEduSQ10Txt(data.Anamnesis[0].HistoriaEdu[0].SQ10Txt);
            setAAHistoriaEduSQ11Txt(data.Anamnesis[0].HistoriaEdu[0].SQ11Txt);

            // PSICOSEXUALIDAD
            setAAPsicosexualidadQ1(data.Anamnesis[0].Psicosexualidad[0].Q1);
            setAAPsicosexualidadQ2(data.Anamnesis[0].Psicosexualidad[0].Q2);
            setAAPsicosexualidadQ3(data.Anamnesis[0].Psicosexualidad[0].Q3);
            setAAPsicosexualidadQ4(data.Anamnesis[0].Psicosexualidad[0].Q4);
            setAAPsicosexualidadQ5(data.Anamnesis[0].Psicosexualidad[0].Q5);

            //ÁREA FAMILIAR
            setAAAreaFamiliarRMamaRechazo(data.Anamnesis[0].AreaFamiliar[0].RMamaRechazo);
            setAAAreaFamiliarRMamaPreocupacion(data.Anamnesis[0].AreaFamiliar[0].RMamaPreocupacion);
            setAAAreaFamiliarRMamaVerguenza(data.Anamnesis[0].AreaFamiliar[0].RMamaVerguenza);
            setAAAreaFamiliarRMamaIndiferencia(data.Anamnesis[0].AreaFamiliar[0].RMamaIndiferencia);
            setAAAreaFamiliarRMamaAceptacion(data.Anamnesis[0].AreaFamiliar[0].RMamaAceptacion);
            setAAAreaFamiliarRMamaEventoTxt(data.Anamnesis[0].AreaFamiliar[0].RMamaEventoTxt);

            setAAAreaFamiliarRPapaRechazo(data.Anamnesis[0].AreaFamiliar[0].RPapaRechazo);
            setAAAreaFamiliarRPapaPreocupacion(data.Anamnesis[0].AreaFamiliar[0].RPapaPreocupacion);
            setAAAreaFamiliarRPapaVerguenza(data.Anamnesis[0].AreaFamiliar[0].RPapaVerguenza);
            setAAAreaFamiliarRPapaIndiferencia(data.Anamnesis[0].AreaFamiliar[0].RPapaIndiferencia);
            setAAAreaFamiliarRPapaAceptacion(data.Anamnesis[0].AreaFamiliar[0].RPapaAceptacion);
            setAAAreaFamiliarRPapaEventoTxt(data.Anamnesis[0].AreaFamiliar[0].RPapaEventoTxt);
            setAAAreaFamiliarQ1Txt(data.Anamnesis[0].AreaFamiliar[0].Q1Txt);
            setAAAreaFamiliarTxt1(data.Anamnesis[0].AreaFamiliar[0].Txt1);
            setAAAreaFamiliarQ2Txt(data.Anamnesis[0].AreaFamiliar[0].Q2Txt);
            setAAAreaFamiliarTxt2(data.Anamnesis[0].AreaFamiliar[0].Txt2); //Cuidadores principales en la niñez: 
            setAAAreaFamiliarQ3Txt(data.Anamnesis[0].AreaFamiliar[0].Q3Txt);
            setAAAreaFamiliarTxt3(data.Anamnesis[0].AreaFamiliar[0].Txt3);
            setAAAreaFamiliarTxt4(data.Anamnesis[0].AreaFamiliar[0].Txt4);
            //Carácter de los padres:
            setAAAreaFamiliarCaractPapa(data.Anamnesis[0].AreaFamiliar[0].CaractPapa);
            setAAAreaFamiliarCaractMama(data.Anamnesis[0].AreaFamiliar[0].CaractMama);
            setAAAreaFamiliarQ4Txt(data.Anamnesis[0].AreaFamiliar[0].Q4Txt);
            //Actualmente: ¿Cómo es la relación con?
            setAAAreaFamiliarQ5Txt(data.Anamnesis[0].AreaFamiliar[0].Q5Txt);
            setAAAreaFamiliarQ6Txt(data.Anamnesis[0].AreaFamiliar[0].Q6Txt);
            setAAAreaFamiliarQ7Txt(data.Anamnesis[0].AreaFamiliar[0].Q7Txt);

            // ÁREA DE PAREJA
            setAAAreaParejaQ1txt(data.Anamnesis[0].AreaPareja[0].Q1txt);
            setAAAreaParejaQ2txt(data.Anamnesis[0].AreaPareja[0].Q2txt);
            setAAAreaParejaQ3txt(data.Anamnesis[0].AreaPareja[0].Q3txt);
            setAAAreaParejaQ4txt(data.Anamnesis[0].AreaPareja[0].Q4txt);

            // ÁREA LABORAL:
            setAAAreaLaboralQ1txt(data.Anamnesis[0].AreaLaboral[0].Q1txt);


        }

        //>>here

        // ANAMESIS>> Menores de edad                  
        // A. Niño y adolescente                       
        // I. PRENATAL     
        if (Object.keys(data.AnamnesisMenorEdad).length > 0) {
            setAMPrenatal_q(data.AnamnesisMenorEdad[0].Prenatal[0].Prenatal_q); // NEW                               
            setAMPrenatal_qa(data.AnamnesisMenorEdad[0].Prenatal[0].Prenatal_qa);
            setAMPrenatal_qb(data.AnamnesisMenorEdad[0].Prenatal[0].Prenatal_qb);
            setAMPrenatal_qc(data.AnamnesisMenorEdad[0].Prenatal[0].Prenatal_qc);
            setAMPrenatalTipoControlM(data.AnamnesisMenorEdad[0].Prenatal[0].PrenatalTipoControlM); // MEDICO               
            setAMPrenatalTipoControlP(data.AnamnesisMenorEdad[0].Prenatal[0].PrenatalTipoControlP); // PARTERA              
            setAMPrenatalTipoControlN(data.AnamnesisMenorEdad[0].Prenatal[0].PrenatalTipoControlN); // NO TUVO CONTROLES    
            setAMPrenatal_qd(data.AnamnesisMenorEdad[0].Prenatal[0].Prenatal_qd);
            setAMPrenatal_qe(data.AnamnesisMenorEdad[0].Prenatal[0].Prenatal_qe);
            setAMPrenatal_qf(data.AnamnesisMenorEdad[0].Prenatal[0].Prenatal_qf); // new                            
            setAMPrenatal_qfTxt(data.AnamnesisMenorEdad[0].Prenatal[0].Prenatal_qfTxt); // new  

            setAMPerinatalTxtTNacio(data.AnamnesisMenorEdad[0].Perinatal[0].TxtTNacio);
            setAMPerinatalDondeNacio(data.AnamnesisMenorEdad[0].Perinatal[0].DondeNacio);
            setAMPerinatalDondeNacioOtros(data.AnamnesisMenorEdad[0].Perinatal[0].DondeNacioOtros);
            setAMPerinatalTipoParto(data.AnamnesisMenorEdad[0].Perinatal[0].TipoParto);
            setAMPerinatalTipoPartoInducido(data.AnamnesisMenorEdad[0].Perinatal[0].TipoPartoInducido);
            setAMPerinatalPRNPesoAltura(data.AnamnesisMenorEdad[0].Perinatal[0].PRNPesoAltura);
            setAMPerinatalTxtLloroNacer(data.AnamnesisMenorEdad[0].Perinatal[0].TxtLloroNacer);
            setAMPerinatalTxtLloroNacerTiempo(data.AnamnesisMenorEdad[0].Perinatal[0].TxtLloroNacerTiempo); // NEW CAMBIA TEXTO ??
            setAMPerinatalEdadP(data.AnamnesisMenorEdad[0].Perinatal[0].EdadP);
            setAMPerinatalEdadM(data.AnamnesisMenorEdad[0].Perinatal[0].EdadM);

            setAMPosnatalQa(data.AnamnesisMenorEdad[0].Posnatal[0].Qa);
            setAMPosnatalQb(data.AnamnesisMenorEdad[0].Posnatal[0].Qb);
            setAMPosnatalQc(data.AnamnesisMenorEdad[0].Posnatal[0].Qc);
            setAMPosnatalQdRespTxt(data.AnamnesisMenorEdad[0].Posnatal[0].QdRespTxt);
            setAMPosnatalQe(data.AnamnesisMenorEdad[0].Posnatal[0].Qe);

            setAMHistoriaMedicaTxta(data.AnamnesisMenorEdad[0].HistoriaMedica[0].Txta);
            setAMHistoriaMedicaTxtb(data.AnamnesisMenorEdad[0].HistoriaMedica[0].Txtb);
            setAMHistoriaMedicaQc(data.AnamnesisMenorEdad[0].HistoriaMedica[0].Qc);
            setAMHistoriaMedicaConvulsion(data.AnamnesisMenorEdad[0].HistoriaMedica[0].Convulsion);
            setAMHistoriaMedicaMareos(data.AnamnesisMenorEdad[0].HistoriaMedica[0].Mareos);
            setAMHistoriaMedicaEdadTenia(data.AnamnesisMenorEdad[0].HistoriaMedica[0].Txta); //CAMBIA TEXTO             
            setAMHistoriaMedicaComoFueAtendido(data.AnamnesisMenorEdad[0].HistoriaMedica[0].EdadTenia);
            setAMHistoriaMedicaOperaciones(data.AnamnesisMenorEdad[0].HistoriaMedica[0].Operaciones);
            setAMHistoriaMedicaOperacionesTxt(data.AnamnesisMenorEdad[0].HistoriaMedica[0].OperacionesTxt);
            setAMHistoriaMedicaExamenRealidadoN(data.AnamnesisMenorEdad[0].HistoriaMedica[0].RealidadoN);
            setAMHistoriaMedicaExamenRealidadoA(data.AnamnesisMenorEdad[0].HistoriaMedica[0].RealidadoA);
            setAMHistoriaMedicaExamenRealidadoP(data.AnamnesisMenorEdad[0].HistoriaMedica[0].RealidadoP);
            setAMHistoriaMedicaExamenRealidadoPsi(data.AnamnesisMenorEdad[0].HistoriaMedica[0].RealidadoPsi);
            setAMHistoriaMedicaExamenRealidadoOtros(data.AnamnesisMenorEdad[0].HistoriaMedica[0].RealidadoOtros);
            setAMHistoriaMedicaExamenRealidadoTxt(data.AnamnesisMenorEdad[0].HistoriaMedica[0].RealidadoTxt);

            setAMDesarrolloNMEdadLevantarCabeza(data.AnamnesisMenorEdad[0].DesarrolloNM[0].EdadLevantarCabeza);
            setAMDesarrolloNMEdadSentarse(data.AnamnesisMenorEdad[0].DesarrolloNM[0].EdadSentarse);
            setAMDesarrolloNMEdadGatear(data.AnamnesisMenorEdad[0].DesarrolloNM[0].EdadGatear);
            setAMDesarrolloNMEdadPararse(data.AnamnesisMenorEdad[0].DesarrolloNM[0].EdadPararse);
            setAMDesarrolloNMEdadCaminar(data.AnamnesisMenorEdad[0].DesarrolloNM[0].EdadCaminar);
            setAMDesarrolloNMDificultades(data.AnamnesisMenorEdad[0].DesarrolloNM[0].Dificultades); //si-no     
            setAMDesarrolloNMMovimientoSacudeBrazo(data.AnamnesisMenorEdad[0].DesarrolloNM[0].MovimientoSacudeBrazo);
            setAMDesarrolloNMMovimientoEstrujaMano(data.AnamnesisMenorEdad[0].DesarrolloNM[0].MovimientoEstrujaMano);
            setAMDesarrolloNMMovimientoTxt(data.AnamnesisMenorEdad[0].DesarrolloNM[0].MovimientoTxt);
            setAMDesarrolloNMMovimientoTxt2(data.AnamnesisMenorEdad[0].DesarrolloNM[0].MovimientoTxt2);
            setAMDesarrolloNMHabCaminar(data.AnamnesisMenorEdad[0].DesarrolloNM[0].HabCaminar);
            setAMDesarrolloNMHabSaltar1Pie(data.AnamnesisMenorEdad[0].DesarrolloNM[0].HabSaltar1Pie);
            setAMDesarrolloNMHabDesplazarSalt1Pie(data.AnamnesisMenorEdad[0].DesarrolloNM[0].HabDesplazarSalt1Pie);
            setAMDesarrolloNMDomLatManual(data.AnamnesisMenorEdad[0].DesarrolloNM[0].DomLatManual);

            setAMHabilidadHablaQ1(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q1); //CAMBIA TEXTO          
            setAMHabilidadHablaQ2(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q2);
            setAMHabilidadHablaEntenderGestos(data.AnamnesisMenorEdad[0].HabilidadHabla[0].EntenderGestos);
            setAMHabilidadHablaEntenderGritos(data.AnamnesisMenorEdad[0].HabilidadHabla[0].EntenderGritos);
            setAMHabilidadHablaEntenderHablando(data.AnamnesisMenorEdad[0].HabilidadHabla[0].EntenderHablando);
            setAMHabilidadHablaEntenderBalbuceando(data.AnamnesisMenorEdad[0].HabilidadHabla[0].EntenderBalbuceando);
            setAMHabilidadHablaEntenderOtros(data.AnamnesisMenorEdad[0].HabilidadHabla[0].EntenderOtros);
            setAMHabilidadHablaQ3(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q3); //new txt 
            setAMHabilidadHablaDificultadPronOmision(data.AnamnesisMenorEdad[0].HabilidadHabla[0].DificultadPronOmision);
            setAMHabilidadHablaDificultadPronSustitucion(data.AnamnesisMenorEdad[0].HabilidadHabla[0].DificultadPronSustitucion);
            setAMHabilidadHablaDificultadPronDistorsion(data.AnamnesisMenorEdad[0].HabilidadHabla[0].DificultadPronDistorsion);
            setAMHabilidadHablaQ4(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q4);
            setAMHabilidadHablaQ5(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q5);
            setAMHabilidadHablaQ6(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q6);
            setAMHabilidadHablaQ7(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q7);
            setAMHabilidadHablaQ8(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q8);
            setAMHabilidadHablaQ9(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q9); //si-no  
            setAMHabilidadHablaQ10(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q10); //si-no 
            setAMHabilidadHablaQ11(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q11); //si-no 
            setAMHabilidadHablaQ12(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q12); //si-no 
            setAMHabilidadHablaQ13(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q13); //si-no 
            setAMHabilidadHablaQ14(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q14); //si-no 
            setAMHabilidadHablaDemasiado(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Demasiado);
            setAMHabilidadHablaRapido(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Rapido);
            setAMHabilidadHablaLento(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Lento);
            setAMHabilidadHablaNormal(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Normal);
            setAMHabilidadHablaQ15Txt(data.AnamnesisMenorEdad[0].HabilidadHabla[0].Q15Txt);

            setAMMovZonaOralUsoBiberon(data.AnamnesisMenorEdad[0].MovZonaOral[0].UsoBiberon); //SI-NO        
            setAMMovZonaOralQ1(data.AnamnesisMenorEdad[0].MovZonaOral[0].Q1);
            setAMMovZonaOralMasticacionQ1(data.AnamnesisMenorEdad[0].MovZonaOral[0].MasticacionQ1);
            setAMMovZonaOralMasticacionQ2(data.AnamnesisMenorEdad[0].MovZonaOral[0].MasticacionQ2);
            setAMMovZonaOralMasticacionQ3(data.AnamnesisMenorEdad[0].MovZonaOral[0].MasticacionQ3);
            setAMMovZonaOralMasticacionQ4(data.AnamnesisMenorEdad[0].MovZonaOral[0].MasticacionQ4);  //si-no    
            setAMMovZonaOralMasticacionQ5(data.AnamnesisMenorEdad[0].MovZonaOral[0].MasticacionQ5);
            setAMMovZonaOralMasticacionQ6(data.AnamnesisMenorEdad[0].MovZonaOral[0].MasticacionQ6);
            setAMMovZonaOralMasticacionQ7(data.AnamnesisMenorEdad[0].MovZonaOral[0].MasticacionQ7);
            setAMMovZonaOralBabeaAlDormir(data.AnamnesisMenorEdad[0].MovZonaOral[0].BabeaAlDormir);
            setAMMovZonaOralBabeaAlComer(data.AnamnesisMenorEdad[0].MovZonaOral[0].BabeaAlComer);
            setAMMovZonaOralBabeaEnTodoMomento(data.AnamnesisMenorEdad[0].MovZonaOral[0].BabeaEnTodoMomento);
            setAMMovZonaOralDiffRespirarViaResp(data.AnamnesisMenorEdad[0].MovZonaOral[0].DiffRespirarViaResp);
            setAMMovZonaOralDiffRespirarAlergias(data.AnamnesisMenorEdad[0].MovZonaOral[0].DiffRespirarAlergias);
            setAMMovZonaOralDiffRespirarResfriado(data.AnamnesisMenorEdad[0].MovZonaOral[0].DiffRespirarResfriado);
            setAMMovZonaOralDiffRespirarAsma(data.AnamnesisMenorEdad[0].MovZonaOral[0].DiffRespirarAsma);
            setAMMovZonaOralDiffRespirarOtros(data.AnamnesisMenorEdad[0].MovZonaOral[0].DiffRespirarOtros);
            setAMMovZonaOralDiffRespirarOtrosTxt(data.AnamnesisMenorEdad[0].MovZonaOral[0].DiffRespirarOtrosTxt);

            setAMAlimentacionMaterno(data.AnamnesisMenorEdad[0].Alimentacion[0].Materno);
            setAMAlimentacionArtificial(data.AnamnesisMenorEdad[0].Alimentacion[0].Artificial);
            setAMAlimentacionEdadDestete(data.AnamnesisMenorEdad[0].Alimentacion[0].EdadDestete);
            setAMAlimentacionRequiereAyuda(data.AnamnesisMenorEdad[0].Alimentacion[0].RequiereAyuda); //SI-NO                        
            setAMAlimentacionUsaCubiertos(data.AnamnesisMenorEdad[0].Alimentacion[0].UsaCubiertos); // ¿Usa cubiertos? SI / NO      
            setAMAlimentacionProblemaApetito(data.AnamnesisMenorEdad[0].Alimentacion[0].ProblemaApetito); //SI-NO                      
            setAMAlimentacionQtyComidasAlDia(data.AnamnesisMenorEdad[0].Alimentacion[0].QtyComidasAlDia);
            setAMAlimentacionAboutDieta(data.AnamnesisMenorEdad[0].Alimentacion[0].AboutDieta);
            setAMAlimentacionAboutDietaWhy(data.AnamnesisMenorEdad[0].Alimentacion[0].AboutDietaWhy);

            setAMHigieneQ1(data.AnamnesisMenorEdad[0].Higiene[0].Q1);
            setAMHigieneQ2(data.AnamnesisMenorEdad[0].Higiene[0].Q2);
            setAMHigieneQ3(data.AnamnesisMenorEdad[0].Higiene[0].Q3); //SI-NO           
            setAMHigieneQ3Txt(data.AnamnesisMenorEdad[0].Higiene[0].Q3Txt);
            setAMHigieneQ4(data.AnamnesisMenorEdad[0].Higiene[0].Q4); //¿Su hijo se asea solo? SI / NO. 
            setAMHigieneQ4Txt(data.AnamnesisMenorEdad[0].Higiene[0].Q4Txt); //Si, requiere ayuda. ¿cómo?   

            setAMSuenioQ1(data.AnamnesisMenorEdad[0].Suenio[0].Q1);
            setAMSuenioQ2(data.AnamnesisMenorEdad[0].Suenio[0].Q2);
            setAMSuenioQ3Habla(data.AnamnesisMenorEdad[0].Suenio[0].Q3Habla);
            setAMSuenioQ3Grita(data.AnamnesisMenorEdad[0].Suenio[0].Q3Grita);
            setAMSuenioQ3SeMueve(data.AnamnesisMenorEdad[0].Suenio[0].Q3SeMueve);
            setAMSuenioQ3Transpira(data.AnamnesisMenorEdad[0].Suenio[0].Q3Transpira);
            setAMSuenioQ3Camina(data.AnamnesisMenorEdad[0].Suenio[0].Q3Camina);
            setAMSuenioQ4(data.AnamnesisMenorEdad[0].Suenio[0].Q4); //¿A qué edad? 
            setAMSuenioQ5(data.AnamnesisMenorEdad[0].Suenio[0].Q5); //SI-NO        
            setAMSuenioQ5Txt(data.AnamnesisMenorEdad[0].Suenio[0].Q5Txt); //si es: SI 

            setAMIndPersonalQ1(data.AnamnesisMenorEdad[0].IndPersonal[0].Q1); //SI-NO ¿Su hijo hace mandados?                       
            setAMIndPersonalQ2(data.AnamnesisMenorEdad[0].IndPersonal[0].Q2); //SI-NO ¿Dentro del hogar?                            
            setAMIndPersonalQ3(data.AnamnesisMenorEdad[0].IndPersonal[0].Q3); //SI-NO ¿Fuera del hogar? (barrio)                    
            setAMIndPersonalQ4(data.AnamnesisMenorEdad[0].IndPersonal[0].Q4); //SI-NO ¿Su hijo ayuda en casa?                       
            setAAIndPersonalQ5Txt(data.AnamnesisMenorEdad[0].IndPersonal[0].Q5Txt); //¿Qué responsabilidades tiene?                    
            setAMIndPersonalQ6(data.AnamnesisMenorEdad[0].IndPersonal[0].Q6); //SI-NO  Existencia de normas:                        
            setAMIndPersonalQ6Txt(data.AnamnesisMenorEdad[0].IndPersonal[0].Q6Txt); //¿Cuáles?                                                                                                                             
            setAAIndPersonalQ7(data.AnamnesisMenorEdad[0].IndPersonal[0].Q7); //SI-NO - Castigos                                    
            setAAIndPersonalQ8(data.AnamnesisMenorEdad[0].IndPersonal[0].Q8); //SI-NO - premios                                     
            setAAIndPersonalQ8Txt(data.AnamnesisMenorEdad[0].IndPersonal[0].Q8Txt); //¿Quiénes lo ejecutan? ¿Con qué frecuencia?  

            setAMConductaQ1(data.AnamnesisMenorEdad[0].Conducta[0].Q1); //¿Se come las uñas? SI/NO                                                                     
            setAMConductaQ2(data.AnamnesisMenorEdad[0].Conducta[0].Q2); //¿Se succiona los dedos?                                                                      
            setAMConductaQ3(data.AnamnesisMenorEdad[0].Conducta[0].Q3); //¿Se muerde el labio?                                                                         
            setAMConductaQ4(data.AnamnesisMenorEdad[0].Conducta[0].Q4); //¿Le sudan las manos?                                                                         
            setAMConductaQ5(data.AnamnesisMenorEdad[0].Conducta[0].Q5); //¿Le tiemblan las manos y piernas?                                                            
            setAMConductaQ6(data.AnamnesisMenorEdad[0].Conducta[0].Q6); //¿Agrede a las personas sin motivo?                                                           
            setAMConductaQ7(data.AnamnesisMenorEdad[0].Conducta[0].Q7); //¿Se le caen las cosas con facilidad?                                                         
            setAMConductaQ8(data.AnamnesisMenorEdad[0].Conducta[0].Q8); //¿Problemas de alimentación?                                                                  
            setAMConductaQ8Txt(data.AnamnesisMenorEdad[0].Conducta[0].Q8Txt); //¿Cuáles? Sueño, concentración. Indisciplina (irritabilidad, hiperactividad) . Otros.      
            setAMConductaQ9Txt(data.AnamnesisMenorEdad[0].Conducta[0].Q9Txt); //Carácter del niño:                                                                        

            setAMJuegoSocialQ1(data.AnamnesisMenorEdad[0].JuegoSocial[0].Q1); //¿Su hijo juega solo? ¿Por qué? ¿dirige o es dirigido?                                 
            setAMJuegoSocialQ2(data.AnamnesisMenorEdad[0].JuegoSocial[0].Q2); // ¿Qué juegos prefiere su hijo? ¿Cuáles son sus juguetes preferidos?                   
            setAMJuegoSocialQ3(data.AnamnesisMenorEdad[0].JuegoSocial[0].Q3); //¿Prefiere jugar con niños de su edad, con mayores o niños menores a su edad?          
            setAMJuegoSocialQ4(data.AnamnesisMenorEdad[0].JuegoSocial[0].Q4); //¿Cuáles son las distracciones principales de su hijo? Uso del tiempo libre. Deportes. 
            setAMJuegoSocialQ5(data.AnamnesisMenorEdad[0].JuegoSocial[0].Q5); //Conductas en el juego con otros niños: agresividad, ausencia del deseo del contacto.  

            setAMHistoriaEduIEdad(data.AnamnesisMenorEdad[0].HistoriaEdu[0].IEdad);
            setAMHistoriaEduIAdaptacion(data.AnamnesisMenorEdad[0].HistoriaEdu[0].IAdaptacion);
            setAMHistoriaEduIDificultades(data.AnamnesisMenorEdad[0].HistoriaEdu[0].IDificultades);
            setAMHistoriaEduIConductasDisfuncionales(data.AnamnesisMenorEdad[0].HistoriaEdu[0].IConductasDisfuncionales);
            setAMHistoriaEduPEdad(data.AnamnesisMenorEdad[0].HistoriaEdu[0].PEdad);
            setAMHistoriaEduPRendimientoBueno(data.AnamnesisMenorEdad[0].HistoriaEdu[0].PRendimientoBueno);
            setAMHistoriaEduPRendimientoRegular(data.AnamnesisMenorEdad[0].HistoriaEdu[0].PRendimientoRegular);
            setAMHistoriaEduPRendimientoBajo(data.AnamnesisMenorEdad[0].HistoriaEdu[0].EduPRendimientoBajo);
            setAMHistoriaEduPDificultades(data.AnamnesisMenorEdad[0].HistoriaEdu[0].PDificultades);
            setAMHistoriaEduPNivelAdaptacion(data.AnamnesisMenorEdad[0].HistoriaEdu[0].PNivelAdaptacion);
            setAMHistoriaEduSEdad(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SEdad);
            setAMHistoriaEduSRendimientoBueno(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SRendimientoBueno);
            setAMHistoriaEduSRendimientoRegular(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SRendimientoRegular);
            setAMHistoriaEduSRendimientoBajo(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SRendimientoBajo);
            setAMHistoriaEduSDificultades(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SDificultades);
            setAMHistoriaEduSNivelAdaptacion(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SNivelAdaptacion);
            setAMHistoriaEduSQ1Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ1Txt);
            setAMHistoriaEduSQ2Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ2Txt);
            setAMHistoriaEduSQ3(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ3);
            setAMHistoriaEduSQ3Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ3Txt);
            setAMHistoriaEduSQ4Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ4Txt);
            setAMHistoriaEduSQ5Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ5Txt);
            setAMHistoriaEduSQ6Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ6Txt);
            setAMHistoriaEduSQ7Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ7Txt);
            setAMHistoriaEduSQ8Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ8Txt);
            setAMHistoriaEduSQ9Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ9Txt);
            setAMHistoriaEduSQ10Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ10Txt);
            setAMHistoriaEduSQ11Txt(data.AnamnesisMenorEdad[0].HistoriaEdu[0].SQ11Txt);

            setAMPsicosexualidadQ1(data.AnamnesisMenorEdad[0].Psicosexualidad[0].Q1); //¿A qué edad hizo preguntas su hijo, sobre sexo y procreación? Información que se le brindó ¿Cómo?    
            setAMPsicosexualidadQ2(data.AnamnesisMenorEdad[0].Psicosexualidad[0].Q2); //¿Tiene amigos(as) del sexo opuesto?                                                                  
            setAMPsicosexualidadQ3(data.AnamnesisMenorEdad[0].Psicosexualidad[0].Q3); //¿Su hijo presenta conductas como tocar o sobar los genitales? SI/NO                                  

            setAMOpinionActRelacionMamaRechazo(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionMamaRechazo);
            setAMOpinionActRelacionMamaPreocupacion(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionMamaPreocupacion);
            setAMOpinionActRelacionMamaVerguenza(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionMamaVerguenza);
            setAMOpinionActRelacionMamaIndiferencia(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionMamaIndiferencia);
            setAMOpinionActRelacionMamaAceptacion(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionMamaAceptacion);
            setAMOpinionActRelacionMamaDescrTxt(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionMamaDescrTxt);
            setAMOpinionActRelacionPadreRechazo(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionPadreRechazo);
            setAMOpinionActRelacionPadrePreocupacion(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionPadrePreocupacion);
            setAMOpinionActRelacionPadreVerguenza(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionPadreVerguenza);
            setAMOpinionActRelacionPadreIndiferencia(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionPadreIndiferencia);
            setAMOpinionActRelacionPadreAceptacion(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionPadreAceptacion);
            setAMOpinionActRelacionPadreDescrTxt(data.AnamnesisMenorEdad[0].OpinionAct[0].RelacionPadreDescrTxt);
            setAMOpinionActQ1(data.AnamnesisMenorEdad[0].OpinionAct[0].Q1);  //¿Cuál fue el comportamiento del menor al nacimiento de su hermano?                                              
            setAMOpinionActQ2(data.AnamnesisMenorEdad[0].OpinionAct[0].Q2);  //¿Cómo era la relación con sus hermanos en la infancia?                                                          
            setAMOpinionActQ3(data.AnamnesisMenorEdad[0].OpinionAct[0].Q3);  //Cambios: Aislarlo o dejarlo con el grupo, exigirle un comportamiento similar, mayor atención, sobreprotección   
            setAMOpinionActQ4(data.AnamnesisMenorEdad[0].OpinionAct[0].Q4);  //Pautas de crianza: ¿Empleaba castigos, refuerzos positivos y con qué frecuencia?                                
            setAMOpinionActQ5(data.AnamnesisMenorEdad[0].OpinionAct[0].Q5);  //Cuidadores principales en la infancia:                                                                          
            setAMOpinionActQ6(data.AnamnesisMenorEdad[0].OpinionAct[0].Q6);  //Cuidadores principales en la niñez                                                                              
            setAMOpinionActQ7(data.AnamnesisMenorEdad[0].OpinionAct[0].Q7);  //Actualmente: ¿Con quién tiene mayor apego el niño?  

            setAAAreaFamiliarEnfermedadPsiquiatrica(data.AnamnesisMenorEdad[0].AreaFamiliar[0].EnfermedadPsiquiatrica); //Especificar enfermedades psiquiátricas                                                     
            setAMAreaFamiliarQ1(data.AnamnesisMenorEdad[0].AreaFamiliar[0].Q1); //Problemas del habla, dificultades en el aprendizaje, epilepsias, convulsiones, retardo mental, entre otros).   
            setAMAreaFamiliarCaraterPapa(data.AnamnesisMenorEdad[0].AreaFamiliar[0].CaraterPapa);  //Carácter de los padres:                                                                              
            setAMAreaFamiliarCaraterMama(data.AnamnesisMenorEdad[0].AreaFamiliar[0].CaraterMama);  //Carácter de los padres:                                                                              
            setAMAreaFamiliarQ2(data.AnamnesisMenorEdad[0].AreaFamiliar[0].Q2);  //Relación de pareja: ¿Qué ha observado el niño de la relación?                                                 
            setAMAreaFamiliarQ3(data.AnamnesisMenorEdad[0].AreaFamiliar[0].Q3);  //¿Cómo es la relación de su papá con el menor?                                                                 
            setAMAreaFamiliarQ4(data.AnamnesisMenorEdad[0].AreaFamiliar[0].Q4);  //¿Cómo es la relación de su mamá con el menor?                                                                 
            setAMAreaFamiliarQ5(data.AnamnesisMenorEdad[0].AreaFamiliar[0].Q5);  //¿Cómo es la relación con sus hermanos?                                                                        
            setAMAreaFamiliarQ6(data.AnamnesisMenorEdad[0].AreaFamiliar[0].Q6);  //Observaciones durante entrvista a los padres
        }

        // @@ANANMESIS ..end

        // @@Antecedentes
        if (Object.keys(data.Antecedentes).length > 0) {
            // setAntecedentesHTA(data.Antecedentes[0]?.Personales[0]?.HTA);
            // setAntecedentesDM(data.Antecedentes[0]?.Personales[0]?.DM);
            // setAntecedentesASMA(data.Antecedentes[0]?.Personales[0]?.ASMA);
            // setAntecedentesTBC(data.Antecedentes[0]?.Personales[0]?.TBC);
            // setAntecedentesHEPATITISA(data.Antecedentes[0]?.Personales[0]?.HEPATITISA);
            // setAntecedentesHEPATITISB(data.Antecedentes[0]?.Personales[0]?.HEPATITISB);
            // setAntecedentesHEPATITISC(data.Antecedentes[0]?.Personales[0]?.HEPATITISC);
            // setAntecedentesGASTRITIS(data.Antecedentes[0]?.Personales[0]?.GASTRITIS);
            // setAntecedentesENFUCPEPTICA(data.Antecedentes[0]?.Personales[0]?.ENFUCPEPTICA);
            // setAntecedentesETS(data.Antecedentes[0]?.Personales[0]?.ETS);
            // setAntecedentesCANCER(data.Antecedentes[0]?.Personales[0]?.CANCER);
            // setAntecedentesEspeficar(data.Antecedentes[0]?.Personales[0]?.Espeficar);
            // setAntecedentesOtros(data.Antecedentes[0]?.Personales[0]?.Otros);
            // setAntecedentesQuirurguicosTraumaticos(data.Antecedentes[0]?.Personales[0]?.QuirurguicosTraumaticos);
            // setAntecedentesAtencionesPrevias(data.Antecedentes[0]?.Personales[0]?.AtencionesPrevias);
            // setAntecedentesRAM(data.Antecedentes[0]?.Personales[0]?.RAM);
            // setAntecedentesAlergias(data.Antecedentes[0]?.Personales[0]?.Alergias);
            // setAntecedentesVacunas(data.Antecedentes[0]?.Personales[0]?.Vacunas);
            // setAntecedentesAlcohol(data.Antecedentes[0]?.Personales[0]?.Alcohol);
            // setAntecedentesDrogas(data.Antecedentes[0]?.Personales[0]?.Drogas);
            // setAntecedentesAlimentacion(data.Antecedentes[0]?.Personales[0]?.Alimentacion);
            // setAntecedentesViajes(data.Antecedentes[0]?.Personales[0]?.Viajes);
            // // @A. Salud Sexual
            // setAntecedentesMenarquia(data.Antecedentes[0]?.SaludSexual[0]?.Menarquia);
            // setAntecedentesNEmbazasos(data.Antecedentes[0]?.SaludSexual[0]?.NEmbazasos);
            // setAntecedentesNAbortos(data.Antecedentes[0]?.SaludSexual[0]?.NAbortos);
            // setAntecedentesNHijos(data.Antecedentes[0]?.SaludSexual[0]?.NHijos);
            // setAntecedentesEdadInicioRelacionSexual(data.Antecedentes[0]?.SaludSexual[0]?.EdadInicioRelacionSexual);
            // setAntecedentesFechaUltimaRegla(data.Antecedentes[0]?.SaludSexual[0]?.FechaUltimaRegla);
            // setAntecedentesRegimenCatamenial(data.Antecedentes[0]?.SaludSexual[0]?.RegimenCatamenial);
            // setAntecedentesAnticonceptivos(data.Antecedentes[0]?.SaludSexual[0]?.Anticonceptivos);
            // setAntecedentesNControlesPreNatales(data.Antecedentes[0]?.SaludSexual[0]?.NControlesPreNatales);
            // setAntecedentesFechaProbbleParto(data.Antecedentes[0]?.SaludSexual[0]?.FechaProbbleParto);
            // setAntecedentesUltimaFechaPapanicolao(data.Antecedentes[0]?.SaludSexual[0]?.UltimaFechaPapanicolao);
            // setAntecedentesSaludSexualOtros(data.Antecedentes[0]?.SaludSexual[0]?.SaludSexualOtros);
            // //@A. FAMILIARES
            // setAntecedentesFamiliaresTxt(data.Antecedentes[0]?.Familiares[0]?.Txt);
        }
        // @@Antecedentes ..end

        // @@EXAMEN FISICO
        if (Object.keys(data.ExamenFisico).length > 0) {
            setExamenFisicoFRespiratoria(data.ExamenFisico[0]?.FRespiratoria);
            setExamenFisicoFCardiaca(data.ExamenFisico[0]?.FCardiaca);
            setExamenFisicoPArterial(data.ExamenFisico[0]?.PArterial);
            setExamenFisicoTemperatura(data.ExamenFisico[0]?.Temperatura);
            setExamenFisicoPeso(data.ExamenFisico[0]?.Peso);
            setExamenFisicoTalla(data.ExamenFisico[0]?.Talla);
            setExamenFisicoIMC(data.ExamenFisico[0]?.IMC);
            setExamenFisicoEscalaNumericaMalestar(data.ExamenFisico[0]?.EscalaNumericaMalestar);
            setExamenFisicoPNecesidadesEspeciales(data.ExamenFisico[0]?.PNecesidadesEspeciales);
            // @[] ¿Paciente pertenece a uno de lo siguientes grupos de pacientes con necesidades especiales?
            setExamenFisicoPNENino(data.ExamenFisico[0]?.PNE[0]?.PNENino);
            setExamenFisicoPNEAdolescente(data.ExamenFisico[0]?.PNE[0]?.PNEAdolescente);
            setExamenFisicoPNEAnciano(data.ExamenFisico[0]?.PNE[0]?.PNEAnciano);
            setExamenFisicoPNEDependenciaDrogas(data.ExamenFisico[0]?.PNE[0]?.PNEDependenciaDrogas);
            setExamenFisicoPNEVictimasAbusoAbandono(data.ExamenFisico[0]?.PNE[0]?.PNEVictimasAbusoAbandono);
            setExamenFisicoPNEQuimioterapiaRadioterapia(data.ExamenFisico[0]?.PNE[0]?.PNEQuimioterapiaRadioterapia);
            setExamenFisicoPNETrastornosEmocionalesPsiquiatricos(data.ExamenFisico[0]?.PNE[0]?.PNETrastornosEmocionalesPsiquiatricos);
            setExamenFisicoPNEEnfermedadInfecciosasContagiosas(data.ExamenFisico[0]?.PNE[0]?.PNEEnfermedadInfecciosasContagiosas);
            setExamenFisicoPNEEnfermedadTerminal(data.ExamenFisico[0]?.PNE[0]?.PNEEnfermedadTerminal);
            setExamenFisicoPNEFaseFinalEmbarazo(data.ExamenFisico[0]?.PNE[0]?.PNEFaseFinalEmbarazo);
            setExamenFisicoPNESistemaInmunologicoAfectado(data.ExamenFisico[0]?.PNE[0]?.PNESistemaInmunologicoAfectado);
        }
        // @@EXAMEN FISICO ..end

        // @@ImpresionDiagnostica
        setGridDataImpresionDiagnostica(data?.ImpresionDiagnostica);
        // @@ImpresionDiagnostica ..end

        // @@PLAN DE TRABAJO
        if (Object.keys(data.PlanTrabajo).length > 0) {
            setPlanTrabajoExamenesAyudaDiagnosticaPsicologicos(data.PlanTrabajo[0]?.ExamenesAyudaDiagnostica[0].Psicologicos);
            setPlanTrabajoExamenesAyudaDiagnosticaOtros(data.PlanTrabajo[0]?.ExamenesAyudaDiagnostica[0].Otros);
            setPlanTrabajoProcedimientos(data.PlanTrabajo[0]?.Procedimientos);
            setPlanTrabajoInterconsultas(data.PlanTrabajo[0]?.Interconsultas);
            setPlanTrabajoReferencia(data.PlanTrabajo[0]?.Referencia);
            setPlanTrabajoTratamiento(data.PlanTrabajo[0]?.Tratamiento);
        }
        // @@PLAN DE TRABAJO ..end
        setIdStaff(data?.IdStaff);
        setGridDataStaff(data?.Profesionales);

        //carga informacion EVALUACION
        pa_get_hc_servicios(data.IdCliente);
        setGridDataEvolucion(data?.Evolucion);
        setGridDataPariente(data?.Pariente);

        setGridDataAFuncionalConducta([]);
        if (data?.InfComplementaria[0]?.AFuncionalConducta) {
            setGridDataAFuncionalConducta(data?.InfComplementaria[0]?.AFuncionalConducta);
        }

        setGridDataOrdenAtencionDetalles([]);
        if (data?.PlanEvaluacion) {
            setGridDataOrdenAtencionDetalles(data.PlanEvaluacion);
        }
        setGridDataOrdenTerapiaDetalles([]);
        if (data?.PlanTerapia) {
            setGridDataOrdenTerapiaDetalles(data.PlanTerapia);
        }
        setGridDataInformePsicologico([]);
        if (data?.InformePsicologico) {
            setGridDataInformePsicologico(data.InformePsicologico);
        }

        //@@DOCUMENTOS ADJUNTO
        setGridFilesLoaded([]);
        // setGridFilesLoaded(data?.DocumentosAdjuntos.slice(0, 9));

        // notificaciones/alertas
        // console.log(resp.data[0])
        if (data?.Alerta)
            handleClickAlertarView(data.IdCliente);

        return true;
    }

    const onValidarCampos = () => {
        let vl_valida = true;
        let vl_message = '';

        if (Number(vl_IdCliente) === 0 || (!Number(vl_IdCliente))) {
            vl_valida = false;
            vl_message = 'Debe buscar un paciente';
        }
        return [vl_valida, vl_message];
    }

    const onRejectAction = () => {
        setVisibleConfirmDialog(false);
    }
    const setConfirmarGrabarHistoriaClinica = () => {
        const IsValidaCampos = onValidarCampos();
        const Isvalido = IsValidaCampos[0];
        const IsMessage = IsValidaCampos[1];
        if (!Isvalido) {
            toastConfirm.current.show({ severity: 'info', summary: '::PSISABE', detail: IsMessage, life: 4000 });
            return;
        }
        // si no hay campos invalidos procesar...    
        setVisibleConfirmDialog(true);
    }

    const setGrabarHistoriaClinica = async () => {
        // console.log('setGrabarHistoriaClinica');
        setVisibleConfirmDialog(false);
        switch (vl_mode) {
            case 'I': case 'U':
                setVisibleConfirmDialog(false);
                break;
            case 'A':
                // setVisibleConfirmDialog1(false);
                break;
            default:
                break;
        }
        // VP_LugarNacimiento: vl_LugarNacimiento, //json DEFAULT NULL,
        const vl_LugarNacimientoJson: any = [];
        vl_LugarNacimientoJson.push({
            'Ubigeo': '',
            'TipoVia': '',
            'NombreVia': vl_LugarNacimientoDireccion,
            'Numero': '',
            'Manzana': '',
            'Lote': '',
            'Dpto': '',
            'Urbanizacion': ''
        });
        // VP_LugarProcedencia: vl_LugarProcedencia,  //json DEFAULT NULL,
        const vl_LugarProcedenciaJson: any = [];
        vl_LugarProcedenciaJson.push({
            'Ubigeo': '',
            'TipoVia': '',
            'NombreVia': vl_LugarProcedenciaDireccion,
            'Numero': '',
            'Manzana': '',
            'Lote': '',
            'Dpto': '',
            'Urbanizacion': ''
        });
        // VP_DomicilioActual: vl_DomicilioActual, //json DEFAULT NULL,        
        const vl_DomicilioActualJson: any = [];
        vl_DomicilioActualJson.push({
            'Ubigeo': vl_DomicilioActualDistrito,
            'TipoVia': String(vl_DomicilioActualTipovia).trim(),
            'NombreVia': String(vl_DomicilioActualNombrevia).trim(),
            'Numero': String(vl_DomicilioActualNumero).trim(),
            'Manzana': String(vl_DomicilioActualManzana).trim(),
            'Lote': String(vl_DomicilioActualLote).trim(),
            'Dpto': String(vl_DomicilioActualDpto).trim(),
            'Urbanizacion': String(vl_DomicilioActualUrbanizacion).trim()
        });
        // VP_DatoAcompanante: vl_DatoAcompanante,  //json DEFAULT NULL,
        const vl_DatoAcompananteJson: any = [];
        vl_DatoAcompananteJson.push({
            'Nombre': String(vl_DatoAcompananteNombres).trim(),
            'TipoDocumento': '1',
            'NumeroDocumento': vl_DatoAcompananteDni
        });

        // MOTIVO DE CONSULTA: 
        // a. Motivo actual: 
        // b. Desarrollo cronológico de los síntomas: ¿desde cuándo presenta las conductas?
        // c. Signos y síntomas: 
        // d. Observación de conducta: Porte,Comportamiento,Actitud

        const vl_MotivoConsulta: any = [];
        const vl_MotivoConsultaTxt_d: any = [];
        console.log(vl_Porte);
        vl_MotivoConsultaTxt_d.push({
            'Porte': vl_Porte,
            'Comportamiento': vl_Comportamiento,
            'Actitud': vl_Actitud
        });
        vl_MotivoConsulta.push({
            'Txta': vl_MotivoConsultaTxt_a,
            'Txtb': vl_MotivoConsultaTxt_b,
            'Txtc': vl_MotivoConsultaTxt_c,
            'Txtd': vl_MotivoConsultaTxt_d
        });

        const vl_AnamnesisJson: any = [];

        // ADULTO
        const vl_AnamnesisJsonPrenatal: any = [];
        const vl_AnamnesisJsonPerinatal: any = [];
        const vl_AnamnesisJsonPosnatal: any = [];
        const vl_AnamnesisJsonHistoryM: any = [];
        const vl_AnamnesisJsonHabilidadHabla: any = [];
        const vl_AnamnesisJsonAlimentacion: any = [];
        const vl_AnamnesisJsonHigiene: any = [];
        const vl_AnamnesisJsonSuenio: any = [];

        const vl_AnamnesisJsonIndPersonal: any = [];
        const vl_AnamnesisJsonConducta: any = [];
        const vl_AnamnesisJsonJuegoSocial: any = [];
        const vl_AnamnesisJsonHistoriaEdu: any = [];
        const vl_AnamnesisJsonPsicosexualidad: any = [];
        const vl_AnamnesisJsonAreaFamiliar: any = [];
        const vl_AnamnesisJsonAreaPareja: any = [];
        const vl_AnamnesisJsonAreaLaboral: any = [];


        const vl_AAPrenatalTipoControl: any = [];
        vl_AAPrenatalTipoControl.push({
            'Medico': vl_AAPrenatalTipoControlM,
            'Partera': vl_AAPrenatalTipoControlP,
            'NoAsisttio': vl_AAPrenatalTipoControlN
        });
        vl_AnamnesisJsonPrenatal.push({
            'Questiona': vl_AAPrenatal_qa,
            'Questionb': vl_AAPrenatal_qb,
            'Questionc': vl_AAPrenatal_qc,
            'Questiond': vl_AAPrenatal_qd,
            'Questione': vl_AAPrenatal_qe,
            'TipoControl': vl_AAPrenatalTipoControl
        });

        vl_AnamnesisJsonPerinatal.push({
            'TxtTNacio': vl_AAPerinatalTxtTNacio,
            'DondeNacio': vl_AAPerinatalDondeNacio,
            'DondeNacioOtros': vl_AAPerinatalDondeNacioOtros,
            'TipoParto': vl_AAPerinatalTipoParto,
            'TipoPartoInducido': vl_AAPerinatalTipoPartoInducido,
            'PRNPesoAltura': vl_AAPerinatalPRNPesoAltura,
            'TxtLloroNacer': vl_AAPerinatalTxtLloroNacer,
            'TxtLloroNacerTiempo': vl_AAPerinatalTxtLloroNacerTiempo,
            'EdadPapa': vl_AAPerinatalEdadP,
            'EdadMama': vl_AAPerinatalEdadM,
            'Q7Txt': vl_AAConductaQ7Txt,
            'Q8Txt': vl_AAConductaQ8Txt,
            'Q9Txt': vl_AAConductaQ9Txt
        });

        vl_AnamnesisJsonPosnatal.push({
            'Questiona': vl_AAPosnatalQa,
            'Questionb': vl_AAPosnatalQb,
            'Questionc': vl_AAPosnatalQc,
            'QuestiondRespTxt': vl_AAPosnatalQdRespTxt,
            'Questiond': vl_AAPosnatalQe
        });

        vl_AnamnesisJsonHistoryM.push({
            'Txta': vl_AAHistoriaMedicaTxta,
            'Txtb': vl_AAHistoriaMedicaTxtb,
            'Qc': vl_AAHistoriaMedicaQc,
            'Convulsion': vl_AAHistoriaMedicaConvulsion,
            'Mareos': vl_AAHistoriaMedicaMareos,
            'QueEdadTenia': vl_AAHistoriaMedicaEdadTenia,
            'ComoFueAtendido': vl_AAHistoriaMedicaComoFueAtendido,
            'Operaciones': vl_AAHistoriaMedicaOperaciones,
            'OperacionesTxt': vl_AAHistoriaMedicaOperacionesTxt,
            'ExamenRealidadoN': vl_AAHistoriaMedicaExamenRealidadoN,
            'ExamenRealidadoA': vl_AAHistoriaMedicaExamenRealidadoA,
            'ExamenRealidadoP': vl_AAHistoriaMedicaExamenRealidadoP,
            'ExamenRealidadoPsi': vl_AAHistoriaMedicaExamenRealidadoPsi,
            'ExamenRealidadoOtros': vl_AAHistoriaMedicaExamenRealidadoOtros,
            'ExamenRealidadoTxt': vl_AAHistoriaMedicaExamenRealidadoTxt,
            'DomLatManual': vl_AAHistoriaMedicaDomLatManual,
            'EnfermedadActualTxt': vl_AAHistoriaMedicaEnfermedadActualTxt,
            'EnfermedadActualTratamientoTxt': vl_AAHistoriaMedicaEnfermedadActualTratamientoTxt
        });

        vl_AnamnesisJsonHabilidadHabla.push({
            'Question1': vl_AAHabilidadHablaQ1,
            'Question2': vl_AAHabilidadHablaQ2,
            'Omision': vl_AAHabilidadHablaOmision,
            'Sustitucion': vl_AAHabilidadHablaSustitucion,
            'Distorsion': vl_AAHabilidadHablaDistorsion,
            'Question3': vl_AAHabilidadHablaQ3,
            'Question4': vl_AAHabilidadHablaQ4,
            'Question5': vl_AAHabilidadHablaQ5,
            'HablaDemasiado': vl_AAHabilidadHablaCk1,
            'HablaRapido': vl_AAHabilidadHablaCk2,
            'HablaLento': vl_AAHabilidadHablaCk3,
            'HablaNormal': vl_AAHabilidadHablaCk4
        });

        vl_AnamnesisJsonAlimentacion.push({
            'Materno': vl_AAAlimentacionMaterno,
            'Artificial': vl_AAAlimentacionArtificial,
            'EdadDestete': vl_AAAlimentacionEdadDestete,
            'ProblemaApetito': vl_AAAlimentacionProblemaApetito,
            'ProblemaApetitoDecripcion': vl_AAAlimentacionProblemaApetitoDecripcion,
            'QtyComidasAlDia': vl_AAAlimentacionQtyComidasAlDia,
            'AboutDieta': vl_AAAlimentacionAboutDieta,
            'AboutDietaWhy': vl_AAAlimentacionAboutDietaWhy
        });
        vl_AnamnesisJsonHigiene.push({
            'Question1': vl_AAHigieneQ1,
            'Question2': vl_AAHigieneQ2
        });

        vl_AnamnesisJsonSuenio.push({
            'Question1': vl_AASuenioQ1,
            'Question2': vl_AASuenioQ2,
            'Question3Habla': vl_AASuenioQ3Habla,
            'Question3Grita': vl_AASuenioQ3Grita,
            'Question3SeMueve': vl_AASuenioQ3SeMueve,
            'Question3Transpira': vl_AASuenioQ3Transpira,
            'Question3Camina': vl_AASuenioQ3Camina,
            'Question4': vl_AASuenioQ4,
            'Question5': vl_AASuenioQ5,
            'Question5Txt': vl_AASuenioQ5Txt
        });

        vl_AnamnesisJsonIndPersonal.push({
            'Q1': vl_AAIndPersonalQ1,
            'Q2': vl_AAIndPersonalQ2,
            'Q2Txt': vl_AAIndPersonalQ2Txt,
            'Q3': vl_AAIndPersonalQ3,
            'Q3Txt': vl_AAIndPersonalQ3Txt,
            'Q4': vl_AAIndPersonalQ4,
            'Q4Txt': vl_AAIndPersonalQ4Txt
        });
        vl_AnamnesisJsonConducta.push({
            'Q1': vl_AAConductaQ1,
            'Q2': vl_AAConductaQ2,
            'Q3': vl_AAConductaQ3,
            'Q4': vl_AAConductaQ4,
            'Q5': vl_AAConductaQ5,
            'Q6Txt': vl_AAConductaQ6Txt
            // 'Q7Txt': vl_AAConductaQ7,
            // 'Q8Txt': vl_AAConductaQ8,
            // 'Q9Txt': vl_AAConductaQ9
        });
        vl_AnamnesisJsonJuegoSocial.push({
            'Q1': vl_AAJuegoSocialQ1,
            'Q2': vl_AAJuegoSocialQ2,
            'Q3': vl_AAJuegoSocialQ3,
            'Q4': vl_AAJuegoSocialQ4,
            'Q5': vl_AAJuegoSocialQ5
        });
        vl_AnamnesisJsonHistoriaEdu.push({
            'IEdad': vl_AAHistoriaEduIEdad,
            'IAdaptacion': vl_AAHistoriaEduIAdaptacion,
            'IDificultades': vl_AAHistoriaEduIDificultades,
            'IConductasDisfuncionales': vl_AAHistoriaEduIConductasDisfuncionales,
            'PEdad': vl_AAHistoriaEduPEdad,
            'PRendimientoBueno': vl_AAHistoriaEduPRendimientoBueno,
            'PRendimientoRegular': vl_AAHistoriaEduPRendimientoRegular,
            'PRendimientoBajo': vl_AAHistoriaEduPRendimientoBajo,
            'PDificultades': vl_AAHistoriaEduPDificultades,
            'PNivelAdaptacion': vl_AAHistoriaEduPNivelAdaptacion,
            'SEdad': vl_AAHistoriaEduSEdad,
            'SRendimientoBueno': vl_AAHistoriaEduSRendimientoBueno,
            'SRendimientoRegular': vl_AAHistoriaEduSRendimientoRegular,
            'SRendimientoBajo': vl_AAHistoriaEduSRendimientoBajo,
            'SDificultades': vl_AAHistoriaEduSDificultades,
            'SNivelAdaptacion': vl_AAHistoriaEduSNivelAdaptacion,
            'SQ1Txt': vl_AAHistoriaEduSNivelAdaptacion,
            'SQ2Txt': vl_AAHistoriaEduSQ2Txt,
            'SQ3': vl_AAHistoriaEduSQ3,
            'SQ3Txt': vl_AAHistoriaEduSQ3Txt,
            //Conducta en clases. 
            'SQ4Txt': vl_AAHistoriaEduSQ4Txt,
            'SQ5Txt': vl_AAHistoriaEduSQ5Txt,
            'SQ6Txt': vl_AAHistoriaEduSQ6Txt,
            'SQ7Txt': vl_AAHistoriaEduSQ7Txt,
            'SQ8Txt': vl_AAHistoriaEduSQ8Txt,
            // Opinión del profesor.
            'SQ9Txt': vl_AAHistoriaEduSQ9Txt,
            'SQ10Txt': vl_AAHistoriaEduSQ10Txt,
            'SQ11Txt': vl_AAHistoriaEduSQ11Txt
        });
        vl_AnamnesisJsonPsicosexualidad.push({
            'Q1': vl_AAPsicosexualidadQ1,
            'Q2': vl_AAPsicosexualidadQ2,
            'Q3': vl_AAPsicosexualidadQ3,
            'Q4': vl_AAPsicosexualidadQ4,
            'Q5': vl_AAPsicosexualidadQ5
        });
        vl_AnamnesisJsonAreaFamiliar.push({
            'RMamaRechazo': vl_AAAreaFamiliarRMamaRechazo,
            'RMamaPreocupacion': vl_AAAreaFamiliarRMamaPreocupacion,
            'RMamaVerguenza': vl_AAAreaFamiliarRMamaVerguenza,
            'RMamaIndiferencia': vl_AAAreaFamiliarRMamaIndiferencia,
            'RMamaAceptacion': vl_AAAreaFamiliarRMamaAceptacion,
            'RMamaEventoTxt': vl_AAAreaFamiliarRMamaEventoTxt,
            'RPapaRechazo': vl_AAAreaFamiliarRPapaRechazo,
            'RPapaPreocupacion': vl_AAAreaFamiliarRPapaPreocupacion,
            'RPapaVerguenza': vl_AAAreaFamiliarRPapaVerguenza,
            'RPapaIndiferencia': vl_AAAreaFamiliarRPapaIndiferencia,
            'RPapaAceptacion': vl_AAAreaFamiliarRPapaAceptacion,
            'RPapaEventoTxt': vl_AAAreaFamiliarRPapaEventoTxt,
            'Q1Txt': vl_AAAreaFamiliarQ1Txt,
            'Txt1': vl_AAAreaFamiliarTxt1,
            'Q2Txt': vl_AAAreaFamiliarQ2Txt,
            'Txt2': vl_AAAreaFamiliarTxt2,
            'Q3Txt': vl_AAAreaFamiliarQ3Txt,
            'Txt3': vl_AAAreaFamiliarTxt3,
            'Txt4': vl_AAAreaFamiliarTxt4,
            //Carácter de los padres:
            'CaractPapa': vl_AAAreaFamiliarCaractPapa,
            'CaractMama': vl_AAAreaFamiliarCaractMama,
            'Q4Txt': vl_AAAreaFamiliarQ4Txt,
            //Actualmente: ¿Cómo es la relación con?
            'Q5Txt': vl_AAAreaFamiliarQ5Txt,
            'Q6Txt': vl_AAAreaFamiliarQ6Txt,
            'Q7Txt': vl_AAAreaFamiliarQ7Txt
        });
        vl_AnamnesisJsonAreaPareja.push({
            'Q1txt': vl_AAAreaParejaQ1txt,
            'Q2txt': vl_AAAreaParejaQ2txt,
            'Q3txt': vl_AAAreaParejaQ3txt,
            'Q4txt': vl_AAAreaParejaQ4txt
        });
        vl_AnamnesisJsonAreaLaboral.push({
            'Q1txt': vl_AAAreaLaboralQ1txt
        });

        //MAIN
        vl_AnamnesisJson.push({
            'Prenatal': vl_AnamnesisJsonPrenatal,
            'Perinatal': vl_AnamnesisJsonPerinatal,
            'Posnatal': vl_AnamnesisJsonPosnatal,
            'HistoriaMedica': vl_AnamnesisJsonHistoryM,
            'HabilidadHabla': vl_AnamnesisJsonHabilidadHabla,
            'Alimentacion': vl_AnamnesisJsonAlimentacion,
            'Higiene': vl_AnamnesisJsonHigiene,
            'Suenio': vl_AnamnesisJsonSuenio,
            'IndPersonal': vl_AnamnesisJsonIndPersonal,
            'Conducta': vl_AnamnesisJsonConducta,
            'JuegoSocial': vl_AnamnesisJsonJuegoSocial,
            'HistoriaEdu': vl_AnamnesisJsonHistoriaEdu,
            'Psicosexualidad': vl_AnamnesisJsonPsicosexualidad,
            'AreaFamiliar': vl_AnamnesisJsonAreaFamiliar,
            'AreaPareja': vl_AnamnesisJsonAreaPareja,
            'AreaLaboral': vl_AnamnesisJsonAreaLaboral
        });

        const vl_AnamnesisMenorEdad: any = []; // main
        const vl_AMPrenatal: any = [];
        const vl_AMPerinatal: any = [];
        const vl_AMPosnatal: any = [];
        const vl_AMHistoriaMedica: any = [];
        const vl_AMDesarrolloNM: any = [];
        const vl_AMHabilidadHabla: any = [];
        const vl_AMMovZonaOral: any = [];
        const vl_AMAlimentacion: any = [];
        const vl_AMHigiene: any = [];
        const vl_AMSuenio: any = [];
        const vl_AMIndPersonal: any = [];
        const vl_AMConducta: any = [];
        const vl_AMJuegoSocial: any = [];
        const vl_AMHistoriaEdu: any = [];
        const vl_AMPsicosexualidad: any = [];
        const vl_AMOpinionAct: any = [];
        const vl_AMAreaFamiliar: any = [];

        vl_AMPrenatal.push({
            'Prenatal_q': vl_AMPrenatal_q,
            'Prenatal_qa': vl_AMPrenatal_qa,
            'Prenatal_qb': vl_AMPrenatal_qb,
            'Prenatal_qc': vl_AMPrenatal_qc,
            'PrenatalTipoControlM': vl_AMPrenatalTipoControlM,
            'PrenatalTipoControlP': vl_AMPrenatalTipoControlP,
            'PrenatalTipoControlN': vl_AMPrenatalTipoControlN,
            'Prenatal_qd': vl_AMPrenatal_qd,
            'Prenatal_qe': vl_AMPrenatal_qe,
            'Prenatal_qf': vl_AMPrenatal_qf,
            'Prenatal_qfTxt': vl_AMPrenatal_qfTxt
        });
        vl_AMPerinatal.push({
            'TxtTNacio': vl_AMPerinatalTxtTNacio,
            'DondeNacio': vl_AMPerinatalDondeNacio,
            'DondeNacioOtros': vl_AMPerinatalDondeNacioOtros,
            'TipoParto': vl_AMPerinatalTipoParto,
            'TipoPartoInducido': vl_AMPerinatalTipoPartoInducido,
            'PRNPesoAltura': vl_AMPerinatalPRNPesoAltura,
            'TxtLloroNacer': vl_AMPerinatalTxtLloroNacer,
            'TxtLloroNacerTiempo': vl_AMPerinatalTxtLloroNacerTiempo,
            'EdadP': vl_AMPerinatalEdadP,
            'EdadM': vl_AMPerinatalEdadM
        });
        vl_AMPosnatal.push({
            'Qa': vl_AMPosnatalQa,
            'Qb': vl_AMPosnatalQb,
            'Qc': vl_AMPosnatalQc,
            'QdRespTxt': vl_AMPosnatalQdRespTxt,
            'Qe': vl_AMPosnatalQe

        });
        vl_AMHistoriaMedica.push({
            'Txta': vl_AMHistoriaMedicaTxta,
            'Txtb': vl_AMHistoriaMedicaTxtb,
            'Qc': vl_AMHistoriaMedicaQc,
            'Convulsion': vl_AMHistoriaMedicaConvulsion,
            'Mareos': vl_AMHistoriaMedicaMareos,
            'EdadTenia': vl_AMHistoriaMedicaEdadTenia,
            'ComoFueAtendido': vl_AMHistoriaMedicaComoFueAtendido,
            'Operaciones': vl_AMHistoriaMedicaOperaciones,
            'OperacionesTxt': vl_AMHistoriaMedicaOperacionesTxt,
            'ExamenRealidadoN': vl_AMHistoriaMedicaExamenRealidadoN,
            'ExamenRealidadoA': vl_AMHistoriaMedicaExamenRealidadoA,
            'ExamenRealidadoP': vl_AMHistoriaMedicaExamenRealidadoP,
            'ExamenRealidadoPsi': vl_AMHistoriaMedicaExamenRealidadoPsi,
            'ExamenRealidadoOtros': vl_AMHistoriaMedicaExamenRealidadoOtros,
            'ExamenRealidadoTxt': vl_AMHistoriaMedicaExamenRealidadoTxt
        });
        vl_AMDesarrolloNM.push({
            'EdadLevantarCabeza': vl_AMDesarrolloNMEdadLevantarCabeza,
            'EdadSentarse': vl_AMDesarrolloNMEdadSentarse,
            'EdadGatear': vl_AMDesarrolloNMEdadGatear,
            'EdadPararse': vl_AMDesarrolloNMEdadPararse,
            'EdadCaminar': vl_AMDesarrolloNMEdadCaminar,
            'Dificultades': vl_AMDesarrolloNMDificultades,
            'MovimientoSacudeBrazo': vl_AMDesarrolloNMMovimientoSacudeBrazo,
            'MovimientoEstrujaMano': vl_AMDesarrolloNMMovimientoEstrujaMano,
            'MovimientoTxt': vl_AMDesarrolloNMMovimientoTxt,
            'MovimientoTxt2': vl_AMDesarrolloNMMovimientoTxt2,
            'HabCaminar': vl_AMDesarrolloNMHabCaminar,
            'HabSaltar1Pie': vl_AMDesarrolloNMHabSaltar1Pie,
            'HabDesplazarSalt1Pie': vl_AMDesarrolloNMHabDesplazarSalt1Pie,
            'DomLatManual': vl_AMDesarrolloNMDomLatManual
        });
        vl_AMHabilidadHabla.push({
            'Q1': vl_AMHabilidadHablaQ1,
            'Q2': vl_AMHabilidadHablaQ2,
            'EntenderGestos': vl_AMHabilidadHablaEntenderGestos,
            'EntenderGritos': vl_AMHabilidadHablaEntenderGritos,
            'EntenderHablando': vl_AMHabilidadHablaEntenderHablando,
            'EntenderBalbuceando': vl_AMHabilidadHablaEntenderBalbuceando,
            'EntenderOtros': vl_AMHabilidadHablaEntenderOtros,
            'Q3': vl_AMHabilidadHablaQ3,
            'DificultadPronOmision': vl_AMHabilidadHablaDificultadPronOmision,
            'DificultadPronSustitucion': vl_AMHabilidadHablaDificultadPronSustitucion,
            'DificultadPronDistorsion': vl_AMHabilidadHablaDificultadPronDistorsion,
            'Q4': vl_AMHabilidadHablaQ4,
            'Q5': vl_AMHabilidadHablaQ5,
            'Q6': vl_AMHabilidadHablaQ6,
            'Q7': vl_AMHabilidadHablaQ7,
            'Q8': vl_AMHabilidadHablaQ8,
            'Q9': vl_AMHabilidadHablaQ9,
            'Q10': vl_AMHabilidadHablaQ10,
            'Q11': vl_AMHabilidadHablaQ11,
            'Q12': vl_AMHabilidadHablaQ12,
            'Q13': vl_AMHabilidadHablaQ13,
            'Q14': vl_AMHabilidadHablaQ14,
            'Demasiado': vl_AMHabilidadHablaDemasiado,
            'Rapido': vl_AMHabilidadHablaRapido,
            'Lento': vl_AMHabilidadHablaLento,
            'Normal': vl_AMHabilidadHablaNormal,
            'Q15Txt': vl_AMHabilidadHablaQ15Txt
        });
        vl_AMMovZonaOral.push({
            'UsoBiberon': vl_AMMovZonaOralUsoBiberon,
            'Q1': vl_AMMovZonaOralQ1,
            'MasticacionQ1': vl_AMMovZonaOralMasticacionQ1,
            'MasticacionQ2': vl_AMMovZonaOralMasticacionQ2,
            'MasticacionQ3': vl_AMMovZonaOralMasticacionQ3,
            'MasticacionQ4': vl_AMMovZonaOralMasticacionQ4,
            'MasticacionQ5': vl_AMMovZonaOralMasticacionQ5,
            'MasticacionQ6': vl_AMMovZonaOralMasticacionQ6,
            'MasticacionQ7': vl_AMMovZonaOralMasticacionQ7,
            'BabeaAlDormir': vl_AMMovZonaOralBabeaAlDormir,
            'BabeaAlComer': vl_AMMovZonaOralBabeaAlComer,
            'BabeaEnTodoMomento': vl_AMMovZonaOralBabeaEnTodoMomento,
            'DiffRespirarViaResp': vl_AMMovZonaOralDiffRespirarViaResp,
            'DiffRespirarAlergias': vl_AMMovZonaOralDiffRespirarAlergias,
            'DiffRespirarResfriado': vl_AMMovZonaOralDiffRespirarResfriado,
            'DiffRespirarAsma': vl_AMMovZonaOralDiffRespirarAsma,
            'DiffRespirarOtros': vl_AMMovZonaOralDiffRespirarOtros,
            'DiffRespirarOtrosTxt': vl_AMMovZonaOralDiffRespirarOtrosTxt
        });
        vl_AMAlimentacion.push({
            'Materno': vl_AMAlimentacionMaterno,
            'Artificial': vl_AMAlimentacionArtificial,
            'EdadDestete': vl_AMAlimentacionEdadDestete,
            'RequiereAyuda': vl_AMAlimentacionRequiereAyuda,
            'UsaCubiertos': vl_AMAlimentacionUsaCubiertos,
            'ProblemaApetito': vl_AMAlimentacionProblemaApetito,
            'QtyComidasAlDia': vl_AMAlimentacionQtyComidasAlDia,
            'AboutDieta': vl_AMAlimentacionAboutDieta,
            'AboutDietaWhy': vl_AMAlimentacionAboutDietaWhy
        });
        vl_AMHigiene.push({
            'Q1': vl_AMHigieneQ1,
            'Q2': vl_AMHigieneQ2,
            'Q3': vl_AMHigieneQ3,
            'Q3Txt': vl_AMHigieneQ3Txt,
            'Q4': vl_AMHigieneQ4,
            'Q4Txt': vl_AMHigieneQ4Txt
        });
        vl_AMSuenio.push({
            'Q1': vl_AMSuenioQ1,
            'Q2': vl_AMSuenioQ2,
            'Q3Habla': vl_AMSuenioQ3Habla,
            'Q3Grita': vl_AMSuenioQ3Grita,
            'Q3SeMueve': vl_AMSuenioQ3SeMueve,
            'Q3Transpira': vl_AMSuenioQ3Transpira,
            'Q3Camina': vl_AMSuenioQ3Camina,
            'Q4': vl_AMSuenioQ4,
            'Q5': vl_AMSuenioQ5,
            'Q5Txt': vl_AMSuenioQ5Txt
        });
        vl_AMIndPersonal.push({
            'Q1': vl_AMIndPersonalQ1,
            'Q2': vl_AMIndPersonalQ2,
            'Q3': vl_AMIndPersonalQ3,
            'Q4': vl_AMIndPersonalQ4,
            'Q5Txt': vl_AMIndPersonalQ5Txt,
            'Q6': vl_AMIndPersonalQ6,
            'Q6Txt': vl_AMIndPersonalQ6Txt,
            'Q7': vl_AAIndPersonalQ7,
            'Q8': vl_AAIndPersonalQ8,
            'Q8Txt': vl_AAIndPersonalQ8Txt
        });
        vl_AMConducta.push({
            'Q1': vl_AMConductaQ1,
            'Q2': vl_AMConductaQ2,
            'Q3': vl_AMConductaQ3,
            'Q4': vl_AMConductaQ4,
            'Q5': vl_AMConductaQ5,
            'Q6': vl_AMConductaQ6,
            'Q7': vl_AMConductaQ7,
            'Q8': vl_AMConductaQ8,
            'Q8Txt': vl_AMConductaQ8Txt,
            'Q9Txt': vl_AMConductaQ9Txt
        });
        vl_AMJuegoSocial.push({
            'Q1': vl_AMJuegoSocialQ1,
            'Q2': vl_AMJuegoSocialQ2,
            'Q3': vl_AMJuegoSocialQ3,
            'Q4': vl_AMJuegoSocialQ4,
            'Q5': vl_AMJuegoSocialQ5
        });
        vl_AMHistoriaEdu.push({
            'IEdad': vl_AMHistoriaEduIEdad,
            'IAdaptacion': vl_AMHistoriaEduIAdaptacion,
            'IDificultades': vl_AMHistoriaEduIDificultades,
            'IConductasDisfuncionales': vl_AMHistoriaEduIConductasDisfuncionales,
            'PEdad': vl_AMHistoriaEduPEdad,
            'PRendimientoBueno': vl_AMHistoriaEduPRendimientoBueno,
            'PRendimientoRegular': vl_AMHistoriaEduPRendimientoRegular,
            'PRendimientoBajo': vl_AMHistoriaEduPRendimientoBajo,
            'PDificultades': vl_AMHistoriaEduPDificultades,
            'PNivelAdaptacion': vl_AMHistoriaEduPNivelAdaptacion,
            'SEdad': vl_AMHistoriaEduSEdad,
            'SRendimientoBueno': vl_AMHistoriaEduSRendimientoBueno,
            'SRendimientoRegular': vl_AMHistoriaEduSRendimientoRegular,
            'SRendimientoBajo': vl_AMHistoriaEduSRendimientoBajo,
            'SDificultades': vl_AMHistoriaEduSDificultades,
            'SNivelAdaptacion': vl_AMHistoriaEduSNivelAdaptacion,
            'SQ1Txt': vl_AMHistoriaEduSQ1Txt,
            'SQ2Txt': vl_AMHistoriaEduSQ2Txt,
            'SQ3': vl_AMHistoriaEduSQ3,
            'SQ3Txt': vl_AMHistoriaEduSQ3Txt,
            'SQ4Txt': vl_AMHistoriaEduSQ4Txt,
            'SQ5Txt': vl_AMHistoriaEduSQ5Txt,
            'SQ6Txt': vl_AMHistoriaEduSQ6Txt,
            'SQ7Txt': vl_AMHistoriaEduSQ7Txt,
            'SQ8Txt': vl_AMHistoriaEduSQ8Txt,
            'SQ9Txt': vl_AMHistoriaEduSQ9Txt,
            'SQ10Txt': vl_AMHistoriaEduSQ10Txt,
            'SQ11Txt': vl_AMHistoriaEduSQ11Txt
        });
        vl_AMPsicosexualidad.push({
            'Q1': vl_AMPsicosexualidadQ1,
            'Q2': vl_AMPsicosexualidadQ2,
            'Q3': vl_AMPsicosexualidadQ3
        });
        vl_AMOpinionAct.push({
            'RelacionMamaRechazo': vl_AMOpinionActRelacionMamaRechazo,
            'RelacionMamaPreocupacion': vl_AMOpinionActRelacionMamaPreocupacion,
            'RelacionMamaVerguenza': vl_AMOpinionActRelacionMamaVerguenza,
            'RelacionMamaIndiferencia': vl_AMOpinionActRelacionMamaIndiferencia,
            'RelacionMamaAceptacion': vl_AMOpinionActRelacionMamaAceptacion,
            'RelacionMamaDescrTxt': vl_AMOpinionActRelacionMamaDescrTxt,
            'RelacionPadreRechazo': vl_AMOpinionActRelacionPadreRechazo,
            'RelacionPadrePreocupacion': vl_AMOpinionActRelacionPadrePreocupacion,
            'RelacionPadreVerguenza': vl_AMOpinionActRelacionPadreVerguenza,
            'RelacionPadreIndiferencia': vl_AMOpinionActRelacionPadreIndiferencia,
            'RelacionPadreAceptacion': vl_AMOpinionActRelacionPadreAceptacion,
            'RelacionPadreDescrTxt': vl_AMOpinionActRelacionPadreDescrTxt,
            'Q1': vl_AMOpinionActQ1,
            'Q2': vl_AMOpinionActQ2,
            'Q3': vl_AMOpinionActQ3,
            'Q4': vl_AMOpinionActQ4,
            'Q5': vl_AMOpinionActQ5,
            'Q6': vl_AMOpinionActQ6,
            'Q7': vl_AMOpinionActQ7
        });

        vl_AMAreaFamiliar.push({
            'EnfermedadPsiquiatrica': vl_AMAreaFamiliarEnfermedadPsiquiatrica,
            'Q1': vl_AMAreaFamiliarQ1,
            'CaraterPapa': vl_AMAreaFamiliarCaraterPapa,
            'CaraterMama': vl_AMAreaFamiliarCaraterMama,
            'Q2': vl_AMAreaFamiliarQ2,
            'Q3': vl_AMAreaFamiliarQ3,
            'Q4': vl_AMAreaFamiliarQ4,
            'Q5': vl_AMAreaFamiliarQ5,
            'Q6': vl_AMAreaFamiliarQ6
        });

        vl_AnamnesisMenorEdad.push({
            'Prenatal': vl_AMPrenatal,
            'Perinatal': vl_AMPerinatal,
            'Posnatal': vl_AMPosnatal,
            'HistoriaMedica': vl_AMHistoriaMedica,
            'DesarrolloNM': vl_AMDesarrolloNM,
            'HabilidadHabla': vl_AMHabilidadHabla,
            'MovZonaOral': vl_AMMovZonaOral,
            'Alimentacion': vl_AMAlimentacion,
            'Higiene': vl_AMHigiene,
            'Suenio': vl_AMSuenio,
            'IndPersonal': vl_AMIndPersonal,
            'Conducta': vl_AMConducta,
            'JuegoSocial': vl_AMJuegoSocial,
            'HistoriaEdu': vl_AMHistoriaEdu,
            'Psicosexualidad': vl_AMPsicosexualidad,
            'OpinionAct': vl_AMOpinionAct,
            'AreaFamiliar': vl_AMAreaFamiliar
        });

        // @@ANTECEDENTES - NO SE USA X AHORA -- MAS ADELANTE  SE PUEDE USAR PARA OTROS DATOS
        const vl_AntecedentesJson: any = [];

        // @PERSONALES [] - NO VA
        const vl_AntecedentesPersonales: any = [];
        // vl_AntecedentesPersonales.push({
        //     'HTA': vl_AntecedentesHTA,
        //     'DM': vl_AntecedentesDM,
        //     'ASMA': vl_AntecedentesASMA,
        //     'TBC': vl_AntecedentesTBC,
        //     'HEPATITISA': vl_AntecedentesHEPATITISA,
        //     'HEPATITISB': vl_AntecedentesHEPATITISB,
        //     'HEPATITISC': vl_AntecedentesHEPATITISC,
        //     'GASTRITIS': vl_AntecedentesGASTRITIS,
        //     'ENFUCPEPTICA': vl_AntecedentesENFUCPEPTICA,
        //     'ETS': vl_AntecedentesETS,
        //     'CANCER': vl_AntecedentesCANCER,
        //     'Espeficar': vl_AntecedentesEspeficar,
        //     'Otros': vl_AntecedentesOtros,
        //     'QuirurguicosTraumaticos': vl_AntecedentesQuirurguicosTraumaticos,
        //     'AtencionesPrevias': vl_AntecedentesAtencionesPrevias,
        //     'RAM': vl_AntecedentesRAM,
        //     'Alergias': vl_AntecedentesAlergias,
        //     'Vacunas': vl_AntecedentesVacunas,
        //     'Alcohol': vl_AntecedentesAlcohol,
        //     'Drogas': vl_AntecedentesDrogas,
        //     'Alimentacion': vl_AntecedentesAlimentacion,
        //     'Viajes': vl_AntecedentesViajes
        // });

        //  @SALUD SEXUAL Y GINECO-OBSTÉTRICOS []        
        const vl_AntecedentesSaludSexual: any = [];
        // vl_AntecedentesSaludSexual.push({
        //     'Menarquia': vl_AntecedentesMenarquia,
        //     'NEmbazasos': vl_AntecedentesNEmbazasos,
        //     'NAbortos': vl_AntecedentesNAbortos,
        //     'NHijos': vl_AntecedentesNHijos,
        //     'EdadInicioRelacionSexual': vl_AntecedentesEdadInicioRelacionSexual,
        //     'FechaUltimaRegla': vl_AntecedentesFechaUltimaRegla,
        //     'RegimenCatamenial': vl_AntecedentesRegimenCatamenial,
        //     'Anticonceptivos': vl_AntecedentesAnticonceptivos,
        //     'NControlesPreNatales': vl_AntecedentesNControlesPreNatales,
        //     'FechaProbbleParto': vl_AntecedentesFechaProbbleParto,
        //     'UltimaFechaPapanicolao': vl_AntecedentesUltimaFechaPapanicolao,
        //     'SaludSexualOtros': vl_AntecedentesSaludSexualOtros
        // });
        // @FAMILIARES
        const vl_AntecedentesFamiliares: any = [];
        // vl_AntecedentesFamiliares.push({
        //     'Txt': vl_AntecedentesFamiliaresTxt
        // });

        vl_AntecedentesJson.push({
            'Personales': vl_AntecedentesPersonales,
            'SaludSexual': vl_AntecedentesSaludSexual,
            'Familiares': vl_AntecedentesFamiliares
        });

        // @@ANTECEDENTES ..end

        const vl_ExamenFisicoJson: any = [];
        const vl_ExamenFisicoPNE: any = [];
        vl_ExamenFisicoPNE.push({
            'PNENino': vl_ExamenFisicoPNENino,
            'PNEAdolescente': vl_ExamenFisicoPNEAdolescente,
            'PNEAnciano': vl_ExamenFisicoPNEAnciano,
            'PNEDependenciaDrogas': vl_ExamenFisicoPNEDependenciaDrogas,
            'PNEVictimasAbusoAbandono': vl_ExamenFisicoPNEVictimasAbusoAbandono,
            'PNEQuimioterapiaRadioterapia': vl_ExamenFisicoPNEQuimioterapiaRadioterapia,
            'PNETrastornosEmocionalesPsiquiatricos': vl_ExamenFisicoPNETrastornosEmocionalesPsiquiatricos,
            'PNEEnfermedadInfecciosasContagiosas': vl_ExamenFisicoPNEEnfermedadInfecciosasContagiosas,
            'PNEEnfermedadTerminal': vl_ExamenFisicoPNEEnfermedadTerminal,
            'PNEFaseFinalEmbarazo': vl_ExamenFisicoPNEFaseFinalEmbarazo,
            'PNESistemaInmunologicoAfectado': vl_ExamenFisicoPNESistemaInmunologicoAfectado,
        });
        vl_ExamenFisicoJson.push({
            'FRespiratoria': vl_ExamenFisicoFRespiratoria,
            'FCardiaca': vl_ExamenFisicoFCardiaca,
            'PArterial': vl_ExamenFisicoPArterial,
            'Temperatura': vl_ExamenFisicoTemperatura,
            'Peso': vl_ExamenFisicoPeso,
            'Talla': vl_ExamenFisicoTalla,
            'IMC': vl_ExamenFisicoIMC,
            'EscalaNumericaMalestar': vl_ExamenFisicoEscalaNumericaMalestar,
            'Otros': vl_ExamenFisicoOtros,
            'PNecesidadesEspeciales': vl_ExamenFisicoPNecesidadesEspeciales,
            // @[] ¿Paciente pertenece a uno de lo siguientes grupos de pacientes con necesidades especiales?
            'PNE': vl_ExamenFisicoPNE
        });
        const vl_ImpresionDiagnosticaJson: any = gridDataImpresionDiagnostica;

        const vl_PlanTrabajoJson: any = [];
        const vl_PlanTrabajoJsonExamenesAyudaDiagnostica: any = [];

        vl_PlanTrabajoJsonExamenesAyudaDiagnostica.push({
            'Psicologicos': vl_PlanTrabajoExamenesAyudaDiagnosticaPsicologicos,
            'Otros': vl_PlanTrabajoExamenesAyudaDiagnosticaOtros,
        });
        vl_PlanTrabajoJson.push({
            'ExamenesAyudaDiagnostica': vl_PlanTrabajoJsonExamenesAyudaDiagnostica,
            'Procedimientos': vl_PlanTrabajoProcedimientos,
            'Interconsultas': vl_PlanTrabajoInterconsultas,
            'Referencia': vl_PlanTrabajoReferencia,
            'Tratamiento': vl_PlanTrabajoTratamiento
        });

        // informacion complementaria: RELIGION, NO DE HERMANOS, escolaridad, inst. educativa(menores de edad)
        const vl_InfComplementariaJson: any = [];
        vl_InfComplementariaJson.push({
            'IdReligion': vl_IdReligion,
            'Religion': vl_Religion,
            'NumeroHermanos': vl_NumeroHermanos,
            'Escolaridad': vl_Escolaridad,
            'InstEducativa': vl_InstEducativa,
            'Referente': vl_Referente,
            'ConquienesViven': vl_ConquienesViven,
            'AFuncionalConducta': gridDataAFuncionalConducta //analisis funcional de condeucta[]
        });


        const vl_ProfesionalesJson: any = gridDataStaff;
        const vl_EvolucionJson: any = gridDataEvolucion;
        const vl_Parientes: any = gridDataPariente;


        // const vl_PlanEvaluacion: any = gridDataOrdenAtencionDetalles;
        // const vl_PlanTerapia: any = [];
        // const vl_EvolucionTerapia: any = [];

        const param = {
            VP_ACTION: vl_mode,
            VP_IdHistoriaClinica: vl_IdHistoriaClinica,  // int NOT NULL AUTO_INCREMENT,
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),  //int DEFAULT '1',
            VP_NumeroHistoriaClinica: vl_NumeroHistoriaClinica,  //varchar(8) NOT NULL COMMENT 'NUMERO DE HISTORIA CLINICA',
            VP_IdCliente: vl_IdCliente,  //int DEFAULT NULL COMMENT 'FK TB_CLIENTE',           
            VP_IdTipoDocumento: vl_IdTipoDocumento,  //int DEFAULT NULL COMMENT 'FK_TB_TIPO_DOCUMENTO',
            VP_NumeroDocumento: vl_NumeroDocumento,  //varchar(20) DEFAULT NULL,
            VP_Sexo: vl_Sexo,  //char(1) DEFAULT NULL,
            VP_ApellidoPaterno: vl_ApellidoPaterno, //varchar(100) DEFAULT NULL,
            VP_ApellidoMaterno: vl_ApellidoMaterno,  //varchar(100) DEFAULT NULL,
            VP_Nombres: vl_Nombres,  //varchar(80) DEFAULT NULL,
            VP_FechaNacimiento: setIntlDateTimeFormat(vl_FechaNacimiento),  //date DEFAULT NULL,
            VP_Edad: vl_Edad,  //int DEFAULT NULL,
            VP_IdEstadoCivil: vl_IdEstadoCivil, //int DEFAULT NULL,
            VP_IdGradoInstruccion: vl_IdGradoInstruccion,  //int DEFAULT NULL,
            VP_IdOcupacion: vl_IdOcupacion,  //int DEFAULT NULL,
            VP_LugarNacimiento: JSON.stringify(vl_LugarNacimientoJson), //json DEFAULT NULL,
            VP_LugarProcedencia: JSON.stringify(vl_LugarProcedenciaJson),  //json DEFAULT NULL,
            VP_DomicilioActual: JSON.stringify(vl_DomicilioActualJson), //vl_DomicilioActual, //json DEFAULT NULL,
            VP_DatoAcompanante: JSON.stringify(vl_DatoAcompananteJson),  //json DEFAULT NULL,
            VP_MotivoConsulta: JSON.stringify(vl_MotivoConsulta),  //json DEFAULT NULL, NEW
            VP_Anamnesis: JSON.stringify(vl_AnamnesisJson),  //json DEFAULT NULL,
            VP_AnamnesisMenorEdad: JSON.stringify(vl_AnamnesisMenorEdad),  //json DEFAULT NULL,
            VP_Antecedentes: JSON.stringify(vl_AntecedentesJson),  //json DEFAULT NULL,
            VP_ExamenFisico: JSON.stringify(vl_ExamenFisicoJson),  //json DEFAULT NULL,
            VP_ImpresionDiagnostica: JSON.stringify(vl_ImpresionDiagnosticaJson),  //json DEFAULT NULL,
            VP_PlanTrabajo: JSON.stringify(vl_PlanTrabajoJson),  //json DEFAULT NULL,
            VP_InfComplementaria: JSON.stringify(vl_InfComplementariaJson),  //json DEFAULT NULL,
            VP_IdStaff: vl_IdStaff, //int DEFAULT NULL COMMENT 'Profesional Tratante actual',
            VP_Profesionales: JSON.stringify(vl_ProfesionalesJson),  //json DEFAULT NULL COMMENT 'Todos los demas profesionales que hayan intervenido en la HC',
            VP_Evolucion: JSON.stringify(vl_EvolucionJson),
            VP_Parientes: JSON.stringify(vl_Parientes),  // new 
            VP_IdUsuario: localStorage.getItem('authIdUsuario')
            // VP_PlanEvaluacion: JSON.stringify(vl_PlanEvaluacion),
            // VP_PlanTerapia: JSON.stringify(vl_PlanTerapia),
            // VP_EvolucionTerapia: JSON.stringify(vl_EvolucionTerapia)
        }
        // console.log('INPUT:');
        console.log(param);
        // return;
        setProgressBar(true);
        const response = await fetchSinToken('paciente/pa_set_historia_clinica', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];

        console.log('RESPONSE:');
        console.log(record);
        if (record) {
            const message = record.sql_message;
            toast.current.show({ severity: 'success', summary: '::PSISABE', detail: message, life: 4000 });
            //recargar informacion
            if (record.sql_error === 0) {
                //setTimeout(() => {
                getHistoriaClinica(vl_NumeroHistoriaClinica);
                //}, 4000);                
            }
        } else {
            const message =
                'Code: ' + resp.data.code +
                'Errno: ' + resp.data.errno +
                'Sql: ' + resp.data.sql +
                'SqlState: ' + resp.data.sqlState +
                'SqlMessage: ' + resp.data.sqlMessage;
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: message, life: 8000 });
        }
        setProgressBar(false);
    }

    const setImprimirHistoriaClinica = () => {
        // console.log('setImprimirHistoriaClinica');
        buildPDFFormatoHistoriaClinica();
    }


    const onTemplateSelect = (e: any) => {
        let _totalSize = vl_totalSize;
        // console.log(e);
        _totalSize = e.files[0].size;
        // e.files.forEach((file: any) => {
        //     _totalSize += file.size;
        // });
        setTotalSize(_totalSize);
    }
    const onTemplateClear = () => {
        setTotalSize(0);
    }
    const onTemplateRemove = (file: any, callback: any) => {
        setTotalSize(vl_totalSize - file.size);
        callback();
    }

    const getDownloadfile = (e: any, in_path: string, in_fileName: string) => {
        // download('http://bit.ly/2mTM3nY','test.jpg');
        // var fileDownload = require('js-file-download');
        // fileDownload('D:\\temp\CPS Adultos.docx', 'filename.csv');
        // GET request for remote image in node.js      
        const params = 'VP_IdHistoriaClinica=' + vl_IdHistoriaClinica +
            '&VP_IdSucursal=' + localStorage.getItem('authIdSucursal') +
            '&VP_IdUsuario=' + localStorage.getItem('authIdUsuario') +
            '&VP_Path=' + in_path;
        const endpoint = 'uploadFiles/pa_set_hc_donload_files/?' + params;
        const baseUrl = process.env.REACT_APP_API_URL;
        const url = `${baseUrl}/${endpoint}`;
        // const in_fileName:string = '';
        Axios({
            method: 'get',
            url: url,
            responseType: 'blob',
        }).then(function (response: any) {
            fileDownload(response.data, in_fileName);
        });
    }

    const getDeletefile = async (e: any, in_path: string, in_fileName: string) => {

        const param = {
            VP_IdHistoriaClinica: vl_IdHistoriaClinica,
            VP_IdSucursal: localStorage.getItem('authIdSucursal'),
            VP_IdUsuario: localStorage.getItem('authIdUsuario'),
            VP_Path: in_path,
            VP_fileName: in_fileName
        }
        const response = await fetchSinToken('uploadFiles/pa_set_hc_remove_files', param, 'POST');
        const resp = await response.json();
        const record = resp.data[0];
        // console.log('response:');
        console.log(record);
        if (record) {
            toast.current.show({ severity: 'info', summary: '::PSISABE', detail: record.sql_message, life: 8000 });
            if (record.sql_error === 0) {
                // recargar datos 
                getHistoriaClinica(vl_NumeroHistoriaClinica);
            }
        } else {
            toast.current.show({ severity: 'error', summary: '::PSISABE', detail: record.sql_message, life: 8000 });
        }
    }

    const endpointUPLOAD = 'uploadFiles/upload_file_S3/?VP_IdHistoriaClinica=' + vl_IdHistoriaClinica + '&VP_IdSucursal=' + localStorage.getItem('authIdSucursal') + '&VP_IdUsuario=' + localStorage.getItem('authIdUsuario');
    // const endpointUPLOAD = 'uploadFiles/pa_set_hc_upload_files/?VP_IdHistoriaClinica=' + vl_IdHistoriaClinica + '&VP_IdSucursal=' + localStorage.getItem('authIdSucursal') + '&VP_IdUsuario=' + localStorage.getItem('authIdUsuario');
    const baseUrl = process.env.REACT_APP_API_URL;
    const hc_files_upload_url = `${baseUrl}/${endpointUPLOAD}`;

    //folder FILES CRUD ...end
    const displayValueTemplateEvoluacion = (value: any) => {
        return (
            <React.Fragment>
                {vl_EvolucionNumeroSesion} /  {vl_EvolucionNumeroSesionTotal}
            </React.Fragment>
        );
    }

    // Agrega titulo a la barra de navegacion superior
    const { showmenuTitle } = useContext(AppContext01);
    const handleSetTitle = () => {
        showmenuTitle('/HISTORIA CLÍNICA');
    }

    const setActiveIndex_1 = (itemIndex: any) => {
        setActiveIndex(itemIndex[1]);
        switch (parseFloat(itemIndex[1])) {
            case 10:
                pa_get_derivacion_paciente();
                break;
            default:
                break;
        }
    }

    const setAcceptIrBandejaDerivacion = () => {
        // ir a bandeja
        setVisibleConfirmDialog2(false);
        link_form_derivacion(vl_NumeroHistoriaClinica);
    }

    const setRejectIrBandejaDerivacion = () => {
        console.log('cancelado...');
    }

    const handleClickAlertarView = async (vp_IdCliente: any) => {
        const param = {
            "VP_IdNotificacion": 0, //VP_IdNotificacion
            "VP_IdCliente": vp_IdCliente,
            "VP_NumeroDocumento": '',
            "VP_NumeroHistoriaClinica": '',
            "VP_Filter": "#99"
        }
        const response = await fetchSinToken('catalogo/pa_get_notificaciones', param, 'POST');
        const resp = await response.json();
        const record = resp.data;
        // console.log(record);
        let array: any = [];
        if (record.length > 0) {
            record.forEach(
                function (row: any) {
                    if (row.Mostrar.S2) {
                        array.push(row);
                    }
                }
            );
            if (array.length > 0) {
                setDataNotificacion(array);
                setVisibleTop(true);
            }
        }
        console.log(array);
    }

    const rowClassName = (data: any) => {
        // return isSelectable(data.Situacion, 'Situacion') ? '' : 'p-disabled bg-gray-100 line-through text-red-500';
        return data.Estado === 1 ? '' : 'bg-gray-100 line-through text-red-500';
    }

    return (
        <>
            <Sidebar visible={visibleTop} closeOnEscape position="left" onHide={() => setVisibleTop(false)}   >
                <h4>Alertas</h4>
                <button className="p-sidebar-icon p-link mr-1 p-2">
                    <span className="pi pi-arrow-right" />
                </button>
                <h6>{dataHistoriaClinica?.Paciente}</h6>
                <DataTable size="small" resizableColumns style={{ width: '100%' }}
                    responsiveLayout="scroll"
                    columnResizeMode="fit"
                    scrollable
                    value={dataNotificacion}
                    scrollHeight="80vh"
                    dataKey="IdNotificacion"
                    selectionMode="single"
                >
                    <Column field="Paciente" bodyClassName="white-space-normal"
                        body={(item: any) => {
                            return (
                                <div className='p-1 m-1 w-full ' >
                                    {/* <label className={`${getClaseName(item.Categoria)}`}>{' ' + item.Mensaje.mensaje}</label> */}
                                    <InputTextarea rows={7} cols={50} className={`${ getClaseName(item.Categoria) }`}
                                        style={{ width: '100%' }} readOnly
                                        value={item.Mensaje.mensaje}
                                    />
                                </div>
                            );
                        }}
                    ></Column>
                </DataTable>
            </Sidebar>

            <ProgressBarUtil
                ProgressBarVisible={b_ProgressBar}
                ProgressBarMessage='Please wait, loading...'
                setProgressBar={setProgressBar}
            />
            <React.Fragment>{isMounted.current && <BuscadorClientesForm />}</React.Fragment>
            <Toast ref={toast} position="bottom-right"></Toast>
            <Toast ref={toastConfirm} position="center"></Toast>
            <ConfirmDialog visible={b_visible} onHide={() => setVisibleConfirmDialog(false)}
                message="¿Guardar Historia Clinica?"
                header="Confirmar para continuar"
                icon="pi pi-exclamation-triangle"
                accept={setGrabarHistoriaClinica}
                reject={onRejectAction}
            />
            <ConfirmDialog visible={b_visible2} onHide={() => setVisibleConfirmDialog2(false)}
                message="¿Seguro de ir hacia derivaciones? ¡Esta acción hará que abandone la HC!"
                header="Ir a Bandeja derivaciones..."
                icon="pi pi-exclamation-triangle"
                accept={setAcceptIrBandejaDerivacion}
                reject={setRejectIrBandejaDerivacion}
            />

            <div className="flex align-items-stretch flex-wrap justify-content-center border-bottom-1 border-gray-600 ">
                <div className='max-w-full mr-4'>
                    <HeaderOptions
                        setNumeroHistoriaClinica={setNumeroHistoriaClinica}
                        NumeroHistoriaClinica={vl_NumeroHistoriaClinica}
                        getHistoriaClinica={getHistoriaClinica}
                        handleClickBuscar={handleClickBuscar}
                        setConfirmarGrabarHistoriaClinica={setConfirmarGrabarHistoriaClinica}
                        setImprimirHistoriaClinica={setImprimirHistoriaClinica}
                    />
                </div>
                <div className='flex align-items-center' >
                    <span className="p-buttonset p-1">
                        <Button
                            label="Guardar"
                            icon="pi pi-save"
                            onClick={() => setConfirmarGrabarHistoriaClinica()}
                            className="p-button-sm p-button-primary p-button-raised"
                        />
                        <Button
                            icon="pi pi-times"
                            onClick={() => link_form_close()}
                            className="p-button-sm p-button-danger p-button-raised"
                        />
                    </span>
                </div>
            </div>

            <ScrollPanel style={{ height: '650px', width: '99%' }} >
                <div className="flex justify-content-center gray-container p-1">
                    <div className='card p-1 mr-1 max-w-full' style={{ minWidth: '15rem' }} >
                        <PacienteInfo PacienteInfo={dataHistoriaClinica} />
                    </div>
                    <div className='card p-1 max-w-full' style={{ minWidth: '500px' }} >
                        <ScrollTop />
                        <div className='scrollpanel-demo p-3'>
                            <ScrollPanel className="custombar1" style={{ width: '100%', height: '600px' }}>
                                <Fieldset legend="DATOS DE FILIACIÓN" toggleable>
                                    <div className="p-field p-grid mb-0">
                                        <div className="p-col pt-0 ">
                                            <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Fecha de filiación:</label>
                                            <Calendar style={{ width: '10rem' }} inputClassName="font-semibold text-primary-800 text-center"
                                                value={vl_FechaAfiliacion}
                                                onChange={(e: any) => setFechaAfiliacion(e.value)} minDate={new Date()}
                                                dateFormat="mm-dd-yy" readOnlyInput
                                            />
                                            <label className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>Hora:</label>

                                            <Calendar value={vl_HoraAfiliacion} style={{ width: '70px' }}
                                                onChange={(e: any) => setHoraAfiliacion(e.value)}
                                                showTime minDate={new Date()} readOnlyInput
                                                dateFormat="hh:mm" timeOnly inputClassName="font-semibold text-primary-800"
                                            />
                                        </div>
                                        <div className="p-field p-grid mb-1">
                                            <div className="p-col pt-1 ">
                                                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Documento:</label>
                                                <Dropdown style={{ width: '10rem' }} className="font-semibold text-primary-800"
                                                    value={vl_IdTipoDocumento}
                                                    options={cmbIdTipoDocumento}
                                                    onChange={(e: any) => setIdTipoDocumento(e.value)}
                                                    optionValue="IdTipoDocumento"
                                                    optionLabel="Codigo"
                                                    placeholder="Seleccionar" />
                                                <label className="p-col-fixed mr-1 ml-1 font-bold text-sm " style={{ textAlign: 'end' }}>Nº:</label>
                                                <InputText type="text" style={{ width: '100px', textAlign: 'center' }}
                                                    value={vl_NumeroDocumento} className=" font-semibold text-primary-800"
                                                    onChange={(e: any) => setNumeroDocumento(String(e.currentTarget.value).toUpperCase())}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Nombres:</label>
                                                <InputText type="text" style={{ width: '22rem' }} className=" font-semibold text-primary-800"
                                                    value={vl_Nombres}
                                                    onChange={(e) => {
                                                        setNombres(String(e.currentTarget.value).toUpperCase());
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1">
                                            <div className="p-col">
                                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '125px', textAlign: 'end' }}>Apellido Paterno:</label>
                                                <InputText type="text" style={{ width: '22rem' }} className=" font-semibold text-primary-800"
                                                    value={vl_ApellidoPaterno}
                                                    placeholder={'Paterno'}
                                                    onChange={(e) => {
                                                        setApellidoPaterno(String(e.currentTarget.value).toUpperCase());
                                                    }
                                                    }
                                                // ref={inputApPaternoRef}
                                                // onKeyPress={(e: any) => {
                                                //     if (e.key == 'Enter') {
                                                //         inputApMaternoRef.current.focus();
                                                //     }
                                                // }}
                                                />
                                            </div>
                                            <div className="p-col mt-1 mb-1">
                                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '125px', textAlign: 'end' }}>Apellido Materno:</label>
                                                <InputText type="text" style={{ width: '22rem' }} className=" font-semibold text-primary-800"
                                                    value={vl_ApellidoMaterno}
                                                    placeholder={'Materno'}
                                                    onChange={(e) => {
                                                        setApellidoMaterno(String(e.currentTarget.value).toUpperCase())
                                                    }
                                                    }
                                                // ref={inputApMaternoRef}
                                                // onKeyPress={(e: any) => {
                                                //     if (e.key == 'Enter') {
                                                //         // inputFechaNacimientoRef.current.focus();
                                                //     }
                                                // }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Fecha Nacimiento:</label>
                                                <Calendar dateFormat="dd-mm-yy" inputClassName=" font-semibold text-primary-800"
                                                    style={{ width: '11rem' }} showIcon maxDate={new Date()} //monthNavigator  yearNavigator yearRange="1930:2050"
                                                    value={vl_FechaNacimiento}
                                                    // ref={inputFechaNacimientoRef}
                                                    onChange={(e: any) => {
                                                        // console.log(e.value);
                                                        setFechaNacimiento(e.value);
                                                    }}
                                                />
                                                <label htmlFor="" className="p-col-fixed mr-1 ml-2 font-bold text-sm" style={{ textAlign: 'end' }}>Edad:</label>
                                                <InputText type="text" style={{ width: '60px', textAlign: 'center' }} readOnly value={vl_Edad} className=" font-semibold text-primary-800" />

                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <label htmlFor="" className="p-col-fixed mr-1 mr-1 font-bold text-sm  " style={{ width: '125px', textAlign: 'end' }}>Sexo:</label>
                                            <RadioButton value="M" name="btn_sexo" style={{ height: '30px' }}
                                                onChange={(e) => setSexo(e.value)}
                                                checked={vl_Sexo === "M"}
                                            />
                                            <label htmlFor="cb1" className="p-checkbox-label ml-1 mr-2 ">Masculino</label>
                                            <RadioButton value="F" name="btn_sexo" style={{ height: '30px' }}
                                                onChange={(e) => setSexo(e.value)}
                                                checked={vl_Sexo === "F"}
                                            />
                                            <label htmlFor="cb2" className="p-checkbox-label ml-1  ">Femenino</label>
                                        </div>

                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className={!b_fieldsetHiddenAA ? 'hidden' : ''} >
                                                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Estado Civil:</label>
                                                <Dropdown style={{ width: '280px' }}
                                                    value={vl_IdEstadoCivil}
                                                    options={cmbIdEstadoCivil}
                                                    onChange={(e: any) => { setIdEstadoCivil(e.value) }}
                                                    optionValue="Codigo"
                                                    optionLabel="Descripcion"
                                                    placeholder="Seleccionar" />
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className={!b_fieldsetHiddenAA ? 'hidden' : ''} >
                                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Grado Instrucción:</label>
                                                <Dropdown style={{ width: '280px' }}
                                                    value={vl_IdGradoInstruccion}
                                                    options={cmbIdGradoInstruccion}
                                                    onChange={(e: any) => { setIdGradoInstruccion(e.value) }}
                                                    optionValue="Codigo"
                                                    optionLabel="Descripcion"
                                                    placeholder="Seleccionar" />

                                            </div>
                                        </div>

                                        <div className="p-field p-grid mt-1 mb-1" >
                                            <div className={!b_fieldsetHiddenAA ? 'hidden' : ''} >
                                                <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Ocupación:</label>
                                                <Dropdown style={{ width: '280px' }} showFilterClear filter filterBy="Descripcion"
                                                    value={vl_IdOcupacion}
                                                    options={cbmIdOcupacion}
                                                    onChange={(e: any) => { setIdOcupacion(e.value) }}
                                                    optionValue="Codigo"
                                                    optionLabel="Descripcion"
                                                    placeholder="Seleccionar o agregar"
                                                    editable />
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 font-bold text-sm" style={{ width: '125px', textAlign: 'end' }}>Lugar de Nacimiento:</label>
                                                <InputText type="text" style={{ width: '18rem', textAlign: 'left' }}
                                                    value={vl_LugarNacimientoDireccion}
                                                    onChange={(e: any) => { setLugarNacimientoDireccion(String(e.currentTarget.value).toUpperCase()) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Lugar de Procedencia:</label>
                                                <InputText type="text" style={{ width: '18rem', textAlign: 'left' }}
                                                    value={vl_LugarProcedenciaDireccion}
                                                    onChange={(e: any) => { setLugarProcedenciaDireccion(String(e.currentTarget.value).toUpperCase()) }}
                                                />
                                            </div>
                                        </div>

                                        <div className="p-field p-grid mt-1 mb-4">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '125px', textAlign: 'end' }}>Religión:</label>
                                                <Dropdown style={{ width: '12rem' }}
                                                    value={vl_IdReligion}
                                                    options={cbmIdReligion}
                                                    onChange={(e: any) => {
                                                        setIdReligion(e.value);
                                                        let itemsfiltered: any = cbmIdReligion.filter(function (element: any) {
                                                            return element.Codigo === e.value;
                                                        });
                                                        setReligion(itemsfiltered[0].Descripcion);
                                                    }}
                                                    optionValue="Codigo"
                                                    optionLabel="Descripcion"
                                                    placeholder="Seleccionar" />

                                                <label className="p-col-fixed ml-1 mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Nº de Hermanos:</label>
                                                <InputText type="text" style={{ width: '7rem', textAlign: 'left' }}
                                                    value={vl_NumeroHermanos}
                                                    onChange={(e: any) => { setNumeroHermanos(String(e.currentTarget.value)) }}
                                                />
                                            </div>
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 mb-1 font-bold text-sm " style={{ textAlign: 'start' }}>¿Con quiénes viven?</label>
                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                    value={vl_ConquienesViven}
                                                    onChange={(e: any) => { setConquienesViven(String(e.currentTarget.value).toUpperCase()) }}
                                                />
                                                <small id="conquienesViven-help">Familia o núcleos de convivencia.</small>
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1 border-top-1 border-gray-500  ">
                                            <div className="p-col mt-1 ">
                                                <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Acompañante:</label>
                                                <InputText type="text" style={{ width: '50%', textAlign: 'left' }}
                                                    value={vl_DatoAcompananteNombres}
                                                    onChange={(e: any) => { setDatoAcompananteNombres(e.currentTarget.value.toUpperCase()) }}
                                                />
                                                <label className="p-col-fixed mr-1 ml-2 font-bold text-sm " style={{ textAlign: 'end' }}>DNI:</label>
                                                <InputText type="text" style={{ width: '100px', textAlign: 'center' }}
                                                    value={vl_DatoAcompananteDni}
                                                    onChange={(e: any) => { setDatoAcompananteDni(e.currentTarget.value) }}
                                                    maxLength={8}
                                                />
                                            </div>
                                        </div>
                                        <TabView className="tabview-custom">
                                            <TabPanel header="Parientes" leftIcon="pi pi-users pr-1">
                                                <div className="p-field p-grid mt-1 mb-1">
                                                    <div className="p-col mt-1 ">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Id:</label>
                                                        <InputText type="text" style={{ width: '60px', textAlign: 'center' }} disabled
                                                            value={vl_IdPariente}
                                                            onChange={(e: any) => { setIdPariente(e.value) }}
                                                        />
                                                        <label className="p-col-fixed mr-1 ml-1 font-bold text-sm " style={{ textAlign: 'end' }}>Parentesco:</label>
                                                        <Dropdown style={{ width: '12rem' }}
                                                            value={vl_IdTipoPariente}
                                                            options={cbmIdTipoPariente}
                                                            onChange={(e: any) => {
                                                                setIdTipoPariente(e.value);
                                                                let itemsfiltered: any = cbmIdTipoPariente.filter(function (element: any) {
                                                                    return element.IdCodigo === e.value;
                                                                });
                                                                setTipoPariente(itemsfiltered[0].Descripcion);
                                                            }}
                                                            optionValue="IdCodigo"
                                                            optionLabel="Descripcion"
                                                            placeholder="Seleccionar" />
                                                    </div>
                                                    <div className="p-col mt-1 w-full ">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Nombres:</label>
                                                        <InputText type="text" style={{ minWidth: '18rem', textAlign: 'left' }}
                                                            value={vl_NombresPariente}
                                                            onChange={(e: any) => {
                                                                setNombresPariente(String(e.currentTarget.value).toUpperCase());
                                                            }}

                                                        />
                                                        <label className="p-col-fixed mr-1 ml-1 font-bold text-sm " style={{ textAlign: 'end' }}>Edad:</label>
                                                        <InputText type="number" style={{ width: '5rem', textAlign: 'center' }}
                                                            value={vl_EdadPariente}
                                                            onChange={(e: any) => { setEdadPariente(String(e.currentTarget.value)) }}
                                                        />

                                                    </div>
                                                    <div className="p-col mt-1 ">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Grado Inst:</label>
                                                        <Dropdown style={{ width: '280px' }}
                                                            value={vl_IdGradoInstruccionPariente}
                                                            options={cmbIdGradoInstruccion}
                                                            onChange={(e: any) => {
                                                                setIdGradoInstruccionPariente(e.value);
                                                                let itemsfiltered: any = cmbIdGradoInstruccion.filter(function (element: any) {
                                                                    return element.Codigo === e.value;
                                                                });
                                                                setGradoInstruccionPariente(itemsfiltered[0].Descripcion);
                                                            }}
                                                            optionValue="Codigo"
                                                            optionLabel="Descripcion"
                                                            placeholder="Seleccionar" />
                                                    </div>
                                                    <div className="p-col mt-1 ">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Ocupacion:</label>
                                                        <InputText type="text" style={{ minWidth: '18rem', textAlign: 'left' }}
                                                            value={vl_OcupacionPariente}
                                                            onChange={(e: any) => {
                                                                setOcupacionPariente(String(e.currentTarget.value).toUpperCase());
                                                                setIdOcupacionPariente(0);
                                                            }} />
                                                        {/* <Dropdown style={{ width: '280px' }} editable showFilterClear filter filterBy="Descripcion"
                                                            value={vl_IdOcupacionPariente}
                                                            options={cbmIdOcupacion}
                                                            onChange={(e: any) => {
                                                                setIdOcupacionPariente(e.value);
                                                                let itemsfiltered: any = cbmIdOcupacion.filter(function (element: any) {
                                                                    return element.Codigo === e.value;
                                                                });
                                                                setOcupacionPariente(itemsfiltered[0]?.Descripcion);
                                                            }}
                                                            optionValue="Codigo"
                                                            optionLabel="Descripcion"
                                                            placeholder="Seleccionar o agregar" /> */}
                                                    </div>
                                                    <div className="p-col mt-1 w-full ">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '80px', textAlign: 'end' }}>Observ.:</label>
                                                        <InputText type="text" style={{ minWidth: '18rem', textAlign: 'left' }}
                                                            value={vl_OcupacionParienteDetalle}
                                                            onChange={(e: any) => {
                                                                setOcupacionParienteDetalle(String(e.currentTarget.value).toUpperCase());
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="p-col mt-3 ">
                                                        <div className="flex justify-content-between">
                                                            <span className="p-buttonset">
                                                                <Button
                                                                    type="button"
                                                                    icon="pi pi-plus"
                                                                    label='Agregar'
                                                                    onClick={(e) => setParienteAgregar()}
                                                                    className="p-button-sm p-button-success p-button-raised"
                                                                />

                                                                <Button
                                                                    icon="pi pi-file"
                                                                    label='Nuevo'
                                                                    onClick={(e) => setParienteNuevo()}
                                                                    className="p-button-sm p-button-success p-button-raised"
                                                                />

                                                            </span>
                                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-0 surface-overlay p-2">Seleccione un registro para modificar!</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="p-col mt-1 ">
                                                        <DataTable size="small"
                                                            resizableColumns
                                                            // style={{ height: '210px' }}
                                                            value={gridDataPariente ?? []}
                                                            dataKey="IdPariente"
                                                            responsiveLayout="scroll"
                                                            columnResizeMode="fit"
                                                            showGridlines
                                                            stripedRows
                                                            scrollable
                                                            scrollHeight="220px"
                                                            selectionMode="single"
                                                            // selection={gridDataEvolucionSelected}
                                                            onSelectionChange={(e) => {
                                                                setIdPariente(e.value.IdPariente);
                                                                setIdTipoPariente(e.value.IdTipoPariente);
                                                                setTipoPariente(e.value.TipoPariente);
                                                                setNombresPariente(e.value.NombresPariente);
                                                                setEdadPariente(e.value.EdadPariente);
                                                                setIdGradoInstruccionPariente(e.value.IdGradoInstruccionPariente);
                                                                setGradoInstruccionPariente(e.value.GradoInstruccionPariente);
                                                                setIdOcupacionPariente(e.value.IdOcupacionPariente);
                                                                setOcupacionPariente(e.value.OcupacionPariente);
                                                                setOcupacionParienteDetalle(e.value.OcupacionParienteDetalle ? e.value.OcupacionParienteDetalle : '');
                                                            }}
                                                        >
                                                            <Column field="IdPariente" header="#ID" style={{ maxWidth: '4rem' }} ></Column>
                                                            <Column field="TipoPariente" header="Parentesco"></Column>
                                                            <Column field="NombresPariente" header="Nombres" bodyClassName="white-space-normal" ></Column>
                                                            <Column field="EdadPariente" header="Edad"></Column>
                                                            <Column field="GradoInstruccionPariente" header="G. Instruc."></Column>
                                                            <Column field="OcupacionPariente" header="Ocupación" bodyClassName="white-space-normal" ></Column>
                                                            <Column body={actionBodyTemplate5} bodyClassName="flex justify-content-center"  ></Column>
                                                        </DataTable>
                                                    </div>

                                                </div>
                                            </TabPanel>
                                            <TabPanel header="Información adicional" className={!b_fieldsetHiddenAM ? 'hidden' : ''} leftIcon="pi pi-user-minus pr-1">
                                                <div className="p-field p-grid mt-1 mb-1">
                                                    <div className="p-col">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '100px', textAlign: 'end' }}>Escolaridad:</label>
                                                        <InputText type="text" style={{ width: '24rem', textAlign: 'left' }}
                                                            value={vl_Escolaridad}
                                                            onChange={(e: any) => { setEscolaridad(String(e.currentTarget.value).toUpperCase()) }}
                                                        />
                                                    </div>
                                                    <div className="p-col mt-1">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '100px', textAlign: 'end' }}>Institución Educativa:</label>
                                                        <InputText type="text" style={{ width: '24rem', textAlign: 'left' }}
                                                            value={vl_InstEducativa}
                                                            onChange={(e: any) => { setInstEducativa(String(e.currentTarget.value).toUpperCase()) }}
                                                        />
                                                    </div>
                                                    <div className="p-col mt-1">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ width: '100px', textAlign: 'end' }}>Referente:</label>
                                                        <InputText type="text" style={{ width: '24rem', textAlign: 'left' }}
                                                            value={vl_Referente}
                                                            onChange={(e: any) => { setReferente(String(e.currentTarget.value).toUpperCase()) }}
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel header="Domicilio" leftIcon="pi pi-map-marker pl-1">

                                                <div className="flex flex-wrap card-container w-full">
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Provincia:</label>
                                                        <Dropdown style={{ width: '10rem' }} filter filterBy="Departamento"
                                                            value={vl_DomicilioActualDepartamento}
                                                            options={cbmDireccionDepartamento}
                                                            onChange={(e: any) => {
                                                                setDomicilioActualDepartamento(e.value);
                                                                pa_get_ubigeo('P', e.value, '');
                                                            }}
                                                            optionValue="DepartamentoInei"
                                                            optionLabel="Departamento"
                                                            placeholder="Seleccionar" />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label className="p-col-fixed mr-1 font-bold " style={{ textAlign: 'end' }}>Distrito:</label>
                                                        <Dropdown style={{ width: '10rem' }} filter filterBy="Provincia"
                                                            value={vl_DomicilioActualProvincia}
                                                            options={cbmDireccionProvincia}
                                                            onChange={(e: any) => {
                                                                console.log(e.value)
                                                                setDomicilioActualProvincia(e.value);
                                                                pa_get_ubigeo('I', '', e.value);
                                                            }}
                                                            optionValue="ProvinciaInei"
                                                            optionLabel="Provincia"
                                                            placeholder="Seleccionar" />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Departamento:</label>
                                                        <Dropdown style={{ width: '10rem' }} filter filterBy="Distrito"
                                                            value={vl_DomicilioActualDistrito}
                                                            options={cbmDireccionDistrito}
                                                            onChange={(e: any) => {
                                                                setDomicilioActualDistrito(e.value);
                                                            }}
                                                            optionValue="UbigeoInei" //UbigeoReniec
                                                            optionLabel="Distrito"
                                                            placeholder="Seleccionar" />
                                                    </div>
                                                </div>

                                                <div className="flex flex-wrap card-container w-full">
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Tipo Vía:</label>
                                                        <Dropdown style={{ width: '12rem' }} filter filterBy="DescripcionVia"
                                                            value={vl_DomicilioActualTipovia}
                                                            options={cbmDireccionTipovia}
                                                            onChange={(e: any) => {
                                                                setDomicilioActualTipovia(e.value);
                                                            }}
                                                            optionValue="CodigoVia"
                                                            optionLabel="DescripcionVia"
                                                            placeholder="Seleccionar" />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label htmlFor="" className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Calle/Vía:</label>
                                                        <InputText type="text" style={{ width: '18rem', textAlign: 'start' }}
                                                            value={vl_DomicilioActualNombrevia}
                                                            onChange={(e) => setDomicilioActualNombrevia(String(e.currentTarget.value).toUpperCase())}
                                                            // ref={inputDireccionNombreviaRef}
                                                            onKeyPress={(e: any) => {
                                                                if (e.key === 'Enter') {
                                                                    // inputDireccionNumeroRef.current.focus();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap card-container w-full">
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm" style={{ textAlign: 'end' }}>Número:</label>
                                                        <InputText type="text" style={{ width: '70px', textAlign: 'start' }}
                                                            value={vl_DomicilioActualNumero}
                                                            onChange={(e) => setDomicilioActualNumero(String(e.currentTarget.value).toUpperCase())}
                                                            // ref={inputDireccionNumeroRef}
                                                            onKeyPress={(e: any) => {
                                                                if (e.key === 'Enter') {
                                                                    // inputDireccionManzanaRef.current.focus();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Mz.:</label>
                                                        <InputText type="text" style={{ width: '70px', textAlign: 'start' }}
                                                            value={vl_DomicilioActualManzana}
                                                            onChange={(e) => setDomicilioActualManzana(String(e.currentTarget.value).toUpperCase())}
                                                            // ref={inputDireccionManzanaRef}
                                                            onKeyPress={(e: any) => {
                                                                if (e.key === 'Enter') {
                                                                    // inputDireccionLoteRef.current.focus();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label className="p-col-fixed mr-1  font-bold text-sm " style={{ textAlign: 'end' }}>Lote:</label>
                                                        <InputText type="text" style={{ width: '70px', textAlign: 'start' }}
                                                            value={vl_DomicilioActualLote}
                                                            onChange={(e) => setDomicilioActualLote(String(e.currentTarget.value).toUpperCase())}
                                                            // ref={inputDireccionLoteRef}
                                                            onKeyPress={(e: any) => {
                                                                if (e.key === 'Enter') {
                                                                    // inputDireccionDptoRef.current.focus();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Dpto:</label>
                                                        <InputText type="text" style={{ width: '70px', textAlign: 'start' }}
                                                            value={vl_DomicilioActualDpto}
                                                            onChange={(e) => setDomicilioActualDpto(String(e.currentTarget.value).toUpperCase())}
                                                            // ref={inputDireccionDptoRef}
                                                            onKeyPress={(e: any) => {
                                                                if (e.key === 'Enter') {
                                                                    // inputDireccionUrbRef.current.focus();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap card-container w-full">
                                                    <div className="flex align-items-center justify-content-center m-1">
                                                        <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'end' }}>Urbanización:</label>
                                                        <InputText type="text" style={{ width: '18rem', textAlign: 'start' }}
                                                            value={vl_DomicilioActualUrbanizacion}
                                                            onChange={(e) => setDomicilioActualUrbanizacion(String(e.currentTarget.value).toUpperCase())}
                                                            // ref={inputDireccionUrbRef}
                                                            onKeyPress={(e: any) => {
                                                                if (e.key === 'Enter') {
                                                                    //inputDireccionUrbRef.current.focus();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </TabView>
                                    </div>
                                </Fieldset>
                                <Accordion multiple
                                    onTabChange={(e) => {
                                        // console.log(e.index[1]);
                                        setActiveIndex_1(e.index)
                                    }}
                                    activeIndex={[vl_activeIndex]}
                                >
                                    <AccordionTab header="MOTIVO DE CONSULTA">
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label htmlFor="" className="p-col-fixed mr-1 mb-2 font-bold " style={{ textAlign: 'end' }}>Motivo actual:</label>
                                                <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                    <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">¿Qué pasó? ¿Cuáles son las conductas disfuncionales? ¿Desde cuándo? ¿Qué hizo al respecto? </p>
                                                </div>
                                                <Editor style={{ height: '220px' }}
                                                    headerTemplate={header01}
                                                    value={vl_MotivoConsultaTxt_a}
                                                    onTextChange={(e) => setMotivoConsultaTxt_a(String(e.htmlValue ? e.htmlValue : ''))}
                                                />
                                            </div>

                                            <div className="p-col pt-3 ">
                                                <label className="p-col-fixed mr-1 mb-2 font-bold " style={{ textAlign: 'end' }}>Desarrollo cronológico de los síntomas:</label>
                                                <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                    <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">¿Desde cuándo presenta las conductas?</p>
                                                </div>
                                                <Editor style={{ height: '200px' }}
                                                    headerTemplate={header01}
                                                    value={vl_MotivoConsultaTxt_b}
                                                    onTextChange={(e) => setMotivoConsultaTxt_b(String(e.htmlValue ? e.htmlValue : ''))}
                                                />
                                            </div>
                                            <div className="p-field p-grid pt-3 mb-1">
                                                <div className="p-col mb-3">
                                                    <label htmlFor="" className="p-col-fixed mr-1 mb-2 font-bold " style={{ textAlign: 'end' }}>Signos y síntomas:</label>
                                                    <Editor style={{ height: '220px' }}
                                                        headerTemplate={header01}
                                                        value={vl_MotivoConsultaTxt_c}
                                                        onTextChange={(e) => setMotivoConsultaTxt_c(String(e.htmlValue ? e.htmlValue : ''))}
                                                    />
                                                </div>

                                                <div className="p-col mb-3">
                                                    <label htmlFor="" className="p-col-fixed mr-1 mb-2 font-bold " style={{ textAlign: 'end' }}>Observación de conducta:</label>
                                                </div>
                                                <div className="p-col mb-1" >
                                                    <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'start' }}>Porte:</label>
                                                    <div className="flex flex-wrap align-items-center justify-content-start pb-2 pt-2">
                                                        <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">
                                                            Tez, vestimenta, estado de hidratación, higiene y nutrición, aliño personal, descripción de vestimenta y señas relevantes, talla y peso.
                                                        </p>
                                                    </div>
                                                    <Editor style={{ height: '180px' }}
                                                        headerTemplate={header01}
                                                        value={vl_Porte}
                                                        onTextChange={(e: any) => {
                                                            // console.log(e);
                                                            setPorte(e.htmlValue ? e.htmlValue : '' ? e.htmlValue : '');
                                                        }}
                                                    />
                                                </div>
                                                <div className="p-col mb-1" >
                                                    <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'start' }}>Comportamiento:</label>
                                                    <div className="flex flex-wrap align-items-center justify-content-start pb-2 pt-2">
                                                        <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">
                                                            Marcha, posición, mirada, movimientos, emoción, ademanes, lenguaje, memoria, pensamiento.
                                                        </p>
                                                    </div>
                                                    <Editor style={{ height: '180px' }}
                                                        headerTemplate={header01}
                                                        value={vl_Comportamiento}
                                                        onTextChange={(e) => setComportamiento(String(e.htmlValue ? e.htmlValue : ''))}
                                                    />
                                                </div>
                                                <div className="p-col mb-1" >
                                                    <label className="p-col-fixed mr-1 font-bold text-sm " style={{ textAlign: 'start' }}>Actitud:</label>
                                                    <div className="flex flex-wrap align-items-center justify-content-start pb-2 pt-2">
                                                        <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">
                                                            Colaborador, reticente, negativo, participativo, desconfiado.
                                                        </p>
                                                    </div>
                                                    <Editor style={{ height: '180px' }}
                                                        headerTemplate={header01}
                                                        value={vl_Actitud}
                                                        onTextChange={(e) => setActitud(String(e.htmlValue ? e.htmlValue : ''))}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header="ANALISIS F. CONDUCTA">
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col w-full">
                                                <label htmlFor="" className="p-col-fixed mr-1 mb-2 font-bold " style={{ textAlign: 'start' }}>Antecedentes:</label>
                                                <i className="custom-tooltip-btn pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-position="right"
                                                    data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                                </i>
                                                <InputTextarea rows={4} cols={30} style={{ width: '99%' }}
                                                    value={vl_AFuncionalAntecedente}
                                                    onChange={(e: any) => setAFuncionalAntecedente(e.target.value)}
                                                // ref={inputObservacionesRef}                                                        
                                                />
                                                <div className="p-col">
                                                    <Tooltip target=".custom-tooltip-btn">
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">Ed interno</p>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <ul className="list-disc">
                                                                <li>Habitos de vida poco saludable</li>
                                                                <li>Obecidad infantil</li>
                                                                <li>Fracaso en perder peso</li>
                                                                <li>Estres</li>
                                                                <li>Aburrimiento</li>
                                                                <li>Frustración</li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">Ed externo</p>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <ul className="list-disc">
                                                                <li>Burlas sobre su peso de familiares</li>
                                                                <li>Problemas familiares</li>
                                                            </ul>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>

                                            <div className="p-col w-full">
                                                <label htmlFor="" className="p-col-fixed mr-1 mb-2 font-bold " style={{ textAlign: 'start' }}>Conducta:</label>
                                                <i className="custom-tooltip-btn-2 pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-position="right"
                                                    data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                                </i>
                                                <InputTextarea rows={4} cols={30}
                                                    style={{ width: '99%' }}
                                                    value={vl_AFuncionalConducta}
                                                    onChange={(e: any) => setAFuncionalConducta(e.target.value)}
                                                // ref={inputObservacionesRef}
                                                />
                                                <div className="p-col">
                                                    <Tooltip target=".custom-tooltip-btn-2">
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">Emocional</p>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <ul className="list-disc">
                                                                <li>Ansiedad.</li>
                                                                <li>Sintomas depresivos</li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">Fisiologico</p>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <ul className="list-disc">
                                                                <li>Heridad cutáneas leves</li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">Cognitivo</p>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <ul className="list-disc">
                                                                <li>Perfeccionismo</li>
                                                                <li>Pensamientos irracionales</li>
                                                                <li>Deficit de autoestima</li>
                                                                <li>Ausencia de motiviación</li>
                                                            </ul>
                                                        </div>

                                                    </Tooltip>
                                                </div>
                                            </div>


                                            <div className="p-col w-full">
                                                <label htmlFor="" className="p-col-fixed mr-1 mb-2 font-bold " style={{ textAlign: 'start' }}>Consecuencia:</label>
                                                <i className="custom-tooltip-btn-3 pi pi-info-circle p-text-secondary p-overlay-badge"
                                                    data-pr-position="right"
                                                    data-pr-at="right+5 top"
                                                    data-pr-my="left center-2" style={{ fontSize: '2rem', cursor: 'pointer' }}>
                                                </i>
                                                <InputTextarea rows={4} cols={30}
                                                    style={{ width: '99%' }}
                                                    value={vl_AFuncionalConsecuencia}
                                                    onChange={(e: any) => setAFuncionalConsecuencia(e.target.value)}
                                                // ref={inputObservacionesRef}
                                                />
                                                <div className="p-col">
                                                    <Tooltip target=".custom-tooltip-btn-3">
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">Ec interno</p>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <ul className="list-disc">
                                                                <li>Reduccion de la ansiedad</li>
                                                                <li>Regularizacion de sus emociones negativas</li>
                                                            </ul>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">Ec externo</p>
                                                        </div>
                                                        <div className="flex flex-wrap align-items-center justify-content-start pb-1">
                                                            <ul className="list-disc">
                                                                <li>Aislamiento social</li>
                                                                <li>Conflictos similares</li>
                                                                <li>No hay una supervision al momento que ella come</li>
                                                            </ul>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className="p-col mt-3 ">
                                                <div className="flex justify-content-between">
                                                    <span className="p-buttonset">
                                                        <Button
                                                            type="button"
                                                            icon="pi pi-plus"
                                                            tooltip='Agregar'
                                                            onClick={(e) => setAFuncionalConductaAgregar()}
                                                            className="p-button-sm p-button-success p-button-raised"
                                                        />
                                                        <Button
                                                            icon="pi pi-file"
                                                            tooltip='Nuevo registro'
                                                            onClick={(e) => setAFuncionalConductaNuevo()}
                                                            className="p-button-sm p-button-success p-button-raised"
                                                        />
                                                    </span>
                                                    <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                        <p className="font-italic text-indigo-600 border-round border-0 surface-overlay p-2">Seleccione un registro para modificar!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-col w-full">
                                                <DataTable size="small"
                                                    resizableColumns
                                                    style={{ height: '210px' }}
                                                    value={gridDataAFuncionalConducta ?? []}
                                                    dataKey="IdPariente"
                                                    responsiveLayout="scroll"
                                                    columnResizeMode="fit"
                                                    showGridlines
                                                    stripedRows
                                                    scrollable
                                                    scrollHeight="220px"
                                                    selectionMode="single"
                                                    // selection={gridDataEvolucionSelected}
                                                    onSelectionChange={(e) => {
                                                        setAFuncionalId(e.value.AFuncionalId);
                                                        setAFuncionalAntecedente(e.value.AFuncionalAntecedente);
                                                        setAFuncionalConducta(e.value.AFuncionalConducta);
                                                        setAFuncionalConsecuencia(e.value.AFuncionalConsecuencia);
                                                    }}
                                                >
                                                    <Column field="AFuncionalId" header="Nº" style={{ maxWidth: '60px' }}  ></Column>
                                                    <Column field="AFuncionalAntecedente" header="Antecentes"    ></Column>
                                                    <Column field="AFuncionalConducta" header="Conducta"></Column>
                                                    <Column field="AFuncionalConsecuencia" header="Consecuencia"  ></Column>
                                                    <Column field="AFuncionalId" body={actionBodyTemplate6}  ></Column>
                                                </DataTable>
                                            </div>

                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header="EXAMEN FÍSICO">
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>F. Respiratoria:</label>
                                                <InputText type="text" style={{ width: '10%', textAlign: 'center' }}
                                                    value={vl_ExamenFisicoFRespiratoria}
                                                    onChange={(e: any) => {
                                                        setExamenFisicoFRespiratoria(String(e.currentTarget.value))
                                                    }}
                                                />
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>F. Cardiaca:</label>
                                                <InputText type="text" style={{ width: '10%', textAlign: 'center' }}
                                                    value={vl_ExamenFisicoFCardiaca}
                                                    onChange={(e: any) => {
                                                        setExamenFisicoFCardiaca(String(e.currentTarget.value))
                                                    }}
                                                />
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>P. Arterial:</label>
                                                <InputText type="text" style={{ width: '10%', textAlign: 'center' }}
                                                    value={vl_ExamenFisicoPArterial}
                                                    onChange={(e: any) => {
                                                        setExamenFisicoPArterial(String(e.currentTarget.value))
                                                    }}
                                                />
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>Temperatura:</label>
                                                <InputText type="text" aria-label='fffff' style={{ width: '8%', textAlign: 'center' }}
                                                    value={vl_ExamenFisicoTemperatura}
                                                    onChange={(e: any) => {
                                                        setExamenFisicoTemperatura(String(e.currentTarget.value))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ width: '82px', textAlign: 'end' }}>Peso:</label>
                                                <InputText type="number" style={{ width: '10%', textAlign: 'center' }}
                                                    value={vl_ExamenFisicoPeso}
                                                    onChange={(e: any) => {
                                                        setExamenFisicoPeso(String(e.currentTarget.value))
                                                    }}
                                                />
                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ width: '78px', textAlign: 'end' }}>Talla:</label>
                                                <InputText type="number" style={{ width: '10%', textAlign: 'center' }}
                                                    value={vl_ExamenFisicoTalla}
                                                    onChange={(e: any) => {
                                                        setExamenFisicoTalla(String(e.currentTarget.value))
                                                    }}
                                                />
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ width: '62px', textAlign: 'end' }}>IMC:</label>
                                                <InputText type="text" style={{ width: '10%', textAlign: 'center' }}
                                                    value={vl_ExamenFisicoIMC}
                                                    onChange={(e: any) => {
                                                        setExamenFisicoIMC(String(e.currentTarget.value))
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-3">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ width: '238px', textAlign: 'end' }}>Escala numérica de malestar:</label>
                                                <InputText type="text" style={{ width: '7em', textAlign: 'center' }} className='border-cyan-500 mb-2'
                                                    value={vl_ExamenFisicoEscalaNumericaMalestar[0] + '/' + vl_ExamenFisicoEscalaNumericaMalestar[1]} readOnly
                                                    onChange={(e: any) => {
                                                        setExamenFisicoEscalaNumericaMalestar(String(e.currentTarget.value))
                                                    }}
                                                />
                                                <Slider value={vl_ExamenFisicoEscalaNumericaMalestar} style={{ width: '70%' }}
                                                    onChange={(e) => setExamenFisicoEscalaNumericaMalestar(e.value)} range />
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ width: '85px', textAlign: 'end' }}>Otros:</label>
                                                <InputText type="text" style={{ width: '80%', textAlign: 'left' }}
                                                    value={vl_ExamenFisicoOtros}
                                                    onChange={(e: any) => {
                                                        setExamenFisicoOtros(String(e.currentTarget.value))
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <div className="surface-600 font-bold text-white pt-1 pb-1 pl-1 pr-1 ">¿Paciente pertenece a uno de lo siguientes grupos con necesidades especiales?</div>
                                                <SelectButton className='pt-1' value={vl_ExamenFisicoPNecesidadesEspeciales} options={['SI', 'NO']}
                                                    onChange={(e) => setExamenFisicoPNecesidadesEspeciales(e.value)} />

                                            </div>
                                        </div>
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNENino(e.checked)} checked={vl_ExamenFisicoPNENino}> </Checkbox> - Niño
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNEAdolescente(e.checked)} checked={vl_ExamenFisicoPNEAdolescente}> </Checkbox> - Adolescente
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNEAnciano(e.checked)} checked={vl_ExamenFisicoPNEAnciano}> </Checkbox> - Anciano
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNEEnfermedadInfecciosasContagiosas(e.checked)} checked={vl_ExamenFisicoPNEEnfermedadInfecciosasContagiosas}> </Checkbox> - Pacientes con enfermedad infecciosas o contagiosas
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNEEnfermedadTerminal(e.checked)} checked={vl_ExamenFisicoPNEEnfermedadTerminal}> </Checkbox> - Pacientes con enfermedad terminal o próximo a la muerte
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNEFaseFinalEmbarazo(e.checked)} checked={vl_ExamenFisicoPNEFaseFinalEmbarazo}> </Checkbox> - Mujeres en la fase final del embarazo
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNEDependenciaDrogas(e.checked)} checked={vl_ExamenFisicoPNEDependenciaDrogas}> </Checkbox> - Paciente con presunta dependencia a drogas
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNESistemaInmunologicoAfectado(e.checked)} checked={vl_ExamenFisicoPNESistemaInmunologicoAfectado}> </Checkbox> - Pacientes cuyo sistema inmunológico está afectado
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }} >
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNEVictimasAbusoAbandono(e.checked)} checked={vl_ExamenFisicoPNEVictimasAbusoAbandono}> </Checkbox> - Víctimas de abuso o abandono
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNEQuimioterapiaRadioterapia(e.checked)} checked={vl_ExamenFisicoPNEQuimioterapiaRadioterapia}> </Checkbox> - Pacientes en quimioterapia o radioterapia
                                                </label>
                                                <label className="p-checkbox-label pl-1" style={{ width: '100%' }}>
                                                    <Checkbox className="ml-3" onChange={e => setExamenFisicoPNETrastornosEmocionalesPsiquiatricos(e.checked)} checked={vl_ExamenFisicoPNETrastornosEmocionalesPsiquiatricos}> </Checkbox> - Paciente con trastornos emocionales o psiquiatricos
                                                </label>
                                            </div>
                                        </div>

                                    </AccordionTab>
                                    <AccordionTab header="ANAMNESIS">
                                        <Fieldset legend="Adultos" className={!b_fieldsetHiddenAA ? 'hidden' : ''} >
                                            <Accordion multiple activeIndex={[0]}>
                                                <AccordionTab header="PRENATAL">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>¿Cómo fue el embarazo o gestación durante (condiciones) de su madre? Síntomas, problemas, duración.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPrenatal_qa}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAAPrenatal_qa(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <div className="flex justify-content-between ">
                                                                <div>
                                                                    <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Fue planificado?</label>
                                                                    <SelectButton className='pt-1' value={vl_AAPrenatal_qb} options={['SI', 'NO']}
                                                                        onChange={(e) => setAAPrenatal_qb(e.value)} />
                                                                    <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Fue deseado?</label>
                                                                    <SelectButton className='pt-1' value={vl_AAPrenatal_qc} options={['SI', 'NO']}
                                                                        onChange={(e) => setAAPrenatal_qc(e.value)} />
                                                                </div>
                                                                <div>
                                                                    <label className="p-col-fixed pb-1 text-sm font-bold " style={{ textAlign: 'end' }}>Tipo de control:</label>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAAPrenatalTipoControlM(e.checked)} checked={vl_AAPrenatalTipoControlM}> </Checkbox>
                                                                        <label className="p-checkbox-label">Médico</label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAAPrenatalTipoControlP(e.checked)} checked={vl_AAPrenatalTipoControlP}> </Checkbox>
                                                                        <label className="p-checkbox-label">Partera</label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAAPrenatalTipoControlN(e.checked)} checked={vl_AAPrenatalTipoControlN}> </Checkbox>
                                                                        <label className="p-checkbox-label">No asistió a controles</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Enfermedades durante el embarazo de su mamá, dificultades y/o accidentes, ingesta de medicamentos. Rayos X.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPrenatal_qd}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAAPrenatal_qd(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Su mamá consumía alcohol, tabaco, drogas y/o anticonceptivos:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPrenatal_qe}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAAPrenatal_qe(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="PERINATAL">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-font " style={{ textAlign: 'start' }}>¿Cuánto duro el embarazo?</label>
                                                            <InputText type="text" style={{ width: '10rem', textAlign: 'left' }}
                                                                value={vl_AAPerinatalTxtTNacio}
                                                                onChange={(e: any) => {
                                                                    setAAPerinatalTxtTNacio(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="formgroup-inline">
                                                            <label className="p-col-fixed pr-2 text-sm font-bold " style={{ textAlign: 'start' }}>¿Dónde nació?</label>
                                                            <div className="field-radiobutton">
                                                                <RadioButton value="H" onChange={e => setAAPerinatalDondeNacio(e.value)} checked={vl_AAPerinatalDondeNacio === "H"}> </RadioButton>
                                                                <label className="p-checkbox-label">Hospital</label>
                                                                <RadioButton value="P" onChange={e => setAAPerinatalDondeNacio(e.value)} checked={vl_AAPerinatalDondeNacio === "P"}> </RadioButton>
                                                                <label className="p-checkbox-label">Partera</label>
                                                                {/* <RadioButton value="O" onChange={e => setAAPerinatalDondeNacio(e.value)} checked={vl_AAPerinatalDondeNacio === "O"}> </RadioButton> */}
                                                                <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Otros</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AAPerinatalDondeNacioOtros}
                                                                    onChange={
                                                                        (e: any) => {
                                                                            setAAPerinatalDondeNacioOtros(String(e.currentTarget.value));
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="formgroup-inline">
                                                            <label className="p-col-fixed pr-2 text-sm font-bold " style={{ textAlign: 'start' }}>Tipo de parto</label>
                                                            <div className="field-radiobutton">
                                                                <RadioButton value="N" onChange={e => setAAPerinatalTipoParto(e.value)} checked={vl_AAPerinatalTipoParto === "N"}> </RadioButton>
                                                                <label className="p-checkbox-label">Normal</label>
                                                                <RadioButton value="C" onChange={e => setAAPerinatalTipoParto(e.value)} checked={vl_AAPerinatalTipoParto === "C"}> </RadioButton>
                                                                <label className="p-checkbox-label">Cesárea </label>
                                                                <RadioButton value="D" onChange={e => setAAPerinatalTipoParto(e.value)} checked={vl_AAPerinatalTipoParto === "D"}> </RadioButton>
                                                                <label className="p-checkbox-label">Con desgarramiento</label>
                                                            </div>
                                                            <div className="field-radiobutton">
                                                                <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Inducido. ¿Por qué?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AAPerinatalTipoPartoInducido}
                                                                    onChange={
                                                                        (e: any) => {
                                                                            setAAPerinatalTipoPartoInducido(String(e.currentTarget.value));
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Presentación del recién nacido (Peso y altura):</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPerinatalPRNPesoAltura}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAAPerinatalPRNPesoAltura(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Lloraste al nacer, coloración ¿necesitó reanimación con oxígeno o incubadora:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPerinatalTxtLloroNacer}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAAPerinatalTxtLloroNacer(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mb-1 mr-2 text-sm font-normal " style={{ textAlign: 'start' }}>¿Por cuánto tiempo?</label>
                                                            <InputText type="text" style={{ width: '12rem', textAlign: 'left' }}
                                                                value={vl_AAPerinatalTxtLloroNacerTiempo}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAAPerinatalTxtLloroNacerTiempo(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <div className="p-col pt-1">
                                                                <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Edades de los padres, al momento de nacer el/la niño/a.</label>
                                                            </div>
                                                            <div className="p-col pt-1">
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'start' }}>Papá</label>
                                                                <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                    value={vl_AAPerinatalEdadP}
                                                                    onChange={(e: any) => {
                                                                        setAAPerinatalEdadP(String(e.currentTarget.value));
                                                                    }
                                                                    }
                                                                />
                                                                <label className="p-col-fixed mr-1 ml-1 text-sm font-normal " style={{ textAlign: 'start' }}>Mamá</label>
                                                                <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                    value={vl_AAPerinatalEdadM}
                                                                    onChange={(e: any) => {
                                                                        setAAPerinatalEdadM(String(e.currentTarget.value));
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* --- desde conducta  */}
                                                        <div className="p-col pb-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Motricidad gruesa:</label>
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>¿A qué edad caminó?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAConductaQ7Txt}
                                                                onChange={(e: any) => {
                                                                    setAAConductaQ7Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿A qué edad dio el destete?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAConductaQ8Txt}
                                                                onChange={(e: any) => {
                                                                    setAAConductaQ8Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿A qué edad controló sus esfínteres?:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAConductaQ9Txt}
                                                                onChange={(e: any) => {
                                                                    setAAConductaQ9Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        {/* ---- */}
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="POSTNATAL">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="flex justify-content-between">
                                                            <div>
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Malformaciones?</label>
                                                                <SelectButton className='pt-1' value={vl_AAPosnatalQa} options={['SI', 'NO']}
                                                                    onChange={(e) => setAAPosnatalQa(e.value)} />
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Dificultades en la succión?</label>
                                                                <SelectButton className='pt-1' value={vl_AAPosnatalQb} options={['SI', 'NO']}
                                                                    onChange={(e) => setAAPosnatalQb(e.value)} />
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Dificultades después del parto?</label>
                                                                <SelectButton className='pt-1' value={vl_AAPosnatalQc} options={['SI', 'NO']}
                                                                    onChange={(e) => setAAPosnatalQc(e.value)} />
                                                            </div>
                                                            <div>
                                                                <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>¿Cuáles?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AAPosnatalQdRespTxt}
                                                                    onChange={
                                                                        (e: any) => {
                                                                            setAAPosnatalQdRespTxt(String(e.currentTarget.value));
                                                                        }
                                                                    }
                                                                />
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Lactancia materna?</label>
                                                                <SelectButton className='pt-1' value={vl_AAPosnatalQe} options={['SI', 'NO']}
                                                                    onChange={(e) => setAAPosnatalQe(e.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="HISTORIA MÉDICA">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-semibold " style={{ textAlign: 'end' }}>¿Padecio de alguna enfermedad?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaMedicaTxta}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaMedicaTxta(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-semibold " style={{ textAlign: 'end' }}>Principales enfermedades. Medicamentos consumidos:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaMedicaTxtb}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaMedicaTxtb(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-semibold" style={{ textAlign: 'end' }}>Ha sufrido accidentes, golpes en la cabeza con pérdida de conocimiento:</label>
                                                            <SelectButton className='pt-1' value={vl_AAHistoriaMedicaQc} options={['SI', 'NO']} onChange={(e) => setAAHistoriaMedicaQc(e.value)} />
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAAHistoriaMedicaConvulsion(e.checked)} checked={vl_AAHistoriaMedicaConvulsion}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2">Convulsiones </label>
                                                                <Checkbox onChange={e => setAAHistoriaMedicaMareos(e.checked)} checked={vl_AAHistoriaMedicaMareos}> </Checkbox>
                                                                <label className="p-checkbox-label">Mareos  </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 text-sm font-semibold " style={{ textAlign: 'end' }}>¿Qué edad tenía?</label>
                                                            <InputText type="text" style={{ width: '30%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaMedicaEdadTenia}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaMedicaEdadTenia(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-semibold " style={{ textAlign: 'end' }}>¿Cómo fue atendido?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaMedicaComoFueAtendido}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaMedicaComoFueAtendido(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="flex justify-content-between pb-2 ">
                                                            <div>
                                                                <label className="p-col-fixed mr-1 text-sm font-semibold " style={{ textAlign: 'end' }}>¿Operaciones?</label>
                                                                <SelectButton className='pt-1' value={vl_AAHistoriaMedicaOperaciones} options={['SI', 'NO']} onChange={(e) => setAAHistoriaMedicaOperaciones(e.value)} />
                                                            </div>
                                                            <div>
                                                                <label className="p-col-fixed mr-1 text-sm font-semibold " style={{ textAlign: 'end' }}>¿En qué edades? ¿Cuáles? ¿Por qué? </label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AAHistoriaMedicaOperacionesTxt}
                                                                    onChange={(e: any) => {
                                                                        setAAHistoriaMedicaOperacionesTxt(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 text-sm font-semibold " style={{ textAlign: 'end' }}> Exámenes realizados: </label>
                                                            <div className="field-checkbox pt-2">
                                                                <Checkbox onChange={e => setAAHistoriaMedicaExamenRealidadoN(e.checked)} checked={vl_AAHistoriaMedicaExamenRealidadoN}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2 ">Neurológico </label>
                                                                <Checkbox onChange={e => setAAHistoriaMedicaExamenRealidadoA(e.checked)} checked={vl_AAHistoriaMedicaExamenRealidadoA}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2">Audiológico  </label>
                                                                <Checkbox onChange={e => setAAHistoriaMedicaExamenRealidadoP(e.checked)} checked={vl_AAHistoriaMedicaExamenRealidadoP}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2">Psiquiátrico  </label>
                                                                <Checkbox onChange={e => setAAHistoriaMedicaExamenRealidadoPsi(e.checked)} checked={vl_AAHistoriaMedicaExamenRealidadoPsi}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2">Psicológico   </label>
                                                                <Checkbox onChange={e => setAAHistoriaMedicaExamenRealidadoOtros(e.checked)} checked={vl_AAHistoriaMedicaExamenRealidadoOtros}> </Checkbox>
                                                                <label className="p-checkbox-label">Otros   </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 text-sm font-semibold " style={{ textAlign: 'end' }}> Resultados: </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaMedicaExamenRealidadoTxt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaMedicaExamenRealidadoTxt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 text-sm font-semibold" style={{ textAlign: 'end' }}> Dominancia lateral manual : </label>
                                                            <SelectButton className='pt-1' value={vl_AAHistoriaMedicaDomLatManual} options={['IZQUIERDA', 'DERECHA']} onChange={(e) => setAAHistoriaMedicaDomLatManual(e.value)} />
                                                        </div>

                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 text-sm font-semibold " style={{ textAlign: 'end' }}> ¿Actualmente que enfermedad padece? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaMedicaEnfermedadActualTxt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaMedicaEnfermedadActualTxt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 text-sm font-semibold " style={{ textAlign: 'end' }}> ¿Que tratamientos recibe? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaMedicaEnfermedadActualTratamientoTxt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaMedicaEnfermedadActualTratamientoTxt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="HABILIDAD PARA HABLAR">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-semibold " style={{ textAlign: 'start' }}>¿A qué edad empezó a hablar?</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AAHabilidadHablaQ1}
                                                                onChange={(e: any) => {
                                                                    setAAHabilidadHablaQ1(String(e.currentTarget.value));
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-semibold " style={{ textAlign: 'start' }}>¿Llevo algun tratamiento para el habla? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHabilidadHablaQ2}
                                                                onChange={(e: any) => {
                                                                    setAAHabilidadHablaQ2(String(e.currentTarget.value));
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-semibold " style={{ textAlign: 'start' }}>Dificultades para pronunciar  </label>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAAHabilidadHablaOmision(e.checked)} checked={vl_AAHabilidadHablaOmision}> </Checkbox>
                                                                <label className="p-checkbox-label">Omisión </label>
                                                            </div>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAAHabilidadHablaSustitucion(e.checked)} checked={vl_AAHabilidadHablaSustitucion}> </Checkbox>
                                                                <label className="p-checkbox-label">Sustitución </label>
                                                            </div>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAAHabilidadHablaDistorsion(e.checked)} checked={vl_AAHabilidadHablaDistorsion}> </Checkbox>
                                                                <label className="p-checkbox-label">Distorsión de fonemas  </label>
                                                            </div>
                                                        </div>

                                                        {/* <div className="p-col pt-1">
                                                                    <div className='flex justify-content-between align-items-center ' >
                                                                        <div>
                                                                            <div className='h-3rem' >
                                                                                <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿La sonrisa tiene valor comunicativo?</label>
                                                                            </div>
                                                                            <div className='h-3rem'>
                                                                                <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿La expresión facial?</label>
                                                                            </div>
                                                                            <div className='h-2rem'>
                                                                                <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Responde cuando se le habla?</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-5' >
                                                                            <SelectButton className='pt-1' value={vl_AAHabilidadHablaQ3} options={['SI', 'NO']} onChange={(e) => setAAHabilidadHablaQ3(e.value)} />
                                                                            <SelectButton className='pt-1' value={vl_AAHabilidadHablaQ4} options={['SI', 'NO']} onChange={(e) => setAAHabilidadHablaQ4(e.value)} />
                                                                            <SelectButton className='pt-1' value={vl_AAHabilidadHablaQ5} options={['SI', 'NO']} onChange={(e) => setAAHabilidadHablaQ5(e.value)} />
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                        <div className="p-col pt-1">
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAAHabilidadHablaCk1(e.checked)} checked={vl_AAHabilidadHablaCk1}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2">Habla demasiado </label>
                                                                <Checkbox onChange={e => setAAHabilidadHablaCk2(e.checked)} checked={vl_AAHabilidadHablaCk2}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2">Rápido  </label>
                                                                <Checkbox onChange={e => setAAHabilidadHablaCk3(e.checked)} checked={vl_AAHabilidadHablaCk3}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2">Lento  </label>
                                                                <Checkbox onChange={e => setAAHabilidadHablaCk4(e.checked)} checked={vl_AAHabilidadHablaCk4}> </Checkbox>
                                                                <label className="p-checkbox-label pr-2">Normal  </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="ALIMENTACIÓN/HIGIENE">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <div className='flex justify-content-between align-items-center'>
                                                                <div>
                                                                    <div className='h-3rem' >
                                                                        <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Recibió lactancia?</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-9' >
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAAAlimentacionMaterno(e.checked)} checked={vl_AAAlimentacionMaterno}> </Checkbox>
                                                                        <label className="p-checkbox-label">Materno  </label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAAAlimentacionArtificial(e.checked)} checked={vl_AAAlimentacionArtificial}> </Checkbox>
                                                                        <label className="p-checkbox-label">Artificial   </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿A qué edad se dio el destete?</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AAAlimentacionEdadDestete}
                                                                onChange={(e: any) => {
                                                                    setAAAlimentacionEdadDestete(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}> ¿Has tenido problemas de apetito en la niñez? </label>
                                                            <SelectButton className='pt-1' value={vl_AAAlimentacionProblemaApetito} options={['SI', 'NO']} onChange={(e) => setAAAlimentacionProblemaApetito(e.value)} />

                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>Si es Sí, describa: </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAlimentacionProblemaApetitoDecripcion}
                                                                onChange={(e: any) => {
                                                                    setAAAlimentacionProblemaApetitoDecripcion(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        {/* <div className="p-col pt-1">
                                                                    <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}> ¿Cuántas comidas recibe al día?  </label>
                                                                    <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                        value={vl_AAAlimentacionQtyComidasAlDia}
                                                                        onChange={(e: any) => {
                                                                            setAAAlimentacionQtyComidasAlDia(String(e.currentTarget.value));
                                                                        }}
                                                                    />
                                                                </div> */}
                                                        <div className="p-col pt-1 pb-2">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Usualmente cómo es su dieta?   </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAlimentacionAboutDieta}
                                                                onChange={(e: any) => {
                                                                    setAAAlimentacionAboutDieta(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Por qué?   </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAlimentacionAboutDietaWhy}
                                                                onChange={(e: any) => {
                                                                    setAAAlimentacionAboutDietaWhy(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="bg-indigo-500 font-bold text-white pt-2 pb-1 pl-1 pr-1 ">HIGIENE</div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿A qué edad comenzaste a controlar la orina? Control de esfínteres:</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Diurna:</label>
                                                            <InputText type="text" style={{ width: '10rem', textAlign: 'left' }}
                                                                value={vl_AAHigieneQ1}
                                                                onChange={(e: any) => {
                                                                    setAAHigieneQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Nocturna:</label>
                                                            <InputText type="text" style={{ width: '10rem', textAlign: 'left' }}
                                                                value={vl_AAHigieneQ2}
                                                                onChange={(e: any) => {
                                                                    setAAHigieneQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="SUEÑO" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Horas de sueño aproximado en la niñez y adolescencia</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AASuenioQ1}
                                                                onChange={(e: any) => {
                                                                    setAASuenioQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Temores nocturnos</label>
                                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">Episodios de gritos, miedo intenso y agitación durante el sueño; a menudo se combina con el sonambulismo</p>
                                                            </div>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AASuenioQ2}
                                                                onChange={(e: any) => {
                                                                    setAASuenioQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <div className='flex justify-content-between align-items-center'>
                                                                <div>
                                                                    <div className='h-3rem' >
                                                                        <label className="p-col mr-1 mb-1 text-sm font-bold " style={{ width: '100%', textAlign: 'left' }}>¿Sabes si durante tu sueño presentabas estas conductas?</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-8' >
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAASuenioQ3Habla(e.checked)} checked={vl_AASuenioQ3Habla}> </Checkbox>
                                                                        <label className="p-checkbox-label">Hablar</label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAASuenioQ3Grita(e.checked)} checked={vl_AASuenioQ3Grita}> </Checkbox>
                                                                        <label className="p-checkbox-label">Gritar   </label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAASuenioQ3SeMueve(e.checked)} checked={vl_AASuenioQ3SeMueve}> </Checkbox>
                                                                        <label className="p-checkbox-label">Moverse   </label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAASuenioQ3Transpira(e.checked)} checked={vl_AASuenioQ3Transpira}> </Checkbox>
                                                                        <label className="p-checkbox-label">Transpirar </label>
                                                                    </div>
                                                                    {/* <div className="field-checkbox">
                                                                                <Checkbox onChange={e => setAASuenioQ3Camina(e.checked)} checked={vl_AASuenioQ3Camina}> </Checkbox>
                                                                                <label className="p-checkbox-label">Caminar   </label>
                                                                            </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿a qué edad y qué pasaba en el ambiente?</label>
                                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">Discusiones, perdidas, duelo.</p>
                                                            </div>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AASuenioQ4}
                                                                onChange={(e: any) => {
                                                                    setAASuenioQ4(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Presentas dificultades para dormir?</label>
                                                            <SelectButton className='pt-1' value={vl_AASuenioQ5} options={['SI', 'NO']} onChange={(e) => setAASuenioQ5(e.value)} />
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Si es SI, ¿Qué hace cuando sucede?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AASuenioQ5Txt}
                                                                onChange={(e: any) => {
                                                                    setAASuenioQ5Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="INDEPENDENCIA PERSONAL" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Cómo te describes de niño, adolescente? ¿Eras responsable, disciplinado, etc?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAIndPersonalQ1}
                                                                onChange={(e: any) => {
                                                                    setAAIndPersonalQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Disciplina en el hogar, existencia de normas:</label>
                                                            <SelectButton className='pt-1' value={vl_AAIndPersonalQ2} options={['SI', 'NO']} onChange={(e) => setAAIndPersonalQ2(e.value)} />
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Descripcion:</label>

                                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">Consistentes, rígidas, inconstantes, autoritario, permisivo, indiferente</p>
                                                            </div>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAIndPersonalQ2Txt}
                                                                onChange={(e: any) => {
                                                                    setAAIndPersonalQ2Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <div className="flex justify-content-between ">
                                                                <div >
                                                                    <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Castigos:</label>
                                                                    <SelectButton className='pt-1' value={vl_AAIndPersonalQ3} options={['SI', 'NO']} onChange={(e) => setAAIndPersonalQ3(e.value)} />
                                                                </div>
                                                                <div className='col-8' >
                                                                    <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Premios:</label>
                                                                    <SelectButton className='pt-1' value={vl_AAIndPersonalQ4} options={['SI', 'NO']} onChange={(e) => setAAIndPersonalQ4(e.value)} />
                                                                </div>
                                                            </div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Quién lo ejecutaba? ¿Con qué frecuencia?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAIndPersonalQ4Txt}
                                                                onChange={(e: any) => {
                                                                    setAAIndPersonalQ4Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="CONDUCTA" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Conductas Inadaptativas:</label>
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Te mordías las uñas?</label>
                                                            <SelectButton className='pt-1' value={vl_AAConductaQ1} options={['SI', 'NO']} onChange={(e) => setAAConductaQ1(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Te succionabas los dedos?</label>
                                                            <SelectButton className='pt-1' value={vl_AAConductaQ2} options={['SI', 'NO']} onChange={(e) => setAAConductaQ2(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Te mordías el labio?</label>
                                                            <SelectButton className='pt-1' value={vl_AAConductaQ3} options={['SI', 'NO']} onChange={(e) => setAAConductaQ3(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿te sudaban las manos?</label>
                                                            <SelectButton className='pt-1' value={vl_AAConductaQ4} options={['SI', 'NO']} onChange={(e) => setAAConductaQ4(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿te temblaban las manos y piernas?</label>
                                                            <SelectButton className='pt-1' value={vl_AAConductaQ5} options={['SI', 'NO']} onChange={(e) => setAAConductaQ5(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Qué conductas inadecuadas presentaban cuando eras niño, adolescente?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAConductaQ6Txt}
                                                                onChange={(e: any) => {
                                                                    setAAConductaQ6Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="JUEGO-SOCIAL" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>En la niñez y adolescencia ¿Con quién jugabas? ¿A qué jugabas? ¿Cuáles eran tus juguetes preferidos?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAJuegoSocialQ1}
                                                                onChange={(e: any) => {
                                                                    setAAJuegoSocialQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Preferías jugar con chicos de tu edad, con mayores o niños menores a tu edad?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAJuegoSocialQ2}
                                                                onChange={(e: any) => {
                                                                    setAAJuegoSocialQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Qué hacías en tu tiempo libre?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAJuegoSocialQ3}
                                                                onChange={(e: any) => {
                                                                    setAAJuegoSocialQ3(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Cómo te llevabas con los demás chicos de tu edad?: agresividad, ausencia del deseo del contacto.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAJuegoSocialQ4}
                                                                onChange={(e: any) => {
                                                                    setAAJuegoSocialQ4(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Cuales son tus juegos de ocio?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAJuegoSocialQ5}
                                                                onChange={(e: any) => {
                                                                    setAAJuegoSocialQ5(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="HISTORIA EDUCATIVA" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Inicial</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Edad </label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduIEdad}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduIEdad(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Adaptación  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduIAdaptacion}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduIAdaptacion(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Dificultades  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduIDificultades}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduIDificultades(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Conductas disfuncionales   </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduIConductasDisfuncionales}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduIConductasDisfuncionales(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-3">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Primaria</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Edad </label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduPEdad}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduPEdad(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-2 ">
                                                            <div className="field-checkbox">
                                                                <label className="p-col-fixed mr-1 text-sm font-bold " style={{ textAlign: 'end' }}>Rendimiento: </label>
                                                                <Checkbox onChange={e => setAAHistoriaEduPRendimientoBueno(e.checked)} checked={vl_AAHistoriaEduPRendimientoBueno}> </Checkbox>
                                                                <label className="p-checkbox-label">Bueno </label>
                                                                <Checkbox onChange={e => setAAHistoriaEduPRendimientoRegular(e.checked)} checked={vl_AAHistoriaEduPRendimientoRegular}> </Checkbox>
                                                                <label className="p-checkbox-label">Regular   </label>
                                                                <Checkbox onChange={e => setAAHistoriaEduPRendimientoBajo(e.checked)} checked={vl_AAHistoriaEduPRendimientoBajo}> </Checkbox>
                                                                <label className="p-checkbox-label">Bajo  </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Dificultades(especificar)</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduPDificultades}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduPDificultades(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Nivel de adaptación  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduPNivelAdaptacion}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduPNivelAdaptacion(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-3">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Secundaria</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Edad </label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSEdad}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSEdad(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-2 ">
                                                            <div className="field-checkbox">
                                                                <label className="p-col-fixed mr-1 text-sm font-bold " style={{ textAlign: 'end' }}>Rendimiento: </label>
                                                                <Checkbox onChange={e => setAAHistoriaEduSRendimientoBueno(e.checked)} checked={vl_AAHistoriaEduSRendimientoBueno}> </Checkbox>
                                                                <label className="p-checkbox-label">Bueno </label>
                                                                <Checkbox onChange={e => setAAHistoriaEduSRendimientoRegular(e.checked)} checked={vl_AAHistoriaEduSRendimientoRegular}> </Checkbox>
                                                                <label className="p-checkbox-label">Regular   </label>
                                                                <Checkbox onChange={e => setAAHistoriaEduSRendimientoBajo(e.checked)} checked={vl_AAHistoriaEduSRendimientoBajo}> </Checkbox>
                                                                <label className="p-checkbox-label">Bajo  </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Dificultades(especificar)</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSDificultades}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSDificultades(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Nivel de adaptación</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSNivelAdaptacion}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSNivelAdaptacion(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Cambios en el colegio ¿En qué grados y por qué?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ1Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ1Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Observó dificultades en tu aprendizaje? ¿desde cuándo? ¿Qué hizo? Rendimiento en la escritura, lectura y matemáticas,etc.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ2Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ2Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}> Repitencias. ¿Cuántas veces? </label>
                                                            <SelectButton className='pt-1' value={vl_AAHistoriaEduSQ3} options={['SI', 'NO']} onChange={(e) => setAAHistoriaEduSQ3(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Por qué?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ3Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ3Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-3">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Conducta en clases</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Asignatura que más domina </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ4Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ4Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Asignatura que más domina </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ5Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ5Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Asignatura que menos domina </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ6Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ6Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-2">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}> ¿Que opinion tienes hacia el profesor?, ¿Hacia tus compañeros y las tareas?</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Hacia el profesor</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ7Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ7Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>De sus compañeros</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ8Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ8Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>De las tareas</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ9Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ9Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        {/* <div className="p-col pt-2">
                                                                    <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}> Opinión del profesor.</label>
                                                                </div> */}
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Ha recibido algún servicio especializado para mejorar tu aprendizaje?</label>
                                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">Ejemplo: Clases particular, terapia, Logopedia, refuerzos ¿a que edad? </p>
                                                            </div>

                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ10Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ10Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Actualmente ¿como te va en tus estudios?</label>
                                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">Hábitos de estudio, organización de tiempo, horarios de estudio.</p>
                                                            </div>

                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAHistoriaEduSQ11Txt}
                                                                onChange={(e: any) => {
                                                                    setAAHistoriaEduSQ11Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="PSICOSEXUALIDAD" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿A qué edad hizo preguntas sobre sexo y procreación? Información que se le brindó ¿Cómo?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPsicosexualidadQ1}
                                                                onChange={(e: any) => {
                                                                    setAAPsicosexualidadQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿A qué edad fue la primera menstruación?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPsicosexualidadQ2}
                                                                onChange={(e: any) => {
                                                                    setAAPsicosexualidadQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿A qué edad fue su primera relación sexual? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPsicosexualidadQ3}
                                                                onChange={(e: any) => {
                                                                    setAAPsicosexualidadQ3(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Has tenido parejas sexuales casuales?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPsicosexualidadQ4}
                                                                onChange={(e: any) => {
                                                                    setAAPsicosexualidadQ4(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Has presentado alguna enfermedad de transmisión sexual?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAPsicosexualidadQ5}
                                                                onChange={(e: any) => {
                                                                    setAAPsicosexualidadQ5(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="ÁREA FAMILIAR" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Relación con el padre en la niñez y adolescencia.</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Relación con la mamá: </label>
                                                            <div className="field-checkbox">
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>Rendimiento: </label>
                                                                <Checkbox onChange={e => setAAAreaFamiliarRMamaRechazo(e.checked)} checked={vl_AAAreaFamiliarRMamaRechazo}> </Checkbox>
                                                                <label className="p-checkbox-label">Rechazo </label>
                                                                <Checkbox onChange={e => setAAAreaFamiliarRMamaVerguenza(e.checked)} checked={vl_AAAreaFamiliarRMamaVerguenza}> </Checkbox>
                                                                <label className="p-checkbox-label">Verguenza   </label>
                                                                <Checkbox onChange={e => setAAAreaFamiliarRMamaIndiferencia(e.checked)} checked={vl_AAAreaFamiliarRMamaIndiferencia}> </Checkbox>
                                                                <label className="p-checkbox-label">Indiferencia  </label>
                                                                <Checkbox onChange={e => setAAAreaFamiliarRMamaAceptacion(e.checked)} checked={vl_AAAreaFamiliarRMamaAceptacion}> </Checkbox>
                                                                <label className="p-checkbox-label">Aceptacion  </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Descripción: (Ejemplos de eventos)</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarRMamaEventoTxt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarRMamaEventoTxt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Relación con el Padre en la niñez y adolescencia</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAAAreaFamiliarRPapaRechazo(e.checked)} checked={vl_AAAreaFamiliarRPapaRechazo}> </Checkbox>
                                                                <label className="p-checkbox-label">Rechazo </label>
                                                                <Checkbox onChange={e => setAAAreaFamiliarRPapaPreocupacion(e.checked)} checked={vl_AAAreaFamiliarRPapaPreocupacion}> </Checkbox>
                                                                <label className="p-checkbox-label">Preocupacion   </label>
                                                                <Checkbox onChange={e => setAAAreaFamiliarRPapaVerguenza(e.checked)} checked={vl_AAAreaFamiliarRPapaVerguenza}> </Checkbox>
                                                                <label className="p-checkbox-label">Verguenza  </label>
                                                                <Checkbox onChange={e => setAAAreaFamiliarRPapaIndiferencia(e.checked)} checked={vl_AAAreaFamiliarRPapaIndiferencia}> </Checkbox>
                                                                <label className="p-checkbox-label">Indiferencia  </label>
                                                                <Checkbox onChange={e => setAAAreaFamiliarRPapaAceptacion(e.checked)} checked={vl_AAAreaFamiliarRPapaAceptacion}> </Checkbox>
                                                                <label className="p-checkbox-label">Aceptación  </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Descripción: (Ejemplos de eventos)</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarRPapaEventoTxt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarRPapaEventoTxt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Cuál fue tu comportamiento al nacimiento de tu hermano?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarQ1Txt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarQ1Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Relación con sus hermanos en la niñez y adolescencia:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarTxt1}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarTxt1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> Pautas de crianza: ¿Empleaban castigos, refuerzos positivos y con qué frecuencia? (Recuerdos- eventos):</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarQ2Txt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarQ2Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> Cuidadores principales en la niñez:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarTxt2}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarTxt2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> Actualmente: ¿cómo es la relación con sus padres y hermanos? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarQ3Txt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarQ3Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> Antecedentes genéticos de enfermedades psiquiátricas:  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarTxt3}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarTxt3(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        {/* <div className="p-col pt-1">
                                                                    <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Problemas del habla, dificultades en el aprendizaje, epilepsias, convulsiones, retardo mental, entre otros).</label>
                                                                    <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                        value={vl_AAAreaFamiliarTxt4}
                                                                        onChange={(e: any) => {
                                                                            setAAAreaFamiliarTxt4(String(e.currentTarget.value));
                                                                        }}
                                                                    />
                                                                </div> */}

                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Carácter de los padres, describe:</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Papá:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarCaractPapa}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarCaractPapa(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Mamá:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarCaractMama}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarCaractMama(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Relación entre sus padres: ¿Qué has observado de niño en la relación?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarQ4Txt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarQ4Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Actualmente: ¿Cómo es la relación con?</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Padre:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarQ5Txt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarQ5Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Madre:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarQ6Txt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarQ6Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Hermanos:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaFamiliarQ7Txt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarQ7Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="ÁREA DE PAREJA" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pb-2">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-semibold " style={{ textAlign: 'left' }}>¿Menciona las relaciones de pareja que has tenido?</label>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-semibold" style={{ textAlign: 'left' }}>¿Actualmente como es la relacion con tu pareja?</label>
                                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">¿Cómo inició? ¿A qué edad y qué edad tenía él o ella? ¿Cómo fue la relación? ¿Por qué terminó? </p>
                                                            </div>
                                                            {/* <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">¿A qué edad y qué edad tenía él o ella? ¿Cómo inició?¿Cómo fue la relación? ¿Por qué terminó?</p>
                                                            </div> */}
                                                            {/* <div className="flex flex-wrap align-items-center justify-content-start pb-2">                                                                
                                                                <p className="p-col mr-1 ml-1 mb-1 text-sm font-semibold" style={{ textAlign: 'left' }}>¿Actualmente como es la relacion con tu pareja?</p>
                                                            </div>    */}
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-semibold " style={{ textAlign: 'left' }}>Relato.</label>
                                                            <Editor style={{ height: '250px' }}
                                                                headerTemplate={header01}
                                                                value={vl_AAAreaParejaQ1txt}
                                                                onTextChange={(e) => setAAAreaParejaQ1txt(String(e.htmlValue ? e.htmlValue : ''))}
                                                            />
                                                            {/* <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAAreaParejaQ1txt}
                                                                onChange={(e: any) => {
                                                                    setAAAreaParejaQ1txt(String(e.currentTarget.value));
                                                                }} 
                                                            />*/}
                                                        </div>
                                                        {/* <div className="p-col pb-2">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-semibold " style={{ textAlign: 'left' }}>Relato.</label>                                                            
                                                            <Editor style={{ height: '200px' }}
                                                                headerTemplate={header01}
                                                                value={vl_AAAreaParejaQ2txt}
                                                                onTextChange={(e) => setAAAreaParejaQ2txt(String(e.htmlValue?e.htmlValue:''))}
                                                            />
                                                        </div>                                                         */}
                                                        {/* <div className="p-col pb-2">                                                            
                                                            <Editor style={{ height: '200px' }}
                                                                headerTemplate={header01}
                                                                value={vl_AAAreaParejaQ4txt}
                                                                onTextChange={(e) => setAAAreaParejaQ4txt(String(e.htmlValue?e.htmlValue:''))}
                                                            />
                                                        </div> */}
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="ÁREA LABORAL" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-semibold " style={{ textAlign: 'left' }}>Relato sobre su trabajo. </label>
                                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-2">¿A qué edad iniciaste a laborar? ¿Cuáles fueron tus funciones? ¿Dónde? ¿Por qué cambiaste de trabajo? ¿Actualmente como es tu ambiente laboral, como es la relación con tu jefe y compañeros?</p>
                                                            </div>
                                                            <Editor style={{ height: '200px' }}
                                                                headerTemplate={header01}
                                                                value={vl_AAAreaLaboralQ1txt}
                                                                onTextChange={(e) => setAAAreaLaboralQ1txt(String(e.htmlValue ? e.htmlValue : ''))}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                            </Accordion>
                                        </Fieldset >
                                        <Fieldset legend="Niños y adolescentes" className={!b_fieldsetHiddenAM ? 'hidden' : ''}>
                                            <Accordion multiple activeIndex={[0]}>
                                                <AccordionTab header="PRENATAL">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>¿Cuál es el número de embarazo con su hijo?</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMPrenatal_q}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAMPrenatal_q(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>¿Cómo fue su embarazo o gestación (condiciones)? Síntomas, problemas, duración.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMPrenatal_qa}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAMPrenatal_qa(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <div className="flex justify-content-between ">
                                                                <div>
                                                                    <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Fue planificado?</label>
                                                                    <SelectButton className='pt-1' value={vl_AMPrenatal_qb} options={['SI', 'NO']}
                                                                        onChange={(e) => setAMPrenatal_qb(e.value)} />
                                                                    <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Fue deseado?</label>
                                                                    <SelectButton className='pt-1' value={vl_AMPrenatal_qc} options={['SI', 'NO']}
                                                                        onChange={(e) => setAMPrenatal_qc(e.value)} />
                                                                </div>
                                                                <div>
                                                                    <label className="p-col-fixed pb-1 text-sm font-bold " style={{ textAlign: 'end' }}>Tipo de control:</label>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAMPrenatalTipoControlM(e.checked)} checked={vl_AMPrenatalTipoControlM}> </Checkbox>
                                                                        <label className="p-checkbox-label">Médico</label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAMPrenatalTipoControlP(e.checked)} checked={vl_AMPrenatalTipoControlP}> </Checkbox>
                                                                        <label className="p-checkbox-label">Partera</label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAMPrenatalTipoControlN(e.checked)} checked={vl_AMPrenatalTipoControlN}> </Checkbox>
                                                                        <label className="p-checkbox-label">No asistió a controles</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Enfermedades durante el embarazo de su mamá, dificultades y/o accidentes, ingesta de medicamentos. Rayos X.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMPrenatal_qd}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAMPrenatal_qd(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Ingesta de alcohol, tabaco, drogas y/o anticonceptivos:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMPrenatal_qe}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAMPrenatal_qe(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Pérdidas de embarazos?</label>
                                                            <SelectButton className='pt-1' value={vl_AMPrenatal_qf} options={['SI', 'NO']}
                                                                onChange={(e) => setAMPrenatal_qf(e.value)} />
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Causas:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMPrenatal_qfTxt}
                                                                onChange={(e: any) => {
                                                                    setAMPrenatal_qfTxt(String(e.currentTarget.value));
                                                                }
                                                                }
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="PERINATAL">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-font " style={{ textAlign: 'start' }}>¿Cuánto duro el embarazo?</label>
                                                            <InputText type="text" style={{ width: '10rem', textAlign: 'left' }}
                                                                value={vl_AMPerinatalTxtTNacio}
                                                                onChange={(e: any) => {
                                                                    setAMPerinatalTxtTNacio(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="formgroup-inline">
                                                            <label className="p-col-fixed pr-2 text-sm font-bold " style={{ textAlign: 'start' }}>¿Dónde nació?</label>
                                                            <div className="field-radiobutton">
                                                                <RadioButton value="H" onChange={e => setAMPerinatalDondeNacio(e.value)} checked={vl_AMPerinatalDondeNacio === "H"}> </RadioButton>
                                                                <label className="p-checkbox-label">Hospital</label>
                                                                <RadioButton value="P" onChange={e => setAMPerinatalDondeNacio(e.value)} checked={vl_AMPerinatalDondeNacio === "P"}> </RadioButton>
                                                                <label className="p-checkbox-label">Partera</label>
                                                                {/* <RadioButton value="O" onChange={e => setAMPerinatalDondeNacio(e.value)} checked={vl_AMPerinatalDondeNacio === "O"}> </RadioButton> */}
                                                                <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Otros</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMPerinatalDondeNacioOtros}
                                                                    onChange={
                                                                        (e: any) => {
                                                                            setAMPerinatalDondeNacioOtros(String(e.currentTarget.value));
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="formgroup-inline">
                                                            <label className="p-col-fixed pr-2 text-sm font-bold " style={{ textAlign: 'start' }}>Tipo de parto</label>
                                                            <div className="field-radiobutton">
                                                                <RadioButton value="N" onChange={e => setAMPerinatalTipoParto(e.value)} checked={vl_AMPerinatalTipoParto === "N"}> </RadioButton>
                                                                <label className="p-checkbox-label">Normal</label>
                                                                <RadioButton value="C" onChange={e => setAMPerinatalTipoParto(e.value)} checked={vl_AMPerinatalTipoParto === "C"}> </RadioButton>
                                                                <label className="p-checkbox-label">Cesárea </label>
                                                                <RadioButton value="D" onChange={e => setAMPerinatalTipoParto(e.value)} checked={vl_AMPerinatalTipoParto === "D"}> </RadioButton>
                                                                <label className="p-checkbox-label">Con desgarramiento</label>
                                                            </div>
                                                            <div className="field-radiobutton">
                                                                <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Inducido. ¿Por qué?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMPerinatalTipoPartoInducido}
                                                                    onChange={
                                                                        (e: any) => {
                                                                            setAMPerinatalTipoPartoInducido(String(e.currentTarget.value));
                                                                        }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Presentación del recién nacido (Peso y altura):</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMPerinatalPRNPesoAltura}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAMPerinatalPRNPesoAltura(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                            <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Llanto al nacer, coloración ¿necesitó reanimación con oxígeno o incubadora:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMPerinatalTxtLloroNacer}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAMPerinatalTxtLloroNacer(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mb-1 mr-2 text-sm font-normal " style={{ textAlign: 'start' }}>¿Por cuánto tiempo?</label>
                                                            <InputText type="text" style={{ width: '12rem', textAlign: 'left' }}
                                                                value={vl_AMPerinatalTxtLloroNacerTiempo}
                                                                onChange={
                                                                    (e: any) => {
                                                                        setAMPerinatalTxtLloroNacerTiempo(String(e.currentTarget.value));
                                                                    }
                                                                }
                                                            />
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <div className="p-col pt-1">
                                                                <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Edades de los padres, al momento de nacer el/la niño/a.</label>
                                                            </div>
                                                            <div className="p-col pt-1">
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'start' }}>Papá</label>
                                                                <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                    value={vl_AMPerinatalEdadP}
                                                                    onChange={(e: any) => {
                                                                        setAMPerinatalEdadP(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                                <label className="p-col-fixed mr-1 ml-1 text-sm font-normal " style={{ textAlign: 'start' }}>Mamá</label>
                                                                <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                    value={vl_AMPerinatalEdadM}
                                                                    onChange={(e: any) => {
                                                                        setAMPerinatalEdadM(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="POSTNATAL">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="flex justify-content-between">
                                                            <div>
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Malformaciones?</label>
                                                                <SelectButton className='pt-1' value={vl_AMPosnatalQa} options={['SI', 'NO']}
                                                                    onChange={(e) => setAMPosnatalQa(e.value)} />
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Dificultades en la succión?</label>
                                                                <SelectButton className='pt-1' value={vl_AMPosnatalQb} options={['SI', 'NO']}
                                                                    onChange={(e) => setAMPosnatalQb(e.value)} />
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Dificultades después del parto?</label>
                                                                <SelectButton className='pt-1' value={vl_AMPosnatalQc} options={['SI', 'NO']}
                                                                    onChange={(e) => setAMPosnatalQc(e.value)} />
                                                            </div>
                                                            <div>
                                                                <label className="p-col-fixed mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>¿Cuáles?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMPosnatalQdRespTxt}
                                                                    onChange={
                                                                        (e: any) => {
                                                                            setAMPosnatalQdRespTxt(String(e.currentTarget.value));
                                                                        }
                                                                    }
                                                                />
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Lactancia materna?</label>
                                                                <SelectButton className='pt-1' value={vl_AMPosnatalQe} options={['SI', 'NO']}
                                                                    onChange={(e) => setAMPosnatalQe(e.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="HISTORIA MÉDICA">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-semibold " style={{ textAlign: 'end' }}>¿Padecio de alguna enfermedad?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaMedicaTxta}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaMedicaTxta(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'end' }}>Principales enfermedades. Medicamentos consumidos:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaMedicaTxtb}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaMedicaTxtb(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'end' }}>Ha sufrido accidentes, golpes en la cabeza con pérdida de conocimiento:</label>
                                                            <SelectButton className='pt-1' value={vl_AMHistoriaMedicaQc} options={['SI', 'NO']} onChange={(e) => setAMHistoriaMedicaQc(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHistoriaMedicaConvulsion(e.checked)} checked={vl_AMHistoriaMedicaConvulsion}> </Checkbox>
                                                                <label className="p-checkbox-label">Convulsiones </label>
                                                                <Checkbox onChange={e => setAMHistoriaMedicaMareos(e.checked)} checked={vl_AMHistoriaMedicaMareos}> </Checkbox>
                                                                <label className="p-checkbox-label">Mareos  </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Qué edad tenía el niño?</label>
                                                            <InputText type="text" style={{ width: '30%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaMedicaEdadTenia}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaMedicaEdadTenia(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Cómo fue atendido?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaMedicaComoFueAtendido}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaMedicaComoFueAtendido(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="flex justify-content-between ">
                                                            <div>
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Operaciones?</label>
                                                                <SelectButton className='pt-1' value={vl_AMHistoriaMedicaOperaciones} options={['SI', 'NO']} onChange={(e) => setAMHistoriaMedicaOperaciones(e.value)} />
                                                            </div>
                                                            <div>
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>¿Cuáles? ¿Por qué? </label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMHistoriaMedicaOperacionesTxt}
                                                                    onChange={(e: any) => {
                                                                        setAMHistoriaMedicaOperacionesTxt(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}> Exámenes realizados: </label>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHistoriaMedicaExamenRealidadoN(e.checked)} checked={vl_AMHistoriaMedicaExamenRealidadoN}> </Checkbox>
                                                                <label className="p-checkbox-label">Neurológico </label>
                                                                <Checkbox onChange={e => setAMHistoriaMedicaExamenRealidadoA(e.checked)} checked={vl_AMHistoriaMedicaExamenRealidadoA}> </Checkbox>
                                                                <label className="p-checkbox-label">Audiológico  </label>
                                                                <Checkbox onChange={e => setAMHistoriaMedicaExamenRealidadoP(e.checked)} checked={vl_AMHistoriaMedicaExamenRealidadoP}> </Checkbox>
                                                                <label className="p-checkbox-label">Psiquiátrico  </label>
                                                                <Checkbox onChange={e => setAMHistoriaMedicaExamenRealidadoPsi(e.checked)} checked={vl_AMHistoriaMedicaExamenRealidadoPsi}> </Checkbox>
                                                                <label className="p-checkbox-label">Psicológico   </label>
                                                                <Checkbox onChange={e => setAMHistoriaMedicaExamenRealidadoOtros(e.checked)} checked={vl_AMHistoriaMedicaExamenRealidadoOtros}> </Checkbox>
                                                                <label className="p-checkbox-label">Otros   </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col">
                                                            <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}> Resultados: </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaMedicaExamenRealidadoTxt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaMedicaExamenRealidadoTxt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="DESARROLLO NEUROMUSCULAR">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pb-2">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'end' }}>Edades para:</label>
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ width: '120px', textAlign: 'end' }}>Levantar la cabeza:</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMDesarrolloNMEdadLevantarCabeza}
                                                                onChange={(e: any) => {
                                                                    setAMDesarrolloNMEdadLevantarCabeza(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ width: '120px', textAlign: 'end' }}>Sentarse (sin ayuda): </label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMDesarrolloNMEdadSentarse}
                                                                onChange={(e: any) => {
                                                                    setAMDesarrolloNMEdadSentarse(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ width: '120px', textAlign: 'end' }}>Gatear :</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMDesarrolloNMEdadGatear}
                                                                onChange={(e: any) => {
                                                                    setAMDesarrolloNMEdadGatear(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ width: '120px', textAlign: 'end' }}>Pararse (sin ayuda):</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMDesarrolloNMEdadPararse}
                                                                onChange={(e: any) => {
                                                                    setAMDesarrolloNMEdadPararse(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ width: '120px', textAlign: 'end' }}>Caminar:</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMDesarrolloNMEdadCaminar}
                                                                onChange={(e: any) => {
                                                                    setAMDesarrolloNMEdadCaminar(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pb-1">
                                                            <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>Dificultades, tendencia a caerse o golpearse</label>
                                                            <SelectButton className='pt-1' value={vl_AMDesarrolloNMDificultades} options={['SI', 'NO']}
                                                                onChange={(e) => setAMDesarrolloNMDificultades(e.value)} />

                                                        </div>
                                                        <div className="p-col pt-2">
                                                            <label className="p-col-fixed mb-1 text-sm font-bold " style={{ textAlign: 'start' }}>Presencia de movimientos automáticos. </label>
                                                            <div className="p-col pt-1">
                                                                <label className="p-col-fixed mb-2 text-sm font-normal " style={{ textAlign: 'start' }}>Movimientos agitados: </label>
                                                                <div className="field-checkbox">
                                                                    <Checkbox onChange={e => setAMDesarrolloNMMovimientoSacudeBrazo(e.checked)} checked={vl_AMDesarrolloNMMovimientoSacudeBrazo}> </Checkbox>
                                                                    <label className="p-checkbox-label">Sacude los brazos </label>
                                                                    <Checkbox onChange={e => setAMDesarrolloNMMovimientoEstrujaMano(e.checked)} checked={vl_AMDesarrolloNMMovimientoEstrujaMano}> </Checkbox>
                                                                    <label className="p-checkbox-label">Estruja las manos  </label>
                                                                </div>
                                                            </div>
                                                            <div className="p-col pt-1">
                                                                <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>¿En qué momento?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMDesarrolloNMMovimientoTxt}
                                                                    onChange={(e: any) => {
                                                                        setAMDesarrolloNMMovimientoTxt(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="p-col pt-1">
                                                                <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>¿Con qué frecuencia? </label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMDesarrolloNMMovimientoTxt2}
                                                                    onChange={(e: any) => {
                                                                        setAMDesarrolloNMMovimientoTxt2(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="p-col pt-1">
                                                                <div className="field-checkbox">
                                                                    <Checkbox onChange={e => setAMDesarrolloNMHabCaminar(e.checked)} checked={vl_AMDesarrolloNMHabCaminar}> </Checkbox>
                                                                    <label className="p-checkbox-label">Habilidades para caminar </label>
                                                                    <Checkbox onChange={e => setAMDesarrolloNMHabSaltar1Pie(e.checked)} checked={vl_AMDesarrolloNMHabSaltar1Pie}> </Checkbox>
                                                                    <label className="p-checkbox-label">Saltar pararse sobre un pie  </label>
                                                                    <Checkbox onChange={e => setAMDesarrolloNMHabDesplazarSalt1Pie(e.checked)} checked={vl_AMDesarrolloNMHabDesplazarSalt1Pie}> </Checkbox>
                                                                    <label className="p-checkbox-label">Desplazarse saltando sobre un pie </label>
                                                                </div>
                                                            </div>
                                                            <div className="p-col pt-1">
                                                                <label className="p-col-fixed mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'start' }}>Dominancia lateral manual:</label>
                                                                <SelectButton className='pt-1' value={vl_AMDesarrolloNMDomLatManual} options={['IZQUIERDA', 'DERECHA']}
                                                                    onChange={(e) => setAMDesarrolloNMDomLatManual(e.value)} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="HABILIDAD PARA HABLAR">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-font " style={{ textAlign: 'start' }}>¿A qué edad su hijo balbuceó?</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMHabilidadHablaQ1}
                                                                onChange={(e: any) => {
                                                                    setAMHabilidadHablaQ1(String(e.currentTarget.value));
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-font " style={{ textAlign: 'start' }}>¿Las primeras palabras? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHabilidadHablaQ2}
                                                                onChange={(e: any) => {
                                                                    setAMHabilidadHablaQ2(String(e.currentTarget.value));
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-font " style={{ textAlign: 'start' }}>¿De qué manera se hace entender Ud., por su hijo?   </label>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHabilidadHablaEntenderGestos(e.checked)} checked={vl_AMHabilidadHablaEntenderGestos}> </Checkbox>
                                                                <label className="p-checkbox-label">Gestos  </label>
                                                            </div>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHabilidadHablaEntenderGritos(e.checked)} checked={vl_AMHabilidadHablaEntenderGritos}> </Checkbox>
                                                                <label className="p-checkbox-label">Gritos  </label>
                                                            </div>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHabilidadHablaEntenderHablando(e.checked)} checked={vl_AMHabilidadHablaEntenderHablando}> </Checkbox>
                                                                <label className="p-checkbox-label">Hablando   </label>
                                                            </div>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHabilidadHablaEntenderBalbuceando(e.checked)} checked={vl_AMHabilidadHablaEntenderBalbuceando}> </Checkbox>
                                                                <label className="p-checkbox-label"> Llevando de la mano balbuceando   </label>
                                                            </div>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHabilidadHablaEntenderOtros(e.checked)} checked={vl_AMHabilidadHablaEntenderOtros}> </Checkbox>
                                                                <label className="p-checkbox-label"> Otros   </label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMHabilidadHablaQ3}
                                                                    onChange={(e: any) => {
                                                                        setAMHabilidadHablaQ3(String(e.currentTarget.value));
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-bold " style={{ textAlign: 'start' }}>Dificultades para pronunciar.</label>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHabilidadHablaDificultadPronOmision(e.checked)} checked={vl_AMHabilidadHablaDificultadPronOmision}> </Checkbox>
                                                                <label className="p-checkbox-label">omisión  </label>
                                                                <Checkbox onChange={e => setAMHabilidadHablaDificultadPronSustitucion(e.checked)} checked={vl_AMHabilidadHablaDificultadPronSustitucion}> </Checkbox>
                                                                <label className="p-checkbox-label">Sustitución   </label>
                                                                <Checkbox onChange={e => setAMHabilidadHablaDificultadPronDistorsion(e.checked)} checked={vl_AMHabilidadHablaDificultadPronDistorsion}> </Checkbox>
                                                                <label className="p-checkbox-label">Distorsión de fonemas </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-bold " style={{ textAlign: 'start' }}>¿Cómo es su pronunciación, se entiende, articulación trabada? Describir.</label>
                                                            <div className="p-col pt-1 pb-1">
                                                                <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>¿Cuántas palabras decía al año?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMHabilidadHablaQ4}
                                                                    onChange={(e: any) => {
                                                                        setAMHabilidadHablaQ4(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="p-col pt-1 pb-1">
                                                                <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>¿Cuántas palabras decía a los dos años?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMHabilidadHablaQ5}
                                                                    onChange={(e: any) => {
                                                                        setAMHabilidadHablaQ5(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="p-col pt-1 pb-1">
                                                                <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>¿Cuántas palabras decía al año y seis meses?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMHabilidadHablaQ6}
                                                                    onChange={(e: any) => {
                                                                        setAMHabilidadHablaQ6(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="p-col pt-1 pb-1">
                                                                <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>¿Cuándo empezó a utilizar frases de 2 palabras? ¿De tres?</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMHabilidadHablaQ7}
                                                                    onChange={(e: any) => {
                                                                        setAMHabilidadHablaQ7(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="p-col pt-1 pb-1">
                                                                <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>Reacción cuando se le llama por su nombre.</label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMHabilidadHablaQ8}
                                                                    onChange={(e: any) => {
                                                                        setAMHabilidadHablaQ8(String(e.currentTarget.value));
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <div className='flex justify-content-between align-items-center ' >
                                                                <div>
                                                                    <div className='h-3rem' >
                                                                        <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>¿Se le entiende bien cuando habla en casa?</label>
                                                                    </div>
                                                                    <div className='h-3rem' >
                                                                        <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>¿Con otros niños?</label>
                                                                    </div>
                                                                    <div className='h-3rem' >
                                                                        <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>¿Con los familiares?</label>
                                                                    </div>
                                                                    <div className='h-3rem' >
                                                                        <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿La sonrisa tiene valor comunicativo?</label>
                                                                    </div>
                                                                    <div className='h-3rem'>
                                                                        <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿La expresión facial?</label>
                                                                    </div>
                                                                    <div className='h-2rem'>
                                                                        <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Responde cuando se le habla?</label>
                                                                    </div>
                                                                </div>
                                                                <div className='col-5' >
                                                                    <SelectButton className='pt-1' value={vl_AMHabilidadHablaQ9} options={['SI', 'NO']} onChange={(e) => setAMHabilidadHablaQ9(e.value)} />
                                                                    <SelectButton className='pt-1' value={vl_AMHabilidadHablaQ10} options={['SI', 'NO']} onChange={(e) => setAMHabilidadHablaQ10(e.value)} />
                                                                    <SelectButton className='pt-1' value={vl_AMHabilidadHablaQ11} options={['SI', 'NO']} onChange={(e) => setAMHabilidadHablaQ11(e.value)} />
                                                                    <SelectButton className='pt-1' value={vl_AMHabilidadHablaQ12} options={['SI', 'NO']} onChange={(e) => setAMHabilidadHablaQ12(e.value)} />
                                                                    <SelectButton className='pt-1' value={vl_AMHabilidadHablaQ13} options={['SI', 'NO']} onChange={(e) => setAMHabilidadHablaQ13(e.value)} />
                                                                    <SelectButton className='pt-1' value={vl_AMHabilidadHablaQ14} options={['SI', 'NO']} onChange={(e) => setAMHabilidadHablaQ14(e.value)} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMHabilidadHablaDemasiado(e.checked)} checked={vl_AMHabilidadHablaDemasiado}> </Checkbox>
                                                                <label className="p-checkbox-label">Habla demasiado</label>
                                                                <Checkbox onChange={e => setAMHabilidadHablaRapido(e.checked)} checked={vl_AMHabilidadHablaRapido}> </Checkbox>
                                                                <label className="p-checkbox-label">Rápido  </label>
                                                                <Checkbox onChange={e => setAMHabilidadHablaLento(e.checked)} checked={vl_AMHabilidadHablaLento}> </Checkbox>
                                                                <label className="p-checkbox-label">Lento  </label>
                                                                <Checkbox onChange={e => setAMHabilidadHablaNormal(e.checked)} checked={vl_AMHabilidadHablaNormal}> </Checkbox>
                                                                <label className="p-checkbox-label">Normal  </label>
                                                            </div>

                                                        </div>
                                                        <div className="p-col pt-1 pb-1">
                                                            <label className="p-col-fixed mb-1 text-sm mr-1 font-normal " style={{ textAlign: 'start' }}>¿Su voz es normal, alterada? ¿De qué tipo? ¿Grita al hablar?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHabilidadHablaQ15Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHabilidadHablaQ15Txt(String(e.currentTarget.value));
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="MOVIMIENTOS DE LA ZONA ORAL">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}> Uso del biberón</label>
                                                            <SelectButton className='pt-1' value={vl_AMMovZonaOralUsoBiberon} options={['SI', 'NO']} onChange={(e) => setAMMovZonaOralUsoBiberon(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Qué come con más frecuencia? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMMovZonaOralQ1}
                                                                onChange={(e: any) => { setAMMovZonaOralQ1(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-bold " style={{ width: '100%', textAlign: 'left' }}>Masticación. hábitos de masticación:  </label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>Morder objetos:  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMMovZonaOralMasticacionQ1}
                                                                onChange={(e: any) => { setAMMovZonaOralMasticacionQ1(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>Onicofagia: ¿A qué edad?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMMovZonaOralMasticacionQ2}
                                                                onChange={(e: any) => { setAMMovZonaOralMasticacionQ2(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>Bruxismo ¿A qué edad? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMMovZonaOralMasticacionQ3}
                                                                onChange={(e: any) => { setAMMovZonaOralMasticacionQ3(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Come con los labios cerrados o abiertos?  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMMovZonaOralMasticacionQ4}
                                                                onChange={(e: any) => { setAMMovZonaOralMasticacionQ4(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Recibe tratamiento ortodóncico u odontológico?</label>
                                                            <SelectButton className='pt-1' value={vl_AMMovZonaOralMasticacionQ5} options={['SI', 'NO']} onChange={(e) => setAMMovZonaOralMasticacionQ5(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Con qué frecuencia lo lleva al odontólogo?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMMovZonaOralMasticacionQ6}
                                                                onChange={(e: any) => { setAMMovZonaOralMasticacionQ6(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Con qué frecuencia lo lleva al médico?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMMovZonaOralMasticacionQ7}
                                                                onChange={(e: any) => { setAMMovZonaOralMasticacionQ7(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-2">
                                                            <label className="p-col mr-1 mb-1 text-sm font-bold " style={{ width: '100%', textAlign: 'left' }}>Babea:</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMMovZonaOralBabeaAlDormir(e.checked)} checked={vl_AMMovZonaOralBabeaAlDormir}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Al dormir </label>
                                                                <Checkbox onChange={e => setAMMovZonaOralBabeaAlComer(e.checked)} checked={vl_AMMovZonaOralBabeaAlComer}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">AlComer </label>
                                                                <Checkbox onChange={e => setAMMovZonaOralBabeaEnTodoMomento(e.checked)} checked={vl_AMMovZonaOralBabeaEnTodoMomento}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">En todo momento </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-bold " style={{ width: '100%', textAlign: 'left' }}>Dificultades para respirar:</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMMovZonaOralDiffRespirarViaResp(e.checked)} checked={vl_AMMovZonaOralDiffRespirarViaResp}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Enfermedades a la vía respiratoria </label>
                                                                <Checkbox onChange={e => setAMMovZonaOralDiffRespirarAlergias(e.checked)} checked={vl_AMMovZonaOralDiffRespirarAlergias}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Alergias </label>
                                                            </div>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMMovZonaOralDiffRespirarResfriado(e.checked)} checked={vl_AMMovZonaOralDiffRespirarResfriado}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Resfriados frecuentes   </label>
                                                                <Checkbox onChange={e => setAMMovZonaOralDiffRespirarAsma(e.checked)} checked={vl_AMMovZonaOralDiffRespirarAsma}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Asma   </label>
                                                            </div>
                                                            <div className="field-checkbox">
                                                                {/* <Checkbox onChange={e => setAMMovZonaOralDiffRespirarOtros(e.checked)} checked={vl_AMMovZonaOralDiffRespirarOtros}> </Checkbox> */}
                                                                <label className="p-checkbox-label pr-2">Otros  </label>
                                                                <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                    value={vl_AMMovZonaOralDiffRespirarOtrosTxt}
                                                                    onChange={(e: any) => { setAMMovZonaOralDiffRespirarOtrosTxt(String(e.currentTarget.value)); }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="FORMACIÓN DE HÁBITOS">
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="bg-indigo-600 font-bold text-white pt-2 pb-1 pl-1 pr-1 ">ALIMENTACIÓN</div>
                                                        <div className="p-col pt-1">
                                                            <div className='flex justify-content-between align-items-center'>
                                                                <div className='h-3rem' >
                                                                    <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿Recibió lactancia su hijo?</label>
                                                                </div>
                                                                <div className='col-9' >
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAMAlimentacionMaterno(e.checked)} checked={vl_AMAlimentacionMaterno}> </Checkbox>
                                                                        <label className="p-checkbox-label">Materno  </label>
                                                                    </div>
                                                                    <div className="field-checkbox">
                                                                        <Checkbox onChange={e => setAMAlimentacionArtificial(e.checked)} checked={vl_AMAlimentacionArtificial}> </Checkbox>
                                                                        <label className="p-checkbox-label">Artificial   </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿A qué edad se dio el destete?</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMAlimentacionEdadDestete}
                                                                onChange={(e: any) => {
                                                                    setAMAlimentacionEdadDestete(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Actualmente ¿Requiere ayuda para comer?</label>
                                                            <SelectButton className='pt-1' value={vl_AMAlimentacionRequiereAyuda} options={['SI', 'NO']} onChange={(e) => setAMAlimentacionRequiereAyuda(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Usa cubiertos?</label>
                                                            <SelectButton className='pt-1' value={vl_AMAlimentacionUsaCubiertos} options={['SI', 'NO']} onChange={(e) => setAMAlimentacionUsaCubiertos(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Su hijo tiene apetito?</label>
                                                            <SelectButton className='pt-1' value={vl_AMAlimentacionProblemaApetito} options={['SI', 'NO']} onChange={(e) => setAMAlimentacionProblemaApetito(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Cuántas comidas recibe al día?</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMAlimentacionQtyComidasAlDia}
                                                                onChange={(e: any) => {
                                                                    setAMAlimentacionQtyComidasAlDia(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Usualmente cómo es su dieta? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAlimentacionAboutDieta}
                                                                onChange={(e: any) => {
                                                                    setAMAlimentacionAboutDieta(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1 pb-3">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Por qué? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAlimentacionAboutDietaWhy}
                                                                onChange={(e: any) => {
                                                                    setAMAlimentacionAboutDietaWhy(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="bg-indigo-600 font-bold text-white pt-2 pb-1 pl-1 pr-1 ">HIGIENE</div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}>¿A qué edad comenzaste a controlar la orina? Control de esfínteres:</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Diurna:</label>
                                                            <InputText type="text" style={{ width: '10rem', textAlign: 'left' }}
                                                                value={vl_AMHigieneQ1}
                                                                onChange={(e: any) => {
                                                                    setAMHigieneQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Nocturna:</label>
                                                            <InputText type="text" style={{ width: '10rem', textAlign: 'left' }}
                                                                value={vl_AMHigieneQ2}
                                                                onChange={(e: any) => {
                                                                    setAMHigieneQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Su hijo le pide cuando quiere hacer sus necesidades?</label>
                                                            <SelectButton className='pt-1' value={vl_AMHigieneQ3} options={['SI', 'NO']} onChange={(e) => setAMHigieneQ3(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Edad:</label>
                                                            <InputText type="text" style={{ width: '10rem', textAlign: 'left' }}
                                                                value={vl_AMHigieneQ3Txt}
                                                                onChange={(e: any) => { setAMHigieneQ3Txt(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1 pb-3">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Su hijo se asea solo?</label>
                                                            <SelectButton className='pt-1' value={vl_AMHigieneQ4} options={['SI', 'NO']} onChange={(e) => setAMHigieneQ4(e.value)} />

                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Si, requiere ayuda. ¿cómo?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHigieneQ4Txt}
                                                                onChange={(e: any) => { setAMHigieneQ4Txt(String(e.currentTarget.value)); }}
                                                            />
                                                        </div>

                                                        <div className="bg-indigo-600 font-bold text-white pt-2 pb-1 pl-1 pr-1 ">SUEÑO</div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Horas de sueño aproximado:</label>
                                                            <InputText type="text" style={{ width: '30%', textAlign: 'left' }}
                                                                value={vl_AMSuenioQ1}
                                                                onChange={(e: any) => {
                                                                    setAMSuenioQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Temores nocturnos:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMSuenioQ2}
                                                                onChange={(e: any) => {
                                                                    setAMSuenioQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Cuando su hijo está dormido:</label>
                                                            <div className="field-checkbox">
                                                                <Checkbox onChange={e => setAMSuenioQ3Habla(e.checked)} checked={vl_AMSuenioQ3Habla}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1 ">Habla</label>
                                                                <Checkbox onChange={e => setAMSuenioQ3Grita(e.checked)} checked={vl_AMSuenioQ3Grita}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Grita   </label>
                                                                <Checkbox onChange={e => setAMSuenioQ3SeMueve(e.checked)} checked={vl_AMSuenioQ3SeMueve}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Se mueve   </label>
                                                                <Checkbox onChange={e => setAMSuenioQ3Transpira(e.checked)} checked={vl_AMSuenioQ3Transpira}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Transpira   </label>
                                                                <Checkbox onChange={e => setAMSuenioQ3Camina(e.checked)} checked={vl_AMSuenioQ3Camina}> </Checkbox>
                                                                <label className="p-checkbox-label pr-1">Camina</label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿A qué edad?</label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMSuenioQ4}
                                                                onChange={(e: any) => {
                                                                    setAMSuenioQ4(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> ¿Se resiste a acostarse a un horario determinado? </label>
                                                            <SelectButton className='pt-1' value={vl_AMSuenioQ5} options={['SI', 'NO']} onChange={(e) => setAMSuenioQ5(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> Si es SÍ, ¿Qué hace cuando sucede eso? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMSuenioQ5Txt}
                                                                onChange={(e: any) => {
                                                                    setAMSuenioQ5Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="INDEPENDENCIA PERSONAL" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Su hijo hace mandados?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMIndPersonalQ1}
                                                                onChange={(e: any) => {
                                                                    setAMIndPersonalQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Dentro del hogar?</label>
                                                            <SelectButton className='pt-1' value={vl_AMIndPersonalQ2} options={['SI', 'NO']} onChange={(e) => setAMIndPersonalQ2(e.value)} />
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Fuera del hogar? (barrio)</label>
                                                            <SelectButton className='pt-1' value={vl_AMIndPersonalQ3} options={['SI', 'NO']} onChange={(e) => setAMIndPersonalQ3(e.value)} />
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Su hijo ayuda en casa?</label>
                                                            <SelectButton className='pt-1' value={vl_AMIndPersonalQ4} options={['SI', 'NO']} onChange={(e) => setAMIndPersonalQ4(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Qué responsabilidades tiene? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMIndPersonalQ5Txt}
                                                                onChange={(e: any) => {
                                                                    setAAIndPersonalQ5Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Existencia de normas</label>
                                                            <SelectButton className='pt-1' value={vl_AMIndPersonalQ6} options={['SI', 'NO']} onChange={(e) => setAMIndPersonalQ6(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Cuales? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMIndPersonalQ6Txt}
                                                                onChange={(e: any) => {
                                                                    setAMIndPersonalQ6Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <div className="flex justify-content-between ">
                                                                <div >
                                                                    <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Castigos:</label>
                                                                    <SelectButton className='pt-1' value={vl_AAIndPersonalQ7} options={['SI', 'NO']} onChange={(e) => setAAIndPersonalQ7(e.value)} />
                                                                </div>
                                                                <div className='col-8' >
                                                                    <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Premios:</label>
                                                                    <SelectButton className='pt-1' value={vl_AAIndPersonalQ8} options={['SI', 'NO']} onChange={(e) => setAAIndPersonalQ8(e.value)} />
                                                                </div>
                                                            </div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Quiénes lo ejecutan? ¿Con qué frecuencia?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AAIndPersonalQ8Txt}
                                                                onChange={(e: any) => {
                                                                    setAAIndPersonalQ8Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="CONDUCTA" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Conductas Inadaptativas:</label>
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Se come las uñas?</label>
                                                            <SelectButton className='pt-1' value={vl_AMConductaQ1} options={['SI', 'NO']} onChange={(e) => setAMConductaQ1(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Se succiona los dedos?</label>
                                                            <SelectButton className='pt-1' value={vl_AMConductaQ2} options={['SI', 'NO']} onChange={(e) => setAMConductaQ2(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Se muerde el labio?</label>
                                                            <SelectButton className='pt-1' value={vl_AMConductaQ3} options={['SI', 'NO']} onChange={(e) => setAMConductaQ3(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Le sudan las manos? </label>
                                                            <SelectButton className='pt-1' value={vl_AMConductaQ4} options={['SI', 'NO']} onChange={(e) => setAMConductaQ4(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Le tiemblan las manos y piernas?</label>
                                                            <SelectButton className='pt-1' value={vl_AMConductaQ5} options={['SI', 'NO']} onChange={(e) => setAMConductaQ5(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Agrede a las personas sin motivo? </label>
                                                            <SelectButton className='pt-1' value={vl_AMConductaQ6} options={['SI', 'NO']} onChange={(e) => setAMConductaQ6(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Se le caen las cosas con facilidad?</label>
                                                            <SelectButton className='pt-1' value={vl_AMConductaQ7} options={['SI', 'NO']} onChange={(e) => setAMConductaQ7(e.value)} />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Problemas de alimentación? </label>
                                                            <SelectButton className='pt-1' value={vl_AMConductaQ8} options={['SI', 'NO']} onChange={(e) => setAMConductaQ8(e.value)} />
                                                        </div>

                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Cuáles? Sueño, concentración. Indisciplina (irritabilidad, hiperactividad) . Otros.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMConductaQ8Txt}
                                                                onChange={(e: any) => {
                                                                    setAMConductaQ8Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Carácter del niño:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMConductaQ9Txt}
                                                                onChange={(e: any) => {
                                                                    setAMConductaQ9Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="JUEGO-SOCIAL" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Su hijo juega solo? ¿Por qué? ¿dirige o es dirigido?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMJuegoSocialQ1}
                                                                onChange={(e: any) => {
                                                                    setAMJuegoSocialQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Qué juegos prefiere su hijo? ¿Cuáles son sus juguetes preferidos?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMJuegoSocialQ2}
                                                                onChange={(e: any) => {
                                                                    setAMJuegoSocialQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Prefiere jugar con niños de su edad, con mayores o niños menores a su edad?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMJuegoSocialQ3}
                                                                onChange={(e: any) => {
                                                                    setAMJuegoSocialQ3(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Cuáles son las distracciones principales de su hijo? Uso del tiempo libre. Deportes.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMJuegoSocialQ4}
                                                                onChange={(e: any) => {
                                                                    setAMJuegoSocialQ4(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Conductas en el juego con otros niños: agresividad, ausencia del deseo del contacto..</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMJuegoSocialQ5}
                                                                onChange={(e: any) => {
                                                                    setAMJuegoSocialQ5(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="HISTORIA EDUCATIVA" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Inicial</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Edad </label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduIEdad}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduIEdad(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Adaptación  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduIAdaptacion}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduIAdaptacion(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Dificultades  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduIDificultades}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduIDificultades(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Conductas disfuncionales   </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduIConductasDisfuncionales}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduIConductasDisfuncionales(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-3">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Primaria</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Edad </label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduPEdad}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduPEdad(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-2 ">
                                                            <div className="field-checkbox">
                                                                <label className="p-col-fixed mr-1 text-sm font-bold " style={{ textAlign: 'end' }}>Rendimiento: </label>
                                                                <Checkbox onChange={e => setAMHistoriaEduPRendimientoBueno(e.checked)} checked={vl_AMHistoriaEduPRendimientoBueno}> </Checkbox>
                                                                <label className="p-checkbox-label">Bueno </label>
                                                                <Checkbox onChange={e => setAMHistoriaEduPRendimientoRegular(e.checked)} checked={vl_AMHistoriaEduPRendimientoRegular}> </Checkbox>
                                                                <label className="p-checkbox-label">Regular   </label>
                                                                <Checkbox onChange={e => setAMHistoriaEduPRendimientoBajo(e.checked)} checked={vl_AMHistoriaEduPRendimientoBajo}> </Checkbox>
                                                                <label className="p-checkbox-label">Bajo  </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Dificultades(especificar)</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduPDificultades}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduPDificultades(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Nivel de adaptación  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduPNivelAdaptacion}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduPNivelAdaptacion(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-3">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Secundaria</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Edad </label>
                                                            <InputText type="text" style={{ width: '8rem', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSEdad}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSEdad(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-2 ">
                                                            <div className="field-checkbox">
                                                                <label className="p-col-fixed mr-1 text-sm font-bold " style={{ textAlign: 'end' }}>Rendimiento: </label>
                                                                <Checkbox onChange={e => setAMHistoriaEduSRendimientoBueno(e.checked)} checked={vl_AMHistoriaEduSRendimientoBueno}> </Checkbox>
                                                                <label className="p-checkbox-label">Bueno </label>
                                                                <Checkbox onChange={e => setAMHistoriaEduSRendimientoRegular(e.checked)} checked={vl_AMHistoriaEduSRendimientoRegular}> </Checkbox>
                                                                <label className="p-checkbox-label">Regular   </label>
                                                                <Checkbox onChange={e => setAMHistoriaEduSRendimientoBajo(e.checked)} checked={vl_AMHistoriaEduSRendimientoBajo}> </Checkbox>
                                                                <label className="p-checkbox-label">Bajo  </label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Dificultades(especificar)</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSDificultades}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSDificultades(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Nivel de adaptación</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSNivelAdaptacion}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSNivelAdaptacion(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Cambios en el colegio ¿En qué grados y por qué?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ1Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ1Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Observó dificultades en tu aprendizaje? ¿desde cuándo? ¿Qué hizo? Rendimiento en la escritura, lectura y matemáticas.</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ2Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ2Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 mb-1 text-sm font-normal " style={{ width: '100%', textAlign: 'left' }}> Repitencias. ¿Cuántas veces? </label>
                                                            <SelectButton className='pt-1' value={vl_AMHistoriaEduSQ3} options={['SI', 'NO']} onChange={(e) => setAMHistoriaEduSQ3(e.value)} />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Por qué?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ3Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ3Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-3">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Conducta en clases</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Asignatura que más domina: </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ4Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ4Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Asignaturas que menos domina: </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ5Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ5Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Asignatura que menos domina </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ6Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ6Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-2">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Opinión de parte del niño hacia el colegio</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Hacia el profesor</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ7Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ7Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>De sus compañeros</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ8Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ8Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>De las tareas</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ9Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ9Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-2">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}> Opinión del profesor.</label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Ha recibido algún servicio especial?: Ejem: logopedia, refuerzos ¿Desde cuándo?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ10Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ10Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Frecuencia</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMHistoriaEduSQ11Txt}
                                                                onChange={(e: any) => {
                                                                    setAMHistoriaEduSQ11Txt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="PSICOSEXUALIDAD" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿A qué edad hizo preguntas su hijo, sobre sexo y procreación? Información que se le brindó ¿Cómo?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMPsicosexualidadQ1}
                                                                onChange={(e: any) => {
                                                                    setAMPsicosexualidadQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> ¿Tiene amigos(as) del sexo opuesto?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMPsicosexualidadQ2}
                                                                onChange={(e: any) => {
                                                                    setAMPsicosexualidadQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>¿Su hijo presenta conductas como tocar o sobar los genitales? </label>
                                                            <SelectButton className='pt-1' value={vl_AMPsicosexualidadQ3} options={['SI', 'NO']} onChange={(e) => setAMPsicosexualidadQ3(e.value)} />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="OPINIÓN Y ACTITUDES DE LOS PADRES" >
                                                    <div className="p-field p-grid mt-1 mb-1">
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Reacción de los padres. </label>
                                                        </div>
                                                        <div className="p-col pt-3">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Relación con la mamá: </label>
                                                        </div>
                                                        <div className="p-col pt-2 ">
                                                            <div className="field-checkbox">
                                                                <label className="p-col-fixed mr-1 text-sm font-normal " style={{ textAlign: 'end' }}>Rechazo: </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionMamaRechazo(e.checked)} checked={vl_AMOpinionActRelacionMamaRechazo}> </Checkbox>
                                                                <label className="p-checkbox-label">Preocupacion </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionMamaPreocupacion(e.checked)} checked={vl_AMOpinionActRelacionMamaPreocupacion}> </Checkbox>
                                                                <label className="p-checkbox-label">Verguenza   </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionMamaVerguenza(e.checked)} checked={vl_AMOpinionActRelacionMamaVerguenza}> </Checkbox>
                                                                <label className="p-checkbox-label">Indiferencia   </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionMamaIndiferencia(e.checked)} checked={vl_AMOpinionActRelacionMamaIndiferencia}> </Checkbox>
                                                                <label className="p-checkbox-label">Aceptacion   </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionMamaAceptacion(e.checked)} checked={vl_AMOpinionActRelacionMamaAceptacion}> </Checkbox>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> Descripción:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActRelacionMamaDescrTxt}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActRelacionMamaDescrTxt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Relación con el padre: </label>
                                                        </div>
                                                        <div className="p-col pt-2 ">
                                                            <div className="field-checkbox">
                                                                <label className="p-col-fixed mr-1 text-sm font-bold " style={{ textAlign: 'end' }}>Rechazo: </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionPadreRechazo(e.checked)} checked={vl_AMOpinionActRelacionPadreRechazo}> </Checkbox>
                                                                <label className="p-checkbox-label">Preocupacion </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionPadrePreocupacion(e.checked)} checked={vl_AMOpinionActRelacionPadrePreocupacion}> </Checkbox>
                                                                <label className="p-checkbox-label">Verguenza   </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionPadreVerguenza(e.checked)} checked={vl_AMOpinionActRelacionPadreVerguenza}> </Checkbox>
                                                                <label className="p-checkbox-label">Indiferencia   </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionPadreIndiferencia(e.checked)} checked={vl_AMOpinionActRelacionPadreIndiferencia}> </Checkbox>
                                                                <label className="p-checkbox-label">Aceptacion   </label>
                                                                <Checkbox onChange={e => setAMOpinionActRelacionPadreAceptacion(e.checked)} checked={vl_AMOpinionActRelacionPadreAceptacion}> </Checkbox>
                                                            </div>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> Descripción:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActRelacionPadreDescrTxt}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActRelacionPadreDescrTxt(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> ¿Cuál fue el comportamiento del menor al nacimiento de su hermano?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActQ1}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> ¿Cómo era la relación con sus hermanos en la infancia?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActQ2}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> Cambios: Aislarlo o dejarlo con el grupo, exigirle un comportamiento similar, mayor atención, sobreprotección</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActQ3}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActQ3(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Pautas de crianza: ¿Empleaba castigos, refuerzos positivos y con qué frecuencia? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActQ4}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActQ4(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-bold " style={{ textAlign: 'left' }}>Comportamiento del niño con los padres, hermanos, amigos, entre otros. </label>
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Cuidadores principales en la infancia: </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActQ5}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActQ5(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Cuidadores principales en la niñez:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActQ6}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActQ6(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Actualmente: ¿Con quién tiene mayor apego el niño? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMOpinionActQ7}
                                                                onChange={(e: any) => {
                                                                    setAMOpinionActQ7(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionTab>
                                                <AccordionTab header="ÁREA FAMILIAR" >
                                                    <div className="p-field p-grid mt-1 mb-1">

                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Especificar enfermedades psiquiátricas:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarEnfermedadPsiquiatrica}
                                                                onChange={(e: any) => {
                                                                    setAAAreaFamiliarEnfermedadPsiquiatrica(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Problemas del habla, dificultades en el aprendizaje, epilepsias, convulsiones, retardo mental, entre otros).</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarQ1}
                                                                onChange={(e: any) => {
                                                                    setAMAreaFamiliarQ1(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Carácter del padre:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarCaraterPapa}
                                                                onChange={(e: any) => {
                                                                    setAMAreaFamiliarCaraterPapa(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Carácter de la madre:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarCaraterMama}
                                                                onChange={(e: any) => {
                                                                    setAMAreaFamiliarCaraterMama(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Relación de pareja: ¿Qué ha observado el niño de la relación? :</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarQ2}
                                                                onChange={(e: any) => {
                                                                    setAMAreaFamiliarQ2(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> ¿Cómo es la relación de su papá con el menor?</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarQ3}
                                                                onChange={(e: any) => {
                                                                    setAMAreaFamiliarQ3(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> ¿Cómo es la relación de su mamá con el menor? </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarQ4}
                                                                onChange={(e: any) => {
                                                                    setAMAreaFamiliarQ4(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}> ¿Cómo es la relación con sus hermanos?  </label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarQ5}
                                                                onChange={(e: any) => {
                                                                    setAMAreaFamiliarQ5(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="p-col pt-1">
                                                            <label className="p-col mr-1 ml-1 mb-1 text-sm font-normal " style={{ textAlign: 'left' }}>Observaciones durante entrvista a los padres:</label>
                                                            <InputText type="text" style={{ width: '100%', textAlign: 'left' }}
                                                                value={vl_AMAreaFamiliarQ6}
                                                                onChange={(e: any) => {
                                                                    setAMAreaFamiliarQ6(String(e.currentTarget.value));
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                </AccordionTab>
                                            </Accordion>
                                        </Fieldset>
                                    </AccordionTab>
                                    <AccordionTab header="PLAN DE EVALUACIÓN ">
                                        <div className="p-field p-grid mt-1 pb-1">
                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">(*) Areas de evaluación y pruebas psicológicas</p>
                                            </div>
                                            <div className="p-col pt-1 pb-2 " >
                                                <Button label='Registrar' type="button" icon="pi pi-qrcode"
                                                    onClick={() => link_formPlanEvaluacion(vl_IdHistoriaClinica, 'New')}
                                                    className="p-button-small p-button-success mr-1"
                                                />
                                            </div>
                                            <div className='pb-2'>
                                                <DataTable size="small"
                                                    resizableColumns
                                                    style={{ height: '210px', width: '100%' }} //maxWidth:'700px'
                                                    value={gridDataOrdenAtencionDetalles ?? []}
                                                    dataKey="CodigoServicio"
                                                    responsiveLayout="scroll"
                                                    columnResizeMode="fit"
                                                    showGridlines
                                                    stripedRows
                                                    scrollable
                                                    scrollHeight="220px"
                                                    selectionMode="single"
                                                    //editMode="row"
                                                    // onRowEditComplete={onRowEditComplete3}
                                                    // selection={gridDataEvolucionSelected}
                                                    onSelectionChange={
                                                        (e) => {
                                                            // console.log(e.value);
                                                            // setPlanEvaIdOrdenAtencion(vl_IdOrdenAtencion);
                                                            // setPlanEvaPruebaCodigoServicio(e.value.CodigoServicio);
                                                            // setPlanEvaDescripcionArea(e.value.Descripcion);
                                                            // setvPlanEvaPruebaPsicologica(e.value?.PruebaPsicologica);
                                                            // refPlanEvaPruebaPsicologica.current.focus();

                                                        }
                                                    }
                                                >
                                                    <Column field="NumeroOrden" header="Nº OA" style={{ maxWidth: '90px' }}></Column>
                                                    <Column field="Fecha" header="Fecha" style={{ maxWidth: '120px' }}></Column>
                                                    <Column field="TotalSesion" header="Sesiones" bodyClassName="flex justify-content-center" style={{ maxWidth: '95px' }}></Column>
                                                    <Column field="Staff" header="Pofesional" bodyClassName="flex justify-content-center" style={{ maxWidth: '250px' }}></Column>
                                                    {/* <Column field="Descripcion" header="Área de evaluación" style={{ maxWidth: '300px' }}
                                                                body={actionBodyTemplate7}
                                                            ></Column>
                                                            <Column field="PruebaPsicologica" header="Pruebas" style={{ maxWidth: '300px' }}></Column>
                                                            <Column field="Resultados" header="Resultados" style={{ maxWidth: '300px' }}></Column> */}
                                                </DataTable>
                                            </div>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header="EVOLUCION DE EVALUACIÓN">
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col pb-1">
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ width: '70px', textAlign: 'end' }}>Fecha:</label>
                                                <Calendar style={{ width: '110px' }}
                                                    value={vl_EvolucionFecha}
                                                    onChange={(e: any) => setEvolucionFecha(e.value)} minDate={new Date()}
                                                    dateFormat="yy/mm/dd"
                                                />
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>Profesional:</label>
                                                <Dropdown style={{ width: '250px' }} filter filterBy="Descripcion"
                                                    value={vl_EvolucionIdStaff}
                                                    options={cmbIdStaff}
                                                    onChange={(e: any) => {
                                                        setEvolucionIdStaff(e.value); //para egregar al grid                                                        
                                                        let itemsfiltered: any = cmbIdStaff.filter(function (element: any) {
                                                            return element.IdStaff === e.value;
                                                        });
                                                        setEvolucionIdStaffNombre(itemsfiltered[0].staff);
                                                    }}
                                                    optionValue="IdStaff"
                                                    optionLabel="staff"
                                                    placeholder="Seleccionar" />
                                            </div>

                                            <div className="p-col pb-1">
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ width: '70px', textAlign: 'end' }}>Servicio:</label>
                                                <Dropdown style={{ width: '350px' }} filter filterBy="Descripcion"
                                                    value={vl_EvolucionIdOrdenPago}
                                                    options={cmbServiciosCliente}
                                                    onChange={(e: any) => {
                                                        setEvolucionIdOrdenPago(e.value); //para egregar al grid
                                                        // console.log(dataIdStaff);
                                                        let itemsfiltered: any = cmbServiciosCliente.filter(function (element: any) {
                                                            return element.IdOrdenPago === e.value;
                                                        });
                                                        // console.log(itemsfiltered[0].Detalle);                                                     
                                                        setEvolucionIdOrdenPagoDetalle(itemsfiltered[0].Detalle);
                                                        setEvolucionNumeroSesionTotal(itemsfiltered[0].NumeroCuotas);
                                                    }}
                                                    optionValue="IdOrdenPago"
                                                    optionLabel="Detalle"
                                                    placeholder="Seleccionar" />

                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>Nº Sesión:</label>
                                                <InputText type="number" style={{ width: '50px', textAlign: 'center' }}
                                                    value={vl_EvolucionNumeroSesion}
                                                    // ref={inputDiasRef}
                                                    onChange={(e) => setEvolucionNumeroSesion(String(e.currentTarget.value))}
                                                />
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>de:</label>
                                                <InputText type="number" style={{ width: '50px', textAlign: 'center' }}
                                                    value={vl_EvolucionNumeroSesionTotal}
                                                    readOnly
                                                    onChange={(e) => setEvolucionNumeroSesionTotal(String(e.currentTarget.value))}
                                                />
                                            </div>
                                            <ProgressBar
                                                value={(100 / vl_EvolucionNumeroSesionTotal) * vl_EvolucionNumeroSesion}
                                                // displayValueTemplate={() => `${vl_EvolucionNumeroSesion} / ${vl_EvolucionNumeroSesionTotal} `}
                                                displayValueTemplate={displayValueTemplateEvoluacion}
                                                style={{ width: '100%' }}>
                                            </ProgressBar>

                                        </div>
                                        <div className="p-col pt-1" >
                                            <InputTextarea className='mr-1' rows={5} cols={20} style={{ width: '100%' }}
                                                value={vl_EvolucionDetalleTxt}
                                                onChange={(e) => {
                                                    setEvolucionDetalleTxt(String(e.currentTarget.value))
                                                }}
                                            />
                                        </div>
                                        <div className="p-field p-grid mt-2 mb-1">
                                            <div className="p-col pt-1" >
                                                <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    tooltip='Agregar registro'
                                                    // label="Agregar"
                                                    // style={{ height: '33px' }}
                                                    onClick={(e) => setEvolucionAgregar()}
                                                    className="p-button-small p-button mr-1"
                                                />
                                                <Button
                                                    type="button"
                                                    icon="pi pi-file"
                                                    tooltip='Nuevo registro'
                                                    // label="Nuevo"
                                                    // style={{ height: '33px' }}
                                                    onClick={(e) => setEvolucionNuevo()}
                                                    className="p-button-small p-button mr-1"
                                                />
                                                <Button
                                                    type="button"
                                                    icon="pi pi-file-pdf"
                                                    label="Imprimir"
                                                    // style={{ height: '33px' }}
                                                    onClick={(e) => buildPDFFormatoEvolucionPaciente()}
                                                    className="p-button-small p-button"
                                                />
                                            </div>
                                        </div>
                                        <DataTable size="small"
                                            resizableColumns
                                            style={{ height: '210px' }} //maxWidth:'700px'
                                            value={gridDataEvolucion ?? []}
                                            dataKey="EvolucionNumber"
                                            responsiveLayout="scroll"
                                            columnResizeMode="fit"
                                            showGridlines
                                            stripedRows
                                            scrollable
                                            scrollHeight="220px"
                                            selectionMode="single"
                                            //editMode="row"
                                            // onRowEditComplete={onRowEditComplete3}
                                            selection={gridDataEvolucionSelected}
                                            onSelectionChange={
                                                (e) => {
                                                    setGridDataEvolucionSelected(e.value); //no usado x ahora
                                                    // console.log(e.value);
                                                    setEvolucionNumber(e.value.EvolucionNumber);
                                                    setEvolucionFecha(new Date(Date.parse(e.value.EvolucionFecha)));
                                                    setEvolucionIdStaff(e.value.EvolucionIdStaff);
                                                    setEvolucionIdStaffNombre(e.value.EvolucionIdStaffNombre);
                                                    setEvolucionIdOrdenPago(e.value.EvolucionIdOrdenPago);
                                                    // console.log(e.value.EvolucionIdOrdenPagoDetalle);
                                                    setEvolucionIdOrdenPagoDetalle(e.value.EvolucionIdOrdenPagoDetalle);
                                                    setEvolucionNumeroSesion(e.value.EvolucionNumeroSesion);
                                                    setEvolucionNumeroSesionTotal(e.value.EvolucionNumeroSesionTotal);
                                                    setEvolucionDetalleTxt(e.value.EvolucionDetalleTxt);
                                                }
                                            }
                                        >
                                            <Column field="EvolucionNumber" header="Nº" style={{ maxWidth: '50px' }}  ></Column>
                                            <Column field="EvolucionFecha" header="Fecha" style={{ maxWidth: '110px' }}   ></Column>
                                            <Column field="EvolucionIdStaffNombre" header="Nombre Profesional" style={{ maxWidth: '200px' }} ></Column>
                                            <Column field="EvolucionIdOrdenPagoDetalle" header="Servicio" style={{ maxWidth: '200px' }}
                                            // editor={(options) => gridProfesionalTratanteEditor(options)}
                                            ></Column>
                                            <Column field="EvolucionNumeroSesion" header="Nº Ses." style={{ maxWidth: '90px' }}
                                            // editor={(options) => gridProfesionalTratanteEditor(options)}
                                            ></Column>
                                            {/* <Column field="EvolucionDetalleTxt" header="Detalles" style={{ maxWidth: '200px' }}></Column> */}
                                            {/* <Column rowEditor style={{ maxWidth: '75px' }} bodyStyle={{ width: '50px', textAlign: 'center' }}></Column> */}
                                            <Column field="" header="" body={actionBodyTemplate4} style={{ maxWidth: '64px' }}  ></Column>
                                        </DataTable>

                                    </AccordionTab>
                                    <AccordionTab header="IMPRESIÓN DIAGNÓSTICA">
                                        <div className="p-field p-grid mt-1 mb-1">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>Diagnóstico:</label>
                                                <InputText type="text" style={{ width: '40%', textAlign: 'left' }}
                                                    value={vl_ImpresionDiagnosticaNombre}
                                                    onChange={(e: any) => {
                                                        setImpresionDiagnosticaNombre(String(e.currentTarget.value))
                                                    }}
                                                />
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-normal " style={{ textAlign: 'end' }}>
                                                    <a title='ir a la web eCIEMaps' target="_blank" rel="noreferrer"
                                                        href='https://eciemaps.mscbs.gob.es/ecieMaps/browser/index_10_mc.html' >Código CIE-10:</a>
                                                </label>
                                                <InputText type="text" style={{ width: '10%', textAlign: 'center' }}
                                                    value={vl_ImpresionDiagnosticaCodigoCIE10}
                                                    onChange={(e: any) => {
                                                        setImpresionDiagnosticaCodigoCIE10(String(e.currentTarget.value))
                                                    }}
                                                />
                                                <label className="p-col-fixed mr-2" style={{ textAlign: 'end' }}></label>
                                                <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    style={{ height: '33px' }}
                                                    onClick={(e) => setImpresionDiagnosticaAgregar()}
                                                    className="p-button-rounded p-button-warning"
                                                />
                                            </div>
                                        </div>
                                        <DataTable size="small"
                                            resizableColumns
                                            style={{ height: '210px' }}
                                            value={gridDataImpresionDiagnostica ?? []}

                                            dataKey="ImpresionDiagnosticaNumber"
                                            responsiveLayout="scroll"
                                            columnResizeMode="fit"
                                            showGridlines
                                            stripedRows
                                            scrollable
                                            scrollHeight="220px"
                                            selectionMode="single"
                                            editMode="row"
                                            onRowEditComplete={onRowEditComplete1}
                                        >
                                            <Column field="ImpresionDiagnosticaNumber" header="Nº" style={{ maxWidth: '64px' }}  ></Column>
                                            <Column field="ImpresionDiagnosticaNombre" header="Nombre del diagnóstico"
                                                editor={(options) => gridImpresionDiagnosticaTextEditor(options)}>
                                            </Column>
                                            <Column field="ImpresionDiagnosticaCodigoCIE10" header="Código CIE-10" style={{ maxWidth: '150px' }}
                                                editor={(options) => gridImpresionDiagnosticaTextEditor(options)}
                                            >
                                            </Column>
                                            <Column rowEditor style={{ maxWidth: '75px' }} bodyStyle={{ width: '50px', textAlign: 'center' }}></Column>
                                            <Column field="" header="" body={actionBodyTemplate} style={{ maxWidth: '64px' }}  ></Column>
                                        </DataTable>
                                    </AccordionTab>
                                    <AccordionTab header="PLAN DE TERAPIA ">
                                        <div className="p-field p-grid mt-1 pb-1">
                                            <div className="flex flex-wrap align-items-center justify-content-start pb-2">
                                                <p className="font-italic text-indigo-600 border-round border-1 surface-overlay p-1">(*) Registro plan de terapia y evolución de sesiones</p>
                                            </div>
                                            <div className="p-col pt-1 pb-2 " >
                                                <Button label='Registrar' type="button" icon="pi pi-qrcode"
                                                    onClick={() => link_formPlanTerapia(vl_IdHistoriaClinica, 'New')}
                                                    className="p-button-small p-button-success mr-1"
                                                />
                                            </div>
                                            <div className='pb-2'>
                                                <DataTable
                                                    size="small"
                                                    resizableColumns
                                                    style={{ minHeight: '200px', width: '100%' }} //maxWidth:'700px'
                                                    value={gridDataOrdenTerapiaDetalles ?? []}
                                                    dataKey="CodigoServicio"
                                                    responsiveLayout="scroll"
                                                    // scrollHeight="220px"
                                                    columnResizeMode="fit"
                                                    showGridlines
                                                    stripedRows
                                                    scrollable

                                                    selectionMode="single"
                                                    //editMode="row"
                                                    // onRowEditComplete={onRowEditComplete3}
                                                    // selection={gridDataEvolucionSelected}
                                                    onSelectionChange={() => {
                                                        // console.log(e.value);
                                                        // setPlanEvaIdOrdenAtencion(vl_IdOrdenAtencion);
                                                        // setPlanEvaPruebaCodigoServicio(e.value.CodigoServicio);
                                                        // setPlanEvaDescripcionArea(e.value.Descripcion);
                                                        // setvPlanEvaPruebaPsicologica(e.value?.PruebaPsicologica);
                                                        // refPlanEvaPruebaPsicologica.current.focus();
                                                    }
                                                    }
                                                >
                                                    {/* <Column field="NumeroOrden" header="Nº OT" style={{ maxWidth: '90px' }}></Column> */}
                                                    <Column field="Servicio" header="Terapia" style={{ maxWidth: '400px' }}
                                                    // body={actionBodyTemplate8}
                                                    ></Column>
                                                    <Column field="TotalSesion" header="Sesiones" bodyClassName="flex justify-content-center" style={{ maxWidth: '95px' }}></Column>
                                                    <Column field="" header="Plan" body={progressBarPlan} headerClassName="flex justify-content-center pi pi-check-square " bodyClassName="flex justify-content-center" style={{ maxWidth: '200px' }}></Column>
                                                    <Column field="" header="Evolución" body={progressBarEvolucion} headerClassName="flex justify-content-center pi pi-chart-line " bodyClassName="flex justify-content-center" style={{ maxWidth: '200px' }}></Column>
                                                </DataTable>
                                            </div>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header="INFORME PSICOLÓGICO">
                                        <div className="flex border-bottom-1 surface-border w-full">
                                            <p className="font-medium w-10">Crear o editar un informe.</p>
                                        </div>
                                        <div className="p-col pt-1" >
                                            <Button
                                                type="button"
                                                icon="pi pi-file"
                                                label="Nuevo"
                                                onClick={() => link_form_informes(vl_IdHistoriaClinica, 0, 'New')}
                                                className="p-button p-button-success"
                                            />
                                        </div>
                                        <div className="flex border-bottom-1 pt-2 surface-border w-full">
                                            <DataTable size="small"
                                                // header="Informes generados"
                                                resizableColumns
                                                style={{ height: '210px' }}
                                                value={gridDataInformePsicologico ?? []}
                                                dataKey="IdInforme"
                                                responsiveLayout="scroll"
                                                columnResizeMode="fit"
                                                showGridlines
                                                stripedRows
                                                scrollable
                                                scrollHeight="220px"
                                                selectionMode="single"
                                                editMode="row"
                                            // onRowEditComplete={onRowEditComplete2}
                                            >
                                                <Column field="IdInforme" header="#" style={{ maxWidth: '40px' }}></Column>
                                                <Column field="FechaInforme" header="Fecha" style={{ minWidth: '100px' }}  ></Column>
                                                <Column field="NumeroInforme" header="Nº Informe" ></Column>
                                                <Column field="NombreDocumento" header="Documento" style={{ minWidth: '200px' }} ></Column>
                                                <Column field="Estado" header="Estado" ></Column>
                                                <Column body={actionBodyTemplate8} style={{ maxWidth: '64px' }}></Column>
                                            </DataTable>
                                        </div>
                                    </AccordionTab>
                                    <AccordionTab header="PROFESIONAL TRATANTE">
                                        <div className="p-field p-grid mt-1 mb-2">
                                            <div className="p-col">
                                                <label className="p-col-fixed mr-1 ml-2 text-sm font-bold " style={{ textAlign: 'end' }}>Profesional:</label>
                                                <Dropdown style={{ width: '250px' }} filter filterBy="Descripcion"
                                                    value={vl_IdStaff}
                                                    options={cmbIdStaff}
                                                    onChange={(e: any) => {
                                                        setIdStaff(e.value); //para grabar como id principal
                                                        setProfesionalTratanteNombreIdStaff(e.value); //para egregar al grid
                                                        // console.log(dataIdStaff);
                                                        let itemsfiltered: any = cmbIdStaff.filter(function (element: any) {
                                                            return element.IdStaff === e.value;
                                                        });
                                                        // console.log(itemsfiltered);                                                     
                                                        setProfesionalTratanteNombre(itemsfiltered[0].staff);
                                                    }}
                                                    optionValue="IdStaff"
                                                    optionLabel="staff"
                                                    placeholder="Seleccionar" />
                                                <label className="p-col-fixed mr-2 text-sm text-pink-700" style={{ textAlign: 'end' }}>(*)Vigente</label>
                                                <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    label="Agregar"
                                                    onClick={(e) => setProfesionalTranteAgregar()}
                                                    className="p-button p-button-success"
                                                />
                                            </div>
                                        </div>
                                        <DataTable size="small"
                                            // header="Profesional tratante"
                                            resizableColumns
                                            style={{ height: '210px' }}
                                            value={gridDataStaff ?? []}
                                            dataKey="ProfTratantesNumber"
                                            responsiveLayout="scroll"
                                            columnResizeMode="fit"
                                            showGridlines
                                            stripedRows
                                            scrollable
                                            scrollHeight="220px"
                                            selectionMode="single"
                                            editMode="row"
                                            onRowEditComplete={onRowEditComplete2}
                                        >
                                            <Column field="ProfesionalTratanteNumber" header="Nº" style={{ maxWidth: '60px' }}  ></Column>
                                            <Column field="ProfesionalTratanteNombre" header="Nombres">
                                            </Column>
                                            <Column field="ProfesionalTratantePeriodoDesde" header="Desde" style={{ maxWidth: '110px' }}
                                                editor={(options) => gridProfesionalTratanteEditor(options)}
                                            ></Column>
                                            <Column field="ProfesionalTratantePeriodoHasta" header="Hasta" style={{ maxWidth: '110px' }}
                                                editor={(options) => gridProfesionalTratanteEditor(options)}
                                            ></Column>
                                            <Column field="ProfesionalTratanteEstado" header="Estado" style={{ maxWidth: '100px' }}
                                                editor={(options) => gridProfesionalTratanteStatusEditor(options)}
                                                body={actionBodyTemplate2}
                                            ></Column>
                                            <Column rowEditor style={{ maxWidth: '75px' }} bodyStyle={{ width: '50px', textAlign: 'center' }}></Column>
                                            <Column field="" header="" body={actionBodyTemplate3} style={{ maxWidth: '64px' }}  ></Column>
                                        </DataTable>
                                    </AccordionTab>
                                    <AccordionTab header="DERIVACIONES" >
                                        <div className="flex border-bottom-1 surface-border w-full">
                                            <p className="font-medium w-10">Derivaciones del paciente.</p>
                                        </div>
                                        <div className="p-col pt-1" >
                                            <Button
                                                type="button"
                                                icon="pi pi-inbox"
                                                label="Ir a la bandeja .. "
                                                onClick={() => setVisibleConfirmDialog2(true)}
                                                className="p-button-link p-button-success"
                                            />
                                        </div>
                                        <div className="p-1 mb-1">
                                            <DataTable size="small" resizableColumns
                                                responsiveLayout="scroll"
                                                columnResizeMode="fit"
                                                showGridlines
                                                stripedRows
                                                scrollable rowClassName={rowClassName}
                                                value={gridDataDerivaciones}
                                                dataKey="IdDerivacion"
                                                selectionMode="single"
                                                // selection={selectedGrid1}
                                                // onSelectionChange={e => setSelectedGrid1(e.value)}
                                                scrollHeight="400px"
                                                paginator
                                                rows={10}
                                            >
                                                <Column field="IdDerivacion" header="Nº" style={{ maxWidth: '4rem' }}   ></Column>
                                                <Column field="FechaDerivacion_1" header="Fecha" style={{ minWidth: '8rem' }}  ></Column>
                                                {/* <Column field="NombreProfesional" header="Profesional tratante" style={{ minWidth: '15rem' }} headerClassName='white-space-normal'  ></Column>
                                                <Column field="NombreProfesionalDerivado" header="Profesional a quien se deriva" style={{ minWidth: '15rem' }} headerClassName='white-space-normal'   ></Column>
                                                <Column field="TipoDerivacion_1" header="Tipo Deriv."  ></Column> */}
                                                <Column field="NombreProfesional" header="Derivado de" style={{ minWidth: '15rem' }}
                                                    body={(item: any) => {
                                                        return (item.NombreProfesional === '' ? item.DerivacionExterna : item.NombreProfesional);
                                                    }}   ></Column>
                                                <Column field="NombreProfesionalDerivado" header="Derivado a" style={{ minWidth: '15rem' }}
                                                    body={(item: any) => {
                                                        return (item.NombreProfesionalDerivado === '' ? item.DerivacionExternaHacia : item.NombreProfesionalDerivado);
                                                    }}  ></Column>
                                                <Column field="TipoDerivacion_1" header="Tipo"  ></Column>
                                                <Column field="Estado" header="Estado" style={{ maxWidth: '4rem' }} bodyClassName='flex justify-content-center'
                                                    body={(item: any) => {
                                                        return (<label title={'' + item.Estado_1} className={`${item.Estado === 1 ? ' pi pi-thumbs-up-fill ' : ' pi pi-thumbs-down-fill '}`}></label>);
                                                    }}
                                                ></Column>
                                                <Column header="Motivo" style={{ minWidth: '30rem', maxWidth: '30rem' }} bodyClassName='white-space-normal'
                                                    body={(item: any) => {
                                                        return (<label className='text-sm' title={item.MotivoDerivacion.MotivoDerivacion} > {item.MotivoDerivacion.MotivoDerivacion}  </label>)
                                                    }} ></Column>
                                                <Column header="Recomendaciones" style={{ minWidth: '30rem', maxWidth: '30rem' }} bodyClassName='white-space-normal'
                                                    body={(item: any) => {
                                                        return (<label className='text-sm' title={item.Recomendacion.Recomendacion} > {item.Recomendacion.Recomendacion}  </label>)
                                                    }}   ></Column>
                                            </DataTable>
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                                <ScrollTop target="parent" threshold={100} className="custom-scrolltop" icon="pi pi-arrow-up" />
                            </ScrollPanel>
                        </div>
                    </div>
                </div>
            </ScrollPanel>
        </>
    );
};

export default HistoriaClinicaView;
